import { useBillingServiceStore } from '@billing/AppProvider'
import ReportStep from '@billing/app/models/report-step.enum'
import { delay } from '@billing/utils/delay'
import { downloadHtmlAsPDF } from '@billing/utils/export-html'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { DeliveryNoteReport } from '../components/deliveryNoteReport/DeliveryNoteReport'
import { DeliveryNoteReportFilter } from '../components/deliveryNoteReport/DeliveryNoteReportFilter'
import { ReportNavigationButtonContainer } from './ReportNavigationButtonContainer'

export const DeliveryNoteContainer = observer(() => {
  const {
    appStore,
    reportsUIStore,
    companyStore,
    tenantStore,
    carrierVisitStore,
    deliveryNoteReportUIStore,
  } = useBillingServiceStore()

  const finish = () => {
    deliveryNoteReportUIStore.resetData()

    reportsUIStore.resetStepper()
  }

  useEffect(() => {
    async function fetchData() {
      await Promise.all([
        carrierVisitStore.getTruckVisits(false), //TOLATER: TBD, false or true
        companyStore.load(),
        tenantStore.get(),
      ])
    }

    fetchData()
  }, [carrierVisitStore, companyStore, tenantStore])

  const downloadHtmlAsFile = async () => {
    appStore.increaseLoadingCounter()

    requestAnimationFrame(async () => {
      await downloadHtmlAsPDF('delivery-note-repo', 'delivery-note', 'portrait')
      await delay(1000)
      appStore.decreaseLoadingCounter()
    })
  }

  return (
    <>
      {reportsUIStore.currentStep === ReportStep.Filter && <DeliveryNoteReportFilter />}
      {reportsUIStore.currentStep === ReportStep.Report && <DeliveryNoteReport />}
      <ReportNavigationButtonContainer
        disableNext={!deliveryNoteReportUIStore.selectedTruckVisit}
        downloadAction={() => downloadHtmlAsFile()}
        finishAction={finish}
      />
    </>
  )
})
