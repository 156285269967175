import { Button, Grid, Typography } from '@mui/material'
import { ArrowRightIcon } from '@mui/x-date-pickers'
import { OrderResponseDto } from '@planning/app/api'
import { OrderItemChips } from '@planning/components/organisms/OrderItemChips'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  order: OrderResponseDto

  onClickSlot?: (slot: OrderResponseDto) => void
}

export const BookingSlotItem: FC<IProps> = observer(({ order, onClickSlot }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const hasContainerNumber = order.containerNumber && order.containerNumber !== ''

  return (
    <Button
      variant='text'
      color='inherit'
      sx={{ padding: 0, width: '100%' }}
      onClick={() => onClickSlot?.(order)}
    >
      <Grid
        container
        p={theme.customSpacing.s}
        borderBottom={`1px solid ${theme.palette.divider}`}
        width='100%'
      >
        <Grid item xs={3} display='flex' alignItems='center'>
          <Typography
            variant='h6'
            color={hasContainerNumber ? theme.palette.text.primary : theme.palette.text.disabled}
          >
            {hasContainerNumber
              ? order.containerNumber!.toUpperCase()
              : t('unspecifiedContainer', 'Unspecified Container')}
          </Typography>
        </Grid>
        <Grid item xs={8} display='flex' alignItems='center'>
          <OrderItemChips order={order} />
        </Grid>
        <Grid item xs={1} display='flex' alignItems='center' justifyContent='flex-end'>
          <ArrowRightIcon color='secondary' />
        </Grid>
      </Grid>
    </Button>
  )
})
