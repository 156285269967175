import { Autocomplete, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CompanyType, VesselDto } from '@planning/app/api'
import { VesselServiceAutocomplete } from '@planning/components/autocompletes/vessel-service-autocomplete/VesselServiceAutocomplete'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import { VesselItem } from '@planning/rt-stores/vessel/VesselItem'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useUniquenessValidation } from '../../../../hooks/useIsPropertyUnique'
import { countryCodeOptions } from '../../helpers/countryCodeOptions'

const getDefaultValues = (): VesselFormData => ({
  name: '',
  shortName: '',
  length: 0,
  width: 0,
  countryCode: '',
  callSign: '',
  capacity: null,
  grossTonnage: null,
  imoNumber: '',
  isActive: true,
  maxDraft: null,
  mmsi: '',
  remarks: '',
  serviceId: null,
  shippingAgentId: null,
  shippingLine: '',
})

export interface VesselFormData {
  id?: number
  name: string
  shortName: string
  shippingLine?: string | null
  imoNumber?: string | null
  mmsi?: string | null
  callSign: string
  length: number
  width: number
  countryCode: string
  grossTonnage?: number | null
  capacity?: number | null
  maxDraft?: number | null
  shippingAgentId?: number | null
  serviceId?: number | null
  isActive?: boolean
  remarks?: string | null
}

interface Props {
  formId?: string
  vessel?: VesselDto
  vesselItems: VesselItem[]
  onSubmit: (data: VesselFormData) => Promise<void>
}
export const VesselForm = observer(({ formId, vessel, vesselItems, onSubmit }: Props) => {
  const { t } = useTranslate()
  const isOrderNewCompanyFieldsActive = useBooleanFlagValue('order-changing-company-fields', false)

  const [isNameUnique, validateNameUniqueness] = useUniquenessValidation({
    id: vessel?.id,
    items: vesselItems.map(x => ({ id: x.id, name: x.data.name })),
  })
  const [isShortNameUnique, validateShortNameUniqueness] = useUniquenessValidation({
    id: vessel?.id,
    items: vesselItems.map(x => ({ id: x.id, name: x.data.shortName ?? '' })),
  })

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<VesselFormData>({
    defaultValues: getDefaultValues(),
  })

  useEffect(() => {
    if (vessel) {
      reset({
        ...vessel,
      })
    } else {
      reset(getDefaultValues())
    }
  }, [vessel, reset])

  const getHelperText = (field: keyof VesselFormData) => {
    if (field === 'name' && !isNameUnique)
      return t('fieldAlreadyExists', '{field} already exists.', { field: t('name', 'Name') })
    if (field === 'shortName' && !isShortNameUnique)
      return t('fieldAlreadyExists', '{field} already exists.', {
        field: t('shortName', 'Short name'),
      })

    if (errors[field]?.message) return errors[field]?.message?.toString()

    return errors[field] ? t('fieldIsRequired', 'Field is required.') : ''
  }

  return (
    <Grid
      container
      spacing={1.5}
      paddingTop={0.2}
      id={formId ?? 'vessel-form'}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <Controller
          name='name'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('name', 'Name')}
              required
              fullWidth
              onChange={event => {
                field.onChange(event)
                validateNameUniqueness(event.target.value)
              }}
              error={!!errors.name || !isNameUnique}
              helperText={getHelperText('name')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='shortName'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('shortName', 'Short Name')}
              fullWidth
              required
              onChange={event => {
                field.onChange(event)
                validateShortNameUniqueness(event.target.value)
              }}
              error={!!errors.shortName || !isShortNameUnique}
              helperText={getHelperText('shortName')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name='shippingLine'
          rules={{ required: true }}
          render={({ field }) =>
            isOrderNewCompanyFieldsActive ? (
              <CustomerAutoComplete
                label={t('vesselOperator', 'Vessel operator')}
                required
                selectedCompanyShortName={field.value}
                handleOnChange={operator => {
                  field.onChange(operator?.shortName)
                }}
                filterByTypes={[CompanyType.VesselOperator]}
                error={!!errors.shippingLine}
              />
            ) : (
              <TextField
                {...field}
                required
                label={t('vesselOperator', 'Vessel operator')}
                fullWidth
              />
            )
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name='shippingAgentId'
          render={({ field }) =>
            isOrderNewCompanyFieldsActive ? (
              <CustomerAutoComplete
                label={t('shippingAgent', 'Shipping Agent')}
                selectedCompanyId={field.value}
                handleOnChange={operator => {
                  field.onChange(operator?.id)
                }}
                filterByTypes={[CompanyType.ShippingAgent]}
              />
            ) : (
              <TextField
                {...field}
                label={t('shippingAgent', 'Shipping Agent')}
                fullWidth
                type='number'
              />
            )
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name='serviceId'
          control={control}
          render={({ field }) => (
            <VesselServiceAutocomplete
              label={t('defaultService', 'Default service')}
              value={field.value}
              onChange={id => field.onChange(id)}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name='imoNumber'
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('imoNumber', 'IMO Number')}
              fullWidth
              required
              error={!!errors.imoNumber}
              helperText={getHelperText('imoNumber')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name='countryCode'
          rules={{
            required: t('fieldIsRequired', 'Field is required.'),
            minLength: {
              value: 2,
              message: t('countryCodeLengthMustBeTwo', 'Country code length must be 2.'),
            },
          }}
          render={({ field }) => (
            <Autocomplete
              freeSolo
              disablePortal
              options={countryCodeOptions}
              value={field.value}
              onChange={(_, value) => field.onChange(value)}
              renderInput={params => (
                <TextField
                  key={params.id}
                  {...params}
                  label={t('countryCode', 'Country code')}
                  required
                  error={!!errors.countryCode}
                  helperText={getHelperText('countryCode')}
                  onChange={e => field.onChange(e)}
                  InputProps={{
                    ...params.InputProps,
                    inputProps: {
                      ...params.inputProps,
                      maxLength: 2,
                      style: { textTransform: 'uppercase' },
                    },
                  }}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name='callSign'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('callSign', 'Call Sign')}
              required
              variant='outlined'
              fullWidth
              error={!!errors.callSign}
              helperText={getHelperText('callSign')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name='mmsi'
          control={control}
          render={({ field }) => (
            <TextField {...field} label={t('mmsi', 'MMSI')} variant='outlined' fullWidth />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name='grossTonnage'
          control={control}
          rules={{ min: 1 }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('grossTonnage', 'Gross tonnage')}
              fullWidth
              type='number'
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name='capacity'
          control={control}
          rules={{ min: 1 }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('capacity', 'Capacity')}
              fullWidth
              type='number'
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    TEU
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Controller
          name='length'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('length', 'Length')}
              fullWidth
              required
              type='number'
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    m
                  </CustomInputAdornment>
                ),
              }}
              inputProps={{
                min: 1,
              }}
              error={!!errors.length}
              helperText={getHelperText('length')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          name='width'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('width', 'Width')}
              fullWidth
              required
              type='number'
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    m
                  </CustomInputAdornment>
                ),
              }}
              inputProps={{
                min: 1,
              }}
              error={!!errors.width}
              helperText={getHelperText('width')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          name='maxDraft'
          rules={{ min: 1 }}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('maxDraft', 'Max Draft')}
              fullWidth
              type='number'
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    m
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='remarks'
          control={control}
          render={({ field }) => (
            <TextField {...field} label={t('remarks', 'Remarks')} multiline rows={3} fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name='isActive'
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label={t('active', 'Active')}
            />
          )}
        />
      </Grid>
    </Grid>
  )
})
