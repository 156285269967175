import * as yup from 'yup'
import { TenantConfigFormProfile } from './tenant-config-form.profile'

export const schema = (): yup.Schema<TenantConfigFormProfile> =>
  yup.object({
    id: yup.number().optional(),
    name: yup.string().label('Name').required(),
    reeferContainerMaxUnpluggedTime: yup.number().emptyAsUndefined().min(0).max(720),
    truckArrivalWindowDuration: yup.number().emptyAsUndefined().min(0).max(720),
  })
