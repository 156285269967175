import { Box, Button, Card, CardContent, Stack } from '@mui/material'
import { EquipmentDto, JobDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { JobAlert } from '../JobCard/JobAlert'
import { JobContainerNumber } from '../JobCard/JobContainerNumber'
import { JobDescriptionMinimalistic } from './JobDescriptionMinimalistic'
import { JobJourneyMinimalistic } from './JobJourneyMinimalistic'

interface Props {
  job: JobDto
  isConfirm?: boolean
  highlightText?: string
  currentEquipment?: EquipmentDto
  onConfirm: (job: JobDto) => void
  showOutboundInfo: boolean
}
export const JobCardMinimalistic = ({
  job,
  isConfirm,
  onConfirm,
  highlightText,
  currentEquipment,
  showOutboundInfo,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const handleConfirm = () => {
    onConfirm(job)
  }

  return (
    <Card
      sx={{ width: '100%', height: '100%' }}
      data-cy={`job-card-${job.cargoUnit?.displayName ?? 'non-numeric'}`}
    >
      <Box
        sx={{
          padding: theme.customSpacing.s,
          display: 'flex',
          backgroundColor: theme.palette.grey[100],
          overflowX: 'auto',
        }}
      >
        <JobJourneyMinimalistic job={job} currentEquipment={currentEquipment} />
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.customSpacing.xs,
          height: '100%',
        }}
      >
        <JobAlert warningReason={job.warningReason} />

        <Stack gap={theme.customSpacing.m}>
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
          >
            <JobContainerNumber job={job} highlightText={highlightText} variant='h1' />

            <Stack flexDirection='row' gap={theme.customSpacing.xll}>
              <JobDescriptionMinimalistic job={job} showOutboundInfo={showOutboundInfo} />

              <Button
                variant='text'
                color='primary'
                size='large'
                onClick={handleConfirm}
                disabled={job.isPlanned}
                data-cy='confirm-container-job-btn'
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                {isConfirm ? t('confirmMove', 'Confirm move') : t('startMove', 'Start move')}
              </Button>
            </Stack>
          </Stack>

          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={handleConfirm}
            disabled={job.isPlanned}
            sx={{ display: { xs: 'block', sm: 'none' } }}
          >
            {isConfirm ? t('confirmMove', 'Confirm move') : t('startMove', 'Start move')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}
