import { Card, CardHeader, Stack, Typography } from '@mui/material'
import { EquipmentDto } from '@operations/app/api'
import { canBeAssignedToYardBlock } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { Tile, useMinimalsTheme } from '@tom-ui/ui'
import { useMemo } from 'react'
import { EquipmentStatusChip } from '../statusChip/EquipmentStatus'

interface Props {
  equipment: EquipmentDto
  onClick?: (equipment: EquipmentDto) => void
}
export const Equipment = ({ equipment, onClick }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const handleClick = () => {
    if (onClick) {
      onClick(equipment)
    }
  }

  const yardBlockAssignment = useMemo<string>(() => {
    const assignments: string[] = []
    equipment.yardBlocks?.forEach(yb => {
      let name = yb.name
      const workArea = equipment.workAreaEquipments?.find(wa => wa.yardBlockId === yb.id)
      if (workArea?.assignedBays.length) {
        const min = workArea.assignedBays[0]
        const max = workArea.assignedBays[workArea.assignedBays.length - 1]
        name = `${name}.${min}${min !== max ? '-' + max : ''}`
      }

      assignments.push(name)
    })

    return assignments.length ? assignments.join(', ') : t('noAssignment', 'No assignment')
  }, [equipment, t])

  return (
    <Card onClick={handleClick} sx={{ cursor: onClick ? 'pointer' : undefined }}>
      <CardHeader
        title={
          <Stack flexDirection='row' justifyContent='space-between'>
            <Typography variant='h4'>{equipment.name}</Typography>
            <EquipmentStatusChip isUnavailable={equipment.isOnMaintenance} isInOperation={false} />
          </Stack>
        }
        subheader={
          <Stack paddingTop={theme.customSpacing.xs}>
            <Typography variant='body2'>{t(equipment.equipmentType)}</Typography>
            {canBeAssignedToYardBlock(equipment.equipmentType) && (
              <Typography variant='body2'>{yardBlockAssignment}</Typography>
            )}
          </Stack>
        }
        sx={{ paddingBottom: theme.customSpacing.m }}
      />
      {(!!equipment.maxStackingHeight || !!equipment.maxLiftWeight) && (
        <Stack
          flexDirection='row'
          sx={{
            backgroundColor: theme.palette.grey[100],
            gap: theme.customSpacing.xl,
            padding: theme.customSpacing.m,
          }}
        >
          {!!equipment.maxLiftWeight && (
            <Tile
              label={t('maxLiftWeight', 'Max lift weight')}
              value={equipment.maxLiftWeight + ' kg'}
            />
          )}
          {!!equipment.maxStackingHeight && (
            <Tile
              label={t('maxStackingHeight', 'Max stacking height')}
              value={equipment.maxStackingHeight}
            />
          )}
        </Stack>
      )}
    </Card>
  )
}
