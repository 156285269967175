import { CarrierVisitsApi, UpsertOrderListCommandV2 } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class CarrierVisitService {
  httpClient = createApiClient(CarrierVisitsApi)

  async delete(id: number) {
    await this.httpClient._delete(id)
  }

  orders = async (visitId: number) => {
    const { data } = await this.httpClient.orders(visitId)

    return data
  }

  containers = async (visitId: number) => {
    const { data } = await this.httpClient.containers(visitId)

    return data
  }

  visits = async (startDate: string, endDate: string) => {
    const { data } = await this.httpClient.getVisits(startDate, endDate)

    return data
  }

  async upsertOrderListV2(upsertOrderListCommand: UpsertOrderListCommandV2) {
    await this.httpClient.orders_1(upsertOrderListCommand)
  }
}

const carrierVisitService = new CarrierVisitService()

export default carrierVisitService
