import { Stack } from '@mui/material'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { OrderWarnings } from '../Atoms/OrderWarnings'

interface Props {
  truckVisit: ITruckVisitItem
  needsReservation?: boolean
}
export const TruckVisitWarnings = observer(({ truckVisit, needsReservation }: Props) => {
  const hasActiveHolds = useMemo(() => {
    return truckVisit.orders.some(order => order.hasActiveHold)
  }, [truckVisit.orders])

  const doesNotHaveReservation = computed(
    () => needsReservation && truckVisit.inboundOrders.some(o => o.hasMissingPosition),
  ).get()

  return (
    <Stack direction='row' spacing={1}>
      <OrderWarnings hasHold={hasActiveHolds} hasMissingReservation={doesNotHaveReservation} />
    </Stack>
  )
})
