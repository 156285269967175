import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import {
  EXPORT_OCCUPANCY_BACKGROUND,
  IMPORT_OCCUPANCY_BACKGROUND,
} from '@storage/pages/yard-management/constants'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { YardBlockBayFilterBy } from './models'

interface Props {
  tenantConfigStore: TenantConfigStore
  onFilterViewChange: (value: YardBlockBayFilterBy) => void
}

export const YardBlockBayOverviewLegend = ({ tenantConfigStore, onFilterViewChange }: Props) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Stack direction='row' spacing={2} sx={{ paddingLeft: '1rem', paddingTop: '0.3rem' }}>
        <LegendItem color={EXPORT_OCCUPANCY_BACKGROUND} label={t('Export', 'Export')} />
        <LegendItem color={IMPORT_OCCUPANCY_BACKGROUND} label={t('Import', 'Import')} />
        {!tenantConfigStore.skipYardPlanning && (
          <LegendItem
            border={`3px solid ${palette.primary.main}`}
            label={t('allocated', 'Allocated')}
          />
        )}
        {!tenantConfigStore.skipYardPlanning && (
          <LegendItem border={`3px solid ${palette.info.main}`} label={t('reserved', 'Reserved')} />
        )}
        <LegendItem
          border={`1px solid ${palette.grey[500]}`}
          label={t('OtherCarrierTypeThanVessel', 'Other carrier type than vessel')}
        />
      </Stack>
      <FormControl sx={{ minWidth: 140 }}>
        <InputLabel>{t('viewBy', 'View by')}</InputLabel>
        <Select
          label={t('viewBy', 'View by')}
          defaultValue='VesselName'
          onChange={e => onFilterViewChange(e.target.value as YardBlockBayFilterBy)}
        >
          <MenuItem value='TripId'>{t('tripId', 'Trip id')}</MenuItem>
          <MenuItem value='VesselName'>{t('vesselName', 'Vessel name')}</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  )
}

interface LegendItemProps {
  color?: string
  border?: string
  label: string
}
const LegendItem = ({ color, border, label }: LegendItemProps) => {
  return (
    <Stack flexDirection='row' alignItems='center' gap={2}>
      <Box
        sx={{
          width: 25,
          height: 25,
          border: border ?? `0.5px groove`,
          background: color,
        }}
      />
      <Typography variant='body2' whiteSpace='nowrap'>
        {label}
      </Typography>
    </Stack>
  )
}
