import { VesselServiceDto } from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { VesselServiceService } from '@storage/services/vessel-service.service'

export class VesselServiceStore extends EntityStore<VesselServiceDto> {
  constructor(private readonly _vesselServiceService: VesselServiceService) {
    super()
  }

  public async loadAll(): Promise<void> {
    const vesselServices = await this._vesselServiceService.getAll()
    this.setAll(vesselServices)
  }

  resetData() {
    this.removeAll()
  }
}
