import { Typography } from '@mui/material'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { EmptyIndicator } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  truckVisit: ITruckVisitItem
}
export const TruckVisitPlate = observer(({ truckVisit }: Props) => {
  return <Typography variant='subtitle2'>{truckVisit.data.identifier ?? EmptyIndicator}</Typography>
})
