import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { ExternalDriverResponseDto } from '@planning/app/api'
import { useUniquenessValidation } from '@planning/hooks/useIsPropertyUnique'
import { IExternalDriverItem } from '@planning/rt-stores/externalDriver/ExternalDriverItem'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ExternalDriverFormData } from '../../Stores/ExternalDriverViewStore'

const getDefaultValues = () => ({
  driverID: '',
  name: '',
  id: undefined,
  banned: 'false',
})

interface Props {
  formId?: string
  defaultValues?: ExternalDriverResponseDto
  externalDrivers: IExternalDriverItem[]
  onSubmit: (data: ExternalDriverFormData) => void
}
export const ExternalDriverForm = observer(
  ({ formId, defaultValues, externalDrivers, onSubmit }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const [isDriverIDUnique, validateDriverIDUniqueness] = useUniquenessValidation({
      id: defaultValues?.id,
      items: externalDrivers.map(x => ({ id: x.id, name: x.data.driverID })),
    })

    const [isNameUnique, validateNameUniqueness] = useUniquenessValidation({
      id: defaultValues?.id,
      items: externalDrivers.map(x => ({ id: x.id, name: x.data.name })),
    })

    const {
      control,
      reset,
      formState: { errors },
      handleSubmit,
    } = useForm<ExternalDriverFormData>({
      defaultValues: getDefaultValues(),
    })

    useEffect(() => {
      if (defaultValues) {
        reset({
          driverID: defaultValues?.driverID,
          name: defaultValues?.name,
          banned: defaultValues?.banned.toString(),
          notes: defaultValues?.notes,
          id: defaultValues.id,
        })
      } else {
        reset(getDefaultValues())
      }
    }, [defaultValues, reset])

    const getHelperText = (field: keyof ExternalDriverFormData) => {
      if (field === 'name' && !isNameUnique)
        return t('fieldAlreadyExists', '{field} already exists.', {
          field: t('driverName', 'Driver name'),
        })
      if (field === 'driverID' && !isDriverIDUnique)
        return t('fieldAlreadyExists', '{field} already exists.', {
          field: t('driverIDNumber', 'Driver ID Number'),
        })

      if (errors[field]?.message) return errors[field]?.message?.toString()

      return errors[field] ? t('fieldIsRequired', 'Field is required.') : ''
    }

    return (
      <form id={formId ?? 'external-driver-form'} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={theme.customSpacing.s}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('nameOfDriver', 'Name of driver')}
                  variant='outlined'
                  required
                  fullWidth
                  onChange={event => {
                    field.onChange(event)
                    validateNameUniqueness(event.target.value)
                  }}
                  error={!!errors.name || !isNameUnique}
                  helperText={getHelperText('name')}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Controller
              name='driverID'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('driverIDNumber', 'Driver ID number')}
                  variant='outlined'
                  required
                  fullWidth
                  onChange={event => {
                    field.onChange(event)
                    validateDriverIDUniqueness(event.target.value)
                  }}
                  error={!!errors.driverID || !isDriverIDUnique}
                  helperText={getHelperText('driverID')}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name='banned'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel sx={{ verticalAlign: 'center' }}>{t('status', 'Status')}</InputLabel>
                  <Select {...field} label={t('status', 'Status')} required fullWidth>
                    <MenuItem value='false'>{t('active', 'Active')}</MenuItem>
                    <MenuItem value='true'>{t('banned', 'Banned')}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='notes'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextField {...field} label={t('notes', 'Notes')} variant='outlined' fullWidth />
              )}
            />
          </Grid>
        </Grid>
      </form>
    )
  },
)
