import {
  CarrierVisitStatus,
  ContainerDamageStatus,
  ContainerJourneyResponseDto,
  ContainerPositionType,
  ContainerResponseDto,
  CoolingOrderResponseDto,
  EquipmentType,
  OperationType,
  OrderStatus,
  StrippingOrderResponseDto,
  StuffingOrderResponseDto,
  YardLocation,
} from '@planning/app/api'
import { ContainerJourneyStatus } from '@planning/components/molecules/ContainerJourneyStatusChip'
import { ContainerVisitStatus } from '@planning/pages/Order/components/OrdersListCard'
import { IOrderWithVisit } from '@planning/pages/Order/stores/SelectOrderViewStore'

export interface IContainerJourney extends ContainerJourneyResponseDto {
  container: ContainerResponseDto
  isDamagedContainer: boolean
  inboundOrder?: IOrderWithVisit
  inboundOrderStatus?: OrderStatus
  inboundCarrierVisitStatus?: CarrierVisitStatus
  outboundOrder?: IOrderWithVisit
  outboundOrderStatus?: OrderStatus
  outboundCarrierVisitStatus?: CarrierVisitStatus
  strippingOrder?: StrippingOrderResponseDto
  stuffingOrder?: StuffingOrderResponseDto
  coolingOrder?: CoolingOrderResponseDto
  status: ContainerVisitStatus
  journeyStatus: ContainerJourneyStatus
}

export class ContainerJourney implements IContainerJourney {
  id: number
  containerId: number
  inboundOrderId: number
  outboundOrderId?: number | null | undefined
  strippingOrderId?: number | null | undefined
  stuffingOrderId?: number | null | undefined
  coolingOrderId?: number | null | undefined
  currentPosition?: ContainerPositionType | undefined
  equipmentType?: EquipmentType | undefined
  operationType?: OperationType | undefined
  yardLocations?: YardLocation[] | null | undefined

  constructor(
    data: ContainerJourneyResponseDto,
    public container: ContainerResponseDto,
    public inboundOrder?: IOrderWithVisit,
    public outboundOrder?: IOrderWithVisit,
    public strippingOrder?: StrippingOrderResponseDto,
    public stuffingOrder?: StuffingOrderResponseDto,
    public coolingOrder?: CoolingOrderResponseDto,
  ) {
    this.id = data.id
    this.containerId = data.containerId
    this.inboundOrderId = data.inboundOrderId
    this.outboundOrderId = data.outboundOrderId
    this.strippingOrderId = data.strippingOrderId
    this.stuffingOrderId = data.stuffingOrderId
    this.coolingOrderId = data.coolingOrderId
    this.currentPosition = data.currentPosition
    this.equipmentType = data.equipmentType
    this.operationType = data.operationType
    this.yardLocations = data.yardLocations
  }

  get inboundOrderStatus() {
    return this.inboundOrder?.order?.status ?? OrderStatus.Open
  }

  get inboundCarrierVisitStatus() {
    return this.inboundOrder?.visit?.status ?? CarrierVisitStatus.Expected
  }

  get outboundOrderStatus() {
    return this.outboundOrder?.order?.status ?? OrderStatus.Open
  }

  get outboundCarrierVisitStatus() {
    return this.outboundOrder?.visit?.status ?? CarrierVisitStatus.Expected
  }

  get status() {
    if (this.outboundOrderStatus === OrderStatus.Fulfilled) return ContainerVisitStatus.Departed

    if (this.inboundOrderStatus !== OrderStatus.Fulfilled) return ContainerVisitStatus.Expected

    return ContainerVisitStatus.OnTerminal
  }

  // [REVIEW] TODO: Should be moved to the backend
  get journeyStatus(): ContainerJourneyStatus {
    if (
      this.outboundCarrierVisitStatus === CarrierVisitStatus.Departed ||
      this.outboundOrderStatus === OrderStatus.Fulfilled
    )
      return ContainerJourneyStatus.Departed

    if (this.currentPosition === ContainerPositionType.Equipment)
      return ContainerJourneyStatus.OnTerminal

    if (this.currentPosition === ContainerPositionType.Yard) return ContainerJourneyStatus.OnYard

    if (this.inboundOrderStatus === OrderStatus.Fulfilled) return ContainerJourneyStatus.OnTerminal

    if (
      this.inboundCarrierVisitStatus === CarrierVisitStatus.Arrived ||
      this.inboundCarrierVisitStatus !== CarrierVisitStatus.Expected
    )
      return ContainerJourneyStatus.Arrived

    return ContainerVisitStatus.Expected
  }

  get isDamagedContainer() {
    return this.container.damages.some(c => c.status === ContainerDamageStatus.Active)
  }
}
