import { action, makeObservable, observable } from 'mobx'

export class ContainerYardOperationViewStore {
  containerNumber?: string
  orderId?: number | null

  title?: string
  message?: string

  beforeYardPositionChangeConfirmation?: () => Promise<void>

  hasSelectedDestination = false

  isOpen = false
  isLoading = false

  constructor() {
    makeObservable(this, {
      containerNumber: observable,
      orderId: observable,
      hasSelectedDestination: observable,
      isOpen: observable,
      isLoading: observable,
      title: observable,
      message: observable,

      open: action,
      close: action,
    })
  }

  setLoading = (isLoading: boolean) => (this.isLoading = isLoading)

  setHasSelectedDestination = (hasSelectedDestination: boolean) =>
    (this.hasSelectedDestination = hasSelectedDestination)

  open(
    containerNumber: string,
    orderId: number | null,
    title?: string,
    message?: string,
    beforeYardPositionChangeConfirmation?: () => Promise<void>,
  ) {
    this.containerNumber = containerNumber
    this.orderId = orderId
    this.isOpen = true
    this.title = title
    this.message = message
    this.beforeYardPositionChangeConfirmation = beforeYardPositionChangeConfirmation
  }

  close() {
    this.containerNumber = undefined
    this.orderId = null
    this.isOpen = false
    this.title = undefined
    this.message = undefined
    this.beforeYardPositionChangeConfirmation = undefined
  }
}
