import { Box, MenuItem, Stack, Typography } from '@mui/material'
import { NNROrderHandlingBoxFooter } from '@planning/pages/GateClerk/Components/NNROrderHandlingBoxFooter'
import { OperationTypeChip } from '@planning/pages/GateClerk/Components/OperationTypeChip'
import { CustomAlert } from '@planning/pages/TallymanV2/Components/CustomAlert'
import { NonNumericOrderWithPickUpAmount } from '@planning/stores/truckAppointment/TruckAppointmentDetailsViewStore'
import { useTranslate } from '@tolgee/react'
import { CloseButton, ColoredLabel, ThreeDotMenu, useMinimalsTheme } from '@tom-ui/ui'
import { FC, useState } from 'react'

interface Props {
  nnrOrder: NonNumericOrderWithPickUpAmount
  showNoContainerAlert?: boolean
  showThreeDotMenu?: boolean
  onEditPickUpAmount?: (nnrOrder: NonNumericOrderWithPickUpAmount) => void
  onRemove?: (nnrOrder: NonNumericOrderWithPickUpAmount) => void
}

export const NNROrderListItem: FC<Props> = ({
  nnrOrder,
  showThreeDotMenu,
  showNoContainerAlert,
  onEditPickUpAmount,
  onRemove,
}) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const [isEditingPickUpAmount, setEditingPickUpAmount] = useState<boolean>(!nnrOrder.pickUpAmount)

  return (
    <Box display='flex' flexDirection='column'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '1rem',
        }}
      >
        <Box>
          <Typography variant='subtitle1'>{nnrOrder.referenceNumber}</Typography>
          <Typography variant='body2'>
            {nnrOrder.pickUpAmount}x {t('nonNumericContainers', 'non-numeric containers')}
          </Typography>
          <OperationTypeChip direction={'Outbound'} />

          {nnrOrder?.suggestedContainers?.length && (
            <Box mt={theme.customSpacing.m}>
              <Typography variant='subtitle1'>
                {t('suggestedContainers', 'Suggested containers ')}
              </Typography>
              <Stack mt={theme.customSpacing.m} gap={theme.customSpacing.s}>
                {nnrOrder?.suggestedContainers?.map(container => (
                  <Stack
                    direction='row'
                    key={`${nnrOrder.id}_${container.containerId}`}
                    justifyContent='space-between'
                    gap={theme.customSpacing.s}
                  >
                    <Typography>{container.containerNumber}</Typography>
                    <ColoredLabel color='success' label={container.currentPosition} />
                  </Stack>
                ))}
              </Stack>
            </Box>
          )}

          {showNoContainerAlert && (
            <CustomAlert
              message={t(
                'noContainerHasBeenAssignedContactControlRoom',
                'No container has been assigned, contact control room',
              )}
              severity='warning'
              margin='0.5rem 0 0 0'
            />
          )}
        </Box>

        {!!onRemove && !showThreeDotMenu && (
          <CloseButton onClose={() => onRemove(nnrOrder)} tooltip={t('remove', 'Remove')} />
        )}
        {showThreeDotMenu && (
          <ThreeDotMenu>
            {nnrOrder.pickUpAmount && onEditPickUpAmount ? (
              <MenuItem onClick={() => setEditingPickUpAmount(true)}>
                {t('editPickUpAmount', 'Edit Pick Up Amount')}
              </MenuItem>
            ) : (
              <div></div>
            )}
            {onRemove ? (
              <MenuItem onClick={() => onRemove(nnrOrder)}>{t('remove', 'Remove')}</MenuItem>
            ) : (
              <></>
            )}
          </ThreeDotMenu>
        )}
      </Box>
      {isEditingPickUpAmount && (
        <NNROrderHandlingBoxFooter
          order={nnrOrder}
          onClickAdd={nnr => {
            onEditPickUpAmount?.(nnr)
            setEditingPickUpAmount(false)
          }}
        />
      )}
    </Box>
  )
}
