import { CargoType, CarrierType } from '@planning/app/api'
import { PaginatedLocalStore } from '@planning/stores/PaginatedLocalStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { VisitItem } from './VisitViewStore'

export type SummaryCount = {
  containerInCount: number
  containerOutCount: number
  //TOLATER: ask UX?
  // breakbulkInTon: number
  // breakbulkOutTon: number
  breakbulkInPcs: number
  breakbulkOutPcs: number
}

export type CarrierTypeCount = {
  all: number
  vessel: number
  train: number
}

export class VisitTableViewStore {
  carrierType?: CarrierType

  constructor(private visitListViewStore: PaginatedLocalStore<VisitItem>) {
    makeObservable(this, {
      carrierType: observable,

      setCarrierType: action,

      summaryCount: computed,
      carrierTypeCount: computed,
      visitItems: computed,
    })
  }

  setCarrierType(carrierType?: CarrierType) {
    if (this.carrierType !== carrierType) {
      this.carrierType = carrierType
      this.visitListViewStore.setCurrentPageIndex(0)
    }
  }

  get visitItems() {
    return this.visitListViewStore.filteredItems.filter(
      x => !this.carrierType || x.carrierType === this.carrierType,
    )
  }

  get summaryCount(): SummaryCount | undefined {
    if (!this.visitItems?.length)
      return {
        containerInCount: 0,
        containerOutCount: 0,
        breakbulkInPcs: 0,
        breakbulkOutPcs: 0,
      }

    const containerVisits = this.visitItems.filter(i => i?.cargoType === CargoType.Container)
    const generalCargoVisits = this.visitItems.filter(i => i?.cargoType === CargoType.GeneralCargo)

    return {
      containerInCount: _.sumBy(containerVisits, 'dischargeCount'),
      containerOutCount: _.sumBy(containerVisits, 'loadCount'),
      breakbulkInPcs: _.sumBy(generalCargoVisits, 'dischargeCount'),
      breakbulkOutPcs: _.sumBy(generalCargoVisits, 'loadCount'),
    }
  }

  get carrierTypeCount(): CarrierTypeCount {
    const filteredItems = this.visitListViewStore.filteredItems
    let train = 0
    let vessel = 0

    filteredItems.forEach(item => {
      if (item.carrierType === CarrierType.Train) {
        train++
      } else if (item.carrierType === CarrierType.Vessel) {
        vessel++
      }
    })

    return {
      all: filteredItems.length,
      train: train,
      vessel: vessel,
    }
  }
}
