import { Grid, Typography } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { useOperationsStore } from '@operations/AppProvider'
import {
  JobGroupDto,
  JobGroupType,
} from '@operations/features/equipmentOperator/models/job-group.model'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { JobCard } from '../JobCard/JobCard'
import { JobCardMinimalistic } from '../MinimalisticCard/JobCardMinimalistic'

interface Props {
  jobGroups: JobGroupDto[]
  highlightText?: string
}

export const JobList = observer(({ jobGroups, highlightText }: Props) => {
  const { t } = useTranslate()

  const { appStore, equipmentOperatorUIStore, equipmentOperatorStepperUIStore, tenantConfigStore } =
    useOperationsStore()

  const { closeSnackbar } = useSnackbar()

  const handleConfirm = async (job: JobDto) => {
    appStore.forceLoader()
    closeSnackbar()
    appStore.unforceLoader()

    if (equipmentOperatorStepperUIStore.canSkipStepper(job)) {
      equipmentOperatorUIStore.hideJob(job.workInstructionId)

      appStore.showAlertForCancellableRequest(
        'success',
        `${job.cargoUnit!.displayName} ${t('isBeingConfirmed', 'is being confirmed')}`,
        async () => {
          await equipmentOperatorStepperUIStore
            .finishJobByWorkInstructionId(job.workInstructionId)
            .catch(() => equipmentOperatorUIStore.showJob(job.workInstructionId))
          equipmentOperatorUIStore.closeSearchIfFinishingLastNotification(job.workInstructionId)
        },
        undefined,
        () => {
          equipmentOperatorUIStore.showJob(job.workInstructionId)
        },
      )
    } else {
      await equipmentOperatorStepperUIStore.openStepper(job)
      await equipmentOperatorStepperUIStore.startJob(job)
    }
  }

  const getGroupDisplayName = (group: JobGroupDto) => {
    if (group.type === JobGroupType.vesselBay) return `${t('bay', 'Bay')} ${group.groupName}`

    return group.groupName !== ''
      ? group.groupName
      : t('ungroupedContainers', 'Ungrouped containers')
  }

  return (
    <>
      {jobGroups.map((group, index) => (
        <Grid
          key={`${group.groupName}_${index}`}
          container
          gap={theme => theme.customSpacing.m}
          marginBottom={theme => theme.customSpacing.xll}
        >
          {(jobGroups.length > 1 || group.groupName !== '') && (
            <Grid item xs={12}>
              <Typography variant='h3'>{getGroupDisplayName(group)}</Typography>
            </Grid>
          )}

          {group.jobs.map(job => (
            <Grid key={`${job.workInstructionId}`} item xs={12}>
              {equipmentOperatorUIStore.isMinimalisticJobView ? (
                <JobCardMinimalistic
                  job={job}
                  isConfirm={!!equipmentOperatorStepperUIStore.canSkipStepper(job)}
                  highlightText={highlightText}
                  currentEquipment={equipmentOperatorUIStore.equipment}
                  onConfirm={handleConfirm}
                  showOutboundInfo={tenantConfigStore.skipYardPlanning}
                />
              ) : (
                <JobCard
                  job={job}
                  isConfirm={!!equipmentOperatorStepperUIStore.canSkipStepper(job)}
                  currentEquipmentType={equipmentOperatorUIStore.typeOfSelectedEquipment}
                  onConfirm={handleConfirm}
                  highlightText={highlightText}
                  fieldsToShow={tenantConfigStore.getOperatorFieldsByEquipmentAndOperationTypeAndCarrierType(
                    equipmentOperatorUIStore.typeOfSelectedEquipment,
                    job.operationType,
                    job.carrierVisit?.type,
                  )}
                />
              )}
            </Grid>
          ))}
        </Grid>
      ))}
    </>
  )
})
