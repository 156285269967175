//This function prevents users from using copy-paste to input invalid values, as onKeyDown doesn't handle this case
export const onlyAllowPositiveInt = (
  event: React.ChangeEvent<HTMLInputElement>,
  allowZero = true,
) => {
  let value: number | string = Math.floor(Number(event.target.value))
  if (value < 0) value = -value
  if (value === 0 && !allowZero) value = ''

  event.target.value = value.toString()
}

export const notAllowPlusMinusPointSign = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event?.key === '-' || event?.key === '+' || event?.key === ',' || event?.key === '.') {
    event.preventDefault()
  }
}

export const notAllowPlusMinusSign = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event?.key === '-' || event?.key === '+') {
    event.preventDefault()
  }
}
