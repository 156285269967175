import { Chip } from '@mui/material'
import { ContainerType } from '@planning/app/api'
import { useContainerAttributesParameters } from '@tom-ui/ui'

interface Props {
  containerType: ContainerType
}

export const ContainerTypeChip = ({ containerType }: Props) => {
  const { types: containerTypeParameters } = useContainerAttributesParameters()
  const { label, icon } = containerTypeParameters[containerType]

  return (
    <Chip
      label={label}
      icon={icon}
      variant='filled'
      sx={{
        marginBottom: '0.25rem',
        pl: '.5rem',
        '& .MuiChip-label': {
          pl: '.25rem',
        },
      }}
    />
  )
}
