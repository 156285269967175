import { Box } from '@mui/material'
import { IContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourney'
import { ContainerYardOperationViewStore } from '@planning/pages/VesselVisit/Components'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { AnnouncedContainerVisitsAccordion } from './AnnouncedContainerVisitsAccordion'
import { ContainerVisitsCardBody } from './ContainerVisitsCardBody'
import { ContainerVisitsCardHeader } from './ContainerVisitsCardHeader'

type Props = {
  containerVisit: IContainerJourney
  announcedVisits: IContainerJourney[]
  onClickEdit?: (journey: IContainerJourney) => void
  isInsideIssueCard?: boolean
  containerYardOperationViewStore?: ContainerYardOperationViewStore
}

export const ContainerVisitsCard: FC<Props> = observer(
  ({
    containerVisit,
    announcedVisits,
    onClickEdit,
    isInsideIssueCard,
    containerYardOperationViewStore,
  }) => {
    const theme = useMinimalsTheme()

    const inbound = containerVisit?.inboundOrder
    const outbound = containerVisit?.outboundOrder
    const order = (inbound ?? outbound)?.order

    if (!order || !containerVisit) return <></>

    return (
      <Box
        sx={
          isInsideIssueCard
            ? {
                borderTop: `1px solid ${theme.palette.grey[300]}`,
              }
            : {
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme.customRadius.medium,
                mt: '1rem',
              }
        }
      >
        <Box
          sx={{
            bgcolor: isInsideIssueCard ? theme.palette.grey[100] : undefined,
          }}
        >
          <ContainerVisitsCardHeader
            container={containerVisit.container}
            isDamaged={containerVisit.isDamagedContainer}
          />
        </Box>

        <Box
          sx={{
            bgcolor: isInsideIssueCard ? undefined : theme.palette.grey[100],
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <ContainerVisitsCardBody
            containerVisit={containerVisit}
            isInsideIssueCard={isInsideIssueCard}
            onClickEdit={onClickEdit}
            containerYardOperationViewStore={containerYardOperationViewStore}
          />

          {announcedVisits.length > 0 && (
            <AnnouncedContainerVisitsAccordion
              isInsideIssueCard={isInsideIssueCard}
              visits={announcedVisits}
              onClickEdit={onClickEdit}
            />
          )}
        </Box>
      </Box>
    )
  },
)
