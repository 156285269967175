import { IsoCodeMappingResponseDto } from '@admin/app/api'
import { useTranslate } from '@tolgee/react'
import { CardWithAttributes, useContainerAttributesParameters } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CardAttributesProps } from 'modules/ui/src/theme/components/card/CardAttributes'

interface IsoCodeMapping extends IsoCodeMappingResponseDto {
  isoCodeMappingType?: 'Standard' | 'Custom'
}
interface Props {
  isoCodeMapping: IsoCodeMapping
  filter?: string
  onClickEdit?: (id: number) => void
}

export const IsoCodeMappingCard = observer((props: Props) => {
  const { t } = useTranslate()
  const { types: containerTypeParameters, heights: containerHeightParameters } =
    useContainerAttributesParameters()

  const { filter, isoCodeMapping, onClickEdit } = props
  const { id, isoCode, length, height, type, archetype, isoCodeMappingType } = isoCodeMapping

  const attributes: CardAttributesProps[] = [
    {
      label: t('length', 'Length'),
      value: length ?? t('unknown', 'Unknown'),
    },
    {
      label: t('height', 'Height'),
      value: containerHeightParameters[height].label ?? t('unknown', 'Unknown'),
    },
    {
      label: t('type', 'Type'),
      value: containerTypeParameters[type].label ?? t('unknown', 'Unknown'),
    },
    {
      label: t('archetype', 'Archetype'),
      value: archetype ?? t('notAssigned', 'Not Assigned'),
    },
  ]

  const isStandard = isoCodeMappingType === 'Standard'

  const chipLabel = isStandard ? t('standard', 'Standard') : t('custom', 'Custom')
  const chipColor = isStandard ? 'warning' : 'info'

  return (
    <CardWithAttributes
      title={isoCode.toUpperCase()}
      highlightedText={filter}
      attributes={attributes}
      chipLabel={chipLabel}
      chipColor={chipColor}
      onClick={() => onClickEdit?.(id)}
    />
  )
})
