import {
  Autocomplete,
  Box,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ExternalDriverResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, HighlightedText, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { DriverAutoCompleteStore } from './DriverAutoCompleteStore'

interface Props {
  label: string
  selectedDriver?: ExternalDriverResponseDto | null
  disabled?: boolean
  required?: boolean
  helperText?: string
  error?: boolean
  searchBy?: 'name' | 'driverID'
  onChange: (driver: ExternalDriverResponseDto | undefined | null) => void
}
export const DriverAutoComplete = observer(
  ({
    label,
    selectedDriver,
    disabled,
    required,
    helperText,
    error,
    searchBy = 'name',
    onChange,
  }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const { externalDriverViewStore } = usePlanningStore()

    const store = useMemo(() => new DriverAutoCompleteStore(), [])

    useEffect(() => {
      store.setFilteringByName(searchBy === 'name')
    }, [store, searchBy])

    const [inputValue, setInputValue] = useState<string>('')

    return (
      <Autocomplete
        disablePortal
        disabled={disabled}
        getOptionLabel={(driver: ExternalDriverResponseDto) =>
          (searchBy === 'name' ? driver.name : driver.driverID) ?? t('unknown', 'Unknown')
        }
        options={store.options}
        defaultValue={selectedDriver ?? null}
        value={selectedDriver ?? null}
        isOptionEqualToValue={(
          option: ExternalDriverResponseDto,
          value: ExternalDriverResponseDto,
        ) => option.id === value.id}
        onChange={(_, selectedDriver: ExternalDriverResponseDto | null) => {
          onChange(selectedDriver)
        }}
        getOptionDisabled={option => option.banned}
        renderOption={(props, option) => (
          <Box component='li' {...props} key={`location_${option.id}`}>
            <Stack
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              gap={1}
              width='100%'
            >
              <Stack>
                <Typography variant='body1'>
                  <HighlightedText highlight={inputValue} color={theme.palette.info.main}>
                    {searchBy === 'name' ? option.name : option.driverID}
                  </HighlightedText>
                </Typography>
                <Typography variant='caption' color='secondary'>
                  {searchBy === 'name' ? option.driverID : option.name}
                </Typography>
              </Stack>
              {option.banned && <ColoredLabel color='warning' label={t('banned', 'Banned')} />}
            </Stack>
          </Box>
        )}
        noOptionsText={
          inputValue ? (
            <MenuItem
              onClick={() => {
                externalDriverViewStore.toggleAddDialog(true, externalDriver => {
                  onChange(externalDriver)
                })
              }}
            >
              <ListItemText>{t('addNew', 'Add new')}</ListItemText>
              <PlusIcon />
            </MenuItem>
          ) : undefined
        }
        renderInput={params => (
          <TextField
            {...params}
            required={required}
            label={<>{label ?? t('truckDriver', 'Truck driver')} </>}
            data-cy='external-driver-autocomplete'
            helperText={helperText}
            error={error}
            onChange={e => {
              store.filterOptions(e.target.value)
              setInputValue(e.target.value)
            }}
          />
        )}
      />
    )
  },
)
