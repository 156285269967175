import { Warning } from '@mui/icons-material'
import { Badge } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { useOperationsStore } from '@tom-ui/operations'
import { usePlanningStore } from '@tom-ui/planning'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

export const IssueMenuIcon = observer(() => {
  const { issueItemStore } = usePlanningStore()
  const { issueUIStore } = useOperationsStore()

  useEffect(() => {
    issueItemStore.fetch()
  }, [issueItemStore])

  const visitDepartedWithUnfinishedJobsIssue = useBooleanFlagValue(
    'visit-departed-with-unfinished-jobs-issue',
    false,
  )

  useEffect(() => {
    if (visitDepartedWithUnfinishedJobsIssue) issueUIStore.fetch()
  }, [issueUIStore, visitDepartedWithUnfinishedJobsIssue])

  const totalIssueCount = useMemo(
    () =>
      issueItemStore.notResolvedItemsWithObjectTypeOrder.length +
      issueItemStore.notResolvedItemsWithObjectTypeContainer.length +
      issueUIStore.unresolvedIssues.length,
    [
      issueItemStore.notResolvedItemsWithObjectTypeOrder.length,
      issueItemStore.notResolvedItemsWithObjectTypeContainer.length,
      issueUIStore.unresolvedIssues.length,
    ],
  )

  return (
    <Badge badgeContent={totalIssueCount} color='warning'>
      <Warning />
    </Badge>
  )
})
