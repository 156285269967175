import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'

interface AutoCompleteWithChipsOptions {
  label: string
  options: string[]
  selectedValues: string[]
  freeSolo?: boolean
  chipSeparators?: string[]
  required?: boolean
  error?: boolean
  helperText?: string
  onChange: (values: string[]) => void
}

export const AutoCompleteWithChips = observer(
  ({
    label,
    options,
    selectedValues,
    chipSeparators,
    freeSolo = false,
    required = false,
    error = false,
    helperText = '',
    onChange,
  }: AutoCompleteWithChipsOptions) => {
    const setUniqueValues = useCallback(
      (newValues: string[]) => {
        const uniqueValues = new Set([
          ...selectedValues.map(v => v.toUpperCase()),
          ...newValues
            .map(n => n.trim())
            .filter(n => n)
            .map(n => n.toUpperCase()),
        ])
        onChange(Array.from(uniqueValues))
      },
      [onChange, selectedValues],
    )

    return (
      <Autocomplete
        multiple
        clearOnBlur
        freeSolo={freeSolo}
        disableCloseOnSelect
        ListboxComponent={List}
        options={options}
        getOptionLabel={height => height}
        renderOption={(props, value) => (
          <ListItem {...props}>
            <ListItemIcon>
              <Checkbox edge='start' checked={selectedValues.includes(value)} disableRipple />
            </ListItemIcon>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='body2'>{value}</Typography>
            </Box>
          </ListItem>
        )}
        value={selectedValues}
        renderTags={value => (
          <>
            {value
              .sort((a, b) => a.localeCompare(b))
              .map(option => (
                <Chip
                  sx={{
                    margin: '2px',
                  }}
                  key={`option-${option}`}
                  label={option}
                  onDelete={() => onChange(value.filter(v => v !== option))}
                />
              ))}
          </>
        )}
        onInputChange={(event: any, newInputValue) => {
          chipSeparators?.forEach(separator => {
            if (newInputValue.endsWith(separator)) {
              const newValue = newInputValue.slice(0, -1)
              if (newValue) {
                setUniqueValues([newValue])
                event.target.blur()
                event.target.focus()
              }
            }
          })
        }}
        onChange={(_, values) => {
          onChange(values)
        }}
        onKeyDown={e => {
          e.key === 'Enter' && e.preventDefault()
        }}
        onPaste={event => {
          event.stopPropagation()
          event.preventDefault()
          const clipboardData = event.clipboardData.getData('Text')
          const clipboardValues = chipSeparators
            ? clipboardData.split(new RegExp(`[${chipSeparators.join('')}]`))
            : [clipboardData]
          setUniqueValues(clipboardValues)
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={
              <>
                {label} {required && <span style={{ color: 'red' }}>*</span>}
              </>
            }
            error={error}
            helperText={helperText}
          />
        )}
      />
    )
  },
)
