import {
  CreateWeightClassDto,
  WeightClassDto,
  WeightClassesApi,
  WeightClassesApiUpsertWeightClassSetsRequest,
  WeightClassSetResponseDto,
} from '@storage/app/api'

export class WeightClassService {
  constructor(private api: WeightClassesApi) {}

  async getAll(): Promise<Array<WeightClassDto>> {
    const { data: weightClasses } = await this.api.getAll()
    return weightClasses
  }

  async create(createWeightClassDto: Array<CreateWeightClassDto>): Promise<void> {
    await this.api.create({
      createWeightClassDto,
    })
  }

  async getAllWeightClassSets(): Promise<Array<WeightClassSetResponseDto>> {
    const { data: weightClasses } = await this.api.getAllWeightClassSets()
    return weightClasses
  }

  async upsertWeightClassSets(cmd: WeightClassesApiUpsertWeightClassSetsRequest): Promise<void> {
    await this.api.upsertWeightClassSets(cmd)
  }
}
