import { AxiosError } from 'axios'
import { ErrorCode } from './error-code-enum'

export const isDomainModelException = (error: AxiosError, errorCode: ErrorCode): boolean => {
  if (error.response?.status !== 400) {
    return false
  }
  const errorResponseData = error.response?.data as any

  return errorResponseData?.errorCode === errorCode
}

export const getDomainModelExceptionPayload = (error: AxiosError, errorCode: ErrorCode) => {
  if (isDomainModelException(error, errorCode)) {
    const errorResponseData = error.response?.data as any
    return errorResponseData?.payload
  }
}
