import { CarrierVisitStatus, TruckVisitDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetTruckVisitsByIdsQuery } from '@planning/messages/queries'
import { GetTruckVisitsQuery } from '@planning/messages/queries/getTruckVisitsQueryHandler'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import { ItemStore } from '../base/ItemStore'
import { OrderItemStore } from '../order/OrderItemStore'
import { TruckItemStore } from '../truck/TruckItemStore'
import { IDateRange, IEntityStore } from '../types'
import { ITruckVisitItem, TruckVisitItem } from './TruckVisitItem'

export interface ITruckVisitStore extends IEntityStore<TruckVisitItem> {
  isLoading: boolean
  loadedDateRange: IDateRange
  fetch: (query: IDateRange) => Promise<void>
}

export class TruckVisitItemStore extends ItemStore<TruckVisitDto, ITruckVisitItem> {
  private orderItemStore?: OrderItemStore
  private truckStore?: TruckItemStore
  private fetchedDateRange: Set<string> = new Set()

  sorting?: string
  from?: Date
  to?: Date
  filter?: string
  filterBy: 'eta' | 'ata' | 'atd' = 'eta'

  constructor(private messageBus: IMessageBus) {
    super((key, data) => new TruckVisitItem(key, data, this, this.truckStore), {
      messageBus,
      bulkFetchFunc: (ids: number[]) => new GetTruckVisitsByIdsQuery(ids),
    })
    makeObservable(this, {
      sortedTruckVisits: computed,
      ordersByTruckVisitId: computed,
      truckVisitIds: computed,

      expectedTruckVisits: computed,
      expectedTruckVisitsInDateRange: computed,
      filteredExpectedTruckVisitsInDateRange: computed,
      expectedTruckVisitsForToday: computed,

      arrivedTruckVisits: computed,
      arrivedTruckVisitsInDateRange: computed,
      filteredArrivedTruckVisits: computed,

      departedTruckVisits: computed,
      departedTruckVisitsInDateRange: computed,
      filteredDepartedTruckVisitsInDateRange: computed,
      departedTruckVisitsForToday: computed,

      receiveTruckVisitsMessage: action,
      receiveTruckVisitUpsertedEventMessage: action,
      receiveTruckVisitDeletedEventMessage: action,
      setSorting: action,
      setDateRange: action,
      setFilter: action,
      setFilterBy: action,

      sorting: observable,
      from: observable,
      to: observable,
      filter: observable,
      filterBy: observable,
    })

    messageBus.subscribeEvent(GetTruckVisitsQuery.type, this.receiveTruckVisitsMessage)
    messageBus.subscribeEvent(GetTruckVisitsByIdsQuery.type, this.receiveTruckVisitsMessage)
    messageBus.subscribeEvent(
      EventTypes.TruckVisitUpsertedEvent,
      this.receiveTruckVisitUpsertedEventMessage,
    )
    messageBus.subscribeEvent(
      EventTypes.TruckVisitDeletedEvent,
      this.receiveTruckVisitDeletedEventMessage,
    )
  }

  connect = (orderItemStore: OrderItemStore, truckStore: TruckItemStore) => {
    this.orderItemStore = orderItemStore
    this.truckStore = truckStore
  }

  fetch = (query: GetTruckVisitsQuery) => {
    const key = query.getKey()
    if (this.fetchedDateRange.has(key)) return

    this.fetchedDateRange.add(key)

    this.messageBus.dispatchQuery(query)
  }

  get truckVisitIds() {
    return _.values(this.elements).map(i => i.id)
  }

  get ordersByTruckVisitId() {
    return this.orderItemStore?.ordersByCarrierVisitId
  }

  get sortedTruckVisits() {
    let truckVisits = _.values(this.elements)

    truckVisits = truckVisits.sort((a: any, b: any) => {
      const dateA = a.data?.[this.filterBy] ? new Date(a.data[this.filterBy]).getTime() : 0
      const dateB = b.data?.[this.filterBy] ? new Date(b.data[this.filterBy]).getTime() : 0

      return this.sorting === 'new' ? dateB - dateA : dateA - dateB
    })

    return truckVisits
  }

  get expectedTruckVisits() {
    return this.sortedTruckVisits.filter(v => v.data.status === CarrierVisitStatus.Expected)
  }

  get expectedTruckVisitsInDateRange() {
    return this.expectedTruckVisits.filter(
      v => !this.from || !this.to || timeIsBetweenFromTo(this.from, this.to, v.data.eta),
    )
  }

  get expectedTruckVisitsForToday() {
    return this.expectedTruckVisits
      .filter(v =>
        timeIsBetweenFromTo(
          moment().startOf('d').toDate(),
          moment().endOf('d').toDate(),
          v.data.eta,
        ),
      )
      .filter(v => filterByLicensePlate(v, this.filter))
  }

  get filteredExpectedTruckVisitsInDateRange() {
    return this.expectedTruckVisitsInDateRange.filter(v => filterByLicensePlate(v, this.filter))
  }

  get arrivedTruckVisits() {
    return this.sortedTruckVisits.filter(v => v.data.status === CarrierVisitStatus.Arrived)
  }

  get arrivedTruckVisitsInDateRange() {
    return this.arrivedTruckVisits.filter(
      v => !this.from || !this.to || timeIsBetweenFromTo(this.from, this.to, v.data.ata),
    )
  }

  get filteredArrivedTruckVisits() {
    return this.arrivedTruckVisitsInDateRange.filter(v => filterByLicensePlate(v, this.filter))
  }

  get departedTruckVisits() {
    return this.sortedTruckVisits.filter(v => v.data.status === CarrierVisitStatus.Departed)
  }

  get departedTruckVisitsForToday() {
    return this.departedTruckVisits
      .filter(v =>
        timeIsBetweenFromTo(
          moment().startOf('d').toDate(),
          moment().endOf('d').toDate(),
          v.data.atd,
        ),
      )
      .filter(v => filterByLicensePlate(v, this.filter))
  }

  get departedTruckVisitsInDateRange() {
    return this.departedTruckVisits.filter(
      v => !this.from || !this.to || timeIsBetweenFromTo(this.from, this.to, v.data.atd),
    )
  }

  get filteredDepartedTruckVisitsInDateRange() {
    return this.departedTruckVisitsInDateRange.filter(v => filterByLicensePlate(v, this.filter))
  }

  setSorting(sorting: string) {
    this.sorting = sorting
  }

  setDateRange(from?: Date, to?: Date) {
    this.from = from
    this.to = to
  }

  setFilter(filter?: string) {
    this.filter = filter
  }

  setFilterBy(filterBy: 'eta' | 'ata' | 'atd') {
    this.filterBy = filterBy
  }

  receiveTruckVisitsMessage = (event: IEvent<TruckVisitDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveTruckVisitUpsertedEventMessage = (event: IEvent<TruckVisitDto>) => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveTruckVisitDeletedEventMessage = (event: IEvent<number>) => {
    if (event.payload) {
      const truckVisit = _.values(this.elements).find(x => x.data.id === event.payload)
      if (truckVisit) {
        this.upsert({ ...truckVisit.data, isCancelled: true })
      }
    }
  }

  handleFilterChange = (value: any) => {
    const includeCompleted = true
    const isDescending = false

    const orderBy: 'eta' | 'ata' | 'atd' = 'ata'

    const todayStartDate = moment().startOf('d')
    const todayEndDate = moment().endOf('d')

    const sevenDaysAgoStartDate = moment().add(-7, 'd').startOf('d')
    const sevenDaysAgoEndDate = moment().endOf('d')
    const nextSevenDaysStartDate = moment().startOf('d')
    const nextSevenDaysEndDate = moment().add(7, 'd').endOf('d')

    let filterBy: 'eta' | 'ata' | 'atd' = 'ata'

    if (['expectedToday', 'expectedLast7Days', 'expectedNext7Days'].includes(value)) {
      filterBy = 'eta'
    } else if (['departedToday', 'departedLast7Days'].includes(value)) {
      filterBy = 'atd'
    }

    this.setFilterBy(filterBy)

    let startDate: Date | undefined
    let endDate: Date | undefined

    if (['expectedToday', 'departedToday'].includes(value)) {
      startDate = todayStartDate.toDate()
      endDate = todayEndDate.toDate()
    } else if (value === 'onTerminal') {
      startDate = undefined
      endDate = undefined
    } else if (['expectedLast7Days', 'departedLast7Days'].includes(value)) {
      startDate = sevenDaysAgoStartDate.toDate()
      endDate = sevenDaysAgoEndDate.toDate()
    } else if (value === 'expectedNext7Days') {
      startDate = nextSevenDaysStartDate.toDate()
      endDate = nextSevenDaysEndDate.toDate()
    }

    this.setDateRange(startDate, endDate)
    this.fetch(
      new GetTruckVisitsQuery(
        startDate,
        endDate,
        includeCompleted,
        orderBy,
        isDescending,
        filterBy,
      ),
    )
  }
}

const timeIsBetweenFromTo = (from: Date, to: Date, date?: string | null) => {
  return moment(date).isBetween(moment(from), moment(to), 'm', '[]')
}

const filterByLicensePlate = (truckVisit: ITruckVisitItem, filter?: string) => {
  return filter
    ? truckVisit.truck?.data.licensePlate.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    : true
}
