import { MenuItem, Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { LocationCodeDto } from '@planning/app/api'
import { PaginatedTable } from '@planning/components/PaginatedTable'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, ColorSchema, ThreeDotMenu, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  store: IPaginatedStoreWithItems<LocationCodeDto>
  onEdit: (item: LocationCodeDto) => void
}

export const LocationCodesList = observer(({ store, onEdit }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const columns: GridColDef<LocationCodeDto>[] = [
    {
      field: 'code',
      headerName: t('code', 'Code'),
      flex: 1,
    },
    {
      field: 'name',
      headerName: t('name', 'Name'),
      flex: 1,
    },
    {
      field: 'country',
      headerName: t('country', 'Country'),
      flex: 1,
    },
    {
      field: 'isActive',
      headerName: '',
      flex: 1,
      renderCell: ({ row }) => (
        <ColoredLabel
          color={(row.isActive ? 'info' : 'warning') as ColorSchema}
          label={`${row.isActive ? t('active', 'Active') : t('inactive', 'Inactive')}`}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <Stack alignItems='end' width='100%'>
          <ThreeDotMenu>
            <MenuItem onClick={() => onEdit(row)}>{t('edit', 'Edit')}</MenuItem>
          </ThreeDotMenu>
        </Stack>
      ),
    },
  ]

  return (
    <PaginatedTable
      store={store}
      columns={columns}
      getRowId={row => row.code}
      rowsPerPageOptions={[10, 25, 50]}
      onCellClick={(column, event) => {
        if (column.field === 'actions') {
          event.stopPropagation()
        }
        event.preventDefault()
      }}
      onRowClick={({ row }) => {
        onEdit(row)
      }}
    />
  )
})
