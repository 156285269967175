import { Box } from '@mui/material'
import { ContainerTurnoverDto, YardPositionDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { UpdateContainerPositionForm } from '@storage/pages/yard-management/components/UpdateContainerPositionForm'
import { AppAlert } from '@storage/stores/alert.store'
import { Vector2d } from 'konva/lib/types'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Group, Layer, Stage, Text } from 'react-konva'
import { YardBlockBayOverviewLegend } from './YardBlockBayOverviewLegend'
import { YardBlockBayOverviewSlotItem } from './YardBlockBayOverviewSlotItem'
import { YardBlockBayFilterBy, YardBlockBayOverviewItem } from './models'

interface Props {
  bayDetailsMatrix: YardBlockBayOverviewItem[][]
  selectedTurnover?: ContainerTurnoverDto
  onMenuAction: (action: string, turnover: ContainerTurnoverDto) => void
  onUpdatePosition: (location: YardPositionDto) => void
  isLoading: boolean
  validationAlerts?: AppAlert[]
}

const style = {
  slotWidth: 120,
  headerWidth: 50,
  spacing: 5,
  offset: 10,
}

export const YardBlockBayOverview = observer(
  ({
    bayDetailsMatrix,
    selectedTurnover,
    onMenuAction,
    onUpdatePosition,
    isLoading,
    validationAlerts,
  }: Props) => {
    const { tenantConfigStore, alertStore } = useStores()

    const [isUpdatePositionDialogOpen, setIsUpdatePositionDialogOpen] = useState(false)
    const [filterviewBy, setFilterViewBy] = useState<YardBlockBayFilterBy>('VesselName')
    const [position, setPosition] = useState<YardPositionDto | undefined>(undefined)

    const headerItem = (index: Vector2d, text: string, pos: Vector2d, isVertical: boolean) => {
      return (
        <Text
          key={index.x.toString() + ',' + index.y.toString()}
          x={pos.x}
          y={pos.y}
          text={text}
          width={isVertical ? style.headerWidth : style.slotWidth}
          height={isVertical ? style.slotWidth : style.headerWidth}
          align='center'
          verticalAlign='middle'
          fontSize={16}
          fontStyle='bold'
        />
      )
    }

    const getPos = (index: Vector2d, isHeader: boolean, isVertical: boolean): Vector2d => {
      if (isHeader) {
        return isVertical
          ? {
              x: style.offset + 8,
              y:
                style.offset +
                (style.slotWidth + style.spacing) * index.y +
                style.headerWidth +
                style.spacing,
            }
          : {
              x:
                style.offset +
                (style.slotWidth + style.spacing) * index.x +
                style.headerWidth +
                style.spacing,
              y: style.offset,
            }
      } else {
        const itemOffset = style.offset + style.spacing + style.headerWidth
        return {
          x: itemOffset + (style.slotWidth + style.spacing) * index.x,
          y: itemOffset + (style.slotWidth + style.spacing) * index.y,
        }
      }
    }

    const stageExtents = (): Vector2d => {
      if (bayDetailsMatrix.length > 0)
        return {
          x: (bayDetailsMatrix[0].length + 1) * (style.slotWidth + style.spacing),
          y: (bayDetailsMatrix.length + 1) * style.slotWidth - 20,
        }
      else return { x: 800, y: 600 }
    }

    return (
      <Box>
        <YardBlockBayOverviewLegend
          tenantConfigStore={tenantConfigStore}
          onFilterViewChange={setFilterViewBy}
        />

        <Stage width={stageExtents().x} height={stageExtents().y} scaleX={1} scaleY={1}>
          <Layer>
            <Group>
              {bayDetailsMatrix.length > 0 &&
                bayDetailsMatrix[0].map((item, index) =>
                  headerItem(
                    { x: index, y: 0 },
                    item.row.name,
                    getPos({ x: index, y: 0 }, true, false),
                    false,
                  ),
                )}
              {bayDetailsMatrix.length > 0 &&
                Array.from(Array(bayDetailsMatrix.length).keys())
                  .sort((a, b) => b - a)
                  .map((tier, index) =>
                    headerItem(
                      { x: 0, y: tier },
                      (tier + 1).toString(),
                      getPos({ x: 0, y: index }, true, true),
                      true,
                    ),
                  )}
              {bayDetailsMatrix.map((tier, tierIndex) =>
                tier.map((slot, rowIndex) => {
                  if (tenantConfigStore.skipYardPlanning) {
                    if (slot.reservations != null && slot.reservations.length > 0) {
                      slot.renderedTurnover = undefined
                    }
                  }
                  return (
                    <YardBlockBayOverviewSlotItem
                      key={slot.slot.id}
                      item={slot}
                      left={getPos({ x: rowIndex, y: tierIndex }, false, false).x}
                      top={getPos({ x: rowIndex, y: tierIndex }, false, false).y}
                      slotWidth={style.slotWidth}
                      onMenuAction={(action: string, turnover: ContainerTurnoverDto) => {
                        onMenuAction(action, turnover)
                        setIsUpdatePositionDialogOpen(true)
                      }}
                      tenantConfigStore={tenantConfigStore}
                      filterViewBy={filterviewBy}
                    />
                  )
                }),
              )}
            </Group>
          </Layer>
        </Stage>

        <UpdateContainerPositionForm
          turnover={selectedTurnover}
          open={isUpdatePositionDialogOpen}
          disableConfirm={!position}
          onChange={(position?: YardPositionDto) => {
            setPosition(position)
            alertStore.clearAlerts()
          }}
          onSubmit={async () => {
            await onUpdatePosition({ ...position })

            if (alertStore.getAlerts().length === 0) {
              setIsUpdatePositionDialogOpen(false)
            }
          }}
          onClose={() => setIsUpdatePositionDialogOpen(false)}
          isLoading={isLoading}
          validationAlerts={validationAlerts}
        />
      </Box>
    )
  },
)
