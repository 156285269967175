import { Box, Button, Tooltip } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection, CarrierVisitStatus } from '@planning/app/api'
import { CargoTypeIconLabel } from '@planning/components/CargoTypeIconLabel'
import { CarrierVisitStatusChip } from '@planning/components/CarrierVisitStatusChip'
import { FilterPaper } from '@planning/components/FilterPaper'
import { GridToolbar } from '@planning/components/GridToolbar'
import { LabeledTypography } from '@planning/components/LabeledTypography'
import { GridColumn, StickyHeaderTable } from '@planning/components/PaginatedTable'
import { StatusRadioGroupFilter } from '@planning/components/StatusRadioGroupFilter'
import { carrierVisitStatus, emptyIndicator } from '@planning/constants'
import { IRailVisitItem, RailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { carrierVisitService } from '@planning/services'
import { IFilterDelegate, ISortDelegate } from '@planning/stores/PaginatedLocalStore'
import { SortingModel } from '@planning/stores/PaginatedStore'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { BinIcon, EditIcon, IconButton } from '@tom-ui/ui'
import dayjs from 'dayjs'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { CreateRailVisitForm } from './Components/Forms/CreateRailVisitForm'
import { EditRailVisitForm } from './Components/Forms/EditRailVisitForm'

export const railVisitSortingDelegate: ISortDelegate<IRailVisitItem> = (sortingModel, a, b) => {
  const getValue = (item: IRailVisitItem, sortingModel: SortingModel<IRailVisitItem>) => {
    if (sortingModel.orderBy) {
      if (_.has(item, sortingModel.orderBy)) return _.get(item, sortingModel.orderBy)
      if (_.has(item.data, sortingModel.orderBy)) return _.get(item.data, sortingModel.orderBy)
    }
    return item.data.eta
  }

  const valA = getValue(a, sortingModel) as any
  const valB = getValue(b, sortingModel) as any
  let sort = 0
  if (moment(valA).isValid() && moment(valB).isValid()) {
    sort = moment(valA).diff(moment(valB))
  } else if (typeof valA === 'string' && typeof valB === 'string') {
    sort = valA.localeCompare(valB)
  }

  return sortingModel.isDescending ? -sort : sort
}

export const railVisitFilterDelegate: IFilterDelegate<IRailVisitItem> = (
  filter: string,
  item: IRailVisitItem,
  showCompleted?: boolean,
  from?: Date,
  to?: Date,
  filterStatus?: string,
) => {
  const filterableProperties: string[] = ['data.cargoType', 'data.name', 'data.shippingLine']

  if (!showCompleted && item.data.status === CarrierVisitStatus.Departed) return false

  if (filterStatus && item.data.status !== filterStatus) {
    return false
  }

  if (dayjs(from) > dayjs(item.arrival) || dayjs(item.arrival) > dayjs(to)) {
    return false
  }

  if (filter !== '') {
    return filterableProperties
      .map(p => p as keyof RailVisitItem)
      .some(p => {
        const prop = _.get(item, p)

        if (typeof prop === 'string') return prop.toLowerCase().includes(filter.toLowerCase())
        if (typeof prop === 'number') return prop === parseFloat(filter)

        return false
      })
  }

  return true
}
const RailVisitActionCell: FC<{
  item: RailVisitItem
  onDelete: () => void
  onEdit: () => void
}> = observer(({ item, onDelete, onEdit }) => {
  const { t } = useTranslate()
  return (
    <Box sx={{ display: 'flex' }}>
      {item.data.status === 'Expected' && (
        <>
          <Tooltip key='edit-order' arrow placement='bottom' title={t('edit', 'Edit')}>
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip key='delete-order' arrow placement='bottom' title={t('delete', 'Delete')}>
            <IconButton color='error' onClick={onDelete}>
              <BinIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  )
})

export const RailVisitPage = observer(() => {
  const { appViewStore, drawerStore, railVisitItemStore, railVisitListViewStore } =
    usePlanningStore()
  const navigate = useNavigate()
  const { t } = useTranslate()

  useEffect(() => {
    railVisitListViewStore.loadCurrentPage()
  }, [railVisitListViewStore])

  const handleCreateRailVisit = () => {
    drawerStore.showView(<CreateRailVisitForm onClose={drawerStore.close} />, {
      title: t('create', 'Create'),
      subTitle: t('railVisit', 'Rail Visit'),
      primaryActionLabel: t('submit', 'Submit'),
      closeActionLabel: t('close', 'Close'),
      formId: 'rail-visit',
    })
  }

  const handleDelete = async (id: number) => {
    try {
      const confirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t('doYouWantToDeleteTheRailVisit', 'Do you want to delete the rail visit?'),
      )
      if (confirmed) {
        await carrierVisitService.delete(id)
        appViewStore.setShowAlert(
          'success',
          t('railVisitDeletedSuccessfully', 'Rail Visit deleted successfully'),
        )
      }
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToDelete', 'Failed to delete'))
    }
  }

  const handleEdit = async (id: number) => {
    const railVisitItem = railVisitItemStore.elements[id]

    drawerStore.showView(
      <EditRailVisitForm onClose={drawerStore.close} visit={railVisitItem.data} />,
      {
        title: t('edit', 'Edit'),
        subTitle: t('railVisit', 'Rail Visit'),
        primaryActionLabel: t('save', 'Save'),
        closeActionLabel: t('close', 'Close'),
        formId: 'edit-rail-visit',
      },
    )
  }

  const handleShowFilter = () => {
    drawerStore.showView(
      <FilterPaper sx={{ minWidth: '24rem' }}>
        <StatusRadioGroupFilter
          store={railVisitListViewStore}
          options={carrierVisitStatus.filter(
            s => railVisitListViewStore.showCompleted || s.value !== 'Departed',
          )}
          title={t('trainVisitStatus', 'Train Visit Status')}
        />
      </FilterPaper>,
    )
  }

  const getDischargeCount = (visit: RailVisitItem) => {
    const total = visit.getCount('total', CarrierVisitDirection.Inbound, visit.data.cargoType)

    if (total === 0 || total == null) {
      return visit.discharge.orderCounts.estimated
    }
    return total
  }

  const getLoadCount = (visit: RailVisitItem) => {
    const total = visit.getCount('total', CarrierVisitDirection.Outbound, visit.data.cargoType)

    if (total === 0 || total == null) {
      return visit.load.orderCounts.estimated
    }
    return total
  }

  const columns: GridColumn<RailVisitItem>[] = [
    {
      field: 'trainName',
      headerName: t('trainName', 'Train'),
      minWidth: 170,
      renderCell: ({ row }) => {
        return (
          <LabeledTypography
            label={row.data.identifier ?? emptyIndicator}
            text={row.data.name}
            alignItems='start'
            labelBelow
          />
        )
      },
      sortable: true,
      orderBy: 'train.name',
      flex: 0.5,
    },
    {
      field: 'eta',
      headerName: t('etaAta', 'ETA / ATA'),
      valueGetter: ({ row }) => row.arrival,
      renderCell: ({ row }) => {
        return (
          <LabeledTypography
            label={row.data.ata ? t('ata', 'ATA') : t('eta', 'ETA')}
            text={formatDateTime(row.arrival)}
            alignItems='start'
            labelBelow
          />
        )
      },
      minWidth: 130,
      sortable: true,
      orderBy: 'arrival',
      flex: 0.5,
    },
    {
      field: 'etd',
      headerName: t('etdAtd', 'ETD / ATD'),
      valueGetter: ({ row }) => row.departure,
      renderCell: ({ row }) => {
        return (
          <LabeledTypography
            label={row.data.atd ? t('atd', 'ATD') : t('etd', 'ETD')}
            text={formatDateTime(row.departure)}
            alignItems='start'
            labelBelow
          />
        )
      },
      minWidth: 130,
      sortable: true,
      orderBy: 'departure',
      flex: 0.5,
    },
    {
      field: 'cargoType',
      headerName: t('cargoType', 'Cargo Type'),
      renderCell: ({ row }) => <CargoTypeIconLabel cargoType={row.data.cargoType} />,
      minWidth: 20,
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'tripIds',
      headerName: t('tripIds', 'Trip IDs'),
      renderCell: ({ row }) => {
        const inboundTripIds =
          row.data.inboundTripIds &&
          row.data.inboundTripIds.length &&
          row.data.inboundTripIds[0] !== ''

        const outboundTripIds =
          row.data.outboundTripIds &&
          row.data.outboundTripIds.length &&
          row.data.outboundTripIds[0] !== ''

        return (
          <>
            {inboundTripIds
              ? `In: ${row.data.inboundTripIds?.filter(id => id.trim() !== '').join(', ')}`
              : emptyIndicator}
            <br />
            {outboundTripIds
              ? `Out: ${row.data.outboundTripIds?.filter(id => id.trim() !== '').join(', ')}`
              : emptyIndicator}
          </>
        )
      },
      minWidth: 200,
      sortable: false,
      flex: 0.75,
    },
    {
      field: 'dischargeEstimate',
      headerName: t('dischargeLoad', 'Discharge / Load'),
      valueGetter: ({ row }) => `${getDischargeCount(row)} / ${getLoadCount(row)}`,
      minWidth: 100,
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'status',
      headerName: t('status', 'Status'),
      minWidth: 150,
      renderCell: ({ row }) => {
        return <CarrierVisitStatusChip status={row.data.status} />
      },
      sortable: true,
      orderBy: 'status',
      flex: 0.5,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      minWidth: 75,
      sortable: false,
      renderCell: ({ row }) => (
        <RailVisitActionCell
          item={row}
          onDelete={() => handleDelete(row.id)}
          onEdit={() => handleEdit(row.id)}
        />
      ),
    },
  ]

  /*
    This is a workaround to force MobX refresh when orderCounts changes
    All calculated properties inside "renderCell: ({ row }) => ()" in columns is not tracked
    @North maybe find a different solution for this problem like using MobX reaction?
  */
  const forceToTrackOrderCountsAndVesselsUpdated = () => {
    railVisitListViewStore.pageItems.map(c => c.load.orderCounts)
  }
  forceToTrackOrderCountsAndVesselsUpdated()

  return (
    <StickyHeaderTable
      store={railVisitListViewStore}
      columns={columns}
      toolBar={
        <GridToolbar
          title={t('railVisits', 'Rail Visits')}
          searchPlaceholder={t('searchByTypeOrTrain', 'Search by cargo type or train name')}
          store={railVisitListViewStore}
          actions={
            <Button variant='contained' onClick={handleCreateRailVisit}>
              {t('create', 'Create')}
            </Button>
          }
          dateRange
          onShowFilter={() => handleShowFilter()}
        />
      }
      onCellClick={(column, event) => {
        if (column.field === 'actions') {
          event.stopPropagation()
        }
        event.preventDefault()
      }}
      onRowClick={({ id }) => {
        navigate(`${id}/dashboard`)
      }}
      rowsPerPageOptions={[10, 25, 50]}
    />
  )
})
