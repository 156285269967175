import { Typography } from '@mui/material'
import { useOpenFeatureClient } from '@openfeature/react-sdk'
import { VesselDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { LocationCodeAutoComplete, VesselServiceAutocomplete } from '@planning/components'
import { VesselAutoComplete } from '@planning/components/autocompletes/vessel-autocomplete/VesselAutoComplete'
import { BerthSideSelectChip } from '@planning/components/visit/BerthSideSelectChip'
import { CargoTypeField } from '@planning/components/visit/CargoTypeField'
import { VisitEstimatesFields } from '@planning/components/visit/VisitEstimatesFields'
import { VisitTimestampsFields } from '@planning/components/visit/VisitTimestampsFields'
import { VisitTripIdsFields } from '@planning/components/visit/VisitTripIdsFields'
import { VesselVisitPlanningStore } from '@planning/rt-stores/vesselVisit/VesselVisitPlanningStore'
import { LocationCodeAutocompleteViewStore } from '@planning/stores/locationCode/LocationCodeAutocompleteViewStore'
import { VesselAutocompleteViewStore } from '@planning/stores/vessel/VesselAutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { VesselVisitPlanningBerthAllocation } from './VesselVisitPlanningBerthAllocation'
import { VesselVisitPlanningDraftsForm } from './VesselVisitPlanningDraftsForm'

export const VesselVisitPlanningForm = observer(
  ({
    store,
    isEditMode,
    onFormSubmit,
    vessel,
    onVesselChange,
  }: {
    store: VesselVisitPlanningStore
    isEditMode: boolean
    vessel?: VesselDto[]
    onFormSubmit: any
    onVesselChange: (vessels: VesselDto[]) => void
  }) => {
    const { t } = useTranslate()
    const { vesselServiceItemStore } = usePlanningStore()
    const {
      handleSubmit,
      control,
      formState: { errors },
      getValues,
      setValue,
    } = useFormContext()

    const featureFlagClient = useOpenFeatureClient()
    const enableVesselVisitLinking = useMemo(
      () => featureFlagClient.getBooleanValue('vessel-visit-port-rotation', false),
      [featureFlagClient],
    )

    const vesselAutocompleteStore = useMemo(() => new VesselAutocompleteViewStore(), [])
    const locationCodeAutocompleteViewStore = useMemo(
      () => new LocationCodeAutocompleteViewStore(),
      [],
    )

    const handleLocationCodeChange = (codes: string[]) => {
      setValue('portRotation', codes)
    }

    const getPortRotation = (id: number) => {
      const options = computed(() =>
        _.values(vesselServiceItemStore.elements).map(vesselService => vesselService.data),
      ).get()
      return options.find(item => item.id === id)?.portRotation
    }

    return (
      <form id='vessel-visit-planning' onSubmit={handleSubmit(onFormSubmit)}>
        <CargoTypeField isEditMode={isEditMode} />

        <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
          {t('vessel', 'Vessel')}
        </Typography>
        <Controller
          control={control}
          name={`vesselIds`}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) =>
            (store.isEditMode && !!store.vesselVisit) || !store.isEditMode ? (
              <VesselAutoComplete
                store={vesselAutocompleteStore}
                onChange={selectedVessels => {
                  onChange(selectedVessels?.map(v => v.id) ?? -1)
                  onVesselChange(selectedVessels)
                  enableVesselVisitLinking &&
                    setValue('vesselServiceId', selectedVessels[0]?.serviceId ?? null)
                  enableVesselVisitLinking &&
                    setValue('portRotation', getPortRotation(selectedVessels[0]?.serviceId ?? 0))
                }}
                value={value}
                defaultValues={vessel}
                required={true}
                error={!!errors.vesselIds}
                helperText={errors.vesselIds ? t('fieldIsRequired', 'Field is required.') : ''}
              />
            ) : (
              <></>
            )
          }
        />
        {enableVesselVisitLinking ? (
          <>
            <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
              {t('vesselService', 'Vessel Service')}
            </Typography>

            <Controller
              control={control}
              name='vesselServiceId'
              rules={{ required: true }}
              render={({ field }) => (
                <VesselServiceAutocomplete
                  label={t('selectVesselService', 'Select Vessel service')}
                  value={field.value}
                  onChange={id => {
                    field.onChange(id)
                    setValue('portRotation', getPortRotation(id!))
                  }}
                  hasError={!!errors.vesselServiceId}
                />
              )}
            />

            <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
              {t('portRotation', 'Port rotation')}
            </Typography>

            <Controller
              name='portRotation'
              control={control}
              rules={{ required: true }}
              defaultValue={getValues('portRotation')}
              render={() => (
                <LocationCodeAutoComplete
                  store={locationCodeAutocompleteViewStore}
                  values={getValues('portRotation')}
                  onChange={value => handleLocationCodeChange(value)}
                  label={t('selectUnCode', 'Select UN code')}
                  error={!!errors.portRotation}
                  helperText={
                    errors.portRotation ? t('fieldIsRequired', 'Field is required') : undefined
                  }
                />
              )}
            />
          </>
        ) : null}
        <VisitTripIdsFields />
        <VesselVisitPlanningDraftsForm />
        <VisitEstimatesFields />
        <VisitTimestampsFields store={store} />

        <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
          {t('dockingSide', 'Docking Side')}
        </Typography>
        <Controller
          control={control}
          name={`berthSide`}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <BerthSideSelectChip value={value} onChange={onChange} />
          )}
        />

        <VesselVisitPlanningBerthAllocation />
      </form>
    )
  },
)
