import { CompaniesApi, CompanyDto } from '@storage/app/api'

export class CompanyService {
  constructor(private api: CompaniesApi) {}

  async getAll(): Promise<CompanyDto[]> {
    const { data: companies } = await this.api.getAll()
    return companies
  }
}
