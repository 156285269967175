import { YardBlockAssignmentDto } from '@operations/app/api'

export interface EquipmentFormProfile {
  id?: number
  name: string
  description?: string | null
  equipmentType: number | string
  isOnMaintenance: boolean
  maxLiftWeight?: number | null
  maxStackingHeight?: number | null
  yardBlockAssignments?: YardBlockAssignmentDto[]
}

export const defaultValues: EquipmentFormProfile = {
  name: '',
  equipmentType: '',
  description: '',
  isOnMaintenance: false,
  maxLiftWeight: undefined,
  maxStackingHeight: undefined,
  yardBlockAssignments: [],
}
