import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ContainerYardOperationDialog } from '@host/components/container-change-position-dialog/ContainerYardOperationDialog'
import { ContainerYardOperationViewStore } from '@host/components/container-change-position-dialog/ContainerYardOperationViewStore'
import { OrdersPage } from '@tom-ui/planning'
import { useMemo } from 'react'
import { renderIsoCodeMappingInput } from '../IsoCodeMapping/IsoCodeMappingWrapper'

export const OrdersPageWrapper = withAuthenticationRequired(() => {
  const containerYardOperationViewStore = useMemo(() => {
    return new ContainerYardOperationViewStore()
  }, [])

  return (
    <>
      <OrdersPage
        renderIsoCodeMappingInput={renderIsoCodeMappingInput}
        containerYardOperationViewStore={containerYardOperationViewStore}
      />
      <ContainerYardOperationDialog store={containerYardOperationViewStore} ignoreCheck />
    </>
  )
})
