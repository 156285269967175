import { Alert, DialogContent, DialogTitle, Grid, Stack } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { ContainerDamageResponseDto, ContainerHeight, UnitType } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerAutoComplete } from '@planning/components'
import { ContainerSummary } from '@planning/pages/Order/components/ContainerSummary'
import bookingsService from '@planning/services/bookingsService'
import { DialogStore } from '@planning/stores/DialogStore'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { useTranslate } from '@tolgee/react'
import { ConfirmationActions } from '@tom-ui/ui'
import { useFormStore } from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IInspectContainerFormData } from './InspectContainer'
import { InspectContainerBase, defaultInspectContainerFormData } from './InspectContainerBase'
import {
  BookingSlotViewStore,
  ExtendedBookingContainerAssignmentErrors,
} from './Organisms/BookingSlotDialog/BookingSlotViewStore'

interface IContainerAssignmentErrorProps {
  error: ExtendedBookingContainerAssignmentErrors
  bookingOperator?: string | null
  bookingIsoCode?: string | null
}

const ContainerAssignmentError: FC<IContainerAssignmentErrorProps> = ({
  error,
  bookingOperator,
  bookingIsoCode,
}) => {
  const { t } = useTranslate()

  switch (error) {
    case 'AlreadyAssigned':
      return (
        <Stack>
          {t('containerAlreadyAssigned', 'Container already assigned to this booking.')}
        </Stack>
      )
    case 'AlreadyOnTerminal':
      return <Stack>{t('containerAlreadyOnTerminal', 'Container already on terminal.')}</Stack>
    case 'LinkedToAnotherOrder':
      return (
        <Stack>
          {t(
            'containerLinkedToAnotherOrder',
            'Container is already assigned to a different order.',
          )}
        </Stack>
      )
    case 'MismatchIsoCode':
      return (
        <Stack>
          {t(
            'containerMismatchIsoCode',
            'Container ISO code is different from that specified by booking ({isoCode}).',
            {
              isoCode: bookingIsoCode,
            },
          )}
        </Stack>
      )
    case 'MismatchOperator':
      return (
        <Stack>
          {t(
            'containerMismatchOperator',
            'Container operator is different from that specified by booking ({operator}).',
            {
              operator: bookingOperator,
            },
          )}
        </Stack>
      )
    default:
      return <>{t('unknownError', 'Unknown error.')}</>
  }
}

interface IProps {
  store: BookingSlotViewStore
  gateInViewStore: GateInViewStore
  dialogStore: DialogStore
  damages?: ContainerDamageResponseDto[]
  displayDamageReport?: boolean
}

export const InspectContainerForBooking: FC<IProps> = observer(
  ({ store, gateInViewStore, dialogStore, damages, displayDamageReport }) => {
    const { t } = useTranslate()
    const { containerItemStore, appViewStore } = usePlanningStore()
    const hasNewDamageRecording = useBooleanFlagValue('damage-recording', false)

    const formStore = useFormStore<IInspectContainerFormData>()

    if (!store.selectedSlot) return <></>

    const order = store.selectedSlot

    const onSubmit = async (data: IInspectContainerFormData) => {
      store.clearErrorList()
      data.containerNumber = store.bookingWithAssignedContainer?.containerNumber

      if (!data.containerNumber || !store.selectedSlot) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
        return
      }

      const shouldProceed = await shouldProceedWithContainerAssignment(
        data.containerNumber,
        store.selectedSlot.id,
      )

      if (!shouldProceed) return

      gateInViewStore.upsertBooking(data)
      store.reset()
      dialogStore.close()
    }

    const shouldProceedWithContainerAssignment = async (
      containerNumber: string,
      outboundOrderId: number,
    ) => {
      try {
        const assessment = await bookingsService.validateContainerAssignment(
          containerNumber,
          outboundOrderId,
        )

        if (assessment.errors?.length) {
          store.setErrorList(assessment.errors)
        }

        if (gateInViewStore.bookingContainerNumbers.includes(containerNumber)) {
          store.addError('AlreadyAssigned')
        }

        if (store.hasContainerAssignmentErrors) {
          return false
        }

        if (assessment.isContainerMissing) {
          return await appViewStore.setOpenConfirmDialog(
            true,
            t(
              'containerWillBeCreatedWithTheGivenAttributes',
              'This container will be created with the booking attributes. Do you want to proceed?',
            ),
          )
        }
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
        return false
      }

      return true
    }

    const onCancel = () => {
      store.setSelectedSlot()
      store.clearErrorList()
    }

    useEffect(() => {
      if (order.containerId) containerItemStore.fetchById(order.containerId)
    }, [containerItemStore, order.containerId])

    const containerItem = computed(() => containerItemStore.elements[order.containerId ?? 0]).get()

    // TODO: Extract form as component without header
    const { control, handleSubmit, getValues, setValue, formState, watch } =
      useForm<IInspectContainerFormData>(
        defaultInspectContainerFormData(order, damages, containerItem),
      )

    useEffect(() => {
      if (containerItem) {
        setValue(
          'hasDamage',
          !!(
            (order as IInspectContainerFormData).damagesReported?.length ??
            containerItem?.data.damages.length
          ),
        )
      }
    }, [containerItem, order, setValue])

    const [defaultValuesData] = useState(getValues())
    const [isDangerous, setIsDangerous] = useState<boolean>(!!getValues('imoClasses')?.length)

    const onFormSubmit = async (data: any) => {
      try {
        data.isEdited = data.isEdited
          ? true
          : JSON.stringify(defaultValuesData) !== JSON.stringify(data)
        store.validateContainerNumber()
        if (store.isInvalidContainerNumber) return

        onSubmit(data)
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    const onEditDamageReport = (
      editedReport: Partial<ContainerDamageResponseDto>,
      initialReport: ContainerDamageResponseDto,
    ): void => {
      setValue('damages', [
        ...getValues('damages').filter(d => d.description !== initialReport.description),
        editedReport as ContainerDamageResponseDto,
      ])
    }

    const unitTypeIsContainer = containerItem?.data.unitType === UnitType.Container

    return (
      <>
        <DialogTitle sx={{ borderBottom: '1px solid rgba(145, 158, 171, 0.24)' }}>
          {t('inspectContainer', 'Inspect container')}
        </DialogTitle>

        <DialogContent>
          {order && (
            <form>
              <Grid container marginTop={2} spacing={2}>
                {unitTypeIsContainer && (
                  <>
                    <Grid item xs={12}>
                      <ContainerAutoComplete
                        onChange={(_, value) => {
                          if (typeof value === 'string') return
                          if (value && 'number' in value) {
                            store.setContainer(value)
                          }
                        }}
                        onFilterChange={containerNumber => {
                          store.setContainer()
                          store.setContainerNumber(containerNumber)
                        }}
                        isoCode={order.containerIsoCode ?? ''}
                        isOnTerminal={false}
                        operator={order.operator ?? ''}
                        filteredContainerNumbers={gateInViewStore.bookingContainerNumbers}
                      />
                      {store.isInvalidContainerNumber && (
                        <Alert severity='warning' sx={{ marginTop: '1rem' }}>
                          {t('containerNumberIsInvalid', 'Container number is invalid.')}
                        </Alert>
                      )}
                      {store.hasContainerAssignmentErrors && (
                        <Alert severity='warning' sx={{ marginTop: '1rem' }}>
                          {store.errorList.map(error => (
                            <ContainerAssignmentError
                              key={error}
                              error={error}
                              bookingOperator={order.operator}
                              bookingIsoCode={order.containerIsoCode}
                            />
                          ))}
                        </Alert>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <ContainerSummary
                        noContainerNumberText={t(
                          'expectedContainerDetails',
                          'Expected container details',
                        )}
                        container={{
                          id: order.containerId ?? -1,
                          isoCode: order.containerIsoCode,
                          length: order.containerLength,
                          height: order.containerHeight ?? ContainerHeight.Standard,
                          type: order.containerType,
                          operator: order.operator,
                          tare: order.containerTare,
                          isEmpty: order.isEmpty,
                          isOnTerminal: false,
                          damages: [],
                        }}
                      />
                    </Grid>
                  </>
                )}

                <InspectContainerBase
                  control={control}
                  isDangerous={isDangerous}
                  getValues={getValues}
                  setIsDangerous={setIsDangerous}
                  formState={formState}
                  displayDamageReport={displayDamageReport}
                  containerItem={containerItem}
                  hasNewDamageRecording={hasNewDamageRecording}
                  watch={watch}
                  onEditDamageReport={onEditDamageReport}
                  t={t}
                  formStore={formStore}
                />
              </Grid>
            </form>
          )}
        </DialogContent>

        <ConfirmationActions
          primaryActionText={t('save', 'Save')}
          onConfirm={handleSubmit(onFormSubmit)}
          closeLabel={t('cancel', 'Cancel')}
          onClose={() => onCancel()}
          primaryActionDisabled={store.isInvalidContainerNumber}
          hasBorderTop
        />
      </>
    )
  },
)
