import { Clear } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import { SealType } from '@planning/app/api'
import { YesNoChipBox } from '@planning/components/dangerous-goods/YesNoChipBox'
import { useTranslate } from '@tolgee/react'
import { BinIcon, PlusIcon } from '@tom-ui/ui'
import { FC } from 'react'
import { Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form'

type SealEntry = {
  number: string
  type?: SealType | string
  isNewSeal: boolean
}

interface IProps<T extends FieldValues> extends UseControllerProps<T> {
  sealsName: Path<T>
  hasSealsName: Path<T>
}

const SealWithTypes: FC<{
  seal: string
  index: number
  isNewSeal: boolean
  value: SealEntry[]
  onChange: (newSeals: SealEntry[]) => void
}> = ({ seal, index, isNewSeal, value, onChange }) => {
  const { t } = useTranslate()
  const updateSeal = (updatedSeal: Partial<SealEntry>) => {
    const newSeals = [...value]
    newSeals[index] = { ...newSeals[index], ...updatedSeal }
    onChange(newSeals)
  }

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='subtitle1'>{`Seal ${index + 1}`}</Typography>
        <IconButton
          color='default'
          onClick={() => {
            const newSeals = value.filter((_, i) => i !== index)
            onChange(newSeals)
          }}
        >
          <BinIcon />
        </IconButton>
      </Box>
      <Grid container alignItems='center' spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', gap: 1 }}>
          <Chip
            label={'Existing Seal'}
            onClick={() => updateSeal({ isNewSeal: false })}
            color={!isNewSeal ? 'secondary' : 'default'}
          />
          <Chip
            label='New Seal'
            onClick={() => updateSeal({ isNewSeal: true })}
            color={isNewSeal ? 'secondary' : 'default'}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id='select-seal-type-label'>{t('sealType', 'Seal type')}</InputLabel>
            <Select
              labelId='select-seal-type-label'
              id='select-seal-type'
              value={value[index].type}
              label={t('sealType', 'Seal type')}
              onChange={e => updateSeal({ type: e.target.value as keyof typeof SealType })}
              endAdornment={
                value[index].type && (
                  <InputAdornment sx={{ marginRight: '10px' }} position='end'>
                    <IconButton size='small' onClick={() => updateSeal({ type: '' as any })}>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                )
              }
            >
              {Object.values(SealType).map(type => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            sx={{ width: '100%' }}
            type='text'
            label={t('sealNo', 'Seal no.')}
            value={seal}
            onChange={e => updateSeal({ number: e.target.value })}
          />
        </Grid>
      </Grid>
    </>
  )
}

const SealsListWithTypes: FC<{ value: SealEntry[]; onChange: (newSeals: SealEntry[]) => void }> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslate()

  return (
    <Stack gap={1} marginTop={1.5}>
      {value.map((sealObj, index) => (
        <>
          <SealWithTypes
            key={index}
            seal={sealObj.number}
            index={index}
            isNewSeal={sealObj.isNewSeal}
            value={value}
            onChange={onChange}
          />
          <Divider sx={{ my: 1 }} />
        </>
      ))}
      <Button
        sx={{ maxWidth: 120 }}
        type='button'
        color='inherit'
        variant='contained'
        onClick={() => {
          onChange([...value, { number: '', type: '', isNewSeal: false }])
        }}
        startIcon={<PlusIcon />}
      >
        {t('addSeal', 'Add Seal')}
      </Button>
    </Stack>
  )
}

export const ControlledContainerSealsWithTypes = <T extends FieldValues>({
  hasSealsName,
  control,
  sealsName,
}: IProps<T>) => {
  return (
    <Paper variant='outlined' sx={{ p: '1rem' }}>
      <Box>
        <Stack direction='column' gap={0.5}>
          <Controller
            control={control}
            name={hasSealsName}
            render={({ field: { onChange: onChangeHasSeals, value: hasSeals } }) => (
              <>
                <Box display='flex' alignItems={'center'} justifyContent='space-between'>
                  <Typography variant='body1' fontWeight='bold'>
                    Seals?
                  </Typography>
                  <YesNoChipBox
                    activeOption={hasSeals ? 'Yes' : 'No'}
                    onClickYes={() => onChangeHasSeals(true)}
                    onClickNo={() => onChangeHasSeals(false)}
                  />
                </Box>
                {hasSeals && (
                  <Controller
                    control={control}
                    name={sealsName}
                    render={({ field: { onChange, value } }) => (
                      <SealsListWithTypes value={value || []} onChange={onChange} />
                    )}
                  />
                )}
              </>
            )}
          />
        </Stack>
      </Box>
    </Paper>
  )
}
