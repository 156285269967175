import { Stack, Tooltip } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, HoldsIcon, YardIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  hasHold?: boolean
  hasMissingReservation?: boolean
}
export const OrderWarnings = observer(({ hasHold, hasMissingReservation }: Props) => {
  const { t } = useTranslate()

  return (
    <Stack flexDirection='row' gap={1} alignItems='center'>
      {hasHold && (
        <ColoredLabel
          color='warning'
          startIcon={
            <Tooltip title={t('hasActiveHold', 'Has active hold')}>
              <HoldsIcon />
            </Tooltip>
          }
        />
      )}
      {hasMissingReservation && (
        <ColoredLabel
          color='warning'
          startIcon={
            <Tooltip title={t('doesNotHaveReservation', 'Does not have reservation')}>
              <YardIcon />
            </Tooltip>
          }
        />
      )}
    </Stack>
  )
})
