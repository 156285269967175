import { ContainerDamageTypeDto } from '@admin/app/api'
import { ContainerDamageTypeStore } from '@admin/stores/ContainerDamageTypeStore'
import { action, computed, makeObservable, observable } from 'mobx'

export class ContainerDamageTypeListUIStore {
  searchField = ''

  constructor(private containerDamageTypeStore: ContainerDamageTypeStore) {
    makeObservable(this, {
      searchField: observable,

      containerDamageTypes: computed,

      setSearchField: action,
    })
  }

  public setSearchField(newSearchValue: string) {
    if (this.searchField !== newSearchValue) {
      this.searchField = newSearchValue
    }
  }

  public get containerDamageTypes(): ContainerDamageTypeDto[] {
    return [...this.containerDamageTypeStore.items]
      .sort(i => i.id)
      .filter(x => this.doesContainerDamageTypeHaveName(x, this.searchField))
  }

  private doesContainerDamageTypeHaveName(
    containerDamageType: ContainerDamageTypeDto,
    name?: string,
  ) {
    return !name || containerDamageType.longName.toLowerCase().includes(name.toLowerCase())
  }
}
