import { useStorageContainerMessagesHandler } from '@host/hooks/useStorageContainerMessageHandler'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useOperationsStore } from '@tom-ui/operations'
import {
  containerPlanningService,
  containerTurnoverService,
  YardPositionSelector,
} from '@tom-ui/storage'
import { YardPositionDto } from '@tom-ui/storage/app/api'
import { ConfirmationDialog, useMinimalsTheme } from '@tom-ui/ui'
import { useUtilsStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import StackedAlert from 'modules/storage/src/components/StackedAlert'
import { useContainerTurnoversStores } from 'modules/storage/src/pages/container-turnovers/hooks/container-turnovers-stores.hook'
import { useMemo } from 'react'
import { PlanContainerPositionDialogViewStore } from './plan-container-position-dialog.view-store'

export const PlanContainerPositionDialog = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { planContainerPositionDialogStore } = useUtilsStore()
  const { appStore } = useOperationsStore()
  const { getExceptionMessages, getSuccessMessage } = useStorageContainerMessagesHandler()

  const { containerTurnoversListStore } = useContainerTurnoversStores()
  const viewStore = useMemo(() => {
    return new PlanContainerPositionDialogViewStore(
      planContainerPositionDialogStore,
      containerTurnoverService,
      containerPlanningService,
    )
  }, [planContainerPositionDialogStore])

  const handleClose = () => {
    viewStore.reset()
    planContainerPositionDialogStore.closeDialog()
  }

  const handleSubmit = async () => {
    try {
      appStore.increaseLoadingCounter()
      await viewStore.submit()

      appStore.setShowAlert('success', getSuccessMessage())
      planContainerPositionDialogStore.closeDialog()
      viewStore.reset()

      containerTurnoversListStore.reload(true)
    } finally {
      appStore.decreaseLoadingCounter()
    }
  }

  const handleDestinationChange = (destination: YardPositionDto) => {
    viewStore.setDestination(destination)
  }

  return (
    <ConfirmationDialog
      open={planContainerPositionDialogStore.isOpen}
      title={t('planContainerPosition', 'Plan container position')}
      primaryActionText={t('plan', 'Plan')}
      closeLabel={t('cancel', 'Cancel')}
      primaryActionDisabled={viewStore.isMovementInvalid}
      maxWidth='md'
      onClose={handleClose}
      onConfirm={handleSubmit}
    >
      <Stack gap={theme.customSpacing.m}>
        <YardPositionSelector handleChange={handleDestinationChange} />

        {viewStore.alerts.length > 0 && (
          <StackedAlert
            alerts={getExceptionMessages(viewStore.alerts).map(x => ({
              key: x,
              message: x,
              severity: 'error',
              date: new Date(),
            }))}
          />
        )}
      </Stack>
    </ConfirmationDialog>
  )
})
