import {
  ContainerDamageLocationResponseDto,
  ContainerDamageTypeResponseDto,
} from '@planning/app/api'
import {
  IContainerDamage,
  IContainerDamageLocation,
  IContainerDamageType,
} from '@planning/components'
import {
  containerDamageLocationService,
  containerDamageTypeService,
  containerService,
} from '@planning/services'
import { computed, makeObservable, observable, runInAction } from 'mobx'

export class ContainerDamageReportViewStore {
  containerDamageLocations: ContainerDamageLocationResponseDto[] = []
  containerDamageTypes: ContainerDamageTypeResponseDto[] = []

  constructor() {
    makeObservable(this, {
      containerDamageLocations: observable,
      containerDamageTypes: observable,

      locationOptions: computed,
      typeOptions: computed,
    })
  }

  async fetch() {
    await Promise.all([this.getLocations(), this.getTypes()])
  }

  async saveDamage(
    damages: IContainerDamage[],
    containerId?: number | null,
    containerNumber?: string,
  ) {
    if (!damages.length || !containerNumber || !containerId) return

    const uploadedDamages = await containerService.getDamageDtoForUploadedDamages(
      containerNumber,
      damages,
    )

    await containerService.createDamage({
      containerId,
      containerDamages: uploadedDamages,
    })
  }

  get locationOptions(): IContainerDamageLocation[] {
    return this.containerDamageLocations.map(
      x => ({ id: x.id, name: x.name, code: x.code }) as IContainerDamageLocation,
    )
  }

  get typeOptions(): IContainerDamageType[] {
    return this.containerDamageTypes
      .filter(t => t.isActive)
      .map(
        x => ({ id: x.id, longName: x.longName, shortName: x.shortName }) as IContainerDamageType,
      )
  }

  private async getLocations() {
    if (this.containerDamageLocations.length) return

    const locations = await containerDamageLocationService.get()
    runInAction(() => {
      this.containerDamageLocations = locations
    })
  }

  private async getTypes() {
    if (this.containerDamageTypes.length) return

    const types = await containerDamageTypeService.get()
    runInAction(() => {
      this.containerDamageTypes = types
    })
  }
}
