import { Badge, Tooltip } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog, FilterIcon, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { TruckVisitsViewStore } from '../../Stores/TruckVisitsViewStore'
import {
  TruckVisitAdvancedFilterForm,
  TruckVisitAdvancedFilterFormProfile,
} from '../Molecules/TruckVisitAdvancedFilterForm'

interface Props {
  store: TruckVisitsViewStore
}
export const TruckVisitAdvancedFilters = observer(({ store }: Props) => {
  const { t } = useTranslate()

  const handleClose = () => {
    store.toggleAdvancedFilter(false)
  }

  const handleSubmit = async (advancedFilter: TruckVisitAdvancedFilterFormProfile) => {
    store.setIssues(advancedFilter.issues)
    handleClose()
  }

  const handleReset = () => {
    handleClose()
    store.setIssues([])
  }

  return (
    <>
      <Tooltip title={t('advancedFilters', 'Advanced filters')}>
        <IconButton
          onClick={() => {
            store.toggleAdvancedFilter(true)
          }}
          size='small'
          style={{ padding: 0 }}
          aria-label='edit'
        >
          <Badge badgeContent={store.selectedIssues.length} color='warning'>
            <FilterIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <ConfirmationDialog
        open={store.isAdvancedFilterOpen}
        formId='advanced-filter-form'
        maxWidth='sm'
        title={t('AdvancedFilters', 'Advanced filters')}
        primaryActionText={t('filter', 'Filter')}
        closeLabel={t('reset', 'Reset')}
        onCancel={handleReset}
        onClose={handleClose}
        hasBorderTop
      >
        <TruckVisitAdvancedFilterForm
          formId='advanced-filter-form'
          issues={store.selectedIssues}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>
    </>
  )
})
