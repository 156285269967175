import { Chip, Container, SxProps, Theme } from '@mui/material'
import { Stack } from '@mui/system'
import { OrderResponseDto, TruckAppointmentOrderDto } from '@planning/app/api'
import { IContainerItem } from '@planning/rt-stores/container/ContainerItem'
import { useTranslate } from '@tolgee/react'
import {
  DamagedIcon,
  DangerousIcon,
  EmptyIcon,
  FullIcon,
  ReeferIcon,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { isContainerReefer } from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'

interface Props {
  order: OrderResponseDto | TruckAppointmentOrderDto
  isRestowed?: boolean
  container?: IContainerItem
  sx?: SxProps<Theme>
  onClick?: () => void
}

export const OrderHandlingInstructionContainer = observer(
  ({ order, isRestowed, container, sx, onClick }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const isReefer = computed(() => isContainerReefer(order.containerType)).get()

    return (
      <Container
        sx={{
          ...sx,
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '0.5rem !important',
          paddingRight: '0.5rem !important',
        }}
        onClick={() => {
          if (onClick) onClick()
        }}
      >
        <Stack spacing={0.5} direction='row' sx={{ svg: { verticalAlign: 'bottom' } }}>
          {(order.isEmpty && <EmptyIcon />) || <FullIcon />}
          {isReefer && <ReeferIcon />}
          {order.isDangerous && <DangerousIcon />}
          {(container?.activeDamages.length || order.isDamaged) && <DamagedIcon />}
          {(isRestowed || ('isRestowed' in order && order.isRestowed)) && (
            <Chip
              label={t('restow', 'Restow')}
              variant='filled'
              size='medium'
              sx={{
                borderRadius: '6px',
                color: 'white',
                backgroundColor: theme.palette.grey[600],
                fontWeight: 'bold',
              }}
            />
          )}
        </Stack>
      </Container>
    )
  },
)
