import {
  CarrierVisitDirection,
  OrderResponseDto,
  OrderStatus,
  RestowDto,
  UnitListDto,
} from '@planning/app/api'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { RailVisitItemStore } from '@planning/rt-stores/railVisit/RailVisitItemStore'
import { VesselVisitItemStore } from '@planning/rt-stores/vesselVisit/VesselVisitItemStore'
import { containerService } from '@planning/services'
import _ from 'lodash'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { OrderListUploadViewStoreV2 } from './OrderListUploadViewStoreV2'

export const key = (order: OrderResponseDto) => {
  if (!order.containerNumber) return ''

  return orderKey(order.containerNumber, order.direction, order.listName)
}

export const orderKey = (
  containerNumber: string,
  direction: CarrierVisitDirection,
  listName?: string | null,
) =>
  `${
    listName ?? OrderListUploadViewStoreV2.defaultListName
  }-${containerNumber}-${direction}`.toLowerCase()

export const openOrderFromOtherVisitsKey = (
  containerNumber: string,
  direction: CarrierVisitDirection,
) => `${containerNumber}-${direction}`.toLowerCase()

export class OrderListUploadDataStore {
  existingUpdateRelatedOrders: OrderResponseDto[] = []
  ordersFromUpdate: UnitListDto[] = []
  emptyRailcars: UnitListDto[] = []
  remainOnBoard: UnitListDto[] = []
  nonNumeric: UnitListDto[] = []
  restowUnits: RestowDto[] = []

  constructor(
    private parentStore: OrderListUploadViewStoreV2,
    private orderStore: OrderItemStore,
    private vesselVisitStore: VesselVisitItemStore,
    private railVisitStore: RailVisitItemStore,
  ) {
    makeObservable(this, {
      emptyRailcars: observable,
      remainOnBoard: observable,
      nonNumeric: observable,
      ordersFromUpdate: observable,
      existingUpdateRelatedOrders: observable,

      ordersFromVisit: computed,
      existingOrdersByContainerNumber: computed,
      existingUpdateRelatedOrdersByContainerNumber: computed,
      updatingOrdersByContainerNumber: computed,
      openOrdersFromOtherVisitsByContainerNumber: computed,

      setOrdersFromUpdate: action,
      setRemainOnBoard: action,
      setNonNumeric: action,
      setEmptyRailcars: action,
      setRestowUnits: action,
    })

    reaction(() => this.parentStore.vesselVisitId, this.fetch)
    reaction(() => this.parentStore.railVisitId, this.fetchRailVisit)
    reaction(() => this.ordersFromUpdate, this.fetchAdjacentData)
  }

  get ordersFromVisit() {
    const visitId = this.parentStore.vesselVisitId
      ? this.parentStore.vesselVisitId
      : this.parentStore.railVisitId

    if (!visitId || !this.orderStore.ordersByCarrierVisitId[visitId.toString()]) return []

    return this.orderStore.ordersByCarrierVisitId[visitId].map(item => item.data)
  }

  get existingOrdersByContainerNumber() {
    return _(this.ordersFromVisit)
      .filter(o => !!o.containerNumber)
      .groupBy(key)
  }

  get existingUpdateRelatedOrdersByContainerNumber() {
    return _(this.existingUpdateRelatedOrders)
      .filter(o => !!o.containerNumber)
      .groupBy(key)
  }

  get updatingOrdersByContainerNumber() {
    return _(this.ordersFromUpdate)
      .filter(o => !!o.containerNumber)
      .groupBy(o => o.containerNumber)
  }

  get openOrdersFromOtherVisitsByContainerNumber() {
    return _(this.existingUpdateRelatedOrders)
      .filter(
        o =>
          !!o.containerNumber &&
          !o.linkedOrderId &&
          (o.direction === CarrierVisitDirection.Inbound || o.status === OrderStatus.Open),
      )
      .groupBy(o => openOrderFromOtherVisitsKey(o.containerNumber ?? '', o.direction))
  }

  fetch = async () => {
    if (!this.parentStore.vesselVisitId) return

    this.vesselVisitStore.fetchById(this.parentStore.vesselVisitId)
  }

  fetchRailVisit = async () => {
    if (!this.parentStore.railVisitId) return

    this.railVisitStore.fetchById(this.parentStore.railVisitId)
  }

  fetchAdjacentData = async () => {
    const containerNumbersFromUpdate = this.ordersFromUpdate
      .filter(o => !!o.containerNumber)
      .map(o => o.containerNumber)

    if (!containerNumbersFromUpdate.length) return

    const existingOrders = await containerService.activeOrders(containerNumbersFromUpdate)

    runInAction(() => {
      this.existingUpdateRelatedOrders = existingOrders
    })
  }

  private tryAddRailTrackIds = (update: UnitListDto) => {
    if (update.railTrackId) return
    if (!update.waggon) return

    const { direction, listName } = this.parentStore

    if (
      !this.existingOrdersByContainerNumber.has(
        orderKey(update.containerNumber, direction, listName),
      )
    )
      return

    const existingOrder = this.existingOrdersByContainerNumber.get(
      orderKey(update.containerNumber, direction, listName),
    )[0]

    if (!existingOrder) return

    // We permit uploading without the railTrackId, but if it's not provided, we should use the existing one
    // Otherwise the comparisson will show a differnce in RailTrack, create an amendment and the backend will
    // unassign the RailcarTrackPosition
    if (existingOrder.railTrackId) {
      update.railTrackId = existingOrder.railTrackId
    }
  }

  setOrdersFromUpdate = (orderUpdates: UnitListDto[]) => {
    const { dataStore, direction, listName } = this.parentStore

    orderUpdates.forEach(this.tryAddRailTrackIds)

    orderUpdates.forEach(order => {
      const existing = dataStore.existingOrdersByContainerNumber.get(
        orderKey(order.containerNumber, direction, listName),
      )?.[0]

      Object.keys(order).forEach(key => {
        const field = key as keyof UnitListDto

        if (
          !order[field] ||
          (typeof order[field] === 'string' && (order[field] as string)?.trim() === '') ||
          (typeof order[field] === 'number' && isNaN(order[field] as number)) ||
          (Array.isArray(order[field]) && (order[field] as string[]).length === 0) ||
          (typeof order[field] === 'object' && Object.keys(order[field] as object).length === 0)
        ) {
          delete order[field]
        }
      })

      // Mandatory fields
      order.imoClasses = order.imoClasses ?? existing?.imoClasses ?? []
    })

    this.ordersFromUpdate = orderUpdates
  }

  setRemainOnBoard = (list: UnitListDto[]) => {
    this.remainOnBoard = list
  }

  setNonNumeric = (list: UnitListDto[]) => {
    this.nonNumeric = list
  }

  setEmptyRailcars = (list: UnitListDto[]) => {
    this.emptyRailcars = list
  }

  setRestowUnits = (list: RestowDto[]) => {
    this.restowUnits = list.map(item => ({
      ...item,
      containerNumber: item.containerNumber.trim(),
    }))
  }

  reset = () => {
    this.setOrdersFromUpdate([])
    this.setRemainOnBoard([])
    this.setNonNumeric([])
    this.setEmptyRailcars([])

    runInAction(() => {
      this.existingUpdateRelatedOrders = []
    })
  }
}
