import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { LocationCodeAutoComplete } from '@planning/components/autocompletes/location-code-autocomplete/LocationCodeAutoComplete'
import { useUniquenessValidation } from '@planning/hooks/useIsPropertyUnique'
import { LocationCodeAutocompleteViewStore } from '@planning/stores/locationCode/LocationCodeAutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useVesselServiceMessagesHandler } from '../../Hooks/useVesselServiceMessagesHandler'
import { VesselServiceFormData, VesselServiceViewStore } from '../../Stores/VesselServiceViewStore'

interface Props {
  store: VesselServiceViewStore
}

export const VesselServiceFormDialog = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { appViewStore } = usePlanningStore()
  const { getExceptionMessage, getSuccessMessage } = useVesselServiceMessagesHandler()

  const [isServiceIdUnique, validateServiceIdUniqueness] = useUniquenessValidation({
    id: store.dataToBeEdited?.id,
    items: store.allItems.map(x => ({ id: x.id, name: x.data.serviceId })),
  })

  const [isNameUnique, validateNameUniqueness] = useUniquenessValidation({
    id: store.dataToBeEdited?.id,
    items: store.allItems.map(x => ({ id: x.id, name: x.data.name })),
  })

  const title = store.dataToBeEdited
    ? t('editVesselService', 'Edit vessel service')
    : t('addNewVesselService', 'Add new vessel service')

  const defaultValues: VesselServiceFormData = useMemo(() => {
    return {
      serviceId: '',
      name: '',
      portRotation: [],
      id: undefined,
    }
  }, [])

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useForm<VesselServiceFormData>({
    defaultValues: defaultValues,
  })

  const locationCodeAutocompleteViewStore = useMemo(
    () => new LocationCodeAutocompleteViewStore(),
    [],
  )

  useEffect(() => {
    if (store.dataToBeEdited) {
      reset({
        serviceId: store.dataToBeEdited?.serviceId,
        name: store.dataToBeEdited?.name,
        portRotation: store.dataToBeEdited?.portRotation ?? undefined,
        id: store.dataToBeEdited.id,
      })
    } else {
      reset(defaultValues)
    }
  }, [store.dataToBeEdited, reset])

  const onSubmit: SubmitHandler<VesselServiceFormData> = async data => {
    const isEditMode = !!store.dataToBeEdited

    try {
      await store.onSubmit(data)
      appViewStore.setShowAlert('success', getSuccessMessage(isEditMode, data.name))
      store.toggleFormDialog(false)

      reset(defaultValues)
    } catch (error: any) {
      appViewStore.setShowAlert('error', getExceptionMessage(error))
    }
  }

  const handleDialogClose = () => {
    store.toggleFormDialog(false)
  }

  const handleFormSubmit = async () => {
    const isValid = await trigger()

    if (isValid) {
      const formData = getValues()
      onSubmit(formData)
    }
  }

  const handleLocationCodeChange = (codes: string[]) => {
    setValue('portRotation', codes)
  }

  const getHelperText = (field: keyof VesselServiceFormData) => {
    if (field === 'name' && !isNameUnique)
      return t('fieldAlreadyExists', '{field} already exists.', {
        field: t('serviceName', 'Service name'),
      })
    if (field === 'serviceId' && !isServiceIdUnique)
      return t('fieldAlreadyExists', '{field} already exists.', {
        field: t('serviceID', 'Service ID'),
      })

    if (errors[field]?.message) return errors[field]?.message?.toString()

    return errors[field] ? t('fieldIsRequired', 'Field is required.') : ''
  }

  return (
    <Dialog fullWidth maxWidth='md' open={store.isFormDialogOpen} onClose={handleDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <form>
        <DialogContent>
          <Stack spacing={theme.customSpacing.s}>
            <FormGroup sx={{ gap: theme.customSpacing.xs }}>
              <Typography variant='subtitle2'>
                {t('vesselServiceDetails', 'Vessel service details')}
              </Typography>
              <Controller
                name='serviceId'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('serviceID', 'Service ID')}
                    variant='outlined'
                    required
                    fullWidth
                    onChange={event => {
                      field.onChange(event)
                      validateServiceIdUniqueness(event.target.value)
                    }}
                    error={!!errors.serviceId || !isServiceIdUnique}
                    helperText={getHelperText('serviceId')}
                  />
                )}
              />

              <Controller
                name='name'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('serviceName', 'Service name')}
                    variant='outlined'
                    required
                    fullWidth
                    onChange={event => {
                      field.onChange(event)
                      validateNameUniqueness(event.target.value)
                    }}
                    error={!!errors.name || !isNameUnique}
                    helperText={getHelperText('name')}
                  />
                )}
              />
            </FormGroup>

            <FormGroup sx={{ gap: theme.customSpacing.xs }}>
              <Typography variant='subtitle2'>{t('portRotation', 'Port rotation')}</Typography>

              <Controller
                name='portRotation'
                control={control}
                rules={{ required: true }}
                render={() => (
                  <LocationCodeAutoComplete
                    store={locationCodeAutocompleteViewStore}
                    values={getValues('portRotation')}
                    onChange={value => handleLocationCodeChange(value)}
                    label={t('selectUnCode', 'Select UN code')}
                    error={!!errors.portRotation}
                    helperText={getHelperText('portRotation')}
                  />
                )}
              />
            </FormGroup>
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            mt: theme.customSpacing.l,
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Stack direction={'row'} spacing={2}>
            <Button onClick={handleFormSubmit} variant='contained' color='primary'>
              {t('save', 'Save')}
            </Button>
            <Button onClick={handleDialogClose} color='secondary'>
              {t('cancel', 'Cancel')}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
})
