import { Box, Button, Container, List, Stack, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerDto, NonNumericOrderDto, OrderResponseDto } from '@planning/app/api'
import { PaginatedList } from '@planning/components/PaginatedList'
import IconNoResults from '@planning/components/icon/IconNoResults'
import { NnrOrderContainerAssignmentPaginationStore } from '@planning/rt-stores/nnrOrder/NnrOrderContainerAssignmentPaginationStore'
import nnrOrderService from '@planning/services/nnrOrderService'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo } from 'react'
import { FullWidthFooter } from '../../../components/FullWidthFooter'
import { AssignContainerListItem } from './AssignContainerListItem'

interface Props {
  nnrOrder: NonNumericOrderDto
  orderId?: number
  order?: OrderResponseDto
}

export const AssignNNROrderContainer: FC<Props> = observer(({ nnrOrder, orderId, order }) => {
  const { t } = useTranslate()

  const { appViewStore, drawerStore } = usePlanningStore()

  if (!nnrOrder) {
    return <></>
  }

  const nnrOrderContainerAssignmentViewStore = useMemo(
    () => new NnrOrderContainerAssignmentPaginationStore(nnrOrder.id),
    [nnrOrder.id],
  )
  useEffect(() => {
    nnrOrderContainerAssignmentViewStore.loadCurrentPage()
  }, [nnrOrderContainerAssignmentViewStore])

  const emptyState = () => {
    return (
      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        <IconNoResults zoom={40} />
        <Typography variant='subtitle1'>No Containers available</Typography>
      </Box>
    )
  }

  const handleAssign = async (container: ContainerDto) => {
    if (!container.id) return

    try {
      await nnrOrderService.assignContainer({
        nonNumericOrderId: nnrOrder.id,
        containerId: container.id,
        orderId,
      })

      appViewStore.setShowAlert('success', 'Container added')
      drawerStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', 'Failed to add Container')
    }
  }

  const suggestedContainerDto = useMemo(() => {
    if (!order?.suggestedEmptyContainerId) return null

    return {
      id: order.suggestedEmptyContainerId,
      number: order.suggestedEmptyContainerNumber,
      isoCode: order.suggestedEmptyContainerIsoCode,
    } as ContainerDto
  }, [
    order?.suggestedEmptyContainerId,
    order?.suggestedEmptyContainerIsoCode,
    order?.suggestedEmptyContainerNumber,
  ])

  return (
    <Container sx={{ width: '450px', marginTop: '1rem' }}>
      <Typography variant='h3'>Choose container</Typography>
      {suggestedContainerDto && (
        <Stack>
          <Typography variant='subtitle2'>{t('suggested', 'Suggested')}</Typography>
          <AssignContainerListItem
            container={suggestedContainerDto}
            onAssign={() => handleAssign(suggestedContainerDto)}
          />
        </Stack>
      )}
      <PaginatedList
        paginatedStore={nnrOrderContainerAssignmentViewStore}
        hidePaginationControl
        filterable
      >
        {(nnrOrderContainerAssignmentViewStore.pageItems.length && (
          <List sx={{ width: '100%' }}>
            {nnrOrderContainerAssignmentViewStore.pageItems.map(container => {
              return (
                <AssignContainerListItem
                  key={`assign-container-list-item-${container.id}`}
                  container={container}
                  onAssign={() => handleAssign(container)}
                />
              )
            })}
          </List>
        )) ||
          emptyState()}
      </PaginatedList>
      <FullWidthFooter>
        <Button
          variant='contained'
          type='submit'
          sx={{ marginLeft: '5px', marginTop: '1rem' }}
          onClick={() => drawerStore.close()}
        >
          Cancel
        </Button>
      </FullWidthFooter>
    </Container>
  )
})
