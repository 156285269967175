import { IMessageBus, IQueryMessage } from '@planning/messages'
import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { IElementWithId, IEntity, IEntityMap } from '../types'

export interface IItemFetchFuncs<T> {
  messageBus: IMessageBus
  fetchFunc?: (id: T) => IQueryMessage
  bulkFetchFunc?: (ids: T[]) => IQueryMessage
}

export class ItemStore<
  U extends IElementWithId<V>,
  T extends IEntity<U, V>,
  V extends string | number = number,
> {
  elements: IEntityMap<T> = {}
  hasBeenInitialized = false
  fetchedIds: Set<V> = new Set()

  constructor(
    private createFunc: (key: V, data: U) => T,
    private fetchFuncs?: IItemFetchFuncs<V>,
  ) {
    makeObservable(this, {
      elements: observable,
      hasBeenInitialized: observable,
      init: action,
    })
  }

  init = (data: U[]) => {
    this.upsertBulk(data)
    this.hasBeenInitialized = true
  }

  public upsertBulk = (data: U[]) => {
    _(data)
      .map(item => this.upsert(item))
      .compact()
      .value()
  }

  protected upsert = (data: U) => {
    const key = data.id
    if (_.has(this.elements, key)) {
      _.get(this.elements, key).update(data)
    } else {
      const item = this.createFunc(key, data)
      _.set(this.elements, key, item)
      this.fetchedIds.add(key)
    }
  }

  protected delete = (id: V) => {
    _.unset(this.elements, id)
    this.fetchedIds.delete(id)
  }

  fetchByIds = async (ids: V[]) => {
    if (this.fetchFuncs?.bulkFetchFunc) {
      const newIds = ids.filter(id => !this.fetchedIds.has(id))

      if (!newIds.length) return

      newIds.forEach(id => this.fetchedIds.add(id))

      await this.fetchFuncs.messageBus.dispatchQuery(this.fetchFuncs.bulkFetchFunc(newIds))
    } else {
      for (const id of ids) {
        await this.fetchById(id)
      }
    }
  }

  fetchById = async (id: V) => {
    if (!this.fetchFuncs?.fetchFunc || this.fetchedIds.has(id)) return

    this.fetchedIds.add(id)

    await this.fetchFuncs.messageBus.dispatchQuery(this.fetchFuncs.fetchFunc(id))
  }
}
