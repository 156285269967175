import { Button } from '@mui/material'
import { FC } from 'react'

type Props = {
  active: boolean
  onClick: () => void
  count?: number
  text: string
}

export const FilterButton: FC<Props> = ({ active, onClick, count, text }) => {
  return (
    <Button
      variant='contained'
      size='small'
      color={active ? 'secondary' : 'inherit'}
      onClick={() => onClick()}
    >
      {text} {count ?? ''}
    </Button>
  )
}
