import { BookingsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class BookingsService {
  httpClient = createApiClient(BookingsApi)

  get = async (query?: object) => {
    const { data } = await this.httpClient.get(query)

    return data
  }

  getByPartialNumber = async (filter: string) => {
    const { data } = await this.httpClient.getByPartialNumber(filter)

    return data
  }

  validateContainerAssignment = async (containerNumber: string, outboundOrderId: number) => {
    const { data } = await this.httpClient.validateContainerAssignment(
      outboundOrderId,
      containerNumber,
    )

    return data
  }
}

const bookingsService = new BookingsService()

export default bookingsService
