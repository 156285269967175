import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { IssueStatus } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useCallback } from 'react'

interface Props {
  status: IssueStatus
  total?: number
}

export const IssueStatusChip = ({ status, total }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const getChipBg = useCallback(
    (value: string) => {
      switch (value) {
        case IssueStatus.Unresolved:
          return theme.palette.warning.lighter
        case IssueStatus.FollowUp:
          return theme.palette.busy.lighter
        default:
          return theme.palette.success.lighter
      }
    },
    [theme.palette],
  )

  const getChipColor = useCallback(
    (value: string) => {
      switch (value) {
        case IssueStatus.Unresolved:
          return theme.palette.warning.dark
        case IssueStatus.FollowUp:
          return theme.palette.busy.dark
        default:
          return theme.palette.success.dark
      }
    },
    [theme.palette],
  )

  return (
    <Box
      sx={{
        background: getChipBg(status),
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'flex',
        height: '32px',
      }}
    >
      <Typography
        fontSize='12px'
        alignSelf='center'
        alignItems='center'
        fontWeight='bold'
        color={getChipColor(status)}
      >
        {`${total ? total : ''} ${t(status)}`}
      </Typography>
    </Box>
  )
}
