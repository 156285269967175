import { Alert, Stack } from '@mui/material'
import {
  AllocationDestinationAndStackingStrategyDto,
  AllocationRuleTemplateDto,
  CarrierVisitAllocationRuleDto,
} from '@storage/app/api'
import { FormProps } from '@storage/app/models'
import { ControlledTextField } from '@storage/components/form-controls'
import { WeightClassContainerUIStore } from '@storage/features/weight-classes/stores/weight-class-container-ui-store'
import { ManualInputValidationStore } from '@storage/pages/container-turnovers/stores/manual-input-validation.store'
import { CompanyStore } from '@storage/stores/company.store'
import { UtilityMappers } from '@storage/utils/mappers'
import { useTranslate } from '@tolgee/react'
import { useEffect, useState } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { AllocationRuleCommonFields } from '../../components/AllocationRuleCommonFields'
import { AllocationRuleDestinationsList } from '../../components/AllocationRuleDestinationsList'
import { AllocationRuleTemplateFormProfile } from './allocation-rule-templates-form.profile'

interface Props extends FormProps {
  allocationRuleTemplateForm: UseFormReturn<AllocationRuleTemplateFormProfile, any, undefined>
  allocationRuleTemplate?: AllocationRuleTemplateDto | CarrierVisitAllocationRuleDto
  manualInputValidationStore: ManualInputValidationStore
  isATemplate?: boolean
  externalDefaultValues?: AllocationRuleTemplateFormProfile
  onSubmit: (formValues: AllocationRuleTemplateFormProfile) => Promise<void> | void
  weightClassUIStore: WeightClassContainerUIStore
  companyStore: CompanyStore
  openWithOnlyContainerNumber?: boolean
  onAddDestination: () => void
  onDeleteDestination: (id: number) => void
  onEditDestination: (id: number) => void
  onReOrderDestination: (startIndex: number, endIndex: number) => void
  destinationAndStackingStrategy: AllocationDestinationAndStackingStrategyDto[]
}

export const AllocationRuleTemplatesForm = ({
  id,
  allocationRuleTemplateForm,
  manualInputValidationStore,
  onSubmit,
  weightClassUIStore,
  companyStore,
  openWithOnlyContainerNumber,
  onAddDestination,
  onDeleteDestination,
  onEditDestination,
  onReOrderDestination,
  destinationAndStackingStrategy: defaultDestinationAndStackingStrategy,
}: Props) => {
  const { t } = useTranslate()

  const [isValidDangerousGoods, setIsValidDangerousGoods] = useState(true)
  const [isDangerousAlertMessage, setIsDangerousAlertMessage] = useState('')

  const { handleSubmit, control, formState } = allocationRuleTemplateForm

  useEffect(() => {
    weightClassUIStore.loadWeightClasses()
  }, [weightClassUIStore])

  const dangerousSelected = useWatch({ control, name: 'facets.isDangerous' })

  useEffect(() => {
    if (defaultDestinationAndStackingStrategy && defaultDestinationAndStackingStrategy.length > 0) {
      defaultDestinationAndStackingStrategy.forEach((destination: any) => {
        manualInputValidationStore.validate(destination.destination.block)
      })
      const validateDangerousGoodsSelection = () => {
        const { isValidDangerousGoods, isDangerousAlertMessage } =
          manualInputValidationStore.validateDGLocation(
            UtilityMappers.mapOptionalFieldValueToBoolean(dangerousSelected),
            true,
          )
        setIsValidDangerousGoods(isValidDangerousGoods)
        setIsDangerousAlertMessage(isDangerousAlertMessage)
      }
      validateDangerousGoodsSelection()
    }
  }, [dangerousSelected, defaultDestinationAndStackingStrategy, manualInputValidationStore])

  return (
    <Stack
      gap={1}
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControlledTextField
        formState={formState}
        control={control}
        name='name'
        label={t('name', 'Name')}
        required
        dataCy='allocation-name-field'
      />

      <AllocationRuleCommonFields
        control={control}
        formState={formState}
        weightClassesOptions={weightClassUIStore.weightClassesOptions}
        companies={companyStore.companies}
        openWithOnlyContainerNumber={openWithOnlyContainerNumber}
      />
      <AllocationRuleDestinationsList
        label={t('destinations', 'Destinations')}
        destinationAndStackingStrategies={defaultDestinationAndStackingStrategy}
        onAdd={onAddDestination}
        onEdit={onEditDestination}
        onDelete={onDeleteDestination}
        onReorder={onReOrderDestination}
      />
      {!isValidDangerousGoods && <Alert severity='warning'>{isDangerousAlertMessage}</Alert>}
    </Stack>
  )
}
