import { useTranslate } from '@tolgee/react'
import { ErrorCodes } from '@tom-ui/storage/app/api'
import { isApplicationDomainException } from '@tom-ui/storage/app/http-client/interceptors/domain-exception.response-interceptor'

export enum ContainerAlerts {
  DgStackingRulesViolationStackingOnTop,
  DgStackingRulesViolationMaxAllowedTier,
  DgSegregationRulesViolation,
  MixedFullAndEmpties,
  MixedDangerousAndNonDangerous,
  MixedDangerousareaNonDangerouscontainers,
  MixedNonDangerousareaDangerouscontainers,
  AllocationSpaceConflict,
  GenericError,
}

export const convertErrorCodeToContainerAlert = (error: any): ContainerAlerts => {
  if (isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationStackingOnTop)) {
    return ContainerAlerts.DgStackingRulesViolationStackingOnTop
  } else if (
    isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationMaxAllowedTier)
  ) {
    return ContainerAlerts.DgStackingRulesViolationMaxAllowedTier
  } else if (isApplicationDomainException(error, ErrorCodes.DgSegregationRulesViolation)) {
    return ContainerAlerts.DgSegregationRulesViolation
  } else if (isApplicationDomainException(error, ErrorCodes.AllocationSpaceConflict)) {
    return ContainerAlerts.AllocationSpaceConflict
  } else {
    return ContainerAlerts.GenericError
  }
}

export const useStorageContainerMessagesHandler = () => {
  const { t } = useTranslate()

  const getAlertMessages = (containerAlerts: ContainerAlerts[]) => {
    return containerAlerts.map(containerAlert => {
      switch (containerAlert) {
        case ContainerAlerts.DgSegregationRulesViolation:
          return t(
            'segregationRulesViolationPlan',
            'Be aware, you action violates the current segreation rules.',
          )

        case ContainerAlerts.DgStackingRulesViolationMaxAllowedTier:
          return t(
            'stackingValidationMaxStackTierPlan',
            "We can't plan at this position. There is a maximum tier stacking rule in place for this position.",
          )

        case ContainerAlerts.DgStackingRulesViolationStackingOnTop:
          return t(
            'stackingValidationStackingOnTopPlan',
            "We can't plan at this position. There is a stacking rule that prohibits stacking on top",
          )

        case ContainerAlerts.MixedFullAndEmpties:
          return t(
            'preplanningMixedFullAndEmpties',
            "Pay attention! you're planning full & empty containers",
          )

        case ContainerAlerts.MixedDangerousAndNonDangerous:
          return t(
            'planningContainerOfDifferentHandling',
            "Pay attention! you're planning dangerous & non dangerous containers",
          )

        case ContainerAlerts.MixedDangerousareaNonDangerouscontainers:
          return t(
            'dangerousLocationNonDangerousSelection',
            'You are trying to allocate non-dangerous goods to a dangerous goods location',
          )

        case ContainerAlerts.MixedNonDangerousareaDangerouscontainers:
          return t(
            'nonDangerousLocationDangerousSelection',
            'You are trying to allocate dangerous goods to a non-dangerous goods location',
          )

        case ContainerAlerts.AllocationSpaceConflict:
          return t('insufficientPlanningSpace', 'Insufficient planning space')

        default:
          return t('unhandledContainerMovementAlertType', 'Unhandled container movement alert type')
      }
    })
  }

  const getSuccessMessage = () => {
    return t('containerPlanned', 'Container planned')
  }

  return { getExceptionMessages: getAlertMessages, getSuccessMessage }
}
