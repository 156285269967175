import { CompanyDto } from '@billing/app/api'
import { CompanyStore } from '@billing/stores/CompanyStore'
import { action, computed, makeObservable, observable } from 'mobx'

export class CompanyTableUIStore {
  searchCompany = ''

  constructor(private companyStore: CompanyStore) {
    makeObservable(this, {
      searchCompany: observable,

      companies: computed,

      setSearchCompany: action,
    })
  }

  public setSearchCompany(company: string) {
    if (this.searchCompany !== company) {
      this.searchCompany = company
    }
  }

  public get companies(): CompanyDto[] {
    return [...this.companyStore.items]
      .sort(i => i.id)
      .filter(
        c =>
          this.doesCompanyHaveName(c, this.searchCompany) ||
          this.doesCompanyHaveShortName(c, this.searchCompany) ||
          this.doesCompanyHaveTaxId(c, this.searchCompany),
      )
  }

  private doesCompanyHaveName(company: CompanyDto, name?: string) {
    return !name || company.name.toLowerCase().includes(name.toLowerCase())
  }

  private doesCompanyHaveShortName(company: CompanyDto, name?: string) {
    return !name || company.shortName.toLowerCase().includes(name.toLowerCase())
  }

  private doesCompanyHaveTaxId(company: CompanyDto, name?: string) {
    return !name || company.taxId?.toLowerCase().includes(name.toLowerCase())
  }
}
