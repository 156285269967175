import { MenuItem, Stack } from '@mui/material'
import { Container } from '@mui/system'
import { GridColDef } from '@mui/x-data-grid'
import { ExternalDriverResponseDto } from '@planning/app/api'
import { PaginatedTable } from '@planning/components/PaginatedTable'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, ColorSchema, ThreeDotMenu, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  store: IPaginatedStoreWithItems<ExternalDriverResponseDto>
  onEdit: (item: ExternalDriverResponseDto) => void
  onRemove: (item: ExternalDriverResponseDto) => void
  onRemoveBan: (item: ExternalDriverResponseDto) => void
  onBan: (item: ExternalDriverResponseDto) => void
}

export const ExternalDriverList: FC<IProps> = observer(
  ({ store, onEdit, onRemove, onRemoveBan, onBan }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const columns: GridColDef<ExternalDriverResponseDto>[] = [
      {
        field: 'driverID',
        headerName: t('idNumber', 'ID number'),
        flex: 0.5,
      },
      {
        field: 'name',
        headerName: t('name', 'Name'),
        flex: 1,
      },
      {
        field: 'notes',
        headerName: t('notes', 'Notes'),
        flex: 2,
      },
      {
        field: 'banned',
        headerName: t('status', 'Status'),
        minWidth: 80,
        maxWidth: 80,
        renderCell: ({ row }) => (
          <ColoredLabel
            color={(row.banned ? 'warning' : 'info') as ColorSchema}
            label={`${row.banned ? t('banned', 'Banned') : t('active', 'Active')}`}
          />
        ),
      },
      {
        field: 'actions',
        headerName: '',
        minWidth: 25,
        sortable: false,
        renderCell: ({ row }) => (
          <Stack alignItems='end' width='100%'>
            <ThreeDotMenu>
              <MenuItem onClick={() => onEdit(row)}>{t('edit', 'Edit')}</MenuItem>
              {row.banned ? (
                <MenuItem onClick={() => onRemoveBan(row)}>{t('removeBan', 'Remove ban')}</MenuItem>
              ) : (
                <MenuItem onClick={() => onBan(row)}>{t('ban', 'Ban')}</MenuItem>
              )}
              <MenuItem onClick={() => onRemove(row)}>{t('delete', 'Delete')}</MenuItem>
            </ThreeDotMenu>
          </Stack>
        ),
      },
    ]

    return (
      <Container
        sx={{
          paddingY: theme.customSpacing.m,
        }}
      >
        <PaginatedTable
          store={store}
          columns={columns}
          rowsPerPageOptions={[10, 25, 50]}
          onCellClick={(column, event) => {
            if (column.field === 'actions') {
              event.stopPropagation()
            }
            event.preventDefault()
          }}
          onRowClick={({ row }) => {
            onEdit(row)
          }}
          getRowHeight={() => 'auto'}
        />
      </Container>
    )
  },
)
