import { useTranslate } from '@tolgee/react'
import {
  AirSurfaceIcon,
  AutoIcon,
  BulkIcon,
  FishIcon,
  FoldableIcon,
  GeneralIcon,
  LivestockIcon,
  NonCargoIcon,
  OpenTopIcon,
  PlataformIcon,
  ReeferIcon,
  TankIcon,
  UnknowIcon,
} from '../theme/icons'

type ContainerAttribute = {
  [key: string]: {
    label: string
    icon?: JSX.Element
  }
}

export const useContainerAttributesParameters = () => {
  const { t } = useTranslate()

  const types: ContainerAttribute = {
    Unknown: {
      label: t('unknown', 'Unknown'),
      icon: <UnknowIcon />,
    },
    NamedCargo: {
      label: t('namedCargo', 'Named cargo'),
      icon: <TankIcon />,
    },
    General: {
      label: t('generalPurpose', 'General purpose'),
      icon: <GeneralIcon />,
    },
    GeneralVentilated: {
      label: t('generalVentilated', 'General ventilated'),
      icon: <GeneralIcon />,
    },
    ReeferRefrigerated: {
      label: t('refrigerated', 'Refrigerated'),
      icon: <ReeferIcon />,
    },
    ReeferRefrigeratedAndHeated: {
      label: t('refrigeratedAndHeated', 'Refrigerated and heated'),
      icon: <ReeferIcon />,
    },
    ReeferSelfPowered: {
      label: t('selfPowered', 'Self-powered'),
      icon: <ReeferIcon />,
    },
    ReeferIntegratedMachinery: {
      label: t('integratedMachinery', 'Integrated machinery'),
      icon: <ReeferIcon />,
    },
    ReeferHeated: {
      label: t('heated', 'Heated'),
      icon: <ReeferIcon />,
    },
    ReeferRefrigeratedOrHeatedWithIntegratedEquipment: {
      label: t(
        'refrigeratedOrHeatedWithIntegratedEquipment',
        'Refrigerated and/or Heated with removable equipment',
      ),
      icon: <ReeferIcon />,
    },
    ReeferInsulated: {
      label: t('insulated', 'Insulated'),
      icon: <ReeferIcon />,
    },
    ReeferEutectic: {
      label: t('eutectic', 'Eutectic'),
      icon: <ReeferIcon />,
    },
    OpenTop: {
      label: t('openTop', 'Open Top'),
      icon: <OpenTopIcon />,
    },
    PlatformWithIncompleteSuperstructure: {
      label: t(
        'platformWithIncompleteSuperstructure',
        'Platform-based container with incomplete superstructure',
      ),
      icon: <PlataformIcon />,
    },
    PlatformFixed: {
      label: t('platformFixed', 'Fixed'),
      icon: <PlataformIcon />,
    },
    PlatformCollapsible: {
      label: t('platformCollapsible', 'Folding'),
      icon: <PlataformIcon />,
    },
    PlatformWithCompleteSuperstructure: {
      label: t(
        'platformWithCompleteSuperstructure',
        'Platform-based container with complete superstructure',
      ),
      icon: <PlataformIcon />,
    },
    PlatformNamedCargo: {
      label: t('platformNamedCargo', 'Platform-based container for named cargo'),
      icon: <PlataformIcon />,
    },
    TankPressurizedOrNonPressurizedDry: {
      label: t(
        'tankPressurizedOrNonPressurizedDry',
        'Pressurised and/or non-pressurised tank container (DRY)',
      ),
      icon: <TankIcon />,
    },
    TankPressurizedLiquid: {
      label: t('tankPressurizedLiquid', 'Pressurised tank container (liquids and gases)'),
      icon: <TankIcon />,
    },
    Bulk: {
      label: t('bulk', 'Bulk'),
      icon: <BulkIcon />,
    },
    Foldable: {
      label: t('foldable', 'Foldable'),
      icon: <FoldableIcon />,
    },
    AirSurface: {
      label: t('airSurface', 'Air Surface'),
      icon: <AirSurfaceIcon />,
    },
    Livestock: {
      label: t('livestock', 'Livestock'),
      icon: <LivestockIcon />,
    },
    Auto: {
      label: t('auto', 'Auto'),
      icon: <AutoIcon />,
    },
    Fish: {
      label: t('fish', 'Fish'),
      icon: <FishIcon />,
    },
    NonCargoCarrying: {
      label: t('nonCargoCarrying', 'Non Cargo'),
      icon: <NonCargoIcon />,
    },
  }

  const heights: ContainerAttribute = {
    Low: {
      label: t('low', 'Low'),
    },
    Standard: {
      label: t('standard', 'Standard'),
    },
    High: {
      label: t('high', 'High'),
    },
  }

  return { types, heights }
}
