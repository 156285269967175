import { Box } from '@mui/system'
import { useOperationsStore } from '@operations/AppProvider'
import { SearchInput } from '@operations/components/filter/SearchInput'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'

export const JobSearchContent = observer(() => {
  const { t } = useTranslate()

  const searchRef = useRef<HTMLDivElement>(null)

  const { equipmentOperatorUIStore, equipmentOperatorSearchUIStore } = useOperationsStore()

  const handleSearch = (newValue: string) => {
    equipmentOperatorSearchUIStore.setSearchText(newValue)
  }

  const getLabelText = () => {
    return equipmentOperatorUIStore.isContainerOperation
      ? t(
          'aContainerOrRefNumberOrPodOrCarrierIdentifier',
          'a container number / ref. number / POD / Carrier identifier',
        )
      : t(
          'aCommodityOrRefNumberOrLicensePlateOrContainerNumber',
          'a commodity / lot number / ref. number / truck license plate / container number',
        )
  }

  return (
    <Box height='3.3rem'>
      <SearchInput
        searchRef={searchRef}
        search={equipmentOperatorSearchUIStore.searchText ?? ''}
        label={getLabelText()}
        onSearch={handleSearch}
        onClearSearch={() => handleSearch('')}
      />
    </Box>
  )
})
