import { Container } from '@mui/material'
import { IVesselItem } from '@planning/rt-stores/vessel/VesselItem'
import { useTranslate } from '@tolgee/react'
import { NoListResult, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { VesselViewStore } from '../../stores/VesselViewStore'
import { VesselCard } from '../Molecules/VesselCard'

interface Props {
  viewStore: VesselViewStore
}
export const VesselList = observer(({ viewStore }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Container
      sx={{
        paddingY: theme.customSpacing.m,
      }}
    >
      {viewStore.items
        .sort((a, b) => a.data.name.localeCompare(b.data.name))
        .map((item: IVesselItem) => (
          <VesselCard
            key={item.id}
            vessel={item.data}
            highlightedText={viewStore.filter}
            onClickEdit={() => {
              viewStore.toggleDialog(true, item.data)
            }}
          />
        ))}
      {viewStore.items.length === 0 && (
        <NoListResult
          result={t('noVesselsFound', 'No vessels found')}
          filtering={viewStore.isFiltering}
        />
      )}
    </Container>
  )
})
