import { Button } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { TruckVisitsViewStore } from '../../Stores/TruckVisitsViewStore'

interface Props {
  store: TruckVisitsViewStore
}
export const TruckVisitClearFitlers = observer(({ store }: Props) => {
  const { t } = useTranslate()

  if (!store.isFiltering) return <></>

  return (
    <Button color='secondary' onClick={() => store.resetFilters()}>
      {t('clearAll', 'Clear all')}
    </Button>
  )
})
