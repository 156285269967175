import { ContainerHeight, ContainerPositionType, EquipmentType, JobDto } from '@operations/app/api'

export const isServiceLocation = (locationType: ContainerPositionType) =>
  locationType === ContainerPositionType.Repair ||
  locationType === ContainerPositionType.Warehouse ||
  locationType === ContainerPositionType.Customs ||
  locationType === ContainerPositionType.Weights

export const getContainerHeight = (containerHeight: ContainerHeight) => {
  switch (containerHeight) {
    case 'Standard':
      return 'DC'

    case 'High':
      return 'HC'

    case 'Low':
      return 'FR'
    default:
      return ' - '
  }
}

export const isCraneEquipmentType = (equipmentType: EquipmentType) =>
  equipmentType === EquipmentType.Sts

export const getJobOriginLocation = (jobDto: JobDto) => {
  return !jobDto.cargoUnit?.displayName && jobDto.suggestedEmptyContainer
    ? jobDto.suggestedEmptyContainer?.currentLocation
    : jobDto.from
}

export const getEmptyContainerLabelInfo = (
  number: string,
  locationName: string,
  isoCode: string,
) => {
  return `${number} \u00A0\u00A0\u00A0\ ${locationName} \u00A0\u00A0\u00A0\ ${isoCode}`
}
