import { Box } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { PageHeader } from '@planning/components/molecules/PageHeader'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useOrderBreadcrumbs } from '../../hooks/useOrderBreadcrumbs'
import { HandleOrderPageLocationState } from '../Order'
import { CheckoutContainerViewStore } from './CheckoutContainerViewStore'
import CheckoutContainerForm from './components/Organism/CheckoutContainerForm'

const DEFAULT_RETURN_PATH = PATH_PLANNING.visits

export const CheckoutContainerPage: FC = observer(() => {
  const { orderItemStore, containerItemStore } = usePlanningStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigate = useNavigate()

  const {
    getVesselVisitCheckoutContainerBreadcrumbs,
    getRailVisitCheckoutContainerBreadcrumbs,
    getContainerVisitCheckoutContainerBreadcrumbs,
  } = useOrderBreadcrumbs()

  const store = useMemo(
    () => new CheckoutContainerViewStore(containerItemStore, orderItemStore),
    [containerItemStore, orderItemStore],
  )

  const { state, pathname } = useLocation() as {
    state: HandleOrderPageLocationState
    pathname: string
  }

  const { orderId: orderIdParam } = useParams()
  const orderId = Number(orderIdParam)

  const { loading: loadingOrder } = useAsyncFetch(async () => {
    store.setOrderId(orderId)
    await orderItemStore.fetchById(orderId)
  }, [orderId, orderItemStore, store])

  const { loading: loadingContainer } = useAsyncFetch(async () => {
    if (store.order?.containerId) {
      await containerItemStore.fetchById(store.order.containerId)
    }
  }, [store.order?.containerId, containerItemStore])

  const onBack = () => {
    navigate(state?.returnPath || DEFAULT_RETURN_PATH)
  }

  const getRouteBreadcrumbs = useCallback(
    (pathname: string) => {
      if (!store.order) return []

      const path = pathname.split('/').filter(Boolean)

      const isVesselVisit = path.includes('vessel-visits')
      const isRailVisit = path.includes('rail-visits')
      const isContainerVisits = path.includes('container-visits')

      if (isVesselVisit) {
        return getVesselVisitCheckoutContainerBreadcrumbs(store.order)
      } else if (isRailVisit) {
        return getRailVisitCheckoutContainerBreadcrumbs(store.order)
      } else if (isContainerVisits) {
        return getContainerVisitCheckoutContainerBreadcrumbs(store.order)
      } else {
        return []
      }
    },
    [
      getVesselVisitCheckoutContainerBreadcrumbs,
      getRailVisitCheckoutContainerBreadcrumbs,
      getContainerVisitCheckoutContainerBreadcrumbs,
      store.order,
    ],
  )

  return (
    <InitializationWrapper
      isInitializing={
        store.isLoading || loadingOrder || loadingContainer || !store.order || !store.container
      }
    >
      <>
        <Breadcrumbs items={getRouteBreadcrumbs(pathname)} />
        <PageHeader
          formId='checkout-container-form'
          onBack={onBack}
          saveTitle={t('checkoutContainer', 'Check out')}
          pageTitle={t('checkoutContainer', 'Check out container')}
        ></PageHeader>

        <Box
          paddingX={theme.customSpacing.l}
          paddingY={theme.customSpacing.m}
          gap={theme.customSpacing.m}
          display={'flex'}
          flexDirection={'column'}
        >
          {store.container && store.order && (
            <CheckoutContainerForm
              container={store.container}
              order={store.order}
              onBack={() => onBack()}
            />
          )}
        </Box>
      </>
    </InitializationWrapper>
  )
})
