import { Chip } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'

type YesNoString = 'Yes' | 'No'
interface Props {
  activeOption: YesNoString
  disableYes?: boolean
  dataCyYes?: string
  disableNo?: boolean
  onClickYes?: () => Promise<void> | void
  onClickNo?: () => Promise<void> | void
}

export const YesNoChipBox: FC<Props> = ({
  activeOption: active,
  disableYes,
  dataCyYes,
  disableNo,
  onClickYes,
  onClickNo,
}) => {
  const { t } = useTranslate()

  const getColor = (option: YesNoString) => (active == option ? 'primary' : 'default')

  return (
    <Box display='flex' alignItems='center'>
      <Chip
        disabled={disableYes}
        label={t('yes', 'Yes')}
        color={getColor('Yes')}
        data-cy={dataCyYes}
        onClick={onClickYes}
        sx={{ mr: '.5rem' }}
      />
      <Chip disabled={disableNo} label={t('no', 'No')} color={getColor('No')} onClick={onClickNo} />
    </Box>
  )
}
