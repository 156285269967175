import {
  CarrierVisitStatus,
  ContainerJourneyResponseDto,
  ContainerPositionType,
  OrderStatus,
} from '@planning/app/api'
import { ContainerJourneyStatus } from '@planning/components/molecules/ContainerJourneyStatusChip'
import { ContainerVisitStatus } from '@planning/pages/Order/components/OrdersListCard'
import { EntityItem } from '@planning/rt-stores/base/EntityItem'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { IEntity } from '@planning/rt-stores/types'
import { computed, makeObservable } from 'mobx'
import { ContainerJourneyItemStore } from './ContainerJourneyItemStore'

export interface IContainerJourneyItem extends IEntity<ContainerJourneyResponseDto> {
  status: ContainerJourneyStatus
  inboundOrder?: IOrderItem
  inboundOrderStatus?: OrderStatus
  inboundCarrierVisitStatus?: CarrierVisitStatus
  outboundOrder?: IOrderItem
  outboundOrderStatus?: OrderStatus
  outboundCarrierVisitStatus?: CarrierVisitStatus
}

export class ContainerJourneyItem
  extends EntityItem<ContainerJourneyResponseDto>
  implements IContainerJourneyItem
{
  constructor(
    public containerJourneyItemStore: ContainerJourneyItemStore,
    id: number,
    data: ContainerJourneyResponseDto,
    public orderItemStore?: OrderItemStore,
  ) {
    super(id, data)

    makeObservable(this, {
      status: computed,
      inboundOrder: computed,
      inboundOrderStatus: computed,
      inboundCarrierVisitStatus: computed,
      outboundOrder: computed,
      outboundOrderStatus: computed,
      outboundCarrierVisitStatus: computed,
    })
  }

  get status(): ContainerJourneyStatus {
    // todo: consolidate with ContainerJourneyDataStore.getContainerJourneyStatus
    if (
      this.outboundCarrierVisitStatus === CarrierVisitStatus.Departed ||
      this.outboundOrderStatus === OrderStatus.Fulfilled
    )
      return ContainerJourneyStatus.Departed

    if (this.data.currentPosition === ContainerPositionType.Equipment)
      return ContainerJourneyStatus.OnTerminal

    if (this.data.currentPosition === ContainerPositionType.Yard)
      return ContainerJourneyStatus.OnYard

    if (this.inboundOrderStatus === OrderStatus.Fulfilled) return ContainerJourneyStatus.OnTerminal

    if (
      this.inboundCarrierVisitStatus === CarrierVisitStatus.Arrived ||
      this.inboundOrder?.visit?.data.status !== CarrierVisitStatus.Expected
    )
      return ContainerJourneyStatus.Arrived

    return ContainerVisitStatus.Expected
  }

  get inboundOrder() {
    return this.orderItemStore?.elements[this.data.inboundOrderId]
  }

  get inboundOrderStatus() {
    return this.inboundOrder?.data.status
  }

  get inboundCarrierVisitStatus() {
    return this.inboundOrder?.visit?.data.status
  }

  get outboundOrder() {
    return this.orderItemStore?.elements[this.data.outboundOrderId ?? -1]
  }

  get outboundOrderStatus() {
    return this.outboundOrder?.data.status
  }

  get outboundCarrierVisitStatus() {
    return this.outboundOrder?.visit?.data.status
  }
}
