/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, ReactElement } from 'react'
import { MoreIcon } from '../../icons'
import { MenuPopperIconButton } from '../menuPopperIconButton/MenuPopperIconButton'

interface Props {
  children: ReactElement[] | ReactElement
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
}

export const ThreeDotMenu: FC<Props> = ({ children, color }: Props) => {
  return (
    <MenuPopperIconButton icon={<MoreIcon />} color={color}>
      {children}
    </MenuPopperIconButton>
  )
}
