import { TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useFormContext } from 'react-hook-form'

interface Props {
  dischargeLabel?: string
  loadLabel?: string
}
export const VisitEstimatesFields = observer(({ dischargeLabel, loadLabel }: Props) => {
  const { t } = useTranslate()

  const { register, watch } = useFormContext()

  const dischargeEstimateValue = watch('dischargeEstimate')
  const loadEstimateValue = watch('loadEstimate')

  return (
    <>
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('dischargeLoadEstimates', 'Discharge / Load Estimates')}
      </Typography>
      <Stack direction='row' spacing={1}>
        <TextField
          fullWidth
          type='number'
          data-cy='create-vessel-visit-form-discharge-estimate-field'
          label={`${dischargeLabel ?? t('dischargeEstimates', 'Discharge Estimates')}`}
          variant='outlined'
          {...register('dischargeEstimate')}
          name='dischargeEstimate'
          value={dischargeEstimateValue || ''}
          onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
          inputProps={{ min: 1 }}
        />

        <TextField
          fullWidth
          type='number'
          data-cy='create-vessel-visit-form-load-estimate-field'
          label={`${loadLabel ?? t('loadEstimates', 'Load Estimates')}`}
          variant='outlined'
          {...register('loadEstimate')}
          name='loadEstimate'
          value={loadEstimateValue || ''}
          onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
          inputProps={{ min: 1 }}
        />
      </Stack>
    </>
  )
})
