import { Paper, Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { BookingsResponseDto } from '@planning/app/api'
import { CellWithTitleAndSubtitle } from '@planning/components/atoms/CellWithTitleAndSubtitle'
import { PaginatedTable } from '@planning/components/PaginatedTable'
import { emptyIndicator } from '@planning/constants'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

interface Props {
  store: IPaginatedStoreWithItems<BookingsResponseDto>
}

export const BookingsTable = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const columns: GridColDef[] = [
    {
      field: 'referenceNumber',
      headerName: t('bookingNo', 'Booking no.'),
      renderCell: ({ row }) => {
        return (
          <CellWithTitleAndSubtitle
            title={row.referenceNumber ?? emptyIndicator}
            subtitle={row.shippingLine ?? emptyIndicator}
          />
        )
      },
      flex: 2,
    },
    {
      field: 'identifier',
      headerName: t('carrier', 'Carrier'),
      flex: 0.75,
      renderCell: ({ row }) => (
        <CellWithTitleAndSubtitle
          title={row.carrierVisit.identifier ?? emptyIndicator}
          subtitle={`ETA: ${formatDateTime(row.carrierVisit.eta) ?? emptyIndicator}`}
        />
      ),
    },
    {
      field: 'tripIds',
      headerName: t('tripIds', 'Trip IDs'),
      renderCell: ({ row }) => {
        const inboundTripIds =
          row.inboundTripIds && row.inboundTripIds.length > 0 && row.inboundTripIds[0] !== ''

        const outboundTripIds =
          row.outboundTripIds && row.outboundTripIds.length > 0 && row.outboundTripIds[0] !== ''

        return (
          <Stack>
            {inboundTripIds && (
              <Stack flexDirection='row' gap={1}>
                <Typography variant='body2'>{t('in', 'In')}:</Typography>
                <Typography variant='body2'>
                  {row.inboundTripIds?.filter((id: string) => id.trim() !== '').join(', ')}
                </Typography>
              </Stack>
            )}
            {outboundTripIds && (
              <Stack flexDirection='row' gap={1}>
                <Typography variant='body2'>{t('out', 'Out')}:</Typography>
                <Typography variant='body2'>
                  {row.outboundTripIds?.filter((id: string) => id.trim() !== '').join(', ')}
                </Typography>
              </Stack>
            )}
            {!inboundTripIds && !outboundTripIds && emptyIndicator}
          </Stack>
        )
      },
      minWidth: 100,
      sortable: false,
      flex: 0.75,
    },
    {
      field: 'portOfDischarge',
      headerName: t('pod', 'POD'),
      flex: 0.5,
      renderCell: ({ row }) => row.portOfDischarge ?? emptyIndicator,
    },
    {
      field: 'finalDestination',
      headerName: t('finalDestination', 'Final Destination'),
      flex: 0.5,
      renderCell: ({ row }) => row.finalDestination ?? emptyIndicator,
    },

    {
      field: 'units',
      headerName: t('units', 'Units'),
      flex: 0.5,
      renderCell: ({ row }) => `${row.completedUnits}/${row.units}`,
    },
  ]

  return (
    <Paper variant='elevation' elevation={1} sx={{ overflow: 'hidden' }}>
      <PaginatedTable
        store={store}
        getRowId={row =>
          `${row.referenceNumber}-${row.carrierVisit.identifier}-${row.units}-${row.carrierVisit.id}`
        }
        columns={columns}
        onRowClick={({ row }) => {
          navigate(`/bookings/${row.referenceNumber}/visit/${row.carrierVisit.id}/orders`)
        }}
      />
    </Paper>
  )
})
