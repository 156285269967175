import { useBillingServiceStore } from '@billing/AppProvider'
import { Asterisk } from '@billing/components/asterisk/Asterisk'
import { CustomerAutocomplete } from '@billing/components/CustomerAutocomplete'
import { Box, Stack, Typography } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

export const CustomerBillingReportFilter = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { companyStore, customerBillingUIStore, reportsUIStore } = useBillingServiceStore()
  const localFormate = dayjs.Ls[dayjs.locale()].formats.L

  useEffect(() => {
    async function fetchData() {
      await companyStore.load()
    }

    fetchData()
  }, [companyStore])

  const customerInputHasError =
    reportsUIStore.nextButtonWasBlocked && !customerBillingUIStore.customerId

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={theme.customSpacing.m}>
      <CustomerAutocomplete
        selectedValue={customerBillingUIStore.customerId}
        options={customerBillingUIStore.customerOptions}
        hasError={customerInputHasError}
        onChange={(value: string) => {
          customerBillingUIStore.setCustomerId(value)
        }}
      />

      <Stack gap={theme.customSpacing.xs}>
        <Typography variant='subtitle2'>
          {t('period', 'Period')} {Asterisk}
        </Typography>
        <Stack
          gap={theme.customSpacing.m}
          sx={{
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          }}
        >
          <MobileDatePicker
            label={t('from', 'From')}
            value={customerBillingUIStore.from}
            onChange={newValue => customerBillingUIStore.setFrom(newValue)}
            disableFuture={true}
            maxDate={customerBillingUIStore.to}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept', 'today'],
              },
              textField: {
                required: true,
                error:
                  reportsUIStore.nextButtonWasBlocked && !customerBillingUIStore.from
                    ? true
                    : undefined,
                helperText: localFormate,
              },
            }}
            sx={{
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '268px',
              },
            }}
          />
          <MobileDatePicker
            label={t('to', 'To')}
            value={customerBillingUIStore.to}
            onChange={newValue => customerBillingUIStore.setTo(newValue)}
            disableFuture={true}
            minDate={customerBillingUIStore.from}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept', 'today'],
              },
              textField: {
                required: true,
                error:
                  reportsUIStore.nextButtonWasBlocked && !customerBillingUIStore.to
                    ? true
                    : undefined,
                helperText: localFormate,
              },
            }}
            sx={{
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '268px',
              },
            }}
          />
        </Stack>
      </Stack>
    </Box>
  )
})
