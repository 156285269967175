import { IFilterDelegate } from '@planning/stores/PaginatedLocalStore'
import { ISearchAutocompleteStore } from '@planning/stores/SearchAutocompleteStore'
import { action, computed, makeObservable, observable } from 'mobx'

export class LocalSearchAutocompleteStore<T> implements ISearchAutocompleteStore<T> {
  filter = ''
  value?: T
  isLoading = false

  constructor(
    private getFunc: () => T[],
    private filterDelegate: IFilterDelegate<T>,
  ) {
    makeObservable(this, {
      value: observable,
      filter: observable,

      items: computed,
      filteredItems: computed,

      setFilter: action,
      setValue: action,
    })
  }

  fetch = () => Promise.resolve()

  get items() {
    return this.getFunc().filter(item => this.filterDelegate(this.filter, item))
  }

  get filteredItems() {
    return this.items
  }

  setFilter = (filter: string) => {
    this.filter = filter
  }

  setValue = (value?: T) => {
    this.value = value
  }

  reset = () => {
    this.setFilter('')
    this.setValue()
  }
}
