import { Box, Button, ListItemText, MenuItem, Stack, Typography } from '@mui/material'
import { IssueStatus } from '@planning/app/api'
import { FilterButtonGroup } from '@planning/components/molecules/FilterButtonGroup'
import { useTranslate } from '@tolgee/react'
import { ChipWithOptions } from '@tom-ui/ui'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'

interface Props {
  issuesTypeCount: {
    all: number
    unresolved: number
    followUp: number
  }
  selectedStatus?: IssueStatus
  selectedType?: string
  issueTypes: string[]
  handleSetIssueFilterStatus: (type?: IssueStatus) => void
  handleSetIssueFilterType: (type?: string) => void
  onClearAll?: () => void
}

export const IssuesTypeFilter = observer(
  ({
    selectedStatus,
    selectedType,
    issueTypes,
    issuesTypeCount,
    handleSetIssueFilterType,
    handleSetIssueFilterStatus,
    onClearAll,
  }: Props) => {
    const { t } = useTranslate()

    const handleFilterStatusChange = (key: string) => {
      if (key === 'all') {
        handleSetIssueFilterStatus()
      } else {
        handleSetIssueFilterStatus(key as IssueStatus)
      }
    }

    const handleFilterTypeChange = (key: string) => {
      if (key === 'all') {
        handleSetIssueFilterType()
      } else {
        handleSetIssueFilterType(key)
      }
    }

    const typeOptions = computed(() => issueTypes.map(x => ({ text: t(x), key: x }))).get()

    const statusOptions = computed(() => [
      {
        text: t('all', 'All'),
        count: issuesTypeCount.all,
        key: 'all',
      },
      {
        text: t('unresolved', 'Unresolved'),
        count: issuesTypeCount.unresolved,
        key: IssueStatus.Unresolved,
      },
      {
        text: t('needFollowUp', 'Need follow up'),
        count: issuesTypeCount.followUp,
        key: IssueStatus.FollowUp,
      },
    ]).get()

    return (
      <Stack flexDirection='row' gap={1} alignItems='center'>
        <Box paddingLeft={3}>
          <ChipWithOptions
            value={selectedStatus ?? 'all'}
            label={t('status', 'Status')}
            options={statusOptions.map(option => (
              <MenuItem
                key={option.key}
                selected={option.key === selectedStatus}
                value={option.key}
                onClick={() => {
                  handleFilterStatusChange(option.key)
                }}
              >
                <ListItemText primary={option.text} primaryTypographyProps={{ variant: 'body2' }} />
                <Typography variant='body2' color='secondary'>
                  {option.count}
                </Typography>
              </MenuItem>
            ))}
          />
        </Box>

        <FilterButtonGroup
          filterOptions={typeOptions}
          selectedFilterKey={selectedType ?? 'all'}
          onSelectedFilterChange={handleFilterTypeChange}
        />

        {onClearAll && (!!selectedStatus || !!selectedType) && (
          <Button color='secondary' onClick={onClearAll}>
            {t('clearAll', 'Clear all')}
          </Button>
        )}
      </Stack>
    )
  },
)
