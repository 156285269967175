import { useAdminStore } from '@admin/AppProvider'
import { Box } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { ArchetypeHeader } from './components/organisms/ArchetypeHeader'
import { ArchetypeList } from './components/organisms/ArchetypeList'
import { ArchetypeUIStore } from './stores/archetype.ui-store'

export const ArchetypeListPage: FC = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { archetypeStore } = useAdminStore()

  const archetypeUIStore = useMemo(() => new ArchetypeUIStore(archetypeStore), [archetypeStore])

  useEffect(() => {
    archetypeUIStore.archetypeStore.load()
  }, [archetypeUIStore.archetypeStore])

  return (
    <Box
      sx={{
        height: '100%',
        bgcolor: theme.palette.grey[100],
      }}
    >
      <Helmet>
        <title>{t('archetypes', 'Archetypes')}</title>
      </Helmet>
      <ArchetypeHeader viewStore={archetypeUIStore} />
      <ArchetypeList viewStore={archetypeUIStore} />
    </Box>
  )
})
