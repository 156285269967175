import { CarrierType } from '@planning/app/api'
import { ContainerJourneyItemStore } from '@planning/pages/Issues/Stores/ContainerJourneyItemStore'
import { ILocalDataStore } from '@planning/stores/PaginatedLocalStore'
import _ from 'lodash'
import { computed, makeObservable, observable, reaction } from 'mobx'
import { ContainerItemStore } from '../container/ContainerItemStore'
import { IOrderItem } from '../order/OrderItem'
import { OrderItemStore } from '../order/OrderItemStore'
import { RailVisitItemStore } from '../railVisit/RailVisitItemStore'
import { TruckItemStore } from '../truck/TruckItemStore'
import { TruckVisitItemStore } from '../truckVisit/TruckVisitItemStore'
import { VesselItemStore } from '../vessel/VesselItemStore'
import { VesselVisitItemStore } from '../vesselVisit/VesselVisitItemStore'
import { NnrOrderItemStore } from './NnrOrderItemStore'

export class NnrOrderLocalStore implements ILocalDataStore<IOrderItem> {
  nnrOrderId: number

  constructor(
    private itemStore: NnrOrderItemStore,
    private orderItemStore: OrderItemStore,
    private containerItemStore: ContainerItemStore,
    private containerJourneyItemStore: ContainerJourneyItemStore,
    private vesselVisitItemStore: VesselVisitItemStore,
    private railVisitItemStore: RailVisitItemStore,
    private truckVisitItemStore: TruckVisitItemStore,
    private vesselItemStore: VesselItemStore,
    private truckItemStore: TruckItemStore,
    nnrOrderId: number,
  ) {
    makeObservable(this, {
      nnrOrderId: observable,
      items: computed,
      nnrOrder: computed,
      containerIds: computed,
      vesselVisitIds: computed,
      railVisitIds: computed,
      truckVisitIds: computed,
      linkedOrderIds: computed,
      inboundOrderIds: computed,
    })

    this.nnrOrderId = nnrOrderId

    reaction(() => this.containerIds, this.fetchContainers)
    reaction(() => this.containerIds, this.fetchContainerJourneys)
    reaction(() => this.vesselVisitIds, this.fetchVesselVisits)
    reaction(() => this.railVisitIds, this.fetchRailVisits)
    reaction(() => this.truckVisitIds, this.fetchTruckVisits)
    reaction(() => this.vesselIds, this.fetchVessels)
    reaction(() => this.truckIds, this.fetchTrucks)
    reaction(() => this.inboundOrderIds, this.fetchOrders)
  }

  fetch = async () => {
    this.fetchNNROrder(this.nnrOrderId)

    if (!this.nnrOrder) return
    await this.orderItemStore.fetchByIds(this.nnrOrder.data.orderIds)
  }

  fetchNNROrder = async (nnrOrderId: number) => {
    await this.itemStore.fetchByIds([nnrOrderId])
  }

  fetchOrders = async (orderIds: number[]) => {
    await this.orderItemStore.fetchByIds(orderIds)
  }

  fetchContainers = async (containerIds: number[]) => {
    await this.containerItemStore.fetchByIds(containerIds)
  }

  fetchContainerJourneys = async (containerIds: number[]) => {
    await this.containerJourneyItemStore.fetch(containerIds)
  }

  fetchVesselVisits = async (ids: number[]) => {
    return this.vesselVisitItemStore.fetchByIds(ids)
  }

  fetchRailVisits = async (ids: number[]) => {
    return this.railVisitItemStore.fetchByIds(ids)
  }

  fetchTruckVisits = async (ids: number[]) => {
    return this.truckVisitItemStore.fetchByIds(ids)
  }

  fetchVessels = async (ids: number[]) => {
    return this.vesselItemStore.fetchByIds(ids)
  }

  fetchTrucks = async (ids: number[]) => {
    return this.truckItemStore.fetchByIds(ids)
  }

  get items() {
    return _(this.orderItemStore.elements)
      .values()
      .filter(o => this.nnrOrder.data.orderIds.includes(o.id))
      .value()
  }

  get linkedOrderIds() {
    return this.items.filter(i => i.data.linkedOrderId).map(i => i.data.linkedOrderId!)
  }

  get containerIds() {
    return this.items.filter(i => i.data.containerId).map(i => i.data.containerId!)
  }

  get nnrOrder() {
    return this.itemStore.elements[this.nnrOrderId]
  }

  get inboundOrderIds() {
    return _(this.containerJourneyItemStore.elements)
      .values()
      .map(j => j.data.inboundOrderId)
      .value()
  }

  get railVisitIds() {
    return _(this.orderItemStore.elements)
      .filter(ele => !!ele.data.carrierVisitId && ele.data.carrierVisitType === CarrierType.Train)
      .map(ele => ele.data.carrierVisitId!)
      .uniq()
      .compact()
      .value()
  }

  get truckVisitIds() {
    return _(this.orderItemStore.elements)
      .filter(ele => !!ele.data.carrierVisitId && ele.data.carrierVisitType === CarrierType.Truck)
      .map(ele => ele.data.carrierVisitId!)
      .uniq()
      .compact()
      .value()
  }

  get vesselVisitIds() {
    return _(this.orderItemStore.elements)
      .filter(ele => !!ele.data.carrierVisitId && ele.data.carrierVisitType === CarrierType.Vessel)
      .map(ele => ele.data.carrierVisitId!)
      .uniq()
      .compact()
      .value()
  }

  get vesselIds() {
    return _(this.vesselVisitItemStore.elements)
      .map(v => v.data.carrierIds)
      .flatten()
      .uniq()
      .value()
  }

  get truckIds() {
    return _(this.truckVisitItemStore.elements)
      .map(v => v.data.carrierIds)
      .flatten()
      .uniq()
      .value()
  }
}
