import { Box } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { AddOrderButton } from '@planning/components/AddOrderButton'
import { UploadOrdersButton } from '@planning/components/UploadOrdersButton'
import { ListHeader } from '@planning/components/list/ListHeader'
import { containerService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { ContainerYardOperationViewStore } from '../VesselVisit/Components'
import { OrderListUploadDialogV2 } from '../VesselVisit/Components/Upload/OrderListUploadDialog'
import { CreateOrdersPage } from './CreateOrders'
import { SearchContainerJourneyVisit } from './components/SearchContainerJourneyVisit'
import { IControlledIsoCodeMappingInputProps } from './stores/IsoCodeMappingInputStore'

export interface IOrdersPage {
  renderIsoCodeMappingInput?: (
    props: IControlledIsoCodeMappingInputProps<any>,
  ) => React.ReactElement
  containerYardOperationViewStore: ContainerYardOperationViewStore
}

export const OrdersPage: FC<IOrdersPage> = observer(
  ({ renderIsoCodeMappingInput, containerYardOperationViewStore }) => {
    const {
      containerAutocompleteStore,
      orderListStore,
      selectOrderViewStore,
      orderListUploadViewStoreV2,
      dialogStore,
      isoCodeMappingInputStore,
    } = usePlanningStore()
    const { t } = useTranslate()

    const { containerNumber } = useParams()

    const [isInitialized, setIsInitialized] = useState(false)

    isoCodeMappingInputStore.renderIsoCodeMappingInput = renderIsoCodeMappingInput

    useEffect(() => {
      if (containerNumber) {
        containerAutocompleteStore.setFilter(containerNumber)
        containerService.getContainersByPartialNumber(containerNumber).then(c => {
          if (c.length > 0) {
            selectOrderViewStore.selectContainer(c[0])
            if (
              c[0].id &&
              selectOrderViewStore.containerJourneyDataStore.items[0]?.containerId !== c[0].id
            ) {
              selectOrderViewStore.containerJourneyDataStore.fetch({
                containerId: c[0].id,
                activeOrderIds: [],
                includeCompleted: false,
                includeUnlinkedOutboundOrders: false,
              })
            }
          }
        })
        orderListStore.setFilter(containerNumber)
      } else {
        containerAutocompleteStore.reset()
        selectOrderViewStore.containerJourneyDataStore.reset()
      }

      setIsInitialized(true)
    }, [containerAutocompleteStore, containerNumber, orderListStore, selectOrderViewStore])

    const handleUpload = () => {
      orderListUploadViewStoreV2.setOpen(true)
    }

    const resetStores = () => {
      containerAutocompleteStore.reset()
      selectOrderViewStore.reset()
      selectOrderViewStore.containerJourneyDataStore.reset()
      orderListStore.reset()
    }

    if (!containerNumber && !selectOrderViewStore.containerJourneyDataStore.items) resetStores()

    return isInitialized ? (
      <Box padding='1rem'>
        <ListHeader
          title={t('containers', 'Containers')}
          actions={[
            <AddOrderButton
              key='addOrderButton'
              onClick={() => {
                resetStores()
                orderListUploadViewStoreV2.reset()

                dialogStore.openDialog(<CreateOrdersPage />)
              }}
            />,
            <UploadOrdersButton key='uploadDocuments' onClick={handleUpload} />,
          ]}
        />

        <SearchContainerJourneyVisit
          searchStore={containerAutocompleteStore}
          listStore={selectOrderViewStore.containerJourneyDataStore}
          viewStore={selectOrderViewStore}
          showHavingOpenOrdersWarning={false}
          onReset={resetStores}
          containerYardOperationViewStore={containerYardOperationViewStore}
        />

        <OrderListUploadDialogV2
          enableFieldSelectionFromOrdersView={true}
          open={orderListUploadViewStoreV2.open}
          handleDialogClose={() => orderListUploadViewStoreV2.reset()}
        />
      </Box>
    ) : (
      <></>
    )
  },
)
