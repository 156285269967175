import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { Header, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { ExternalDriverViewStore } from '../../Stores/ExternalDriverViewStore'

interface IProps {
  viewStore: ExternalDriverViewStore
}

export const ExternalDriverHeader: FC<IProps> = observer(({ viewStore }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigate = useNavigate()

  const onSearch = (value: string) => {
    viewStore.setFilter(value)
  }

  return (
    <Header
      title={t('externalDrivers', 'External Drivers')}
      searchInputLabel={t('searchIdNumberOrName', 'Search fID number or name')}
      onSearchFieldChange={onSearch}
      displaySearchIcon
      enableSearchField
      rightRenderOption={
        <Stack flexDirection='row' gap={theme.customSpacing.xs}>
          <Button
            color='inherit'
            variant='contained'
            sx={{ flex: 'none' }}
            startIcon={<PlusIcon />}
            onClick={() => {
              viewStore.setDataToBeEdited()
              navigate('/registers/external-driver-form')
            }}
          >
            {t('addNew', 'Add new')}
          </Button>
        </Stack>
      }
    />
  )
})
