import { Stack, Tooltip, Typography } from '@mui/material'
import { UnitType } from '@planning/app/api'
import { useUnitTypeIcon, useUnitTypeLabel } from '@planning/components/UnitType'
import { useTranslate } from '@tolgee/react'
import { formatContainerNumber } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'

export interface Props {
  number?: string | null
  unitType?: UnitType
}
export const CargoTitle = observer(({ number, unitType }: Props) => {
  const { t } = useTranslate()
  const unitTypeIcon = useUnitTypeIcon(unitType)
  const unitTypeLabel = useUnitTypeLabel(unitType)

  return (
    <Stack flexDirection='row' gap={1} alignItems='center'>
      <Tooltip title={unitTypeLabel} placement='bottom'>
        {unitTypeIcon}
      </Tooltip>
      <Typography variant='body2'>
        {number ? formatContainerNumber(number) : t('notAssigned', 'Not assigned')}
      </Typography>
    </Stack>
  )
})
