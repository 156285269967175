import { Alert } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { NoListResult, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IsoCodeMappingUIStore } from '../stores/iso-code-mapping.ui-store'
import { IsoCodeMappingCard } from './IsoCodeMappingCard'

interface IProps {
  viewStore: IsoCodeMappingUIStore
}

export const IsoCodeMappingList: FC<IProps> = observer(({ viewStore }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const hasFilteredItems =
    viewStore.filter && (!viewStore.isoCodeMappings || viewStore.isoCodeMappings.length === 0)

  return (
    <Container
      sx={{
        paddingY: theme.customSpacing.m,
      }}
    >
      {(!viewStore.filter || viewStore.filter.length !== 4) && (
        <Alert severity='info' sx={{ marginBottom: theme.customSpacing.s }}>
          {t('onlyCustomIsoCodesAreShownHere', 'Only custom ISO codes are shown here')}
        </Alert>
      )}
      {hasFilteredItems && (
        <NoListResult
          result={t('noIsoCodeFound', 'No ISO code found')}
          filtering={hasFilteredItems}
        />
      )}
      {viewStore.isoCodeMappings.map(item => (
        <IsoCodeMappingCard
          key={item.id}
          isoCodeMapping={item}
          filter={viewStore.filter}
          onClickEdit={() => {
            viewStore.toggleFormDialog(true, item)
          }}
        />
      ))}
    </Container>
  )
})
