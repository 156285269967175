import { IssueDto, IssueStatus, IssueType, ObjectType } from '@operations/app/api'
import { IssueStore } from '@operations/stores/IssueStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export class IssueWithJourney implements IssueDto {
  'id': number
  'orderId'?: number | null | undefined
  'issueType': IssueType
  'issueStatus': IssueStatus
  'objectType': ObjectType
  'objectId': number
  'notes'?: string | null | undefined
  'carrierVisitIds': Array<number>

  'containerJourney': any //should be filled with octa info
  getContainerNumber(): string {
    return (
      this.containerJourney?.inboundOrder?.order?.containerNumber ||
      this.containerJourney?.outboundOrder?.order?.containerNumber
    )
  }
}

export class IssueUIStore {
  issues: IssueWithJourney[] = []
  issueFilterStatus?: IssueStatus
  issueFilterType?: IssueType
  containerNumberFilter? = ''
  carrierNameFilter? = ''
  isFilterEnabled = false
  filterByVisitId?: number
  filterByRailcarTrackPositionId?: number

  constructor(private issueStore: IssueStore) {
    makeObservable(this, {
      issues: observable,
      issueFilterStatus: observable,
      issueFilterType: observable,
      containerNumberFilter: observable,
      carrierNameFilter: observable,
      isFilterEnabled: observable,
      filterByVisitId: observable,
      filterByRailcarTrackPositionId: observable,

      fetch: action,
      setContainerNumberFilter: action,
      setIssueFilterStatus: action,
      setIssueFilterType: action,
      setCarrierNameFilter: action,
      enableFilters: action,
      setFilterByVisitId: action,
      setFilterByRailCarTrackPositionId: action,

      dataFilteredByIssueStatus: computed,
      issueTypes: computed,
      filteredData: computed,
    })
  }

  async fetch() {
    if (this.issueStore.items.length) return
    await this.issueStore.load()
  }

  setContainerNumberFilter(filter?: string) {
    this.containerNumberFilter = filter
  }

  setIssueFilterStatus(issueFilterType?: IssueStatus) {
    if (this.issueFilterStatus !== issueFilterType) {
      this.issueFilterStatus = issueFilterType
    }
  }

  setIssueFilterType(issueFilterType?: string) {
    if (this.issueFilterType !== issueFilterType) {
      this.issueFilterType = issueFilterType as IssueType
    }
  }

  setCarrierNameFilter(filter?: string) {
    this.carrierNameFilter = filter
  }

  setFilterByVisitId(visitId?: number) {
    this.filterByVisitId = visitId
  }

  setFilterByRailCarTrackPositionId(railCarTrackPositionId?: number) {
    this.filterByRailcarTrackPositionId = railCarTrackPositionId
  }

  enableFilters(apply: boolean) {
    this.isFilterEnabled = apply
  }

  get unresolvedIssues(): IssueDto[] {
    return this.issueStore.items.filter(x => x.issueStatus !== IssueStatus.Resolved)
  }

  get dataFilteredByIssueStatus(): any[] {
    const data = this.filteredData ?? []

    return data.filter(
      i =>
        (!this.issueFilterStatus || i.issueStatus === this.issueFilterStatus) &&
        (!this.issueFilterType || i.issueType === this.issueFilterType),
    )
  }

  get issueTypes(): string[] {
    return _(this.filteredData)
      .map(i => i.data?.issueType)
      .filter(i => !!i)
      .uniq()
      .value()
  }

  get filteredData(): any[] {
    let filteredData = [...this.issues]

    // Filter by VisitId
    if (this.filterByVisitId) {
      filteredData = filteredData.filter(i => {
        const { containerJourney } = i

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.visit?.id === this.filterByVisitId ||
            containerJourney.outboundOrder?.visit?.id === this.filterByVisitId)
        )
      })
    }

    // Filter by RailCarTrackPositionId
    if (this.filterByRailcarTrackPositionId) {
      filteredData = filteredData.filter(i => {
        const { containerJourney } = i

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.order?.railcarTrackPositionId ===
            this.filterByRailcarTrackPositionId ||
            containerJourney.outboundOrder?.order?.railcarTrackPositionId ===
              this.filterByRailcarTrackPositionId)
        )
      })
    }

    // Filter by container number
    if (this.containerNumberFilter) {
      filteredData = filteredData.filter(i => {
        const { containerJourney } = i

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.order?.containerNumber?.includes(
            this.containerNumberFilter!,
          ) ||
            containerJourney.outboundOrder?.order?.containerNumber?.includes(
              this.containerNumberFilter!,
            ))
        )
      })
    }

    // Filter by carrier name
    if (this.carrierNameFilter && this.isFilterEnabled) {
      filteredData = filteredData.filter(i => {
        const { containerJourney } = i
        const filter = this.carrierNameFilter!.toLocaleUpperCase()

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.visit?.identifier?.toLocaleUpperCase().includes(filter) ||
            containerJourney.outboundOrder?.visit?.identifier?.toLocaleUpperCase().includes(filter))
        )
      })
    }

    return filteredData
  }

  get issuesTypeCount() {
    const items = this.filteredData
    let unresolved = 0
    let followUp = 0

    items.forEach(item => {
      if (item.issueStatus === 'Unresolved') {
        unresolved++
      } else if (item.issueStatus === 'FollowUp') {
        followUp++
      }
    })

    return {
      all: items.length,
      unresolved: unresolved,
      followUp: followUp,
    }
  }

  clearFilters() {
    this.setCarrierNameFilter()
    this.setContainerNumberFilter()
    this.setFilterByVisitId()
    this.setFilterByRailCarTrackPositionId()
    this.enableFilters(false)
    this.clearChips()
  }

  clearChips() {
    this.setIssueFilterStatus()
    this.setIssueFilterType()
  }
}
