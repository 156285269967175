import { Button, Stack, Typography } from '@mui/material'
import { VesselDto, VesselVisitDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { VesselVisitHeaderContainer } from '../Header/VesselVisitHeaderContainer'

interface Props {
  vesselVisit: VesselVisitDto
  vessel: VesselDto
}

export const VesselVisitHeaderVessel: FC<Props> = observer(({ vesselVisit, vessel }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigate = useNavigate()

  if (!vesselVisit || !vessel) return <></>

  const actions = (
    <Button color='info' size='small' onClick={() => navigate('/registers/vessels')}>
      {t('view', 'View')}
    </Button>
  )
  return (
    <VesselVisitHeaderContainer title={t('vessel', 'Vessel')} actions={actions}>
      <Stack
        sx={{
          border: 1,
          borderRadius: theme.customRadius.medium,
          borderColor: theme.palette.grey[300],
          p: `${theme.customSpacing.xxs} ${theme.customSpacing.xs}`,
        }}
        direction={{ md: 'column', lg: 'row' }}
      >
        <Stack m={`0 ${theme.customSpacing.xs}`}>
          <Typography variant='subtitle2' color='secondary'>
            {t('lengthM', 'Length (m)')}
          </Typography>
          <Typography variant='subtitle2'>{vessel.length}</Typography>
        </Stack>
        <Stack m={`0 ${theme.customSpacing.m}`}>
          <Typography variant='subtitle2' color='secondary'>
            {t('beamM', 'Beam (m)')}
          </Typography>
          <Typography variant='subtitle2'>{vessel.width}</Typography>
        </Stack>
      </Stack>
    </VesselVisitHeaderContainer>
  )
})
