import { Stack } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC, ReactNode } from 'react'
import { MultiselectCheckbox } from '../atoms/MultiselectCheckbox'
import { ActionBar } from './ActionBar'

type IProps = {
  state: boolean | 'indeterminate'
  handleOnStateChange: (checked: boolean) => void
  label?: string
  actions: ReactNode[]
  secondaryActions?: ReactNode[]
  allowSelection?: boolean
}

export const MultiselectActionBar: FC<IProps> = ({
  state,
  handleOnStateChange,
  label,
  actions,
  secondaryActions,
  allowSelection = true,
}) => {
  const theme = useMinimalsTheme()

  return (
    <Stack direction={'row'} alignItems={'center'} gap={theme.customSpacing.xs}>
      {allowSelection && <MultiselectCheckbox value={state} handleOnChange={handleOnStateChange} />}
      {state && <ActionBar label={label} actions={actions} secondaryActions={secondaryActions} />}
    </Stack>
  )
}
