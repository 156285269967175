import { ArrowOutward } from '@mui/icons-material'
import { Grid, Paper, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { OrderStatus } from '@planning/app/api'
import { CarrierTypeIcon } from '@planning/components/CarrierTypeIcon'
import { ContainerJourneyStatusChipWrapper } from '@planning/components/molecules/ContainerJourneyStatusChipFFWrapper'
import { emptyIndicator } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { IContainerJourney } from '../Stores/ContainerJourney'
import { ServiceOrdersType } from '../Stores/ServiceOrdersViewStore'

interface IProps {
  containerJourney: IContainerJourney
  serviceType: ServiceOrdersType
  onClickCard: (containerJourney: IContainerJourney, serviceType: ServiceOrdersType) => void
}

export const ServiceOrdersSearchListCard = observer(
  ({ containerJourney, serviceType, onClickCard }: IProps) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const getCardTitle = (serviceType: ServiceOrdersType) => {
      switch (serviceType) {
        case 'stuffing':
          return t('stuffingOrder', 'Stuffing order')
        case 'stripping':
          return t('strippingOrder', 'Stripping order')
        case 'cooling':
          return t('coolingOrder', 'Cooling order')

        default:
          return emptyIndicator
      }
    }

    const getStatus = (journey: IContainerJourney, serviceType: ServiceOrdersType) => {
      switch (serviceType) {
        case 'stuffing':
          return journey.stuffingOrder?.status ?? OrderStatus.Open
        case 'stripping':
          return journey.strippingOrder?.status ?? OrderStatus.Open
        case 'cooling':
          return journey.coolingOrder?.status ?? OrderStatus.Open
        default:
          return OrderStatus.Open
      }
    }

    return (
      <Paper
        elevation={2}
        sx={{ width: '100%', mt: 2 }}
        onClick={() => onClickCard(containerJourney, serviceType)}
      >
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <Box
              sx={{
                height: '65px',
                padding: '.5rem 1rem',
              }}
            >
              <Typography variant='subtitle1'>{getCardTitle(serviceType)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box
              sx={{
                height: '65px',
                padding: '.5rem 1rem',
                // [CoolingOrder] TODO: Why would we show the card, if no service order exists for journey
                borderLeft:
                  containerJourney.stuffingOrderId ||
                  containerJourney.strippingOrderId ||
                  containerJourney.coolingOrderId
                    ? `1px solid ${theme.palette.divider}`
                    : 'none',
              }}
            >
              <Typography variant='subtitle1'>{containerJourney.container?.number}</Typography>
              <Typography variant='body1'>{containerJourney.container?.operator}</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                height: '65px',
                padding: '.5rem 1rem',
                borderLeft: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Stack direction={'row'} spacing={1}>
                <ArrowOutward
                  fontSize='small'
                  sx={{ transform: 'rotate(90deg)', height: '24px' }}
                />
                <Typography variant='subtitle1'>{t('inbound', 'Inbound')}</Typography>
              </Stack>

              <Stack direction={'row'} spacing={1}>
                <CarrierTypeIcon type={containerJourney.inboundOrder?.order?.carrierVisitType} />
                <Typography variant='body1'>
                  {containerJourney.inboundOrder?.visit?.identifier}
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box
              sx={{
                height: '65px',
                padding: '.5rem 1rem',
                borderLeft: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Stack direction={'row'} spacing={1}>
                <ArrowOutward fontSize='small' sx={{ height: '24px' }} />
                <Typography variant='subtitle1'>{t('outbound', 'Outbound')}</Typography>
              </Stack>

              <Stack direction={'row'} spacing={1}>
                <CarrierTypeIcon type={containerJourney.outboundOrder?.order?.carrierVisitType} />
                <Typography variant='body1'>
                  {containerJourney.outboundOrder?.visit?.identifier ?? t('unknown', 'Unknown')}
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box
              sx={{
                height: '65px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderLeft: `1px solid ${theme.palette.divider}`,
              }}
            >
              <ContainerJourneyStatusChipWrapper journey={containerJourney} />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    )
  },
)
