import { CreateRailcarCommand, RailcarResponseDto, UpdateRailcarCommand } from '@planning/app/api'
import { GetRailcarsQuery } from '@planning/messages/queries'
import { RailcarItemStore } from '@planning/rt-stores/railcar/RailcarItemStore'
import railcarService from '@planning/services/railcarService'
import { AppViewStore } from '@planning/stores/AppViewStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { RailcarFormData } from '../components/RailcarsFormDialog'

export class RailcarsViewStore {
  isDialogOpen = false
  dataToBeEdited?: RailcarResponseDto = undefined
  filter = ''
  defaultRailcarNumber?: string

  constructor(
    private railcarItemStore: RailcarItemStore,
    private appViewStore: AppViewStore,
  ) {
    makeObservable(this, {
      isDialogOpen: observable,
      dataToBeEdited: observable,
      filter: observable,
      defaultRailcarNumber: observable,

      isFiltering: computed,

      toggleDialog: action,
      fetch: action,
      setFilter: action,
      onDelete: action,
      onSubmit: action,
      setDefaultRailcarNumber: action,
    })
  }

  toggleDialog = (shouldOpen: boolean, data?: RailcarResponseDto) => {
    this.isDialogOpen = shouldOpen
    this.dataToBeEdited = data
  }

  fetch = async () => {
    await this.railcarItemStore.fetch(new GetRailcarsQuery(1, 1000))
  }

  setFilter(filter: string) {
    this.filter = filter
  }

  get isFiltering() {
    return this.filter.length > 0
  }

  onDelete = async (data: RailcarResponseDto) => {
    const isConfirmed = await this.appViewStore.setOpenConfirmDialog(
      true,
      'Railcar will be deleted and you cannot undo this action!',
      'Delete railcar?',
    )

    if (isConfirmed) {
      try {
        await railcarService.delete(data.id)
        this.appViewStore.setShowAlert('success', `Railcar no. ${data.name} deleted`)
      } catch (error: any) {
        this.appViewStore.setShowAlert(
          'error',
          error?.response?.data?.detail ?? `Failed to delete railcar ${data.name}`,
        )
      }

      this.toggleDialog(false)
    }
  }

  onSubmit = async (data: RailcarFormData) => {
    const isEditMode = !!data.id

    try {
      if (isEditMode) {
        const cmd: UpdateRailcarCommand = {
          id: data.id!,
          name: data.railcarNumber,
          operatorId: data.operatorId,
          length: Number(data.railcarLength),
        }
        await railcarService.put(cmd)
        this.appViewStore.setShowAlert('success', `Railcar no. ${data.railcarNumber} data updated`)
      } else {
        const cmd: CreateRailcarCommand = {
          name: data.railcarNumber,
          operatorId: data.operatorId,
          length: Number(data.railcarLength),
        }
        await railcarService.post(cmd)
        this.appViewStore.setShowAlert('success', `Railcar no. ${data.railcarNumber} data created`)
      }

      this.toggleDialog(false)
    } catch (error: any) {
      this.appViewStore.setShowAlert(
        'error',
        error?.response?.data?.detail ??
          `Failed to ${isEditMode ? 'update' : 'create'} railcar ${data.railcarNumber}`,
      )
    }
  }

  get data() {
    return _.values(this.railcarItemStore.elements).filter(i =>
      i.data.name.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase()),
    )
  }

  setDefaultRailcarNumber = (railcarNumber: string) => (this.defaultRailcarNumber = railcarNumber)
}
