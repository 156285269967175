import { VesselServiceResponseDto } from '@planning/app/api'
import { EntityItem } from '../base/EntityItem'
import { IEntity } from '../types'

export type IVesselServiceItem = IEntity<VesselServiceResponseDto>

export class VesselServiceItem
  extends EntityItem<VesselServiceResponseDto>
  implements IVesselServiceItem
{
  constructor(id: number, data: VesselServiceResponseDto) {
    super(id, data)
  }
}
