import { Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ClockIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useFormatMinutesLabel } from '../../Hooks/useFormatMinutesLabel'

interface Props {
  ata?: string | null
  truckOverdue?: number | null
}
export const TimeVisitOnTerminal = observer(({ ata, truckOverdue }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const [hasWarning, setHasWarning] = useState(false)
  const [label, setLabel] = useState<string>('-')
  const { formatMinutesLabel } = useFormatMinutesLabel()

  useEffect(() => {
    const updateMinutesAndWarning = () => {
      if (ata) {
        const ataDate = new Date(ata)
        const now = new Date()
        const diff = Math.abs(now.getTime() - ataDate.getTime())
        const minutes = Math.floor(diff / 1000 / 60)

        const newLabel = formatMinutesLabel(minutes)

        setLabel(newLabel)

        if (truckOverdue && minutes > (truckOverdue ?? 0)) {
          setHasWarning(true)
        } else {
          setHasWarning(false)
        }
      }
    }

    updateMinutesAndWarning()
    const interval = setInterval(() => {
      updateMinutesAndWarning()
    }, 60000)

    return () => clearInterval(interval)
  }, [ata, truckOverdue, t])

  if (!ata || !label) return <Typography variant='subtitle2'> - </Typography>

  return (
    <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
      <ClockIcon sx={{ color: hasWarning ? theme.palette.warning.dark : 'inherit' }} />
      <Typography
        variant='subtitle2'
        sx={{ color: hasWarning ? theme.palette.warning.dark : 'inherit' }}
      >
        {label}
      </Typography>
    </Stack>
  )
})
