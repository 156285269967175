import { NonNumericOrderDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import {
  GetNnrOrderByIdQuery,
  GetNnrOrdersByVisitIdQuery,
  GetNnrOrdersQuery,
} from '@planning/messages/queries'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { OrderItemStore } from '../order/OrderItemStore'
import { INnrOrderItem, NnrOrderItem } from './NnrOrderItem'

export interface INnrOrderSummary {
  visitId: number
  nnrOrders: INnrOrderItem[]
  totalAmount: number
  totalUnassignedContainersForVisit: number
}

export class NnrOrderItemStore extends ItemStore<NonNumericOrderDto, INnrOrderItem> {
  fetchedVisitIds: Set<number> = new Set()
  private orderItemStore?: OrderItemStore

  constructor(private messageBus: IMessageBus) {
    super((key, data) => new NnrOrderItem(key, data, this.orderItemStore), {
      messageBus,
      fetchFunc: (id: number) => new GetNnrOrderByIdQuery(id),
    })
    makeObservable(this, {
      receiveNnrOrdersMessage: action,
      receiveNnrOrderMessage: action,
      receiveNonNumericOrderUpsertedEventMessage: action,
      receiveNonNumericOrderDeletedEventMessage: action,
      ordersForVesselVisitId: computed,
      orderSummaryForVesselVisitId: computed,
    })

    messageBus.subscribeEvent(GetNnrOrdersQuery.type, this.receiveNnrOrdersMessage)
    messageBus.subscribeEvent(GetNnrOrderByIdQuery.type, this.receiveNnrOrderMessage)
    messageBus.subscribeEvent(GetNnrOrdersByVisitIdQuery.type, this.receiveNnrOrdersMessage)
    messageBus.subscribeEvent(
      EventTypes.NonNumericOrderUpsertedEvent,
      this.receiveNonNumericOrderUpsertedEventMessage,
    )

    messageBus.subscribeEvent(
      EventTypes.NonNumericOrderDeletedEvent,
      this.receiveNonNumericOrderDeletedEventMessage,
    )
  }

  connect = (orderItemStore: OrderItemStore) => {
    this.orderItemStore = orderItemStore
  }

  fetchByVisitId = async (visitId: number) => {
    if (this.fetchedVisitIds.has(visitId)) return

    this.fetchedVisitIds.add(visitId)

    await this.messageBus.dispatchQuery(new GetNnrOrdersByVisitIdQuery(visitId))
  }

  fetchByVisitIds = async (visitIds: number[]) => {
    for (const id of visitIds) {
      await this.fetchByVisitId(id)
    }
  }

  get ordersForVesselVisitId() {
    return _(this.elements)
      .values()
      .groupBy(order => order.data.carrierVisitId)
      .value()
  }

  get orderSummaryForVesselVisitId() {
    return _(this.ordersForVesselVisitId)
      .flatMap((values, key) => {
        const nnrOrderItems = values.map(item => item.data)
        const amount = _.sumBy(nnrOrderItems, 'amount')
        return {
          visitId: Number(key),
          nnrOrders: values,
          totalAmount: amount,
          totalUnassignedContainersForVisit: amount - _.sumBy(nnrOrderItems, 'assigned'),
        } as INnrOrderSummary
      })
      .keyBy(aa => aa.visitId)
      .value()
  }

  receiveNnrOrdersMessage = (event: IEvent<NonNumericOrderDto[]>) => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }
  receiveNnrOrderMessage = (event: IEvent<NonNumericOrderDto>) => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveNonNumericOrderUpsertedEventMessage = (event: IEvent<NonNumericOrderDto>): void => {
    console.log('Upserted Event Received:', event.payload)
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveNonNumericOrderDeletedEventMessage = (event: IEvent<number>): void => {
    console.log('Deleted Event Received:', event.payload)
    if (event.payload && _.has(this.elements, event.payload)) {
      console.log('Deleting Order:', event.payload)
      this.delete(event.payload)
    }
  }
}
