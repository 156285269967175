/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { CargoType, CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { UpdatesNotificationCard } from '@planning/pages/VesselVisit/Components/atoms/UpdatesNotificationCard'
import { ReviewUpdatesDialog } from '@planning/pages/VesselVisit/Components/organisms/ReviewUpdatesDialog'
import { ReviewUpdatesDialogViewStore } from '@planning/pages/VesselVisit/Stores/ReviewUpdatesDialogViewStore'
import { INnrOrderSummary } from '@planning/rt-stores/nnrOrder/NnrOrderItemStore'
import { useTranslate } from '@tolgee/react'
import { ThreeDotMenu, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { FeatureFlag } from './FeatureFlags'

interface Props {
  visitType: CarrierType
  direction?: CarrierVisitDirection
  estimate?: number | null
  actual?: number | null
  confirmed?: number | null
  onViewClick: (e: any) => void
  onClickEdit?: () => void
  onFileUpload?: (direction: CarrierVisitDirection) => void
  onClickExportOrders?: (direction: CarrierVisitDirection) => void
  handleAssignNnrOrder: () => void
  nnrOrderSummary: INnrOrderSummary
  cargoType?: CargoType
  reviewUpdatesDialogStore: ReviewUpdatesDialogViewStore
  isRestow?: boolean
}

export const OrderMovementCard: FC<Props> = observer(
  ({
    estimate,
    actual,
    confirmed,
    onViewClick,
    onClickEdit,
    direction,
    isRestow,
    onFileUpload,
    onClickExportOrders,
    handleAssignNnrOrder,
    nnrOrderSummary,
    cargoType,
    visitType,
    reviewUpdatesDialogStore,
  }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const getMovementType = () => {
      switch (direction) {
        case CarrierVisitDirection.Inbound:
          return t('discharged', 'Discharged')
        case CarrierVisitDirection.Outbound:
          return 'Loaded'
        default:
          return 'Restowed'
      }
    }

    const getTitle = () => {
      switch (direction) {
        case CarrierVisitDirection.Inbound:
          return t('discharge', 'Discharge')
        case CarrierVisitDirection.Outbound:
          if (visitType === CarrierType.Train) {
            return t('load', 'Load')
          } else {
            return t('loading', 'Loading')
          }
        default:
          return t('restows', 'Restows')
      }
    }

    const getUnassignedReleasesAlertText = (totalAmount: number, totalUnassigned: number) => {
      return `${totalAmount} ${
        totalUnassigned > 1
          ? t('containerPlannedForNonNumericLoadidng', 'containers planned for non-numeric loading')
          : t('containerPlannedForNonNumericLoadidng', 'container planned for non-numeric loading')
      } (${totalUnassigned} ${t('notAssignedYet', 'not assigned yet')})`
    }

    const movementViewButtonCyId = (isRestow?: boolean, direction?: CarrierVisitDirection) => {
      return isRestow
        ? 'view-vessel-visit-restow-btn'
        : direction === 'Inbound'
          ? 'view-vessel-visit-discharge-btn'
          : 'view-vessel-visit-loading-btn'
    }

    return (
      <Card sx={{ minWidth: 360 }}>
        <CardHeader
          action={
            <Stack direction='row'>
              <Button
                color='info'
                onClick={onViewClick}
                data-cy={movementViewButtonCyId(isRestow, direction)}
              >
                {t('view', 'View')}
              </Button>
              <ThreeDotMenu color='info'>
                {!isRestow && direction ? (
                  <MenuItem onClick={onClickEdit}>{t('editEstimate', 'Edit Estimate')}</MenuItem>
                ) : (
                  <></>
                )}

                {(cargoType && cargoType !== CargoType.GeneralCargo && onFileUpload && (
                  <MenuItem
                    onClick={() => {
                      onFileUpload(direction!)
                    }}
                  >
                    {t('upload', 'Upload')} {getTitle()} {t('manifest', 'Manifest')}
                  </MenuItem>
                )) || <></>}
                <FeatureFlag name='export-csv-rail-visit-loading-list'>
                  {(onClickExportOrders && direction && cargoType === CargoType.Container && (
                    <MenuItem onClick={() => onClickExportOrders(direction)}>
                      {t('exportOrders', 'Export orders')}
                    </MenuItem>
                  )) || <></>}
                </FeatureFlag>
              </ThreeDotMenu>
            </Stack>
          }
          title={getTitle()}
        />

        <CardContent>
          <Stack direction='row' spacing={2.5}>
            {isRestow ? (
              <Stack direction='row' spacing={2.5}>
                <Stack>
                  <Typography variant='subtitle1' data-cy={`order-direction-title-card-restows`}>
                    {t('expected', 'Expected')}
                  </Typography>
                  <Typography variant='h4' data-cy={`order-direction-title-card-restows-value`}>
                    {actual}
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              <>
                <Stack>
                  <Typography variant='subtitle1'>{t('estimate', 'Estimate')}</Typography>
                  <a>
                    <Typography
                      onClick={onClickEdit}
                      paragraph
                      component='span'
                      variant='h4'
                      sx={{ cursor: 'pointer' }}
                    >
                      {estimate ?? '-'}
                    </Typography>
                  </a>
                </Stack>
                <Stack>
                  {(actual && (
                    <Stack direction='row' spacing={2.5}>
                      <Stack>
                        <Typography
                          variant='subtitle1'
                          data-cy={`order-direction-title-card-${direction}`}
                        >
                          {t('expected', 'Expected')}
                        </Typography>
                        <Typography
                          variant='h4'
                          data-cy={`order-direction-title-card-${direction}-value`}
                        >
                          {actual}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography
                          variant='subtitle1'
                          data-cy={`order-direction-title-card-${direction}-label`}
                        >
                          {getMovementType()}
                        </Typography>
                        <Typography
                          variant='h4'
                          data-cy={`order-direction-title-card-${direction}-confirmed`}
                        >
                          {confirmed}
                        </Typography>
                      </Stack>
                    </Stack>
                  )) || (
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: theme.customRadius.medium,
                        bgcolor: theme.palette.grey[200],
                      }}
                    >
                      <Typography variant='body1'>
                        {actual
                          ? actual
                          : t('noContainerRecordsFound', 'No container records found')}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </>
            )}
          </Stack>
          {direction === CarrierVisitDirection.Outbound &&
            nnrOrderSummary?.totalUnassignedContainersForVisit > 0 && (
              <Stack>
                {direction === CarrierVisitDirection.Outbound && (
                  <Alert
                    data-cy='unassigned-nnr-orders-alert'
                    severity='warning'
                    action={
                      <Button color='inherit' size='small' onClick={handleAssignNnrOrder}>
                        {t('assign', 'Assign')}
                      </Button>
                    }
                  >
                    {getUnassignedReleasesAlertText(
                      nnrOrderSummary.totalAmount,
                      nnrOrderSummary.totalUnassignedContainersForVisit,
                    )}
                  </Alert>
                )}
              </Stack>
            )}
        </CardContent>

        <FeatureFlag name='review-order-updates'>
          {visitType === CarrierType.Vessel && reviewUpdatesDialogStore.updatesCount > 0 && (
            <UpdatesNotificationCard
              onClick={() => reviewUpdatesDialogStore.openDialog()}
              updateCount={reviewUpdatesDialogStore.updatesCount}
            />
          )}
        </FeatureFlag>

        <FeatureFlag name='review-order-updates'>
          {visitType === CarrierType.Vessel && (
            <ReviewUpdatesDialog store={reviewUpdatesDialogStore} />
          )}
        </FeatureFlag>
      </Card>
    )
  },
)
