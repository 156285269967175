import { UnitLabelType } from '@planning/app/api'
import { FieldStore, ISubFormStore, validateSubForm } from '@tom-ui/utils'
import { computed, makeObservable } from 'mobx'
import { UnitLabelFormData } from './unit-label-helper'

export class UnitLabelSubFormStore implements ISubFormStore<UnitLabelFormData> {
  isUnitLabel: FieldStore<boolean>
  imoClasses: FieldStore<string[]>
  types: FieldStore<UnitLabelType[]>
  unNumbers: FieldStore<string[]>

  constructor() {
    makeObservable(this, {
      isUnitLabelSelected: computed,
    })

    this.isUnitLabel = new FieldStore<boolean>(false)
    this.imoClasses = new FieldStore<string[]>([])
    this.types = new FieldStore<UnitLabelType[]>([])
    this.unNumbers = new FieldStore<string[]>([])
  }

  setValues(data?: UnitLabelFormData) {
    this.isUnitLabel.setValue(data?.isUnitLabel ?? false)
    this.imoClasses.setValue(data?.imoClasses ?? [])
    this.types.setValue(data?.types ?? [])
    this.unNumbers.setValue(data?.unNumbers ?? [])
  }

  validate = () => {
    return validateSubForm(this)
  }

  get isUnitLabelSelected() {
    return this.isUnitLabel.value
  }

  get data() {
    if (!this.isUnitLabelSelected) {
      return {
        isUnitLabel: false,
      }
    }

    return {
      isUnitLabel: true,
      imoClasses: this.imoClasses.value ? this.imoClasses.value : undefined,
      types: this.types.value ? this.types.value : undefined,
      unNumbers: this.unNumbers.value ? this.unNumbers.value : undefined,
    }
  }
}
