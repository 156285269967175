import { IssueUIStore, IssueWithJourney } from '@tom-ui/operations'
import { IIssueItem } from '@tom-ui/planning/pages/Issues/Stores/IssueItem'
import { IssueResolutionViewStore } from '@tom-ui/planning/pages/Issues/Stores/IssueResolutionViewStore'
import {
  IssuesTypeCount,
  IssuesViewStore,
} from '@tom-ui/planning/pages/Issues/Stores/IssuesViewStore'
import { ContainerJourneyDataStore } from '@tom-ui/planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { RailcarItemStore } from '@tom-ui/planning/rt-stores/railcar/RailcarItemStore'
import { appStore } from '@tom-ui/utils'
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx'

export type PlanningStores = {
  issueViewStore: IssuesViewStore
  issueResolutionViewStore: IssueResolutionViewStore
  railcarItemStore: RailcarItemStore
}

export type OperationStores = {
  issueViewStore: IssueUIStore
}

export class AggregateIssuesViewStore {
  selectedMissingPositionIssue?: IssueWithJourney

  constructor(
    public planningStores: PlanningStores,
    public operationStores: OperationStores,
    public containerJourneyDataStore: ContainerJourneyDataStore,
  ) {
    makeObservable(this, {
      selectedMissingPositionIssue: observable,

      toggleMissingPositionIssue: action,

      issuesTypeCount: computed,
      planningDataFilteredByIssueStatus: computed,
      operationDataFilteredByIssueStatus: computed,
      dataFilteredByIssueStatusCount: computed,
      isFilterEnabled: computed,
      containerNumberFilter: computed,
    })
  }

  fetch = async () => {
    this.planningStores.issueViewStore.setLoadContainerJourneyDataReaction(true)
    await Promise.all([
      this.planningStores.issueViewStore.fetch(),
      this.planningStores.railcarItemStore.fetch(),
      this.operationStores.issueViewStore.fetch(),
    ])

    autorun(async () => {
      const operationIssues = this.operationStores.issueViewStore.unresolvedIssues

      const orderIds = operationIssues?.filter(i => !!i.orderId).map(i => i.orderId!)
      const planningOrderIds = this.planningStores.issueViewStore.GetIssueOrderIds()
      if (planningOrderIds.length) orderIds.push(...planningOrderIds)
      appStore.increaseLoadingCounter()
      await this.containerJourneyDataStore.fetchByOrderIds(orderIds ?? [])
      appStore.decreaseLoadingCounter()
      runInAction(() => {
        this.operationStores.issueViewStore.issues = operationIssues.map(issue => ({
          ...issue,
          getContainerNumber(): string {
            return (
              (this.containerJourney?.inboundOrder?.order?.containerNumber ||
                this.containerJourney?.outboundOrder?.order?.containerNumber) ??
              ''
            )
          },
          containerJourney: this.containerJourneyDataStore.items.find(
            x =>
              x.inboundOrder?.order?.id === issue.orderId ||
              x.outboundOrder?.order?.id === issue.orderId,
          ),
        }))
      })
    })
  }

  toggleMissingPositionIssue(issue?: IssueWithJourney) {
    this.selectedMissingPositionIssue = issue
  }

  get issuesTypeCount(): IssuesTypeCount {
    const planningIssuesTypeCount = this.planningStores.issueViewStore.issuesTypeCount
    const operationIssuesTypeCount = this.operationStores.issueViewStore.issuesTypeCount

    return {
      all: planningIssuesTypeCount.all + operationIssuesTypeCount.all,
      unresolved: planningIssuesTypeCount.unresolved + operationIssuesTypeCount.unresolved,
      followUp: planningIssuesTypeCount.followUp + operationIssuesTypeCount.followUp,
    }
  }

  get planningDataFilteredByIssueStatus(): IIssueItem[] {
    return this.planningStores.issueViewStore.dataFilteredByIssueStatus
  }

  get operationDataFilteredByIssueStatus(): any[] {
    return this.operationStores.issueViewStore.dataFilteredByIssueStatus
  }

  get dataFilteredByIssueStatusCount(): number {
    return (
      this.planningDataFilteredByIssueStatus.length + this.operationDataFilteredByIssueStatus.length
    )
  }

  get isFilterEnabled(): boolean {
    return (
      this.planningStores.issueViewStore.isFilterEnabled ||
      this.operationStores.issueViewStore.isFilterEnabled
    )
  }

  get containerNumberFilter(): string | undefined {
    return (
      this.planningStores.issueViewStore.containerNumberFilter ||
      this.operationStores.issueViewStore.containerNumberFilter
    )
  }

  clearFilters() {
    this.planningStores.issueViewStore.clearFilters()
    this.operationStores.issueViewStore.clearFilters()
  }
}
