import {
  CarrierType,
  CarrierVisitDirection,
  OrderResponseDto,
  OrderStatus,
  RailVisitResponseDto,
  TruckVisitDto,
  VesselVisitDto,
} from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import {
  GetDeliveryOrdersQuery,
  GetOrderByIdQuery,
  GetOrdersByIdsQuery,
  GetOrdersByNnrOrderIdQuery,
  GetOrdersByVisitIdQuery,
} from '@planning/messages/queries'
import { GetOrdersByContainerNumberQuery } from '@planning/messages/queries/getOrdersByContainerNumberQueryHandler'
import { GetOrdersByReferenceNumberQuery } from '@planning/messages/queries/getOrdersByReferenceNumberQueryHandler'
import { ContainerJourneyItemStore } from '@planning/pages/Issues/Stores/ContainerJourneyItemStore'
import { IssueItemStore } from '@planning/pages/Issues/Stores/IssueItemStore'
import { IOrderWithVisit } from '@planning/pages/Order/stores/SelectOrderViewStore'
import { railVisitService, truckVisitService, vesselVisitService } from '@planning/services'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { ContainerItemStore } from '../container/ContainerItemStore'
import { OrderUpdateItemStore } from '../orderUpdate/OrderUpdateItemStore'
import { RailcarTrackPositionItemStore } from '../railTrack/RailcarTrackPositionItemStore'
import { RailTrackItemStore } from '../railTrack/RailTrackItemStore'
import { RailVisitItemStore } from '../railVisit/RailVisitItemStore'
import { TruckVisitItemStore } from '../truckVisit/TruckVisitItemStore'
import { VesselVisitItemStore } from '../vesselVisit/VesselVisitItemStore'
import { IOrderItem, OrderItem } from './OrderItem'

export class OrderItemStore extends ItemStore<OrderResponseDto, IOrderItem> {
  fetchedVisitIds: Set<number> = new Set()
  fetchedNnrOrderIds: Set<number> = new Set()
  activeOrderIdsByContainerNumber: Record<string, number[]> = {}
  private vesselVisitItemStore?: VesselVisitItemStore
  private railVisitItemStore?: RailVisitItemStore
  private truckVisitItemStore?: TruckVisitItemStore
  private containerItemStore?: ContainerItemStore
  private railTrackItemStore?: RailTrackItemStore

  isLoading = false

  constructor(
    private messageBus: IMessageBus,
    railcarTrackPositionStore: RailcarTrackPositionItemStore,
    issueStore?: IssueItemStore,
    orderUpdateItemStore?: OrderUpdateItemStore,
    containerJourneyItemStore?: ContainerJourneyItemStore,
  ) {
    super(
      (key, data) =>
        new OrderItem(
          this,
          railcarTrackPositionStore,
          issueStore,
          orderUpdateItemStore,
          containerJourneyItemStore,
          key,
          data,
        ),
      {
        messageBus,
        fetchFunc: (id: number) => new GetOrderByIdQuery(id),
        bulkFetchFunc: (ids: number[]) => new GetOrdersByIdsQuery(ids),
      },
    )
    makeObservable(this, {
      activeOrderIdsByContainerNumber: observable,
      carrierVisitById: computed,
      ordersByCarrierVisitId: computed,
      ordersByNnrOrderId: computed,
      ordersByContainerNumber: computed,
      ordersByReferenceNumber: computed,
      activeOrdersByContainerNumber: computed,
      deliveryOrders: computed,
      railtracksById: computed,
      setActiveOrderIdsByContainerNumber: action,
      receiveOrdersMessage: action,
      receiveOrderMessage: action,
      receiveOrdersUpsertedMessage: action,
      receiveOrdersDeletedMessage: action,
    })

    messageBus.subscribeEvent(GetOrdersByVisitIdQuery.type, this.receiveOrdersMessage)
    messageBus.subscribeEvent(GetOrdersByIdsQuery.type, this.receiveOrdersMessage)
    messageBus.subscribeEvent(GetOrdersByNnrOrderIdQuery.type, this.receiveOrdersMessage)
    messageBus.subscribeEvent(GetDeliveryOrdersQuery.type, this.receiveOrdersMessage)
    messageBus.subscribeEvent(GetOrderByIdQuery.type, this.receiveOrderMessage)
    messageBus.subscribeEvent(EventTypes.OrdersUpsertedEvent, this.receiveOrdersUpsertedMessage)
    messageBus.subscribeEvent(EventTypes.OrdersDeletedEvent, this.receiveOrdersDeletedMessage)
    messageBus.subscribeEvent(GetOrdersByReferenceNumberQuery.type, this.receiveOrdersMessage)
  }

  connect = (
    vesselVisitItemStore: VesselVisitItemStore,
    RailVisitItemStore: RailVisitItemStore,
    truckVisitItemStore: TruckVisitItemStore,
    containerItemStore: ContainerItemStore,
    railTrackItemStore: RailTrackItemStore,
  ) => {
    this.vesselVisitItemStore = vesselVisitItemStore
    this.railVisitItemStore = RailVisitItemStore
    this.truckVisitItemStore = truckVisitItemStore
    this.containerItemStore = containerItemStore
    this.railTrackItemStore = railTrackItemStore
  }

  get containerById() {
    return this.containerItemStore?.elements
  }

  get carrierVisitById() {
    return {
      ...this.vesselVisitItemStore?.elements,
      ...this.railVisitItemStore?.elements,
      ...this.truckVisitItemStore?.elements,
    }
  }

  get railtracksById() {
    return this.railTrackItemStore?.elements
  }

  get ordersByReferenceNumber() {
    return _(this.elements)
      .groupBy(order => order.data.referenceNumber)
      .value()
  }

  get ordersByCarrierVisitId() {
    return _(this.elements)
      .groupBy(order => order.data.carrierVisitId)
      .value()
  }

  get ordersByNnrOrderId() {
    return _(this.elements)
      .filter(item => !!item.data.nonNumericOrderId)
      .groupBy(item => item.data.nonNumericOrderId)
      .value()
  }

  get ordersByContainerNumber() {
    const groupedByContainerNumber = _(this.elements)
      .groupBy(order => order.data.containerNumber)
      .value()

    return groupedByContainerNumber
  }

  get activeOrdersByContainerNumber() {
    const activeOrderIds = _(this.activeOrderIdsByContainerNumber)
      .flatMap(activeOrderIds => activeOrderIds)
      .value()
    return _(this.elements)
      .filter(order => activeOrderIds.includes(order.id))
      .groupBy(order => order.data.containerNumber)
      .value()
  }

  get deliveryOrders() {
    return _(this.elements)
      .filter(item => item.data.isDeliveryByTruck ?? false)
      .value()
  }

  setActiveOrderIdsByContainerNumber = async (
    containerNumber: string,
    activeOrderIds: number[],
  ) => {
    this.activeOrderIdsByContainerNumber[containerNumber] = activeOrderIds
  }

  fetchByVisitId = async (visitId: number) => {
    if (this.fetchedVisitIds.has(visitId)) return

    this.fetchedVisitIds.add(visitId)

    await this.messageBus.dispatchQuery(new GetOrdersByVisitIdQuery(visitId))
  }

  fetchByVisitIds = async (visitIds: number[]) => {
    for (const id of visitIds) {
      await this.fetchByVisitId(id)
    }
  }

  fetchByNnrOrderId = async (nnrOrderId: number) => {
    if (this.fetchedNnrOrderIds.has(nnrOrderId)) return

    this.fetchedNnrOrderIds.add(nnrOrderId)

    await this.messageBus.dispatchQuery(new GetOrdersByNnrOrderIdQuery(nnrOrderId))
  }

  fetchByNnrOrderIds = async (nnrOrderIds: number[]) => {
    for (const id of nnrOrderIds) {
      await this.fetchByNnrOrderId(id)
    }
  }

  fetchByContainerNumber = async (containerNumber?: string) => {
    if (!containerNumber?.length) return

    await this.messageBus.dispatchQuery(new GetOrdersByContainerNumberQuery(containerNumber))
  }

  fetchByReferenceNumber = async (
    referenceNumber: string,
    direction: CarrierVisitDirection,
    carrierType?: CarrierType,
    status?: OrderStatus,
  ) => {
    await this.messageBus.dispatchQuery(
      new GetOrdersByReferenceNumberQuery(referenceNumber, direction, carrierType, status),
    )
  }

  receiveOrdersMessage = (event: IEvent<OrderResponseDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveOrderMessage = (event: IEvent<OrderResponseDto>): void => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveOrdersUpsertedMessage = (event: IEvent<OrderResponseDto[]>): void => {
    if (event.payload) {
      console.log('receiveOrdersUpsertedMessage', event.payload)

      this.upsertBulk(event.payload)
    }
  }

  receiveOrdersDeletedMessage = (event: IEvent<number[]>): void => {
    event.payload?.forEach(id => {
      if (_.has(this.elements, id)) this.delete(id)
    })
  }

  // @deprecated Use containerJourneyDataStore instead
  getContainerVisitItem = async (order: OrderResponseDto) => {
    if (!order.carrierVisitId && order.carrierVisitType !== 'Truck') return
    const carrierVisitId = order.carrierVisitId!

    let carrierVisit: TruckVisitDto | VesselVisitDto | RailVisitResponseDto | undefined

    if (order.carrierVisitType === CarrierType.Train) {
      const railVisit = await railVisitService.getById(carrierVisitId)

      carrierVisit = railVisit
    } else if (order.carrierVisitType === CarrierType.Truck) {
      const truckVisits = await truckVisitService.getByIds([carrierVisitId])
      const truckVisit = truckVisits.data[0]
      carrierVisit = truckVisit
    } else if (order.carrierVisitType === CarrierType.Vessel) {
      const vesselVisit = await vesselVisitService.getById(carrierVisitId)
      carrierVisit = vesselVisit
    } else {
      return
    }

    const containerVisitOrderItem: IOrderWithVisit = {
      visit: carrierVisit,
      order: order,
    }

    return containerVisitOrderItem
  }

  // @deprecated Use containerJourneyDataStore instead
  getOrderContainerVisitDetails = async (order: OrderResponseDto) => {
    if (!order.carrierVisitId) return {}

    const getContainerVisitItem = await this.getContainerVisitItem(order)

    let inbound = order.direction === 'Inbound' ? getContainerVisitItem : undefined
    let outbound = order.direction === 'Outbound' ? getContainerVisitItem : undefined

    if (order.linkedOrderId) {
      await this.fetchById(order.linkedOrderId)
      const linkedOrder = this.elements[order.linkedOrderId]?.data

      const linkedOrderCarrierVisitItem = await this.getContainerVisitItem(linkedOrder)

      if (linkedOrder.direction === 'Inbound') {
        inbound = linkedOrderCarrierVisitItem
      } else {
        outbound = linkedOrderCarrierVisitItem
      }
    }

    return { inbound, outbound }
  }
}
