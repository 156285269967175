import { Add } from '@mui/icons-material'
import { Grid, IconButton, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { FormProps } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { BinIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { WeightClassSetContainerUIStore } from '../../stores/weight-class-set-ui-store'
import { WeightClassSetWeightClassFormData } from './WeightClassSetVesselServiceDialog'

interface Props extends FormProps {
  viewStore: WeightClassSetContainerUIStore
  onSubmit: (formData: WeightClassSetWeightClassFormData) => void
}

export const WeightClassSetWeighClassesForm: FC<Props> = observer(({ id, viewStore, onSubmit }) => {
  const { t } = useTranslate()

  const { handleSubmit } = useForm<WeightClassSetWeightClassFormData>()

  useEffect(() => {
    viewStore.validateWeightClasses()
  }, [viewStore])

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    viewStore.validateWeightClasses()

    if (viewStore.hasErrors) {
      return
    }

    handleSubmit(onSubmit)()
  }

  return (
    <>
      <Stack
        id={id}
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleFormSubmit}
        spacing={2}
        mt={2}
      >
        {viewStore.selectedWeightClassSet?.weightClassSets
          .filter(weightClassSet =>
            viewStore.selectedWeightClassSetMode === 0
              ? !weightClassSet.containerLength
              : weightClassSet.containerLength,
          )
          .map(weightClassSet => (
            <>
              {weightClassSet.containerLength && (
                <Typography variant='subtitle2'>
                  {t('xFtContainers', '{length}ft containers', {
                    length: weightClassSet.containerLength,
                  })}
                </Typography>
              )}

              {weightClassSet.weightClasses.map(weightClass => {
                const error = viewStore.errors.get(weightClass.id!)

                return (
                  <>
                    <Grid
                      container
                      columnSpacing={1}
                      key={`${weightClassSet.vesselServiceId}-${weightClass.id}`}
                    >
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          label={t('weightClassName', 'Name')}
                          defaultValue={weightClass.name}
                          error={!!error?.nameError}
                          helperText={
                            error?.nameError ? t('duplicateNameError', 'Name must be unique') : ''
                          }
                          onChange={e => {
                            weightClass.name = e.target.value
                            viewStore.validateWeightClasses()
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          label={t('fromKg', 'From, Kg')}
                          defaultValue={weightClass.minWeight}
                          type='number'
                          error={!!error?.weightError}
                          helperText={
                            error?.weightError
                              ? t('fromHigherThanToError', 'From must be less than To')
                              : ''
                          }
                          onChange={e => {
                            weightClass.minWeight = parseInt(e.target.value) || 0
                            viewStore.validateWeightClasses()
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          label={t('toKg', 'To, Kg')}
                          defaultValue={weightClass.maxWeight}
                          type='number'
                          error={!!error?.weightError}
                          helperText={
                            error?.weightError
                              ? t('fromHigherThanToError', 'From must be less than To')
                              : ''
                          }
                          onChange={e => {
                            const value =
                              e.target.value.trim() === ''
                                ? undefined
                                : parseInt(e.target.value, 10)

                            weightClass.maxWeight = value
                            viewStore.validateWeightClasses()
                          }}
                        />
                      </Grid>
                      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={() =>
                            viewStore.addWeightClassToWeightClassSetControl(
                              weightClassSet.vesselServiceId,
                              weightClassSet.containerLength,
                            )
                          }
                        >
                          <Add />
                        </IconButton>
                        {weightClassSet.weightClasses.length > 1 && (
                          <IconButton
                            color='error'
                            onClick={() =>
                              viewStore.removeWeightClassFromWeightClassSetControl(weightClass.id)
                            }
                          >
                            <BinIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )
              })}
            </>
          ))}
      </Stack>
    </>
  )
})
