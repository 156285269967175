import DownloadIcon from '@mui/icons-material/Download'
import { Button, Chip, Tooltip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { ContainerDamageResponseDto } from '@planning/app/api'
import { containerService, gcsService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { IconButton, useMinimalsTheme } from '@tom-ui/ui'
import { appStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useState } from 'react'

const DownloadContainerDamageImagesIconButton = observer(
  ({ imagePaths }: { imagePaths: string[] }) => {
    const { t } = useTranslate()

    return (
      <Tooltip title={t('downloadImages', 'Download images')}>
        <IconButton onClick={() => gcsService.downloadFiles(imagePaths)}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    )
  },
)

const ResolveContainerDamageButton = observer(
  ({ resolveDamage, isResolving }: { resolveDamage: () => void; isResolving: boolean }) => {
    const { t } = useTranslate()

    return (
      <Button
        disabled={isResolving}
        onClick={resolveDamage}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        color='secondary'
      >
        {isResolving ? t('resolving', 'Resolving') : t('resolve', 'Resolve')}
      </Button>
    )
  },
)

const ContainerDamageCatalogues = observer(
  ({
    damage,
    index,
    containerId,
    isMobile,
    callBackAfterResolveDamage,
  }: {
    damage: ContainerDamageResponseDto
    index: number
    containerId: number
    isMobile?: boolean
    callBackAfterResolveDamage?: () => void
  }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const [isResolving, setIsResolving] = useState(false)

    const resolveDamage = async (title: string) => {
      setIsResolving(true)
      appStore.showAlertForCancellableRequest(
        'success',
        `Resolving ${title}`,
        async () => {
          await onResolveDamage()
          if (callBackAfterResolveDamage) callBackAfterResolveDamage()
        },
        undefined,
        () => {
          setIsResolving(false)
        },
      )
    }

    const onResolveDamage = async () => {
      await containerService.resolveDamages({
        containerId: containerId,
        damageIds: [damage.id],
      })
    }

    const textColor = isMobile ? 'secondary' : ''

    return (
      <Stack gap={theme.customSpacing.xs}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography
            variant='subtitle1'
            color={textColor}
          >{`${t('damage', 'Damage')} ${index + 1}`}</Typography>

          {isMobile && (
            <ResolveContainerDamageButton
              resolveDamage={() => resolveDamage(`${t('damage', 'Damage')} ${index + 1}`)}
              isResolving={isResolving}
            />
          )}

          {!isMobile && damage.imagePaths && !!damage.imagePaths.length && (
            <DownloadContainerDamageImagesIconButton imagePaths={damage.imagePaths} />
          )}
        </Stack>

        {damage.location && (
          <>
            <Stack direction='row' gap={theme.customSpacing.xs}>
              <Chip color='secondary' label={damage.location.name ?? ''} size='small' />
              {damage.types?.map(type => (
                <Chip
                  key={`${damage.id}-${type.id}`}
                  color='secondary'
                  label={type.longName ?? ''}
                  size='small'
                />
              ))}
            </Stack>

            {damage.size && (
              <Typography variant='caption' color={textColor}>
                {damage.size}
              </Typography>
            )}
          </>
        )}

        {damage.description && (
          <Typography variant='caption' color={textColor}>
            {damage.description}
          </Typography>
        )}

        <Typography variant='caption' color='secondary'>
          {damage.imagePaths?.length
            ? `${damage.imagePaths.length} ${t('picturesAttached', 'pictures attached').toLocaleLowerCase()}.`
            : `${t('noPictureAttached', 'No picture attached')}.`}
        </Typography>

        <Typography
          variant='caption'
          color={textColor}
        >{`${t('reported', 'Reported')} ${moment(damage.created).calendar()}`}</Typography>

        {!isMobile && (
          <Stack direction='row' alignItems='flex-start'>
            <ResolveContainerDamageButton
              resolveDamage={() => resolveDamage(`${t('damage', 'Damage')} ${index + 1}`)}
              isResolving={isResolving}
            />
          </Stack>
        )}
      </Stack>
    )
  },
)

const NoDamageReport = observer(() => {
  const { t } = useTranslate()

  return (
    <Box>
      <Typography variant='h4'>{t('damageReport', 'Damage Report')}</Typography>
      <Typography variant='caption'>{t('noDamageReport', 'No damage report')}</Typography>
    </Box>
  )
})

export const ContainerDamageReport = observer(
  ({
    damages,
    containerId,
    isMobile,
    callBackAfterResolveDamage,
  }: {
    damages: ContainerDamageResponseDto[]
    containerId: number
    isMobile?: boolean
    callBackAfterResolveDamage?: () => void
  }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()

    if (!damages.length) return <NoDamageReport />

    return (
      <Stack gap={theme.customSpacing.s}>
        {!isMobile && <Typography variant='h4'>{t('damageReport', 'Damage Report')}</Typography>}

        {damages.map((damage, index) => {
          return (
            <ContainerDamageCatalogues
              containerId={containerId}
              key={damage.id}
              damage={damage}
              index={index}
              isMobile={isMobile}
              callBackAfterResolveDamage={callBackAfterResolveDamage}
            />
          )
        })}
      </Stack>
    )
  },
)
