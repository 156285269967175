import { Box, Grid, Stack, TextField, Typography } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CompanyType } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import { formatWeight } from '@planning/utils/container-utils'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ContainerMasterDataFormData } from './ContainerMasterDataForm'

export const ContainerMasterDataFormFields: FC = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { isoCodeMappingInputStore } = usePlanningStore()
  const isOrderNewCompanyFieldsActive = useBooleanFlagValue('order-changing-company-fields', false)

  const form = useFormContext<ContainerMasterDataFormData>()
  const { control, formState, watch } = form
  const { errors } = formState

  const getMaxPayload = (tare: string | number, maxGross: string | number) => {
    if (!tare || tare === '' || !maxGross || maxGross === '') return t('unknown', 'Unknown')

    if (typeof tare === 'string') tare = parseFloat(tare)
    if (typeof maxGross === 'string') maxGross = parseFloat(maxGross)

    return formatWeight(maxGross - tare)
  }

  const tare = watch('tare')
  const maxGross = watch('maxGross')
  const maxPayload = getMaxPayload(tare, maxGross)

  return (
    <>
      <Grid mt={theme.customSpacing.s} item xs={12}>
        <Typography variant='subtitle1'>{t('containerDetails', 'Container details')}</Typography>
      </Grid>

      <Grid item xs={12}>
        {isoCodeMappingInputStore.renderIsoCodeMappingInput?.({
          label: `${t('isoCode', 'ISO Code')}`,
          fullWidth: true,
          variant: 'outlined',
          type: 'text',
          uppercase: true,
          formState: formState,
          name: 'isoCode',
          required: true,
          control: control,
          allowEdit: false,
          showArchetype: true,
          setValue: (value: string) => form.setValue('isoCode', value),
          watch: watch,
        })}
      </Grid>

      <Grid item xs={4}>
        <Controller
          name='tare'
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label={`${t('tareWeight', 'Tare weight')}`}
              variant='outlined'
              fullWidth
              type='text'
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    kg
                  </CustomInputAdornment>
                ),
              }}
              error={!!errors.tare}
            />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name='maxGross'
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label={`${t('maxGrossWeight', 'Max gross weight')}`}
              variant='outlined'
              fullWidth
              type='text'
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    kg
                  </CustomInputAdornment>
                ),
              }}
              error={!!errors.tare}
            />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Box>
          <Stack
            gap={theme.customSpacing.xxs}
            direction={'row'}
            alignItems={'center'}
            sx={{
              backgroundColor: theme.palette['grey'][200],
              color: theme.palette['grey'][700],
              padding: `${theme.customSpacing.xs}`,
              borderRadius: theme.customRadius.small,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              placeItems: 'start',
              alignContent: 'start',
              margin: `${theme.customSpacing.xxs} 0`,
              textAlign: 'center',
            }}
          >
            <Typography variant='captionBold'>{t('maxPayload', 'Max payload')}</Typography>
            <Typography variant='caption'>{maxPayload}</Typography>
          </Stack>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='operator'
          control={control}
          rules={{ required: true }}
          render={({ field }) =>
            isOrderNewCompanyFieldsActive ? (
              <CustomerAutoComplete
                required
                label={t('operator', 'Operator')}
                selectedCompanyShortName={field.value}
                handleOnChange={operator => {
                  field.onChange(operator?.shortName)
                }}
                filterByTypes={[CompanyType.ShippingLine]}
                dataCy='operator-autocomplete'
                error={!!errors.operator}
                helperText={errors.operator ? t('fieldIsRequired', 'Field is required.') : ''}
              />
            ) : (
              <TextField
                {...field}
                label={`${t('operator', 'Operator')}`}
                required
                variant='outlined'
                fullWidth
                type='text'
                error={!!errors.operator}
                helperText={errors.operator ? t('fieldIsRequired', 'Field is required.') : ''}
              />
            )
          }
        />
      </Grid>
    </>
  )
})
