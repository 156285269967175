import { TextField } from '@mui/material'
import { validateUnitNumber } from '@planning/rt-stores/helpers'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form'

export const UnitNumberController: FC<{
  control: Control<FieldValues, any>
  errors: FieldErrors<FieldValues>
  label: string
}> = observer(
  ({
    control,
    errors,
    label,
  }: {
    control: Control<FieldValues, any>
    errors: FieldErrors<FieldValues>
    label: string
  }) => {
    const { t } = useTranslate()

    const unitNumberErrorText = (errors: FieldErrors) => {
      const containerNumberError = errors.number
      if (containerNumberError) {
        const errorType = containerNumberError.type
        if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
        if (errorType === 'validate')
          return `${t('mustMatchPattern', 'Must match pattern')}: AAAA9999999`

        return containerNumberError.message?.toString()
      }
    }

    return (
      <Controller
        name='number'
        control={control}
        rules={{
          validate: v => !!v && validateUnitNumber(v),
        }}
        render={({ field }) => (
          <TextField
            {...field}
            data-cy='container-number-field'
            label={label}
            fullWidth
            variant='outlined'
            type='text'
            name='number'
            required={true}
            onChange={event => field.onChange(event.target.value.toUpperCase())}
            error={!!errors.number}
            helperText={unitNumberErrorText(errors)}
          />
        )}
      />
    )
  },
)
