import { BookingContainerAssignmentErrors, ContainerDto, OrderResponseDto } from '@planning/app/api'
import { validateContainerNumber } from '@planning/rt-stores/helpers'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { action, computed, makeObservable, observable } from 'mobx'

export type ExtendedBookingContainerAssignmentErrors =
  | BookingContainerAssignmentErrors
  | 'AlreadyAssigned'

export class BookingSlotViewStore {
  slots: OrderResponseDto[] = []
  container: ContainerDto | undefined
  containerNumber: string | undefined
  isInvalidContainerNumber = false

  errorList: ExtendedBookingContainerAssignmentErrors[] = []

  selectedSlot?: OrderResponseDto

  constructor(private gateInViewStore: GateInViewStore) {
    makeObservable(this, {
      slots: observable,
      selectedSlot: observable,
      container: observable,
      containerNumber: observable,
      isInvalidContainerNumber: observable,
      errorList: observable,

      bookingWithAssignedContainer: computed,
      isContainerAttributeMismatch: computed,
      bookingWithoutAssignedContainer: computed,
      upsertedSlots: computed,
      allSlotsAssigned: computed,
      hasContainerAssignmentErrors: computed,

      setSlots: action,
      setSelectedSlot: action,
      setContainer: action,
      setContainerNumber: action,
      setIsInvalidContainerNumber: action,
      reset: action,
      resetSelectedSlotData: action,
      resetSelectedContainerData: action,
      validateContainerNumber: action,
      setErrorList: action,
      clearErrorList: action,
    })
  }

  setSlots(slots: OrderResponseDto[]) {
    this.slots = slots
  }

  setSelectedSlot(slot?: OrderResponseDto) {
    this.selectedSlot = slot
  }

  setContainer(container?: ContainerDto) {
    this.container = container
    if (container?.number) this.setContainerNumber(container.number)
  }

  setContainerNumber(containerNumber: string) {
    this.containerNumber = containerNumber

    if (!this.containerNumber || this.containerNumber?.length < 11)
      this.setIsInvalidContainerNumber(false)
    else this.validateContainerNumber()
  }

  validateContainerNumber() {
    if (!this.containerNumber) this.setIsInvalidContainerNumber(false)
    else this.setIsInvalidContainerNumber(!validateContainerNumber(this.containerNumber))
  }

  setIsInvalidContainerNumber(isInvalid: boolean) {
    this.isInvalidContainerNumber = isInvalid
  }

  addError(error: ExtendedBookingContainerAssignmentErrors) {
    this.errorList.push(error)
  }

  setErrorList(errors: ExtendedBookingContainerAssignmentErrors[]) {
    this.errorList = errors
  }

  clearErrorList() {
    this.errorList = []
  }

  reset() {
    this.resetSelectedSlotData()
  }

  resetSelectedSlotData() {
    this.selectedSlot = undefined
    this.resetSelectedContainerData()
  }

  resetSelectedContainerData() {
    this.container = undefined
    this.containerNumber = undefined
    this.setIsInvalidContainerNumber(false)
  }

  get hasContainerAssignmentErrors() {
    return this.errorList.length > 0
  }

  get isContainerAttributeMismatch() {
    return this.container && this.container?.isoCode !== this.selectedSlot?.containerIsoCode
  }

  get upsertedSlots(): OrderResponseDto[] {
    return this.slots.map(slot => {
      const upsertedSlot = this.gateInViewStore.bookings.find(order => order.id === slot.id)
      slot.containerNumber = upsertedSlot?.containerNumber || slot.containerNumber
      return slot
    })
  }

  get bookingWithoutAssignedContainer(): OrderResponseDto[] {
    const alreadyAssignedBookingIds = this.gateInViewStore.bookings.map(order => order.id)
    return this.slots.filter(slot => !alreadyAssignedBookingIds.includes(slot.id))
  }

  get allSlotsAssigned() {
    return this.bookingWithoutAssignedContainer.length === 0
  }

  get bookingWithAssignedContainer(): OrderResponseDto | undefined {
    if (!this.selectedSlot) return

    if (!this.container)
      return {
        ...this.selectedSlot,
        containerNumber: this.containerNumber,
      }

    return {
      ...this.selectedSlot,
      containerNumber: this.container.number,
      containerIsoCode: this.container.isoCode,
      containerMaxGross: this.container.maxGross,
      containerTare: this.container.tare,
    }
  }
}
