import { usePlanningStore } from '@planning/AppProvider'
import { ContainerDto, ContainerType } from '@planning/app/api'
import { SearchAutocomplete } from '@planning/components/list/SearchAutocomplete'
import { ContainerAutocompleteListItem } from '@planning/pages/Order/components/ContainerAutocompleteListItem'
import { ContainerAutocompleteListItemLoader } from '@planning/pages/Order/components/ContainerAutocompleteListItemLoader'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface IProps {
  hasServiceOrders?: boolean
  canAssignServiceOrders?: boolean
  isEmpty?: boolean
  onChange?: (filter: string, value: string | ContainerDto | null) => Promise<void> | void
  onFilterChange?: (filter: string) => void
  type?: ContainerType
  isoCode?: string
  operator?: string
  isOnTerminal?: boolean
  filteredContainerNumbers?: string[]
}

const RenderAutocompleteOption = (option: ContainerDto) => (
  <ContainerAutocompleteListItem container={option} />
)

export const ContainerAutoComplete = observer(
  ({
    canAssignServiceOrders,
    hasServiceOrders,
    isEmpty,
    onChange,
    onFilterChange,
    type,
    isoCode,
    operator,
    isOnTerminal,
    filteredContainerNumbers,
  }: IProps) => {
    const { t } = useTranslate()
    const { containerAutocompleteStore } = usePlanningStore()

    containerAutocompleteStore.setIsEmpty(isEmpty)
    containerAutocompleteStore.setHasServiceOrders(hasServiceOrders)
    containerAutocompleteStore.setCanAssignServiceOrders(canAssignServiceOrders)
    containerAutocompleteStore.setType(type)
    containerAutocompleteStore.setIsoCode(isoCode)
    containerAutocompleteStore.setIsOnTerminal(isOnTerminal)
    containerAutocompleteStore.setOperator(operator)
    containerAutocompleteStore.setFilteredContainerNumbers(filteredContainerNumbers ?? [])

    return (
      <SearchAutocomplete
        label={t('containerNumber', 'Container number')}
        store={containerAutocompleteStore}
        renderOption={RenderAutocompleteOption}
        renderLoadingOption={() => <ContainerAutocompleteListItemLoader />}
        getOptionLabel={c => c.number ?? ''}
        onChange={onChange}
        onFilterChange={onFilterChange}
      />
    )
  },
)
