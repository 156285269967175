import { Box, SxProps, Theme } from '@mui/material'
import { ReactNode } from 'react'
import { useMinimalsTheme } from '../../../hooks'

interface Props {
  children: ReactNode
  sx?: SxProps<Theme>
}
export const CardAttributesContainer = ({ children, sx }: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Box
      sx={{
        padding: theme.customSpacing.m,
        gap: theme.customSpacing.xl,
        display: 'flex',
        backgroundColor: theme.palette.grey[100],
        overflowX: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
