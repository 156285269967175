import { useOpenFeatureClient } from '@openfeature/react-sdk'
import {
  CarrierType,
  ContainerPositionType,
  EquipmentType,
  OperationType,
  OrderStatus,
  YardLocation,
} from '@planning/app/api'
import {
  ContainerJourneyStatus,
  ContainerJourneyStatusChip,
} from '@planning/components/molecules/ContainerJourneyStatusChip'
import { OrderStatusChip } from '@planning/components/OrderStatusChip'
import { IContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourney'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'

export const ContainerJourneyStatusChipWrapper: FC<{ journey: IContainerJourney }> = observer(
  ({ journey }) => {
    return (
      <ContainerJourneyStatusChipFFWrapper
        journeyStatus={journey.status}
        orderStatus={
          journey.outboundOrder?.order?.status ??
          journey.inboundOrder?.order?.status ??
          OrderStatus.Open
        }
        direction={journey.operationType}
        inboundCarrierType={journey.inboundOrder?.order?.carrierVisitType}
        currentPosition={journey.currentPosition}
        equipment={journey.equipmentType}
        yardLocations={journey.yardLocations}
      />
    )
  },
)

export const OrderItemJourneyStatusChipWrapper: FC<{ orderItem: IOrderItem }> = observer(
  ({ orderItem }) => {
    const journey = orderItem.containerJourney
    return (
      <ContainerJourneyStatusChipFFWrapper
        journeyStatus={journey?.status ?? ContainerJourneyStatus.Expected}
        orderStatus={orderItem.data.status}
        direction={journey?.data.operationType}
        inboundCarrierType={journey?.inboundOrder?.data.carrierVisitType}
        currentPosition={journey?.data.currentPosition}
        equipment={journey?.data.equipmentType}
        yardLocations={journey?.data.yardLocations}
      />
    )
  },
)

export const ContainerJourneyStatusChipFFWrapper: FC<{
  journeyStatus: ContainerJourneyStatus
  orderStatus: OrderStatus
  direction?: OperationType
  inboundCarrierType?: CarrierType
  currentPosition?: ContainerPositionType
  equipment?: EquipmentType
  yardLocations?: YardLocation[] | null
}> = observer(
  ({
    journeyStatus,
    orderStatus,
    currentPosition,
    direction,
    inboundCarrierType,
    equipment,
    yardLocations,
  }) => {
    const featureFlagClient = useOpenFeatureClient()
    const containerJourneyStatusFF = useMemo(
      () => featureFlagClient.getBooleanValue('container-journey-status', false),
      [featureFlagClient],
    )

    if (containerJourneyStatusFF) {
      return (
        <ContainerJourneyStatusChip
          status={journeyStatus}
          direction={direction}
          inboundCarrierType={inboundCarrierType}
          currentPosition={currentPosition}
          equipment={equipment}
          yardLocations={yardLocations}
        />
      )
    }

    return <OrderStatusChip status={orderStatus} />
  },
)
