import { ContainerDamageUpsertDto, OrderResponseDto, UpdateOrderCommand } from '@planning/app/api'
import { IInspectContainerFormData } from '@planning/pages/GateClerk/Components/InspectContainer'
import { containerService, orderService } from '@planning/services'
import { CancellableTimeoutStore } from '@planning/stores/gateClerk/CancellableTimeoutStore'
import { AxiosError } from 'axios'
import { action, makeObservable, observable } from 'mobx'

export class CheckOrderNotificationStore extends CancellableTimeoutStore {
  order?: OrderResponseDto

  constructor(delay?: number) {
    super(delay)
    makeObservable(this, {
      order: observable,
      setOrder: action,
    })
  }

  setOrder = (order?: OrderResponseDto) => {
    this.order = order
  }

  createCheckInRequest = async (
    data: IInspectContainerFormData,
    order?: OrderResponseDto,
    tallymanOperationsUIStore?: any,
  ) => {
    if (!order) return

    this.setOrder(order)

    this.createRequest(async () => {
      try {
        // TODO Verify how to improve this interim solution
        if (tallymanOperationsUIStore)
          await tallymanOperationsUIStore.confirmSelectedEquipment(order.carrierVisitId, order.id)

        let damages: ContainerDamageUpsertDto[] = []
        if (data?.containerNumber && data?.damagesReported?.length) {
          damages = await containerService.getDamageDtoForUploadedDamages(
            data.containerNumber,
            data.damagesReported,
          )
        }

        const updatedOrder = {
          ...data,
          containerDamages: damages,
          isAccidentalDischarge: false,
          isToBeStoredInYard: true,
          isOOG: data?.oog?.isOog,
          overWidthRight: data?.oog?.overWidthRight,
          overWidthLeft: data?.oog?.overWidthLeft,
          overLengthRear: data?.oog?.overLengthRear,
          overLengthFront: data?.oog?.overLengthFront,
          overHeight: data?.oog?.overHeight,
        } as UpdateOrderCommand

        await orderService.update(updatedOrder)

        await orderService.allowPassage({
          id: updatedOrder.id,
          carrierVisitDirection: updatedOrder.direction,
        })
      } catch (error) {
        const err = error as AxiosError
        this.setRequestErrorMessage(err.message)
      }

      this.reset()
    })

    this.order!.status = 'Fulfilled'
  }

  cancelRequest = () => {
    super.cancelRequest()

    setTimeout(async () => {
      this.reset()
    }, this.delay)
  }

  reset() {
    super.reset()
    this.setOrder()
  }
}
