import { Container } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { VisitsHeader } from './Components/VisitsHeader'
import { VisitsTable } from './Components/VisitsTable'

export const VisitsPage = observer(() => {
  const { visitListViewStore, visitTableViewStore, visitViewStore } = usePlanningStore()

  const startDate = dayjs().subtract(5, 'days').startOf('day')
  const endDate = dayjs().add(5, 'days').endOf('day')

  useEffect(() => {
    visitViewStore.fetchVisits(startDate.toDate(), endDate.toDate())
  }, [endDate, startDate, visitViewStore])

  return (
    <>
      <VisitsHeader store={visitListViewStore} />
      <Container sx={{ paddingY: 2 }}>
        <VisitsTable store={visitListViewStore} tableStore={visitTableViewStore} />
      </Container>
    </>
  )
})
