import { CompanyDto, ContainerTurnoverDto, WeightClassDto } from '@storage/app/api'
import { AllStringValue, UnknownStringValue } from '@storage/app/models'
import { ContainerTurnoverGroup, UnAllocatedFilter } from '../interfaces'

const upsertOnContainerTurnoverDictionary = (
  groupsDictionary: Record<string, ContainerTurnoverGroup>,
  key: string,
  containerTurnover: ContainerTurnoverDto,
  label?: string,
) => {
  const groupDictionary = groupsDictionary[key]

  if (groupDictionary) {
    groupDictionary.containerTurnovers.push(containerTurnover)

    if (!groupDictionary.label) {
      groupDictionary.label = label
    }
  } else {
    groupsDictionary[key] = { name: key, containerTurnovers: [containerTurnover], label }
  }
}

const convertContainerTurnoversDictionaryToList = (
  groupsDictionary: Record<string, ContainerTurnoverGroup>,
) => {
  const containerTurnoverGroups: ContainerTurnoverGroup[] = []

  for (const key in groupsDictionary) {
    if (groupsDictionary[key]) {
      const group = groupsDictionary[key]

      if (group.containerTurnovers.length) {
        containerTurnoverGroups.push(group)
      }
    }
  }

  return containerTurnoverGroups.sort(
    (a, b) => b.containerTurnovers.length - a.containerTurnovers.length,
  )
}

export const getContainerTurnoverGroups = (
  containerTurnovers: ContainerTurnoverDto[],
  filter: UnAllocatedFilter,
  weightClasses: WeightClassDto[],
  companies: CompanyDto[],
): ContainerTurnoverGroup[] => {
  const groupsDictionary: Record<string, ContainerTurnoverGroup> = {}

  if (filter === 'weightClass') {
    weightClasses.forEach(weightClass => {
      groupsDictionary[weightClass.name] = { name: weightClass.name, containerTurnovers: [] }
    })
  }

  containerTurnovers.forEach(containerTurnover => {
    if (filter === 'weightClass') {
      const weightClass = weightClasses.find(
        x =>
          containerTurnover.grossWeight &&
          x.minWeight <= containerTurnover.grossWeight &&
          (!x.maxWeight || x.maxWeight >= containerTurnover.grossWeight),
      )

      if (!weightClass) {
        upsertOnContainerTurnoverDictionary(groupsDictionary, UnknownStringValue, containerTurnover)
      } else {
        upsertOnContainerTurnoverDictionary(groupsDictionary, weightClass.name, containerTurnover)
      }
    } else if (filter === 'customer') {
      const customer = companies.find(x => x.id === containerTurnover.customerId)

      if (!customer) {
        upsertOnContainerTurnoverDictionary(groupsDictionary, UnknownStringValue, containerTurnover)
      } else {
        upsertOnContainerTurnoverDictionary(
          groupsDictionary,
          String(customer.id),
          containerTurnover,
          customer.name,
        )
      }
    } else {
      const key = containerTurnover[filter] ?? UnknownStringValue
      upsertOnContainerTurnoverDictionary(groupsDictionary, key, containerTurnover)
    }
  })

  let containerTurnoverGroups = convertContainerTurnoversDictionaryToList(groupsDictionary)

  if (containerTurnoverGroups.length > 1) {
    containerTurnoverGroups = [
      {
        containerTurnovers: containerTurnovers,
        name: AllStringValue,
      },
      ...containerTurnoverGroups,
    ]
  }

  return containerTurnoverGroups
}
