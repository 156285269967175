import { IInspectContainerFormData } from '@planning/pages/GateClerk/Components/InspectContainer'
import { nnrOrderService } from '@planning/services'
import { action, makeObservable, observable } from 'mobx'
import { ValidateOutboundOrderFunc } from '../gateControl/ValidateOutboundDto'
import { NonNumericOrderWithPickUpAmount } from '../truckAppointment/TruckAppointmentDetailsViewStore'
import { IGateClerkViewStore } from './GateClerkViewStore'
import { GateOperationViewStore } from './GateOperationViewStore'
import { GetEasiestContainerToReachFunc } from './SuggestedContainerDto'

export class GateInViewStore extends GateOperationViewStore {
  licensePlate = ''
  dropOffOrders: IInspectContainerFormData[] = []
  pickUpOrders: IInspectContainerFormData[] = []
  nnrOrders: NonNumericOrderWithPickUpAmount[] = []

  validateOutboundRequest?: ValidateOutboundOrderFunc

  getEasiestContainerToReachFunc?: GetEasiestContainerToReachFunc

  constructor(public parentStore: IGateClerkViewStore) {
    super()

    makeObservable(this, {
      licensePlate: observable,
      dropOffOrders: observable,
      pickUpOrders: observable,
      nnrOrders: observable,
      setLicensePlate: action,
      upsertDropOffOrder: action,
      upsertPickUpOrder: action,
      upsertNNROrder: action,
      deleteDropOffOrder: action,
      deletePickUpOrder: action,
      deleteNNROrder: action,
      reset: action,
    })
  }

  setLicensePlate = (licensePlate: string) => {
    this.licensePlate = licensePlate
  }

  upsertDropOffOrder = (order: IInspectContainerFormData) => {
    this.dropOffOrders = [...this.dropOffOrders.filter(o => o.id !== order.id), order]
  }

  deleteDropOffOrder = (orderId: number) => {
    this.dropOffOrders = [...this.dropOffOrders.filter(o => o.id !== orderId)]
  }

  upsertPickUpOrder = (order: IInspectContainerFormData) => {
    this.pickUpOrders = [...this.pickUpOrders.filter(o => o.id !== order.id), order]
  }

  deletePickUpOrder = (orderId: number) => {
    this.pickUpOrders = [...this.pickUpOrders.filter(o => o.id !== orderId)]
  }

  upsertNNROrder = async (order: NonNumericOrderWithPickUpAmount) => {
    const matchingContainers = await nnrOrderService.availableContainers(
      order.id,
      order.availableForPickUpOrdersCount, //TODO: check if it is fine to filter the amount?
    )

    const matchingContainerIds: number[] = matchingContainers
      .filter(x => x !== null && x !== undefined)
      .map(x => x.id!)

    const suggestedContainers = await this.getEasiestContainerToReachFunc?.(
      matchingContainerIds,
      order.pickUpAmount,
    )

    order.suggestedContainers = suggestedContainers

    this.nnrOrders = [...this.nnrOrders.filter(o => o.id !== order.id), order]
  }

  deleteNNROrder = (orderId: number) => {
    this.nnrOrders = [...this.nnrOrders.filter(o => o.id !== orderId)]
  }

  reset = () => {
    this.resetDialogs()
    this.licensePlate = ''
    this.dropOffOrders = []
    this.pickUpOrders = []
    this.nnrOrders = []
  }
}
