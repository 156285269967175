import { Button } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { appStore } from '../stores/AppStore'

export const SnackbarToast = observer(() => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { t } = useTranslate()

  useEffect(() => {
    appStore.alertQueue.forEach(alert => {
      enqueueSnackbar({
        message: alert.translate ? t(alert.message) : alert.message,
        variant: alert.severity,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: appStore.delay,
        preventDuplicate: true,
        disableWindowBlurListener: true,
        action: key => {
          if (alert.action) return alert.action

          return alert.cancellable ? (
            <Button
              onClick={() => {
                appStore.cancelAlert(alert)
                closeSnackbar(key)
              }}
            >
              {t('cancel', 'Cancel')}
            </Button>
          ) : undefined
        },
        onExit: async () => await appStore.removeAlert(alert),
        key: alert.key,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStore.alertQueue])

  return <></>
})
