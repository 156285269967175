import { Autocomplete, ListItem, TextField } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Loader } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { ISearchAutocompleteStore } from '../../stores/SearchAutocompleteStore'

interface Props<T> {
  store: ISearchAutocompleteStore<T>
  label?: string
  getOptionLabel: (option: T) => string
  renderOption?: (option: T, props?: React.HTMLAttributes<HTMLLIElement>) => ReactNode
  renderLoadingOption?: () => ReactNode
  onChange?: (filter: string, value: string | T | null) => Promise<void> | void
  onFilterChange?: (filter: string) => void
  testId?: string
}

const defaultLoadingOption = <Loader show />

export const SearchAutocomplete = observer(
  <_, T>({
    store,
    renderOption,
    renderLoadingOption,
    label,
    getOptionLabel,
    onChange,
    onFilterChange,
    testId,
  }: Props<T>) => {
    const { t } = useTranslate()

    const dataCyId = testId ? `search-autocomplete-${testId}` : 'search-autocomplete'

    const getOptionComponent = (option: T, props?: React.HTMLAttributes<HTMLLIElement>) => {
      if (store.isLoading) return renderLoadingOption?.() ?? defaultLoadingOption

      return renderOption?.(option, props)
    }

    const handleInputChange = async (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const filter = e.target.value
      store.setFilter(filter)

      onFilterChange?.(filter)
    }

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && store.filter.length) {
        onChange?.(store.filter, null)
      }
    }

    const handleValueChange = async (
      _: React.SyntheticEvent<Element, Event>,
      value: string | T | null,
    ) => {
      if (value) {
        const isString = typeof value === 'string'
        const filter = isString ? value : getOptionLabel(value)

        store.setFilter(filter)
        onChange?.(filter, value)

        if (!isString) store.setValue(value)
      } else {
        store.setValue(undefined)
        store.setFilter('')
      }
    }

    const renderListItem = (option: T, props?: React.HTMLAttributes<HTMLLIElement>) => {
      return <ListItem {...props}>{getOptionComponent(option, props)}</ListItem>
    }

    return (
      <Autocomplete
        value={store.filter}
        options={store.filteredItems}
        freeSolo
        loading={store.isLoading}
        loadingText={renderLoadingOption?.()}
        getOptionLabel={o => {
          if (typeof o === 'string') return o

          return getOptionLabel(o)
        }}
        renderOption={(props, o) => renderListItem(o, props)}
        renderInput={params => {
          return (
            <TextField
              {...params}
              data-cy={dataCyId}
              value={store.filter}
              label={label ?? t('search', 'Search')}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          )
        }}
        onChange={handleValueChange}
      />
    )
  },
)
