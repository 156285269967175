export enum ErrorCode {
  EntityNotFound = 'EntityNotFound',
  VesselServiceLocationCodeInactive = 'VesselServiceLocationCodeInactive',
  ServiceIdNotUnique = 'ServiceIdNotUnique',
  NameNotUnique = 'NameNotUnique',
  ShortNameNotUnique = 'ShortNameNotUnique',
  VesselOperatorNotFound = 'VesselOperatorNotFound',
  ShippingAgentNotFound = 'ShippingAgentNotFound',
  VesselServiceNotFound = 'VesselServiceNotFound',
  DriverIdNotUnique = 'DriverIdNotUnique',
}
