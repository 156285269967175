export interface ContainerDamageTypeFormProfile {
  id?: number
  longName: string
  shortName?: string | null
  externalCodes: string[]
  isDefault?: boolean
  isActive: boolean
}

export const defaultValues: ContainerDamageTypeFormProfile = {
  longName: '',
  shortName: '',
  externalCodes: [],
  isActive: true,
}
