import { Box, Container, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { IssueWithJourney, useOperationsStore } from '@tom-ui/operations'
import { InitializationWrapper, usePlanningStore } from '@tom-ui/planning'
import { IssueCard } from '@tom-ui/planning/pages/Issues/Components/molecules/IssueCard'
import { IssuesFilterChips } from '@tom-ui/planning/pages/Issues/Components/molecules/IssuesFilterChips'
import { IssuesTypeFilter } from '@tom-ui/planning/pages/Issues/Components/molecules/IssuesTypeFilter'
import { IssuesPageHeader } from '@tom-ui/planning/pages/Issues/Components/organisms/IssuesPageHeader'
import { ResolveIssueDialog } from '@tom-ui/planning/pages/Issues/Components/templates/ResolveIssueDialog'
import { IssueResolutionViewStore } from '@tom-ui/planning/pages/Issues/Stores/IssueResolutionViewStore'
import { IssuesViewStore } from '@tom-ui/planning/pages/Issues/Stores/IssuesViewStore'
import { NoListResult, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { OperationsIssueCard } from './Components/OperationsIssueCard'
import { PositionMissingDialog } from './Components/PositionMissingDialog'
import {
  AggregateIssuesViewStore,
  OperationStores,
  PlanningStores,
} from './Stores/AggregateIssuesViewStore'

export type IssuesPageLocationState = {
  id?: number
  domain?: 'Planning'
  filterByRailCarTrackPositionId?: number
  visitId?: number
}

const useCreateViewStores = () => {
  const {
    issueItemStore,
    containerJourneyDataStore,
    containerItemStore,
    railVisitItemStore,
    railcarTrackPositionItemStore,
    railcarItemStore,
  } = usePlanningStore()
  const { issueUIStore } = useOperationsStore()

  const issuesViewStore = useLocalObservable(() => {
    return new IssuesViewStore(
      issueItemStore,
      containerJourneyDataStore,
      containerItemStore,
      railVisitItemStore,
      railcarTrackPositionItemStore,
    )
  })
  const issueResolutionViewStore = useLocalObservable(() => {
    return new IssueResolutionViewStore()
  })

  const planningStores: PlanningStores = {
    issueViewStore: issuesViewStore,
    issueResolutionViewStore: issueResolutionViewStore,
    railcarItemStore: railcarItemStore,
  }

  const operationStore: OperationStores = {
    issueViewStore: issueUIStore,
  }

  const aggregateIssuesViewStore = useLocalObservable(() => {
    return new AggregateIssuesViewStore(planningStores, operationStore, containerJourneyDataStore)
  })

  return { aggregateIssuesViewStore }
}

export const Issue = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { state } = useLocation() as { state: IssuesPageLocationState }

  const { aggregateIssuesViewStore } = useCreateViewStores()
  const {
    issueViewStore: planningIssueViewStore,
    issueResolutionViewStore: planningIssueResolutionViewStore,
  } = aggregateIssuesViewStore.planningStores
  const { issueViewStore: operationIssueViewStore } = aggregateIssuesViewStore.operationStores

  useEffect(() => {
    if (!state) return

    if (state.domain === 'Planning' && state.id) {
      const issue = planningIssueViewStore.dataFilteredByIssueStatus.find(
        (i: any) => i.id === state.id,
      )
      if (issue) planningIssueResolutionViewStore.openDialog(issue)
    } else if (state?.filterByRailCarTrackPositionId) {
      planningIssueViewStore.setFilterByRailCarTrackPositionId(
        state?.filterByRailCarTrackPositionId,
      )
      operationIssueViewStore.setFilterByRailCarTrackPositionId(
        state?.filterByRailCarTrackPositionId,
      )
    } else if (state?.visitId) {
      planningIssueViewStore.setFilterByVisitId(state?.visitId)
      operationIssueViewStore.setFilterByVisitId(state?.visitId)
    }
  }, [planningIssueViewStore, planningIssueResolutionViewStore, state, operationIssueViewStore])

  const { loading, error } = useAsyncFetch(async () => {
    await aggregateIssuesViewStore.fetch()
  }, [aggregateIssuesViewStore])

  if (error) {
    return <Typography variant='h4'>{t('errorLoadingIssues', 'Error loading issues')}</Typography>
  }

  return (
    <InitializationWrapper isInitializing={loading}>
      <Box
        sx={{
          height: '100%',
        }}
      >
        <IssuesPageHeader
          store={planningIssueViewStore}
          handleSetContainerNumberFilter={value =>
            operationIssueViewStore.setContainerNumberFilter(value)
          }
          handleSetCarrierNameFilter={value => operationIssueViewStore.setCarrierNameFilter(value)}
          handleSetEnableFilters={value => operationIssueViewStore.enableFilters(value)}
        />
        <IssuesFilterChips
          store={planningIssueViewStore}
          handleFilterRemove={key => {
            if (key === 'visit') {
              operationIssueViewStore.setFilterByVisitId()
            } else if (key === 'railCarNo') {
              operationIssueViewStore.setFilterByRailCarTrackPositionId()
            } else if (key === 'carrierName') {
              operationIssueViewStore.enableFilters(false)
              operationIssueViewStore.setCarrierNameFilter('')
            }
          }}
        />
        <IssuesTypeFilter
          selectedStatus={planningIssueViewStore.issueFilterStatus}
          selectedType={planningIssueViewStore.issueFilterType}
          handleSetIssueFilterStatus={status => {
            planningIssueViewStore.setIssueFilterStatus(status)
            operationIssueViewStore.setIssueFilterStatus(status)
          }}
          handleSetIssueFilterType={type => {
            planningIssueViewStore.setIssueFilterType(type)
            operationIssueViewStore.setIssueFilterType(type)
          }}
          issuesTypeCount={aggregateIssuesViewStore.issuesTypeCount}
          issueTypes={[...planningIssueViewStore.issueTypes, ...operationIssueViewStore.issueTypes]}
          onClearAll={() => {
            planningIssueViewStore.clearChips()
            operationIssueViewStore.clearChips()
          }}
        />

        <Container
          sx={{
            paddingY: theme.customSpacing.m,
          }}
        >
          <Stack gap={theme.customSpacing.m}>
            {aggregateIssuesViewStore.dataFilteredByIssueStatusCount === 0 && (
              <NoListResult
                result={t('noIssuesFound', 'No issues found')}
                filtering={
                  aggregateIssuesViewStore.isFilterEnabled ||
                  !!aggregateIssuesViewStore.containerNumberFilter
                }
                onClearFilter={() => aggregateIssuesViewStore.clearFilters()}
              />
            )}

            {/* it could not find any prop extended from IEntity<T> in IssueItem. For an interim solution, we have to cast to any here first */}
            {aggregateIssuesViewStore.planningDataFilteredByIssueStatus.map((i: any) => (
              <IssueCard
                key={i.id}
                issueItem={i}
                onClickResolve={() => {
                  planningIssueResolutionViewStore.openDialog(i)
                }}
              />
            ))}

            {aggregateIssuesViewStore.operationDataFilteredByIssueStatus.map(
              (issue: IssueWithJourney) => (
                <OperationsIssueCard
                  key={`operations_issue_${issue.id}`}
                  store={aggregateIssuesViewStore}
                  issue={issue}
                  hasResolve
                />
              ),
            )}
          </Stack>
        </Container>

        <PositionMissingDialog store={aggregateIssuesViewStore} />
        <ResolveIssueDialog store={planningIssueResolutionViewStore} />
      </Box>
    </InitializationWrapper>
  )
})
