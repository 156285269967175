import { useAdminStore } from '@admin/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BinIcon, ConfirmationDialog, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ContainerDamageLocationFormMapper } from '../components/containerDamageLocationForm/container-damage-location-form.mapper'
import { ContainerDamageLocationFormProfile } from '../components/containerDamageLocationForm/container-damage-location-form.profile'
import { ContainerDamageLocationForm } from '../components/containerDamageLocationForm/ContainerDamageLocationForm'

export const ContainerDamageLocationsDialogContainer = observer(() => {
  const { t } = useTranslate()
  const { appStore, containerDamageLocationStore, containerDamageLocationDialogUIStore } =
    useAdminStore()

  const handleSubmit = async (formValues: ContainerDamageLocationFormProfile) => {
    if (containerDamageLocationDialogUIStore.formType === 'Add') {
      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.name} ${t('isBeingCreated', 'is being created')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await containerDamageLocationStore.add(
                ContainerDamageLocationFormMapper.mapFormValuesToCreateDto(formValues),
              ),
          ),
        `${formValues.name} ${t('creationCancelled', 'creation cancelled')}`,
      )
    } else {
      const isDefault = containerDamageLocationDialogUIStore.editingEntity?.isDefault

      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.name} ${t('isBeingUpdated', 'is being updated')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await containerDamageLocationStore.update(
                ContainerDamageLocationFormMapper.mapFormValuesToUpdateDto(formValues),
                isDefault,
              ),
          ),
        `${formValues.name} ${t('updateCancelled', 'update cancelled')}`,
      )
    }

    containerDamageLocationDialogUIStore.toggle(
      containerDamageLocationDialogUIStore.editingEntity?.id,
    )
  }

  const handleClose = () => {
    containerDamageLocationDialogUIStore.toggle(
      containerDamageLocationDialogUIStore.editingEntity?.id,
    )
  }

  const handleToggleDelete = () => {
    containerDamageLocationDialogUIStore.toggle(
      containerDamageLocationDialogUIStore.editingEntity?.id,
    )
    containerDamageLocationDialogUIStore.toggleDelete()
  }

  const handleCloseDelete = () => {
    containerDamageLocationDialogUIStore.toggleDelete()
  }

  const handleDelete = async () => {
    if (containerDamageLocationDialogUIStore.editingEntity) {
      const ContainerDamageLocationName = containerDamageLocationDialogUIStore.editingEntity.name
      await containerDamageLocationStore.delete(
        containerDamageLocationDialogUIStore.editingEntity.id,
      )
      handleCloseDelete()

      appStore.setShowAlert('success', `${ContainerDamageLocationName} ${t('deleted', 'deleted')}`)
    }
  }

  return (
    <>
      <ConfirmationDialog
        open={containerDamageLocationDialogUIStore.isOpen}
        title={`${
          containerDamageLocationDialogUIStore.formType === 'Add'
            ? t('addNew', 'Add new')
            : t('edit', 'Edit')
        } ${t('location', 'location')}`}
        primaryActionText={t('save', 'Save')}
        closeLabel={t('cancel', 'Cancel')}
        formId='ContainerDamageLocation-form'
        maxWidth='sm'
        rightRenderOption={
          containerDamageLocationDialogUIStore.formType === 'Edit' &&
          !containerDamageLocationDialogUIStore.editingEntity?.isDefault ? (
            <IconButton variant='contained' color='default' onClick={handleToggleDelete}>
              <BinIcon />
            </IconButton>
          ) : undefined
        }
        onClose={handleClose}
        hasBorderTop
      >
        <ContainerDamageLocationForm
          id='ContainerDamageLocation-form'
          containerDamageLocation={containerDamageLocationDialogUIStore.editingEntity}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>

      <ConfirmationDialog
        open={containerDamageLocationDialogUIStore.isDeleteOpen}
        title={t('deleteName', 'Delete {name}', {
          name: t('location', 'location'),
        })}
        message={t(
          'entityWillBeDeleted',
          '{name} will be deleted and you cannot undo this action!',
          {
            name: containerDamageLocationDialogUIStore.editingEntity?.name ?? t('location'),
          },
        )}
        primaryActionText={t('delete', 'Delete')}
        closeLabel={t('keepEditing', 'Keep editing')}
        maxWidth='xs'
        blockClickOutside
        onConfirm={handleDelete}
        onClose={handleToggleDelete}
      />
    </>
  )
})
