import { CarrierVisitDirection, OrderResponseDto } from '@planning/app/api'

export const sortOrdersByContainerNumber = (orders: OrderResponseDto[]) => {
  return orders.sort((a, b) => (a.containerNumber ?? '').localeCompare(b.containerNumber ?? ''))
}

interface OrderProps {
  isOnTerminal?: boolean
  direction?: string
  hasActiveHolds?: boolean
  isReleaseExpired?: boolean
  allowGateInWhenUnitNotOnTerminal?: boolean
  skipReleaseOrder?: boolean
}
export const canOrderProceed = ({
  allowGateInWhenUnitNotOnTerminal,
  direction,
  hasActiveHolds,
  isOnTerminal,
  isReleaseExpired,
  skipReleaseOrder,
}: OrderProps): boolean => {
  const isValidOnTerminalState =
    direction === CarrierVisitDirection.Inbound
      ? !isOnTerminal
      : allowGateInWhenUnitNotOnTerminal || isOnTerminal

  const isValidState =
    isValidOnTerminalState && !hasActiveHolds && (skipReleaseOrder || !isReleaseExpired)

  return !!isValidState
}
