import { Box, Button, Paper, Typography } from '@mui/material'
import { ContainerTurnoversFilterDto } from '@storage/app/api'
import {
  useCargoStatusOptions,
  useContainerSizeOptions,
  useDamagedOptions,
  useReeferOptions,
} from '@storage/app/container-turnovers/hooks'
import { useOOGOptions } from '@storage/app/container-turnovers/hooks/use-oog-options.hook'
import { useRestowOptions } from '@storage/app/container-turnovers/hooks/use-restow-options.hook'
import Label from '@storage/components/label'
import { hasValue } from '@storage/utils/has-value'
import { UtilityMappers } from '@storage/utils/mappers'
import { useTranslate } from '@tolgee/react'

interface Props {
  numberOfContainers: number
  filter?: ContainerTurnoversFilterDto
  numberOfSelectedSlots: number
}

export const PlanningInformation = ({
  filter,
  numberOfContainers,
  numberOfSelectedSlots,
}: Props) => {
  const { t } = useTranslate()

  const reeferOptions = useReeferOptions()
  const damagedOptions = useDamagedOptions()
  const cargoStatusOptions = useCargoStatusOptions()
  const restowOptions = useRestowOptions()
  const sizeOptions = useContainerSizeOptions()
  const oogOptions = useOOGOptions()

  const reeferFilterLabel = UtilityMappers.mapSelectValueToLabel(
    UtilityMappers.mapBooleanToBooleanSelectOption(filter?.isReefer),
    reeferOptions,
  )

  const damagedFilterLabel = UtilityMappers.mapSelectValueToLabel(
    UtilityMappers.mapBooleanToBooleanSelectOption(filter?.isDamaged),
    damagedOptions,
  )

  const oogFilterLabel = UtilityMappers.mapSelectValueToLabel(
    UtilityMappers.mapBooleanToBooleanSelectOption(filter?.isOOG),
    oogOptions,
  )

  const cargoStatusFilterLabel = UtilityMappers.mapSelectValueToLabel(
    UtilityMappers.mapBooleanToBooleanSelectOption(filter?.isEmpty),
    cargoStatusOptions,
  )

  const sizeFilterLabel = UtilityMappers.mapSelectValueToLabel(
    filter?.size?.toString(),
    sizeOptions,
  )

  const restowFilterLabel = UtilityMappers.mapSelectValueToLabel(
    UtilityMappers.mapBooleanToBooleanSelectOption(filter?.isRestow),
    restowOptions,
  )

  return (
    <Paper sx={{ padding: 2, display: 'flex', gap: 2, flexFlow: 'column' }}>
      {filter && (
        <Box>
          <Box>
            <Typography>{t('filteredContainers', 'Filtered containers')}</Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Label>{numberOfContainers}</Label>

            {filter.containerOrReferenceNumber && (
              <Label>{filter.containerOrReferenceNumber}</Label>
            )}

            {filter.containerIsoCode && <Label>{filter.containerIsoCode}</Label>}

            {filter.locationStatus && <Label>{t(filter.locationStatus)}</Label>}

            {filter.portOfDischarge && <Label>{filter.portOfDischarge}</Label>}

            {filter.portOfLoading && <Label>{filter.portOfLoading}</Label>}

            {filter.typeCode && <Label>{filter.typeCode}</Label>}

            {filter.size && <Label>{sizeFilterLabel}</Label>}

            {filter.containerOperator && <Label>{filter.containerOperator}</Label>}

            {filter.inboundCarrierType && <Label>{t(filter.inboundCarrierType)}</Label>}

            {filter.inboundCarrier && <Label>{filter.inboundCarrier}</Label>}

            {filter.outboundCarrierType && <Label>{t(filter.outboundCarrierType)}</Label>}

            {filter.outboundCarrier && <Label>{filter.outboundCarrier}</Label>}

            {hasValue(filter.isReefer) && <Label>{t(reeferFilterLabel)}</Label>}

            {hasValue(filter.isEmpty) && <Label>{t(cargoStatusFilterLabel)}</Label>}

            {hasValue(filter.isRestow) && <Label>{t(restowFilterLabel)}</Label>}

            {filter.weightClasses.length > 0 &&
              filter.weightClasses.map(wClass => <Label key={wClass}>{wClass}</Label>)}

            {hasValue(filter.isDamaged) && <Label>{t(damagedFilterLabel)}</Label>}

            {hasValue(filter.isOOG) && <Label>{t(oogFilterLabel)}</Label>}

            {filter.imoClasses.length > 0 &&
              filter.imoClasses.map(imoClass => <Label key={imoClass}>{imoClass}</Label>)}
          </Box>
        </Box>
      )}

      <Box>
        <Box>
          <Typography>{t('selectedSlots', 'Selected slots')}</Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Label>{numberOfSelectedSlots}</Label>
          <Button variant='contained' size='small'>
            {t('reserve', 'Reserve')}
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}
