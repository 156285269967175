import { Card, CardActionArea, Grid, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
interface Props {
  railcarNumber: string
  railcarLength?: number | null
  operator?: string | null
  onClickEdit?: () => void
}
export const RailcarsCard = observer(
  ({ railcarNumber, railcarLength, operator, onClickEdit }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    return (
      <CardActionArea
        sx={{
          '.MuiCardActionArea-focusHighlight': {
            background: 'transparent',
          },
          mb: theme.customSpacing.m,
        }}
        onClick={onClickEdit}
      >
        <Card
          sx={{
            padding: theme.customSpacing.m,
          }}
        >
          <Typography variant='h2'>{railcarNumber}</Typography>
          <Grid container mt={theme.customSpacing.s} gap={theme.customSpacing.l}>
            <Grid item>
              <Typography variant='body1'>
                {t('length', 'Length')}:{' '}
                {railcarLength ? `${railcarLength}m` : t('unknown', 'Unknown')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1'>
                {t('operator', 'Operator')}: {operator ?? t('unknown', 'Unknown')}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </CardActionArea>
    )
  },
)
