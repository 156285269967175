import { CompaniesApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class CompanyService {
  httpClient = createApiClient(CompaniesApi)

  async getByPartialName(filter: string) {
    const { data } = await this.httpClient.getByPartialName(filter)

    return data
  }

  async getById(id: number) {
    const { data } = await this.httpClient.getById(id)

    return data
  }
}

const companyService = new CompanyService()

export default companyService
