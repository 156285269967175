import { Grid, Paper, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  CheckoutOrderCommand,
  ContainerResponseDto,
  CustomsResponseDto,
  CustomsStatus,
  OrderResponseDto,
} from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { FeatureFlag } from '@planning/components/FeatureFlags'
import {
  ChangeTransportSubFormType,
  RegisteredChangeTransportSubForm,
} from '@planning/components/organisms/ChangeTransportSubForm'
import { CustomsSubForm } from '@planning/components/organisms/CustomsSubForm'
import { CargoDetailsSummary } from '@planning/pages/Order/components/CargoDetailsSummary'
import { ContainerSummary } from '@planning/pages/Order/components/ContainerSummary'
import adminActionService from '@planning/services/adminActionService'
import { useTranslate } from '@tolgee/react'
import { FormField, useFormStore } from '@tom-ui/utils'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'

interface IProps {
  container?: ContainerResponseDto
  order?: OrderResponseDto
  onBack?: (reset?: boolean) => void
}

const CheckoutContainerForm = observer(({ container, order, onBack }: IProps) => {
  const { t } = useTranslate()
  const { appViewStore } = usePlanningStore()

  const formStore = useFormStore<{
    transport: ChangeTransportSubFormType
    departureTime: Dayjs
    customs: CustomsResponseDto[]
  }>()

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!formStore.validate()) {
      return
    }

    const formData = formStore.data
    const customs = formData.customs

    const orderHasUnresolvedCustoms =
      customs.length > 0 &&
      customs.some(
        custom =>
          custom.status === CustomsStatus.Refused ||
          custom.status === CustomsStatus.InspectionRequired ||
          custom.status === CustomsStatus.Pending,
      )

    if (orderHasUnresolvedCustoms) {
      const proceedWithUnresolvedCustoms = await appViewStore.setOpenConfirmDialog(
        true,
        t(
          'orderHasUnresolvedCustomsProceedWillMarkItAsResolved',
          'This order has unresolved customs. If you proceed, it will be marked as resolved.',
        ),
        t('unresolved customs', 'Unresolved customs'),
      )

      if (!proceedWithUnresolvedCustoms) {
        return
      }
    }

    const cmd: CheckoutOrderCommand = {
      orderId: order?.id ?? 0,
      carrierVisitId: formData.transport.carrierVisitId,
      carrierType: formData.transport.carrierType ?? 'Universal',
      departureTime: formData.departureTime.toISOString(),
    }

    try {
      await adminActionService.checkoutOrder(cmd)
      appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved Successfully'))

      onBack!(true)
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  return (
    <form onSubmit={onSubmit} id='checkout-container-form'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {container && <ContainerSummary container={container} />}
        </Grid>

        <Grid item xs={12} sm={6}>
          {order && (
            <CargoDetailsSummary
              isEmpty={order.isEmpty}
              dangerousGoods={order.isDangerous}
              seals={order.hasSeals}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
            <Typography variant='subtitle1' sx={{ mb: '.5rem' }}>
              {t('containerDepartureDetails', 'Container departure details')}
            </Typography>

            <Grid item sm={3}>
              <FormField formStore={formStore} name={'departureTime'} initialValue={dayjs()}>
                {store => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label={t('departureTime', 'Departure time')}
                        value={store.value}
                        onChange={newValue => (newValue != null ? store.setValue(newValue) : '')}
                        slotProps={{
                          textField: {
                            required: true,
                            variant: 'outlined',
                            helperText: store.error
                              ? t('fieldIsRequired', 'Field is required.')
                              : '',
                            error: !!store.error,
                            sx: { width: '100%', marginTop: '10px' },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )
                }}
              </FormField>
            </Grid>

            <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
              <Typography variant='subtitle2' sx={{ mb: '1rem' }}>
                {t('transportType', 'Transport Type')}
              </Typography>

              <RegisteredChangeTransportSubForm
                name={'transport'}
                formStore={formStore}
                hideContainerReleasePrompt
                initialValues={{
                  carrierType: order?.carrierVisitType,
                  carrierVisitId: order?.carrierVisitId ?? undefined,
                  railcarId: order?.railcarId ?? undefined,
                  railTrackId: order?.railTrackId ?? undefined,
                }}
              />
            </Paper>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <FeatureFlag name='customs-v-1'>
            <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
              <CustomsSubForm
                name={'customs'}
                formStore={formStore}
                initialValue={order?.customs}
                showHeader={true}
                allowDelete={false}
                allowCreation={false}
                allowSelection={false}
                disableReference={true}
                disableStatus={true}
                disableReason={true}
              />
            </Paper>
          </FeatureFlag>
        </Grid>
      </Grid>
    </form>
  )
})

export default CheckoutContainerForm
