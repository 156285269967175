import { Chip, MenuItem, Stack } from '@mui/material'
import { Container } from '@mui/system'
import { GridColDef } from '@mui/x-data-grid'
import { VesselServiceResponseDto } from '@planning/app/api'
import { PaginatedTable } from '@planning/components/PaginatedTable'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { ThreeDotMenu, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  store: IPaginatedStoreWithItems<VesselServiceResponseDto>
  onEdit: (item: VesselServiceResponseDto) => void
  onRemove: (item: VesselServiceResponseDto) => void
}

export const VesselServiceList: FC<IProps> = observer(({ store, onEdit, onRemove }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const columns: GridColDef<VesselServiceResponseDto>[] = [
    {
      field: 'serviceId',
      headerName: t('id', 'ID'),
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'name',
      headerName: t('name', 'Name'),
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'portRotation',
      headerName: t('unCodePortRotation', 'UN Code/Port rotation'),
      renderCell: ({ row }) => {
        const rotationItems = row.portRotation

        return (
          <Stack
            direction='row'
            gap={theme.customSpacing.xs}
            flexWrap='wrap'
            py={theme.customSpacing.xs}
          >
            {rotationItems?.map(item => <Chip key={`service-${row.id}-${item}`} label={item} />)}
          </Stack>
        )
      },
      sortable: false,
      flex: 2,
    },
    {
      field: 'actions',
      headerName: '',
      minWidth: 25,
      sortable: false,
      renderCell: ({ row }) => (
        <Stack alignItems='end' width='100%'>
          <ThreeDotMenu>
            <MenuItem onClick={() => onEdit(row)}>{t('edit', 'Edit')}</MenuItem>
            <MenuItem onClick={() => onRemove(row)}>{t('remove', 'Remove')}</MenuItem>
          </ThreeDotMenu>
        </Stack>
      ),
    },
  ]

  return (
    <Container
      sx={{
        paddingY: theme.customSpacing.m,
      }}
    >
      <PaginatedTable
        store={store}
        columns={columns}
        rowsPerPageOptions={[10, 25, 50]}
        onCellClick={(column, event) => {
          if (column.field === 'actions') {
            event.stopPropagation()
          }
          event.preventDefault()
        }}
        onRowClick={({ row }) => {
          onEdit(row)
        }}
        getRowHeight={() => 'auto'}
      />
    </Container>
  )
})
