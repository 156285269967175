import Permission from '@billing/app/models/permission.enum'
import { useBillingServiceStore } from '@billing/AppProvider'
import { Box, Button, Container, Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Header, NoListResult, PlusIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { Company } from '../components/company/Company'

export const CompaniesContainer = observer(() => {
  const { t } = useTranslate()
  const { companyStore, companyTableUIStore, companyDialogUIStore, authStore } =
    useBillingServiceStore()
  const canEdit = authStore.hasPermission(Permission.WriteCompanies)
  const { closeSnackbar } = useSnackbar()

  useEffect(() => {
    async function fetchData() {
      await companyStore.load()
    }

    fetchData()
  }, [companyStore])

  const handleAddClick = () => {
    closeSnackbar()
    companyDialogUIStore.toggle()
  }

  const handleEditClick = (id: number) => {
    closeSnackbar()
    companyDialogUIStore.toggle(id)
  }

  return (
    <>
      <Header
        title={t('companies', 'Companies')}
        searchInputLabel={t('searchForANameOrID', 'Search for a name, ID')}
        onSearchFieldChange={name => companyTableUIStore.setSearchCompany(name)}
        displaySearchIcon
        enableSearchField
        searchFilter={companyTableUIStore.searchCompany}
        rightRenderOption={
          canEdit ? (
            <Box flex='none'>
              <Button
                color='inherit'
                variant='contained'
                startIcon={<PlusIcon />}
                onClick={handleAddClick}
              >
                {t('addNew', 'Add new')}
              </Button>
            </Box>
          ) : undefined
        }
      />
      <Container>
        <Stack gap={2} paddingY={4}>
          {companyTableUIStore.companies.map(x => (
            <Company
              key={x.id}
              company={x}
              canEdit={canEdit}
              onClick={c => (canEdit ? handleEditClick(c.id) : false)}
            />
          ))}
          {!companyTableUIStore.companies.length && (
            <NoListResult
              result={t('noCompanyFound', 'No company found')}
              filtering={!!companyTableUIStore.searchCompany}
              onClearFilter={() => companyTableUIStore.setSearchCompany('')}
            />
          )}
        </Stack>
      </Container>
    </>
  )
})
