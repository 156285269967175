import { TruckOverdueFormProfile } from '../Components/Molecules/TruckOverdueForm'

export const convertMinutesToTruckOverdueForm = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return {
    overdueHours: hours,
    overdueMinutes: remainingMinutes,
  }
}

export const convertTruckOverdueFormToMinutes = (formValues: TruckOverdueFormProfile) => {
  return +formValues.overdueHours * 60 + +formValues.overdueMinutes
}
