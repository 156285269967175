import { Box, Stack, Typography } from '@mui/material'
import { ContainerResponseDto, UnitType } from '@planning/app/api'
import { DamagedContainerIcon } from '@planning/components/DamagedContainerIcon'
import { useUnitTypeIcon } from '@planning/components/UnitType'
import { emptyIndicator } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, EditIcon, IconButton, useContainerAttributesParameters } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

type Props = {
  container: ContainerResponseDto
  isDamaged?: boolean
  onClickEdit?: () => void
}

export const ContainerVisitsCardHeader: FC<Props> = observer(
  ({ container, isDamaged, onClickEdit }) => {
    const { t } = useTranslate()
    const { types: containerTypeParameters, heights: containerHeightParameters } =
      useContainerAttributesParameters()

    let icon

    if (container.unitType === UnitType.Container) {
      const { icon: containerIcon } = containerTypeParameters[container.type]
      icon = containerIcon
    } else {
      icon = useUnitTypeIcon(container.unitType)
    }

    return (
      <Box sx={{ padding: '1rem', display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {icon}
          <Typography variant='h4' data-cy='container-visit-container-number'>
            {container.number}
          </Typography>

          {container.isoCode && <ColoredLabel color='secondary' label={container.isoCode || ''} />}
          {container.type && (
            <ColoredLabel color='secondary' label={containerTypeParameters[container.type].label} />
          )}
          {container.length && (
            <ColoredLabel
              color='secondary'
              label={`${container.length ? container.length + 'ft' : emptyIndicator}`}
            />
          )}
          {container.height && (
            <ColoredLabel
              color='secondary'
              label={containerHeightParameters[container.height]?.label || ''}
            />
          )}
          {container.maxGross && (
            <ColoredLabel
              color='secondary'
              label={`${t('maxGross', 'MaxGross')}: ${container.maxGross}`}
            />
          )}
          {isDamaged && <DamagedContainerIcon />}
        </Stack>

        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {onClickEdit && (
            <IconButton variant='text' onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          )}
        </Stack>
      </Box>
    )
  },
)
