import { Grid, Stack, Typography } from '@mui/material'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { useTranslate } from '@tolgee/react'
import { ArrowDownRightIcon, ArrowUpLeftIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { DirectionCard } from '../Atoms/DirectionCard'
import { OrderCard } from '../Molecules/OrderCard'

interface Props {
  visit: ITruckVisitItem
  needsReservation?: boolean
  canDisplayWarnings?: boolean
  onReserveLocation?: (orderId: number) => void
}
export const OrderList = observer(
  ({ visit, needsReservation, canDisplayWarnings, onReserveLocation }: Props) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()

    if (!visit.orders.length)
      return (
        <Stack padding={2} sx={{ backgroundColor: theme.palette.grey[200] }}>
          <Typography variant='subtitle2'>{t('noOrdersAttached', 'No orders attached')}</Typography>
        </Stack>
      )

    return (
      <Stack sx={{ backgroundColor: theme.palette.grey[200], overflowX: 'auto' }}>
        {visit.inboundOrders.length > 0 && (
          <Stack flexDirection='row'>
            <DirectionCard label={t('dropOff', 'Drop off')} icon={<ArrowDownRightIcon />} />

            <Grid container>
              {visit.inboundOrders.map(order => (
                <Grid item xs={12} md={visit.inboundOrders.length > 1 ? 6 : 12} key={order.id}>
                  <OrderCard
                    order={order}
                    needsReservation={needsReservation}
                    canDisplayWarnings={canDisplayWarnings}
                    onReserveLocation={onReserveLocation}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}

        {visit.outboundOrders.length > 0 && (
          <Stack flexDirection='row'>
            <DirectionCard label={t('pickUp', 'Pick up')} icon={<ArrowUpLeftIcon />} />
            <Grid container>
              {visit.outboundOrders.map(order => (
                <Grid item xs={12} md={visit.outboundOrders.length > 1 ? 6 : 12} key={order.id}>
                  <OrderCard
                    order={order}
                    needsReservation={needsReservation}
                    canDisplayWarnings={canDisplayWarnings}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}
      </Stack>
    )
  },
)
