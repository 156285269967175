import { Typography } from '@mui/material'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { formatDateRange, formatDateTime } from '@planning/utils'
import { EmptyIndicator } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

interface Props {
  truckVisit: ITruckVisitItem
}
export const TruckVisitAnnouncedDate = observer(({ truckVisit }: Props) => {
  const date = useMemo(() => {
    let date: string | undefined

    if (truckVisit.data.eta) {
      date = formatDateTime(truckVisit.data.eta)

      if (truckVisit.data.etd) {
        date = formatDateRange(truckVisit.data.eta, truckVisit.data.etd)
      }
    }

    return date
  }, [truckVisit.data.eta, truckVisit.data.etd])

  return <Typography variant='subtitle2'>{date ?? EmptyIndicator}</Typography>
})
