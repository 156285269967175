import { Stack, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog, EditIcon, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { convertTruckOverdueFormToMinutes } from '../../Helpers/convert-minutes-form'
import { useFormatMinutesLabel } from '../../Hooks/useFormatMinutesLabel'
import { TruckVisitsViewStore } from '../../Stores/TruckVisitsViewStore'
import { TruckOverdueForm, TruckOverdueFormProfile } from '../Molecules/TruckOverdueForm'

interface Props {
  store: TruckVisitsViewStore
}
export const TruckOverdue = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const { appViewStore } = usePlanningStore()
  const { formatMinutesLabel } = useFormatMinutesLabel()

  const handleSubmit = async (overdueValue: TruckOverdueFormProfile) => {
    try {
      await store.saveOverdueTime(convertTruckOverdueFormToMinutes(overdueValue))
      store.toggleOverdueDialog(false)
      appViewStore.setShowAlert(
        'success',
        t('overdueTimeSuccessfullySaved', 'Overdue time successfully saved'),
      )
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  return (
    <>
      <Stack spacing={1} direction='row'>
        <Typography variant='body2' color='GrayText'>
          {t('timeOnTerminalLimit', 'Time on terminal limit: {value}', {
            value: store.truckOverdue ? formatMinutesLabel(store.truckOverdue) : 'unset',
          })}
        </Typography>
        <IconButton
          onClick={() => {
            store.toggleOverdueDialog(true)
          }}
          size='small'
          style={{ padding: 0 }}
          aria-label='edit'
        >
          <EditIcon />
        </IconButton>
      </Stack>

      <ConfirmationDialog
        open={store.isOverdueDialogOpen}
        formId='overdue-form'
        maxWidth='sm'
        title={t('setOverdueTimeForTheTruck', 'Set overdue time for the truck')}
        primaryActionText={t('save', 'Save')}
        closeLabel={t('cancel', 'Cancel')}
        onClose={() => {
          store.toggleOverdueDialog(false)
        }}
        hasBorderTop
      >
        <TruckOverdueForm
          formId='overdue-form'
          overdueTime={store.truckOverdue}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>
    </>
  )
})
