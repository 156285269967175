import { Button, Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { TruckVisitStatus, TruckVisitsViewStore } from '../../Stores/TruckVisitsViewStore'

interface Props {
  store: TruckVisitsViewStore
}
export const TruckVisitTableFilter = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={1}
      padding={1}
      sx={{
        backgroundColor: theme.palette.grey[300],
      }}
    >
      <Button
        variant='contained'
        size='small'
        color={store.selectedStatus === TruckVisitStatus.expected ? 'primary' : 'inherit'}
        onClick={() => store.setTruckVisitStatus(TruckVisitStatus.expected)}
      >
        {t('announced', 'Announced')} {store.totalPerStatus.expected}
      </Button>
      <Button
        variant='contained'
        size='small'
        color={store.selectedStatus === TruckVisitStatus.arrived ? 'primary' : 'inherit'}
        onClick={() => store.setTruckVisitStatus(TruckVisitStatus.arrived)}
      >
        {t('onTerminal', 'On terminal')} {store.totalPerStatus.arrived}
      </Button>
      <Button
        variant='contained'
        size='small'
        color={store.selectedStatus === TruckVisitStatus.departed ? 'primary' : 'inherit'}
        onClick={() => store.setTruckVisitStatus(TruckVisitStatus.departed)}
      >
        {t('departed', 'Departed')} {store.totalPerStatus.departed}
      </Button>
      <Button
        variant='contained'
        size='small'
        color={store.selectedStatus === TruckVisitStatus.cancelled ? 'primary' : 'inherit'}
        onClick={() => store.setTruckVisitStatus(TruckVisitStatus.cancelled)}
      >
        {t('cancelled', 'Cancelled')} {store.totalPerStatus.cancelled}
      </Button>
    </Stack>
  )
})
