import { Grid, TextField, Typography } from '@mui/material'
import { YesNoChipBox } from '@planning/components/dangerous-goods/YesNoChipBox'
import { useTranslate } from '@tolgee/react'
import { FieldBox, useMinimalsTheme } from '@tom-ui/ui'
import { IFormStore, onlyAllowPositiveInt, useRegisterSubFormStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { OogSubFormStore } from './OogSubFormStore'
import { OogFormData } from './oog-helper'

export type OogSubFormProps<T> = {
  formStore: IFormStore<T>
  name: keyof T
  initialValue?: OogFormData
  onChange?: (value: OogFormData) => void
}

export const OogSubForm = observer(
  <T,>({ formStore, name, initialValue, onChange }: OogSubFormProps<T>) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()

    const store = useRegisterSubFormStore(formStore, name, new OogSubFormStore())

    useEffect(() => {
      store.setValues(initialValue)
    }, [initialValue])

    const handleChange = () => {
      if (onChange) {
        onChange(store.data)
      }
    }

    const handleIsOogChange = (value: boolean) => {
      store.isOog.setValue(value)
      handleChange()
    }

    const validateField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      onlyAllowPositiveInt(event as React.ChangeEvent<HTMLInputElement>, false)

    return (
      <FieldBox
        label={t('OOG', 'OOG')}
        hasDivider={store.isOogSelected}
        rightRender={
          <YesNoChipBox
            activeOption={store.isOogSelected ? 'Yes' : 'No'}
            dataCyYes='oog-field-yes'
            onClickYes={() => handleIsOogChange(true)}
            onClickNo={() => {
              handleIsOogChange(false)
            }}
          />
        }
      >
        {store.isOogSelected && (
          <>
            <Grid container spacing={theme.customSpacing.m}>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>
                  {t('dimensions', 'Dimensions')} ({t('cm', 'cm')})
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                  {...store.overHeight.props}
                  onChange={event => {
                    validateField(event)
                    store.overHeight.onChange(event)
                    handleChange()
                  }}
                  type='number'
                  data-cy='oog-height-input'
                  label={t('top', 'Top')}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                  {...store.overWidthLeft.props}
                  onChange={event => {
                    validateField(event)
                    store.overWidthLeft.onChange(event)
                    handleChange()
                  }}
                  type='number'
                  data-cy='oog-left-input'
                  label={t('left', 'Left')}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                  {...store.overWidthRight.props}
                  onChange={event => {
                    validateField(event)
                    store.overWidthRight.onChange(event)
                    handleChange()
                  }}
                  type='number'
                  data-cy='oog-right-input'
                  label={t('right', 'Right')}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                  {...store.overLengthFront.props}
                  onChange={event => {
                    validateField(event)
                    store.overLengthFront.onChange(event)
                    handleChange()
                  }}
                  type='number'
                  data-cy='oog-front-input'
                  label={t('front', 'Front')}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                  {...store.overLengthRear.props}
                  onChange={event => {
                    validateField(event)
                    store.overLengthRear.onChange(event)
                    handleChange()
                  }}
                  type='number'
                  data-cy='oog-rear-input'
                  label={t('rear', 'Rear')}
                />
              </Grid>
            </Grid>
          </>
        )}
      </FieldBox>
    )
  },
)
