import { useOperationsStore } from '@operations/AppProvider'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { SelectedEquipmentHelmet } from '../components/SelectEquipment/SelectedEquipmentHelmet'
import { EquipmentOperatorAlertContainer } from './EquipmentOperatorAlertContainer'
import { EquipmentOperatorWorkInstructionListContainer } from './EquipmentOperatorWorkInstructionListContainer'

export const GeneralCargoOperatorContainer = observer(() => {
  const {
    equipmentOperatorUIStore,
    equipmentOperatorWorkInstructionsUIStore,
    equipmentOperatorNotificationUIStore,
  } = useOperationsStore()

  useEffect(() => {
    equipmentOperatorUIStore.setIsContainerOperation(false)

    const loadPageData = async () => {
      equipmentOperatorNotificationUIStore.clearNotifications()

      await equipmentOperatorWorkInstructionsUIStore.loadJobs()
      equipmentOperatorWorkInstructionsUIStore.handleFirstSelectedJobType()
    }

    loadPageData()
  }, [
    equipmentOperatorUIStore,
    equipmentOperatorWorkInstructionsUIStore,
    equipmentOperatorNotificationUIStore,
  ])

  return (
    <>
      <SelectedEquipmentHelmet />

      <EquipmentOperatorWorkInstructionListContainer />

      <EquipmentOperatorAlertContainer />
    </>
  )
})
