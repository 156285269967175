import { ContainerDamageLocationDto } from '@admin/app/api'
import { FormProps } from '@admin/app/models'
import { ControlledTextField } from '@admin/components/form-controls'
import useFormWithSchema from '@admin/hooks/use-form-with-schema.hook'
import { Box, FormGroup } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useEffect } from 'react'
import { ContainerDamageLocationFormMapper } from './container-damage-location-form.mapper'
import {
  ContainerDamageLocationFormProfile,
  defaultValues,
} from './container-damage-location-form.profile'
import { schema } from './container-damage-location-form.scheme'

interface Props extends FormProps {
  containerDamageLocation?: ContainerDamageLocationDto
  onSubmit: (formValues: ContainerDamageLocationFormProfile) => Promise<void> | void
}

export const ContainerDamageLocationForm = ({ id, containerDamageLocation, onSubmit }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { handleSubmit, formState, control, reset } =
    useFormWithSchema<ContainerDamageLocationFormProfile>(schema, defaultValues)

  useEffect(() => {
    if (containerDamageLocation) {
      reset(ContainerDamageLocationFormMapper.mapDtoToFormValues(containerDamageLocation))
    }
  }, [reset, containerDamageLocation])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      pb={theme.customSpacing.xs}
    >
      <FormGroup sx={{ gap: theme.customSpacing.l }}>
        <ControlledTextField<ContainerDamageLocationFormProfile>
          name='name'
          formState={formState}
          control={control}
          label={t('name', 'Name')}
          fullWidth
          required
          disabled={containerDamageLocation?.isDefault}
        />

        <ControlledTextField<ContainerDamageLocationFormProfile>
          name='code'
          formState={formState}
          control={control}
          label={t('code', 'Code')}
          fullWidth
        />
      </FormGroup>
    </Box>
  )
}
