import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { LocationCodeDto } from '@planning/app/api'
import { InitializationWrapper } from '@planning/components'
import { useWrappedLocalPagination } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { LocationCodeHeader } from './components/organisms/LocationCodeHeader'
import { LocationCodesFormDialog } from './components/organisms/LocationCodesFormDialog'
import { LocationCodesList } from './components/organisms/LocationCodesList'
import { LocationCodesViewStore } from './stores/LocationCodesViewStore'

export const LocationCodesPage = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const locationCodesStore = useMemo(() => new LocationCodesViewStore(), [])

  const { loading, error } = useAsyncFetch(async () => {
    await locationCodesStore.fetch()
  }, [locationCodesStore])

  const locationCodesListViewStore = useWrappedLocalPagination(
    () => locationCodesStore.filteredData,
    () => locationCodesStore.fetch(),
  )

  if (error) {
    return (
      <Typography variant='h4'>
        {t('errorLoadingUnLocationCodes', 'Error loading UN location codes')}
      </Typography>
    )
  }

  const onEdit = (item: LocationCodeDto) => {
    locationCodesStore.toggleFormDialog(true, item)
  }

  return (
    <InitializationWrapper isInitializing={loading}>
      <Box
        sx={{
          height: '100%',
          bgcolor: theme.palette.grey[100],
        }}
      >
        <Helmet>
          <title>{t('unLocationCodes', 'UN Location codes')}</title>
        </Helmet>
        <LocationCodeHeader viewStore={locationCodesStore} />
        <LocationCodesList store={locationCodesListViewStore} onEdit={onEdit} />
        <LocationCodesFormDialog store={locationCodesStore} />
      </Box>
    </InitializationWrapper>
  )
})
