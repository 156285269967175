import {
  CreateLocationCodeCommand,
  LocationCodeDto,
  UpdateLocationCodeCommand,
} from '@planning/app/api'
import locationCodeService from '@planning/services/locationCodeService'
import { action, computed, makeObservable, observable } from 'mobx'

export interface LocationCodesFormData {
  code?: string
  name: string
  country: string
  isActive: boolean
}

export class LocationCodesViewStore {
  data: LocationCodeDto[] = []
  filter = ''
  firstLoad = false
  isFormDialogOpen = false
  dataToBeEdited?: LocationCodeDto
  isEditMode = false
  constructor() {
    makeObservable(this, {
      data: observable,
      filter: observable,
      isFormDialogOpen: observable,
      dataToBeEdited: observable,
      isEditMode: observable,
      filteredData: computed,
      fetch: action,
      setFilter: action,
      toggleFormDialog: action,
    })
  }

  async fetch() {
    try {
      const result = await locationCodeService.get()
      this.data = result
      this.firstLoad = true
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }

  get filteredData() {
    if (!this.filter) return this.data

    const filterLowerCase = this.filter.toLocaleLowerCase()

    return this.data.filter(item => {
      const codeMatch = item.code?.toLocaleLowerCase().includes(filterLowerCase)

      const nameMatch = item.name?.toLocaleLowerCase().includes(filterLowerCase)

      return codeMatch || nameMatch
    })
  }

  toggleFormDialog = (shouldOpen: boolean, data?: LocationCodeDto) => {
    this.isFormDialogOpen = shouldOpen
    this.dataToBeEdited = data
    this.isEditMode = !!data
  }

  updateItem = (data: LocationCodesFormData) => {
    const dto = data as LocationCodeDto

    const exactIndex = this.data.findIndex(item => item.code! === dto.code!)
    if (exactIndex !== -1) {
      this.data[exactIndex] = dto
    } else {
      const nextIndex = this.data.findIndex(item => item.code! > dto.code!)
      if (nextIndex === -1) {
        this.data.push(dto)
      } else {
        this.data.splice(nextIndex, 0, dto)
      }
    }
  }

  setFilter(filter: string) {
    this.filter = filter
  }

  onSubmit = async (data: LocationCodesFormData) => {
    if (this.isEditMode) {
      const cmd: UpdateLocationCodeCommand = {
        ...data,
        code: data.code!,
      }
      await locationCodeService.put(cmd)
    } else {
      const cmd: CreateLocationCodeCommand = {
        ...data,
      }
      await locationCodeService.post(cmd)
    }
    this.updateItem(data)
  }
}
