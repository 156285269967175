import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import {
  AllocationDestinationAndStackingStrategyDto,
  CarrierVisitDirection,
} from '@storage/app/api'
import AllocationRuleTemplatesTableDialog from '@storage/features/carrier-visit-allocation-rules/components/AllocationRuleTemplatesTableDialog'
import CarrierVisitAllocationRulesDialog from '@storage/features/carrier-visit-allocation-rules/components/CarrierVisitAllocationRulesDialog'
import UnsavedChangesDialog from '@storage/features/carrier-visit-allocation-rules/components/UnsavedChangesDialog'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import YardPlanningDashboardBlocks from '../yard-planning-dashboard-details/components/YardPlanningDashboardBlocks'
import YardPlanningDashboardLegend from '../yard-planning-dashboard-details/components/YardPlanningDashboardLegendComponent'
import { useGoBack } from '../yard-planning-dashboard-details/hooks/use-go-back.hook'
import { GlobalAllocationRuleDeletionConfirmationDialog } from './components/GlobalAllocationRuleListItemDialog'
import { GlobalAllocationRulesHeader } from './components/GlobalAllocationRulesHeader'
import { GlobalAllocationRulesSidePanel } from './components/GlobalAllocationRulesSidePanel'

export const GlobalAllocationRules = observer(() => {
  const carrierVisitId = -1

  const { t } = useTranslate()
  const { breakpoints, palette } = useMinimalsTheme()
  const handleGoBack = useGoBack()

  const {
    allocationRuleTemplatesV2UIStore,
    yardBlockStore,
    yardBlockBayStore,
    yardBlockRowStore,
    yardBlockStackStore,
    weightClassContainerUIStore,
    yardBlockSlotStore,
    weightClassStore,
    companyStore,
    globalAllocationRulesUIStore,
  } = useStores()

  // Needed to be loaded once regardless of the handling direction
  useEffect(() => {
    Promise.all([
      yardBlockStore.loadList(),
      yardBlockBayStore.loadList(),
      yardBlockRowStore.loadAll(),
      yardBlockStackStore.loadAll({}),
      yardBlockSlotStore.loadOccupiedSlots(),

      companyStore.loadCompanies(),
      weightClassStore.loadAll(),
    ])
    return () => {
      yardBlockStore.resetData()
      yardBlockBayStore.resetData()
      yardBlockRowStore.resetData()
      yardBlockStackStore.resetData()
      yardBlockSlotStore.resetData()
      weightClassStore.resetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const loadTurnovers = async () => {
      await globalAllocationRulesUIStore.loadTurnoversByDirection()
    }

    loadTurnovers()

    return () => {
      globalAllocationRulesUIStore.reset()
    }
  }, [globalAllocationRulesUIStore])

  useEffect(() => {
    globalAllocationRulesUIStore.handleDestinationsHighlight()
  }, [
    globalAllocationRulesUIStore,
    globalAllocationRulesUIStore.allocationRules,
    globalAllocationRulesUIStore.selectedAllocationRule,
    globalAllocationRulesUIStore.showAllocationRuleForm,
  ])

  const handleOnClose = () => {
    globalAllocationRulesUIStore.allocationRuleTemplatesDialogUtilStore.toggleDialog()
    allocationRuleTemplatesV2UIStore.resetSearchFilter()
  }

  const handleCopyToGlobalRule = (
    allocationRuleId: string,
    destinationAndStackingStrategies: AllocationDestinationAndStackingStrategyDto[],
  ) => {
    globalAllocationRulesUIStore.copyAllocationRuleTemplateToGlobalRule(
      allocationRuleId,
      destinationAndStackingStrategies,
    )
  }

  const handleUnsavedChangesDialogConfirm = async () => {
    if (globalAllocationRulesUIStore.unsavedChangesDialogContext === 'direction') {
      await globalAllocationRulesUIStore.setActiveHandlingDirection(
        globalAllocationRulesUIStore.activeHandlingDirection === CarrierVisitDirection.Outbound
          ? CarrierVisitDirection.Inbound
          : CarrierVisitDirection.Outbound,
      )
    } else {
      handleGoBack()
    }
    globalAllocationRulesUIStore.toggleUnsavedChangesDialog()
  }
  const handleUnsavedChangesDialogCancel = () => {
    globalAllocationRulesUIStore.toggleUnsavedChangesDialog()
  }

  const unsavedDialogContent = {
    message:
      globalAllocationRulesUIStore.unsavedChangesDialogContext === 'direction'
        ? t(
            'unsavedChangesDirectionMessage',
            'You have unsaved changes. Are you sure you want to switch direction? Your changes will be lost.',
          )
        : t(
            'unsavedChangesLeaveMessage',
            'You have unsaved changes. Are you sure you want to leave? Your changes will be lost.',
          ),
    confirmLabel:
      globalAllocationRulesUIStore.unsavedChangesDialogContext === 'direction'
        ? t('cancel', 'Cancel')
        : t('stay', 'Stay'),
    cancelLabel:
      globalAllocationRulesUIStore.unsavedChangesDialogContext === 'direction'
        ? t('switchAnyway', 'Switch Anyway')
        : t('leaveAndDiscard', 'Leave & Discard'),
  }

  return (
    <Box sx={{ height: '100%', backgroundColor: palette.grey[200] }}>
      <GlobalAllocationRulesHeader />

      <Grid
        container
        spacing={4}
        sx={{
          padding: 2,
          [breakpoints.up('sm')]: {
            height: 'calc(100% - 50px)',
          },
        }}
      >
        <Grid item xs={12} sm={5} lg={4} xl={3}>
          <GlobalAllocationRulesSidePanel
            weightClasses={weightClassContainerUIStore.weightClasses}
          />
        </Grid>

        <Grid item xs={12} sm={7} lg={8} xl={9} sx={{ height: '100%', position: 'relative' }}>
          <YardPlanningDashboardLegend
            carrierVisitDirection={globalAllocationRulesUIStore.activeHandlingDirection}
          />
          <YardPlanningDashboardBlocks
            uiStore={globalAllocationRulesUIStore}
            carrierVisitDirection={globalAllocationRulesUIStore.activeHandlingDirection}
            carrierVisitId={carrierVisitId}
          />
        </Grid>
      </Grid>

      <UnsavedChangesDialog
        open={globalAllocationRulesUIStore.isUnsavedChangesDialogOpen}
        message={unsavedDialogContent.message}
        confirmLabel={unsavedDialogContent.confirmLabel}
        cancelLabel={unsavedDialogContent.cancelLabel}
        onConfirm={handleUnsavedChangesDialogConfirm}
        onCancel={handleUnsavedChangesDialogCancel}
      />

      <CarrierVisitAllocationRulesDialog
        destinationUIStore={globalAllocationRulesUIStore}
        isOpen={globalAllocationRulesUIStore.allocationRuleTemplatesDialogUtilStore.isDialogOpen}
        onCopy={handleCopyToGlobalRule}
        onClose={handleOnClose}
      />

      <GlobalAllocationRuleDeletionConfirmationDialog />

      <AllocationRuleTemplatesTableDialog destinationUIStore={globalAllocationRulesUIStore} />
    </Box>
  )
})
