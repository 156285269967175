import { debounce, TextField } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { TruckVisitsViewStore } from '../../Stores/TruckVisitsViewStore'

interface Props {
  store: TruckVisitsViewStore
}
export const TruckVisitSearch = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const [filter, setFilter] = useState('')

  useEffect(() => {
    if (!store.filter) setFilter('')
  }, [store.filter])

  const OnSearchFilterChange = debounce(async (filter: string) => {
    store.setFilter(filter)
  }, 500)

  return (
    <TextField
      placeholder={t('searchByTruckPlateAndTruckDriver', 'Search by truck plate, truck driver')}
      value={filter}
      sx={{ width: '300px', marginTop: 0 }}
      data-cy='visits-page-search-field'
      onChange={event => {
        setFilter(event.currentTarget.value)
        OnSearchFilterChange(event.currentTarget.value)
      }}
    />
  )
})
