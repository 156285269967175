import { Stack, Typography } from '@mui/material'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { ArrowDownRightIcon, ArrowUpLeftIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

interface Props {
  truckVisit: ITruckVisitItem
}
export const OrderCounts = observer(({ truckVisit }: Props) => {
  const [inbound, setInbound] = useState<number | undefined>(undefined)
  const [outbound, setOutbound] = useState<number | undefined>(undefined)

  useEffect(() => {
    setInbound(truckVisit.inboundOrders.length)

    setOutbound(truckVisit.outboundOrders.length)
  }, [truckVisit.inboundOrders.length, truckVisit.outboundOrders.length])

  return (
    <Stack spacing={2} direction='row' alignItems='center' sx={{ margin: 1 }}>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography variant='subtitle2'>{inbound ?? 0}</Typography>
        <ArrowDownRightIcon fontSize='small' />
      </Stack>
      <Stack spacing={1} direction='row' alignItems='center'>
        <ArrowUpLeftIcon fontSize='small' />
        <Typography variant='subtitle2'>{outbound ?? 0}</Typography>
      </Stack>
    </Stack>
  )
})
