import { ErrorCode } from '@admin/utils/error-code/error-code-enum'
import { getAllErrorDescriptions } from '@admin/utils/error-code/error-code-utils'
import { useTranslate } from '@tolgee/react'

interface ISpecialRules {
  customIsoCodeFieldCannotBeEmptyMessage: boolean
}

export const useArchetypeMessagesHandler = () => {
  const { t } = useTranslate()

  const getExceptionMessage = (error: any, specialRules?: ISpecialRules) => {
    const errorDescriptions = getAllErrorDescriptions(error)

    const errors: string[] = []

    errorDescriptions.forEach(errorDescription => {
      if (errorDescription.code === ErrorCode.EntityNotFound) {
        errors.push(
          t('entityNotFound', '{entity} with Id {id} not found', {
            entity: errorDescription.payload?.name,
            id: errorDescription.payload?.id,
          }),
        )
      }

      if (errorDescription.code === ErrorCode.FieldCannotBeEmpty) {
        if (
          specialRules?.customIsoCodeFieldCannotBeEmptyMessage &&
          errorDescription.propertyName === 'IsoCodes'
        ) {
          errors.push(
            t(
              'unableToRemoveLastIsoCodeFromArchetype',
              'Unable to remove the last ISO Code from an archetype',
            ),
          )
        } else {
          errors.push(
            t('fieldCannotBeEmpty', '{field} cannot be empty', {
              field: t(errorDescription.propertyName ?? ''),
            }),
          )
        }
      }

      if (errorDescription.code === ErrorCode.FieldMustBeGreaterThan) {
        errors.push(
          t('fieldMustBeGreaterThan', '{field} must be greater than {value}', {
            field: t(errorDescription.propertyName ?? ''),
            value: errorDescription.payload,
          }),
        )
      }

      if (errorDescription.code === ErrorCode.NameNotUnique) {
        errors.push(
          t('fieldNotUnique', 'The {field} "{name}" already exists', {
            field: t('name', 'name'),
            name: errorDescription.payload,
          }),
        )
      }

      if (errorDescription.code === ErrorCode.ValueAlreadyInUse) {
        errors.push(
          t('valueAlreadyInUse', 'ISO Code {isoCode} is already assigned to another Archetype', {
            isoCode: errorDescription.payload,
          }),
        )
      }
    })

    if (errors.length > 0) return errors.join('\n')

    return error?.response?.data?.detail ?? t('failedToSaveArchetype', 'Failed to save archetype')
  }

  const getSuccessMessage = (isEditMode: boolean, name: string) => {
    if (isEditMode) {
      return t('archetypeWasUpdatedSuccessfully', 'Archetype {name} was updated successfully', {
        name: name,
      })
    }

    return t('archetypeWasCreatedSuccessfully', 'Archetype {name} was created successfully', {
      name: name,
    })
  }

  return { getExceptionMessage, getSuccessMessage }
}
