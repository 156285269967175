import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Grid,
  ListItem,
  ListItemIcon,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DangerousGoodsDefinitionDto, UnitLabelType } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { DangerousGoodAutoComplete } from '@planning/components/dangerous-goods/DangerousGoodsAutoComplete'
import { UnNumbersAutoComplete } from '@planning/components/dangerous-goods/UnNumbersAutoComplete'
import { OogFormData } from '@planning/components/organisms/OogSubForm/oog-helper'
import { OogSubForm } from '@planning/components/organisms/OogSubForm/OogSubForm'
import { UnitLabelFormData } from '@planning/components/organisms/UnitLabelSubForm/unit-label-helper'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useFormStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { CustomInputAdornment, FieldBox } from 'modules/ui/src/theme/components'
import { FC, useCallback, useEffect, useState } from 'react'
import { Controller, UseFormSetValue, useWatch } from 'react-hook-form'

interface CargoDetailsProps {
  oog: OogFormData
}

interface IProps {
  control: any
  register: any
  errors: any
  getValues: (field: string) => any
  isReeferContainer: boolean
  isGateControl?: boolean
  isDangerousContainer: boolean
  isEmpty: boolean
  prefix: string
  setValue?: UseFormSetValue<any>
  inputRef?: React.Ref<any>
}

export const CreateOrderFormCargoStatusFieldBox: FC<IProps> = observer(
  ({
    control,
    register,
    errors,
    getValues,
    isGateControl,
    isDangerousContainer,
    isEmpty,
    prefix, // Use the prefix to differentiate fields
    setValue,
    inputRef,
  }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const imoClasses = useWatch({ control, name: `${prefix}.imoClasses` })
    const unitLabel = getValues(`${prefix}.unitLabel`) as UnitLabelFormData

    const dangerousGoodsDefinitions = useWatch({
      control,
      name: `${prefix}.dangerousGoodsDefinitions`,
    })

    const formStore = useFormStore<CargoDetailsProps>()

    const { dangerousGoodsDefinitionDataStore } = usePlanningStore()

    useEffect(() => {
      dangerousGoodsDefinitionDataStore.setPageSize(25)
    }, [dangerousGoodsDefinitionDataStore])

    const handleUNNumbersInputChange = useCallback(
      (newValue?: string) => {
        dangerousGoodsDefinitionDataStore.setFilter(newValue)
      },
      [dangerousGoodsDefinitionDataStore],
    )

    const [isDangerous, setIsDangerous] = useState<boolean>(isDangerousContainer)

    const [imoClassMatchingUNNUmbersError, setImoClassMatchingUNNUmbersError] = useState<
      string | undefined
    >(undefined)

    const [showCargoDetails, setShowCargoDetails] = useState<boolean>(isEmpty)

    const [unNumbersSelected, setUnNumbers] = useState<DangerousGoodsDefinitionDto[] | undefined>(
      getValues(`${prefix}.dangerousGoodsDefinitions`),
    )

    const handleUnNumberChange = (UnNumber?: DangerousGoodsDefinitionDto[]) => {
      const currentAssignedCodes = getValues(`${prefix}.imoClasses`) ?? []
      const newImoClasses = UnNumber
        ? [
            ...UnNumber.flatMap(un => un.primaryImoClass || []),
            ...UnNumber.flatMap(un => un.secondaryImoClasses || []),
          ]
        : []

      // Combine and remove duplicates
      const updatedAssignedCodes = [...new Set([...currentAssignedCodes, ...newImoClasses])]

      setValue && setValue(`${prefix}.imoClasses`, updatedAssignedCodes)
    }

    useEffect(() => {
      if (isDangerous) {
        if (!imoClasses || imoClasses.length === 0) {
          setImoClassMatchingUNNUmbersError(t('fieldIsRequired', 'Field is required.'))
          return
        }

        const imoClassesErrorMessages = {
          single: {
            id: 'missingImoClassesSingle',
            defaultValue:
              'Please add the following IMO class: {classes} to match the selected UN numbers.',
          },
          plural: {
            id: 'missingImoClassesMultiple',
            defaultValue:
              'Please add the following IMO classes: {classes} to match the selected UN numbers.',
          },
        }
        const missingImoClasses =
          dangerousGoodsDefinitions
            ?.map((dg: DangerousGoodsDefinitionDto) => dg.primaryImoClass)
            .filter((imoClass: string) => !imoClasses.includes(imoClass)) ?? []

        if (missingImoClasses.length > 0) {
          const errorKey = missingImoClasses.length === 1 ? 'single' : 'plural'
          setImoClassMatchingUNNUmbersError(
            t(
              imoClassesErrorMessages[errorKey].id,
              imoClassesErrorMessages[errorKey].defaultValue,
              {
                classes: missingImoClasses.join(', '),
              },
            ),
          )
        } else {
          setImoClassMatchingUNNUmbersError(undefined)
        }
      } else {
        setImoClassMatchingUNNUmbersError(undefined)
      }
    }, [isDangerous, imoClasses, dangerousGoodsDefinitions, t])

    const hasVgm = useWatch({ control, name: `${prefix}.vgm` })

    return (
      <Box mt={'1rem'}>
        <FieldBox
          label={t(`${prefix}.cargoStatus`, 'Cargo status')}
          rightRender={
            <Controller
              control={control}
              name={`${prefix}.isEmpty`}
              render={({ field: { onChange } }) => (
                <Box>
                  <Chip
                    label={t('full', 'Full')}
                    color='primary'
                    sx={{ padding: '0 1rem', mr: '.75rem' }}
                    variant={!showCargoDetails ? 'outlined' : undefined}
                    onClick={() => {
                      onChange(false)
                      setShowCargoDetails(true)
                    }}
                  />
                  <Chip
                    label={t('empty', 'Empty')}
                    color='primary'
                    sx={{ padding: '0 1rem' }}
                    variant={showCargoDetails ? 'outlined' : undefined}
                    onClick={() => {
                      onChange(true)
                      setShowCargoDetails(false)
                    }}
                  />
                </Box>
              )}
            />
          }
        >
          {showCargoDetails && (
            <Stack gap={2}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label={`${t('grossWeight', 'Gross weight')}`}
                    variant='outlined'
                    type='tel'
                    required={!isGateControl && !hasVgm}
                    InputProps={{
                      endAdornment: (
                        <CustomInputAdornment position='end' color='secondary'>
                          kg
                        </CustomInputAdornment>
                      ),
                    }}
                    {...register(`${prefix}.grossWeight`, {
                      required: !isGateControl && !hasVgm,
                      valueAsNumber: true,
                    })}
                    name={`${prefix}.grossWeight`}
                    error={errors[prefix] && !!errors[prefix]?.grossWeight}
                    helperText={
                      errors[prefix]?.grossWeight ? t('fieldIsRequired', 'Field is required.') : ''
                    }
                    data-cy='gross-weight-input'
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label={`${t('vgm', 'VGM')}`}
                    variant='outlined'
                    type='tel'
                    InputProps={{
                      endAdornment: (
                        <CustomInputAdornment position='end' color='secondary'>
                          kg
                        </CustomInputAdornment>
                      ),
                    }}
                    {...register(`${prefix}.vgm`, { valueAsNumber: true })}
                    name={`${prefix}.vgm`}
                    error={!!errors[`${prefix}.vgm`]}
                    helperText={
                      errors[`${prefix}.vgm`] ? t('fieldIsRequired', 'Field is required.') : ''
                    }
                    data-cy='vgm-input'
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={`${prefix}.content`}
                    defaultValue=''
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={`${t('content', 'Content')}`}
                        variant='outlined'
                        type='text'
                        name={`${prefix}.content`}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Paper variant='outlined' sx={{ p: '1rem' }}>
                <Controller
                  control={control}
                  name={`${prefix}.imoClasses`}
                  rules={{ required: isDangerous }}
                  render={({ field: { onChange } }) => (
                    <DangerousGoodAutoComplete
                      inputRef={inputRef}
                      required={isDangerous}
                      assignedCodes={getValues(`${prefix}.imoClasses`) ?? []}
                      enableSelect={isDangerous}
                      onToggleSelectDangerousGoods={() => setIsDangerous(!isDangerous)}
                      onDangerousGoodChange={newValues => {
                        onChange(newValues as [])
                      }}
                      error={!!errors[`${prefix}.imoClasses`] || !!imoClassMatchingUNNUmbersError}
                      helperText={imoClassMatchingUNNUmbersError}
                    />
                  )}
                />

                {isDangerous && (
                  <Controller
                    control={control}
                    name={`${prefix}.dangerousGoodsDefinitions`}
                    render={({ field: { onChange } }) => (
                      <UnNumbersAutoComplete
                        enableSelect={isDangerous}
                        onInputChange={handleUNNumbersInputChange}
                        options={dangerousGoodsDefinitionDataStore.pageItems}
                        onUnNumbersChange={newValues => {
                          onChange(newValues as [])
                        }}
                        selectedUnNumbers={unNumbersSelected}
                        handleOnChange={UnNumber => {
                          setUnNumbers(UnNumber)
                          handleUnNumberChange(UnNumber)
                        }}
                      />
                    )}
                  />

                  /*<TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  inputProps={{ maxLength: 4 }}
                  label={`${t('unNumber', 'UN Number')}`}
                  {...register(`${prefix}.unNumber`)}
                  variant='outlined'
                  type='text'
                  name={`${prefix}.unNumber`}
                />*/
                )}
                {isDangerous && (
                  <Controller
                    control={control}
                    name={`${prefix}.otherLabels`}
                    render={({ field: { onChange, value: selectedValue } }) => (
                      <Autocomplete
                        multiple
                        disabled={!isDangerous}
                        disableCloseOnSelect
                        fullWidth
                        options={Object.values(UnitLabelType)}
                        onChange={(_, types) => {
                          onChange(types)
                        }}
                        value={selectedValue}
                        getOptionLabel={option => t(option)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            sx={{ marginTop: '1rem' }}
                            label={t('otherLabels', 'Other labels')}
                          />
                        )}
                        renderOption={(props, value) => (
                          <ListItem {...props}>
                            <ListItemIcon>
                              <Checkbox
                                edge='start'
                                checked={selectedValue?.includes(value) ?? false}
                                disableRipple
                              />
                            </ListItemIcon>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography variant='body2'>{t(value)}</Typography>
                            </Box>
                          </ListItem>
                        )}
                      />
                    )}
                  />
                )}
              </Paper>
            </Stack>
          )}
        </FieldBox>
        {/* Observed labels */}
        <Box mt={'1rem'}>
          <FieldBox label={t(`${prefix}.observedLabels`, `Observed labels`)} rightRender={null}>
            {unitLabel?.isUnitLabel ? (
              <Stack>
                <TextField
                  sx={{ marginBottom: '1rem' }}
                  label={'IMO Classes'}
                  value={unitLabel?.imoClasses?.join(', ')}
                  disabled
                />
                <TextField
                  sx={{ marginBottom: '1rem' }}
                  label={'UN Numbers'}
                  value={unitLabel?.unNumbers?.join(', ')}
                  disabled
                />
                <TextField label={'Other Labels'} value={unitLabel?.types?.join(', ')} disabled />
              </Stack>
            ) : (
              <Box
                sx={{
                  backgroundColor: theme.palette.background.neutral,
                  textAlign: 'center',
                  borderRadius: theme.customRadius.medium,
                  px: theme.customSpacing.m,
                  py: theme.customSpacing.xs,
                }}
              >
                <Typography variant='body2' color={theme.palette.text.primary}>
                  {t('noObservedLabels', 'No observed labels')}
                </Typography>
              </Box>
            )}
          </FieldBox>
        </Box>

        {showCargoDetails && (
          <Box mt={'1rem'}>
            <Controller
              control={control}
              name={`${prefix}.oog`}
              rules={{ required: isDangerous }}
              render={({ field: { onChange, value } }) => (
                <OogSubForm
                  formStore={formStore}
                  name='oog'
                  initialValue={value}
                  onChange={oog => {
                    onChange(oog)
                  }}
                />
              )}
            />
          </Box>
        )}
      </Box>
    )
  },
)
