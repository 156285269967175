import { FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { CustomsStatus } from '@planning/app/api'
import { CustomsItemType } from '@planning/components/organisms/CustomsSubForm'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { DeleteIconButton } from '../../../atoms/DeleteIconButton'
import { MultiselectCheckbox } from '../../../atoms/MultiselectCheckbox'

type IProps = {
  customs: CustomsItemType
  allowSelection?: boolean
  disableReference?: boolean
  disableStatus?: boolean
  disableReason?: boolean
  onChangeState: (checked: boolean) => void
  onDelete?: () => void
}

export const CustomsItem: FC<IProps> = observer(
  ({
    customs,
    allowSelection,
    disableReference,
    disableStatus,
    disableReason,
    onChangeState,
    onDelete,
  }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()

    return (
      <Stack direction={'row'} justifyContent={'space-between'} gap={theme.customSpacing.xs}>
        <Stack flex={1} direction={'row'} alignItems={'center'} gap={theme.customSpacing.xs}>
          {allowSelection && (
            <MultiselectCheckbox value={customs.selected.value} handleOnChange={onChangeState} />
          )}
          <TextField
            label={t('reference', 'Reference')}
            variant='outlined'
            fullWidth
            {...customs.reference.props}
            helperText={customs.reference.error}
            disabled={disableReference}
          />
          {customs.status.value === CustomsStatus.Refused && (
            <TextField
              label={t('reason', 'Reason')}
              variant='outlined'
              fullWidth
              {...customs.reason.props}
              helperText={customs.reason.error}
              disabled={disableReason}
            />
          )}
          <FormControl sx={{ minWidth: 160 }}>
            <Select
              sx={{
                backgroundColor: theme.palette.background.neutral,
              }}
              size='small'
              {...customs.status.props}
              defaultValue={customs.status.value}
              disabled={disableStatus}
            >
              <MenuItem value={CustomsStatus.Pending}>
                <Typography variant='body2' color={theme.palette.secondary.dark}>
                  {t('pending', 'Pending')}
                </Typography>
              </MenuItem>
              <MenuItem value={CustomsStatus.InspectionRequired}>
                <Typography variant='body2' color={theme.palette.secondary.dark}>
                  {t('inspectionRequired', 'Inspection required')}
                </Typography>
              </MenuItem>
              <MenuItem value={CustomsStatus.Cleared}>
                <Typography variant='body2' color={theme.palette.secondary.dark}>
                  {t('cleared', 'Cleared')}
                </Typography>
              </MenuItem>
              <MenuItem value={CustomsStatus.Refused}>
                <Typography variant='body2' color={theme.palette.secondary.dark}>
                  {t('refused', 'Refused')}
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        {onDelete && <DeleteIconButton onClick={onDelete} />}
      </Stack>
    )
  },
)
