import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { LocationCodesFormData, LocationCodesViewStore } from '../../stores/LocationCodesViewStore'

interface Props {
  store: LocationCodesViewStore
}

const getDefaultValues = (): LocationCodesFormData => ({
  code: '',
  name: '',
  country: '',
  isActive: true,
})

export const LocationCodesFormDialog = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { appViewStore } = usePlanningStore()
  const title = store.dataToBeEdited
    ? t('editLocationCode', 'Edit location code')
    : t('addNewLocationCode', 'Add new location code')

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    trigger,
  } = useForm<LocationCodesFormData>({
    defaultValues: getDefaultValues(),
  })

  useEffect(() => {
    if (store.dataToBeEdited) {
      reset({
        code: store.dataToBeEdited?.code ?? '',
        name: store.dataToBeEdited?.name ?? '',
        country: store.dataToBeEdited?.country ?? '',
        isActive: store.dataToBeEdited?.isActive,
      })
    } else {
      reset(getDefaultValues())
    }
  }, [store.dataToBeEdited, reset])

  const getSuccessMessage = (isEditMode: boolean, name: string | null) => {
    return isEditMode
      ? t('locationCodeUpdateSuccess', 'Location code data for {name} updated successfully', {
          name,
        })
      : t(
          'locationCodeCreatedSuccessfully',
          'Location code data for {name} was created successfully',
          { name },
        )
  }

  const getExceptionMessage = (error: any) => {
    return (
      error?.response?.data?.detail ?? t('failedToSaveLocationCode', 'Failed to save location code')
    )
  }

  const onSubmit: SubmitHandler<LocationCodesFormData> = async data => {
    const isEditMode = !!store.dataToBeEdited

    try {
      await store.onSubmit(data)
      appViewStore.setShowAlert('success', getSuccessMessage(isEditMode, data.name))
      store.toggleFormDialog(false)
      reset()
    } catch (error: any) {
      appViewStore.setShowAlert('error', getExceptionMessage(error))
    }
  }

  const handleDialogClose = () => {
    store.toggleFormDialog(false)
  }

  const handleFormSubmit = async () => {
    const isValid = await trigger()

    if (isValid) {
      const formData = getValues()
      onSubmit(formData)
    }
  }

  return (
    <Dialog maxWidth='sm' fullWidth open={store.isFormDialogOpen} onClose={handleDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <form>
        <DialogContent>
          <Stack spacing={theme.customSpacing.s}>
            <FormGroup sx={{ gap: theme.customSpacing.xs }}>
              <Controller
                name='code'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('code', 'Code')}
                    disabled={!!store.dataToBeEdited?.code}
                    required
                    fullWidth
                    variant='outlined'
                    error={!!errors.code}
                    helperText={errors.code ? t('fieldIsRequired', 'Field is required.') : ''}
                  />
                )}
              />
            </FormGroup>
            <FormGroup sx={{ gap: theme.customSpacing.xs }}>
              <Controller
                name='name'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('name', 'Name')}
                    fullWidth
                    required
                    variant='outlined'
                    error={!!errors.name}
                    helperText={errors.name ? t('fieldIsRequired', 'Field is required.') : ''}
                  />
                )}
              />
            </FormGroup>
            <FormGroup sx={{ gap: theme.customSpacing.xs }}>
              <Controller
                name='country'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('country', 'Country')}
                    fullWidth
                    required
                    variant='outlined'
                    error={!!errors.country}
                    helperText={errors.country ? t('fieldIsRequired', 'Field is required.') : ''}
                  />
                )}
              />
            </FormGroup>
            <FormGroup sx={{ gap: theme.customSpacing.xs }}>
              <Controller
                name='isActive'
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value ?? false}
                        onChange={e => {
                          const value = e.target.checked
                          field.onChange(value)
                        }}
                      />
                    }
                    label={t('isActive', 'Is Active')}
                  />
                )}
              />
            </FormGroup>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            mt: theme.customSpacing.l,
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Stack direction={'row'} spacing={2}>
            <Button onClick={handleFormSubmit} variant='contained' color='primary'>
              {t('save', 'Save')}
            </Button>
            <Button onClick={handleDialogClose} color='secondary'>
              {t('cancel', 'Cancel')}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
})
