import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
} from '@mui/material'
import { SelectOption } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'
import { AllTranslationKey, AllValue } from './constants'

type FieldProps = Pick<SelectProps, 'disabled' | 'multiple' | 'size' | 'margin'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  label: string
  fullWidth?: boolean
  allOption?: boolean
  options: SelectOption[]
  formControlSx?: SxProps
  autoFocus?: boolean
  maxMenuHeight?: string | number
  dataCy?: string
}

export const ControlledSelect = <T extends FieldValues>({
  name,
  control,
  label,
  disabled,
  autoFocus,
  multiple,
  fullWidth,
  options,
  size,
  allOption,
  formState: { errors },
  formControlSx,
  margin,
  maxMenuHeight,
  dataCy,
}: Props<T>) => {
  const { t } = useTranslate()

  return (
    <FormControl margin={margin} sx={formControlSx} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            {...field}
            value={field.value ?? AllValue}
            onChange={({ target }) => field.onChange(target.value)}
            error={!!errors[name]}
            size={size}
            multiple={multiple}
            disabled={disabled}
            label={label}
            autoFocus={autoFocus}
            MenuProps={maxMenuHeight ? { sx: { maxHeight: maxMenuHeight } } : {}}
            data-cy={dataCy}
          >
            {allOption && <MenuItem value={AllValue}>{t(AllTranslationKey)}</MenuItem>}
            {options.map(({ value, label }) => (
              <MenuItem key={value.toString()} value={value.toString()} data-cy={value}>
                <ListItemText primary={t(label, label)} />
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors[name] && (
        <FormHelperText error={!!errors[name]}>{errors[name]?.message as string}</FormHelperText>
      )}
    </FormControl>
  )
}
