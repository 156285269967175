import { CreateWeightClassDto } from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import { VesselServiceStore } from '@storage/stores/vessel-service.store'
import { action, computed, makeObservable, observable } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import { WeightClassSetVesselServiceFormData } from '../components/organisms/WeightClassSetVesselServiceDialog'
import { WeightClassSetStore } from './weight-class-set.store'

export interface WeightClassSetControlDto {
  name: string
  vesselServiceId?: number | null
  containerLength?: number | null
  weightClasses: CreateWeightClassControlDto[]
  isActive: boolean
}
export interface WeightClassSetCreatedControlDto extends WeightClassSetControlDto {
  id?: string
}

export interface WeightClassSetUpdatedControlDto extends WeightClassSetControlDto {
  id: string
}

export interface WeightClassSetByVesselService {
  name: string
  vesselServiceId?: number | null
  weightClassSets: WeightClassSetCreatedControlDto[]
}

interface CreateWeightClassControlDto extends CreateWeightClassDto {
  id?: string | null
}

interface WeightClassesUpsertWeightClassSetsControl {
  created: WeightClassSetCreatedControlDto[]
  updated: WeightClassSetUpdatedControlDto[]
  deletedIds: string[]
}

const defaultWeightClass = {
  name: 'New Class',
  minWeight: 0,
  maxWeight: undefined,
}

export class WeightClassSetContainerUIStore {
  isDeleteConfirmationDialogOpen = false
  isVesselServiceDialogOpen = false

  selectedWeightClassSetMode = 0

  weightClassSetsControl: WeightClassesUpsertWeightClassSetsControl = {
    created: [],
    updated: [],
    deletedIds: [],
  }

  toBeRemovedWeightClassSetVesselServiceId?: number | null

  selectedWeightClassSetVesselServiceId?: number | null
  editingVesselServiceId?: number | null = undefined

  errors = new Map<string, { nameError?: boolean; weightError?: boolean }>()

  constructor(
    private readonly _snackbarStore: SnackbarStore,
    private readonly _weightClassSetStore: WeightClassSetStore,
    private readonly _vesselServiceStore: VesselServiceStore,
  ) {
    makeObservable(this, {
      selectedWeightClassSetVesselServiceId: observable,
      toBeRemovedWeightClassSetVesselServiceId: observable,
      isVesselServiceDialogOpen: observable,
      isDeleteConfirmationDialogOpen: observable,
      weightClassSetsControl: observable,
      editingVesselServiceId: observable,
      selectedWeightClassSetMode: observable,
      errors: observable,

      setSelectedWeightClassSetMode: action,
      handleVesselServiceSubmit: action,
      toggleVesselServiceDialog: action,
      toggleDeleteConfirmationDialog: action,
      setSelectedWeightClassSetVesselServiceId: action,
      setToBeRemovedWeightClassSetVesselServiceId: action,
      setEditingVesselServiceId: action,
      refresh: action,
      validateWeightClasses: action,

      notInUseVesselServices: computed,
      vesselServices: computed,
      weightClassSets: computed,
      allWeightClassSets: computed,
      groupedWeightClassSets: computed,
      selectedWeightClassSet: computed,
      hasErrors: computed,
    })
  }

  get selectedWeightClassSet() {
    return this.groupedWeightClassSets.find(
      set => set.vesselServiceId === this.selectedWeightClassSetVesselServiceId,
    )
  }

  get allWeightClassSets() {
    return this.weightClassSetsControl.created.concat(this.weightClassSetsControl.updated)
  }

  get groupedWeightClassSets() {
    // Grouping sets by vessel service
    const grouped = this.allWeightClassSets.reduce<WeightClassSetByVesselService[]>((acc, set) => {
      const existing = acc.find(a => a.vesselServiceId === set.vesselServiceId)

      if (existing) {
        existing.weightClassSets.push(set)
      } else {
        acc.push({ vesselServiceId: set.vesselServiceId, name: set.name, weightClassSets: [set] })
      }

      return acc
    }, [])

    // Sorting sets by name
    grouped.sort((a, b) => {
      if (a.name === 'Generic') return -1
      else if (b.name === 'Generic') return 1
      else return a.name.localeCompare(b.name)
    })

    // Sorting classes by container lengths
    grouped.forEach(group => {
      group.weightClassSets.sort((a, b) => {
        if (a.containerLength === undefined) return -1
        else if (b.containerLength === undefined) return 1
        else return (a.containerLength ?? 0) - (b.containerLength ?? 0)
      })
    })

    return grouped
  }

  get weightClassSets() {
    return this._weightClassSetStore.entries
  }

  get vesselServices() {
    return this._vesselServiceStore.entries
  }

  get notInUseVesselServices() {
    const usedVesselServiceIds = new Set(this.allWeightClassSets.map(wcs => wcs.vesselServiceId))
    return this.vesselServices.filter(vs => !usedVesselServiceIds.has(vs.id))
  }

  get hasErrors() {
    return this.errors.size > 0
  }

  async refresh() {
    await this.loadVesselServices()
    await this.loadWeightClassSets()

    this.initializeWeightClassSetsControl()
  }

  async loadWeightClassSets() {
    await this._weightClassSetStore.loadAll()
  }

  async loadVesselServices() {
    await this._vesselServiceStore.loadAll()
  }

  async upsertWeightClassSets(data: WeightClassesUpsertWeightClassSetsControl) {
    await this._weightClassSetStore
      .upsert({
        weightClassesUpsertWeightClassSetsRequest: data,
      })
      .then(() => {
        this.refresh()
        this._snackbarStore.showMessage(
          tolgee.t('weightClassesUpdateSuccess', 'The weight classes are successfully updated'),
          'success',
        )
      })
  }

  setSelectedWeightClassSetMode(mode: number) {
    this.selectedWeightClassSetMode = mode
  }

  setToBeRemovedWeightClassSetVesselServiceId(id?: number | null) {
    this.toBeRemovedWeightClassSetVesselServiceId = id
  }

  setSelectedWeightClassSetVesselServiceId(id?: number | null) {
    this.selectedWeightClassSetVesselServiceId = id
  }

  upsertWeightClassSetsControl(weightClassSet: WeightClassSetCreatedControlDto) {
    const updatedIndex = this.weightClassSetsControl.updated.findIndex(
      set =>
        set.vesselServiceId === weightClassSet.vesselServiceId &&
        set.containerLength === weightClassSet.containerLength,
    )

    const createdIndex = this.weightClassSetsControl.created.findIndex(
      set =>
        set.vesselServiceId === weightClassSet.vesselServiceId &&
        set.containerLength === weightClassSet.containerLength,
    )

    if (updatedIndex !== -1) {
      this.weightClassSetsControl.updated[updatedIndex] = {
        ...weightClassSet,
        id: weightClassSet.id!,
      }
    } else if (createdIndex !== -1) {
      this.weightClassSetsControl.created[createdIndex] = weightClassSet
    } else {
      this.weightClassSetsControl.created.push(weightClassSet)
    }
  }

  setEditingVesselServiceId = (set?: number | null) => (this.editingVesselServiceId = set)

  toggleDeleteConfirmationDialog() {
    this.isDeleteConfirmationDialogOpen = !this.isDeleteConfirmationDialogOpen
  }

  toggleVesselServiceDialog() {
    this.isVesselServiceDialogOpen = !this.isVesselServiceDialogOpen
  }

  tryToggleVesselServiceDialog() {
    if (this.notInUseVesselServices.length > 0) {
      this.toggleVesselServiceDialog()
    } else {
      this._snackbarStore.showMessage(
        tolgee.t(
          'noVesselServicesAvailable',
          'There are no vessel services available to create a new weight class set',
        ),
        'info',
      )
    }
  }

  initializeWeightClassSetsControl() {
    this.weightClassSetsControl = {
      created: [],
      updated: [],
      deletedIds: [],
    }

    if (this.weightClassSets.length === 0) {
      this.upsertWeightClassSetsControl({
        id: undefined,
        name: 'Generic',
        vesselServiceId: undefined,
        containerLength: undefined,
        weightClasses: [],
        isActive: true,
      })
    } else {
      this.weightClassSets.forEach(wcs => {
        wcs.weightClasses = wcs.weightClasses.slice().sort((a, b) => a.minWeight - b.minWeight)
      })

      this.weightClassSetsControl.updated = this.weightClassSets
    }

    const weightClassSetsWithContainerLengths = this.weightClassSets.filter(
      wcs => wcs.containerLength !== undefined,
    )

    // Adding empty sets for missing container lengths
    weightClassSetsWithContainerLengths.forEach(wcs => {
      const { vesselServiceId, containerLength } = wcs

      if (!vesselServiceId || !containerLength) return

      const otherLength = containerLength === 20 ? 40 : 20

      const otherLengthSet = this.weightClassSets.find(
        wcs => wcs.vesselServiceId === vesselServiceId && wcs.containerLength === otherLength,
      )

      if (!otherLengthSet) {
        this.upsertWeightClassSetsControl({
          id: undefined,
          name: wcs.name,
          vesselServiceId,
          containerLength: otherLength,
          isActive: true,
          weightClasses: [],
        })
      }
    })

    // Adding empty weight class to empty sets
    this.weightClassSetsControl.created.forEach(set => this.addDefaultWeightClass(set))
    this.weightClassSetsControl.updated.forEach(set => this.addDefaultWeightClass(set))

    this.selectedWeightClassSetVesselServiceId =
      this.selectedWeightClassSetVesselServiceId ??
      (this.groupedWeightClassSets.length > 0
        ? this.groupedWeightClassSets[0].vesselServiceId
        : null)

    const mode = this.groupedWeightClassSets
      .find(set => set.vesselServiceId === this.selectedWeightClassSetVesselServiceId)
      ?.weightClassSets.find(ws => ws.isActive)?.containerLength
      ? 1
      : 0

    this.setSelectedWeightClassSetMode(mode)
  }

  separateByContainerSize(vesselServiceId?: number | null) {
    let hasSeparateSets = false
    const vesselService = this.vesselServices.find(vs => vs.id === vesselServiceId)

    const sharedUpdateSet = this.weightClassSetsControl.updated.find(
      wcs => wcs.vesselServiceId === vesselServiceId && !wcs.containerLength,
    )

    if (sharedUpdateSet) {
      sharedUpdateSet.isActive = false
    }

    const separatedUpdateSets = this.weightClassSetsControl.updated.filter(
      wcs => wcs.vesselServiceId === vesselServiceId && wcs.containerLength,
    )

    if (separatedUpdateSets?.length > 0) {
      separatedUpdateSets.forEach(set => (set.isActive = true))
      hasSeparateSets = true
    }

    const sharedCreatedSet = this.weightClassSetsControl.created.find(
      wcs => wcs.vesselServiceId === vesselServiceId && !wcs.containerLength,
    )

    if (sharedCreatedSet) {
      sharedCreatedSet.isActive = false
    }

    const separatedCreatedSets = this.weightClassSetsControl.created.filter(
      wcs => wcs.vesselServiceId === vesselServiceId && wcs.containerLength,
    )

    if (separatedCreatedSets?.length > 0) {
      separatedCreatedSets.forEach(set => (set.isActive = true))
      hasSeparateSets = true
    }

    if (!hasSeparateSets) {
      this.upsertWeightClassSetsControl({
        id: undefined,
        name: vesselService?.name ?? 'Generic',
        vesselServiceId,
        containerLength: 20,
        isActive: true,
        weightClasses: [{ ...defaultWeightClass, id: uuidv4() }],
      })

      this.upsertWeightClassSetsControl({
        id: undefined,
        name: vesselService?.name ?? 'Generic',
        vesselServiceId,
        containerLength: 40,
        isActive: true,
        weightClasses: [{ ...defaultWeightClass, id: uuidv4() }],
      })
    }
  }

  shareByContainerSize(vesselServiceId?: number | null) {
    let hasSharedSet = false
    const vesselService = this.vesselServices.find(vs => vs.id === vesselServiceId)

    const sharedUpdateSets = this.weightClassSetsControl.updated.filter(
      wcs => wcs.vesselServiceId === vesselServiceId && wcs.containerLength,
    )

    if (sharedUpdateSets?.length > 0) {
      sharedUpdateSets.forEach(set => (set.isActive = false))
    }

    const sharedUpdateSet = this.weightClassSetsControl.updated.find(
      wcs => wcs.vesselServiceId === vesselServiceId && !wcs.containerLength,
    )

    if (sharedUpdateSet) {
      sharedUpdateSet.isActive = true
      hasSharedSet = true
    }

    const sharedCreatedSets = this.weightClassSetsControl.created.filter(
      wcs => wcs.vesselServiceId === vesselServiceId && wcs.containerLength,
    )

    if (sharedCreatedSets?.length > 0) {
      sharedCreatedSets.forEach(set => (set.isActive = false))
    }

    const sharedCreatedSet = this.weightClassSetsControl.created.find(
      wcs => wcs.vesselServiceId === vesselServiceId && !wcs.containerLength,
    )

    if (sharedCreatedSet) {
      sharedCreatedSet.isActive = true
      hasSharedSet = true
    }

    if (!hasSharedSet) {
      this.upsertWeightClassSetsControl({
        id: undefined,
        name: vesselService?.name ?? 'Generic',
        vesselServiceId,
        containerLength: undefined,
        isActive: true,
        weightClasses: [{ ...defaultWeightClass, id: uuidv4() }],
      })
    }
  }

  removeWeightClassSet(vesselServiceId?: number | null) {
    const updateSets = this.weightClassSetsControl.updated.filter(
      wcs => wcs.vesselServiceId === vesselServiceId,
    )

    if (updateSets && updateSets.length > 0) {
      updateSets.forEach(set => {
        if (set.id) {
          this.weightClassSetsControl.deletedIds.push(set.id)
        }
      })

      this.weightClassSetsControl.updated = this.weightClassSetsControl.updated.filter(
        wcs => wcs.vesselServiceId !== vesselServiceId,
      )
    }

    const createdSets = this.weightClassSetsControl.created.filter(
      wcs => wcs.vesselServiceId === vesselServiceId,
    )

    if (createdSets && createdSets.length > 0) {
      this.weightClassSetsControl.created = this.weightClassSetsControl.created.filter(
        wcs => wcs.vesselServiceId !== vesselServiceId,
      )
    }
  }

  addWeightClassToWeightClassSetControl(
    vesselServiceId?: number | null,
    containerLength?: number | null,
  ) {
    const updateSet = this.weightClassSetsControl.updated.find(
      wcs => wcs.vesselServiceId === vesselServiceId && wcs.containerLength === containerLength,
    )

    if (updateSet) this.addWeightClass(updateSet)

    const createSet = this.weightClassSetsControl.created.find(
      wcs => wcs.vesselServiceId === vesselServiceId && wcs.containerLength === containerLength,
    )

    if (createSet) this.addWeightClass(createSet)
  }

  removeWeightClassFromWeightClassSetControl(weightClassId?: string | null) {
    const updateSet = this.weightClassSetsControl.updated.find(wcs =>
      wcs.weightClasses.some(wc => wc.id === weightClassId),
    )

    if (updateSet) {
      updateSet.weightClasses = updateSet.weightClasses.filter(wc => wc.id !== weightClassId)
    }

    const createdSet = this.weightClassSetsControl.created.find(wcs =>
      wcs.weightClasses.some(wc => wc.id === weightClassId),
    )

    if (createdSet) {
      createdSet.weightClasses = createdSet.weightClasses.filter(wc => wc.id !== weightClassId)
    }
  }

  handleVesselServiceSubmit = (formData: WeightClassSetVesselServiceFormData) => {
    if (this.editingVesselServiceId) {
      this.onEditWeightClassSet(formData)
    } else {
      this.onCreateWeightClassSet(formData)
    }

    this.toggleVesselServiceDialog()
    this.setSelectedWeightClassSetVesselServiceId(formData.vesselService.id)
  }

  onEditWeightClassSet = (formData: WeightClassSetVesselServiceFormData) => {
    const vesselService = this.vesselServices.find(vs => vs.id === formData.vesselService.id)

    if (!vesselService) return

    const updatedSets = this.weightClassSetsControl.updated.filter(
      wcs => wcs.vesselServiceId === this.editingVesselServiceId,
    )

    if (updatedSets && updatedSets.length > 0) {
      updatedSets.forEach(updatedSet => {
        updatedSet.name = vesselService.name
        updatedSet.vesselServiceId = vesselService.id
      })
    }

    const createdSets = this.weightClassSetsControl.created.filter(
      wcs => wcs.vesselServiceId === this.editingVesselServiceId,
    )

    if (createdSets && createdSets.length > 0) {
      createdSets.forEach(createdSet => {
        createdSet.name = vesselService.name
        createdSet.vesselServiceId = vesselService.id
      })
    }

    this.setEditingVesselServiceId(undefined)
  }

  onCreateWeightClassSet = (formData: WeightClassSetVesselServiceFormData) => {
    const vesselService = this.vesselServices.find(vs => vs.id === formData.vesselService.id)

    if (!vesselService) return

    this.upsertWeightClassSetsControl({
      id: undefined,
      name: vesselService.name,
      vesselServiceId: vesselService.id,
      containerLength: undefined,
      isActive: true,
      weightClasses: [{ ...defaultWeightClass, id: uuidv4() }],
    })
  }

  validateWeightClasses() {
    this.errors.clear()

    this.allWeightClassSets.forEach(set => {
      const nameCounts = new Map<string, number>()

      set.weightClasses.forEach(weightClass => {
        nameCounts.set(weightClass.name, (nameCounts.get(weightClass.name) || 0) + 1)
      })

      set.weightClasses.forEach(weightClass => {
        const hasNameError = nameCounts.get(weightClass.name)! > 1

        const hasWeightError =
          weightClass.maxWeight !== undefined && weightClass.maxWeight !== null
            ? weightClass.minWeight >= weightClass.maxWeight
            : false

        if (hasNameError || hasWeightError) {
          this.errors.set(weightClass.id!, {
            nameError: hasNameError,
            weightError: hasWeightError,
          })
        } else {
          this.errors.delete(weightClass.id!)
        }
      })
    })
  }

  private addDefaultWeightClass(set: WeightClassSetControlDto) {
    if (!set.weightClasses) {
      set.weightClasses = []
    }

    const isEmptyWeightClass = set.weightClasses.length === 0

    if (isEmptyWeightClass) {
      this.addWeightClass(set)
    }
  }
  private addWeightClass(set: WeightClassSetControlDto) {
    const lastWeightClass = set.weightClasses[set.weightClasses.length - 1]
    const lastMaxWeight = lastWeightClass
      ? lastWeightClass.maxWeight || lastWeightClass.minWeight
      : 0

    const newWeightClass = {
      id: uuidv4(),
      name: `New Class ${set.weightClasses.length + 1}`,
      minWeight: lastMaxWeight + 1,
    }

    set.weightClasses.push(newWeightClass)
  }
}
