import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { NonNumericOrderDto } from '@planning/app/api'
import { CarrierVisitDirectionChip } from '@planning/components/CarrierVisitDirectionChip'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
interface Props {
  order: NonNumericOrderDto
  onClick: () => void
}

export const NNROrderListCard = ({ order, onClick }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Card variant='outlined' sx={{ width: '100%', pb: 0, mt: 1 }} onClick={onClick}>
      <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Stack direction='row' spacing={'2px'}>
          <Stack sx={{ width: '100%' }}>
            <Box
              sx={{
                m: 1,
                p: 1,
                borderRadius: 1,
                border: 1,
                borderColor: '#eeeeee',
                bgcolor: theme.palette.grey[200],
              }}
              display='flex'
              flexDirection='column'
            >
              <Typography variant='h4'>{`${order?.referenceNumber}`}</Typography>
              <Typography variant='subtitle2'>{`${order.containerIsoCode} / ${
                order.containerLengths
              }${t('ft', 'ft')} / ${t('empty', 'Empty')}`}</Typography>
              <Typography variant='subtitle2'>{order?.shippingLine?.toUpperCase()}</Typography>
            </Box>
          </Stack>
          <Stack sx={{ m: 1, p: 1, width: '80px' }}>
            <Grid item xs={4} sx={{ p: 1 }}>
              <CarrierVisitDirectionChip direction={'OUT'} size='small' />
            </Grid>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
