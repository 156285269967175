import { Box } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { ContainerDto } from '@planning/app/api'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box pt={'1rem'} pb={'1rem'}>
          {children}
        </Box>
      )}
    </div>
  )
}

type Props = {
  container?: ContainerDto
  labels?: string[]
  children?: React.ReactNode[]
}

export const CreateOrderFormCargoTabs: FC<Props> = observer(({ labels, children }) => {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {labels?.map((label, index) => (
            <Tab
              label={label}
              key={index}
              id={`tab-${index}`}
              aria-controls={`tabpanel-${index}`}
              data-cy={`${label.toLowerCase()}-transport-tabpanel`}
            />
          ))}
        </Tabs>
      </Box>
      {children?.map((child, index) => {
        return (
          <CustomTabPanel value={value} index={index} key={index}>
            {child}
          </CustomTabPanel>
        )
      })}
    </Box>
  )
})
