import { OrderResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { HandleOrderPageLocationState } from '@planning/pages/Order'
import {
  getCheckinContainerPagePath,
  getCheckoutContainerPagePath,
  getVisitOrderPagePath,
  getVisitOrderPageReturnPath,
} from '@planning/utils/visit-utils'
import { useTranslate } from '@tolgee/react'
import { useNavigate } from 'react-router'

export const useOrderNavigation = () => {
  const navigate = useNavigate()
  const { t } = useTranslate()
  const { appViewStore } = usePlanningStore()

  const navigateOrderPage = async (order: OrderResponseDto) => {
    if (!order.carrierVisitType || !order.carrierVisitId) {
      appViewStore.setShowAlert('error', t('failedToEditOrder', 'Failed to edit order'))
      return
    }

    const { carrierVisitType, carrierVisitId, direction, id: orderId } = order

    const orderPath = getVisitOrderPagePath(carrierVisitType, carrierVisitId, orderId)
    const returnPath = getVisitOrderPageReturnPath(carrierVisitType, direction, carrierVisitId)

    const state: HandleOrderPageLocationState = {
      returnPath,
    }

    navigate(orderPath, { state })
  }

  const navigateBookingOrderPage = async (order: OrderResponseDto) => {
    const state: HandleOrderPageLocationState = {
      returnPath: `/bookings/${order.referenceNumber}/visit/${order.carrierVisitId}/orders`,
    }

    navigate(
      `/bookings/${order.referenceNumber}/visit/${order.carrierVisitId}/orders/${order.id}`,
      { state },
    )
  }

  const navigateCheckoutContainerPage = async (order: OrderResponseDto) => {
    if (!order.carrierVisitType || !order.carrierVisitId) {
      appViewStore.setShowAlert('error', t('failedToCheckout', 'Failed to access checkout order'))
      return
    }

    const { carrierVisitType, carrierVisitId, direction, id: orderId } = order

    const checkoutPath = getCheckoutContainerPagePath(carrierVisitType, carrierVisitId, orderId)
    const returnPath = getVisitOrderPageReturnPath(carrierVisitType, direction, carrierVisitId)

    const state: HandleOrderPageLocationState = {
      returnPath,
    }

    navigate(checkoutPath, { state })
  }

  const navigateCheckoutContainerVisitsPage = async (order: OrderResponseDto) => {
    const state: HandleOrderPageLocationState = {
      returnPath: `/container-visits/`,
    }

    navigate(`/container-visits/${order.carrierVisitId ?? 0}/checkout/${order.id}`, { state })
  }

  const navigateCheckinContainerVisitsPage = async (order: OrderResponseDto) => {
    const state: HandleOrderPageLocationState = {
      returnPath: `/container-visits/`,
    }

    navigate(`/container-visits/${order.carrierVisitId ?? 0}/checkin/${order.id}`, { state })
  }

  const navigateCheckinContainerPage = async (order: OrderResponseDto) => {
    if (!order.carrierVisitType || !order.carrierVisitId) {
      appViewStore.setShowAlert('error', t('failedToEditOrder', 'Failed to access checkin order'))
      return
    }

    const { carrierVisitType, carrierVisitId, direction, id: orderId } = order

    const checkinPath = getCheckinContainerPagePath(carrierVisitType, carrierVisitId, orderId)
    const returnPath = getVisitOrderPageReturnPath(carrierVisitType, direction, carrierVisitId)

    const state: HandleOrderPageLocationState = {
      returnPath,
    }

    navigate(checkinPath, { state })
  }

  const openEditOrder = async (order: OrderResponseDto) => {
    navigateOrderPage(order)
  }

  return {
    openEditOrder,
    navigateBookingOrderPage,
    navigateCheckinContainerPage,
    navigateCheckoutContainerPage,
    navigateCheckoutContainerVisitsPage,
    navigateCheckinContainerVisitsPage,
  }
}
