import { LocationCodesApi, UpdateGlobalLocationCodesCommand } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class LocationCodesService {
  httpClient = createApiClient(LocationCodesApi)

  async getAll() {
    const { data } = await this.httpClient.getAll()
    return data
  }

  async uploadGlobalCodes(cmd: UpdateGlobalLocationCodesCommand) {
    const { data } = await this.httpClient.refreshGlobal(cmd)
    return data
  }
}

const locationCodesService = new LocationCodesService()

export default locationCodesService
