import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, Header, useMinimalsTheme } from '@tom-ui/ui'
import { useHistory } from '@tom-ui/utils'

import { Box, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { ExternalDriverForm } from './Components/Molecules/ExternalDriverForm'
import { useExternalDriverMessagesHandler } from './Hooks/useExternalDriverMessagesHandler'
import { ExternalDriverFormData } from './Stores/ExternalDriverViewStore'

export const ExternalDriverFormPage = observer(() => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const history = useHistory()
  const theme = useMinimalsTheme()

  const { appViewStore, externalDriverViewStore } = usePlanningStore()
  const { getExceptionMessage, getSuccessMessage } = useExternalDriverMessagesHandler()

  const title = externalDriverViewStore.dataToBeEdited
    ? t('editDriver', 'Edit Driver')
    : t('addDriver', 'Add Driver')

  const breadCrumbs = [
    {
      label: t('externalDrivers', 'External Drivers'),
      onClick: () => {
        navigate('/registers/external-drivers')
        externalDriverViewStore.setDataToBeEdited()
      },
    },
    {
      label: title,
    },
  ]

  const onSubmit = async (data: ExternalDriverFormData) => {
    const isEditMode = !!externalDriverViewStore.dataToBeEdited

    try {
      await externalDriverViewStore.onSubmit(data)
      history.goBackHistoryOrDefault('/registers/external-drivers')
      appViewStore.setShowAlert('success', getSuccessMessage(isEditMode, data.name))
      externalDriverViewStore.setDataToBeEdited()
    } catch (error: any) {
      appViewStore.setShowAlert('error', getExceptionMessage(error))
    }
  }

  return (
    <>
      <Breadcrumbs items={breadCrumbs} />
      <Header
        title={title}
        rightRenderOption={
          <>
            <Button
              size='large'
              color='inherit'
              onClick={() => {
                history.goBackHistoryOrDefault('/registers/external-drivers')
                externalDriverViewStore.setDataToBeEdited()
              }}
              variant='text'
            >
              {t('cancel', 'Cancel')}
            </Button>
            <Button
              type='submit'
              form='external-driver-form'
              size='large'
              color='primary'
              variant='contained'
            >
              {t('submit', 'Sumbit')}
            </Button>
          </>
        }
      />
      <Box paddingX={theme.customSpacing.l}>
        <ExternalDriverForm
          defaultValues={externalDriverViewStore.dataToBeEdited}
          externalDrivers={externalDriverViewStore.allItems}
          onSubmit={onSubmit}
        />
      </Box>
    </>
  )
})
