import { AxiosError } from 'axios'
import { ErrorCode } from './error-code-enum'

interface ErrorDescription {
  propertyName?: string
  code: string
  payload: any
  message?: string
}

export const hasErrorDescription = (error: AxiosError, errorCode: ErrorCode): boolean => {
  if (error.response?.status !== 400) {
    return false
  }
  const errorResponseData = error.response?.data as any
  const errorDescriptions = errorResponseData?.errors as ErrorDescription[]

  return errorDescriptions.some(x => x.code === errorCode)
}

export const getErrorDescriptionPayload = (error: AxiosError, errorCode: ErrorCode) => {
  if (hasErrorDescription(error, errorCode)) {
    const errorResponseData = error.response?.data as any
    const errorDescriptions = errorResponseData?.errors as ErrorDescription[]
    return errorDescriptions.find(x => x.code === errorCode)?.payload
  }
}

export const getAllErrorDescriptions = (error: AxiosError) => {
  if (error.response?.status !== 400) {
    return []
  }

  const errorResponseData = error.response?.data as any
  return errorResponseData?.errors as ErrorDescription[]
}
