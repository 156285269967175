import { Card, CardActionArea, CardHeader, Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { useMinimalsTheme } from '../../../hooks'
import { ColorSchema } from '../../palette'
import { ColoredLabel } from '../ColoredLabel/ColoredLabel'
import { HighlightedText } from '../highlightedText/HighlightedText'
import { CardAttributes, CardAttributesProps } from './CardAttributes'

interface Props {
  title: string
  attributes: CardAttributesProps[]
  chipLabel?: string
  chipColor?: ColorSchema
  children?: ReactNode
  highlightedText?: string
  highlightAttributes?: boolean
  onClick?: () => void
}

export const CardWithAttributes = ({
  title,
  attributes,
  chipLabel,
  chipColor,
  highlightedText,
  highlightAttributes = false,
  onClick,
}: Props) => {
  const theme = useMinimalsTheme()

  return (
    <CardActionArea
      sx={{
        '.MuiCardActionArea-focusHighlight': {
          background: 'transparent',
        },
        mb: theme.customSpacing.m,
      }}
      onClick={onClick}
    >
      <Card>
        <CardHeader
          title={
            <Stack flexDirection='row' justifyContent='space-between'>
              <Typography variant='h2'>
                <HighlightedText highlight={highlightedText} color={theme.palette.info.main}>
                  {title}
                </HighlightedText>
              </Typography>
              {chipLabel && <ColoredLabel label={chipLabel} color={chipColor ?? 'info'} />}
            </Stack>
          }
          sx={{ paddingBottom: theme => theme.customSpacing.m }}
        />

        <CardAttributes
          attributes={attributes}
          highlightedText={highlightAttributes ? highlightedText : undefined}
        />
      </Card>
    </CardActionArea>
  )
}
