import {
  ContainerDamageLocationDto,
  CreateContainerDamageLocationCommand,
  UpdateContainerDamageLocationCommand,
} from '@admin/app/api'
import { ContainerDamageLocationFormProfile } from './container-damage-location-form.profile'

const mapFormValuesToCreateDto = ({
  name,
  code,
}: ContainerDamageLocationFormProfile): CreateContainerDamageLocationCommand => ({
  name,
  code,
})

const mapFormValuesToUpdateDto = ({
  id,
  name,
  code,
}: ContainerDamageLocationFormProfile): UpdateContainerDamageLocationCommand => ({
  id: id!,
  name,
  code,
})

const mapDtoToFormValues = ({
  id,
  name,
  code,
}: ContainerDamageLocationDto): ContainerDamageLocationFormProfile => ({
  id,
  name,
  code,
})

export const ContainerDamageLocationFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
