import { ContainerDto, ContainerType } from '@planning/app/api'
import { containerService } from '@planning/services'
import { SearchAutocompleteStore } from '@planning/stores/SearchAutocompleteStore'
import { action, makeObservable, observable } from 'mobx'

const fetch = async (
  filter: string,
  isEmpty?: boolean,
  hasServiceOrders?: boolean,
  canAssignServiceOrders?: boolean,
  type?: ContainerType,
  isOnTerminal?: boolean,
  isoCode?: string,
  operator?: string,
) =>
  await containerService.getContainersByPartialNumber(
    filter,
    isEmpty,
    hasServiceOrders,
    canAssignServiceOrders,
    type,
    isoCode,
    isOnTerminal,
    operator,
  )

export class ContainerAutocompleteStore extends SearchAutocompleteStore<ContainerDto> {
  isEmpty?: boolean
  isOnTerminal?: boolean
  isoCode?: string
  operator?: string
  hasServiceOrders?: boolean
  canAssignServiceOrders?: boolean
  type?: ContainerType
  filteredContainerNumbers: string[] = []

  constructor() {
    super(
      (query: string) =>
        fetch(
          query,
          this.isEmpty,
          this.hasServiceOrders,
          this.canAssignServiceOrders,
          this.type,
          this.isOnTerminal,
          this.isoCode,
          this.operator,
        ),
      container =>
        !!container.id && !this.filteredContainerNumbers.includes(container.number ?? ''),
    )
    makeObservable(this, {
      isEmpty: observable,
      isOnTerminal: observable,
      isoCode: observable,
      operator: observable,
      hasServiceOrders: observable,
      canAssignServiceOrders: observable,
      type: observable,
      filteredContainerNumbers: observable,
      setIsEmpty: action,
      setHasServiceOrders: action,
      setCanAssignServiceOrders: action,
      setType: action,
      setIsoCode: action,
      setIsOnTerminal: action,
      setOperator: action,
      setFilteredContainerNumbers: action,
      reset: action,
    })
  }

  setType = (type?: ContainerType) => (this.type = type)
  setIsEmpty = (isEmpty?: boolean) => (this.isEmpty = isEmpty)
  setHasServiceOrders = (hasServiceOrders?: boolean) => (this.hasServiceOrders = hasServiceOrders)
  setCanAssignServiceOrders = (canAssingServiceOrders?: boolean) =>
    (this.canAssignServiceOrders = canAssingServiceOrders)
  setIsoCode = (isoCode?: string) => (this.isoCode = isoCode)
  setIsOnTerminal = (isOnTerminal?: boolean) => (this.isOnTerminal = isOnTerminal)
  setOperator = (operator?: string) => (this.operator = operator)
  setFilteredContainerNumbers = (filteredContainerNumbers: string[]) =>
    (this.filteredContainerNumbers = filteredContainerNumbers)

  reset = () => {
    this.setFilter('')
    this.setValue()
    this.items = []
    this.setIsEmpty()
    this.setHasServiceOrders()
    this.setCanAssignServiceOrders()
    this.setType()
    this.setIsoCode()
    this.setIsOnTerminal()
    this.setOperator()
    this.setFilteredContainerNumbers([])
  }
}
