import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection, OrderStatus } from '@planning/app/api'
import { ContainerHistoryList } from '@planning/components/ContainerHistoryList'
import { ContainerJourneyStatus } from '@planning/components/molecules/ContainerJourneyStatusChip'
import { useOrderNavigation } from '@planning/hooks/useOrderNavigation'
import { IOrderItem, OrderItem } from '@planning/rt-stores/order/OrderItem'
import adminActionService from '@planning/services/adminActionService'
import { OrderGroupingStore } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { Group, Grouping, GroupingContextAction, Item } from '@tom-ui/ui'
import { authStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useState } from 'react'
import { Id } from 'react-beautiful-dnd'
import { ContainerYardOperationViewStore, OpsIssue } from '../OrderGrouping'
import { OrderGroupListItem } from './OrderGroupListItem'

interface Props {
  store: OrderGroupingStore
  containerYardOperationViewStore: ContainerYardOperationViewStore
  disableSequencing?: boolean
  opsIssues?: OpsIssue[]
}

// Hooks up grouping component with grouping store
export const ObserverGrouping: FC<Props> = observer(
  ({ store: groupingStore, containerYardOperationViewStore, disableSequencing, opsIssues }) => {
    const { t } = useTranslate()
    const {
      appViewStore,
      dialogStore,
      containerEventViewStore,
      containerEventItemStore,
      orderUpdateItemStore,
    } = usePlanningStore()

    const orderAdminContextMenuFf = useBooleanFlagValue('order-admin-context-menu', false)

    const [refreshKey, setRefreshKey] = useState(0)
    const [adminActions, setAdminActions] = useState<GroupingContextAction[]>([])

    const { openEditOrder, navigateCheckinContainerPage, navigateCheckoutContainerPage } =
      useOrderNavigation()

    const onDeleteOrder = useCallback(
      async (items: IOrderItem[]) => {
        const count = items.length
        const confirmationMessageKey =
          count === 1 ? 'orderWillBeDeletedContinue' : 'ordersWillBeDeletedContinue'
        const confirmationMessage =
          count === 1
            ? 'Order will be deleted. Continue?'
            : `The selected ${count} Orders will be deleted. Continue?`

        const hasServiceOrders = items.some(item => item.data.hasServiceOrders)

        const isConfirmed = await appViewStore.setOpenConfirmDialog(
          true,
          t(confirmationMessageKey, confirmationMessage),
          t('confirmOrderDelete', 'Confirm order delete'),
          hasServiceOrders
            ? t(
                'anyAssociatedStrippingOrStuffingOrdersWillBeDeleted',
                'Any associated stripping/stuffing orders will be deleted',
              )
            : undefined,
        )

        if (!isConfirmed) return

        try {
          await adminActionService.deleteByIds(items.map(item => item.id))
          appViewStore.setShowAlert(
            'success',
            t('successfullyDeletedOrder', 'Successfully deleted order'),
          )
        } catch (e) {
          appViewStore.setShowAlert('error', t('failedToDeleteOrder', 'Failed to delete order'))
        }
      },
      [appViewStore, t],
    )

    const onOrderHistory = useCallback(
      async (containerNumber?: string | null) => {
        containerEventItemStore.setContainerNumber(containerNumber!)
        await containerEventItemStore.fetch()

        dialogStore.openDialog(
          <ContainerHistoryList
            containerNumber={containerNumber}
            store={containerEventViewStore}
          />,
        )
      },
      [containerEventItemStore, containerEventViewStore, dialogStore],
    )

    const getSelectedEntries = useCallback(
      (selectedItemIds: Id[]) => {
        const entries = Object.entries(groupingStore.entities.items).filter(i =>
          selectedItemIds.includes(i[0]),
        )

        if (entries.length === 0) return []

        return entries
      },
      [groupingStore.entities.items],
    )

    const getOrderProperty = useCallback(
      (selectedItemIds: Id[], prop: string) => {
        return getSelectedEntries(selectedItemIds).map(i => i[1].content.data[prop])
      },
      [getSelectedEntries],
    )

    const getOrderStatus = useCallback(
      (selectedItemIds: Id[]) => {
        if (selectedItemIds.length !== 1) return

        const selectedItem = getSelectedEntries(selectedItemIds)?.[0]
        if (!selectedItem) return

        const orderItem: OrderItem = selectedItem[1].content
        const fallbackStatus = (() => {
          if (orderItem.data.status === OrderStatus.Fulfilled) {
            return orderItem.data.closedAt != null
              ? ContainerJourneyStatus.Departed
              : ContainerJourneyStatus.Arrived
          }
          return ContainerJourneyStatus.Expected
        })()

        const status = orderItem.containerJourney?.status ?? fallbackStatus

        return status
      },
      [getSelectedEntries],
    )

    const groupingActionsIndex = 2 // Index of grouping actions menu item in the actions list

    const getAdminActions = useCallback((): GroupingContextAction[] => {
      const deleteAction = {
        label: () => t('delete', 'Delete'),
        isActionDisabled: (selectedItemIds: Id[]) => selectedItemIds.length === 0,
        onClick: (selectedItemIds: Id[]) => {
          const entries = getSelectedEntries(selectedItemIds)

          onDeleteOrder(entries.map(i => i[1].content))
        },
      }

      let adminActions: GroupingContextAction[] = []

      //TODO Refactor when feature flag is disabled
      if (!orderAdminContextMenuFf) {
        adminActions = [deleteAction]
      }

      const isAdmin = authStore.isAdmin()
      if (isAdmin && orderAdminContextMenuFf) {
        adminActions = [
          {
            label: () => t('admin', 'Admin'),
            children: () => [
              {
                label: () => t('checkInContainer', 'Check in container'),
                isActionDisabled: (selectedItemIds: Id[]) => {
                  if (selectedItemIds.length !== 1) return true

                  const status = getOrderStatus(selectedItemIds)
                  const direction = getOrderProperty(selectedItemIds, 'direction')

                  return (
                    !status ||
                    !direction ||
                    (status !== ContainerJourneyStatus.Expected &&
                      status !== ContainerJourneyStatus.Arrived) ||
                    direction[0] === CarrierVisitDirection.Outbound
                  )
                },
                onClick: (selectedItemIds: Id[]) => {
                  if (selectedItemIds.length !== 1) return

                  const selectedItem = groupingStore.entities.items[selectedItemIds[0]]
                  navigateCheckinContainerPage(selectedItem.content.data)
                },
              },
              {
                label: () => t('undoCheckin', 'Undo check in'),
                isActionDisabled: (selectedItemIds: Id[]) => {
                  if (selectedItemIds.length !== 1) return true

                  const status = getOrderStatus(selectedItemIds)
                  const direction = getOrderProperty(selectedItemIds, 'direction')

                  return (
                    !status ||
                    !direction ||
                    status === ContainerJourneyStatus.Expected ||
                    status === ContainerJourneyStatus.Arrived ||
                    status === ContainerJourneyStatus.Departed ||
                    direction[0] === CarrierVisitDirection.Outbound
                  )
                },
                onClick: async (selectedItemIds: Id[]) => {
                  if (selectedItemIds.length !== 1) return

                  const selectedItem = groupingStore.entities.items[selectedItemIds[0]]
                  const confirmed = await appViewStore.setOpenConfirmDialog(
                    true,
                    t(
                      'containerWillBeResetToExpected',
                      'Container no. {container} will be reset to Expected status and any associated jobs will be terminated. This will afect billing.',
                      { container: selectedItem.content.data.containerNumber },
                    ),
                    t('undoCheckin', 'Undo check in'),
                  )

                  if (confirmed) {
                    await adminActionService.undoOrderCheckIn(selectedItem.content.data.id)
                    setRefreshKey(prevKey => prevKey + 1)
                  }
                },
              },
              {
                label: () => t('checkOutContainer', 'Check out container'),
                isActionDisabled: (selectedItemIds: Id[]) => {
                  const status = getOrderStatus(selectedItemIds)
                  const direction = getOrderProperty(selectedItemIds, 'direction')

                  return (
                    !status ||
                    !direction ||
                    status === ContainerJourneyStatus.Expected ||
                    status === ContainerJourneyStatus.Arrived ||
                    status === ContainerJourneyStatus.Departed ||
                    direction[0] === CarrierVisitDirection.Inbound
                  )
                },
                onClick: (selectedItemIds: Id[]) => {
                  if (selectedItemIds.length !== 1) return
                  const selectedItem = groupingStore.entities.items[selectedItemIds[0]]
                  navigateCheckoutContainerPage(selectedItem.content.data)
                },
              },
              {
                label: () => t('undoCheckout', 'Undo check out'),
                isActionDisabled: (selectedItemIds: Id[]) => {
                  if (selectedItemIds.length !== 1) return true

                  const status = getOrderStatus(selectedItemIds)
                  const direction = getOrderProperty(selectedItemIds, 'direction')

                  return (
                    !status ||
                    !direction ||
                    status !== ContainerJourneyStatus.Departed ||
                    direction[0] === CarrierVisitDirection.Inbound
                  )
                },
                onClick: async (selectedItemIds: Id[]) => {
                  const containerNumbers = getOrderProperty(selectedItemIds, 'containerNumber')
                  const orderIds = getOrderProperty(selectedItemIds, 'id')

                  if (containerNumbers.length === 1 && orderIds.length === 1) {
                    const orderId = orderIds[0]
                    const containerNumber = containerNumbers[0]

                    containerYardOperationViewStore!.open(
                      containerNumber,
                      orderId,
                      t('undoCheckout', 'Undo check out'),
                      t(
                        'containerWillBeResetToTerminal',
                        'Container no. {container} will be reset to On terminal status. This will afect billing.',
                        { container: containerNumber },
                      ),
                      async () => {
                        await adminActionService.undoOrderCheckOut(orderId)
                        setRefreshKey(prevKey => prevKey + 1)
                      },
                    )
                  }
                },
              },
              {
                label: () => t('correctYardLocation', 'Correct yard location'),
                isActionDisabled: (selectedItemIds: Id[]) => {
                  const status = getOrderStatus(selectedItemIds)
                  return !status || status !== ContainerJourneyStatus.OnYard
                },
                onClick: (selectedItemIds: Id[]) => {
                  const containerNumbers = getOrderProperty(selectedItemIds, 'containerNumber')
                  const orderIds = getOrderProperty(selectedItemIds, 'id')
                  if (containerNumbers.length === 1 && orderIds.length === 1) {
                    containerYardOperationViewStore.open(containerNumbers[0], orderIds[0])
                  }
                },
              },
              deleteAction,
            ],
          },
        ]
      }

      return adminActions
    }, [
      appViewStore,
      containerYardOperationViewStore,
      getOrderProperty,
      getOrderStatus,
      getSelectedEntries,
      groupingStore.entities.items,
      navigateCheckinContainerPage,
      navigateCheckoutContainerPage,
      onDeleteOrder,
      orderAdminContextMenuFf,
      t,
    ])

    const getActions = useCallback((): GroupingContextAction[] => {
      const actions: GroupingContextAction[] = [
        {
          label: () => t('viewDetails', 'View details'),
          subtitle: (selectedItemIds: Id[]) => {
            const updates = orderUpdateItemStore.unreadOrderUpdates.filter(update =>
              selectedItemIds.includes(update.orderId.toString()),
            ).length

            if (!updates) return null

            return `${updates} updates`
          },
          isActionDisabled: (selectedItemIds: Id[]) => selectedItemIds.length !== 1,
          onClick: (selectedItemIds: Id[]) => {
            if (selectedItemIds.length !== 1) return
            const selectedItem = groupingStore.entities.items[selectedItemIds[0]]
            openEditOrder(selectedItem.content.data)
          },
        },
        {
          label: () => t('history', 'History'),
          isActionDisabled: (selectedItemIds: Id[]) => selectedItemIds.length !== 1,
          onClick: (selectedItemIds: Id[]) => {
            if (selectedItemIds.length !== 1) return
            const selectedItem = groupingStore.entities.items[selectedItemIds[0]]
            onOrderHistory(selectedItem.content.data.containerNumber)
          },
        },
        {
          label: () => t('copyDetail', 'Copy detail'),
          isActionDisabled: (selectedItemIds: Id[]) => selectedItemIds.length === 0,
          children: () => [
            {
              label: () => t('containerNumber', 'Container Number'),
              onClick: (selectedItemIds: Id[]) => {
                const data = getOrderProperty(selectedItemIds, 'containerNumber')
                if (data) navigator.clipboard.writeText(data.join(', '))
              },
            },
            {
              label: () => t('referenceNumber', 'Reference Number'),
              onClick: (selectedItemIds: Id[]) => {
                const data = getOrderProperty(selectedItemIds, 'referenceNumber')
                if (data) navigator.clipboard.writeText(data.join(', '))
              },
            },
          ],
        },
      ]

      return [...actions, ...adminActions]
    }, [
      adminActions,
      getOrderProperty,
      groupingStore.entities.items,
      onOrderHistory,
      openEditOrder,
      orderUpdateItemStore.unreadOrderUpdates,
      t,
    ])

    const notifyMovedOrders = (sourceGroup: Group, destinationGroup: Group, orders: Item[]) => {
      const sourceGroupNames = sourceGroup.name
      const destinationGroupName = destinationGroup.name

      const itemName = orders
        .slice(0, 3)
        .map(o => o.content.containerNumber)
        .join(', ')

      appViewStore.setShowAlert(
        'success',
        `Orders ${itemName} moved from ${sourceGroupNames} to ${destinationGroupName}`,
      )
    }

    useEffect(() => {
      const newAdminActions = getAdminActions()
      setAdminActions(newAdminActions)
    }, [groupingStore.entities.items])

    return (
      <Grouping
        entities={groupingStore.entities}
        defaultGroupId={groupingStore.defaultGroupId}
        groupChangeFn={(entities, sourceGroup, destinationGroup, orders) => {
          groupingStore.saveChanges(entities)
          notifyMovedOrders(sourceGroup, destinationGroup, orders)
        }}
        ungroupFn={groupingStore.saveChanges}
        toggleSeqFn={groupingStore.saveChanges}
        updateGroupFn={groupingStore.saveChanges}
        sequenceChangeFn={groupingStore.saveChanges}
        actions={getActions()}
        genericFilter={{
          keys: ['referenceNumber', 'consignee', 'containerNumber'],
          value: groupingStore.filter ?? '',
        }}
        itemSize={60}
        allowSequence={!disableSequencing}
        allowGroupNotes={false}
        hasDefaultGroup={true}
        customGroupName={t('order', 'Order')}
        uniqueGroupNames
        groupingActionsIndex={groupingActionsIndex}
        filters={
          groupingStore.filterStatus
            ? [
                {
                  key: 'status',
                  value: groupingStore.filterStatus,
                },
              ]
            : []
        }
        renderData={(item: { id: Id; content: IOrderItem }, group) => (
          <OrderGroupListItem
            group={group}
            item={item}
            filter={groupingStore.filter}
            containerYardOperationViewStore={containerYardOperationViewStore}
            opsIssues={opsIssues}
          />
        )}
      />
    )
  },
)
