import {
  ContainerHeight,
  ContainerType,
  CreateArchetypeCommand,
  IsoCodeMappingResponseDto,
  UpdateArchetypeCommand,
} from '@admin/app/api'
import { ArchetypeStore } from '@admin/stores/Archetype.store'
import { action, computed, makeObservable, observable } from 'mobx'

export interface ArchetypeFormData {
  id?: number
  name: string
  isoCodes: string[]
}

export interface InUseIsoCodesData {
  archetypeId: number
  archetypeName: string
  isoCodes: string[]
}

export class ArchetypeControlUIStore {
  id?: number
  isDeleteDialogOpen = false

  currentAttributes: IsoCodeMappingResponseDto[] = []

  constructor(public archetypeStore: ArchetypeStore) {
    makeObservable(this, {
      isDeleteDialogOpen: observable,
      currentAttributes: observable,

      currentArchetypeLengths: computed,
      currentArchetypeHeights: computed,
      currentArchetypeTypes: computed,
      selectedArchetype: computed,
      inUseIsoCodes: computed,

      setId: action,
      setCurrentAttributes: action,
      onRemoveArchetypeDuplicates: action,
      onSubmit: action,
      toggleDeleteDialog: action,
    })
  }

  get selectedArchetype() {
    return this.archetypeStore.items.find(item => item.id === this.id)
  }

  get inUseIsoCodes(): InUseIsoCodesData[] {
    const inUseIsoCodes: InUseIsoCodesData[] = []

    this.archetypeStore.items.forEach(archetype =>
      archetype.isoCodes.forEach(code => {
        const inUseIsoCode = inUseIsoCodes.find(i => i.archetypeId === archetype.id)

        if (inUseIsoCode) {
          inUseIsoCode.archetypeName = `${inUseIsoCode.archetypeName}`
          inUseIsoCode.isoCodes.push(code)
        } else {
          inUseIsoCodes.push({
            archetypeId: archetype.id,
            archetypeName: archetype.name,
            isoCodes: [code],
          })
        }
      }),
    )

    return inUseIsoCodes
  }

  get currentArchetypeLengths() {
    const uniqueLengths: Set<number> = new Set()

    this.currentAttributes.forEach(attr => uniqueLengths.add(attr.length))

    return Array.from(uniqueLengths)
  }

  get currentArchetypeHeights() {
    const uniqueHeights: Set<ContainerHeight> = new Set()

    this.currentAttributes.forEach(attr => uniqueHeights.add(attr.height))

    return Array.from(uniqueHeights)
  }

  get currentArchetypeTypes() {
    const uniqueTypes: Set<ContainerType> = new Set()

    this.currentAttributes.forEach(attr => uniqueTypes.add(attr.type))

    return Array.from(uniqueTypes)
  }

  toggleDeleteDialog = (shouldOpen: boolean) => (this.isDeleteDialogOpen = shouldOpen)

  onDelete = async (id: number) => {
    await this.archetypeStore.delete(id)
  }

  setId = (id: number) => (this.id = id)

  setCurrentAttributes = (attributes: IsoCodeMappingResponseDto[]) =>
    (this.currentAttributes = attributes)

  onRemoveArchetypeDuplicates = async (
    archetypeId: number,
    archetypeName: string,
    isoCodes: string[],
  ) => {
    const cmd: UpdateArchetypeCommand = {
      id: archetypeId,
      name: archetypeName,
      isoCodes,
    }

    const returnId = await this.archetypeStore.update(cmd)

    this.archetypeStore.updateStoreItem(
      {
        id: archetypeId,
        name: archetypeName,
        isoCodes,
        // We don't need to compute this again, since we're fetching the data on the list page
        heights: [],
        lengths: [],
        types: [],
      },
      archetypeId,
    )

    return returnId
  }

  onSubmit = async (data: ArchetypeFormData) => {
    const isEditMode = !!data.id
    let returnId

    if (isEditMode) {
      const cmd: UpdateArchetypeCommand = {
        ...data,
        id: data.id!,
      }
      returnId = await this.archetypeStore.update(cmd)
    } else {
      const cmd: CreateArchetypeCommand = {
        ...data,
      }

      returnId = await this.archetypeStore.add(cmd)
    }

    return returnId
  }
}
