import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ContainerHeight, ContainerType, useAdminStore } from '@tom-ui/admin'
import { nnrOrderService } from '@tom-ui/planning'
import { ArchetypeResponseDto, ContainerCharacteristics } from '@tom-ui/planning/app/api'
import { ArchetypeIsoCodeCharacteristics } from '@tom-ui/planning/pages/Order/stores/IsoCodeMappingInputStore'
import { EditIcon, useContainerAttributesParameters, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { IsoCodeMappingFormDialog } from 'modules/admin/src/pages/IsoCodeMapping/components/IsoCodeMappingForm'
import { FC, useCallback, useState } from 'react'
import { Controller, FieldErrors, FieldValues } from 'react-hook-form'
import {
  getContainerCharacteristicsByIsoCode,
  IControlledIsoCodeMappingInputProps,
} from './ControlledIsoCodeMappingInput'

const IsoCodeNotFoundMessage: FC<{
  isoCodeInput: string
}> = observer(({ isoCodeInput }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { isoCodeMappingUIStore } = useAdminStore()

  return (
    <Grid item xs={8} mt={theme.customSpacing.xxs}>
      <Box
        bgcolor={theme.palette.grey[200]}
        p={1}
        borderRadius={theme.customRadius.medium}
        color={theme.palette.grey[600]}
      >
        <Grid container>
          <Grid
            item
            xs={10}
            display={'flex'}
            sx={{
              alignItems: 'center',
            }}
          >
            {t('couldNotFindDetailsForThisIsoCode', 'Could not find details for this ISO code.')}
          </Grid>
          <Grid item xs={2} display={'flex'} sx={{ placeContent: 'end' }}>
            <Button
              variant='text'
              color='inherit'
              onClick={() =>
                isoCodeMappingUIStore.toggleFormDialog(true, {
                  isoCode: isoCodeInput,
                  height: ContainerHeight.Standard,
                  type: ContainerType.General,
                })
              }
            >
              {t('addNewMapping', 'Add new mapping')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
})

interface ContainerCharacteristicsInfoData {
  id?: number
  archetype?: string | null
  length?: number | null
  height?: ContainerHeight | null
  type?: ContainerType | null

  displayLength?: string | null
  displayHeight?: string | null
  displayType?: string | null
}

const ContainerCharacteristicsInfo: FC<{
  isoCode: string
  containerCharacteristics: ContainerCharacteristicsInfoData
  showArchetype?: boolean
  allowEdit?: boolean
}> = observer(({ isoCode, containerCharacteristics, showArchetype = false, allowEdit = true }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { isoCodeMappingUIStore } = useAdminStore()

  return (
    <Box
      bgcolor={theme.palette.grey[200]}
      borderRadius={theme.customRadius.medium}
      color={theme.palette.grey[600]}
      height={'100%'}
      sx={{
        padding: `${theme.customSpacing.xxs} ${theme.customSpacing.s}`,
      }}
    >
      <Grid height={'100%'} container>
        <Grid height={'100%'} item xs={11}>
          <Grid height={'100%'} container columnSpacing={4}>
            {showArchetype && (
              <ContainerCharacteristicsAttribute
                label={t('archetype', 'Archetype')}
                value={containerCharacteristics.archetype ?? t('notAssigned', 'Not assigned')}
                sx={{ borderRight: `1px solid ${theme.palette.grey[400]}`, paddingRight: 4 }}
              />
            )}
            <ContainerCharacteristicsAttribute
              label={t('length', 'Length')}
              value={
                containerCharacteristics.displayLength
                  ? `${containerCharacteristics.displayLength}ft`
                  : t('unknown', 'Unknown')
              }
            />
            <ContainerCharacteristicsAttribute
              label={t('height', 'Height')}
              value={
                containerCharacteristics.displayHeight
                  ? containerCharacteristics.displayHeight
                  : t('unknown', 'Unknown')
              }
            />
            <ContainerCharacteristicsAttribute
              label={t('type', 'Type')}
              value={
                containerCharacteristics.displayType
                  ? containerCharacteristics.displayType
                  : t('unknown', 'Unknown')
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={1}
          display={'flex'}
          flexDirection={'column'}
          height={'100%'}
          sx={{ alignItems: 'end', placeContent: 'center' }}
        >
          {allowEdit && (
            <IconButton
              aria-label='edit'
              onClick={() =>
                isoCodeMappingUIStore.toggleFormDialog(true, {
                  id: containerCharacteristics.id ?? undefined,
                  isoCode: isoCode,
                  height: containerCharacteristics.height ?? ContainerHeight.Standard,
                  type: containerCharacteristics.type ?? ContainerType.General,
                  // length: containerCharacteristics.length ?? undefined,
                })
              }
            >
              <EditIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Box>
  )
})

interface AttributeProps {
  label: string
  value: string | number
  sx?: SxProps<Theme>
}

const ContainerCharacteristicsAttribute: FC<AttributeProps> = ({ label, value, sx }) => {
  return (
    <Grid
      item
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      sx={{ ...sx, alignItems: 'start', placeContent: 'center' }}
    >
      <Typography variant='body2'>{label}</Typography>
      <Typography variant='body2' fontWeight={'bold'}>
        {value}
      </Typography>
    </Grid>
  )
}

// [Review] TODO: Please don't create components using the react hook form interfaces. It ties our components to react hook forms.
export const ControlledIsoCodeMappingInputWithDetails = <T extends FieldValues>(
  props: IControlledIsoCodeMappingInputProps<T>,
) => {
  const { t } = useTranslate()

  const { isoCodeMappingUIStore } = useAdminStore()

  const { types: containerTypeParameters, heights: containerHeightParameters } =
    useContainerAttributesParameters()

  const {
    name,
    control,
    label,
    fullWidth,
    disabled,
    variant,
    type,
    formState,
    required,
    showArchetype = false,
    showArchetypeAttributes = false,
    allowEdit = false,
    inputSize = 4,
    setValue,
    watch,
    onChange,
  } = props
  const { errors } = formState
  const isoCodeInput = watch(name)

  const [isIsoCodeValid, setIsIsoCodeValid] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [containerCharacteristics, setContainerCharacteristics] = useState<
    ContainerCharacteristicsInfoData | undefined
  >()
  const theme = useMinimalsTheme()

  const containerIsoCodeErrorText = (errors: FieldErrors) => {
    const isoCodeError = errors.isoCode
    if (isoCodeError) {
      const errorType = isoCodeError.type
      if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
      if (errorType === 'minLength' || errorType === 'maxLength')
        return t(
          'isoCodeConsistsOf4AlphanumericCharacters',
          'ISO Codes consists of 4 alphanumeric characters.',
        )

      return isoCodeError.message?.toString()
    }
  }

  const fetchContainerCharacteristicsByIsoCode = useCallback(
    async (isoCode?: string) => {
      if (isoCode?.length === 4) {
        try {
          setIsLoading(true)

          const characteristics: ContainerCharacteristics =
            await getContainerCharacteristicsByIsoCode(isoCode)
          const isValid = !!characteristics
          const containerCharacteristicsInfoData: ContainerCharacteristicsInfoData = {
            id: characteristics.id ?? -1,
            archetype: characteristics.archetype,
            length: characteristics.length,
            displayLength: characteristics.length?.toString(),
            height: characteristics.height,
            displayHeight: characteristics.height
              ? containerHeightParameters[characteristics.height]?.label
              : characteristics.height,
            type: characteristics.type,
            displayType: characteristics.type
              ? containerTypeParameters[characteristics.type]?.label
              : characteristics.type,
          }
          const archetypeIsoCodeCharacteristics: ArchetypeIsoCodeCharacteristics = {
            archetype: {},
            isoCode: {},
          }

          if (showArchetypeAttributes && characteristics.archetype) {
            const matchingArchetypes: ArchetypeResponseDto[] =
              await nnrOrderService.getArchetypesByPartialCode(characteristics.archetype)

            const archetypeAttributes = matchingArchetypes.find(
              archetype => archetype.name === characteristics.archetype,
            )

            if (archetypeAttributes) {
              archetypeIsoCodeCharacteristics.archetype = archetypeAttributes
              archetypeIsoCodeCharacteristics.isoCode = characteristics

              containerCharacteristicsInfoData.displayLength =
                archetypeAttributes.lengths?.join(', ')
              containerCharacteristicsInfoData.displayHeight = archetypeAttributes.heights
                ?.map(h => containerHeightParameters[h]?.label ?? h)
                .join(', ')
              containerCharacteristicsInfoData.displayType = archetypeAttributes.types
                ?.map(t => containerTypeParameters[t]?.label ?? t)
                .join(', ')
            }
          }

          onChange?.(archetypeIsoCodeCharacteristics)
          setContainerCharacteristics(containerCharacteristicsInfoData)
          setIsIsoCodeValid(isValid)
        } catch (e) {
          setContainerCharacteristics(undefined)
          setIsIsoCodeValid(false)
        } finally {
          setIsLoading(false)
        }
      } else {
        setContainerCharacteristics(undefined)
        setIsIsoCodeValid(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, showArchetypeAttributes],
  )

  useAsyncFetch(async () => {
    await fetchContainerCharacteristicsByIsoCode(isoCodeInput)
  }, [fetchContainerCharacteristicsByIsoCode, isoCodeInput])

  return (
    <>
      <Grid container spacing={1} paddingTop={0.2}>
        <Grid item xs={inputSize}>
          <Controller
            rules={{ required, minLength: 4, maxLength: 4, validate: () => isIsoCodeValid }}
            name={name}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                data-cy={'create-nnr-form-isocode'}
                error={!!errors[name]}
                helperText={containerIsoCodeErrorText(errors)}
                label={label}
                type={type}
                fullWidth={fullWidth}
                disabled={disabled}
                required={required}
                variant={variant ?? 'outlined'}
                onChange={async event => {
                  event.target.value = event.target.value.toUpperCase()
                  field.onChange(event)
                }}
                inputProps={{
                  maxLength: 4,
                }}
                InputProps={{
                  endAdornment: <>{isLoading && <CircularProgress size={20} />}</>,
                }}
              />
            )}
          />
        </Grid>

        {!isLoading && !isIsoCodeValid && isoCodeInput?.length === 4 && (
          <IsoCodeNotFoundMessage isoCodeInput={isoCodeInput} />
        )}

        {!isLoading && isIsoCodeValid && isoCodeInput?.length === 4 && containerCharacteristics && (
          <Grid item xs={12 - inputSize} mt={theme.customSpacing.xxs}>
            <ContainerCharacteristicsInfo
              isoCode={isoCodeInput}
              containerCharacteristics={containerCharacteristics}
              showArchetype={showArchetype}
              allowEdit={allowEdit}
            />
          </Grid>
        )}
      </Grid>

      <IsoCodeMappingFormDialog
        viewStore={isoCodeMappingUIStore}
        onFilter={getContainerCharacteristicsByIsoCode}
        onSubmitFn={(id, data) => {
          if (data.isoCode) setValue?.(data.isoCode)
          setContainerCharacteristics({ ...data, id })
          setIsIsoCodeValid(true)
        }}
      />
    </>
  )
}
