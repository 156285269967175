import { debounce } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

interface ItemProps {
  id: number
  name: string
}

interface Props {
  items: ItemProps[]
  id?: number
}
export const useUniquenessValidation = ({
  items,
  id,
}: Props): [boolean, (value: string) => void] => {
  const [isUnique, setIsUnique] = useState<boolean>(true)

  const validateUniqueness = useMemo(
    () =>
      debounce(
        (value: string) =>
          setIsUnique(
            !items.some(
              item =>
                item.name.toLowerCase().trim() === value.toLowerCase().trim() && item.id !== id,
            ),
          ),
        200,
      ),
    [id, items],
  )

  useEffect(() => {
    setIsUnique(true)
  }, [id])

  return [isUnique, validateUniqueness]
}
