import { ContainerJourneyResponseDto, ContainerJourneysApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetContainerJourneysQuery implements IQueryMessage {
  static type = 'GetContainerJourneysQuery'
  type = GetContainerJourneysQuery.type

  constructor(
    public ids: number[],
    public includeCompleted?: boolean,
  ) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(ContainerJourneysApi)

export const getContainerJourneysQueryHandler: IQueryHandler<
  GetContainerJourneysQuery,
  IEvent<ContainerJourneyResponseDto[]>
> = async query => {
  const { data } = await httpClient.get(query.ids, query.includeCompleted)

  return new Event(GetContainerJourneysQuery.type, data)
}
