import { CargoType, CarrierType, CarrierVisitStatus } from '@planning/app/api'
import { CarrierVisitViewStatus } from '@planning/constants'
import { carrierVisitService } from '@planning/services'
import { IFilterDelegate, ISortDelegate } from '@planning/stores/PaginatedLocalStore'
import { SortingModel } from '@planning/stores/PaginatedStore'
import dayjs from 'dayjs'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import moment from 'moment'
import { IRailVisitItem } from '../railVisit/RailVisitItem'
import { IVesselVisitItem } from '../vesselVisit/VesselVisitItem'

export const visitSortingDelegate: ISortDelegate<VisitItem> = (sortingModel, a, b) => {
  const getValue = (item: VisitItem, sortingModel: SortingModel<VisitItem>) => {
    if (sortingModel.orderBy) {
      if (_.has(item, sortingModel.orderBy)) return _.get(item, sortingModel.orderBy)
      if (_.has(item.data, sortingModel.orderBy)) return _.get(item.data, sortingModel.orderBy)
    }
    return item.arrival
  }

  const valA = getValue(a, sortingModel) as any
  const valB = getValue(b, sortingModel) as any

  let sort = 0
  if (moment(valA).isValid() && moment(valB).isValid()) {
    sort = moment(valA).diff(moment(valB))
  } else if (typeof valA === 'string' && typeof valB === 'string') {
    sort = valA.localeCompare(valB)
  }

  return sortingModel.isDescending ? -sort : sort
}

export const visitFilterDelegate: IFilterDelegate<VisitItem> = (
  filter: string,
  item: VisitItem,
  showCompleted?: boolean,
  from?: Date,
  to?: Date,
  filterStatus?: string,
) => {
  const filterableProperties: string[] = ['data.cargoType', 'carrierName', 'shippingLine']

  if (filterStatus && item.data?.status !== filterStatus) {
    return false
  }

  if (filter !== '') {
    return filterableProperties
      .map(p => p as keyof VisitItem)
      .some(p => {
        const prop = _.get(item, p)

        if (typeof prop === 'string') return prop.toLowerCase().includes(filter.toLowerCase())
        if (typeof prop === 'number') return prop === parseFloat(filter)

        return false
      })
  }

  return true
}

export type VisitItem = {
  carrierType: CarrierType
  arrival: string | null | undefined
  departure: string | null | undefined
  ata: string | null | undefined
  atd: string | null | undefined
  eta: string | null | undefined
  etd: string | null | undefined
  carrierName?: string | null
  shippingLine?: string | null
  dischargeCount: number
  loadCount: number
  dischargeEstimate: number
  loadEstimate: number
  inboundTripIds: string[]
  outboundTripIds: string[]
  orderIds: number[]
  berthOrTrack: string
  status: CarrierVisitViewStatus
  cargoType: CargoType
} & (Partial<IVesselVisitItem> | Partial<IRailVisitItem>)

const noneIndicator = 'None'

export class VisitViewStore {
  visits: VisitItem[] = []

  constructor() {
    makeObservable(this, {
      visits: observable,
      fetchVisits: action,
      elements: computed,
    })
  }

  fetchVisits = async (from?: Date, to?: Date) => {
    try {
      let startDate = from
      let endDate = to

      if (!from && !to) {
        startDate = dayjs().subtract(5, 'days').startOf('day').toDate()
        endDate = dayjs().add(5, 'days').endOf('day').toDate()
      }

      const fetchedVisits = await carrierVisitService.visits(
        startDate!.toISOString(),
        endDate!.toISOString(),
      )

      runInAction(() => {
        this.visits = fetchedVisits.map(visit => ({
          id: visit.carrierVisitId ?? 0,
          arrival: visit.ata ?? visit.eta ?? null,
          departure: visit.atd ?? visit.etd ?? null,
          carrierType: visit.carrierType ?? CarrierType.Universal,
          carrierName: visit.carrierName ?? 'Unknown',
          shippingLine: visit.shippingLine ?? 'Unknown',
          dischargeCount: visit.dischargeCount ?? 0,
          loadCount: visit.loadCount ?? 0,
          inboundTripIds: visit.inboundTripIds ?? [],
          outboundTripIds: visit.outboundTripIds ?? [],
          berthOrTrack: visit.berthName ?? visit.trackName ?? noneIndicator,
          cargoType: visit.cargoType ?? CargoType.Unknown,
          status: visit.carrierVisitStatus ?? CarrierVisitStatus.Expected,
          orderIds: [...(visit.inboundOrderIds ?? []), ...(visit.outboundOrderIds ?? [])],
          ...(visit as Partial<IVesselVisitItem> | Partial<IRailVisitItem>),
        })) as VisitItem[]
      })
    } catch (error) {
      console.error('Failed to fetch visits:', error)
    }
  }

  get elements(): VisitItem[] {
    return this.visits
  }
}
