import { Box } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerAutoComplete } from '@planning/components'
import { Loader } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IContainerJourney } from '../Stores/ContainerJourney'
import { ServiceOrdersType, ServiceOrdersViewStore } from '../Stores/ServiceOrdersViewStore'
import { ServiceOrderCardCollection } from './ServiceOrderCardCollection'

export const ServiceOrdersSearchList: FC<{
  store: ServiceOrdersViewStore
}> = observer(({ store }) => {
  const { serviceOrdersListViewStore } = usePlanningStore()

  const handleCardClick = (containerJourney: IContainerJourney, serviceType: ServiceOrdersType) => {
    store.setMainServiceType(serviceType)
    store.setSelectedContainerJourney(containerJourney)
  }

  return (
    <Box>
      <ContainerAutoComplete
        onChange={async (_, value) => {
          if (value && typeof value != 'string' && value.id) {
            await serviceOrdersListViewStore.fetchData(value.id)
          }
        }}
        hasServiceOrders
      />

      {serviceOrdersListViewStore.isLoading ? (
        <Loader show />
      ) : (
        serviceOrdersListViewStore.containerJourneyListItems.map(
          (containerJourney: IContainerJourney) => (
            <ServiceOrderCardCollection
              key={containerJourney.id}
              containerJourney={containerJourney}
              handleCardClick={handleCardClick}
            />
          ),
        )
      )}
    </Box>
  )
})
