import { Box, Typography } from '@mui/material'
import { VesselServiceResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { useWrappedLocalPagination } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet-async'
import { VesselServiceFormDialog } from './Components/Organisms/VesselServiceFormDialog'
import { VesselServiceHeader } from './Components/Organisms/VesselServiceHeader'
import { VesselServiceList } from './Components/Organisms/VesselServiceList'
import { VesselServiceViewStore } from './Stores/VesselServiceViewStore'

export const VesselServicePage = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { vesselServiceItemStore, appViewStore } = usePlanningStore()
  const viewStore = new VesselServiceViewStore(vesselServiceItemStore)

  const { loading, error } = useAsyncFetch(async () => {
    await vesselServiceItemStore.fetch()
  }, [vesselServiceItemStore])

  const vesselServiceListViewStore = useWrappedLocalPagination(
    () => viewStore.items,
    () => vesselServiceItemStore.fetch(),
  )

  if (error) {
    return (
      <Typography variant='h4'>
        {t('errorLoadingVesselServices', 'Error loading vessel services')}
      </Typography>
    )
  }

  const onEdit = (item: VesselServiceResponseDto) => {
    viewStore.toggleFormDialog(true, item)
  }

  const onRemove = async (item: VesselServiceResponseDto) => {
    const confirmed = await appViewStore.setOpenConfirmDialog(
      true,
      t(
        'areYouSureYouWantToDeleteThisVesselService',
        'Are you sure you want to delete this vessel service?',
      ),
      t('confirmVesselServiceDelete', 'Confirm vessel service delete'),
      t(
        'anyVesselUsingThisServiceAsDefaultWillBeAffected',
        'Any vessel using this service as default will be affected',
      ),
    )

    if (confirmed) {
      await viewStore.onDelete(item.id)
      appViewStore.setShowAlert(
        'success',
        t('visitServiceWasCancelledSuccessfully', 'Vessel service was removed successfully'),
      )
    }
  }

  return (
    <InitializationWrapper isInitializing={loading}>
      <Box
        sx={{
          height: '100%',
          bgcolor: theme.palette.grey[100],
        }}
      >
        <Helmet>
          <title>{t('vesselServices', 'Vessel services')}</title>
        </Helmet>

        <VesselServiceHeader viewStore={viewStore} />
        <VesselServiceList store={vesselServiceListViewStore} onEdit={onEdit} onRemove={onRemove} />
        <VesselServiceFormDialog store={viewStore} />
      </Box>
    </InitializationWrapper>
  )
})
