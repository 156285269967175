import {
  CreateVesselServiceCommand,
  UpdateVesselServiceCommand,
  VesselServiceResponseDto,
} from '@planning/app/api'
import { VesselServiceItemStore } from '@planning/rt-stores/vesselService/VesselServiceItemStore'
import { vesselServiceService } from '@planning/services'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export interface VesselServiceFormData {
  id?: number
  serviceId: string
  name: string
  portRotation: string[]
}

export class VesselServiceViewStore {
  filter = ''
  isFormDialogOpen = false
  isDeleteDialogOpen = false

  dataToBeEdited?: VesselServiceResponseDto

  constructor(private readonly vesselServiceItemStore: VesselServiceItemStore) {
    makeObservable(this, {
      filter: observable,
      dataToBeEdited: observable,
      isFormDialogOpen: observable,
      isDeleteDialogOpen: observable,

      allItems: computed,
      items: computed,

      setFilter: action,
      toggleFormDialog: action,
      toggleDeleteDialog: action,
      setDataToBeEdited: action,
    })
  }

  get allItems() {
    return _.values(this.vesselServiceItemStore.elements)
  }

  get items() {
    const filteredItems = _(this.vesselServiceItemStore.elements)
      .filter(item =>
        item.data.name?.toLocaleUpperCase().includes(this.filter?.toLocaleUpperCase()),
      )
      .value()
      .map(x => x.data)

    return filteredItems
  }

  setDataToBeEdited = (data?: VesselServiceResponseDto) => (this.dataToBeEdited = data)

  setFilter = (filter: string) => (this.filter = filter)

  toggleFormDialog = (shouldOpen: boolean, data?: VesselServiceResponseDto) => {
    this.isFormDialogOpen = shouldOpen
    this.setDataToBeEdited(data)
  }

  toggleDeleteDialog = (shouldOpen: boolean) => (this.isDeleteDialogOpen = shouldOpen)

  onDelete = async (id: number) => {
    this.toggleDeleteDialog(false)
    await vesselServiceService.delete(id)
  }

  onSubmit = async (data: VesselServiceFormData) => {
    const isEditMode = !!data.id

    if (isEditMode) {
      const cmd: UpdateVesselServiceCommand = {
        ...data,
        id: data.id!,
      }
      await vesselServiceService.update(cmd)
    } else {
      const cmd: CreateVesselServiceCommand = {
        ...data,
      }
      await vesselServiceService.create(cmd)
    }
  }
}
