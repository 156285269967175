import { Grid, Paper } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { IControlledIsoCodeMappingInputProps } from '@planning/pages/Order/stores/IsoCodeMappingInputStore'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { VesselVisitHeaderVisit } from '../Header/VesselVisitHeaderVisit'
import { VesselVisitHeaderBerth } from './VesselVisitHeaderBerth'
import { VesselVisitHeaderTime } from './VesselVisitHeaderTime'
import { VesselVisitHeaderVessel } from './VesselVisitHeaderVessel'

interface Props {
  vesselVisitItem: IVesselVisitItem
  renderIsoCodeMappingInput?: (
    props: IControlledIsoCodeMappingInputProps<any>,
  ) => React.ReactElement
}

// todo: [north] storybook
export const VesselVisitHeader: FC<Props> = observer(
  ({ vesselVisitItem, renderIsoCodeMappingInput }) => {
    const theme = useMinimalsTheme()
    const { isoCodeMappingInputStore } = usePlanningStore()

    const isBerthPlanningFeatureActive = useBooleanFlagValue('vessel-visit-berth-planning', false)

    if (!vesselVisitItem || !vesselVisitItem.vessels[0]) return <></>

    const vesselVisit = vesselVisitItem.data
    const vessels = vesselVisitItem.vessels
    const firstVessel = vessels[0].data

    isoCodeMappingInputStore.renderIsoCodeMappingInput = renderIsoCodeMappingInput

    return (
      <Paper
        className='vessel-visit-details-header'
        square
        elevation={1}
        sx={{ backgroundColor: theme.palette.grey[200] }}
      >
        <Grid container direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between'>
          <Grid item sm={isBerthPlanningFeatureActive ? 6 : 3}>
            <VesselVisitHeaderVisit
              vesselVisit={vesselVisit}
              vessel={firstVessel}
              vessels={vessels}
              vesselService={vesselVisit}
              status={vesselVisitItem.status}
            />
          </Grid>
          <Grid item sm={isBerthPlanningFeatureActive ? 6 : 9}>
            <Grid container direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between'>
              <Grid item sm={isBerthPlanningFeatureActive ? 6 : 4}>
                <VesselVisitHeaderTime vesselVisitItem={vesselVisitItem} />
              </Grid>
              {!isBerthPlanningFeatureActive && (
                <Grid item sm={4}>
                  <VesselVisitHeaderBerth
                    vesselVisit={vesselVisit}
                    assignedBerths={vesselVisitItem.berths}
                  />
                </Grid>
              )}
              <Grid item sm={isBerthPlanningFeatureActive ? 6 : 4}>
                <VesselVisitHeaderVessel vesselVisit={vesselVisit} vessel={firstVessel} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  },
)
