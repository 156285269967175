import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { Header, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { LocationCodesViewStore } from '../../stores/LocationCodesViewStore'

interface IProps {
  viewStore: LocationCodesViewStore
}

export const LocationCodeHeader: FC<IProps> = observer(({ viewStore }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const onSearch = (value: string) => {
    viewStore.setFilter(value)
  }

  return (
    <Header
      title={t('unLocationCodes', 'UN Location codes')}
      searchInputLabel={t('searchForALocationCode', 'Search for a location code')}
      onSearchFieldChange={onSearch}
      displaySearchIcon
      enableSearchField
      rightRenderOption={
        <Stack flexDirection='row' gap={theme.customSpacing.xs}>
          <Button
            color='inherit'
            variant='contained'
            sx={{ flex: 'none' }}
            startIcon={<PlusIcon />}
            onClick={() => {
              viewStore.toggleFormDialog(true)
            }}
          >
            {t('addNew', 'Add new')}
          </Button>
        </Stack>
      }
    />
  )
})
