import { SelectOption } from '@billing/app/models/select-option.model'
import { CompanyStore } from '@billing/stores/CompanyStore'
import { Dayjs } from 'dayjs'
import { action, computed, makeObservable, observable } from 'mobx'

export class CustomerBillingUIStore {
  customerId = ''
  from: Dayjs | null = null
  to: Dayjs | null = null
  constructor(private readonly _customerStore: CompanyStore) {
    makeObservable(this, {
      customerId: observable,
      from: observable,
      to: observable,
      customerOptions: computed,
      setCustomerId: action,
      setFrom: action,
      setTo: action,
    })
  }

  get customerOptions(): SelectOption[] {
    return this._customerStore.items.map(c => ({ label: c.name, value: c.id }))
  }

  setCustomerId(customerId: string) {
    this.customerId = customerId
  }

  setFrom(from: Dayjs | null): void {
    this.from = from
  }

  setTo(to: Dayjs | null): void {
    this.to = to
  }

  resetData() {
    this.setCustomerId('')
    this.setFrom(null)
    this.setTo(null)
  }
}
