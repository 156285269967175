import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CompanyDto, CompanyType } from '@storage/app/api'
import { useCarrierTypeOptions } from '@storage/app/carriers/hooks'
import {
  useCargoStatusOptions,
  useContainerSizeOptions,
  useDangerousOptions,
  useReeferOptions,
} from '@storage/app/container-turnovers/hooks'
import { useContainerHeightOptions } from '@storage/app/container-turnovers/hooks/use-container-height-options.hook'
import { useContainerTypeOptions } from '@storage/app/container-turnovers/hooks/use-container-type-options.hook'
import { useOOGOptions } from '@storage/app/container-turnovers/hooks/use-oog-options.hook'
import { SelectOption, UnknownNumberValue } from '@storage/app/models'
import { ControlledSelect, ControlledTextField } from '@storage/components/form-controls'
import { ControlledMultipleChipSelect } from '@storage/components/form-controls/ControlledMultipleChipSelect'
import { useTranslate } from '@tolgee/react'
import { matchSorter } from 'match-sorter'
import { Control, Controller, FormState } from 'react-hook-form'
import { AllocationRuleTemplateFormProfile } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'

interface AllocationRuleCommonFieldsProps {
  control: Control<AllocationRuleTemplateFormProfile>
  formState: FormState<AllocationRuleTemplateFormProfile>
  weightClassesOptions: any[]
  companies: CompanyDto[]
  openWithOnlyContainerNumber?: boolean
  showCarrierTypeField?: boolean
}

export const AllocationRuleCommonFields = ({
  control,
  formState,
  weightClassesOptions,
  companies,
  openWithOnlyContainerNumber,
  showCarrierTypeField = false,
}: AllocationRuleCommonFieldsProps) => {
  const { t } = useTranslate()
  const isOrderNewCompanyFieldsActive = useBooleanFlagValue('order-changing-company-fields', false)

  const containerSizeOptions = useContainerSizeOptions()
  const containerTypeOptions = useContainerTypeOptions()
  const containerHeightOptions = useContainerHeightOptions()
  const cargoStatusOptions = useCargoStatusOptions()
  const reeferOptions = useReeferOptions()
  const dangerousOptions = useDangerousOptions()
  const oogOptions = useOOGOptions()
  const carrierOptions = useCarrierTypeOptions(true)

  if (openWithOnlyContainerNumber) {
    return (
      <ControlledTextField
        name='facets.containerNumber'
        formState={formState}
        control={control}
        fullWidth
        label={t('containerNumber', 'Container number')}
      />
    )
  }

  return (
    <>
      <Typography variant='subtitle1'>{t('facets', 'Facets')}</Typography>
      <Stack direction='row' gap={1}>
        <ControlledSelect
          formState={formState}
          control={control}
          fullWidth
          options={containerSizeOptions}
          label={t('size', 'Size')}
          name='facets.size'
        />

        {isOrderNewCompanyFieldsActive ? (
          BuildAutoComplete(
            'facets.containerOperator',
            CompanyType.ShippingLine,
            t('containerOperator', 'Container Operator'),
          )
        ) : (
          <ControlledTextField
            name='facets.containerOperator'
            formState={formState}
            control={control}
            fullWidth
            label={t('containerOperator', 'Container Operator')}
          />
        )}
      </Stack>
      <Stack direction='row' gap={1}>
        <ControlledSelect
          allOption
          formState={formState}
          control={control}
          fullWidth
          options={containerTypeOptions}
          label={t('type', 'Type')}
          name='facets.containerType'
        />
        <ControlledSelect
          allOption
          formState={formState}
          control={control}
          fullWidth
          options={containerHeightOptions}
          label={t('height', 'Height')}
          name='facets.containerHeight'
        />
      </Stack>
      <Stack direction='row' gap={1}>
        {isOrderNewCompanyFieldsActive ? (
          BuildAutoComplete('facets.consignee', CompanyType.Consignee, t('consignee', 'Consignee'))
        ) : (
          <ControlledTextField
            name='facets.consignee'
            formState={formState}
            control={control}
            fullWidth
            label={t('consignee', 'Consignee')}
          />
        )}

        <ControlledTextField
          name='facets.portOfDischarge'
          formState={formState}
          control={control}
          fullWidth
          label={t('portOfDischarge', 'Port of discharge')}
        />
      </Stack>

      {isOrderNewCompanyFieldsActive ? (
        <Controller
          control={control}
          name='facets.customer'
          render={({ field }) => (
            <Autocomplete
              value={
                companies
                  .map(x => ({ label: x.name ?? x.name, value: x.id.toString() }))
                  .find(x => x.value === field.value) ?? null
              }
              options={[
                ...companies
                  .filter(x => x.types.includes(CompanyType.ShippingLine))
                  .map(x => ({ label: x.name ?? x.name, value: x.id.toString() })),
                { label: t('unknown', 'Unknown'), value: UnknownNumberValue.toString() },
              ]}
              fullWidth
              onChange={(_, newValue: SelectOption | null) => {
                field.onChange(newValue?.value ?? '')
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              getOptionLabel={option => option.label}
              renderInput={params => <TextField {...params} label={t('customer', 'Customer')} />}
              filterOptions={(options, { inputValue }) => {
                return matchSorter(options, inputValue.replace(/\s/g, ''), {
                  keys: [item => item.label.replace(/\./g, '').replace(/\s/g, '')],
                  threshold: matchSorter.rankings.CONTAINS,
                })
              }}
            />
          )}
        />
      ) : (
        <ControlledSelect
          formState={formState}
          control={control}
          options={[
            ...companies.map(x => ({ label: x.name, value: x.id.toString() })),
            { label: t('unknown', 'Unknown'), value: UnknownNumberValue.toString() },
          ]}
          label={t('customer', 'Customer')}
          name='facets.customer'
        />
      )}
      {weightClassesOptions.length > 0 && (
        <>
          <Typography variant='captionBold'>{t('weightClasses', 'Weight Classes')}</Typography>
          <Stack direction='row'>
            <ControlledMultipleChipSelect
              formState={formState}
              control={control}
              options={weightClassesOptions}
              name='facets.weightClasses'
              hasTooltip
            />
          </Stack>
        </>
      )}

      <ControlledSelect
        formState={formState}
        control={control}
        options={cargoStatusOptions}
        label={t('cargoStatus', 'Cargo Status')}
        name='facets.isEmpty'
      />

      <ControlledSelect
        formState={formState}
        control={control}
        options={reeferOptions}
        label={t('reefer', 'Reefer')}
        name='facets.isReefer'
      />

      <ControlledSelect
        formState={formState}
        control={control}
        options={dangerousOptions}
        label={t('dangerous', 'Dangerous')}
        name='facets.isDangerous'
      />

      <ControlledSelect
        formState={formState}
        control={control}
        options={oogOptions}
        label={t('oog', 'OOG')}
        name='facets.isOOG'
        dataCy='oog-select'
      />

      {showCarrierTypeField && (
        <ControlledSelect
          formState={formState}
          control={control}
          options={carrierOptions}
          label={t('carrierType', 'Carrier Type')}
          name='facets.carrierType'
        />
      )}
    </>
  )

  function BuildAutoComplete(name: any, companyType: CompanyType, textFieldLabel: string) {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Autocomplete
            value={companies.find(x => x.shortName === field.value) ?? null}
            options={companies.filter(x => x.types.includes(companyType))}
            fullWidth
            onChange={(_, newValue: CompanyDto | null) => {
              field.onChange(newValue?.shortName ?? '')
            }}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) => option.shortName === value.shortName}
            renderInput={params => <TextField {...params} label={textFieldLabel} />}
            filterOptions={(options, { inputValue }) => {
              return matchSorter(options, inputValue.replace(/\s/g, ''), {
                keys: [item => item.name.replace(/\./g, '').replace(/\s/g, '')],
                threshold: matchSorter.rankings.CONTAINS,
              })
            }}
          />
        )}
      />
    )
  }
}
