import { useAdminStore } from '@admin/AppProvider'
import { SingleLocationCodeAutocompleteProps } from '@admin/pages/TenantConfiguration/components/form/tenant-config.interface'
import { useTranslate } from '@tolgee/react'
import { DrawerFormContainer } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { TenantForm } from '../components/form/TenantForm'
import { TenantFormMapper } from '../components/form/tenant-form.mapper'
import { TenantFormProfile } from '../components/form/tenant-form.profile'

interface Props {
  SingleLocationCodeAutoComplete: React.ComponentType<SingleLocationCodeAutocompleteProps>
}

export const TenantDrawerContainer = observer(({ SingleLocationCodeAutoComplete }: Props) => {
  const { tenantStore, tenantDrawerUIStore, timezonesStore } = useAdminStore()

  const handleSubmit = async (formValues: TenantFormProfile) => {
    if (tenantDrawerUIStore.formType === 'Add') {
      await tenantStore.add(TenantFormMapper.mapFormValuesToCreateDto(formValues))
    } else {
      await tenantStore.update(TenantFormMapper.mapFormValuesToUpdateDto(formValues))
    }

    tenantDrawerUIStore.toggle()
  }

  const { t } = useTranslate()

  return (
    <DrawerFormContainer
      open={tenantDrawerUIStore.isOpen}
      title={`${tenantDrawerUIStore.formType} ${t('tenant', 'Tenant')}`}
      primaryActionLabel={tenantDrawerUIStore.formType}
      closeActionLabel={t('close', 'Close')}
      formId='tenant-form'
      onClose={() => tenantDrawerUIStore.toggle()}
    >
      <TenantForm
        id='tenant-form'
        tenant={tenantDrawerUIStore.editingEntity}
        timezonesStore={timezonesStore}
        onSubmit={handleSubmit}
        SingleLocationCodeAutoComplete={SingleLocationCodeAutoComplete}
      />
    </DrawerFormContainer>
  )
})
