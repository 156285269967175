import {
  AllowTruckVisitEntryCommand,
  AllowTruckVisitExitCommand,
  CarrierVisitStatus,
  TruckVisitsApi,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class TruckVisitService {
  httpClient = createApiClient(TruckVisitsApi)

  allowEntry = async (cmd: AllowTruckVisitEntryCommand) =>
    await this.httpClient.allowTruckVisitEntry(cmd.id, cmd)

  allowExit = async (cmd: AllowTruckVisitExitCommand) =>
    await this.httpClient.allowTruckVisitExit(cmd.id, cmd)

  getByIds = async (ids?: Array<number>) => await this.httpClient.getByIds(ids)

  isOnTerminal = async (licensePlate: string) => await this.httpClient.isOnTerminal(licensePlate)

  getWithOrdersById = async (id: number) => {
    const { data } = await this.httpClient.getWithOrdersById(id)
    return data
  }

  get = async (page: number, pageSize: number, status: CarrierVisitStatus, filter?: string) => {
    const { data } = await this.httpClient.get(page, pageSize, status, undefined, undefined, filter)

    return data.payload
  }

  getWithOrders = async (from?: Date, to?: Date) => {
    const { data } = await this.httpClient.getWithOrders(from?.toUTCString(), to?.toUTCString())

    return data
  }

  getByLicensePlate = async (licensePlate: string, status: CarrierVisitStatus) => {
    const { data } = await this.httpClient.getWithOrdersByLicensePlate(licensePlate, status)

    return data
  }

  getByDriverId = async (driverId: string, status: CarrierVisitStatus) => {
    const { data } = await this.httpClient.getWithOrdersByDriverDetails(driverId, status)

    return data
  }
}

const truckVisitService = new TruckVisitService()

export default truckVisitService
