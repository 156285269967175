import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ArrowRightIcon } from '@mui/x-date-pickers'
import { BookingsResponseDto } from '@planning/app/api'
import IconVessel from '@planning/components/icon/IconVessel'
import { formatDateTime } from '@planning/utils'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

type Props = {
  booking: BookingsResponseDto
}

export const DropOffBookingCard: FC<Props> = observer(({ booking }) => {
  const theme = useMinimalsTheme()
  return (
    <Box width='100%'>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack>
          <Typography variant='subtitle2'>{booking.referenceNumber}</Typography>
          <Typography variant='caption' color='secondary'>
            {booking.containerOperator}
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='center' spacing={theme.customSpacing.m}>
          <IconVessel color='secondary' />

          <Stack>
            <Typography variant='body2' color='secondary'>
              {booking.carrierVisit?.identifier}
            </Typography>
            <Typography variant='caption' color='secondary'>
              {formatDateTime(booking.carrierVisit?.ata ?? booking.carrierVisit?.eta)}
            </Typography>
          </Stack>
          <ArrowRightIcon fontSize='large' />
        </Stack>
      </Stack>
    </Box>
  )
})
