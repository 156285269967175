import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'

export const useDeleteEntityHandler = () => {
  const { t } = useTranslate()
  const { appViewStore } = usePlanningStore()

  const handleDelete = async (
    id: number,
    entityName: string,
    onDelete: (id: number) => Promise<void>,
  ) => {
    const isConfirmed = await appViewStore.setOpenConfirmDialog(
      true,
      t(
        'entityWillBeDeletedAndYouCannotUndoThisAction',
        '{entity} will be deleted and you cannot undo this action!',
        { entity: entityName },
      ),
      t('deleteEntity', 'Delete {entity}?', { entity: entityName }),
    )

    if (isConfirmed) {
      try {
        await onDelete(id)
        appViewStore.setShowAlert(
          'success',
          t('entityDeletedSuccessfully', `{entity} deleted successfully`, { entity: entityName }),
        )
      } catch (error: any) {
        appViewStore.setShowAlert(
          'error',
          error?.response?.data?.detail ??
            t('failedToDeleteEntity', `Failed to delete {entity}`, { entity: entityName }),
        )
      }
    }
  }

  return { handleDelete }
}
