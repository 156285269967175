import { ContainerType } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { useContainerAttributesParameters } from '@tom-ui/ui'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { ReeferRefrigerated, Fish, Auto, Livestock, ...ModifiedContainerType } = ContainerType

export const useContainerTypeOptions = (): SelectOption[] => {
  const { types: containerTypeParameters } = useContainerAttributesParameters()

  const containerTypeOptions = useMemo(
    () =>
      Object.values(ModifiedContainerType)
        .sort()
        .map(type => ({
          label: containerTypeParameters[type].label,
          value: type.toString(),
        })),
    [containerTypeParameters],
  )

  return containerTypeOptions
}
