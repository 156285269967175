import { ContainerDamageTypeDto } from '@admin/app/api'
import { FormProps } from '@admin/app/models'
import { useAdminStore } from '@admin/AppProvider'
import useFormWithSchema from '@admin/hooks/use-form-with-schema.hook'
import {
  Box,
  Checkbox,
  debounce,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { BinIcon, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { ContainerDamageTypeFormMapper } from './container-damage-type-form.mapper'
import { ContainerDamageTypeFormProfile, defaultValues } from './container-damage-type-form.profile'
import { schema } from './container-damage-type-form.scheme'

interface Props extends FormProps {
  containerDamageType?: ContainerDamageTypeDto
  onSubmit: (formValues: ContainerDamageTypeFormProfile) => Promise<void> | void
}

export const ContainerDamageTypeForm = ({ id, containerDamageType, onSubmit }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { containerDamageTypeStore } = useAdminStore()

  const { handleSubmit, formState, control, reset, setValue } =
    useFormWithSchema<ContainerDamageTypeFormProfile>(schema, defaultValues)

  useEffect(() => {
    if (containerDamageType) {
      reset(ContainerDamageTypeFormMapper.mapDtoToFormValues(containerDamageType))
    }
  }, [reset, containerDamageType])

  const [externalCodes, setExternalCodes] = useState<string[]>(
    containerDamageType?.externalCodes.length ? containerDamageType.externalCodes : [''],
  )

  const updateExternalCodes = (newCodes: string[]) => {
    setExternalCodes(newCodes)
    setValue('externalCodes', newCodes)
  }

  const externalCodeExistError = (code: string): boolean => {
    if (!code) return false
    return (
      containerDamageTypeStore.items.some(
        i =>
          i.externalCodes.map(e => e.toLowerCase()).includes(code.toLowerCase()) &&
          i.id !== containerDamageType?.id,
      ) || externalCodes.filter(a => a === code).length > 1
    )
  }

  const [longNameExist, setLongNameExist] = useState<boolean>(false)
  const [shortNameExist, setShortNameExist] = useState<boolean>(false)

  const debouncedCheckLongName = useMemo(
    () =>
      debounce((value: string) => {
        setLongNameExist(
          containerDamageTypeStore.items.some(
            i =>
              i.longName.toLowerCase() === value.toLowerCase() && i.id !== containerDamageType?.id,
          ),
        )
      }, 200),
    [],
  )

  const debouncedCheckShortName = useMemo(
    () =>
      debounce((value: string) => {
        setShortNameExist(
          containerDamageTypeStore.items.some(
            i =>
              i.shortName?.toLowerCase() === value.toLowerCase() &&
              i.id !== containerDamageType?.id,
          ),
        )
      }, 200),
    [],
  )

  const longNameHelperText = () => {
    if (longNameExist) return t('longNameAlreadyExists', 'Long name already exists')
    if (formState.errors['longName']) return t('fieldIsRequired', 'Field is required')
    return undefined
  }

  const shortNameHelperText = () => {
    if (shortNameExist) return t('shortNameAlreadyExists', 'Short name already exists')
    return undefined
  }

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      pb={theme.customSpacing.xs}
    >
      <FormGroup sx={{ gap: theme.customSpacing.l }}>
        <Controller
          name='longName'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('longName', 'Long name')}
              fullWidth
              required
              disabled={containerDamageType?.isDefault}
              onChange={event => {
                field.onChange(event)
                debouncedCheckLongName(event.target.value)
              }}
              error={!!formState.errors['longName'] || longNameExist}
              helperText={longNameHelperText()}
            />
          )}
        />

        <Controller
          name='shortName'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('shortName', 'Short name')}
              fullWidth
              onChange={event => {
                field.onChange(event)
                debouncedCheckShortName(event.target.value)
              }}
              error={shortNameExist}
              helperText={shortNameHelperText()}
            />
          )}
        />

        {externalCodes.map((code, index) => (
          <Stack
            key={'alternative_name_' + index}
            flexDirection={'row'}
            gap={theme.customSpacing.s}
          >
            <TextField
              error={externalCodeExistError(code)}
              value={code}
              helperText={
                externalCodeExistError(code)
                  ? t('externalCodeAlreadyExists', 'External code already exists.')
                  : undefined
              }
              onChange={event => {
                const newCodes = [...externalCodes]
                newCodes[index] = event.target.value
                updateExternalCodes(newCodes)
              }}
              label={t('externalCode', 'External code')}
              fullWidth
            />

            {index === 0 ? (
              <Tooltip title={t('add', 'Add')}>
                <IconButton onClick={() => updateExternalCodes((externalCodes ?? []).concat(''))}>
                  <PlusIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t('remove', 'Remove')}>
                <IconButton
                  onClick={() => updateExternalCodes(externalCodes.filter((_, i) => i !== index))}
                >
                  <BinIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        ))}

        <Controller
          control={control}
          name={'isActive'}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label={t('active', 'Active')}
            />
          )}
        />
      </FormGroup>
    </Box>
  )
}
