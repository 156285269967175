import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Button, ButtonGroup, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { MultipleContainerSearchTextField } from '@planning/components/MultipleContainerSearchTextField'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IContainerJourney } from '../Stores/ContainerJourney'
import { ContainerJourneyOptions } from './ContainerJourneyOptions'

export const MultiSelectNumericForm: FC<{
  selectedContainerJourneys: IContainerJourney[]
  containerJourneyOptions: IContainerJourney[]
  onSelectContainerJourney: (containerJourneys?: IContainerJourney[]) => void
  onSearchFilterChange?: (containerNumbers?: string[]) => Promise<void> | void
  isNoContainersSelectedError?: boolean
  isLoading: boolean
}> = observer(
  ({
    selectedContainerJourneys,
    containerJourneyOptions,
    onSelectContainerJourney,
    onSearchFilterChange,
    isNoContainersSelectedError,
    isLoading,
  }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const selectedContainerJourneysLength = selectedContainerJourneys.length

    const getTextFieldCaption = () => {
      if (!containerJourneyOptions) return ''

      // TODO: If we pull the isMultiSelect logic out to CoolingOrderNumericForm, we can use computed value from store
      const numberOfExistingCoolingOrders = containerJourneyOptions.filter(
        journey => journey.coolingOrderId,
      ).length
      const reeferContainersRecognisedCaption = `${containerJourneyOptions.length} ${t('reeferContainersRecognised', 'reefer containers recognised')} `

      switch (numberOfExistingCoolingOrders) {
        case 0:
          return ''
        case 1:
          return `${reeferContainersRecognisedCaption}, 1 ${t('alreadyHasCoolingDetails', 'already has cooling details')}.`
        default:
          return `${reeferContainersRecognisedCaption}, ${containerJourneyOptions.filter(journey => journey.coolingOrderId).length} ${t('alreadyHaveCoolingDetails', 'already have cooling details')}.`
      }
    }

    const multilineTextFieldCaption = getTextFieldCaption()

    return (
      <>
        <>
          <MultipleContainerSearchTextField onChange={onSearchFilterChange} />
          {isLoading ? (
            <Stack direction='row' gap={theme.customSpacing.xs} alignItems='center'>
              <AutorenewIcon fontSize='small' sx={{ mt: 1, color: theme.palette.grey[600] }} />
              <Typography variant='caption' sx={{ mt: 1, color: theme.palette.grey[600] }}>
                {t('searching', 'Searching')}...
              </Typography>
            </Stack>
          ) : (
            !!multilineTextFieldCaption.length && (
              <Typography variant='caption' sx={{ mt: 1, color: theme.palette.grey[600] }}>
                {multilineTextFieldCaption}
              </Typography>
            )
          )}
        </>
        {containerJourneyOptions && !!containerJourneyOptions.length && (
          <Stack direction='column' alignItems='flex-end' sx={{ marginTop: theme.customSpacing.m }}>
            <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
              <Typography
                variant='body2'
                sx={
                  isNoContainersSelectedError && !selectedContainerJourneysLength
                    ? { color: 'red !important' }
                    : undefined
                }
              >
                {selectedContainerJourneysLength} {t('xContainersSelected', 'containers Selected')}
              </Typography>
              <ButtonGroup variant='contained' color='inherit'>
                <Button
                  color={
                    selectedContainerJourneysLength === containerJourneyOptions.length
                      ? 'primary'
                      : 'inherit'
                  }
                  onClick={() => onSelectContainerJourney(containerJourneyOptions)}
                >
                  {t('selectAll', 'Select All')}
                </Button>
                <Button
                  color={selectedContainerJourneysLength === 0 ? 'primary' : 'inherit'}
                  onClick={() => onSelectContainerJourney()}
                >
                  {t('deselectAll', 'Deselect All')}
                </Button>
              </ButtonGroup>
            </Stack>
            <ContainerJourneyOptions
              options={containerJourneyOptions}
              onSelect={onSelectContainerJourney}
              showCoolingOrderWarning={true}
              isMultiSelect={true}
              selectedOptions={selectedContainerJourneys}
            />
          </Stack>
        )}
      </>
    )
  },
)
