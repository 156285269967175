import { ContainerDamageTypeDto } from '@admin/app/api'
import { Box, Card, CardHeader, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, ColorSchema, Tile, useMinimalsTheme } from '@tom-ui/ui'
import { useMemo } from 'react'

interface Props {
  containerDamage: ContainerDamageTypeDto
  canEdit: boolean
  onClick: (containerDamageType: ContainerDamageTypeDto) => void
}
export const ContainerDamageTypeCard = ({ containerDamage, canEdit, onClick }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const isActiveInfo = useMemo(() => {
    return {
      label: containerDamage.isActive ? t('active', 'Active') : t('inactive', 'Inactive'),
      color: containerDamage.isActive ? 'success' : 'warning',
    }
  }, [containerDamage.isActive, t])

  return (
    <Card onClick={() => onClick(containerDamage)} sx={{ cursor: canEdit ? 'pointer' : undefined }}>
      <CardHeader
        title={
          <Stack flexDirection='row' justifyContent='space-between'>
            <Typography variant='h4'>{containerDamage.longName}</Typography>
            <Stack direction='row' gap={1}>
              <ColoredLabel label={isActiveInfo.label} color={isActiveInfo.color as ColorSchema} />
              {containerDamage.isDefault ? (
                <ColoredLabel label={t('default', 'Default')} color={'secondary'} />
              ) : (
                <ColoredLabel label={t('added', 'Added')} color='warning' />
              )}
            </Stack>
          </Stack>
        }
        sx={{ paddingBottom: theme => theme.customSpacing.m }}
      />
      {containerDamage.shortName && (
        <Box
          sx={{
            padding: theme.customSpacing.m,
            gap: theme.customSpacing.xl,
            display: 'flex',
            backgroundColor: theme.palette.grey[100],
            overflowX: 'auto',
          }}
        >
          <Tile label={t('shortName', 'Short name')} value={containerDamage.shortName} />
        </Box>
      )}
    </Card>
  )
}
