import { Box } from '@mui/material'
import { CarrierVisitStatus } from '@storage/app/api'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import {
  ContainerTurnoversFilterFormProfile,
  ContainerTurnoversFilterFormProfileKey,
  defaultValues,
} from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'

import {
  CTAdvanceFilterSection,
  CTAdvancedSearchField,
} from '@storage/pages/container-turnovers/components/container-turnovers-filter-form/CTAdvancedSearchField'
import { schema } from '@storage/pages/container-turnovers/components/container-turnovers-filter-form/container-turnovers-filter-form.schema'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import YardQuickFilter from './YardQuickFilter'

export const ContainerTurnoversAdvancedFilterFormId = 'ContainerTurnoversAdvancedFilterForm'

interface Props {
  filter: ContainerTurnoversFilterFormProfile
  advancedFiltersShown: boolean
  disabledFilters?: ContainerTurnoversFilterFormProfileKey[]
  onToggleAdvancedFilters: () => void
  onPlanManually?: () => void
  onPlanOnYard?: () => void
  onSubmit: (values: ContainerTurnoversFilterFormProfile) => void
}

export const YardFilterForm = observer(
  ({ filter, advancedFiltersShown, disabledFilters, onToggleAdvancedFilters, onSubmit }: Props) => {
    const form = useFormWithSchema<ContainerTurnoversFilterFormProfile>(schema, filter)

    const { control, formState, reset, handleSubmit, watch, setValue } = form

    const [showClearAll, setShowClearAll] = useState(false)

    useEffect(() => {
      isEqual(
        omit(filter, 'containerOrReferenceNumber'),
        omit(defaultValues, 'containerOrReferenceNumber'),
      )
        ? setShowClearAll(false)
        : setShowClearAll(true)
    }, [filter])

    const { carrierVisitStore: vesselVisitStore, companyStore } = useStores()

    const handleClearAll = useCallback(() => {
      for (const key of Object.keys(filter)) {
        const profileKey = key as keyof ContainerTurnoversFilterFormProfile

        setValue(profileKey, filter[profileKey])
      }
    }, [filter, setValue])

    useEffect(() => {
      handleClearAll()
    }, [setValue, filter, handleClearAll])

    useEffect(() => {
      const { unsubscribe } = watch((formValues, { name, type }) => {
        if (!advancedFiltersShown || (type === 'change' && name === 'containerOrReferenceNumber')) {
          onSubmit(formValues as ContainerTurnoversFilterFormProfile)
        }
      })

      return () => unsubscribe()
    }, [reset, onSubmit, watch, advancedFiltersShown])

    useEffect(() => {
      const loadVesselOptions = async () => {
        await vesselVisitStore.loadList({
          query: [
            CarrierVisitStatus.Arrived,
            CarrierVisitStatus.Expected,
            CarrierVisitStatus.InOperation,
          ],
        })
      }

      const fetchData = async () => {
        await companyStore.loadCompanies()
      }

      fetchData()

      loadVesselOptions()
    }, [vesselVisitStore])

    const handleToggleAdvancedFilters = () => {
      if (advancedFiltersShown) {
        handleClearAll()
      }

      onToggleAdvancedFilters()
    }

    return (
      <FormProvider {...form}>
        <Box sx={{ display: 'flex', flexFlow: 'column', gap: 2 }}>
          <Box
            id={ContainerTurnoversAdvancedFilterFormId}
            onReset={() => reset()}
            component='form'
            autoComplete='off'
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <CTAdvancedSearchField
              control={control}
              formState={formState}
              handleToggleAdvancedFilters={handleToggleAdvancedFilters}
              advancedFiltersShown={advancedFiltersShown}
              hideSections={[CTAdvanceFilterSection.LocationStatusControls]}
              companies={companyStore.companies}
            />
          </Box>

          {!advancedFiltersShown && (
            <YardQuickFilter
              disabledFilters={disabledFilters}
              showClearAll={showClearAll}
              onClearAll={() => {
                reset()
                setShowClearAll(false)
              }}
            />
          )}
        </Box>
      </FormProvider>
    )
  },
)
