import { Autocomplete, TextField } from '@mui/material'
import { LocationCodeAutocompleteViewStore } from '@planning/stores/locationCode/LocationCodeAutocompleteViewStore'
import { useTranslate } from '@tolgee/react'

import { observer } from 'mobx-react-lite'
import { FC, useMemo, useState } from 'react'

interface Props {
  name: string
  value?: string
  error?: boolean
  helperText?: React.ReactNode
  label?: string
  required?: boolean
  disabled?: boolean
  defaultValue?: string
  onChange: (locationCode: string) => void
}

export const SingleLocationCodeAutoComplete: FC<Props> = observer(
  ({ name, value, defaultValue, error, helperText, label, required, disabled, onChange }) => {
    const { t } = useTranslate()
    const [fieldValue, setFieldValue] = useState<string>(value ?? defaultValue!)
    const [inputValue, setInputValue] = useState<string>('')

    const store = useMemo(() => new LocationCodeAutocompleteViewStore(), [])

    return (
      <Autocomplete
        fullWidth
        disabled={disabled}
        multiple={false}
        open={store.open}
        isOptionEqualToValue={(option: string, value: string) => option === value}
        onOpen={() => store.setOpened(true)}
        onClose={() => store.setOpened(false)}
        options={store.options}
        defaultValue={defaultValue}
        value={fieldValue ?? ''}
        onChange={(_, value: string | null) => {
          setFieldValue(value ?? '')
          onChange(value ?? '')
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
          store.filterOptions(newInputValue)
        }}
        renderInput={params => (
          <TextField
            name={name}
            {...params}
            required={required}
            placeholder={
              value?.length
                ? ''
                : t('startTypingTOGetlocationCode', 'Start typing to get location code')
            }
            data-cy='location-code-autocomplete'
            label={label}
            helperText={helperText}
            error={error}
            fullWidth
            sx={{ minWidth: 300 }}
          />
        )}
      />
    )
  },
)
