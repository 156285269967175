import {
  CreateExternalDriverCommand,
  ExternalDriverResponseDto,
  UpdateExternalDriverCommand,
} from '@planning/app/api'
import { ExternalDriverItemStore } from '@planning/rt-stores/externalDriver/ExternalDriverItemStore'
import externalDriverService from '@planning/services/externalDriverService'

import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export interface ExternalDriverFormData {
  id?: number
  driverID: string
  name: string
  banned: string
  notes?: string | null
}

export class ExternalDriverViewStore {
  filter = ''

  isAddDialogOpen = false
  isDeleteDialogOpen = false

  dataToBeEdited?: ExternalDriverResponseDto

  addingCallback?: (externalDriver: ExternalDriverResponseDto) => void

  constructor(private readonly externalDriverItemStore: ExternalDriverItemStore) {
    makeObservable(this, {
      filter: observable,
      dataToBeEdited: observable,
      isAddDialogOpen: observable,
      isDeleteDialogOpen: observable,

      items: computed,

      setFilter: action,
      toggleAddDialog: action,
      toggleDeleteDialog: action,
      setDataToBeEdited: action,
    })
  }

  get allItems() {
    return _.values(this.externalDriverItemStore.elements)
  }

  get items() {
    const filteredItems = _(this.externalDriverItemStore.elements)
      .filter(
        item =>
          item.data.name?.toLocaleUpperCase().includes(this.filter?.toLocaleUpperCase()) ||
          item.data.driverID?.toLocaleUpperCase().includes(this.filter?.toLocaleUpperCase()),
      )
      .value()
      .map(x => x.data)

    return filteredItems
  }

  setDataToBeEdited = (data?: ExternalDriverResponseDto) => (this.dataToBeEdited = data)

  setFilter = (filter: string) => (this.filter = filter)

  toggleAddDialog = (
    shouldOpen: boolean,
    addingCallback?: (externalDriver?: ExternalDriverResponseDto) => void,
  ) => {
    this.isAddDialogOpen = shouldOpen
    this.addingCallback = addingCallback
  }

  toggleDeleteDialog = (shouldOpen: boolean) => (this.isDeleteDialogOpen = shouldOpen)

  onDelete = async (id: number) => {
    this.toggleDeleteDialog(false)
    await externalDriverService.delete(id)
  }

  onSubmit = async (data: ExternalDriverFormData) => {
    const isEditMode = !!data.id

    if (isEditMode) {
      const cmd: UpdateExternalDriverCommand = {
        ...data,
        banned: data.banned === 'true',
        id: data.id!,
      }
      await externalDriverService.update(cmd)
    } else {
      const cmd: CreateExternalDriverCommand = {
        ...data,
        banned: data.banned === 'true',
      }
      const id = await externalDriverService.create(cmd)

      if (this.addingCallback) {
        this.addingCallback({
          id,
          banned: cmd.banned ?? false,
          driverID: cmd.driverID,
          name: cmd.name,
          notes: cmd.notes,
        })
      }
    }
  }

  onRemoveBan = async (data: ExternalDriverResponseDto) => {
    const cmd: UpdateExternalDriverCommand = {
      ...data,
      banned: false,
    }
    await externalDriverService.update(cmd)
  }

  onBan = async (data: ExternalDriverResponseDto) => {
    const cmd: UpdateExternalDriverCommand = {
      ...data,
      banned: true,
    }
    await externalDriverService.update(cmd)
  }
}
