import { ErrorCodes, IApplicationDomainException } from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import { AxiosError } from 'axios'

/**
 * Excluded application domain exception errorCodes from
 * automatic snackbar error message
 */
const EXCLUDED_APPLICATION_DOMAIN_EXCEPTIONS_ERR_CODES: ErrorCodes[] = [
  ErrorCodes.AllocationSpaceConflict,
  ErrorCodes.DgStackingRulesViolationStackingOnTop,
  ErrorCodes.DgStackingRulesViolationMaxAllowedTier,
  ErrorCodes.DgSegregationRulesViolation,
]

export const onRejected = (snackbarStore: SnackbarStore) => {
  return (error: AxiosError) => {
    const exception = error.response?.data as IApplicationDomainException
    if (
      error.response?.status === 400 &&
      exception.errorCode &&
      !EXCLUDED_APPLICATION_DOMAIN_EXCEPTIONS_ERR_CODES.includes(exception.errorCode)
    ) {
      // Interim solution: Using a hardcoded map to translate error codes
      const { key, fallback } = ERROR_TRANSLATIONS[exception.errorCode] || {
        key: exception.errorCode,
        fallback: exception.errorCode,
      }

      const translatedMessage = tolgee.t(key, fallback)

      snackbarStore.showMessage(translatedMessage, 'error')
    }

    return Promise.reject(error)
  }
}

/**
 * Check if the application domain error code matches the axios error exception code
 * @param error
 * @param errorCode
 * @returns boolean
 */
export const isApplicationDomainException = (error: AxiosError, errorCode: ErrorCodes): boolean => {
  if (error.response?.status !== 400) {
    return false
  }
  const errorResponseData = error.response.data

  if (Array.isArray(errorResponseData)) {
    // Check if any of the errors in the array match the errorCode
    return errorResponseData.some(
      (item: IApplicationDomainException) => item.errorCode === errorCode,
    )
  } else {
    // Check if the single error object matches the errorCode
    return (errorResponseData as IApplicationDomainException).errorCode === errorCode
  }
}

/**
 * Get payload from application domain exception axios error
 * @param error
 * @returns any
 */
export const getApplicationDomainExceptionPayload = (error: AxiosError, errorCode?: ErrorCodes) => {
  const errorResponseData = error.response?.data

  if (Array.isArray(errorResponseData)) {
    const matchingError = errorResponseData.find(
      (item: IApplicationDomainException) => item.errorCode === errorCode,
    )
    return matchingError?.payload
  }

  return (errorResponseData as IApplicationDomainException)?.payload
}

const ERROR_TRANSLATIONS: Record<string, { key: string; fallback: string }> = {
  WEIGHT_CLASS_SET_EXISTS: {
    key: 'weightClassSetExists',
    fallback: 'A weight class set with this name already exists',
  },
  CREATE_WEIGHT_CLASS_SET_WITHOUT_WEIGHT_CLASSES: {
    key: 'createWeightClassSetWithoutWeightClasses',
    fallback: 'A weight class set must have at least one weight class',
  },
  WEIGHT_CLASS_RANGE_OVERLAP: {
    key: 'weightClassRangeOverlap',
    fallback: 'Weight class ranges cannot overlap',
  },
}
