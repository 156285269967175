/* eslint-disable jsx-a11y/anchor-is-valid */
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@mui/material'
import React, { FC, ReactElement, useState } from 'react'
import { IconButton } from '../button/IconButton'

interface Props {
  icon: ReactElement
  children?: ReactElement[] | ReactElement
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
}

export const MenuPopperIconButton: FC<Props> = ({ icon, children, color }: Props) => {
  const [menuPopupOpen, setMenuPopupOpen] = useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setMenuPopupOpen(!menuPopupOpen)
  }

  const handleClose = () => {
    setMenuPopupOpen(false)
  }

  return (
    <>
      <IconButton
        color={color}
        ref={anchorRef}
        id='composition-button'
        aria-controls={menuPopupOpen ? 'composition-menu' : undefined}
        aria-expanded={menuPopupOpen ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        data-cy='three-dot-menu-button'
      >
        {icon}
      </IconButton>
      <Popper
        open={menuPopupOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal={false}
        sx={{
          zIndex: 10000,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left bottom' : 'right top',
            }}
          >
            <Paper variant='elevation' elevation={6}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={menuPopupOpen}
                  id='composition-menu'
                  aria-labelledby='composition-button'
                  onClick={handleClose}
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
