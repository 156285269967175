import { Box, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { IVesselItem } from '@planning/rt-stores/vessel/VesselItem'
import { IFilterDelegate, ISortDelegate } from '@planning/stores/PaginatedLocalStore'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { VesselFormDialog } from './components/Organisms/VesselFormDialog'
import { VesselHeader } from './components/Organisms/VesselHeader'
import { VesselList } from './components/Organisms/VesselList'
import { VesselViewStore } from './stores/VesselViewStore'

export const vesselsSortingDelegate: ISortDelegate<IVesselItem> = (sortingModel, a, b) => {
  const sort = a.data.name.localeCompare(b.data.name)

  return sortingModel.isDescending ? -sort : sort
}

export const vesselsFilterDelegate: IFilterDelegate<IVesselItem> = (
  filter: string,
  item: IVesselItem,
) => {
  if (filter) {
    return item.data.name.toLowerCase().includes(filter.toLowerCase())
  }

  return true
}

export const VesselPage = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { vesselItemStore } = usePlanningStore()

  const vesselViewStore = useMemo(() => new VesselViewStore(vesselItemStore), [vesselItemStore])

  const { loading, error } = useAsyncFetch(async () => {
    await vesselItemStore.fetch()
  }, [vesselItemStore])

  if (error) {
    return <Typography variant='h4'>{t('errorLoadingVessels', 'Error loading vessels')}</Typography>
  }

  return (
    <InitializationWrapper isInitializing={loading}>
      <Box
        sx={{
          height: '100%',
          bgcolor: theme.palette.grey[100],
        }}
      >
        <VesselHeader viewStore={vesselViewStore} />

        <VesselList viewStore={vesselViewStore} />

        <VesselFormDialog store={vesselViewStore} />
      </Box>
    </InitializationWrapper>
  )
})
