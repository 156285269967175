import {
  CarrierType,
  ContainerPositionType,
  EquipmentType,
  JobDto,
  OperationType,
  WorkAreaEquipmentBindingTypes,
} from '@operations/app/api'
import { SelectOption } from '@operations/app/models'
import { EquipmentRadioOption } from '@operations/app/models/operator-pages'
import { CargoUnitStore } from '@operations/stores/CargoUnitStore'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { JobStore } from '@operations/stores/JobStore'
import { TenantConfigStore } from '@operations/stores/TenantConfigStore'
import { YardBlockStore } from '@operations/stores/YardBlockStore'
import { AppStore } from '@tom-ui/utils'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { FinishJobDto } from '../models/finish-job.model'
import {
  CreateJobStepperProps,
  DirectLoadOption,
  JobStep,
  JobSteps,
} from '../models/job-stepper.model'
import { isCraneEquipmentType, isServiceLocation } from '../utils'
import { EquipmentOperatorUIStore } from './equipment-operator.ui-store'

export class EquipmentOperatorStepperUIStore {
  isOpen = false
  steps: JobStep[] = []

  hasSlotChanged?: boolean
  selectedYardBlock?: any = null
  selectedJob?: JobDto
  selectedContainerNumber?: SelectOption
  assignedEquipmentId?: number
  isPluggedIn?: boolean
  railcarId?: number | null = null
  grossWeight?: number
  picks?: number
  destinationLocationType?: ContainerPositionType
  directLoadOption?: DirectLoadOption

  containerOrigin?: string

  hasError?: boolean

  isConfirmationDialogOpen = false

  constructor(
    private cargoUnitStore: CargoUnitStore,
    private equipmentStore: EquipmentStore,
    private jobStore: JobStore,
    private yardBlockStore: YardBlockStore,
    private appStore: AppStore,
    private tenantConfigStore: TenantConfigStore,
    public equipmentOperatorUIStore: EquipmentOperatorUIStore,
  ) {
    makeObservable(this, {
      assignedEquipmentId: observable,
      containerOrigin: observable,
      hasSlotChanged: observable,
      isOpen: observable,
      isPluggedIn: observable,
      selectedContainerNumber: observable,
      selectedJob: observable,
      selectedYardBlock: observable,
      steps: observable,
      hasError: observable,
      railcarId: observable,
      grossWeight: observable,
      isConfirmationDialogOpen: observable,
      destinationLocationType: observable,
      directLoadOption: observable,

      assignEquipment: action,
      openStepper: action,
      closeStepper: action,
      nextStep: action,
      previousStep: action,
      plugReeferJob: action,
      setContainerNumberOptionAndOrigin: action,
      setGrossWeight: action,
      setPicks: action,
      setSelectedYardBlock: action,
      setHasError: action,
      setRailcarId: action,
      toggleConfirmationDialog: action,
      setDestinationLocationType: action,
      setDirectLoadOption: action,

      activeStep: computed,
      totalSteps: computed,
      hasNextStep: computed,
      currentJob: computed,
      equipments: computed,
      equipmentAssignableOptions: computed,
      canCurrentEquipmentBeAssignedToNextJob: computed,
      canSkipEquipmentAssignment: computed,
      containerNumber: computed,
      containerCanBePluggedIn: computed,
      cargoUnits: computed,
      displayJourneyForNonNumeric: computed,
      yardBlockOptions: computed,
      containerNeedsToBePluggedOut: computed,
    })
  }

  public async checkIfJobInProgressToOpenStepper() {
    const job = this.equipmentOperatorUIStore.jobs.find(
      x =>
        x.assignedEquipment?.id === this.equipmentOperatorUIStore.selectedEquipmentId &&
        x.cargoUnit?.currentLocation.equipmentId ===
          this.equipmentOperatorUIStore.selectedEquipmentId &&
        this.equipmentOperatorUIStore.typeOfSelectedEquipment !== EquipmentType.Tt,
    )

    if (job && !this.canSkipStepper(job)) {
      await this.openStepper(job)
    }
  }

  public async openStepper(job: JobDto) {
    this.selectedJob = job

    let steps: JobStep[] = []

    if (job.linkedOutboundWorkInstruction) {
      steps = [
        {
          step: JobSteps.directLoad,
          stepNumber: 1,
          active: true,
        },
      ]
    } else {
      const props = this.createPropsForJobStepperFromJobDto(job)
      steps = await this.createStepper(props)
    }

    runInAction(() => {
      this.isOpen = true
      this.steps = steps
    })

    return steps
  }

  public nextStep() {
    this.steps = this.steps.map(x => ({
      ...x,
      active: x.stepNumber === this.activeStep.stepNumber + 1,
    }))
  }

  public previousStep() {
    this.steps = this.steps.map(x => ({
      ...x,
      active: x.stepNumber === this.activeStep.stepNumber - 1,
    }))
  }

  public closeStepper() {
    this.isOpen = false

    this.steps = []
    this.selectedYardBlock = undefined
    this.selectedContainerNumber = undefined
    this.containerOrigin = undefined
    this.assignedEquipmentId = undefined
    this.isPluggedIn = undefined
    this.hasSlotChanged = undefined
    this.grossWeight = undefined
    this.railcarId = null
    // the picks is commented out because we want to retain the number of picks even after closing the stepper
    // it is relevant for billing
    //this.picks = undefined
    this.hasError = undefined
    this.destinationLocationType = undefined
    this.directLoadOption = undefined
  }

  public assignEquipment(id?: number): void {
    if (this.assignedEquipmentId !== id) {
      this.assignedEquipmentId = id
      this.handleOptionStepsOnEquipmentSelection(id)
    }
  }

  public plugReeferJob(isPluggedIn: boolean) {
    if (this.isPluggedIn !== isPluggedIn) {
      this.isPluggedIn = isPluggedIn
    }
  }

  public setPicks(picks?: number): void {
    if (this.picks !== picks) {
      this.picks = picks
    }
  }

  public setSelectedYardBlock(selectedYardBlock?: any) {
    this.hasSlotChanged = true
    if (
      this.selectedYardBlock?.value !== selectedYardBlock?.value ||
      this.selectedYardBlock !== selectedYardBlock
    ) {
      this.selectedYardBlock = selectedYardBlock
    }
  }

  public setContainerNumberOptionAndOrigin(option?: SelectOption, origin?: string) {
    if (this.selectedContainerNumber?.value !== option?.value) {
      let newOption = option

      if (!option && this.selectedJob?.suggestedEmptyContainer?.id) {
        const containerDetails = this.selectedJob.suggestedEmptyContainer
        newOption = {
          value: containerDetails.id,
          label: '',
        }
      }

      this.selectedContainerNumber = newOption ? { ...newOption } : undefined
    }

    if (this.containerOrigin !== origin) {
      this.containerOrigin = origin
    }
  }

  public setGrossWeight(weight?: number) {
    if (this.grossWeight !== weight) {
      this.grossWeight = weight
    }
  }

  public setRailcarId(railcarId?: number) {
    if (this.railcarId !== railcarId) {
      this.railcarId = railcarId
    }
  }

  public toggleConfirmationDialog() {
    this.isConfirmationDialogOpen = !this.isConfirmationDialogOpen
  }

  public setHasError(value?: boolean) {
    if (value !== this.hasError) this.hasError = value
  }

  public setDestinationLocationType(destinationLocationType?: ContainerPositionType) {
    if (this.destinationLocationType !== destinationLocationType) {
      this.destinationLocationType = destinationLocationType
    }
  }

  public async setDirectLoadOption(directLoadOption?: DirectLoadOption) {
    if (this.directLoadOption !== directLoadOption) {
      this.directLoadOption = directLoadOption
      const job = this.selectedJob

      if (
        job &&
        (!isTrailerFlow(job) ||
          this.doesTrailerNeedRailcarSelection(job, directLoadOption === DirectLoadOption.outbound))
      ) {
        const props = this.createPropsForJobStepperFromJobDto(
          job,
          directLoadOption,
          this.activeStep,
        )
        const steps = await this.createStepper(props)

        runInAction(() => {
          this.steps = steps
        })
      }
    }
  }

  async startJob(job: JobDto) {
    await this.jobStore.startJob({
      workInstructionId: job.workInstructionId,
      equipmentId: this.equipmentOperatorUIStore.selectedEquipmentId!,
    })
  }

  async cancelStartJob(workInstructionId: number) {
    const job = this.equipmentOperatorUIStore.jobs.find(
      x => x.workInstructionId === workInstructionId,
    )
    if (
      !job ||
      job.cargoUnit?.currentLocation.equipmentId !==
        this.equipmentOperatorUIStore.selectedEquipmentId
    ) {
      return
    }

    await this.jobStore.cancelStartJob({
      workInstructionId: job.workInstructionId,
      equipmentId: this.equipmentOperatorUIStore.selectedEquipmentId!,
    })
  }

  async finishJobByWorkInstructionId(workInstructionId: number) {
    await this.appStore.triggerRequestWithoutLoader(
      async () =>
        await this.jobStore.finishJob({
          workInstructionId: workInstructionId,
          equipmentId: this.equipmentOperatorUIStore.selectedEquipmentId!,
          isPluggedIn: false,
        }),
    )
  }

  async finishJob(jobDto: FinishJobDto) {
    await this.appStore.triggerRequestWithoutLoader(async () => {
      await this.jobStore.finishJob({
        workInstructionId: jobDto.linkedWorkInstructionId ?? jobDto.workInstructionId,
        equipmentId: jobDto.equipmentId,
        newCargoUnitId: jobDto.selectedContainerNumber
          ? +jobDto.selectedContainerNumber.value
          : undefined,
        nextJobEquipmentId:
          jobDto.assignedEquipmentId !== this.equipmentOperatorUIStore.selectedEquipmentId
            ? jobDto.assignedEquipmentId
            : undefined,
        isPluggedIn: !!jobDto.isPluggedIn,
        grossWeight: jobDto.grossWeight,
        railcarTrackPositionId: jobDto.railcarTrackPositionId,
        picks: jobDto.picks,
        destinationLocationType: jobDto.destinationLocationType,
      })
    })
  }

  public canSkipStepper(job: JobDto) {
    const isNumeric = !!job.cargoUnit?.displayName

    const isNotOnOrigin =
      job.cargoUnit?.currentLocation?.type === ContainerPositionType.Handover ||
      job.cargoUnit?.currentLocation?.type === ContainerPositionType.Berth ||
      job.cargoUnit?.currentLocation?.type === ContainerPositionType.Equipment

    const isNotGoingToDestination =
      job.to.type === ContainerPositionType.Handover ||
      job.to.type === ContainerPositionType.Berth ||
      isServiceLocation(job.to.type)

    const isTrailerAndNotDirectLoad =
      isTrailerFlow(job) &&
      !this.doesTrailerNeedRailcarSelection(job) &&
      job.linkedOutboundWorkInstruction?.carrierVisit?.type !== CarrierType.Train

    const isRestow = job.operationType === OperationType.Restow
    const isRestowOnBerth =
      isRestow && job.cargoUnit?.currentLocation?.type === ContainerPositionType.Berth

    return (
      (!this.isRtgEquipmentType() && isNotOnOrigin && isNotGoingToDestination && isNumeric) ||
      (isCraneEquipmentType(this.equipmentOperatorUIStore.typeOfSelectedEquipment) &&
        (!isRestow || isRestowOnBerth)) ||
      this.isTtEquipmentType() ||
      isTrailerAndNotDirectLoad
    )
  }

  public isTtEquipmentType() {
    return this.equipmentOperatorUIStore.typeOfSelectedEquipment === EquipmentType.Tt
  }

  public isRtgEquipmentType() {
    return this.equipmentOperatorUIStore.typeOfSelectedEquipment === EquipmentType.Rtg
  }

  public isRsEquipmentType(equipmentType: EquipmentType, job: JobDto) {
    return (
      equipmentType === EquipmentType.Rs &&
      (job.to.type === ContainerPositionType.Handover ||
        job.to.type === ContainerPositionType.Berth ||
        isServiceLocation(job.to.type))
    )
  }

  public getDestinationType(workInstructionId: number) {
    return this.jobStore.jobAndYardBlocksDto.jobs.find(
      x => x.workInstructionId === workInstructionId,
    )?.to.type
  }

  public getOriginType(workInstructionId: number) {
    return this.jobStore.jobAndYardBlocksDto.jobs.find(
      x => x.workInstructionId === workInstructionId,
    )?.from.type
  }
  public getContainerNumber(workInstructionId: number) {
    return this.jobStore.jobAndYardBlocksDto.jobs.find(
      x => x.workInstructionId === workInstructionId,
    )?.cargoUnit?.displayName
  }

  public getVisitId(workInstructionId: number) {
    return this.jobStore.jobAndYardBlocksDto.jobs.find(
      x => x.workInstructionId === workInstructionId,
    )?.carrierVisit?.id
  }

  public get activeStep() {
    return this.steps.find(x => x.active) ?? { stepNumber: 1, step: JobSteps.checkInformation }
  }

  public get totalSteps() {
    return this.steps.filter(x => !x.isOptional).length
  }

  public get hasNextStep() {
    return this.steps.some(x => x.stepNumber > this.activeStep.stepNumber && !x.isOptional)
  }

  public get currentJob() {
    if (
      this.directLoadOption === DirectLoadOption.outbound &&
      this.selectedJob?.linkedOutboundWorkInstruction
    ) {
      return {
        ...this.selectedJob,
        ...this.selectedJob.linkedOutboundWorkInstruction,
        to: this.selectedJob.destination,
        workInstructionId: this.selectedJob.workInstructionId,
        order: this.selectedJob.order,
        operationType: OperationType.Outbound,
      }
    }

    return this.selectedJob
  }

  public get containerNumber() {
    if (
      this.selectedContainerNumber &&
      this.selectedContainerNumber.value === this.selectedJob?.suggestedEmptyContainer?.id
    )
      return this.selectedJob?.suggestedEmptyContainer?.number

    return this.selectedContainerNumber
      ? this.cargoUnitStore.items.find(x => x.id === this.selectedContainerNumber?.value)
          ?.containerNumber
      : this.currentJob?.cargoUnit?.displayName
  }

  public get containerCanBePluggedIn() {
    return (
      this.currentJob?.cargoUnit?.isReefer &&
      !this.currentJob?.cargoUnit?.isEmpty &&
      this.currentJob?.to.type === ContainerPositionType.Yard
    )
  }

  public get containerNeedsToBePluggedOut() {
    return (
      this.currentJob?.operationType === OperationType.Outbound &&
      this.currentJob?.cargoUnit?.isPluggedIn
    )
  }

  public get yardBlockOptions(): SelectOption[] {
    return _(
      this.yardBlockStore.items
        .filter(y => !!y.name)
        .map(x => ({
          label: x.name,
          value: x.id,
        })),
    )
      .sortBy(i => i.label)
      .value()
  }

  public get cargoUnits() {
    return _(this.cargoUnitStore.items)
      .sortBy(x => x.containerNumber)
      .value()
  }

  public get railcarTrackPositions() {
    return _(
      this.equipmentOperatorUIStore.railcarTrackPositions[this.currentJob!.carrierVisit!.id!],
    )
      .sortBy(x => x.railcarName)
      .value()
  }

  public get canCurrentEquipmentBeAssignedToNextJob() {
    const currentEquipmentType = this.equipmentOperatorUIStore.typeOfSelectedEquipment

    const isMobileEquipment =
      currentEquipmentType === EquipmentType.Rs || currentEquipmentType === EquipmentType.Ech

    const isStationaryEquipment =
      currentEquipmentType === EquipmentType.Rtg || currentEquipmentType === EquipmentType.Rmg

    const isToYard = this.selectedJob?.to.type === ContainerPositionType.Yard

    return isMobileEquipment || (isStationaryEquipment && isToYard)
  }

  public get equipments() {
    return this.equipmentStore.items
  }

  public get equipmentAssignableOptions(): EquipmentRadioOption[] {
    if (!this.currentJob) {
      return []
    }

    const jobCarrierWaId =
      this.currentJob?.operationType === OperationType.Inbound
        ? this.currentJob?.originWorkAreaId
        : this.currentJob?.destinationWorkAreaId

    const equipmentType = this.equipmentOperatorUIStore.typeOfSelectedEquipment

    const equipments = _(
      this.equipments
        .filter(
          eq =>
            equipmentType !== eq.equipmentType &&
            eq.equipmentType !== EquipmentType.Sts &&
            eq.equipmentType !== EquipmentType.Rtg &&
            eq.equipmentType !== EquipmentType.Fl &&
            eq.equipmentType !== EquipmentType.Rmg &&
            ((this.currentJob?.cargoUnit?.isEmpty && equipmentType !== EquipmentType.Rs) ||
              eq.equipmentType !== EquipmentType.Ech) && //Hide ECH when it is not empty container or current job is taken by RS
            (equipmentType !== EquipmentType.Ech || eq.equipmentType !== EquipmentType.Rs) && //Hide RS when current job is  taken by ECH
            (this.currentJob?.operationType === OperationType.Internal ||
              this.currentJob?.operationType === OperationType.Service ||
              eq.workAreaEquipments?.some(
                wa =>
                  wa.workAreaId === jobCarrierWaId &&
                  wa.assignmentType.includes(
                    this.currentJob?.operationType === OperationType.Inbound
                      ? WorkAreaEquipmentBindingTypes.Discharge
                      : WorkAreaEquipmentBindingTypes.Load,
                  ),
              )),
        )
        .map(
          ({ id, name, equipmentType }) =>
            ({ id: id, title: name, type: equipmentType }) as EquipmentRadioOption,
        ),
    )
      .sortBy(i => i.title)
      .value()

    return equipments
  }

  public get canSkipEquipmentAssignment() {
    return (
      this.canCurrentEquipmentBeAssignedToNextJob &&
      (!this.equipmentAssignableOptions.length ||
        this.currentJob?.destination.yardBlockType === CarrierType.Train)
    )
  }

  public get displayJourneyForNonNumeric() {
    return (
      (this.currentJob?.carrierVisit?.type === CarrierType.Vessel && this.hasToAssignEquipment) ||
      this.currentJob?.carrierVisit?.type === CarrierType.Truck
    )
  }

  public getCurrentFinishJobDto(): FinishJobDto | null {
    if (!this.currentJob) {
      return null
    }

    let orderId = this.currentJob.order?.externalId
    let linkedWorkInstructionId = undefined
    if (this.directLoadOption === DirectLoadOption.outbound) {
      orderId = this.currentJob.linkedOutboundWorkInstruction?.order?.externalId
      linkedWorkInstructionId = this.currentJob.linkedOutboundWorkInstruction?.workInstructionId
    }

    return {
      workInstructionId: this.currentJob.workInstructionId,
      linkedWorkInstructionId: linkedWorkInstructionId,
      equipmentId: this.equipmentOperatorUIStore.selectedEquipmentId!,
      hasSlotChanged: this.hasSlotChanged,
      selectedYardBlock: undefined, //TODO: remove as soon as FinishJob api updated
      selectedContainerNumber: this.selectedContainerNumber,
      assignedEquipmentId: this.assignedEquipmentId,
      isPluggedIn: this.isPluggedIn,
      grossWeight: this.grossWeight,
      railcarId: this.railcarId,
      orderId: orderId ?? undefined,
      picks: this.picks,
      destinationLocationType: this.destinationLocationType,
    }
  }

  public closeStepperWhenNeeded(workInstructionIds: number[]) {
    if (!this.isOpen) return

    if (this.selectedJob && workInstructionIds.includes(this.selectedJob.workInstructionId)) {
      this.closeStepper()
    }
  }

  private get hasToAssignEquipment() {
    return (
      (this.currentJob?.from.type === ContainerPositionType.Yard ||
        this.currentJob?.from.type === ContainerPositionType.Warehouse ||
        this.currentJob?.from.type === ContainerPositionType.Train) &&
      !this.canSkipEquipmentAssignment
    )
  }

  private handleOptionStepsOnEquipmentSelection(id?: number) {
    if (this.equipmentOperatorUIStore.selectedEquipmentId === id) {
      this.steps = [...this.steps.map(x => ({ ...x, isOptional: false }))]
    } else {
      this.steps = [...this.steps.map(x => ({ ...x, isOptional: x.canBeOptional }))]
    }
  }

  private async createStepper({
    fromType,
    toType,
    operationType,
    cargoUnitDisplayName,
    cargoUnitId,
    carrierVisitId,
    carrierVisitType,
    isEmpty,
    hasCoolingOrder,
    currentStep,
    doesTrailerNeedRailcarSelection,
  }: CreateJobStepperProps): Promise<JobStep[]> {
    const steps: JobStep[] = []

    if (currentStep) steps.push(currentStep)

    const addStep = (step: JobSteps, isOptional?: boolean) => {
      steps.push({
        step: step,
        stepNumber: steps.length + 1,
        active: !steps.length,
        isOptional,
        canBeOptional: isOptional,
      })
    }

    const addTrainSteps = async (isOptional?: boolean, hasWeightStep?: boolean) => {
      if (hasWeightStep) {
        if (carrierVisitId) await this.equipmentOperatorUIStore.setWagonWeights(carrierVisitId)
        addStep(JobSteps.confirmWeight, isOptional)
      }

      addStep(JobSteps.confirmWagonNumber, isOptional)
    }

    const addYardSteps = (isOptional?: boolean) => {
      addStep(JobSteps.editDestination, isOptional)

      if (!isEmpty && hasCoolingOrder) {
        addStep(JobSteps.plugReefer, isOptional)
      }
    }

    const addCheckInformationStep = () => {
      if (!currentStep) addStep(JobSteps.checkInformation)
    }

    const removeCheckInformationIfOnlyStep = () => {
      if (steps.some(x => x.step === JobSteps.checkInformation) && steps.length === 1) {
        steps.pop()
      }
    }

    const isJobOptional = () => {
      return (
        steps.some(x => x.step === JobSteps.assignEquipment) &&
        !this.canSkipEquipmentAssignment &&
        this.canCurrentEquipmentBeAssignedToNextJob
      )
    }

    if (doesTrailerNeedRailcarSelection) {
      addStep(JobSteps.confirmWagonNumber)

      return steps
    }

    if (fromType === ContainerPositionType.Yard && !cargoUnitDisplayName) {
      //non numeric

      if (this.selectedJob?.suggestedEmptyContainer?.id) {
        const containerDetails = this.selectedJob.suggestedEmptyContainer
        this.selectedContainerNumber = {
          value: containerDetails.id,
          label: '',
        }
      }

      addStep(JobSteps.selectContainer)
      if (
        (carrierVisitType === CarrierType.Vessel || carrierVisitType === CarrierType.Train) &&
        this.hasToAssignEquipment
      ) {
        addStep(JobSteps.assignEquipment)
      }

      await this.cargoUnitStore.loadEmpties(cargoUnitId)
    } else if (toType === ContainerPositionType.Truck && cargoUnitDisplayName) {
      //container pick up
      if (!currentStep) addStep(JobSteps.confirmPickup)
    } else if (
      fromType === ContainerPositionType.Yard ||
      fromType === ContainerPositionType.Warehouse ||
      fromType === ContainerPositionType.Train
    ) {
      //from yard job
      addCheckInformationStep()

      if (this.canSkipEquipmentAssignment) {
        this.assignedEquipmentId = this.equipmentOperatorUIStore.selectedEquipmentId
      } else {
        addStep(JobSteps.assignEquipment)
      }
    }

    if (toType === ContainerPositionType.Train) {
      //to train pick up
      if (
        this.tenantConfigStore.skipRailTally ||
        this.tenantConfigStore.allowOperatorToSelectRailcar
      ) {
        removeCheckInformationIfOnlyStep()
        const isOptional = isJobOptional()

        await addTrainSteps(isOptional, this.tenantConfigStore.skipRailTally)
      } else if (!steps.length) {
        addCheckInformationStep()
      }
    }

    if (toType === ContainerPositionType.Yard) {
      //to yard job
      removeCheckInformationIfOnlyStep()
      const isOptional = isJobOptional()

      addYardSteps(isOptional)
    }

    if (operationType === OperationType.Restow) {
      //restow job
      addStep(JobSteps.restowDestination)
    }

    return steps
  }

  private createPropsForJobStepperFromJobDto(
    job: JobDto,
    directLoad?: DirectLoadOption,
    activeStep?: JobStep,
  ): CreateJobStepperProps {
    let toType = job.to.type
    let carrierVisit = job.carrierVisit
    let operationType = job.operationType

    const isDirectLoad = directLoad === DirectLoadOption.outbound
    if (job.linkedOutboundWorkInstruction && isDirectLoad) {
      toType = job.linkedOutboundWorkInstruction.destination.type
      carrierVisit = job.linkedOutboundWorkInstruction.carrierVisit
      operationType = OperationType.Outbound
    }

    return {
      fromType: job.from.type,
      toType: toType,
      operationType: operationType,
      cargoUnitDisplayName: job.cargoUnit?.displayName,
      cargoUnitId: job.cargoUnit?.id,
      carrierVisitId: carrierVisit?.id,
      carrierVisitType: carrierVisit?.type,
      isEmpty: job.cargoUnit?.isEmpty,
      hasCoolingOrder: job.hasCoolingOrder,
      currentStep: activeStep,
      doesTrailerNeedRailcarSelection: this.doesTrailerNeedRailcarSelection(job, isDirectLoad),
    }
  }

  private doesTrailerNeedRailcarSelection(job: JobDto, isDirectLoad?: boolean) {
    let goesToTrain = job.destination?.type === ContainerPositionType.Train

    if (isDirectLoad)
      goesToTrain =
        job.linkedOutboundWorkInstruction?.destination.type === ContainerPositionType.Train

    return (
      isTrailerFlow(job) &&
      goesToTrain &&
      (this.tenantConfigStore.skipRailTally || this.tenantConfigStore.allowOperatorToSelectRailcar)
    )
  }
}

const isTrailerFlow = (job: JobDto) =>
  job.origin?.type === ContainerPositionType.TrailerParkingLot ||
  job.destination?.type === ContainerPositionType.TrailerParkingLot
