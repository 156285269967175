import { ContainerResponseDto, IssueResponseDto, ObjectType } from '@planning/app/api'
import { IContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourney'
import { ContainerJourneyDataStore } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { EntityItem } from '@planning/rt-stores/base/EntityItem'
import { ContainerItemStore } from '@planning/rt-stores/container/ContainerItemStore'
import { IEntity } from '@planning/rt-stores/types'
import _ from 'lodash'
import { computed, makeObservable } from 'mobx'
import { IssueItemStore } from './IssueItemStore'

export interface IIssueItem extends IEntity<IssueResponseDto> {
  containerJourney: IContainerJourney | undefined
  container: ContainerResponseDto | undefined
  containsContainerNumber(containerNumber: string): boolean
}

export class IssueItem extends EntityItem<IssueResponseDto> implements IIssueItem {
  constructor(
    public issueItemStore: IssueItemStore,
    public containerJourneyDataStore: ContainerJourneyDataStore,
    public containerItemStore: ContainerItemStore,
    id: number,
    data: IssueResponseDto,
  ) {
    super(id, data)

    makeObservable(this, {
      containerJourney: computed,
      container: computed,
    })
  }

  containsContainerNumber(containerNumber: string): boolean {
    return (
      !!this.containerJourney?.container?.number?.includes(containerNumber) ||
      !!this.container?.number?.includes(containerNumber)
    )
  }

  get containerJourney() {
    switch (this.data.objectType) {
      case ObjectType.Order:
        return this.containerJourneyDataStore.items.find(
          journey =>
            journey.inboundOrderId === this.data.objectId ||
            journey.outboundOrderId === this.data.objectId,
        )
      case ObjectType.Container:
        return this.containerJourneyDataStore.items.find(
          journey => journey.containerId === this.data.objectId,
        )
      default:
        return undefined
    }
  }

  get container() {
    switch (this.data.objectType) {
      case ObjectType.Container:
        return _.values(this.containerItemStore.elements).find(
          container => container.id === this.data.objectId,
        )?.data
      default:
        return undefined
    }
  }
}
