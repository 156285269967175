import { DangerousGoodsDefinitionDto, DangerousGoodsDefinitionsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { axiosUnwrapper } from '@planning/app/utils'
import { DangerousGoodsDefinitionFormProfile } from '@planning/pages/DangerousGoodsDefinitions/utils/dangerousGoodsDefinitionsTableFormUtils'
import { dangerousGoodsDefinitionService } from '@planning/services'
import { DangerousGoodsDefinitionService } from '@planning/services/dangerousGoodsDefinitionService'
import { PaginatedStore } from '@planning/stores/PaginatedStore'

export const createDangerousGoodsDefinitionDataStore = () =>
  new DangerousGoodsDefinitionDataStore(
    createApiClient(DangerousGoodsDefinitionsApi),
    dangerousGoodsDefinitionService,
  )

export class DangerousGoodsDefinitionDataStore extends PaginatedStore<DangerousGoodsDefinitionDto> {
  constructor(
    public httpClient: DangerousGoodsDefinitionsApi,
    private readonly dangerousGoodsDefinitionService: DangerousGoodsDefinitionService,
  ) {
    super(async (currentPageIndex, pageSize, _, filter) => {
      return axiosUnwrapper(httpClient.getAll(currentPageIndex + 1, pageSize, filter))
    })
  }

  public getById(id: number): DangerousGoodsDefinitionDto | undefined {
    return this.pageItems.find(i => i.id === id)
  }

  public async add(dangerousGoodsDefinition: DangerousGoodsDefinitionFormProfile): Promise<void> {
    await this.dangerousGoodsDefinitionService.create(dangerousGoodsDefinition)
    this.loadCurrentPage()
  }

  public async update(
    dangerousGoodsDefinition: DangerousGoodsDefinitionFormProfile,
  ): Promise<void> {
    await this.dangerousGoodsDefinitionService.update(dangerousGoodsDefinition)
    this.loadCurrentPage()
  }

  public async delete(id: number): Promise<void> {
    await this.dangerousGoodsDefinitionService.delete(id)
    this.loadCurrentPage()
  }
}
