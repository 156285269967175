import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CompanyType } from '@planning/app/api'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import { containerHeights, containerTypes } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import {
  CustomInputAdornment,
  useContainerAttributesParameters,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { UnitNumberController } from './UnitNumberController'

export const SwapBodyInfoForm: FC = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { types: containerTypeParameters } = useContainerAttributesParameters()

  const isOrderNewCompanyFieldsActive = useBooleanFlagValue('order-changing-company-fields', false)

  const { formState, control } = useFormContext()

  const { errors } = formState

  return (
    <>
      <Grid item sm={6} xs={12}>
        <UnitNumberController
          control={control}
          errors={errors}
          label={t('swapBodyNo', 'Swap body no.')}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <Controller
          name='operator'
          control={control}
          rules={{
            required: false,
          }}
          render={({ field }) =>
            isOrderNewCompanyFieldsActive ? (
              <CustomerAutoComplete
                label={t('operator', 'Operator')}
                selectedCompanyShortName={field.value}
                dataCy='operator-autocomplete'
                handleOnChange={operator => {
                  field.onChange(operator?.shortName)
                }}
                filterByTypes={[CompanyType.ShippingLine]}
                data-cy='container-operator-field'
              />
            ) : (
              <TextField
                {...field}
                data-cy='container-operator-field'
                label={`${t('operator', 'Operator')}`}
                fullWidth
                variant='outlined'
                type='text'
              />
            )
          }
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <Controller
          name='length'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              data-cy='container-length'
              label={`${t('length', 'Length')}`}
              fullWidth
              variant='outlined'
              type='text'
              name='length'
              onChange={event => field.onChange(event.target.value.toUpperCase())}
              error={!!errors.length}
              helperText={errors.length && t('fieldIsRequired', 'Field is required.')}
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment
                    position='end'
                    color='secondary'
                    padding={theme.customSpacing.l}
                  >
                    ft
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{t('height', 'Height')}</InputLabel>
          <Controller
            name='height'
            control={control}
            render={({ field }) => (
              <Select data-cy='container-height' {...field} label='Height'>
                {containerHeights.map(d => {
                  return (
                    <MenuItem key={d.key} value={d.name}>
                      {d.name}
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>{`${t('unitType', 'Unit Type')}`}</InputLabel>
          <Controller
            name='type'
            control={control}
            render={({ field }) => (
              <Select {...field} label={`${t('unitType', 'Unit Type')} *`} data-cy='container-type'>
                {containerTypes.map(d => {
                  return (
                    <MenuItem key={d.key} value={d.name}>
                      {containerTypeParameters[d.name].label}
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          />
        </FormControl>
      </Grid>
    </>
  )
})
