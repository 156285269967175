import { Button } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, Header } from '@tom-ui/ui'
import { useHistory } from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { ContainerMasterDataFormViewStore } from '../../stores/ContainerMasterDataFormViewStore'

interface Props {
  viewStore: ContainerMasterDataFormViewStore
}

const UnitMasterDataFormPageHeaderActions: FC = observer(() => {
  const { t } = useTranslate()
  const history = useHistory()

  return (
    <>
      <Button
        size='large'
        color='inherit'
        onClick={() => {
          history.goBackHistoryOrDefault('/registers/containers')
        }}
        variant='text'
      >
        {t('cancel', 'Cancel')}
      </Button>
      <Button type='submit' size='large' color='primary' variant='contained'>
        {t('submit', 'Sumbit')}
      </Button>
    </>
  )
})

export const ContainerMasterDataFormPageHeader: FC<Props> = observer(({ viewStore }) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const title = computed(() =>
    viewStore.isEditMode
      ? t('editContainer', 'Edit Container')
      : t('addContainer', 'Add Container'),
  ).get()

  const breadCrumbs = [
    {
      label: t('containers', 'Containers'),
      onClick: () => navigate('/registers/containers'),
    },
    {
      label: title,
    },
  ]

  return (
    <>
      <Breadcrumbs items={breadCrumbs} />
      <Header title={title} rightRenderOption={<UnitMasterDataFormPageHeaderActions />} />
    </>
  )
})
