import { Stack, Typography } from '@mui/material'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { EmptyIndicator } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  truckVisit: ITruckVisitItem
}
export const TruckVisitDriver = observer(({ truckVisit }: Props) => {
  return (
    <Stack>
      <Typography variant='body2'>
        {truckVisit.data.driverName?.length ? truckVisit.data.driverName : EmptyIndicator}
      </Typography>
    </Stack>
  )
})
