import { ContainerVisitStatus } from '@planning/pages/Order/components/OrdersListCard'
import { ColoredLabel } from '@tom-ui/ui'

export const ContainerVisitStatusChip = ({
  status,
  label,
}: {
  status: ContainerVisitStatus
  label: string
}) => {
  const chipColor = () => {
    switch (status) {
      case ContainerVisitStatus.Expected:
        return 'info'
      case ContainerVisitStatus.OnTerminal:
        return 'primary'
      case ContainerVisitStatus.Departed:
        return 'secondary'

      default:
        return 'warning'
    }
  }

  return (
    <ColoredLabel
      color={chipColor()}
      label={label}
      dataCy={'container-journey-status'}
    ></ColoredLabel>
  )
}
