import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import {
  ArchetypeResponseDto,
  CarrierType,
  CarrierVisitStatus,
  CompanyType,
  RailVisitResponseDto,
  VesselDto,
  VesselVisitDto,
} from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import { RailVisitAutoComplete } from '@planning/components/visit/RailVisitAutoComplete'
import { carrierVisitTypes } from '@planning/constants'
import { railVisitService, vesselService, vesselVisitService } from '@planning/services'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NnrOrderControlViewStore, NnrOrderFormData } from '../../Stores/NnrOrderControlViewStore'
import { ControlledArchetypeInputWithDetails } from './ControlledArchetypeInputWithDetails/ControlledArchetypeInputWithDetails'

interface Props {
  viewStore: NnrOrderControlViewStore
  onSubmit: (data: NnrOrderFormData) => void
}

export const NNROrderForm: FC<Props> = observer(({ viewStore, onSubmit }) => {
  const { isoCodeMappingInputStore } = usePlanningStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const isOrderNewCompanyFieldsActive = useBooleanFlagValue('order-changing-company-fields', false)

  const form = useForm<NnrOrderFormData>({
    defaultValues: {
      archetypeId: undefined,
      referenceNumber: undefined,
      shippingLine: undefined,
      amount: undefined,
      modeOfTransport: undefined,
      carrierVisitId: undefined,
      containerIsoCode: undefined,
    },
  })

  const { control, register, getValues, reset, watch, handleSubmit, formState } = form
  const { errors } = formState

  const [selectedArchetype, _] = useState<ArchetypeResponseDto | undefined>(
    viewStore.selectedArchetype,
  )

  // todo: [north] will replace once there is vesselItemStore
  const [vessels, setVessels] = useState<VesselDto[]>([])
  const [selectedVisit, setSelectedVisit] = useState<VesselVisitDto | RailVisitResponseDto | null>(
    null,
  )

  useAsyncFetch(async () => {
    if (viewStore.selectedNnrOrder) {
      const {
        archetypeId,
        referenceNumber,
        shippingLine,
        amount,
        modeOfTransport,
        carrierVisitId,
        containerIsoCode,
      } = viewStore.selectedNnrOrder.data

      reset({
        archetypeId,
        referenceNumber,
        shippingLine,
        amount,
        modeOfTransport,
        carrierVisitId,
        containerIsoCode,
      })

      if (carrierVisitId) {
        let visit = null
        if (modeOfTransport === CarrierType.Train) {
          visit = await railVisitService.getById(carrierVisitId)
        } else if (modeOfTransport === CarrierType.Vessel) {
          visit = await vesselVisitService.getById(carrierVisitId)
        }

        setSelectedVisit(visit)
      }
    }
  }, [reset, viewStore.selectedNnrOrder])

  // [Review] TODO: Why all the state here? Would be better to use FormStores.
  const watchModeOfTransport = watch('modeOfTransport')

  const fetch = async (filter?: string) => {
    return (await vesselVisitService.get(1, 10, filter)).payload ?? []
  }

  const onSearchFilterChange = debounce(async (filter?: string) => {
    setOptions(await fetch(filter))
  }, 500)

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<VesselVisitDto[]>([])
  const [isIsoCodeToggled, setIsIsoCodeToggled] = useState(false)

  useEffect(() => {
    if (open) {
      fetch().then(payload => setOptions(payload))
    } else {
      setOptions([])
    }
  }, [open])

  useEffect(() => {
    vesselService.get(1, 1000).then(v => setVessels(v.payload))
  }, [])

  return (
    <form id='nnr-order-form' onSubmit={handleSubmit(onSubmit)} autoComplete='off' noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='referenceNumber'
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t('referenceNumber', 'Reference Number')}
                variant='outlined'
                data-cy='create-nnr-form-reference'
                error={!!errors.referenceNumber}
                helperText={errors.referenceNumber ? 'Field is required.' : ''}
                sx={{ marginTop: '1rem' }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {isOrderNewCompanyFieldsActive ? (
            <Controller
              control={control}
              name='shippingLine'
              render={({ field }) => (
                <CustomerAutoComplete
                  label={t('operator', 'Operator')}
                  selectedCompanyShortName={field.value}
                  handleOnChange={operator => {
                    field.onChange(operator?.shortName)
                  }}
                  filterByTypes={[CompanyType.ShippingLine]}
                  dataCy='operator-autocomplete'
                  required
                  error={!!errors.shippingLine}
                  helperText={errors.shippingLine ? 'Field is required.' : ''}
                />
              )}
            />
          ) : (
            <TextField
              fullWidth
              label={t('operator', 'Operator')}
              variant='outlined'
              {...register('shippingLine', { required: true })}
              name='shippingLine'
              data-cy='create-nnr-form-operator'
              sx={{ marginTop: '1rem' }}
              error={!!errors.shippingLine}
              helperText={errors.shippingLine ? 'Field is required.' : ''}
            />
          )}
        </Grid>
      </Grid>

      <Typography variant='h4' sx={{ marginTop: '1rem' }}>
        {t('modeOfTransport', 'Mode of transport')}
      </Typography>
      <Controller
        control={control}
        name={`modeOfTransport`}
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <FormControl fullWidth sx={{ marginTop: '1rem' }}>
            <InputLabel sx={{ verticalAlign: 'center' }}>
              {t('modeOfTransport', 'Mode of transport')}
            </InputLabel>
            <Select
              label={t('modeOfTransport', 'Mode of transport')}
              required
              value={getValues(`modeOfTransport`) ?? ''}
              onChange={onChange}
              data-cy='create-nnr-form-transport'
              error={!!errors.modeOfTransport}
            >
              {carrierVisitTypes.map(d => {
                return (
                  <MenuItem key={d.key} value={d.name}>
                    {d.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
      />
      {watchModeOfTransport === CarrierType.Vessel && (
        <Controller
          control={control}
          name={`carrierVisitId`}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Autocomplete
              disablePortal
              value={selectedVisit}
              getOptionLabel={(vv: VesselVisitDto) => {
                const vessel = vessels.find(
                  v => vv.carrierIds.includes(v.id) && vv.status !== CarrierVisitStatus.Departed,
                )
                const voyageNumber = vessel?.imoNumber ?? vessel?.mmsi ?? vessel?.callSign
                const eta_ata = vv.eta ?? vv.ata
                return `${vessel?.name} - ${voyageNumber} - ${formatDateTime(eta_ata)}`
              }}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              options={options}
              isOptionEqualToValue={(option: VesselVisitDto, value: VesselVisitDto) =>
                option.id === value.id
              }
              onChange={(_, selectedVesselVisit: VesselVisitDto | null) => {
                if (selectedVesselVisit) {
                  onChange(selectedVesselVisit.id)
                  setSelectedVisit(selectedVesselVisit)
                }
              }}
              data-cy='vessel-autocomplete'
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('vessel', 'Vessel')}
                  helperText={errors.carrierVisitId ? 'Field is required.' : ''}
                  error={!!errors.carrierVisitId}
                  onChange={async (event: any) => {
                    onSearchFilterChange(event.target.value)
                  }}
                />
              )}
              sx={{ marginTop: '1rem' }}
            />
          )}
        />
      )}
      {watchModeOfTransport === CarrierType.Train && (
        <Box sx={{ marginTop: '1rem' }}>
          <Controller
            control={control}
            name={`carrierVisitId`}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <RailVisitAutoComplete
                selectedRailVisit={selectedVisit as RailVisitResponseDto}
                handleOnChange={visit => {
                  if (visit) {
                    onChange(visit.id)
                    setSelectedVisit(visit)
                  }
                }}
                error={!!errors.carrierVisitId}
                helperText={errors.carrierVisitId ? t('fieldIsRequired', 'Field is required.') : ''}
                cargoType='Container'
              />
            )}
          />
        </Box>
      )}

      <Typography variant='h4' sx={{ marginTop: '1rem' }}>
        {t('containerRequirements', 'Container requirements')}
      </Typography>

      <Chip
        label={t('archetype', 'Archetype')}
        sx={{ m: '1rem 1rem 1rem 0' }}
        variant={isIsoCodeToggled ? 'outlined' : undefined}
        data-cy='create-nnr-form-toggle-containerAttributes'
        onClick={() => setIsIsoCodeToggled(false)}
      />

      <Chip
        label={t('isoCode', 'ISO Code')}
        sx={{ mt: '1rem', mb: '1rem' }}
        variant={!isIsoCodeToggled ? 'outlined' : undefined}
        data-cy='create-nnr-form-toggle-isocode'
        onClick={() => setIsIsoCodeToggled(true)}
      />
      <Grid container>
        <Grid item xs={12}>
          {isIsoCodeToggled ? (
            <>
              <Typography
                variant='body2'
                color={theme.palette.text.secondary}
                sx={{ marginBottom: '1rem' }}
              >
                {t(
                  'systemWillAlwaysMatchContainersToTheArchetypeNotTheExactISOCode',
                  'System will always match containers to the archetype, not the exact ISO code!',
                )}
              </Typography>
              {/* [Review] TODO: Stores should concerned with state and state manipulation, not to render components.*/}
              {/* The same can be achieved by just passing a function from the host module. */}
              {isoCodeMappingInputStore.renderIsoCodeMappingInput?.({
                label: `${t('isoCode', 'ISO Code')}`,
                fullWidth: true,
                variant: 'outlined',
                type: 'text',
                uppercase: true,
                formState: formState,
                name: 'containerIsoCode',
                required: true,
                control: control,
                showArchetype: true,
                showArchetypeAttributes: true,
                inputSize: 2,
                setValue: (value: string) => form.setValue('containerIsoCode', value),
                watch: watch,
                onChange: archetypeIsoCodeCharacteristics => {
                  form.setValue('archetypeId', archetypeIsoCodeCharacteristics?.archetype.id ?? -1)
                },
              })}
            </>
          ) : (
            <ControlledArchetypeInputWithDetails
              name='archetypeId'
              required={true}
              arquetype={selectedArchetype}
              control={control}
              formState={formState}
              inputSize={2}
            />
          )}
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label={t('quantity', 'Quantity')}
              data-cy='create-nnr-form-amount'
              variant='outlined'
              type='number'
              {...register('amount', { required: true, valueAsNumber: true })}
              name='amount'
              error={!!errors.amount}
              helperText={errors.amount ? 'Field is required.' : ''}
              sx={{ marginTop: '1rem' }}
              onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
              inputProps={{ min: 1 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
})
