import { IssueResponseDto, IssueStatus, ObjectType } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetIssuesQuery } from '@planning/messages/queries/getIssuesQueryHandler'
import { ContainerJourneyDataStore } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { ItemStore } from '@planning/rt-stores/base/ItemStore'
import { ContainerItemStore } from '@planning/rt-stores/container/ContainerItemStore'
import { IEntityStore } from '@planning/rt-stores/types'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { IIssueItem, IssueItem } from './IssueItem'

export class IssueItemStore
  extends ItemStore<IssueResponseDto, IIssueItem>
  implements IEntityStore<IIssueItem>
{
  private hasBeenLoaded = false

  constructor(
    private messageBus: IMessageBus,
    private containerJourneyDataStore: ContainerJourneyDataStore,
    private containerItemStore: ContainerItemStore,
  ) {
    super(
      (key, data) => new IssueItem(this, containerJourneyDataStore, containerItemStore, key, data),
    )
    makeObservable(this, {
      issues: computed,
      notResolvedItemsWithObjectTypeOrder: computed,
      notResolvedItemsWithObjectTypeContainer: computed,

      receiveIssuesMessage: action,
      receiveIssueUpsertedEventMessage: action,
    })

    messageBus.subscribeEvent(GetIssuesQuery.type, this.receiveIssuesMessage)
    messageBus.subscribeEvent(EventTypes.IssueUpsertedEvent, this.receiveIssueUpsertedEventMessage)
  }

  fetch = async () => {
    if (this.hasBeenLoaded) return

    this.hasBeenLoaded = true

    await this.messageBus.dispatchQuery(new GetIssuesQuery())
  }

  get notResolvedItemsWithObjectTypeOrder() {
    return this.getIssuesByObjectTypes([ObjectType.Order], true)
  }

  get notResolvedItemsWithObjectTypeContainer() {
    return this.getIssuesByObjectTypes([ObjectType.Container], true)
  }

  receiveIssuesMessage = (event: IEvent<IssueResponseDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveIssueUpsertedEventMessage = (event: IEvent<IssueResponseDto>) => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  get issues(): IssueResponseDto[] {
    return this.getIssuesByObjectTypes([ObjectType.Order, ObjectType.Container]).map(i => i.data)
  }

  private getIssuesByObjectTypes(objectTypes: ObjectType[], notResolved?: boolean): IIssueItem[] {
    return _(this.elements)
      .filter(
        i =>
          objectTypes.includes(i.data.objectType) &&
          (!notResolved || i.data.issueStatus !== IssueStatus.Resolved),
      )
      .value()
  }
}
