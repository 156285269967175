import { Chip } from '@mui/material'
import { Stack } from '@mui/system'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { TruckVisitsViewStore } from '../../Stores/TruckVisitsViewStore'

interface Props {
  store: TruckVisitsViewStore
}

export const TruckTimeRange = observer(({ store }: Props) => {
  const { t } = useTranslate()

  const [startTime, setStartTime] = useState(store.timeRangeStart)
  const [endTime, setEndTime] = useState(store.timeRangeEnd)
  const [error, setError] = useState(false)

  const onApply = () => {
    if (!startTime && !endTime) {
      store.clearTimeRange()
    } else {
      const from = startTime ?? dayjs().startOf('date').toDate()
      const to = endTime ?? dayjs().startOf('date').add(1, 'd').toDate()
      store.setTimeRange(from, to)
    }

    store.toggleTimeRangeDialog(false)
  }

  const onClear = () => {
    setStartTime(undefined)
    setEndTime(undefined)
    store.clearTimeRange()
    store.toggleTimeRangeDialog(false)
  }

  const setToLastXHours = (hours: number) => {
    const now = new Date()

    setStartTime(new Date(now.setHours(now.getHours() - hours)))
    setEndTime(new Date())
  }

  const setToLastHour = () => {
    setToLastXHours(1)
  }

  const setToLast2Hours = () => {
    setToLastXHours(2)
  }

  return (
    <ConfirmationDialog
      open={store.isTimeRangeDialogOpen}
      maxWidth='sm'
      primaryActionDisabled={error}
      title={t('selectTimeRange', 'Select time range')}
      primaryActionText={t('apply', 'Apply')}
      closeLabel={t('clear', 'Clear')}
      onClose={onClear}
      onConfirm={onApply}
      hasBorderTop
    >
      <Stack>
        <Stack id='time-range-form' gap={1} mb={2} mt={1}>
          <Stack flexDirection='row' gap={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={startTime ? dayjs(startTime) : null}
                label={t('from', 'From')}
                onError={error => setError(error != null)}
                onChange={data => {
                  setStartTime(data?.toDate())
                }}
                maxTime={endTime ? dayjs(endTime) : null}
              />

              <TimePicker
                value={endTime ? dayjs(endTime) : null}
                label={t('to', 'To')}
                onError={error => setError(error != null)}
                onChange={data => {
                  setEndTime(data?.toDate())
                }}
                minTime={startTime ? dayjs(startTime) : null}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>
        <Stack flexDirection='row' gap={2}>
          <Chip color='secondary' label={t('lastHour', 'Last hour')} onClick={setToLastHour} />
          <Chip
            color='secondary'
            label={t('last2Hours', 'Last 2 hours')}
            onClick={setToLast2Hours}
          />
        </Stack>
      </Stack>
    </ConfirmationDialog>
  )
})
