import { ArchetypeStore } from '@admin/stores/Archetype.store'
import { action, computed, makeObservable, observable } from 'mobx'

export class ArchetypeUIStore {
  filter = ''

  constructor(public archetypeStore: ArchetypeStore) {
    makeObservable(this, {
      filter: observable,

      archetypes: computed,

      setFilter: action,
    })
  }

  get archetypes() {
    const filteredItems = this.archetypeStore.items.filter(item => {
      const upperCaseFilter = this.filter?.toLocaleUpperCase()

      return item.name?.toLocaleUpperCase().includes(upperCaseFilter)
      // || item.isoCodes?.some(isocode => isocode.toLocaleUpperCase().includes(upperCaseFilter))
    })

    return filteredItems
  }

  setFilter = (filter: string) => (this.filter = filter)
}
