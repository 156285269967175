import {
  CarrierType,
  CarrierVisitsApi,
  ContainerSlotDto,
  ContainerSlotPositionType,
  OperationType,
  VesselBayDto,
  VesselBaySlotDto,
  VesselBayType,
  VesselGeometryDto,
} from '@operations/app/api'
import { CarrierVisitRefreshEventPayload } from '@operations/app/api/signalRDtos/carrierVisitRefreshEventPayload'
import { CarrierVisitWorkInstructionUpsertedPayload } from '@operations/app/api/signalRDtos/carrierVisitWorkInstructionUpsertedPayload'
import { WorkInstructionConfirmedEventPayload } from '@operations/app/api/signalRDtos/workInstructionConfirmedEventPayload'
import { WorkInstructionDeletedEventPayload } from '@operations/app/api/signalRDtos/workInstructionDeletedEventPayload'
import { createApiClient } from '@operations/app/http-client'
import {
  getRelatedBays,
  getRelatedEvenBay,
  is20Ft,
  isJobAssignedByEquipment,
} from '@operations/features/craneOperator/utils'
import { EventTypes } from '@operations/messages/eventsTypes'
import { IEvent, IMessageBus } from '@operations/messages/messageBus'
import { AppStore } from '@tom-ui/utils'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { BayLegendPortsToLoad } from '../components/Legend/legend.model'

export class CraneOperatorUIStore {
  loadingSignalR = false
  selectedVesselVisitId?: number
  selectedEquipmentId?: number

  vesselGeometry?: VesselGeometryDto

  itemViewCurrentBayNumber?: number
  itemViewOperationType?: OperationType

  constructor(
    private messageBus: IMessageBus,
    private appStore: AppStore,
  ) {
    makeObservable(this, {
      vesselGeometry: observable,

      itemViewCurrentBayNumber: observable,
      itemViewOperationType: observable,

      setItemViewOperationType: action,
      setItemViewCurrentBayNumber: action,

      loadVesselGeometry: action,

      inboundBayViewsGroup: computed,
      outboundBayViewsGroup: computed,

      itemViewGeometry: computed,
      itemViewBayNumbers: computed,
      itemViewGetNextBay: computed,
      itemViewGetPreviousBay: computed,
      itemViewGeometryLoadPorts: computed,
      itemViewAvailableOperationTypes: computed,

      loadPorts: computed,
      workStatusPerBay: computed,
    })

    this.messageBus.subscribeEvent(
      EventTypes.CarrierVisitRefresh,
      this.reloadVesselGeometryBySignalR,
    )

    this.messageBus.subscribeEvent(
      EventTypes.CarrierVisitWorkInstructionUpserted,
      async (res: IEvent<CarrierVisitWorkInstructionUpsertedPayload>) => {
        if (res.payload?.carrierVisitId === this.selectedVesselVisitId) await this.reloadVisit()
      },
    )

    this.messageBus.subscribeEvent(EventTypes.WorkInstructionDeleted, this.deleteWorkInstructions)

    this.messageBus.subscribeEvent<WorkInstructionConfirmedEventPayload>(
      EventTypes.WorkInstructionConfirmed,
      this.confirmWorkInstruction,
    )
  }

  public setItemViewCurrentBayNumber(bay?: number): void {
    if (this.itemViewCurrentBayNumber !== bay) {
      this.itemViewCurrentBayNumber = bay
    }
  }

  public setItemViewOperationType(opType: OperationType): void {
    if (this.itemViewOperationType !== opType) {
      this.itemViewOperationType = opType
    }
  }

  public get loadPorts(): BayLegendPortsToLoad[] | undefined {
    return this.vesselGeometry?.portsToDischarge.map((item, index) => {
      return {
        name: item,
        index: index,
      }
    })
  }

  public get itemViewGeometry(): VesselBayDto[] | undefined {
    if (this.itemViewCurrentBayNumber && this.itemViewOperationType) {
      return getBaysForItemView(this.vesselGeometry?.bays, this.itemViewCurrentBayNumber)
    }

    return undefined
  }

  public get inboundBayViewsGroup() {
    return groupByEvenBay(this.vesselGeometry?.bays, OperationType.Inbound)
  }

  public get outboundBayViewsGroup() {
    return groupByEvenBay(this.vesselGeometry?.bays, OperationType.Outbound)
  }

  public get itemViewBayNumbers(): number[] {
    if (!this.itemViewOperationType) return []
    return this.itemViewOperationType === OperationType.Inbound
      ? this.inboundBayViewsGroup.map((_, key) => Number(key)).value()
      : this.outboundBayViewsGroup.map((_, key) => Number(key)).value()
  }

  public get itemViewGetNextBay(): number | undefined {
    if (!this.itemViewCurrentBayNumber) return undefined
    return getNextBay(this.itemViewBayNumbers, this.itemViewCurrentBayNumber)
  }

  public get itemViewGetPreviousBay(): number | undefined {
    if (!this.itemViewCurrentBayNumber) return undefined
    return getPreviousBay(this.itemViewBayNumbers, this.itemViewCurrentBayNumber)
  }

  public get itemViewAvailableOperationTypes(): OperationType[] {
    return getItemViewAvailableOperationTypes(this.itemViewGeometry)
  }

  public get itemViewGeometryLoadPorts(): BayLegendPortsToLoad[] | undefined {
    return getItemViewBayLegendPortsToLoad(this.itemViewGeometry, this.loadPorts)
  }

  public itemBayContain(filter: (slot: VesselBaySlotDto) => boolean): boolean {
    return baysContain(filter, this.itemViewGeometry)
  }

  public vesselBaysContain(filter: (slot: VesselBaySlotDto) => boolean): boolean {
    return baysContain(filter, this.vesselGeometry?.bays)
  }

  public itemBayhasContainer(filter: (container?: ContainerSlotDto | null) => boolean) {
    return hasContainerInBays(filter, this.itemViewGeometry, this.itemViewOperationType)
  }

  public vesselBayshasContainer(filter: (container?: ContainerSlotDto | null) => boolean) {
    return hasContainerInBays(filter, this.vesselGeometry?.bays)
  }

  public isOtherCrane(container?: ContainerSlotDto | null): boolean {
    return (
      !!this.selectedEquipmentId &&
      !!container?.workInstruction &&
      !isJobAssignedByEquipment(container, this.selectedEquipmentId)
    )
  }

  public get workStatusPerBay() {
    return getWorkStatusPerBay(this.vesselGeometry?.bays)
  }

  deleteWorkInstructions = async (res: IEvent<WorkInstructionDeletedEventPayload>) => {
    if (
      this.selectedVesselVisitId &&
      res.payload?.carrierVisitIds.includes(this.selectedVesselVisitId)
    ) {
      await this.reloadVisit()
    }
  }

  reloadVesselGeometryBySignalR = async (res: IEvent<CarrierVisitRefreshEventPayload>) => {
    //refresh only if event update is about everything(carrierVisits is null) or about current visit
    if (
      res.payload?.carrierVisits?.length &&
      !res.payload?.carrierVisits.find(
        c =>
          c.carrierType === CarrierType.Vessel && c.carrierVisitId === this.selectedVesselVisitId,
      )
    )
      return

    await this.reloadVisit()
  }

  confirmWorkInstruction = (res: IEvent<WorkInstructionConfirmedEventPayload>) => {
    if (!res.payload?.containerStowageSlot) {
      return
    }

    const currentBayNumber = res.payload?.containerStowageSlot?.bay
    const bayType =
      this.vesselGeometry?.bays.find(b => b.bay === currentBayNumber)?.type ?? VesselBayType.Forty

    const relatedBays = getRelatedBays(res.payload?.containerStowageSlot.bay, bayType)

    const vesselBayDtos = this.vesselGeometry?.bays.filter(x => relatedBays.includes(x.bay))

    if (!vesselBayDtos) {
      return
    }

    const slots = vesselBayDtos.flatMap(vesselBayDto => {
      return res.payload!.containerStowageSlot!.tier >= vesselBayDto.deckStartedAt
        ? vesselBayDto.deckSlots
        : vesselBayDto.holdSlots
    })

    const vesselBaySlotDtos = slots.filter(
      x =>
        x.tier === res.payload?.containerStowageSlot!.tier &&
        x.row === res.payload?.containerStowageSlot.row,
    )

    const containerSlots = vesselBaySlotDtos
      .map(vesselBaySlotDto => {
        return res.payload?.operationType === OperationType.Inbound
          ? vesselBaySlotDto?.inboundContainer
          : vesselBaySlotDto?.outboundContainer
      })
      .filter(x => x && !!x.workInstruction && x.workInstruction.id === res.payload?.id)

    if (containerSlots.length > 0) {
      runInAction(() => {
        containerSlots.forEach(x => (x!.workInstruction!.confirmed = true))
      })
    }
  }

  loadVesselGeometry = async () => {
    if (!this.selectedVesselVisitId) return
    try {
      const { data } = await createApiClient(CarrierVisitsApi).getVesselBay(
        this.selectedVesselVisitId,
      )
      runInAction(() => {
        this.vesselGeometry = data
      })
    } catch (error) {
      runInAction(() => {
        this.vesselGeometry = undefined
      })
    }
  }

  private async reloadVisit() {
    if (!this.loadingSignalR) {
      this.loadingSignalR = true

      await this.appStore.triggerReloadBySignalR(
        this.loadVesselGeometry,
        '/operatorViews/Crane/BayView/',
      )

      this.loadingSignalR = false
    }
  }
}

//Tested Logics:
export const groupByEvenBay = (bays: VesselBayDto[] | undefined, operationType: OperationType) => {
  return _(
    bays?.filter(
      b =>
        b.deckSlots.find(d =>
          operationType === OperationType.Inbound ? d.inboundContainer : d.outboundContainer,
        ) ??
        b.holdSlots.find(d =>
          operationType === OperationType.Inbound ? d.inboundContainer : d.outboundContainer,
        ),
    ),
  ).groupBy(v => getRelatedEvenBay(v.bay, v.type))
}

export const getNextBay = (bayNumbers: number[], currentBay: number): number | undefined => {
  return [...bayNumbers].sort((a, b) => a - b).find(i => i > currentBay)
}

export const getPreviousBay = (bayNumbers: number[], currentBay: number): number | undefined => {
  return [...bayNumbers].sort((a, b) => b - a).find(i => i < currentBay)
}

export const getBaysForItemView = (
  vesselBays: VesselBayDto[] | undefined,
  currentBayNumber: number,
) => {
  const type = vesselBays?.find(b => b.bay === currentBayNumber)?.type ?? VesselBayType.Forty
  const relatedBays = getRelatedBays(currentBayNumber, type)

  const [bays, _] = filterDuplicatedTwenties(
    vesselBays?.filter(b => relatedBays.some(rb => rb === b.bay)) ?? [],
  )
  return bays
}

export const filterDuplicatedTwenties = (
  bays: VesselBayDto[],
): [VesselBayDto[], string | undefined] => {
  if (hasContainerInBays(is20Ft, bays) || bays.length === 0) return [bays, undefined]

  const relatedEvenBay = getRelatedEvenBay(bays[0].bay, bays[0].type)

  return [bays.filter(b => b.bay < relatedEvenBay), relatedEvenBay.toString()]
}

export const hasContainerInBays = (
  filter: (container?: ContainerSlotDto | null) => boolean,
  bays?: VesselBayDto[],
  operation?: OperationType,
): boolean => {
  return baysContain(
    s =>
      (operation != OperationType.Outbound && filter(s.inboundContainer)) ||
      (operation != OperationType.Inbound && filter(s.outboundContainer)) ||
      filter(s.remainOnBoardContainer) ||
      filter(s.nonNumeric),
    bays,
  )
}

export const baysContain = (
  filter: (slot: VesselBaySlotDto) => boolean,
  bays?: VesselBayDto[],
): boolean => {
  return (
    bays?.some(b => b.holdSlots.some(s => filter(s)) || b.deckSlots.some(s => filter(s))) ?? false
  )
}

export const getItemViewBayLegendPortsToLoad = (
  itemViewGeometry: VesselBayDto[] | undefined,
  loadPorts: BayLegendPortsToLoad[] | undefined,
) => {
  let bayLoadPortIndexes: number[] = []

  itemViewGeometry?.forEach(x => {
    const baySlots = _.union(x.deckSlots, x.holdSlots)

    const indexes = _.uniq(
      baySlots.filter(x => x.outboundContainer).map(x => x.outboundContainer!.portOfDischargeIndex),
    )

    bayLoadPortIndexes = _.union(bayLoadPortIndexes, indexes)
  })

  return loadPorts?.filter(x => bayLoadPortIndexes.includes(x.index))
}

export const getItemViewAvailableOperationTypes = (
  itemViewGeometry: VesselBayDto[] | undefined,
): OperationType[] => {
  const hasInbound = itemViewGeometry?.find(
    i => i.deckSlots.find(d => d.inboundContainer) ?? i.holdSlots.find(d => d.inboundContainer),
  )
  const hasOutbound = itemViewGeometry?.find(
    i => i.deckSlots.find(d => d.outboundContainer) ?? i.holdSlots.find(d => d.outboundContainer),
  )

  const operationTypes = []

  if (hasInbound) operationTypes.push(OperationType.Inbound)
  if (hasOutbound) operationTypes.push(OperationType.Outbound)

  return operationTypes as OperationType[]
}

export const getWorkStatusPerBay = (bays?: VesselBayDto[]) => {
  return bays?.map(x => {
    const baySlots = _.union(x.deckSlots, x.holdSlots)

    const operationStatus = [
      getOperationStatus(baySlots, OperationType.Inbound),
      getOperationStatus(baySlots, OperationType.Outbound),
    ]

    return {
      bay: x.bay,
      operationStatus,
    }
  })
}

const getOperationStatus = (slots: VesselBaySlotDto[], operationType: OperationType) => {
  const operationContainers = slots
    .filter(x =>
      operationType === OperationType.Inbound ? x.inboundContainer : x.outboundContainer,
    )
    .map(x =>
      operationType === OperationType.Inbound ? x.inboundContainer! : x.outboundContainer!,
    )
    .filter(x => x.containerPositionType !== ContainerSlotPositionType.FortyBack)

  return {
    operationType: operationType,
    total: operationContainers.length,
    finished: operationContainers.filter(x => x?.workInstruction?.confirmed === true).length,
  }
}
