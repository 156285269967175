import { useOperationsStore } from '@operations/AppProvider'
import { EquipmentType } from '@operations/app/api'
import { getEquipmentTypesSelectedByPage } from '@operations/app/models/operator-pages'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { SelectedEquipmentHelmet } from '../components/SelectEquipment/SelectedEquipmentHelmet'
import { EquipmentOperatorAlertContainer } from './EquipmentOperatorAlertContainer'
import { EquipmentOperatorJobListContainer } from './EquipmentOperatorJobListContainer'

export const EquipmentOperatorContainer = observer(() => {
  const { page, equipmentId } = useParams()
  const equipmentTypesSelected = getEquipmentTypesSelectedByPage(page)

  const {
    yardBlockStore,
    equipmentStore,
    equipmentOperatorUIStore,
    equipmentOperatorNotificationUIStore,
    equipmentOperatorLandingUIStore,
    equipmentOperatorStepperUIStore,
  } = useOperationsStore()

  useEffect(() => {
    equipmentOperatorUIStore.setIsContainerOperation(true)

    const loadPageData = async () => {
      equipmentOperatorNotificationUIStore.clearNotifications()

      equipmentOperatorLandingUIStore.selectEquipmentTypes(equipmentTypesSelected)

      const requests = [equipmentOperatorUIStore.selectEquipment(Number(equipmentId))]

      if (
        equipmentTypesSelected.includes(EquipmentType.Rs) ||
        equipmentTypesSelected.includes(EquipmentType.Rtg) ||
        equipmentTypesSelected.includes(EquipmentType.Ech) ||
        equipmentTypesSelected.includes(EquipmentType.Sts) ||
        equipmentTypesSelected.includes(EquipmentType.Rmg)
      ) {
        requests.push(yardBlockStore.loadYardBlocks(), equipmentStore.load())
      } else {
        requests.push(equipmentStore.loadOne(Number(equipmentId)))
      }

      if (page !== 'all' && equipmentTypesSelected.includes(EquipmentType.Tt))
        equipmentOperatorUIStore.setMinimalisticJobView(false)

      await Promise.all(requests)
      equipmentOperatorStepperUIStore.checkIfJobInProgressToOpenStepper()

      equipmentOperatorUIStore.selectVesselIfCraneOperator()

      equipmentOperatorUIStore.setDefaultTypeAndArea()
    }

    loadPageData()
  }, [
    equipmentTypesSelected,
    equipmentStore,
    equipmentId,
    equipmentOperatorLandingUIStore,
    equipmentOperatorStepperUIStore,
    equipmentOperatorUIStore,
    equipmentOperatorNotificationUIStore,
    yardBlockStore,
  ])

  return (
    <>
      <SelectedEquipmentHelmet />
      <EquipmentOperatorJobListContainer />
      <EquipmentOperatorAlertContainer />
    </>
  )
})
