import { TextFieldProps } from '@mui/material'
import { ArchetypeResponseDto, ContainerCharacteristics } from '@planning/app/api'
import { FieldValues, FormState, UseControllerProps, UseFormWatch } from 'react-hook-form'

type FieldProps = Pick<
  TextFieldProps,
  'label' | 'disabled' | 'fullWidth' | 'variant' | 'multiline' | 'maxRows' | 'minRows' | 'autoFocus'
>

export interface IControlledIsoCodeMappingInputProps<T extends FieldValues>
  extends UseControllerProps<T>,
    FieldProps {
  type?: string
  uppercase?: boolean
  endAdornment?: React.ReactNode
  formState: FormState<T>
  formArrayIndex?: number
  maxLength?: number
  required?: boolean
  inputSize?: number
  showArchetype?: boolean
  showArchetypeAttributes?: boolean
  allowEdit?: boolean
  setValue?: (value: string) => void
  watch: UseFormWatch<T>
  onChange?: (value: ArchetypeIsoCodeCharacteristics) => void
}

export interface ArchetypeIsoCodeCharacteristics {
  archetype: ArchetypeResponseDto
  isoCode: ContainerCharacteristics
}

export class IsoCodeMappingInputStore {
  renderIsoCodeMappingInput?: (
    props: IControlledIsoCodeMappingInputProps<any>,
  ) => React.ReactElement
}
