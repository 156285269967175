import {
  BerthSide,
  CargoType,
  CreateVesselVisitCommand,
  UpdateVesselVisitCommand,
} from '@planning/app/api'
import { ITripId } from '@planning/components/visit/VisitTripIdsFields'
import { vesselVisitService } from '@planning/services'
import dayjs, { Dayjs } from 'dayjs'
import { action, makeObservable, observable } from 'mobx'
import { VisitPlanningStore } from '../visit/VisitPlanningStore'
import { IVesselVisitItem } from './VesselVisitItem'

export interface IVesselVisitPlanningFormData {
  id?: number
  eta?: Dayjs
  etb?: Dayjs
  etd?: Dayjs
  vesselIds: number[]
  importDraft: number
  exportDraft: number
  dischargeEstimate: number
  loadEstimate: number
  cargoType: CargoType
  qmmFrom: number
  qmmTo: number
  berthSide: BerthSide
  tripIds: ITripId[]
  vesselServiceId?: number | null
  portRotation?: Array<string> | null
}

export class VesselVisitPlanningStore extends VisitPlanningStore {
  vesselVisit?: IVesselVisitItem

  constructor() {
    super()
    makeObservable(this, {
      vesselVisit: observable,

      setVesselVisit: action,
      createVesselVisit: action,
      reset: action,
    })
  }

  createVesselVisit = async (data: IVesselVisitPlanningFormData) => {
    const createCmd: CreateVesselVisitCommand = {
      vesselIds: data.vesselIds,
      eta: dayjs(data.eta) as any,
      etb: dayjs(data.etb) as any,
      etd: dayjs(data.etd) as any,
      inboundTripIds: this.getInboundsFromTripIds(data.tripIds),
      outboundTripIds: this.getOutboundsFromTripIds(data.tripIds),
      importDraft: data.importDraft ? parseFloat(data.importDraft.toString()) : 0.0,
      exportDraft: data.exportDraft ? parseFloat(data.exportDraft.toString()) : 0.0,
      loadEstimate: data.loadEstimate ? parseInt(data.loadEstimate.toString()) : 0,
      dischargeEstimate: data.dischargeEstimate ? parseInt(data.dischargeEstimate.toString()) : 0,
      cargoType: data.cargoType,
      schedule: this.scheduleTiming(),
      berthSide: data.berthSide,
      qmmFrom: data.qmmFrom,
      qmmTo: data.qmmTo,
      vesselServiceId: data?.vesselServiceId ?? null,
      portRotation: data?.portRotation ?? [],
    }

    return await vesselVisitService.post(createCmd)
  }

  updateVesselVisit = async (data: IVesselVisitPlanningFormData) => {
    if (!data.id) return

    const updateCmd: UpdateVesselVisitCommand = {
      id: data.id,
      vesselIds: data.vesselIds,
      eta: dayjs(data.eta) as any,
      etb: dayjs(data.etb) as any,
      etd: dayjs(data.etd) as any,
      inboundTripIds: this.getInboundsFromTripIds(data.tripIds),
      outboundTripIds: this.getOutboundsFromTripIds(data.tripIds),
      importDraft: data.importDraft ? parseFloat(data.importDraft.toString()) : 0.0,
      exportDraft: data.exportDraft ? parseFloat(data.exportDraft.toString()) : 0.0,
      loadEstimate: data.loadEstimate ? parseInt(data.loadEstimate.toString()) : 0,
      dischargeEstimate: data.dischargeEstimate ? parseInt(data.dischargeEstimate.toString()) : 0,
      cargoType: data.cargoType,
      berthSide: data.berthSide,
      qmmFrom: data.qmmFrom,
      qmmTo: data.qmmTo,
      vesselServiceId: data?.vesselServiceId ?? null,
      portRotation: data?.portRotation ?? [],
    }

    return await vesselVisitService.put(updateCmd)
  }

  setVesselVisit = (vesselVisit?: IVesselVisitItem) => {
    this.vesselVisit = vesselVisit
  }

  reset = () => {
    this.vesselVisit = undefined

    this.resetVisit()
  }
}
