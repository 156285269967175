import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BinIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useDeleteEntityHandler } from '../../../../hooks/useDeleteEntityHandler'
import { useVesselMessagesHandler } from '../../hooks/useVesselMessagesHandler'
import { VesselViewStore } from '../../stores/VesselViewStore'
import { VesselForm, VesselFormData } from '../Molecules/VesselForm'

interface Props {
  store: VesselViewStore
}
export const VesselFormDialog = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { appViewStore } = usePlanningStore()
  const { getSuccessMessage, getExceptionMessage } = useVesselMessagesHandler()
  const { handleDelete } = useDeleteEntityHandler()

  const title = store.dataToBeEdited
    ? t('editVessel', 'Edit Vessel')
    : t('addNewVessel', 'Add New Vessel')

  const onSubmit = async (data: VesselFormData) => {
    const isEditMode = !!store.dataToBeEdited

    try {
      await store.onSubmit(data)
      appViewStore.setShowAlert('success', getSuccessMessage(isEditMode, data.name))
      store.toggleDialog(false)
    } catch (error: any) {
      appViewStore.setShowAlert('error', getExceptionMessage(error))
    }
  }

  const handleDialogClose = () => {
    store.toggleDialog(false)
  }

  const handleVesselDelete = async () => {
    if (!store.dataToBeEdited?.id) return

    await handleDelete(store.dataToBeEdited?.id, t('vessel', 'Vessel'), store.onDelete)
  }

  return (
    <Dialog fullWidth maxWidth='md' open={store.isDialogOpen} onClose={handleDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <VesselForm
          formId='vessel-form'
          vessel={store.dataToBeEdited}
          vesselItems={store.vesselItems}
          onSubmit={onSubmit}
        />
      </DialogContent>

      <DialogActions
        sx={{
          mt: theme.customSpacing.l,
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Stack direction={'row'} spacing={2}>
          <Button type='submit' form='vessel-form' variant='contained' color='primary'>
            {t('save', 'Save')}
          </Button>
          <Button onClick={handleDialogClose} color='secondary'>
            {t('cancel', 'Cancel')}
          </Button>
        </Stack>
        {store.dataToBeEdited && (
          <IconButton onClick={handleVesselDelete} color='secondary'>
            <BinIcon />
          </IconButton>
        )}
      </DialogActions>
    </Dialog>
  )
})
