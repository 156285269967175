import { Done } from '@mui/icons-material'
import { Chip } from '@mui/material'
import { Stack } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import {
  BookingsResponseDto,
  CarrierVisitDirection,
  ContainerJourneyDto,
  NonNumericOrderDto,
  OrderResponseDto,
} from '@planning/app/api'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { CustomAlert } from '../../TallymanV2/Components'
import { DropOffAutoCompleteRenderOption } from './DropOffAutoCompleteRenderOption'
import { GateInAutoComplete } from './GateInAutoComplete'
import { GeneralCargoOrderCard } from './GeneralCargoOrderCard'
import { IInspectContainerFormData } from './InspectContainer'
import { BookingSlotDialog } from './Organisms/BookingSlotDialog/BookingSlotDialog'
import { DropOffBookingCard } from './Organisms/DropOffBookingCard/DropOffBookingCard'

interface IProps {
  store: GateInViewStore
}

export const DropOffAutoComplete: FC<IProps> = observer(({ store }) => {
  const { t } = useTranslate()
  const { tenantStore, dialogStore, appViewStore } = usePlanningStore()

  const [open, setOpen] = useState<boolean>(false)

  const dropOffSearchType = store.dropOffOrderSearchStore.searchType
  const dropOffContainerSearchType = store.dropOffOrderSearchStore.containerSearchType

  const isGeneralCargoOrder = (c: OrderResponseDto | ContainerJourneyDto) => {
    return 'commodityId' in c && c.commodityId !== null
  }

  const isBookingItem = (c: OrderResponseDto | ContainerJourneyDto | BookingsResponseDto) => {
    return 'id' in c === false
  }

  const openInboundForADifferentCarrierValidation = (
    order: ContainerJourneyDto | OrderResponseDto,
  ) => {
    return !!store.ordersByContainerNumber.find(
      item =>
        item.containerNumber === order.containerNumber &&
        item.direction === CarrierVisitDirection.Inbound &&
        item.carrierVisitId !== null,
    )
  }

  const alreadyOnTerminalValidation = (
    order: ContainerJourneyDto | OrderResponseDto | NonNumericOrderDto | BookingsResponseDto,
  ) => {
    if (order && !isBookingItem(order) && 'isOnTerminal' in order) {
      return (order as OrderResponseDto).isOnTerminal !== undefined && !!order.isOnTerminal
    }

    return false
  }

  const AlreadyOnTerminalAlert = () => (
    <CustomAlert
      message={t(
        'containerIsAlreadyOnTerminalContactControlRoom',
        'Container is already on terminal, contact control room',
      )}
      severity='warning'
    />
  )

  const OpenInboundWithDifferentCarrierAlert = () => (
    <CustomAlert
      message={t(
        'containerHasOpenOrderForADifferentCarrier',
        'Container has an open order for a different carrier',
      )}
      severity='warning'
    />
  )

  const CargoAmountDiscrepancyAlert = () => (
    <CustomAlert
      message={t('cargoAmountDiscrepancyDetected', 'Cargo amount discrepancy detected')}
      severity='warning'
    />
  )

  const getOptions = () => {
    if (store.dropOffOrderSearchStore.searchType === 'generalCargo') {
      return store.dropOffOrderSearchStore.ordersForReferenceNumber
    }

    if (store.isSearchingByBookingNumber) {
      return store.dropOffOrderSearchStore.bookings
    }

    const orders = store.dropOffOrderSearchStore.orders

    return orders.filter(
      item =>
        !store.dropOffOrders.find(order => order.id === item.id) &&
        !store.dropOffGeneralCargoOrders.find(order => order.id === item.id),
    )
  }

  const renderOption = (item: ContainerJourneyDto | OrderResponseDto | BookingsResponseDto) => {
    if (isBookingItem(item)) {
      const booking = item as BookingsResponseDto

      return (
        <DropOffBookingCard
          key={`booking-card-${booking.referenceNumber}-${booking.carrierVisit?.id}-${booking.containerOperator}`}
          booking={booking}
        />
      )
    } else {
      const dropOffOrder = item as ContainerJourneyDto | OrderResponseDto

      if (isGeneralCargoOrder(dropOffOrder) && dropOffSearchType === 'generalCargo') {
        return (
          <GeneralCargoOrderCard
            key={`order-card-${dropOffOrder.id}`}
            order={dropOffOrder as OrderResponseDto}
          />
        )
      }

      return (
        <DropOffAutoCompleteRenderOption
          key={`order-card-${dropOffOrder.id}`}
          dropOffOrder={{ ...dropOffOrder, customs: [] }}
          alertMessage={[
            alreadyOnTerminalValidation(dropOffOrder) ? <AlreadyOnTerminalAlert /> : <></>,
            openInboundForADifferentCarrierValidation(dropOffOrder) ? (
              <OpenInboundWithDifferentCarrierAlert />
            ) : (
              <></>
            ),
          ]}
        />
      )
    }
  }

  const getOptionLabel = (item: ContainerJourneyDto | OrderResponseDto | BookingsResponseDto) => {
    if (store.isSearchingByBookingNumber) {
      return item.referenceNumber ?? ''
    }

    const order = item as ContainerJourneyDto | OrderResponseDto

    return order.containerNumber ?? order.referenceNumber ?? ''
  }

  const handleOnChange = (
    item: ContainerJourneyDto | OrderResponseDto | BookingsResponseDto | undefined,
  ) => {
    if (store.isSearchingByBookingNumber) {
      const booking = item as BookingsResponseDto

      if (!booking) return

      if (!booking.referenceNumber || !booking.containerOperator || !booking.carrierVisit?.id) {
        appViewStore.setShowAlert('error', t('bookingDetailsInvalid', 'Booking details invalid'))
        return
      }

      dialogStore.openDialog(
        <BookingSlotDialog
          bookingNumber={booking.referenceNumber}
          operator={booking.containerOperator}
          carrierVisitId={booking.carrierVisit.id}
          store={store}
        />,
      )

      return
    }

    const order = item as ContainerJourneyDto | OrderResponseDto

    if (order && isGeneralCargoOrder(order)) {
      store.upsertDropOffGeneralCargoOrder(order as IInspectContainerFormData)
    } else if (order) {
      store.upsertDropOffOrder(order as IInspectContainerFormData)
    }
  }

  const getPlaceHolder = () => {
    if (dropOffContainerSearchType === 'containerNumber')
      return t('searchUnitNumber', 'Search unit number')
    else if (dropOffContainerSearchType === 'referenceNumber')
      return t('searchReferenceNumber', 'Search reference number')
    else if (dropOffContainerSearchType === 'bookingNumber')
      return t('searchBookingNumber', 'Search booking number')

    return undefined
  }

  return (
    <>
      {tenantStore.hasGeneralCargo && (
        <Stack direction='row' spacing={1} mb={1}>
          <Chip
            icon={dropOffSearchType === 'container' ? <Done /> : <></>}
            variant={dropOffSearchType === 'container' ? 'filled' : 'outlined'}
            label={t('unit', 'Unit')}
            onClick={() => store.dropOffOrderSearchStore.setSearchType('container')}
          />
          <Chip
            icon={dropOffSearchType === 'generalCargo' ? <Done /> : <></>}
            label={t('generalCargo', 'General Cargo')}
            variant={dropOffSearchType === 'generalCargo' ? 'filled' : 'outlined'}
            onClick={() => store.dropOffOrderSearchStore.setSearchType('generalCargo')}
          />
        </Stack>
      )}
      <GateInAutoComplete
        open={open}
        setOpen={setOpen}
        getOptionDisabled={option => alreadyOnTerminalValidation(option)}
        placeholder={getPlaceHolder()}
        store={store.dropOffOrderSearchStore}
        setContainerSearchType={store.dropOffOrderSearchStore.setContainerSearchType}
        containerSearchType={store.dropOffOrderSearchStore.containerSearchType}
        searchType={store.dropOffOrderSearchStore.searchType}
        type={'DropOff'}
        options={getOptions()}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        handleOnChange={handleOnChange}
      />
    </>
  )
})
