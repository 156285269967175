import { Chip, FormControl, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TruckVisitIssues } from '../../Stores/TruckVisitsViewStore'

const getDefaultValues = () => ({
  issues: [],
})

export interface TruckVisitAdvancedFilterFormProfile {
  issues: TruckVisitIssues[]
}

interface Props {
  formId: string
  issues: TruckVisitIssues[]
  onSubmit: (value: TruckVisitAdvancedFilterFormProfile) => void
}
export const TruckVisitAdvancedFilterForm = ({ formId, issues, onSubmit }: Props) => {
  const { t } = useTranslate()

  const { control, reset, handleSubmit } = useForm<TruckVisitAdvancedFilterFormProfile>({
    defaultValues: getDefaultValues(),
  })

  useEffect(() => {
    if (issues.length) {
      reset({ issues })
    } else {
      reset(getDefaultValues())
    }
  }, [issues, reset])

  const options = useMemo(
    () => [
      { label: t('hold', 'Hold'), value: TruckVisitIssues.hold },
      { label: t('missingPosition', 'Missing position'), value: TruckVisitIssues.missingPosition },
      {
        label: t('overdue', 'Overdue'),
        value: TruckVisitIssues.overdue,
      },
    ],
    [t],
  )

  const isSelected = (selectedIssues: TruckVisitIssues[], option: TruckVisitIssues) => {
    return selectedIssues.some((x: any) => x === option)
  }

  const handleChipClick = (selectedIssues: TruckVisitIssues[], option: TruckVisitIssues) => {
    const newValue = [...selectedIssues]

    if (newValue.length > 0) {
      const index = newValue.findIndex(x => x === option)

      if (index === -1) {
        newValue.push(option)
      } else {
        newValue.splice(index, 1)
      }
    } else {
      newValue.push(option)
    }

    return newValue
  }

  return (
    <Stack gap={4}>
      <Stack
        id={formId}
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(async (formValues: TruckVisitAdvancedFilterFormProfile) => {
          onSubmit(formValues)
        })}
        gap={1}
        mb={2}
      >
        <Controller
          name='issues'
          control={control}
          render={({ field }) => (
            <FormControl component='fieldset' variant='standard' fullWidth>
              <Typography variant='subtitle2' mb={1}>
                {t('issues', 'Issues')}
              </Typography>
              <Stack flexDirection='row' flexWrap='wrap' gap={1}>
                {options?.map(option => (
                  <Chip
                    key={option.value}
                    label={option.label}
                    onClick={() => {
                      const issuesList = handleChipClick(field.value, option.value)
                      field.onChange(issuesList)
                    }}
                    color={isSelected(field.value, option.value) ? 'primary' : 'default'}
                  />
                ))}
              </Stack>
            </FormControl>
          )}
        />
      </Stack>
    </Stack>
  )
}
