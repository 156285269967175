import { withAuthenticationRequired } from '@auth0/auth0-react'
import { CompaniesContainer } from '@billing/features/company/containers/CompaniesContainer'
import { CompaniesDialogContainer } from '@billing/features/company/containers/CompaniesDialogContainer'
import { useTranslate } from '@tolgee/react'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'

const Companies: FC = withAuthenticationRequired(() => {
  const { t } = useTranslate()

  return (
    <>
      <Helmet>
        <title>{t('companiesTomBillingService', 'Companies - TOM Billing Service')}</title>
      </Helmet>

      <CompaniesContainer />
      <CompaniesDialogContainer />
    </>
  )
})

export default Companies
