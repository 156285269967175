import { Grid, Stack, TextField, Typography } from '@mui/material'
import { CompanyResponseDto } from '@planning/app/api'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const StrippingOrderCustomerSelection: FC = observer(() => {
  const { t } = useTranslate()

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext()

  const [customer, setCustomer] = useState<CompanyResponseDto | undefined>()

  return (
    <Stack gap={1}>
      <Typography variant='subtitle1'>{t('clientDetails', 'Client Details')}</Typography>
      <Grid container direction='row' columnSpacing={{ xs: 1 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='customer'
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <CustomerAutoComplete
                selectedCustomer={customer}
                handleOnChange={customer => {
                  onChange(customer)
                  setCustomer(customer)
                }}
                error={!!errors.customer}
                helperText={errors.customer ? t('fieldIsRequired', 'Field is required.') : ''}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={`${t('referenceNumber', 'Reference number')}`}
            {...register('referenceNumber', { required: true })}
            error={!!errors.referenceNumber}
            helperText={errors.referenceNumber ? t('fieldIsRequired', 'Field is required.') : ''}
            variant='outlined'
            type='text'
            name='referenceNumber'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={`${t('lotNo', 'LOT Number')}`}
            {...register('lotNumber')}
            variant='outlined'
            type='text'
            name='lotNumber'
          />
        </Grid>
      </Grid>
    </Stack>
  )
})
