import { Stack, Tooltip } from '@mui/material'
import { ContainerTurnoverDto } from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import {
  DamagedIcon,
  DangerousIcon,
  EmptyIcon,
  FlatRackIcon,
  FullIcon,
  OptionsButton,
  ReeferIcon,
} from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

interface Props {
  turnover: ContainerTurnoverDto
  occupancies?: ContainerTurnoverDto[]
  onUpdatePosition?: (action: string, turnover: ContainerTurnoverDto) => void
}
export const ContainerTurnoverIcons = observer(
  ({ turnover, occupancies, onUpdatePosition }: Props) => {
    const { t } = useTranslate()

    const options = useMemo(() => {
      const options = []

      if (onUpdatePosition && occupancies) {
        options.push({
          label: t('updatePosition', 'Update position'),
          children: occupancies.map(occ => ({
            label: occ.containerNumber,
            onClick: () => onUpdatePosition('update', occ),
          })),
        })
      }
      return options
    }, [onUpdatePosition, occupancies, t])

    return (
      <Stack direction='row' justifyContent='space-between' gap={1} alignItems='center'>
        <Stack direction='row' gap={0.5} alignItems='center'>
          {turnover.isEmpty && <EmptyIcon />}
          {!turnover.isEmpty && <FullIcon />}

          {turnover.isReefer && <ReeferIcon />}
          {turnover.isDamaged && <DamagedIcon />}
          {turnover.isDangerous && <DangerousIcon />}
          {turnover.isFlatRack && (
            <Tooltip title={t('flatRack', 'Flat rack')}>
              <FlatRackIcon />
            </Tooltip>
          )}
        </Stack>

        {options.length > 0 && (
          <OptionsButton
            size='small'
            position='right'
            tooltip={t('moreOptions', 'More options')}
            options={options}
          />
        )}
      </Stack>
    )
  },
)
