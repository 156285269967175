import { useAdminStore } from '@admin/AppProvider'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { useArchetypeMessagesHandler } from '../../hooks/useArchetypeMessagesHandler'
import { ArchetypeControlUIStore } from '../../stores/archetype-control.ui-store'

interface Props {
  viewStore: ArchetypeControlUIStore
}

export const DeleteArchetypeConfirmationDialog: FC<Props> = observer(({ viewStore }) => {
  const { t } = useTranslate()
  const { appStore } = useAdminStore()
  const navigate = useNavigate()
  const { getExceptionMessage } = useArchetypeMessagesHandler()

  const handleDelete = async () => {
    try {
      if (viewStore.selectedArchetype) {
        const archetypeName = viewStore.selectedArchetype.name

        await viewStore.onDelete(viewStore.selectedArchetype.id)

        appStore.setShowAlert('success', `Archetype ${archetypeName} deleted`)
        navigate('/registers/archetypes')
      }
    } catch (error) {
      appStore.setShowAlert('error', getExceptionMessage(error))
    }
  }

  return (
    <ConfirmationDialog
      open={viewStore.isDeleteDialogOpen}
      title={t('confirmDelete', 'Confirm Delete')}
      message={
        viewStore.selectedArchetype?.isoCodes.length
          ? t(
              'confirmDeleteArchetypeWithIsoCodes',
              'This archetype is linked to an ISO Code, and deleting it will remove the mapping betwween then. You cannot undo this action!',
            )
          : t(
              'confirmDeleteArchetype',
              'Archetype will be deleted and you cannot undo this action!',
            )
      }
      primaryActionText={t('Delete', 'Delete')}
      closeLabel={t('cancel', 'Cancel')}
      onConfirm={handleDelete}
      onClose={() => viewStore.toggleDeleteDialog(false)}
    />
  )
})
