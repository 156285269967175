const isContainerReefer = (containerType?: string) =>
  containerType === 'ReeferEutectic' ||
  containerType === 'ReeferHeated' ||
  containerType === 'ReeferInsulated' ||
  containerType === 'ReeferIntegratedMachinery' ||
  containerType === 'ReeferRefrigerated' ||
  containerType === 'ReeferRefrigeratedAndHeated' ||
  containerType === 'ReeferRefrigeratedOrHeatedWithIntegratedEquipment' ||
  containerType === 'ReeferSelfPowered'

export { isContainerReefer }
