import { Card, CardActionArea, CardHeader, Stack, Typography } from '@mui/material'
import { VesselDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import {
  CardAttributes,
  ColoredLabel,
  ColorSchema,
  HighlightedText,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

interface Props {
  vessel: VesselDto
  highlightedText?: string
  onClickEdit?: () => void
}
export const VesselCard = observer(({ vessel, highlightedText, onClickEdit }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const attributes = useMemo(() => {
    const attributes = []

    if (vessel.shortName)
      attributes.push({ label: t('shortName', 'Short name'), value: vessel.shortName })

    if (vessel.shippingLine)
      attributes.push({ label: t('vesselOperator', 'Vessel operator'), value: vessel.shippingLine })

    if (vessel.imoNumber) attributes.push({ label: t('imo', 'IMO'), value: vessel.imoNumber })

    if (vessel.callSign)
      attributes.push({ label: t('callSign', 'Call sign'), value: vessel.callSign })

    if (vessel.mmsi) attributes.push({ label: t('mmsi', 'MMSI'), value: vessel.mmsi })

    if (vessel.capacity)
      attributes.push({ label: t('capacity', 'Capacity'), value: `${vessel.capacity} TEU` })

    if (vessel.grossTonnage)
      attributes.push({ label: t('grossTonnage', 'Gross tonnage'), value: vessel.grossTonnage })

    if (vessel.width) attributes.push({ label: t('width', 'Width'), value: `${vessel.width}m` })

    if (vessel.length) attributes.push({ label: t('length', 'Length'), value: `${vessel.length}m` })

    if (vessel.maxDraft)
      attributes.push({ label: t('maxDraft', 'Max draft'), value: `${vessel.maxDraft}m` })

    return attributes
  }, [vessel, t])

  const isActiveInfo = useMemo(() => {
    return {
      label: vessel.isActive ? t('active', 'Active') : t('inactive', 'Inactive'),
      color: vessel.isActive ? 'success' : 'warning',
    }
  }, [vessel.isActive, t])

  return (
    <CardActionArea
      sx={{
        '.MuiCardActionArea-focusHighlight': {
          background: 'transparent',
        },
        mb: theme.customSpacing.m,
      }}
      onClick={onClickEdit}
    >
      <Card>
        <CardHeader
          title={
            <Stack flexDirection='row' justifyContent='space-between'>
              <Typography variant='h2'>
                <HighlightedText highlight={highlightedText} color={theme.palette.info.main}>
                  {vessel.name}
                </HighlightedText>
              </Typography>
              <ColoredLabel label={isActiveInfo.label} color={isActiveInfo.color as ColorSchema} />
            </Stack>
          }
          sx={{ paddingBottom: theme => theme.customSpacing.m }}
        />

        <CardAttributes attributes={attributes} />
      </Card>
    </CardActionArea>
  )
})
