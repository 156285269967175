import { Button, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { Box } from '@mui/system'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CompanyType } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { DriverAutoComplete } from '@planning/components/autocompletes/driver-autocomplete/DriverAutoComplete'
import { CustomDatePicker } from '@planning/components/CustomDatePicker'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import { CustomTimePicker } from '@planning/components/CustomTimePicker'
import { ExternalDriverFormDialog } from '@planning/pages/ExternalDriver/Components/Organisms/ExternalDriverFormDialog'
import { IGateInFormData } from '@planning/pages/GateClerk'
import { IInspectContainerFormData } from '@planning/pages/GateClerk/Components/InspectContainer'
import { OrderHandlingBox } from '@planning/pages/GateClerk/Components/OrderHandlingBox'
import { NoOrdersHint } from '@planning/pages/GateClerk/NoOrdersHint'
import { truckVisitService } from '@planning/services'
import { useNavigationStore } from '@planning/stores'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch, useHistory } from '@tom-ui/utils'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FC, useEffect } from 'react'
import { Controller, useForm, UseFormReset } from 'react-hook-form'
import { useParams } from 'react-router'
import { DropOffAutoComplete } from './DropOffAutoComplete'
import { InspectContainer } from './InspectContainer'
import { TruckAppointmentPageHeader } from './Organisms/TruckAppointment/TruckAppointmentPageHeader'
import { PickUpAutoComplete } from './PickUpAutoComplete'
import { SelectPickUpAmountAlert } from './SelectPickUpAmountAlert'

interface IProps {
  store: GateInViewStore
}

interface IDialogProps extends IProps {
  reset: UseFormReset<IGateInFormData>
}

const InspectContainerWrapper: FC<IProps> = ({ store }) => {
  return (
    store.editedOrder && (
      <InspectContainer
        order={store.editedOrder}
        damages={store.editedOrder.damages}
        displayDamageReport={store.editedType === 'dropOff'}
        onSubmit={(data: IInspectContainerFormData) => {
          if (store.editedType === 'pickUp') {
            store.upsertPickUpOrder(data)
          } else {
            store.upsertDropOffOrder(data)
          }
          store.resetEditMode()
        }}
        onCancel={() => store.resetEditMode()}
      />
    )
  )
}

export const GateInDialog = observer(({ store }: IProps) => {
  const RenderDialogMode = () => {
    if (store.isDialogEditMode && store.editedOrder?.id) {
      return <InspectContainerWrapper store={store}></InspectContainerWrapper>
    } else {
      return <GateInFormDialog />
    }
  }

  return (
    <Dialog fullWidth maxWidth={'lg'} open={store.isGateInDialogOpen}>
      {RenderDialogMode()}
    </Dialog>
  )
})

const GateInFormDialogActions: FC<IDialogProps> = ({ store, reset }: IDialogProps) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { notificationStore, isTruckAppointment } = store

  return (
    <DialogActions
      sx={{
        mt: theme.customSpacing.l,
        borderTop: `1px solid ${theme.palette.divider}`,
        justifyContent: 'space-between',
      }}
    >
      <Box display='flex' gap={2}>
        {(!isTruckAppointment || (isTruckAppointment && store.truckVisitId)) && (
          <Button
            data-cy='gate-in-submit-btn'
            key='confirmGateIn'
            variant='contained'
            type='submit'
            onClick={() => store.setIsTruckAppointment(false)}
          >
            {t('gateIn', 'Gate In')}
          </Button>
        )}
        {isTruckAppointment && (
          <Button
            key='confirm'
            variant={isTruckAppointment && store.truckVisitId ? 'outlined' : 'contained'}
            type='submit'
          >
            {t('save', 'Save')}
          </Button>
        )}
        <Button
          key='cancelGateIn'
          onClick={() => {
            store.reset()
            reset()
          }}
          color='secondary'
        >
          {t('cancel', 'Cancel')}
        </Button>
      </Box>
      {isTruckAppointment && store.truckVisitId && (
        <Button
          key='deleteTruckAppointment'
          color='error'
          onClick={() => {
            const truckVisitId = store.truckVisitId!
            notificationStore.deleteTruckAppointment(truckVisitId)
            store.reset()
            reset()
          }}
        >
          {t('cancelAppointment', 'Delete Appointment')}
        </Button>
      )}
    </DialogActions>
  )
}

const GateInFormDialogs: FC<IDialogProps> = observer(({ store, reset }: IDialogProps) => {
  const { t } = useTranslate()
  const navigationStore = useNavigationStore()

  const isTruckAppointmentImprovementEnabled = useBooleanFlagValue(
    'truck-appointment-improvement',
    false,
  )

  const onClose = () => {
    store.reset()
    reset()
    navigationStore.pop()
  }

  const deleteOrder = () => {
    if (!store.selectedOrder) return

    const { id } = store.selectedOrder

    if (store.deleteFrom === 'DropOff') {
      store.deleteDropOffOrder(id)
      store.deleteBooking(id)
    } else store.deletePickUpOrder(id)

    store.resetDialogs()
  }

  const deleteNNROrder = () => {
    if (!store.selectedNNROrder) return

    store.deleteNNROrder(store.selectedNNROrder.id)
    store.resetDialogs()
  }

  const onConfirmRemoval = () => (store.selectedOrder ? deleteOrder() : deleteNNROrder())

  return (
    <>
      <ConfirmationDialog
        open={store.isRemoveDialogOpen}
        title={`${t('sureYouWantToRemove', 'Sure you want to remove')} ${
          store.selectedOrder
            ? store.selectedOrder.containerNumber
            : store.selectedNNROrder?.referenceNumber
        }?`}
        primaryActionText={t('remove', 'Remove')}
        closeLabel={t('cancel', 'Cancel')}
        onConfirm={onConfirmRemoval}
        onClose={store.resetDialogs}
        mobile
      />

      <ConfirmationDialog
        open={store.isCancelGateDialogOpen}
        title={t('cancelGateIn?', 'Cancel gate in?')}
        message={t('anyChangesYouMadeWillBeLost', 'Any changes you made will be lost')}
        closeLabel={t('noDontCancel', `No, don't cancel`)}
        primaryActionText={t('cancel', 'Cancel')}
        onClose={store.resetDialogs}
        onConfirm={onClose}
        mobile
      />

      {isTruckAppointmentImprovementEnabled && (
        <Dialog fullWidth maxWidth={'lg'} open={store.isDialogEditMode && !!store.editedOrder?.id}>
          <InspectContainerWrapper store={store}></InspectContainerWrapper>
        </Dialog>
      )}
    </>
  )
})

export const GateInFormDialog = observer(() => {
  const params = useParams()
  const history = useHistory()
  const theme = useMinimalsTheme()

  const { t } = useTranslate()

  const { tenantStore, truckVisitAggregationStore, gateInViewStore: store } = usePlanningStore()
  const { notificationStore: gateInNotificationStore } = store
  const navigationStore = useNavigationStore()

  const isTruckAppointmentImprovementEnabled = useBooleanFlagValue(
    'truck-appointment-improvement',
    false,
  )

  const id = params.id ? parseInt(params.id) : undefined

  const { loading } = useAsyncFetch(async () => {
    if (id) {
      const truckVisit = await truckVisitService.getWithOrdersById(id)

      if (truckVisit) {
        truckVisitAggregationStore.upsertVisitsToItemStores([truckVisit])

        const truckVisitItem = truckVisitAggregationStore.truckVisits.find(t => t.id === id)
        if (truckVisitItem) {
          store.openEditTruckVisitAppointment(truckVisitItem)
          store.setExternalDriver({
            ...truckVisit.driver,
            id: truckVisit.driver?.id ?? 0,
            driverID: truckVisit.driver?.driverID ?? '',
            name: truckVisit.driver?.name ?? '',
            banned: truckVisit.driver?.banned ?? false,
          })
          store.setTruckCompany({
            ...truckVisit.truckCompany,
            id: truckVisit.truckCompany?.id ?? 0,
            name: truckVisit.truckCompany?.name ?? '',
            shortName: truckVisit.truckCompany?.shortName ?? '',
            referenceId: 0,
          })
          store.setExternalPortGatePassRef(truckVisit.externalPortGatePassRef ?? '')
        }
      }
    }
  }, [id, store, truckVisitAggregationStore])

  useEffect(() => {
    if (isTruckAppointmentImprovementEnabled) {
      store.setIsTruckAppointment(!tenantStore.skipTruckAppointment)
    }
  }, [isTruckAppointmentImprovementEnabled, store, tenantStore.skipTruckAppointment])

  const { isTruckAppointment, pickUpGeneralCargoOrders, dropOffGeneralCargoOrders } = store

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IGateInFormData>({
    values: {
      truckPlate: store.licensePlate,
      driverName: store.driverName,
      dropOffOrders: store.dropOffOrders,
      pickUpOrders: store.pickUpOrders,
      nnrOrders: store.nnrOrders,
      eta: store.truckAppointmentEstimatedTimes.eta,
      etd: store.truckAppointmentEstimatedTimes.etd,
      truckAppointmentDate: store.truckAppointmentDate,
      externalPortGatePassRef: store.externalPortGatePassRef,
      generalCargoOrders: [],
      bookings: [],
    },
  })

  const onGateInFormSubmit = async (data: IGateInFormData) => {
    const {
      truckVisitId,
      dropOffOrders,
      pickUpOrders,
      bookings,
      nnrOrders,
      pickUpGeneralCargoOrders,
      dropOffGeneralCargoOrders,
      isTruckAppointment,
      truckAppointmentEstimatedTimes,
    } = store
    const generalCargoOrders = pickUpGeneralCargoOrders.concat(dropOffGeneralCargoOrders)
    const allowGateIn = !isTruckAppointment

    const request = isTruckAppointmentImprovementEnabled
      ? gateInNotificationStore.submitGateInData
      : gateInNotificationStore.createGateInRequest

    await request(
      {
        ...data,
        externalDriverId: store.externalDriver?.id,
        truckCompanyId: store.truckCompany?.id,
        truckVisitId,
        dropOffOrders,
        pickUpOrders,
        bookings,
        nnrOrders,
        generalCargoOrders,
        eta: truckAppointmentEstimatedTimes.eta
          ? moment(truckAppointmentEstimatedTimes.eta).toISOString()
          : null,
        etd: truckAppointmentEstimatedTimes.etd
          ? moment(truckAppointmentEstimatedTimes.etd).toISOString()
          : null,
      },
      allowGateIn,
    )
    store.reset()
    reset()
    navigationStore.clear()
    if (isTruckAppointmentImprovementEnabled) history.goBackHistoryOrDefault('/truck-visits')
  }

  const validateNoOrderVisit = () => {
    return (
      !!store.dropOffOrders.length ||
      !!store.pickUpOrders.length ||
      !!store.bookings.length ||
      !!store.nnrOrders.length ||
      !!store.pickUpGeneralCargoOrders.length ||
      !!store.dropOffGeneralCargoOrders.length
    )
  }

  const validateNnrOrdersWithoutPickUpAmount = () => {
    return store.nnrOrders.every(nnr => nnr.pickUpAmount)
  }

  const onEditDropOffOrder = (order: IInspectContainerFormData) => {
    store.toggleEditDialogVisibility(true)
    store.setEditedOrder(order)
    store.setEditedType('dropOff')
  }

  const onEditPickUpOrder = (order: IInspectContainerFormData) => {
    store.toggleEditDialogVisibility(true)
    store.setEditedOrder(order)
    store.setEditedType('pickUp')
  }

  const showNoOrdersHint = !!errors.dropOffOrders && !!errors.pickUpOrders
  const showSelectPickUpAmountAlert = !errors.dropOffOrders && !!errors.pickUpOrders

  return (
    <InitializationWrapper isInitializing={loading}>
      <Box sx={{ backgroundColor: theme.palette.grey[100], minHeight: '100%' }}>
        <TruckAppointmentPageHeader viewStore={store} onSubmit={handleSubmit(onGateInFormSubmit)} />
        <form id='truck-appointment-form' onSubmit={handleSubmit(onGateInFormSubmit)} noValidate>
          <DialogContent>
            <Box minHeight={'450px'}>
              <NoOrdersHint show={showNoOrdersHint} />
              <SelectPickUpAmountAlert show={showSelectPickUpAmountAlert} />

              {/* APPOINTMENT DETAILS */}

              <Grid container spacing={2}>
                <Grid item xs={6} mt={2} mb={2}>
                  <Box
                    padding={2}
                    border={1}
                    borderColor='divider'
                    borderRadius={1}
                    bgcolor={'white'}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle1'>
                          {t('truckDetails', 'Truck details')}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          data-cy='gate-in-truck-plate-input'
                          fullWidth
                          required
                          label={`${t('truckPlateNumber', 'Truck plate number')}`}
                          variant='outlined'
                          {...register('truckPlate', { required: true })}
                          name='truckPlate'
                          onChange={e => store.setLicensePlate(e.target.value)}
                          error={!!errors.truckPlate}
                          helperText={
                            errors.truckPlate
                              ? t('truckPlateNumberMandatory', 'Truck plate number is mandatory')
                              : ''
                          }
                        />
                      </Grid>

                      {!isTruckAppointmentImprovementEnabled && isTruckAppointment && (
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label={`${t('driverName', 'Driver name')}`}
                            {...register('driverName', { required: false })}
                            variant='outlined'
                            name='driverName'
                            onChange={e => store.setDriverName(e.target.value)}
                            sx={{ mb: '1rem' }}
                          />
                        </Grid>
                      )}
                      {isTruckAppointmentImprovementEnabled && isTruckAppointment && (
                        <>
                          <Grid item xs={6}>
                            <CustomerAutoComplete
                              label={t('truckCompany', 'Truck company')}
                              required={true}
                              selectedCustomer={store.truckCompany}
                              handleOnChange={customer => {
                                store.setTruckCompany(customer)
                              }}
                              error={!!errors.truckCompanyId}
                              helperText={
                                errors.truckCompanyId
                                  ? t('fieldIsRequired', 'Field is required.')
                                  : ''
                              }
                              filterByTypes={[CompanyType.TransportCompany]}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DriverAutoComplete
                              selectedDriver={store.externalDriver}
                              label={t('driverIdNo', 'Driver ID no')}
                              searchBy='driverID'
                              required
                              helperText={
                                errors.externalDriverId ? t('fieldRequired', 'Field required') : ''
                              }
                              error={!!errors.externalDriverId}
                              onChange={driver => store.setExternalDriver(driver)}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DriverAutoComplete
                              selectedDriver={store.externalDriver}
                              label={t('driverName', 'Driver name')}
                              searchBy='name'
                              required
                              helperText={
                                errors.externalDriverId ? t('fieldRequired', 'Field required') : ''
                              }
                              error={!!errors.externalDriverId}
                              onChange={driver => store.setExternalDriver(driver)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label={`${t('externalRefGatePassNo', 'External ref/Gate pass no.')}`}
                              {...register('externalPortGatePassRef', { required: false })}
                              variant='outlined'
                              name='externalPortGatePassRef'
                              onChange={e => store.setExternalPortGatePassRef(e.target.value)}
                              sx={{ mb: '1rem' }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                </Grid>

                {isTruckAppointment && (
                  <Grid item xs={6} mt={2} mb={2}>
                    <Box
                      padding={2}
                      border={1}
                      borderColor='divider'
                      borderRadius={1}
                      bgcolor={'white'}
                      height={'100%'}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant='subtitle1'>
                            {t('arrivalWindow', 'Arrival window')}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name='truckAppointmentDate'
                            rules={{ required: true }}
                            control={control}
                            render={() => (
                              <CustomDatePicker
                                label={t('date', 'Date')}
                                value={
                                  store.truckAppointmentDate
                                    ? dayjs(store.truckAppointmentDate)
                                    : ''
                                }
                                onChange={(data: string) =>
                                  store.setTruckAppointmentDate(dayjs(data).toISOString())
                                }
                                error={!!errors.truckAppointmentDate}
                                helperText={
                                  errors.truckAppointmentDate
                                    ? t(
                                        'arrivalWindowDateMandatory',
                                        'Arrival window date is mandatory',
                                      )
                                    : ''
                                }
                              ></CustomDatePicker>
                            )}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <CustomTimePicker
                            label={t('from', 'From')}
                            value={
                              store.truckAppointmentStartTime
                                ? dayjs(store.truckAppointmentStartTime)
                                : undefined
                            }
                            onChange={(data: string) => {
                              const truckArrivalWindowDuration =
                                tenantStore.tenant?.truckArrivalWindowDuration ?? 0
                              const time = dayjs(data)

                              const startTime = time.toISOString()
                              const endTime = time
                                .add(truckArrivalWindowDuration, 'hour')
                                .toISOString()

                              store.setTruckAppointmentStartTime(startTime)
                              store.setTruckAppointmentEndTime(endTime)
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <CustomTimePicker
                            label={t('to', 'To')}
                            value={
                              store.truckAppointmentEndTime
                                ? dayjs(store.truckAppointmentEndTime)
                                : undefined
                            }
                            onChange={(data: string) =>
                              store.setTruckAppointmentEndTime(dayjs(data).toISOString())
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>

              {/* BOX */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`dropOffOrders`}
                    rules={{ validate: validateNoOrderVisit }}
                    render={() => {
                      return (
                        <OrderHandlingBox
                          isAllowedToCreateOrders={true}
                          orders={store.dropOffOrders}
                          bookings={store.bookings}
                          dropOffGeneralCargoOrders={dropOffGeneralCargoOrders}
                          type='DropOff'
                          error={!!errors.dropOffOrders}
                          onRemoveOrder={order => {
                            store.openRemoveDialog(order, 'DropOff')
                          }}
                          onRemoveGeneralCargoOrder={gco =>
                            store.deleteDropOffGeneralCargoOrder(gco.id)
                          }
                          onEditOrder={onEditDropOffOrder}
                          useSearchAutoComplete={true}
                          renderAutocomplete={() => <DropOffAutoComplete store={store} />}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`pickUpOrders`}
                    rules={{
                      validate: () =>
                        validateNoOrderVisit() && validateNnrOrdersWithoutPickUpAmount(),
                    }}
                    render={() => {
                      return (
                        <OrderHandlingBox
                          isAllowedToCreateOrders={true}
                          orders={store.pickUpOrders}
                          nnrOrders={store.nnrOrders}
                          pickUpGeneralCargoOrders={pickUpGeneralCargoOrders}
                          type='PickUp'
                          error={!!errors.pickUpOrders}
                          useSearchAutoComplete
                          onRemoveOrder={order => {
                            store.openRemoveDialog(order, 'PickUp')
                          }}
                          onEditOrder={onEditPickUpOrder}
                          onEditNnrOrderAmount={store.upsertNNROrder}
                          onRemoveNNROrder={nnr => store.deleteNNROrder(nnr.id)}
                          onRemoveGeneralCargoOrder={gco =>
                            store.deletePickUpGeneralCargoOrder(gco.id)
                          }
                          renderAutocomplete={() => <PickUpAutoComplete store={store} />}
                          validateOutboundRequest={store.validateOutboundRequest}
                        />
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <GateInFormDialogs store={store} reset={reset} />
            </Box>
          </DialogContent>
          {!isTruckAppointmentImprovementEnabled && (
            <GateInFormDialogActions store={store} reset={reset} />
          )}
        </form>
        <ExternalDriverFormDialog />
      </Box>
    </InitializationWrapper>
  )
})
