import {
  ArchetypeResponseDto,
  CarrierType,
  CreateNonNumericOrderCommand,
  UpdateNonNumericOrderCommand,
} from '@planning/app/api'
import { NnrOrderItemStore } from '@planning/rt-stores/nnrOrder/NnrOrderItemStore'
import { nnrOrderService } from '@planning/services'
import { action, computed, makeObservable, observable } from 'mobx'

export interface NnrOrderFormData {
  archetypeId: number | null
  referenceNumber: string
  shippingLine: string
  amount: number
  modeOfTransport: CarrierType
  carrierVisitId: number | null
  containerLength?: number
  containerHeight?: number
  containerType?: string | null
  containerIsoCode: string | null
}

export class NnrOrderControlViewStore {
  id?: number

  isDeleteDialogOpen = false

  constructor(private nnrOrderStore: NnrOrderItemStore) {
    makeObservable(this, {
      id: observable,

      selectedArchetype: computed,
      selectedNnrOrder: computed,
      isEditMode: computed,

      setId: action,
      toggleDeleteDialog: action,
    })
  }

  get selectedArchetype() {
    const selectedNnrOrder = this.selectedNnrOrder?.data
    let arquetype: ArchetypeResponseDto | undefined

    if (selectedNnrOrder?.archetypeId) {
      arquetype = {
        id: selectedNnrOrder.archetypeId,
        name: selectedNnrOrder?.archetypeName ?? '',
        heights: selectedNnrOrder?.containerHeights ?? [],
        lengths: selectedNnrOrder?.containerLengths ?? [],
        types: selectedNnrOrder?.containerTypes ?? [],
      }
    }

    return arquetype
  }

  get selectedNnrOrder() {
    return this.id ? this.nnrOrderStore.elements[this.id] : undefined
  }

  get isEditMode() {
    return !!this.id
  }

  setId = (id: number) => (this.id = id)

  toggleDeleteDialog = (shouldOpen: boolean) => (this.isDeleteDialogOpen = shouldOpen)

  onDelete = async (id: number) => {
    await nnrOrderService.delete(id)
  }

  onSubmit = async (data: NnrOrderFormData) => {
    if (this.isEditMode) {
      const cmd: UpdateNonNumericOrderCommand = {
        ...data,
        id: this.id!,
      }

      if (cmd.modeOfTransport === CarrierType.Truck) {
        cmd.carrierVisitId = undefined
      }

      await nnrOrderService.put(cmd)
    } else {
      const cmd: CreateNonNumericOrderCommand = {
        ...data,
      }

      await nnrOrderService.post(cmd)
    }
  }
}
