export interface ContainerDamageLocationFormProfile {
  id?: number
  name: string
  code?: string | null
  isDefault?: boolean
}

export const defaultValues: ContainerDamageLocationFormProfile = {
  name: '',
  code: '',
}
