import {
  CarrierVisitDirection,
  FirstAvailableOrderInGroupDto,
  GetFirstAvailableOrderInGroupQueryItem,
  NonNumericOrderDto,
  OrderResponseDto,
  OrderStatus,
} from '@planning/app/api'
import { SimpleListStore } from '@planning/components/list/SimpleListStore'
import { nnrOrderService, orderService } from '@planning/services'
import generalCargoService from '@planning/services/generalCargoService'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { OrderContainerSearchType, OrderSearchType } from './GateInViewStore'

export class PickUpOrderSearchStore extends SimpleListStore<OrderResponseDto | NonNumericOrderDto> {
  containerSearchType: OrderContainerSearchType = 'containerNumber'
  searchType: OrderSearchType = 'container'
  ordersByContainerNumber: OrderResponseDto[] = []
  firstOrderAvailableInGroups: FirstAvailableOrderInGroupDto[] = []

  constructor() {
    super(async (filter: string) => {
      if (this.searchType === 'container') {
        if (this.containerSearchType === 'containerNumber') {
          const pickUpOrders = await orderService.pickUpOrders(filter)
          this.ordersByContainerNumber = await orderService.getByContainerNumber(
            filter,
            OrderStatus.Open,
          )

          const groups = this.getGroupsFromOrders(pickUpOrders)
          if (groups.length) {
            this.firstOrderAvailableInGroups =
              await orderService.firstAvailableOrderInGroups(groups)
          }

          if (pickUpOrders.length === 0) return this.ordersByContainerNumber
          return pickUpOrders
        }

        if (this.containerSearchType === 'referenceNumber') {
          const orders = await orderService.pickUpOrders(filter, true)
          const nnrOrders = await nnrOrderService.getByReferenceNumber(filter)

          return [...orders, ...nnrOrders]
        }
      }

      if (this.searchType === 'generalCargo') {
        const generalCargoOrders = await generalCargoService.getByCustomerOrReferenceNumber(
          filter,
          CarrierVisitDirection.Outbound,
        )
        return _(generalCargoOrders.filter(o => !o.carrierVisitId && o.status === OrderStatus.Open))
          .sortBy(o => o.referenceNumber)
          .value()
      }

      return []
    })

    makeObservable(this, {
      containerSearchType: observable,
      searchType: observable,
      ordersByContainerNumber: observable,
      firstOrderAvailableInGroups: observable,
      orders: computed,
      nnrOrders: computed,
      ordersForReferenceNumber: computed,
      setContainerSearchType: action,
      setSearchType: action,
    })
  }

  private isNonNumericOrder = (item: OrderResponseDto | NonNumericOrderDto) => 'amount' in item

  get orders() {
    return this.items.filter(o => !this.isNonNumericOrder(o)).map(o => o as OrderResponseDto)
  }

  isDummyOrder = (order: OrderResponseDto | NonNumericOrderDto) => {
    return 'containerNumber' in order && !order.containerNumber
  }

  get ordersForReferenceNumber() {
    return _(this.items.filter(x => !!x.referenceNumber && !this.isDummyOrder(x)))
      .uniqBy(x => x.referenceNumber)
      .orderBy()
      .value()
  }

  get nnrOrders() {
    return this.items.filter(this.isNonNumericOrder).map(o => o as NonNumericOrderDto)
  }

  setContainerSearchType = (searchType: OrderContainerSearchType) => {
    this.containerSearchType = searchType
  }

  setSearchType = (searchType: OrderSearchType) => {
    this.reset()
    this.searchType = searchType

    if (searchType === 'generalCargo') {
      this.setContainerSearchType('referenceNumber')
    } else {
      this.setContainerSearchType('containerNumber')
    }
  }

  getGroupsFromOrders = (orders: OrderResponseDto[]) => {
    const groups: GetFirstAvailableOrderInGroupQueryItem[] = []

    orders
      .filter(order => !!order.customerGrouping && !!order.linkedCustomerGroupingVisitId)
      .forEach(order => {
        if (
          !groups.some(
            x =>
              x.customerGrouping === order.customerGrouping &&
              x.linkedCustomerGroupingVisitId === order.linkedCustomerGroupingVisitId,
          )
        ) {
          groups.push({
            customerGrouping: order.customerGrouping,
            linkedCustomerGroupingVisitId: order.linkedCustomerGroupingVisitId!,
          })
        }
      })

    return groups
  }
}
