import { VesselServiceResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetVesselServicesQuery } from '@planning/messages/queries'
import _ from 'lodash'
import { action, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { IEntityStore } from '../types'
import { IVesselServiceItem, VesselServiceItem } from './VesselServiceItem'

export class VesselServiceItemStore
  extends ItemStore<VesselServiceResponseDto, IVesselServiceItem>
  implements IEntityStore<IVesselServiceItem>
{
  private hasBeenLoaded = false

  constructor(private readonly messageBus: IMessageBus) {
    super((key, data) => new VesselServiceItem(key, data), {
      messageBus,
      bulkFetchFunc: (_: number[]) => new GetVesselServicesQuery(),
    })

    makeObservable(this, {
      receiveEntityMessage: action,
      receiveUpsertedEventMessage: action,
      receiveDeletedEventMessage: action,
    })

    messageBus.subscribeEvent(GetVesselServicesQuery.type, this.receiveEntityMessage)
    messageBus.subscribeEvent(
      EventTypes.VesselServiceUpsertedInternalEvent,
      this.receiveUpsertedEventMessage,
    )
    messageBus.subscribeEvent(
      EventTypes.VesselServiceDeletedInternalEvent,
      this.receiveDeletedEventMessage,
    )
  }

  receiveEntityMessage = (event: IEvent<VesselServiceResponseDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveUpsertedEventMessage = (event: IEvent<VesselServiceResponseDto>): void => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveDeletedEventMessage = (event: IEvent<number>): void => {
    if (event.payload && _.has(this.elements, event.payload)) {
      this.delete(event.payload)
    }
  }

  fetch = async (query?: GetVesselServicesQuery) => {
    if (this.hasBeenLoaded) return

    this.hasBeenLoaded = true

    await this.messageBus.dispatchQuery(query ?? new GetVesselServicesQuery())
  }
}
