import { createSvgIcon } from '@mui/material'

export const Multi4Icon = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill='currentColor'
  >
    <path d='M0 0h24v24H0z' fill='none' />
    <path d='M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm12 10h2V5h-2v4h-2V5h-2v6h4v4zm6-14H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z' />
  </svg>,
  'Multi4Icon',
)
