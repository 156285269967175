import { Stack, Tooltip, Typography } from '@mui/material'
import { AllocationRulePropertiesDtoFacets } from '@storage/app/api'
import { UnknownStringValue } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { GeneralIcon, useMinimalsTheme } from '@tom-ui/ui'

interface AllocationRuleTemplateFacetListProps {
  facets: AllocationRulePropertiesDtoFacets
}

const AllocationRuleTemplateFacetList = ({ facets }: AllocationRuleTemplateFacetListProps) => {
  const { t } = useTranslate()
  const { palette } = useMinimalsTheme()

  const facetElements = [
    facets.size && `${facets.size}′`,
    facets.isEmpty != null && (facets.isEmpty ? t('empty', 'Empty') : t('full', 'Full')),
    facets.isOOG === true ? t('oog', 'OOG') : '',
    facets.isReefer === true ? t('reefer', 'Reefer') : '',
    facets.isDangerous === true ? t('dangerous', 'Dangerous') : '',
    facets.containerOperator,
    facets.containerHeight,
    facets.containerType,
    facets.weightClasses?.length && facets.weightClasses.join(', '),
    facets.consignee &&
      `${facets.consignee === UnknownStringValue ? t('consignee', 'Consignee') + ': ' : ''}${facets.consignee}`,
    facets.portOfDischarge &&
      `${facets.portOfDischarge === UnknownStringValue ? t('portOfDischarge', 'Port of discharge') + ': ' : ''}${facets.portOfDischarge}`,
    facets.isDangerous === false ? t('nonDangerous', 'Non Dangerous') : '',
    facets.isReefer === false ? t('nonReefer', 'Non Reefer') : '',
    facets.isOOG === false ? t('nonOOG', 'Non OOG') : '',
  ].filter(Boolean)

  return (
    <Stack direction='row'>
      <GeneralIcon sx={{ color: palette.grey[600], mr: 1 }} />
      {facets.containerNumber ? (
        <Typography color={palette.text.secondary} whiteSpace='nowrap' variant='body1'>
          {facets.containerNumber}
        </Typography>
      ) : (
        <Tooltip title={facetElements.join('/')}>
          <Typography
            color={palette.text.secondary}
            sx={{ textOverflow: 'ellipsis', maxWidth: '250px', overflow: 'hidden' }}
            whiteSpace='nowrap'
            variant='body1'
          >
            {facetElements.join('/')}
          </Typography>
        </Tooltip>
      )}
    </Stack>
  )
}

export default AllocationRuleTemplateFacetList
