import { Box, Grid, SxProps, TextFieldProps, Theme, Typography } from '@mui/material'
import { ArchetypeResponseDto } from '@planning/app/api'
import { ArchetypeAutoComplete } from '@planning/components/autocompletes/archetype-autocomplete/ArchetypeAutoComplete'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

const ContainerCharacteristicsInfo: FC<{
  archetypeCharacteristics: ArchetypeResponseDto
}> = observer(({ archetypeCharacteristics }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Box
      bgcolor={theme.palette.grey[200]}
      borderRadius={theme.customRadius.medium}
      color={theme.palette.grey[600]}
      height={'100%'}
      sx={{
        padding: `${theme.customSpacing.xxs} ${theme.customSpacing.s}`,
      }}
    >
      <Grid height={'100%'} container>
        <Grid height={'100%'} item xs={11}>
          <Grid height={'100%'} container columnSpacing={4}>
            <ContainerCharacteristicsAttribute
              label={t('length', 'Length')}
              value={
                archetypeCharacteristics.lengths && archetypeCharacteristics.lengths.length > 0
                  ? archetypeCharacteristics.lengths.map(l => `${l}ft`).join(', ')
                  : t('unknown', 'Unknown')
              }
            />
            <ContainerCharacteristicsAttribute
              label={t('height', 'Height')}
              value={
                archetypeCharacteristics.heights && archetypeCharacteristics.heights.length > 0
                  ? archetypeCharacteristics.heights.join(', ')
                  : t('unknown', 'Unknown')
              }
            />
            <ContainerCharacteristicsAttribute
              label={t('type', 'Type')}
              value={
                archetypeCharacteristics.types && archetypeCharacteristics.types.length > 0
                  ? archetypeCharacteristics.types.join(', ')
                  : t('unknown', 'Unknown')
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
})

interface AttributeProps {
  label: string
  value: string | number
  sx?: SxProps<Theme>
}

const ContainerCharacteristicsAttribute: FC<AttributeProps> = ({ label, value, sx }) => {
  return (
    <Grid
      item
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      sx={{ ...sx, alignItems: 'start', placeContent: 'center' }}
    >
      <Typography variant='body2'>{label}</Typography>
      <Typography variant='body2' fontWeight={'bold'}>
        {value}
      </Typography>
    </Grid>
  )
}

type FieldProps = Pick<
  TextFieldProps,
  'label' | 'disabled' | 'fullWidth' | 'variant' | 'multiline' | 'maxRows' | 'minRows' | 'autoFocus'
>

export interface IControlledArchetypeInputWithDetailsProps<T extends FieldValues>
  extends UseControllerProps<T>,
    FieldProps {
  arquetype?: ArchetypeResponseDto
  formState: FormState<T>
  required?: boolean
  inputSize?: number
}

export const ControlledArchetypeInputWithDetails = <T extends FieldValues>(
  props: IControlledArchetypeInputWithDetailsProps<T>,
) => {
  const { t } = useTranslate()

  const { arquetype, name, control, formState, required, inputSize = 4 } = props
  const { errors } = formState

  const [archetypeCharacteristics, setArchetypeCharacteristics] = useState<
    ArchetypeResponseDto | undefined | null
  >(arquetype)
  const theme = useMinimalsTheme()

  return (
    <>
      <Grid container spacing={1} paddingTop={0.2}>
        <Grid item xs={inputSize}>
          <Controller
            rules={{ required }}
            name={name}
            control={control}
            render={({ field: { onChange } }) => (
              <ArchetypeAutoComplete
                onChange={archetype => {
                  onChange(archetype?.id ?? -1)
                  setArchetypeCharacteristics(archetype)
                }}
                defaultValues={arquetype}
                required={required}
                error={!!errors[name]}
                helperText={errors[name] ? t('fieldIsRequired', 'Field is required.') : ''}
              />
            )}
          />
        </Grid>

        {archetypeCharacteristics && (
          <Grid item xs={12 - inputSize} mt={theme.customSpacing.xxs}>
            <ContainerCharacteristicsInfo archetypeCharacteristics={archetypeCharacteristics} />
          </Grid>
        )}
      </Grid>

      {/* <IsoCodeMappingFormDialog
        viewStore={isoCodeMappingUIStore}
        onFilter={getContainerCharacteristicsByIsoCode}
        onSubmitFn={(id, data) => {
          if (data.isoCode) setValue?.(data.isoCode)
          setContainerCharacteristics({ ...data, id })
          setIsIsoCodeValid(true)
        }}
      /> */}
    </>
  )
}
