import {
  Autocomplete,
  Checkbox,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import { UnitLabelType } from '@planning/app/api'
import { YesNoChipBox } from '@planning/components/dangerous-goods/YesNoChipBox'
import { ImdgCodes } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import { FieldBox, useMinimalsTheme } from '@tom-ui/ui'
import { IFormStore, useRegisterSubFormStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { UnitLabelSubFormStore } from './UnitLabelSubFormStore'
import { UnitLabelFormData } from './unit-label-helper'

export type UnitLabelSubFormProps<T> = {
  formStore: IFormStore<T>
  name: keyof T
  initialValue?: UnitLabelFormData
  onChange?: (value: UnitLabelFormData) => void
}

export const UnitLabelSubForm = observer(
  <T,>({ formStore, name, initialValue, onChange }: UnitLabelSubFormProps<T>) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const { dangerousGoodsDefinitionDataStore } = usePlanningStore()

    useEffect(() => {
      dangerousGoodsDefinitionDataStore.setPageSize(100000)
    }, [dangerousGoodsDefinitionDataStore])

    const store = useRegisterSubFormStore(formStore, name, new UnitLabelSubFormStore())

    useEffect(() => {
      store.setValues(initialValue)
    }, [initialValue, store])

    const handleChange = () => {
      if (onChange) {
        onChange(store.data)
      }
    }

    const handleIsUnitLabelChange = (value: boolean) => {
      store.isUnitLabel.setValue(value)
      handleChange()
    }

    return (
      <FieldBox
        label={t('labels', 'Labels')}
        hasDivider={store.isUnitLabelSelected}
        rightRender={
          <YesNoChipBox
            activeOption={store.isUnitLabelSelected ? 'Yes' : 'No'}
            dataCyYes='unitLabel-field-yes'
            onClickYes={() => handleIsUnitLabelChange(true)}
            onClickNo={() => {
              handleIsUnitLabelChange(false)
            }}
          />
        }
      >
        {store.isUnitLabelSelected && (
          <Stack spacing={theme.customSpacing.m}>
            <Autocomplete
              {...store.imoClasses.props}
              multiple
              disableCloseOnSelect
              fullWidth
              options={ImdgCodes}
              onChange={(_, assignedCodes) => {
                store.imoClasses.setValue(assignedCodes)
                handleChange()
              }}
              renderOption={(props, value) =>
                CustomizedListItem(props, value, store.imoClasses.data)
              }
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{ marginTop: '1rem' }}
                  label={`${t('imoClass', 'IMO Class')}`}
                />
              )}
              data-cy='unitLabel-imoClasses-input'
            />
            <Typography variant='caption' color='GrayText' mt='0rem !important'>
              {t(
                'iMOIsUsuallyAOneOrTwoDigitFoundOnTheLabel',
                'IMO is usually a one or two digit found on the label.',
              )}
            </Typography>

            <Autocomplete
              {...store.unNumbers.props}
              multiple
              disableCloseOnSelect
              fullWidth
              options={dangerousGoodsDefinitionDataStore.pageItems
                .map(i => i.unNumber)
                .filter(i => i !== null && i !== undefined)}
              onChange={(_, unNumbers) => {
                store.unNumbers.setValue(unNumbers as any)
                handleChange()
              }}
              renderOption={(props, value) =>
                CustomizedListItem(props, value as string, store.unNumbers.data)
              }
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{ marginTop: '1rem' }}
                  label={t('unNumbers', 'UN numbers')}
                />
              )}
              data-cy='unitLabel-unNumbers-input'
              freeSolo
            />
            <Typography variant='caption' color='GrayText' mt='0rem !important'>
              {t(
                'uNNumberIsUsuallyAFourDigitCodeLabelIfNotFoundInTheListAddANewOneByPressingENTER',
                'UN number is usually a 4 digit code. If not found in the list, add a new one by pressing ENTER.',
              )}
            </Typography>

            <Autocomplete
              {...store.types.props}
              multiple
              disableCloseOnSelect
              fullWidth
              options={Object.values(UnitLabelType)}
              getOptionLabel={option => t(option)}
              onChange={(_, types) => {
                store.types.setValue(types)
                handleChange()
              }}
              renderOption={(props, value) =>
                CustomizedListItem(props, value, store.types.data, true)
              }
              renderInput={params => (
                <TextField {...params} sx={{ marginTop: '1rem' }} label={t('label', 'Label')} />
              )}
              data-cy='unitLabel-types-input'
            />
          </Stack>
        )}
      </FieldBox>
    )

    function CustomizedListItem(
      props: React.HTMLAttributes<HTMLLIElement>,
      value: string,
      selectedValues: string[],
      useTranslation = false,
    ) {
      return (
        <ListItem {...props}>
          <ListItemIcon>
            <Checkbox edge='start' checked={selectedValues.includes(value)} disableRipple />
          </ListItemIcon>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='body2'>{useTranslation ? t(value) : value}</Typography>
          </Box>
        </ListItem>
      )
    }
  },
)
