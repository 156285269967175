import { LocationCodeDto } from '@planning/app/api'
import locationCodeService from '@planning/services/locationCodeService'

import { debounce } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

export class LocationCodeAutocompleteViewStore {
  open = false
  options: string[] = []

  constructor() {
    makeObservable(this, {
      open: observable,
      options: observable,

      filterOptions: action,
      setOptions: action,
      setOpened: action,
    })
  }

  setOpened(open: boolean) {
    this.open = open

    if (open) this.filterOptions()
  }

  setOptions(options: LocationCodeDto[]) {
    this.options = options.map(o => o.code!)
  }

  filterOptions = debounce(async (code?: string) => {
    this.setOptions(
      await locationCodeService.getMatchedLocationCodes(code ? code : undefined, true, true, 10),
    )
  }, 500)
}
