import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ContainerDto, ContainerType } from '@planning/app/api'
import { ContainerAutoComplete } from '@planning/components'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IContainerJourney } from '../Stores/ContainerJourney'
import { ContainerJourneyOptions } from './ContainerJourneyOptions'

export const NumericForm: FC<{
  selectedContainerJourneys: IContainerJourney[]
  containerJourneyOptions: IContainerJourney[]
  onSelectContainerJourney: (containerJourneys?: IContainerJourney[]) => void
  onSearchFilterChange: (
    filter: string,
    value: string | ContainerDto | null,
  ) => Promise<void> | void
  isEmpty: boolean
  type?: ContainerType
  isNoContainersSelectedError?: boolean
}> = observer(
  ({
    selectedContainerJourneys,
    containerJourneyOptions,
    onSelectContainerJourney,
    onSearchFilterChange,
    isEmpty,
    type,
    isNoContainersSelectedError,
  }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    return (
      <>
        {selectedContainerJourneys[0] ? (
          <ContainerJourneyOptions
            options={[selectedContainerJourneys[0]]}
            isEdited
            onSelect={() => onSelectContainerJourney()}
          />
        ) : (
          <>
            <ContainerAutoComplete
              isEmpty={isEmpty}
              type={type}
              onChange={onSearchFilterChange}
              canAssignServiceOrders
            />
            {isNoContainersSelectedError && (
              <Typography variant='body2' color='error' mt={theme.customSpacing.s}>
                {t('noContainersSelected', 'No containers selected')}.
              </Typography>
            )}
            {containerJourneyOptions && !!containerJourneyOptions.length && (
              <Stack
                direction='column'
                alignItems='flex-end'
                sx={{ marginTop: theme.customSpacing.m }}
              >
                <ContainerJourneyOptions
                  options={containerJourneyOptions}
                  onSelect={onSelectContainerJourney}
                  showCoolingOrderWarning={false}
                  isMultiSelect={false}
                  selectedOptions={[]}
                />
              </Stack>
            )}
          </>
        )}
      </>
    )
  },
)
