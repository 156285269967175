import {
  CarrierVisitStatus,
  ContainerJourneyResponseDto,
  ContainerResponseDto,
  OrderResponseDto,
  TruckVisitWithOrdersDto,
} from '@planning/app/api'
import { ContainerJourneyItemStore } from '@planning/pages/Issues/Stores/ContainerJourneyItemStore'
import { ContainerItemStore } from '@planning/rt-stores/container/ContainerItemStore'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { TruckVisitItemStore } from '@planning/rt-stores/truckVisit/TruckVisitItemStore'
import _ from 'lodash'

export class TruckVisitAggregationStore {
  constructor(
    private readonly truckVisitItemStore: TruckVisitItemStore,
    private readonly orderItemStore: OrderItemStore,
    private readonly containerItemStore: ContainerItemStore,
    private readonly containerJourneyItemStore: ContainerJourneyItemStore,
  ) {}

  get truckVisits() {
    return _.values(this.truckVisitItemStore.elements)
  }

  upsertVisitsToItemStores = (truckVisitsWithOrders: TruckVisitWithOrdersDto[]) => {
    const orders = truckVisitsWithOrders
      .flatMap(x => x.inboundOrders)
      .concat(truckVisitsWithOrders.flatMap(x => x.outboundOrders))
      .filter(x => !!x) as OrderResponseDto[]
    this.orderItemStore.upsertBulk(orders)

    const containers = truckVisitsWithOrders
      .flatMap(x => x.containers)
      .filter(x => !!x) as ContainerResponseDto[]
    this.containerItemStore.upsertBulk(containers)

    const containerJourneys = truckVisitsWithOrders
      .flatMap(x => x.containerJourneys)
      .filter(x => !!x) as ContainerJourneyResponseDto[]
    this.containerJourneyItemStore.upsertBulk(containerJourneys)

    const truckVisits = truckVisitsWithOrders.map(x => ({
      id: x.id!,
      status: x.status ?? CarrierVisitStatus.Expected,
      carrierIds: [],
      cargoType: x.cargoType ?? 'Unknown',
      driverName: x.driver?.name,
      isCancelled: x.isCancelled,
      eta: x.eta ?? undefined,
      ata: x.ata ?? undefined,
      etd: x.etd ?? undefined,
      atd: x.atd ?? undefined,
      identifier: x.identifier,
    }))
    this.truckVisitItemStore.upsertBulk(truckVisits)
  }
}
