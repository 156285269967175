import {
  Alert,
  Box,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CargoData,
  CarrierType,
  CarrierVisitDirection,
  CompanyResponseDto,
  CompanyType,
  CustomsResponseDto,
  DangerousGoodsDefinitionDto,
  DoorDirection,
  OrderStatus,
  RailVisitResponseDto,
  SealsDto,
  TruckVisitDto,
  VesselVisitDto,
} from '@planning/app/api'
import { ControlledContainerSealsWithTypes } from '@planning/components/ControlledContainerSealsWithTypes'
import { CustomDateTimePicker } from '@planning/components/CustomDateTimePicker'
import { FeatureFlag } from '@planning/components/FeatureFlags'
import { RailcarControlledAutocomplete } from '@planning/components/autocompletes/railcar-autocomplete/RailcarControlledAutocomplete'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import { ContainerDamageReport } from '@planning/components/damage-report/ContainerDamageReport'
import IconTruck from '@planning/components/icon/IconTruck'
import {
  createOogFormDataFromOrderResponse,
  OogFormData,
} from '@planning/components/organisms/OogSubForm/oog-helper'
import {
  createUnitLabelFormDataFromOrderResponse,
  UnitLabelFormData,
} from '@planning/components/organisms/UnitLabelSubForm/unit-label-helper'
import { RailVisitAutoComplete } from '@planning/components/visit/RailVisitAutoComplete'
import { VesselVisitAutoComplete } from '@planning/components/visit/VesselVisitAutoComplete'
import companyService from '@planning/services/customerService'
import { useTranslate } from '@tolgee/react'
import { IFormStore, isContainerReefer, useAsyncFetch, useFormStore } from '@tom-ui/utils'
import { Dayjs } from 'dayjs'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Control, Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { CustomsSubForm } from '../../../components/organisms/CustomsSubForm'
import { IOrderWithVisit, SelectOrderViewStore } from '../stores/SelectOrderViewStore'
import { ContainerSummary } from './ContainerSummary'
import { CreateOrderFormCargoStatusFieldBox } from './CreateOrderFormCargoStatusFieldBox'
import { CreateOrderFormCargoTabs } from './CreateOrderFormCargoTabs'
import { DoorDirectionField } from './DoorDirectionField'
import { CargoDetailsSummary } from './Organisms/CargoDetailsSummary'
import RailTrackControlledAutocomplete from './RailTrackControlledAutocomplete'
interface IProps {
  store: SelectOrderViewStore
  forcedDirection?: CarrierVisitDirection
  isTruckAppointment?: boolean
  isGateControl?: boolean
  isDetailsOpen?: boolean
  onSubmit: (data: IOrderFormData) => Promise<void> | void
  callBackAfterResolveDamage?: () => void
}

export interface IFormCargoData extends CargoData {
  isEmpty: boolean
  grossWeight?: number
  vgm?: number
  content?: string
  dangerousGoodsDefinitions: DangerousGoodsDefinitionDto[]
  oog?: OogFormData
  unitLabel?: UnitLabelFormData
}
export interface IOrderFormData {
  containerId: number
  containerNumber: string
  containerIsoCode: string
  referenceNumber?: string
  consignee?: string
  customer?: CompanyResponseDto

  notes?: string
  operationalInstructions?: string

  containerIsReleasedForPickUp: boolean
  inboundOrderId?: number
  outboundOrderId?: number
  inboundVisit?: VesselVisitDto | TruckVisitDto | RailVisitResponseDto
  outboundVisit?: VesselVisitDto | TruckVisitDto | RailVisitResponseDto
  inboundCarrierType?: CarrierType
  outboundCarrierType?: CarrierType
  commonCargo?: IFormCargoData
  inboundCargo?: IFormCargoData
  outboundCargo?: IFormCargoData
  releaseExpires?: string
  inboundRailTrackId?: string
  outboundRailTrackId?: string
  inboundRailcarId?: number
  outboundRailcarId?: number
  inboundRailcarTrackPositionId?: number
  outboundRailcarTrackPositionId?: number
  doorDirection?: DoorDirection
  commonCargoHasSeals: boolean
  inboundHasSeals: boolean
  outboundHasSeals: boolean
  commonCargoSeals: SealsDto[]
  inboundSeals: SealsDto[]
  outboundSeals: SealsDto[]
  commonCargoCustoms?: CustomsResponseDto[]
  outboundCustoms?: CustomsResponseDto[]
}

const OrderFormCargoDetails: FC<{
  inboundOrder?: IOrderWithVisit
  outboundOrder?: IOrderWithVisit
  isReeferContainer: boolean
  imoClassInputRef: React.RefObject<HTMLInputElement>
  isDetailsOpen: boolean
  hasCustoms: boolean
  hasDoorDirection: boolean
  outboundCustomsFormStore: IFormStore<{ customs: CustomsResponseDto[] }>
}> = ({
  inboundOrder,
  outboundOrder,
  isReeferContainer,
  imoClassInputRef,
  isDetailsOpen,
  hasCustoms,
  hasDoorDirection,
  outboundCustomsFormStore,
}) => {
  const [showCargoDetails, setShowCargoDetails] = useState(isDetailsOpen)

  const { t } = useTranslate()

  const {
    control,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<IOrderFormData>()

  let grossWeight = 0
  let vgm = 0
  let content = ''
  let dangerousGoods = false
  let seals = false
  let isEmpty = false
  let oog
  let unitLabel

  if (inboundOrder?.order) {
    grossWeight = inboundOrder.order.grossWeight ?? 0
    vgm = inboundOrder.order.vgm ?? 0
    content = inboundOrder.order.content ?? ''
    dangerousGoods = !!inboundOrder.order.imoClasses?.length
    seals = !!inboundOrder.order.hasSeals
    isEmpty = !!inboundOrder.order.isEmpty
    ;(oog = createOogFormDataFromOrderResponse(inboundOrder.order)),
      (unitLabel = createUnitLabelFormDataFromOrderResponse(inboundOrder.order))
  } else if (outboundOrder?.order) {
    grossWeight = outboundOrder.order.grossWeight ?? 0
    vgm = outboundOrder.order.vgm ?? 0
    content = outboundOrder.order.content ?? ''
    dangerousGoods = !!outboundOrder.order.imoClasses?.length
    seals = !!outboundOrder.order.hasSeals
    isEmpty = !!outboundOrder.order.isEmpty
    ;(oog = createOogFormDataFromOrderResponse(outboundOrder.order)),
      (unitLabel = createUnitLabelFormDataFromOrderResponse(outboundOrder.order))
  }

  return (
    <>
      {!showCargoDetails && (
        <CargoDetailsSummary
          grossWeight={grossWeight}
          vgm={vgm}
          content={content}
          dangerousGoods={dangerousGoods}
          seals={seals}
          isEmpty={isEmpty}
          isOog={oog?.isOog}
          onEdit={() => setShowCargoDetails(true)}
        />
      )}

      {showCargoDetails && (
        <Stack>
          <Typography variant='subtitle1'>{t('cargoDetails', 'Cargo Details')}</Typography>
          <CreateOrderFormCargoStatusFieldBox
            inputRef={imoClassInputRef}
            control={control}
            register={register}
            getValues={getValues}
            errors={errors}
            prefix='commonCargo'
            isReeferContainer={isReeferContainer}
            isEmpty={!isEmpty}
            isDangerousContainer={dangerousGoods}
            setValue={setValue}
          />
          <OrderFormSeal prefix='commonCargo' control={control} />
          {hasCustoms && (
            <FeatureFlag name='customs-v-1'>
              <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
                <CustomsSubForm
                  name={'customs'}
                  formStore={outboundCustomsFormStore}
                  initialValue={outboundOrder?.order?.customs}
                  allowDelete={true}
                  showHeader={true}
                />
              </Paper>
            </FeatureFlag>
          )}
          {hasDoorDirection && <DoorDirectionField control={control} />}
        </Stack>
      )}
    </>
  )
}

const OrderFormSeal: FC<{
  prefix: 'inbound' | 'outbound' | 'commonCargo'
  control: Control<IOrderFormData, any>
}> = ({ prefix, control }) => {
  return (
    <Box mt={'1rem'}>
      <ControlledContainerSealsWithTypes
        name={`${prefix}Seals`}
        control={control}
        sealsName={`${prefix}Seals`}
        hasSealsName={`${prefix}HasSeals`}
      />
    </Box>
  )
}

// [Review] TODO: Refactor this component
export const CreateOrderPageForm: FC<IProps> = observer(
  ({
    store,
    forcedDirection,
    onSubmit,
    isTruckAppointment,
    isGateControl,
    isDetailsOpen = false,
    callBackAfterResolveDamage,
  }) => {
    const {
      containerItemStore,
      tenantStore,
      railcarTrackPositionItemStore,
      railVisitItemStore,
      containerJourneyDataStore,
    } = usePlanningStore()
    const { t } = useTranslate()
    const isOrderNewCompanyFieldsActive = useBooleanFlagValue(
      'order-changing-company-fields',
      false,
    )

    const fetchRailcarTrackPositions = useCallback(
      (visit?: RailVisitResponseDto) => {
        if (visit?.railcarTrackPositionIds?.length)
          railcarTrackPositionItemStore.fetchByIds(visit.railcarTrackPositionIds)
      },
      [railcarTrackPositionItemStore],
    )

    //TODO [Review]
    const getContainerVisitInfo = useCallback(() => {
      if (!store.selectedContainerVisit)
        return {
          inboundOrder: undefined,
          outboundOrder: undefined,
        }

      const inbound = store.selectedContainerVisit[0]
      const outbound = store.selectedContainerVisit[1]

      return {
        inboundOrder: inbound,
        outboundOrder: outbound,
      }
    }, [store.selectedContainerVisit])

    const getDirection = useCallback((forcedDirection?: CarrierVisitDirection) => {
      if (!forcedDirection) {
        return ['Inbound', 'Outbound']
      }

      if (forcedDirection === 'Inbound') {
        return ['Inbound']
      } else if (forcedDirection === 'Outbound') {
        return ['Outbound']
      }
    }, [])

    const getRailTracksByVisit = useCallback(
      (visitId?: number) => {
        const railTracks = railcarTrackPositionItemStore.getRailTracks()

        if (visitId) {
          const railTrackIds = railVisitItemStore.getById(visitId)?.data.assignedRailTrackIds || []
          return railTracks.filter(x => railTrackIds.includes(x.id))
        }

        return railTracks
      },
      [railVisitItemStore, railcarTrackPositionItemStore],
    )

    const { inboundOrder, outboundOrder } = getContainerVisitInfo()
    const commonOrderData = inboundOrder?.order ?? outboundOrder?.order

    //TODO [Review]
    const [inboundCarrierType, setInboundCarrierType] = useState(
      isTruckAppointment && forcedDirection === 'Inbound'
        ? CarrierType.Truck
        : inboundOrder?.order?.carrierVisitType,
    )

    const [inboundVisit, setInboundVisit] = useState<VesselVisitDto | undefined>(
      inboundOrder?.visit as VesselVisitDto,
    )

    const [outboundCarrierType, setOutboundCarrierType] = useState(
      isTruckAppointment && forcedDirection === 'Outbound'
        ? CarrierType.Truck
        : outboundOrder?.order?.carrierVisitType,
    )

    const [outboundVisit, setOutboundVisit] = useState<VesselVisitDto | undefined>(
      outboundOrder?.visit as VesselVisitDto,
    )
    const [customer, setCustomer] = useState<CompanyResponseDto | undefined>(undefined)

    const [showDatetimeForPickUp, setShowDatetimeForPickUp] = useState<boolean>(
      !!outboundOrder?.order?.releaseEnd,
    )

    const container = computed(() => containerItemStore.elements[store.selectedContainerId]).get()
    console.log(container)
    const isReeferContainer = computed(() => isContainerReefer(container?.data.type)).get()

    const inboundRailTracks = computed(() => getRailTracksByVisit(inboundVisit?.id)).get()
    const outboundRailTracks = computed(() => getRailTracksByVisit(outboundVisit?.id)).get()

    const hasServiceOrders = computed(() => {
      const journeys = containerJourneyDataStore.items.filter(
        x =>
          x.inboundOrderId === inboundOrder?.order?.id ||
          x.outboundOrderId === outboundOrder?.order?.id,
      )

      return journeys.some(x => x.stuffingOrderId || x.strippingOrderId)
    }).get()

    const outboundCustomsFormStore = useFormStore<{ customs: CustomsResponseDto[] }>()

    // const outboundCustomsViewStore = useMemo(
    //   () => new CustomsSubFormStore(outboundOrder?.order?.customs ?? []),
    //   [outboundOrder?.order?.customs],
    // )

    const methods = useForm<IOrderFormData>({
      defaultValues: {
        containerId: container?.id ?? store.selectedContainer?.id ?? 0,
        containerNumber: container?.data.number ?? store.selectedContainer?.number ?? '',
        containerIsoCode: container?.data.isoCode ?? store.selectedContainer?.isoCode ?? '',
        referenceNumber: commonOrderData?.referenceNumber ?? undefined,
        consignee: commonOrderData?.consignee ?? undefined,
        notes: commonOrderData?.notes ?? undefined,
        operationalInstructions: commonOrderData?.operationalInstructions ?? undefined,
        customer: commonOrderData?.customerId
          ? {
              id: commonOrderData.customerId,
              name: commonOrderData.customerName ?? '',
            }
          : undefined,
        containerIsReleasedForPickUp: !!commonOrderData?.releaseEnd, // dont need on the form
        inboundOrderId: inboundOrder?.order?.id,
        outboundOrderId: outboundOrder?.order?.id,
        inboundVisit: inboundOrder?.visit,
        outboundVisit: outboundOrder?.visit,
        inboundCarrierType: inboundOrder?.order?.carrierVisitType ?? inboundCarrierType,
        outboundCarrierType: outboundOrder?.order?.carrierVisitType ?? outboundCarrierType,
        releaseExpires: !tenantStore.skipReleaseOrder
          ? (outboundOrder?.order?.releaseEnd ?? undefined)
          : undefined,
        inboundRailcarId: inboundOrder?.order?.railcarId ?? undefined,
        inboundRailTrackId: inboundOrder?.order?.railTrackId ?? undefined,
        commonCargo: inboundOrder?.order
          ? {
              isEmpty: inboundOrder?.order?.isEmpty ?? false,
              imoClasses: inboundOrder?.order?.imoClasses ?? [],
              otherLabels: inboundOrder?.order?.otherLabels ?? [],
              temperature: inboundOrder?.order?.temperature,
              dangerousGoodsDefinitions: inboundOrder?.order?.dangerousGoodsDefinitions ?? [],
              grossWeight: inboundOrder?.order?.grossWeight ?? undefined,
              vgm: inboundOrder?.order?.vgm ?? undefined,
              content: inboundOrder?.order?.content ?? undefined,
              oog: createOogFormDataFromOrderResponse(inboundOrder?.order),
              unitLabel: createUnitLabelFormDataFromOrderResponse(inboundOrder?.order),
            }
          : {
              isEmpty: outboundOrder?.order?.isEmpty ?? false,
              imoClasses: outboundOrder?.order?.imoClasses ?? [],
              otherLabels: outboundOrder?.order?.otherLabels ?? [],
              temperature: outboundOrder?.order?.temperature,
              dangerousGoodsDefinitions: outboundOrder?.order?.dangerousGoodsDefinitions ?? [],
              grossWeight: outboundOrder?.order?.grossWeight ?? undefined,
              vgm: outboundOrder?.order?.vgm ?? undefined,
              content: outboundOrder?.order?.content ?? undefined,
              oog: createOogFormDataFromOrderResponse(outboundOrder?.order),
              unitLabel: createUnitLabelFormDataFromOrderResponse(outboundOrder?.order),
            },
        inboundCargo:
          !forcedDirection || forcedDirection === 'Inbound'
            ? {
                isEmpty: inboundOrder?.order?.isEmpty ?? false,
                imoClasses: inboundOrder?.order?.imoClasses ?? [],
                otherLabels: inboundOrder?.order?.otherLabels ?? [],
                temperature: inboundOrder?.order?.temperature,
                dangerousGoodsDefinitions: inboundOrder?.order?.dangerousGoodsDefinitions ?? [],
                grossWeight: inboundOrder?.order?.grossWeight ?? undefined,
                vgm: inboundOrder?.order?.vgm ?? undefined,
                content: inboundOrder?.order?.content ?? undefined,
                oog: createOogFormDataFromOrderResponse(inboundOrder?.order),
                unitLabel: createUnitLabelFormDataFromOrderResponse(inboundOrder?.order),
              }
            : undefined,
        outboundRailcarId: outboundOrder?.order?.railcarId ?? undefined,
        outboundRailTrackId: outboundOrder?.order?.railTrackId ?? undefined,
        outboundCargo:
          !forcedDirection || forcedDirection === 'Outbound'
            ? {
                isEmpty: outboundOrder?.order?.isEmpty ?? false,
                imoClasses: outboundOrder?.order?.imoClasses ?? [],
                otherLabels: outboundOrder?.order?.otherLabels ?? [],
                temperature: outboundOrder?.order?.temperature,
                dangerousGoodsDefinitions: outboundOrder?.order?.dangerousGoodsDefinitions ?? [],
                grossWeight: outboundOrder?.order?.grossWeight ?? undefined,
                vgm: outboundOrder?.order?.vgm ?? undefined,
                content: outboundOrder?.order?.content ?? undefined,
                oog: createOogFormDataFromOrderResponse(outboundOrder?.order),
                unitLabel: createUnitLabelFormDataFromOrderResponse(outboundOrder?.order),
              }
            : undefined,
        doorDirection: outboundOrder?.order?.doorDirection,
        inboundHasSeals: inboundOrder?.order?.hasSeals ?? false,
        outboundHasSeals: outboundOrder?.order?.hasSeals ?? false,
        inboundSeals: inboundOrder?.order?.seals ?? [],
        outboundSeals: outboundOrder?.order?.seals ?? [],
        commonCargoHasSeals: inboundOrder
          ? (inboundOrder?.order?.hasSeals ?? false)
          : (outboundOrder?.order?.hasSeals ?? false),
        commonCargoSeals: inboundOrder
          ? (inboundOrder?.order?.seals ?? [])
          : (outboundOrder?.order?.seals ?? []),
      },
    })

    const {
      control,
      register,
      handleSubmit,
      getValues,
      setValue,
      formState: { errors },
    } = methods

    const imoClassInputRef = useRef<HTMLInputElement>(null)

    const onError = () => {
      if (errors.commonCargo?.imoClasses) {
        imoClassInputRef.current?.focus()
      }
    }

    useEffect(() => {
      const fetch = async () => {
        if (store.selectedContainerVisit) {
          const customerId =
            store.selectedContainerVisit[0]?.order?.customerId ??
            store.selectedContainerVisit[1]?.order?.customerId
          if (customerId) {
            return await companyService.getById(customerId)
          }
        }
      }

      fetch().then(customer => {
        if (customer) {
          setCustomer(customer)
          setValue('customer', customer, { shouldValidate: true })
        }
      })
    }, [store, setValue])

    useAsyncFetch(async () => {
      const orderIds = []
      if (inboundOrder?.order?.id || outboundOrder?.order?.id) {
        if (inboundOrder?.order?.id) orderIds.push(inboundOrder.order.id)
        if (outboundOrder?.order?.id) orderIds.push(outboundOrder.order.id)

        await containerJourneyDataStore.fetchByOrderIds(orderIds)
      }
    }, [containerJourneyDataStore, inboundOrder?.order?.id, outboundOrder?.order?.id])

    const RenderDirectionExtraField: FC<{
      carrierType?: CarrierType
      direction: CarrierVisitDirection
      disabled?: boolean
    }> = ({ carrierType, direction, disabled }) => {
      if (carrierType === CarrierType.Vessel || carrierType === CarrierType.Train) {
        const isInbound = direction === CarrierVisitDirection.Inbound
        const fieldError = isInbound ? errors.inboundVisit : errors.outboundVisit

        return (
          <Controller
            control={control}
            name={isInbound ? 'inboundVisit' : 'outboundVisit'}
            rules={{ required: true }}
            render={({ field: { onChange } }) => {
              const selectedVesselVisit = isInbound ? inboundVisit : outboundVisit
              const setVisitFn = (visit: VesselVisitDto | RailVisitResponseDto | undefined) => {
                if (isInbound) {
                  setValue('inboundRailcarTrackPositionId', undefined)
                  setInboundVisit(visit)
                } else {
                  setValue('outboundRailcarTrackPositionId', undefined)
                  setOutboundVisit(visit)
                }
              }

              const vesselVisitAutoComplete = (
                <VesselVisitAutoComplete
                  selectedVesselVisit={selectedVesselVisit}
                  handleOnChange={visit => {
                    onChange(visit)
                    setVisitFn(visit)
                  }}
                  error={!!fieldError}
                  helperText={fieldError ? t('fieldIsRequired', 'Field is required.') : ''}
                  disabled={disabled}
                  cargoType='Container'
                />
              )

              const railVisitAutoComplete = (
                <RailVisitAutoComplete
                  selectedRailVisit={selectedVesselVisit as RailVisitResponseDto}
                  handleOnChange={visit => {
                    onChange(visit)
                    setVisitFn(visit)
                    fetchRailcarTrackPositions(visit)
                    railVisitItemStore.fetchById(visit!.id)
                  }}
                  error={!!fieldError}
                  helperText={fieldError ? t('fieldIsRequired', 'Field is required.') : ''}
                  disabled={disabled}
                  cargoType='Container'
                />
              )

              switch (carrierType) {
                case CarrierType.Vessel:
                  return vesselVisitAutoComplete
                case CarrierType.Train:
                  return railVisitAutoComplete
                default:
                  return <></>
              }
            }}
          />
        )
      }

      if (carrierType === 'Truck') {
        return (
          <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
            <IconTruck zoom={150} />
            <Typography variant='subtitle1' ml='.5rem'>
              {t('truck', 'Truck')}
            </Typography>
          </Box>
        )
      }
    }

    const InboundTransportDataSelection = () => {
      return (
        <>
          {(!forcedDirection || forcedDirection === 'Inbound') && (
            <Grid container spacing={2}>
              <Grid item sm={2} xs={12}>
                <Controller
                  control={control}
                  name={`inboundCarrierType`}
                  rules={{ required: !outboundCarrierType }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth error={!!errors.inboundCarrierType}>
                      <InputLabel>{t('inbound', 'Inbound')}</InputLabel>
                      <Select
                        defaultValue=''
                        value={value || ''}
                        label={t('inbound', 'Inbound')}
                        onChange={(e: any) => {
                          onChange(e.target.value)
                          setInboundCarrierType(e.target.value)
                          setInboundVisit(undefined)
                          setValue('inboundRailcarTrackPositionId', undefined)
                        }}
                        disabled={
                          inboundOrder?.order?.status === OrderStatus.Fulfilled || !!forcedDirection
                        }
                        data-cy='inbound-transport-select'
                      >
                        <MenuItem value=''>
                          <em>{t('none', 'None')}</em>
                        </MenuItem>
                        <MenuItem value='Train'>{t('train', 'Train')}</MenuItem>
                        <MenuItem value='Truck'>{t('truck', 'Truck')}</MenuItem>
                        <MenuItem value='Vessel'>{t('vessel', 'Vessel')}</MenuItem>
                      </Select>

                      {errors.inboundCarrierType && (
                        <FormHelperText>
                          {t('fieldIsRequired', 'Field is required.')}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item sm={inboundCarrierType === 'Train' ? 4 : 8} xs={12}>
                <RenderDirectionExtraField
                  carrierType={inboundCarrierType}
                  direction={CarrierVisitDirection.Inbound}
                  disabled={
                    inboundOrder?.order?.status === OrderStatus.Fulfilled || !!forcedDirection
                  }
                />
              </Grid>

              {inboundCarrierType === 'Train' && (
                <>
                  <Grid item sm={3} xs={12}>
                    <RailTrackControlledAutocomplete
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      name='inboundRailTrackId'
                      label={t('railTrack', 'Rail Track')}
                      options={inboundRailTracks}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <RailcarControlledAutocomplete
                      control={control}
                      errors={errors}
                      name='inboundRailcarId'
                      label={t('railcar', 'Railcar')}
                    />
                  </Grid>
                </>
              )}

              {!inboundCarrierType && inboundOrder?.order?.hasServiceOrders && (
                <Grid item xs={12}>
                  <Alert severity='warning'>
                    {t(
                      'settingInboundTransportToNoneWillDeleteThisOrderAndAnyAssociatedStrippingOrStuffingOrders',
                      'Setting inbound transport to none will delete this order & any associated stripping/stuffing orders',
                    )}
                  </Alert>
                </Grid>
              )}
            </Grid>
          )}
        </>
      )
    }

    const OutboundTranpostDataSelection = () => {
      return (
        <>
          {(!forcedDirection || forcedDirection === 'Outbound') && (
            <Grid container spacing={2}>
              <Grid item sm={2} xs={12}>
                <Controller
                  control={control}
                  name={`outboundCarrierType`}
                  rules={{ required: !inboundCarrierType }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth error={!!errors.inboundCarrierType}>
                      <InputLabel>{t('outbound', 'Outbound')}</InputLabel>
                      <Select
                        defaultValue=''
                        value={value || ''}
                        label={t('outbound', 'Outbound')}
                        onChange={(e: any) => {
                          onChange(e.target.value)
                          setOutboundCarrierType(e.target.value)
                          setOutboundVisit(undefined)
                          setValue('outboundRailcarTrackPositionId', undefined)
                        }}
                        disabled={
                          outboundOrder?.order?.status === OrderStatus.Fulfilled ||
                          !!forcedDirection
                        }
                        data-cy='outbound-transport-select'
                      >
                        <MenuItem value=''>
                          <em>{t('none', 'None')}</em>
                        </MenuItem>
                        <MenuItem value='Train'>{t('train', 'Train')}</MenuItem>
                        <MenuItem value='Truck'>{t('truck', 'Truck')}</MenuItem>
                        <MenuItem value='Vessel'>{t('vessel', 'Vessel')}</MenuItem>
                      </Select>
                      {errors.outboundCarrierType ? (
                        <FormHelperText>
                          {t('fieldIsRequired', 'Field is required.')}
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item sm={outboundCarrierType === 'Train' ? 4 : 8} xs={12}>
                <RenderDirectionExtraField
                  carrierType={outboundCarrierType}
                  direction={CarrierVisitDirection.Outbound}
                  disabled={
                    outboundOrder?.order?.status === OrderStatus.Fulfilled || !!forcedDirection
                  }
                />
              </Grid>

              {outboundCarrierType === 'Train' && (
                <>
                  <Grid item sm={3} xs={12}>
                    <RailTrackControlledAutocomplete
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      name='outboundRailTrackId'
                      label={t('railTrack', 'Rail Track')}
                      options={outboundRailTracks}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <RailcarControlledAutocomplete
                      control={control}
                      errors={errors}
                      name='outboundRailcarId'
                      label={t('railcar', 'Railcar')}
                    />
                  </Grid>
                </>
              )}

              {outboundCarrierType === 'Truck' && !tenantStore.skipReleaseOrder ? (
                <Grid item xs={12}>
                  <Box sx={{ mt: '1rem' }}>
                    <Controller
                      control={control}
                      name={`containerIsReleasedForPickUp`}
                      render={({ field: { onChange } }) => (
                        <Box sx={{ mb: '1rem' }}>
                          <Typography variant='subtitle2' mb={'.75rem'}>
                            {`${t(
                              'containerIsReleasedForPickUp',
                              'Container is released for pick-up',
                            )}?`}
                          </Typography>
                          <Chip
                            label={t('yes', 'Yes')}
                            color='primary'
                            sx={{ padding: '0 1rem', mr: '.75rem' }}
                            variant={!showDatetimeForPickUp ? 'outlined' : undefined}
                            onClick={() => {
                              onChange(true)
                              setShowDatetimeForPickUp(true)
                              setValue(
                                'releaseExpires',
                                outboundOrder?.order?.releaseEnd ?? new Date().toISOString(),
                              )
                            }}
                          />
                          <Chip
                            label={t('no', 'No')}
                            color='primary'
                            sx={{ padding: '0 1rem' }}
                            variant={showDatetimeForPickUp ? 'outlined' : undefined}
                            onClick={() => {
                              onChange(false)
                              setShowDatetimeForPickUp(false)
                              setValue('releaseExpires', undefined)
                            }}
                          />
                        </Box>
                      )}
                    />

                    {showDatetimeForPickUp && !tenantStore.skipReleaseOrder ? (
                      <Box sx={{ mb: '1rem' }}>
                        <Controller
                          control={control}
                          name={`releaseExpires`}
                          render={({ field: { onChange, value } }) => (
                            <CustomDateTimePicker
                              label={t('releaseExpiresAfter', 'Release expires after')}
                              value={value}
                              onChange={async (value: Dayjs | null) => {
                                onChange(value)
                              }}
                              popperPlacement={'top-end'}
                            />
                          )}
                        />
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          )}
        </>
      )
    }

    function renderCargoTabs(
      forcedDirection?: CarrierVisitDirection,
      hasServiceOrders?: boolean,
      unitLabel?: UnitLabelFormData,
    ) {
      const labels = getDirection(forcedDirection)

      switch (forcedDirection) {
        case 'Inbound':
          return (
            <CreateOrderFormCargoTabs labels={labels}>
              <>
                <InboundTransportDataSelection />
                {inboundCarrierType && hasServiceOrders && (
                  <>
                    <CreateOrderFormCargoStatusFieldBox
                      inputRef={imoClassInputRef}
                      control={control}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                      isDangerousContainer={
                        !!getValues('inboundCargo.imoClasses')?.length ||
                        !!getValues('inboundCargo.otherLabels')?.length
                      }
                      isReeferContainer={isReeferContainer}
                      isGateControl={isGateControl}
                      isEmpty={!inboundOrder?.order?.isEmpty}
                      prefix='inboundCargo'
                    />
                    <OrderFormSeal prefix='inbound' control={control} />
                  </>
                )}
              </>
              <></>
            </CreateOrderFormCargoTabs>
          )

        case 'Outbound':
          return (
            <CreateOrderFormCargoTabs labels={labels}>
              <>
                <OutboundTranpostDataSelection />
                {outboundCarrierType && hasServiceOrders && (
                  <>
                    <CreateOrderFormCargoStatusFieldBox
                      inputRef={imoClassInputRef}
                      control={control}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      isDangerousContainer={
                        !!getValues('outboundCargo.imoClasses')?.length ||
                        !!getValues('outboundCargo.otherLabels')?.length
                      }
                      isReeferContainer={isReeferContainer}
                      isEmpty={!outboundOrder?.order?.isEmpty}
                      prefix='outboundCargo'
                      isGateControl={isGateControl}
                      setValue={setValue}
                    />
                    <OrderFormSeal prefix='outbound' control={control} />
                    {outboundCarrierType === CarrierType.Vessel && (
                      <FeatureFlag name='customs-v-1'>
                        <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
                          <CustomsSubForm
                            name={'customs'}
                            formStore={outboundCustomsFormStore}
                            initialValue={outboundOrder?.order?.customs}
                            allowDelete={true}
                            showHeader={true}
                          />
                        </Paper>
                      </FeatureFlag>
                    )}
                    {outboundCarrierType === CarrierType.Train && (
                      <DoorDirectionField control={control} />
                    )}
                  </>
                )}
              </>
              <></>
            </CreateOrderFormCargoTabs>
          )

        default:
          return (
            <CreateOrderFormCargoTabs labels={labels}>
              <>
                {/* Inbound Section */}
                <InboundTransportDataSelection />
                {inboundCarrierType && hasServiceOrders && (
                  <>
                    <CreateOrderFormCargoStatusFieldBox
                      inputRef={imoClassInputRef}
                      control={control}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      isDangerousContainer={
                        !!getValues('inboundCargo.imoClasses')?.length ||
                        !!getValues('inboundCargo.otherLabels')?.length
                      }
                      isReeferContainer={isReeferContainer}
                      isEmpty={!inboundOrder?.order?.isEmpty}
                      prefix='inboundCargo'
                      isGateControl={isGateControl}
                      setValue={setValue}
                    />
                    <OrderFormSeal prefix='inbound' control={control} />
                  </>
                )}
              </>
              <>
                {/* Outbound Section */}
                <OutboundTranpostDataSelection />
                {outboundCarrierType && hasServiceOrders && (
                  <>
                    <CreateOrderFormCargoStatusFieldBox
                      inputRef={imoClassInputRef}
                      control={control}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      isDangerousContainer={
                        !!getValues('outboundCargo.imoClasses')?.length ||
                        !!getValues('outboundCargo.otherLabels')?.length
                      }
                      isReeferContainer={isReeferContainer}
                      isEmpty={!outboundOrder?.order?.isEmpty}
                      prefix='outboundCargo'
                      isGateControl={isGateControl}
                      setValue={setValue}
                    />
                    <OrderFormSeal prefix='outbound' control={control} />
                    {outboundCarrierType === CarrierType.Vessel && (
                      <FeatureFlag name='customs-v-1'>
                        <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
                          <CustomsSubForm
                            name={'customs'}
                            formStore={outboundCustomsFormStore}
                            initialValue={outboundOrder?.order?.customs}
                            allowDelete={true}
                            showHeader={true}
                          />
                        </Paper>
                      </FeatureFlag>
                    )}
                    {outboundCarrierType === CarrierType.Train && (
                      <DoorDirectionField control={control} />
                    )}
                  </>
                )}
              </>
            </CreateOrderFormCargoTabs>
          )
      }
    }

    const handleOnSubmit = async (data: IOrderFormData) => {
      console.log(data)
      if (!outboundCustomsFormStore.validate()) {
        return
      }

      const formData = { ...data, outboundCustoms: outboundCustomsFormStore.data.customs }

      if (!hasServiceOrders) {
        formData.inboundCargo = formData.commonCargo
        formData.outboundCargo = formData.commonCargo
        formData.inboundHasSeals = formData.commonCargoHasSeals
        formData.outboundHasSeals = formData.commonCargoHasSeals
        formData.inboundSeals = formData.commonCargoSeals
        formData.outboundSeals = formData.commonCargoSeals
      }

      onSubmit(formData)
    }

    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleOnSubmit, onError)} id='order-form'>
          <Stack gap={4}>
            <Stack gap={2}>
              <ContainerSummary container={container?.data} />
            </Stack>

            <Stack gap={2}>
              <Typography variant='subtitle1'>{t('addingNotes', 'Adding notes')}</Typography>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name='notes'
                  defaultValue=''
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={`${t('notes', 'Control room notes')}`}
                      variant='outlined'
                      helperText={t('notesForControlRoom', 'General notes for the control room')}
                      type='text'
                      name='notes'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name='operationalInstructions'
                  defaultValue=''
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={`${t('operationalInstructions', 'Operational instructions')}`}
                      variant='outlined'
                      helperText={t(
                        'thisNoteWillAppearOnOperatorsCard',
                        `This note will appear on operator's card`,
                      )}
                      type='text'
                      name='operationalInstructions'
                    />
                  )}
                />
              </Grid>
            </Stack>

            {/* CLIENT DETAILS */}
            <Stack gap={2}>
              <Typography variant='subtitle1'>{t('clientDetails', 'Client Details')}</Typography>
              <Controller
                control={control}
                name='customer'
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <CustomerAutoComplete
                    required={true}
                    selectedCustomer={customer}
                    handleOnChange={customer => {
                      onChange(customer)
                      setCustomer(customer)
                    }}
                    error={!!errors.customer}
                    helperText={errors.customer ? t('fieldIsRequired', 'Field is required.') : ''}
                    filterByTypes={
                      isOrderNewCompanyFieldsActive ? [CompanyType.ShippingLine] : undefined
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name='referenceNumber'
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={`${t('referenceNumber', 'Reference Number')}`}
                    variant='outlined'
                    type='text'
                    name='referenceNumber'
                  />
                )}
              />
              <Controller
                control={control}
                name='consignee'
                defaultValue=''
                render={({ field }) =>
                  isOrderNewCompanyFieldsActive ? (
                    <CustomerAutoComplete
                      label={t('consignee', 'Consignee')}
                      selectedCompanyShortName={field.value}
                      dataCy='consignee-autocomplete'
                      handleOnChange={consignee => {
                        field.onChange(consignee?.shortName ?? '')
                      }}
                      filterByTypes={[CompanyType.Consignee]}
                    />
                  ) : (
                    <TextField
                      {...field}
                      fullWidth
                      label={`${t('consignee', 'Consignee')}`}
                      variant='outlined'
                      type='text'
                      name='consignee'
                    />
                  )
                }
              />
            </Stack>

            {!hasServiceOrders && (
              <OrderFormCargoDetails
                isDetailsOpen={isDetailsOpen}
                imoClassInputRef={imoClassInputRef}
                inboundOrder={inboundOrder}
                outboundOrder={outboundOrder}
                isReeferContainer={isReeferContainer}
                hasCustoms={outboundCarrierType === CarrierType.Vessel}
                hasDoorDirection={outboundCarrierType === CarrierType.Train}
                outboundCustomsFormStore={outboundCustomsFormStore}
              />
            )}

            {/* TRANSPORT DETAILS */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  {t('transportDetails', 'Transport Details')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {/* CARGO DETAILS */}
                {renderCargoTabs(forcedDirection, hasServiceOrders)}
              </Grid>
            </Grid>
            <FeatureFlag name='damage-recording'>
              <ContainerDamageReport
                containerId={container?.id ?? 0}
                damages={container?.activeDamages ?? []}
                callBackAfterResolveDamage={callBackAfterResolveDamage}
              />
            </FeatureFlag>
          </Stack>
        </form>
      </FormProvider>
    )
  },
)
