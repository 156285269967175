import { ExternalDriverResponseDto, ExternalDriversApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetExternalDriversQuery implements IQueryMessage {
  static type = 'GetExternalDriversQuery'
  type = GetExternalDriversQuery.type

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(ExternalDriversApi)

export const getExternalDriversQueryHandler: IQueryHandler<
  GetExternalDriversQuery,
  IEvent<ExternalDriverResponseDto[]>
> = async () => {
  const { data } = await httpClient.get()

  return new Event(GetExternalDriversQuery.type, data)
}
