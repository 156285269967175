import { OrderResponseDto, UnitLabelType } from '@planning/app/api'

export type UnitLabelFormData = {
  isUnitLabel?: boolean
  imoClasses?: string[] | null
  types?: UnitLabelType[] | null
  unNumbers?: string[] | null
}

export const createUnitLabelFormDataFromOrderResponse = (
  order?: OrderResponseDto,
): UnitLabelFormData => {
  return {
    isUnitLabel: !!order?.unitLabel,
    imoClasses: order?.unitLabel?.imoClasses,
    types: order?.unitLabel?.types,
    unNumbers: order?.unitLabel?.unNumbers,
  }
}
