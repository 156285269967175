import { Stack, SxProps, Theme } from '@mui/material'
import { ReactNode } from 'react'
import { ColoredLabel } from '../ColoredLabel/ColoredLabel'
import { Tile } from '../tile/Tile'
import { CardAttributesContainer } from './CardAttributesContainer'

export interface CardAttributesProps {
  label: string
  value: string | string[] | number
}
export interface Props {
  attributes: CardAttributesProps[]
  children?: ReactNode
  highlightedText?: string
  sx?: SxProps<Theme>
}
export const CardAttributes = ({ attributes, children, sx, highlightedText }: Props) => {
  if (!attributes.length && !children) return <></>

  return (
    <CardAttributesContainer sx={sx}>
      {attributes.map((attribute, index) => {
        let node = <></>

        if (typeof attribute.value === 'string' || typeof attribute.value === 'number') {
          node = <Tile key={index} label={attribute.label} value={attribute.value} />
        }

        if (Array.isArray(attribute.value)) {
          node = (
            <Tile key={index} label={attribute.label}>
              <Stack mt={1} spacing={0.5} direction='row' useFlexGap sx={{ flexWrap: 'wrap' }}>
                {attribute.value.map(value => {
                  const isHighlighted = highlightedText
                    ? value.toLowerCase().includes(highlightedText.toLowerCase())
                    : false

                  return (
                    <Stack key={index} width={`${value.length * 12}px`}>
                      <ColoredLabel
                        label={value}
                        color={isHighlighted ? 'warning' : 'info'}
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      />
                    </Stack>
                  )
                })}
              </Stack>
            </Tile>
          )
        }
        return node
      })}
      {children}
    </CardAttributesContainer>
  )
}
