import { ErrorCode } from '@planning/utils/ErrorCode/error-code-enum'
import {
  getDomainModelExceptionPayload,
  isDomainModelException,
} from '@planning/utils/ErrorCode/error-code-utils'
import { useTranslate } from '@tolgee/react'

export const useVesselServiceMessagesHandler = () => {
  const { t } = useTranslate()

  const getExceptionMessage = (error: any) => {
    if (isDomainModelException(error, ErrorCode.EntityNotFound)) {
      const payload = getDomainModelExceptionPayload(error, ErrorCode.EntityNotFound)

      return t('entityNotFound', '{entity} with Id {id} not found', {
        entity: payload?.name,
        id: payload?.id,
      })
    }

    if (isDomainModelException(error, ErrorCode.VesselServiceLocationCodeInactive)) {
      const payload = getDomainModelExceptionPayload(
        error,
        ErrorCode.VesselServiceLocationCodeInactive,
      )

      return t(
        'portRotationLocationCodeInactive',
        'PortRotation: LocationCode {locationCode} is inactive',
        { locationCode: payload },
      )
    }

    if (isDomainModelException(error, ErrorCode.ServiceIdNotUnique)) {
      const payload = getDomainModelExceptionPayload(error, ErrorCode.ServiceIdNotUnique)

      return t('fieldNotUnique', 'The {field} "{name}" already exists', {
        field: t('serviceID', 'Service ID'),
        name: payload,
      })
    }

    if (isDomainModelException(error, ErrorCode.NameNotUnique)) {
      const payload = getDomainModelExceptionPayload(error, ErrorCode.NameNotUnique)

      return t('fieldNotUnique', 'The {field} "{name}" already exists', {
        field: t('serviceName', 'Service name'),
        name: payload,
      })
    }

    return (
      error?.response?.data?.detail ??
      t('failedToSaveVesselService', 'Failed to save vessel service')
    )
  }

  const getSuccessMessage = (isEditMode: boolean, name: string) => {
    if (isEditMode) {
      return t(
        'vesselServiceWasUpdatedSuccessfully',
        'Vessel service {name} was updated successfully',
        { name: name },
      )
    }

    return t(
      'vesselServiceWasCreatedSuccessfully',
      'Vessel service {name} was created successfully',
      { name: name },
    )
  }

  return { getExceptionMessage, getSuccessMessage }
}
