import { SelectOption } from '@billing/app/models'
import { Autocomplete, TextField } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { matchSorter } from 'match-sorter'
import { observer } from 'mobx-react-lite'

interface Props {
  selectedValue?: string
  hasError?: boolean
  options: SelectOption[]
  onChange: (value: string) => void
}
export const CustomerAutocomplete = observer(
  ({ selectedValue, options, hasError, onChange }: Props) => {
    const { t } = useTranslate()

    return (
      <Autocomplete
        fullWidth
        disableClearable
        options={options}
        value={options.find(c => c.value === selectedValue)}
        onChange={(_, newValue: SelectOption | null) => {
          if (newValue) onChange(newValue.value.toString())
        }}
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            required
            helperText={hasError ? t('fieldIsRequired', 'Field is required') : undefined}
            label={t('customer', 'Customer')}
          />
        )}
        filterOptions={(options, { inputValue }) => {
          return matchSorter(options, inputValue.replace(/\s/g, ''), {
            keys: [item => item.label.replace(/\./g, '').replace(/\s/g, '')],
            threshold: matchSorter.rankings.CONTAINS,
          })
        }}
      />
    )
  },
)
