import {
  ArchetypeResponseDto,
  ArchetypesApi,
  CreateArchetypeCommand,
  UpdateArchetypeCommand,
} from '@admin/app/api'
import { BaseEntityStore } from './base/BaseEntityStore'

export class ArchetypeStore extends BaseEntityStore<ArchetypeResponseDto> {
  constructor(private readonly api: ArchetypesApi) {
    super()
  }

  async load() {
    const { data } = await this.api.archetypesGet()
    this.updateStoreItems(data)
  }

  async get(id: number) {
    const { data } = await this.api.archetypesGetOne(id)
    this.updateStoreItem(data, id)
  }

  async add(createArchetypeCommand: CreateArchetypeCommand) {
    const { data: id } = await this.api.archetypesPost(createArchetypeCommand)
    return id
  }

  async update(updateArchetypeCommand: UpdateArchetypeCommand) {
    const id = updateArchetypeCommand.id
    await this.api.archetypesPut(updateArchetypeCommand)
    return id
  }

  async delete(id: number) {
    await this.api.archetypesDelete(id)
    this.deleteStoreItem(id)
  }
}
