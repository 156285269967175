import { Box, Button, Stack, Typography } from '@mui/material'
import { IssueResponseDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { IssueStatusChip } from './IssueStatusChip'

interface Props {
  issue: IssueResponseDto
  onResolve: () => void
}
export const IssueCardHeader = observer(({ issue, onResolve }: Props) => {
  const { t } = useTranslate()

  return (
    <Box sx={{ padding: '1rem', display: 'flex' }}>
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        justifyContent='space-between'
        width='100%'
      >
        <Typography variant='h4'>{t(issue.issueType)}</Typography>
        <Stack direction='row' spacing={1} alignItems='center'>
          <IssueStatusChip status={issue.issueStatus} />
          <Button size='medium' variant='contained' onClick={() => onResolve()}>
            {t('resolve', 'Resolve')}
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
})
