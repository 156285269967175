import { Stack, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitStatus, VesselVisitDto } from '@planning/app/api'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC, SyntheticEvent, useState } from 'react'
import { FilterOption, TabOption } from './FilterOption'
import { GateOutWarningDialogs } from './GateOutWarningDialogs'
import { DropOffVisit, PickUpVisit, TruckVisitCard } from './TruckVisitCard'

interface TabPanelProps {
  children?: any
  index: number
  value: number
}

export interface TabData {
  key: 'expected' | 'onTerminal' | 'departed'
  title: string
  data: ITruckVisitItem[]
  count: (tab: number) => number
  carrierVisitStatus: CarrierVisitStatus
}

interface ReusableTabsProps {
  tabs: TabData[]
}

const CustomTabPanel: FC<TabPanelProps> = props => {
  const { children, value, index } = props

  return (
    <div hidden={value !== index}>{value === index && <Box sx={{ pt: 1 }}>{children}</Box>}</div>
  )
}

const FilterOptions: FC<{ tab: TabData }> = ({ tab }) => {
  const { t } = useTranslate()

  const { truckVisitItemStore } = usePlanningStore()

  const tabsFilteringOptions: TabOption = {
    expected: {
      defaultOption: 'expectedToday',
      options: [
        { value: 'expectedLast7Days', label: t('last7Days', 'Last 7 days') },
        { value: 'expectedToday', label: t('today', 'Today') },
        { value: 'expectedNext7Days', label: t('next7Days', 'Next 7 days') },
      ],
    },
    onTerminal: {
      defaultOption: 'onTerminal',
      options: [{ value: 'onTerminal', label: t('onTerminal', 'On Terminal') }],
    },
    departed: {
      defaultOption: 'departedToday',
      options: [
        { value: 'departedLast7Days', label: t('last7Days', 'Last 7 days') },
        { value: 'departedToday', label: t('today', 'Today') },
      ],
    },
  }

  const filteringOptions = tabsFilteringOptions[tab.key]

  return (
    <Box key={`searchable-list-action-${tab.key}`} sx={{ ml: '1rem', minWidth: '140px' }}>
      <FilterOption
        disabled={tab.key === 'onTerminal'}
        key='dayFilter'
        onChange={truckVisitItemStore.handleFilterChange}
        defaultOption={filteringOptions.defaultOption}
        options={filteringOptions.options}
      />
    </Box>
  )
}

const SortingOptions: FC = () => {
  const { t } = useTranslate()
  const { truckVisitItemStore } = usePlanningStore()

  const handleSortingChange = (value: any) => {
    truckVisitItemStore.setSorting(value)
  }

  const sortingOptions = [
    { value: 'old', label: t('oldToNew', 'Old to new') },
    { value: 'new', label: t('newToOld', 'New to old') },
  ]

  return (
    <Box key={`searchable-list-action-sorting`} sx={{ ml: '1rem', minWidth: '140px' }}>
      <FilterOption key='sortingFilter' onChange={handleSortingChange} options={sortingOptions} />
    </Box>
  )
}

const TabItem: FC<{ item: ITruckVisitItem; tab: TabData }> = observer(({ item, tab }) => {
  const { orderItemStore, vesselVisitItemStore, truckVisitItemStore, gateInViewStore } =
    usePlanningStore()

  const onClickTruckAppointment = async (truckVisitId: number) => {
    const truckVisit = truckVisitItemStore.filteredExpectedTruckVisitsInDateRange.find(
      e => e.id === truckVisitId,
    )

    if (truckVisit) gateInViewStore.openEditTruckVisitAppointment(truckVisit)
  }

  const dropOffOrders: DropOffVisit[] = item.inboundOrders.map(item => {
    const linkedOutboundOrder: IOrderItem =
      _.get(orderItemStore.elements, item.data.linkedOrderId ?? 0) ?? {}

    const visit = vesselVisitItemStore.getById(linkedOutboundOrder.data?.carrierVisitId ?? 0)

    return {
      dropOffOrder: item.data,
      theOtherDirectionVisitType: linkedOutboundOrder.data?.carrierVisitType,
      theOtherDirectionStatus: linkedOutboundOrder.data?.status,
      theOtherDirectionVisit: linkedOutboundOrder.visit?.data as VesselVisitDto,
      theOtherDirectionCarriers: visit?.vessels ? visit.vessels.map(item => item.data) : [],
    }
  })

  const pickUpOrders: PickUpVisit[] = item.outboundOrders.map(item => {
    const visit: ITruckVisitItem =
      _.get(truckVisitItemStore.elements, item.data?.carrierVisitId ?? 0) ?? {}
    return {
      pickUpOrder: item.data,
      carriers: visit?.truck ? [visit.truck.data] : [],
    }
  })

  return (
    <Box mb='16px'>
      <TruckVisitCard
        viewStore={gateInViewStore}
        visit={item.data}
        dropOffOrders={dropOffOrders}
        pickUpOrders={pickUpOrders}
        carrierVisitStatus={tab.carrierVisitStatus}
        onClickTruckAppointment={onClickTruckAppointment}
      />
    </Box>
  )
})

export const TabsList = observer(({ tabs }: ReusableTabsProps) => {
  const { t } = useTranslate()
  const [currentTab, setCurrentTab] = useState(0)
  const { truckVisitItemStore, gateInViewStore } = usePlanningStore()

  const handleTextFilterChange = (value: any) => {
    truckVisitItemStore.setFilter(value.target.value)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box display='flex' alignItems='center' mb='1rem' justifyContent='space-between'>
        <Box>
          <Tabs
            value={currentTab}
            onChange={(_: SyntheticEvent, newValue: number) => {
              setCurrentTab(newValue)
              truckVisitItemStore.setFilter()
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                data-cy={`tab-${tab.key}`}
                key={index}
                label={
                  <Stack spacing='8px' direction='row' alignItems='baseline'>
                    <Typography variant='subtitle1'>{tab.title}</Typography>
                    <Box
                      sx={{
                        backgroundColor: '#F9FAFB',
                        padding: '4px 8px',
                        borderRadius: '4px',
                      }}
                    >
                      <Typography variant='caption' fontWeight='bold'>
                        {tab.count(currentTab)}
                      </Typography>
                    </Box>
                  </Stack>
                }
              />
            ))}
          </Tabs>
        </Box>
        <Box display='flex'>
          <TextField
            key='filter'
            label={t('search', 'Search')}
            value={truckVisitItemStore.filter || ''}
            defaultValue={truckVisitItemStore.filter}
            onChange={handleTextFilterChange}
          />
          {<FilterOptions tab={tabs[currentTab]} />}
          {<SortingOptions />}
        </Box>
      </Box>

      <Box>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={currentTab} index={index}>
            {tab.data.map(item => (
              <TabItem key={item.id} item={item} tab={tab} />
            ))}
          </CustomTabPanel>
        ))}
      </Box>
      <GateOutWarningDialogs viewStore={gateInViewStore} />
    </Box>
  )
})
