import { Box } from '@mui/material'
import WeightClassesContainer from '@storage/features/weight-classes/components/WeightClassesContainer'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { PageHeader, useMinimalsTheme } from '@tom-ui/ui'
import { Helmet } from 'react-helmet-async'

const WeightClassesPage = () => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { authStore } = useStores()

  const formId = 'weight-classes-form'

  return (
    <>
      <Helmet>
        <title>{t('weightClasses', 'Weight Classes')}</title>
      </Helmet>
      <PageHeader
        pageTitle={t('weightClasses', 'Weight Classes')}
        formId={formId}
        showSaveButton={authStore.hasPermission('write:weight-classes')}
        showCancelButton={false}
      />
      <Box m={theme.customSpacing.l} sx={{ height: 'calc(100% - 110px)' }}>
        <WeightClassesContainer formId={formId} />
      </Box>
    </>
  )
}

export default WeightClassesPage
