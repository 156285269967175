import { createSvgIcon } from '@mui/material'

export const FlatRackIcon = createSvgIcon(
  <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.99999 8.33337L0.666656 4.33337L7.99999 0.333374L15.3333 4.33337L7.99999 8.33337ZM7.99999 11L1.04999 7.21671L2.44999 6.45004L7.99999 9.48337L13.55 6.45004L14.95 7.21671L7.99999 11ZM7.99999 13.6667L1.04999 9.88337L2.44999 9.11671L7.99999 12.15L13.55 9.11671L14.95 9.88337L7.99999 13.6667ZM7.99999 6.81671L12.55 4.33337L7.99999 1.85004L3.44999 4.33337L7.99999 6.81671Z'
      fill='currentColor'
    />
  </svg>,
  'FlatRackIcon',
)
