import { GlobalLocationCodeUpdateDto, UpdateGlobalLocationCodesCommand } from '@planning/app/api'
import { locationCodesService } from '@planning/services'
import { action, makeObservable, observable } from 'mobx'
import Papa from 'papaparse'

export class LocationCodesParsingStore {
  file?: File
  uploadFailedReason?: string
  resultCount?: number
  resultFileName?: string

  constructor() {
    makeObservable(this, {
      file: observable,
      uploadFailedReason: observable,
      resultCount: observable,
      resultFileName: observable,

      setFile: action,
      submit: action,
      setUploadFailedReason: action,
    })
  }

  setFile = (file?: File) => {
    if (file) {
      this.resultCount = undefined
      this.uploadFailedReason = undefined
    }
    this.file = file
  }

  setUploadFailedReason = (reason?: string) => {
    this.uploadFailedReason = reason
  }

  private parseData = async (upload: File) => {
    return await new Promise((resolve, reject) => {
      try {
        Papa.parse(upload, {
          dynamicTyping: false,
          header: false,
          skipEmptyLines: true,
          encoding: 'windows-1252',
          complete: r => {
            resolve(r.data)
          },
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  submit = async () => {
    if (!this.file) {
      return
    }

    const data = (await this.parseData(this.file)) as [any]

    let currentCountry: string | undefined
    const parsedData: GlobalLocationCodeUpdateDto[] = []

    data.forEach(entry => {
      if (entry[2] === '' && entry[3].startsWith('.')) {
        currentCountry = entry[3].substring(1)
      } else {
        parsedData.push({
          countryCode: entry[1],
          portCode: entry[2],
          portName: entry[3],
          countryName: currentCountry ?? entry[2],
        })
      }
    })

    try {
      const cmd: UpdateGlobalLocationCodesCommand = {
        globalLocationCodes: parsedData,
      }
      this.resultCount = await locationCodesService.uploadGlobalCodes(cmd)
      this.resultFileName = this.file.name
      this.setFile(undefined)
    } catch (e: any) {
      this.setUploadFailedReason(e.message)
    }
  }
}
