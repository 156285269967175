import { createSvgIcon } from '@mui/material'

export const ReachStackerIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.7455 2.16812L3.6628 8.81767C3.46807 8.93137 3.31105 9.1026 3.21504 9.30805C3.17445 9.39493 3.14476 9.48792 3.12775 9.58527C3.11493 9.65794 3.10928 9.73197 3.11111 9.80623V14.2223C2.49746 14.2223 2 14.7197 2 15.3334V19.7778C2 17.9369 3.49238 16.4445 5.33333 16.4445C7.17428 16.4445 8.66667 17.9369 8.66667 19.7778C8.66667 20.1674 8.59983 20.5414 8.47699 20.8889H12.1897C12.0668 20.5414 12 20.1674 12 19.7778C12 17.9369 13.4924 16.4445 15.3333 16.4445C17.1743 16.4445 18.6667 17.9369 18.6667 19.7778V15.3334C18.6667 14.7197 18.1692 14.2223 17.5556 14.2223H12.9524L10.0977 9.22662C9.89986 8.88043 9.5317 8.66678 9.13298 8.66678H8.23351L15.161 4.51031L16.4444 5.79371V7.55568H14.2222C13.6086 7.55568 13.1111 8.05314 13.1111 8.66678V12.0001C13.1111 12.6137 13.6086 13.1112 14.2222 13.1112C14.8359 13.1112 15.3333 12.6137 15.3333 12.0001V9.77788H19.7778V12.0001C19.7778 12.6137 20.2752 13.1112 20.8889 13.1112C21.5025 13.1112 22 12.6137 22 12.0001V8.66678C22 8.05314 21.5025 7.55568 20.8889 7.55568H18.6667V5.33424L18.6667 5.33014C18.6658 5.02833 18.5446 4.75483 18.3485 4.55509L18.3406 4.54714L16.131 2.33759C16.0821 2.28709 16.029 2.24193 15.9727 2.20233C15.8167 2.09234 15.6388 2.02723 15.4568 2.007C15.2894 1.98815 15.1168 2.00708 14.9526 2.06716C14.881 2.09322 14.8115 2.12687 14.7455 2.16812Z'
      fill='currentColor'
    />
    <path
      d='M17.5556 19.7778C17.5556 21.0051 16.5606 22 15.3333 22C14.106 22 13.1111 21.0051 13.1111 19.7778C13.1111 18.5505 14.106 17.5556 15.3333 17.5556C16.5606 17.5556 17.5556 18.5505 17.5556 19.7778Z'
      fill='currentColor'
    />
    <path
      d='M5.33333 22C6.56063 22 7.55556 21.0051 7.55556 19.7778C7.55556 18.5505 6.56063 17.5556 5.33333 17.5556C4.10603 17.5556 3.11111 18.5505 3.11111 19.7778C3.11111 21.0051 4.10603 22 5.33333 22Z'
      fill='currentColor'
    />
  </svg>,
  'ReachStacker',
)
