import { Box, Stack } from '@mui/material'
import { CarrierVisitDirection, ContainerTurnoverDto, WeightClassDto } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { TextBox } from '@storage/components/TextBox'
import { CarrierVisitAllocationRulesV2UIStore } from '@storage/features/carrier-visit-allocation-rules/stores/carrier-visit-allocation-rules-v2.ui-store'
import { useStores } from '@storage/hooks/use-stores.hook'
import { GlobalAllocationRulesUIStore } from '@storage/pages/global-allocation-rules/stores/global-allocation-rules.ui-store'
import { UnallocatedTurnoversBreakDown } from '@storage/stores/carrier-visit.store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { UnAllocatedFilter, UnAllocatedLayer } from '../interfaces'
import { filterContainerTurnoversByBreakdown } from '../utils/yard-planning-dashboard-stack.util'
import UnAllocatedContainersGroup from './UnAllocatedContainersGroup'
import UnAllocatedContainersHeader from './UnAllocatedContainersHeader'
import { UnAllocatedItems } from './UnAllocatedItems'

interface Props {
  uiStore: CarrierVisitAllocationRulesV2UIStore | GlobalAllocationRulesUIStore
  isLoading: boolean
  unallocatedTurnovers: ContainerTurnoverDto[]
  carrierVisitDirection: CarrierVisitDirection
  weightClasses: WeightClassDto[]
}

const UnAllocatedContainers = observer(
  ({ uiStore, isLoading, unallocatedTurnovers, carrierVisitDirection, weightClasses }: Props) => {
    const { t } = useTranslate()
    const { carrierVisitStore, companyStore } = useStores()
    const [filter, setFilter] = useState<UnAllocatedFilter>('containerNumber')
    const [layers, setLayers] = useState<UnAllocatedLayer[]>([])

    useEffect(() => {
      setLayers(layers =>
        layers
          .map(layer => {
            const containers = filterContainerTurnoversByBreakdown(
              layer.turnoversBreakdown,
              unallocatedTurnovers,
              weightClasses,
            )

            return {
              ...layer,
              containerTurnovers: containers,
            }
          })
          .filter(layer => layer.containerTurnovers.length),
      )
    }, [unallocatedTurnovers, weightClasses])

    const standardContainers = useMemo(() => {
      return carrierVisitStore.getStandardUnallocatedBreakdownFromTurnovers(
        unallocatedTurnovers ?? [],
      )
    }, [carrierVisitStore, unallocatedTurnovers])

    const specialContainers = useMemo(() => {
      return carrierVisitStore.getSpecialUnallocatedBreakdownFromTurnovers(
        unallocatedTurnovers ?? [],
      )
    }, [carrierVisitStore, unallocatedTurnovers])

    const startExpand = (breakdownItem: UnallocatedTurnoversBreakDown, isStandard?: boolean) => {
      setLayers([])
      handleExpand(breakdownItem, undefined, isStandard)
    }

    const handleExpand = (
      breakdownItem: UnallocatedTurnoversBreakDown,
      previousFilter?: UnAllocatedFilter,
      isStandard?: boolean,
    ) => {
      let defaultGroupBy: UnAllocatedFilter = 'containerNumber'
      const filters: SelectOption[] = []

      if (carrierVisitDirection === CarrierVisitDirection.Inbound && !layers.length) {
        filters.push(
          { label: t('weightClass', 'Weight class'), value: 'weightClass' },
          { label: t('customer', 'Customer'), value: 'customer' },
          {
            label: t('outboundCarrierType', 'Outbound carrierF type'),
            value: 'outboundCarrierType',
          },
          { label: t('consignee', 'Consignee'), value: 'consignee' },
        )
        defaultGroupBy = 'weightClass'
        setFilter(defaultGroupBy)
      } else if (carrierVisitDirection === CarrierVisitDirection.Outbound) {
        if (!layers.length) {
          filters.push(
            { label: t('portOfDischarge', 'Port of discharge'), value: 'portOfDischarge' },
            { label: t('weightClass', 'Weight class'), value: 'weightClass' },
          )
          defaultGroupBy = 'portOfDischarge'
          setFilter(defaultGroupBy)
        } else if (layers.length === 1) {
          defaultGroupBy = previousFilter === 'portOfDischarge' ? 'weightClass' : 'portOfDischarge'
        }
      }

      setLayers(layers => [
        ...layers,
        {
          isStandard: isStandard ?? layers[0]?.isStandard,
          turnoversBreakdown: breakdownItem,
          containerTurnovers: breakdownItem.value,
          defaultGroupBy: defaultGroupBy,
          filters: filters,
          isFinalLevel: defaultGroupBy === 'containerNumber',
        },
      ])
    }

    const handleReturn = () => {
      setLayers(layers => layers.filter((_, index) => index < layers.length - 1))
    }

    const handleOnContainerGroupCreateRule = (breakdownItem: UnallocatedTurnoversBreakDown) =>
      uiStore.openAddDialogWithBreakdown(breakdownItem)

    const renderLoadingState = () => (
      <TextBox
        text={t('loadingUnallocatedContainers', 'Loading unallocated containers...')}
        isLoading
      />
    )

    const renderEmptyState = () => (
      <TextBox text={t('noUnAllocatedContainers', 'No unallocated containers remaining')} />
    )

    const renderContainerGroups = () => (
      <>
        {standardContainers.length > 0 && (
          <UnAllocatedContainersGroup
            unallocatedContainersBreakdown={standardContainers}
            variant='standard'
            onCreateNewRule={handleOnContainerGroupCreateRule}
            onExpand={breakdownItem => startExpand(breakdownItem, true)}
          />
        )}
        {specialContainers.length > 0 && (
          <UnAllocatedContainersGroup
            unallocatedContainersBreakdown={specialContainers}
            variant='special'
            onCreateNewRule={handleOnContainerGroupCreateRule}
            onExpand={breakdownItem => startExpand(breakdownItem)}
          />
        )}
      </>
    )

    const renderLayerItems = () => (
      <UnAllocatedItems
        layer={layers[layers.length - 1]}
        weightClasses={weightClasses}
        filter={filter}
        changeFilter={setFilter}
        onCreateNewRule={handleOnContainerGroupCreateRule}
        onExpand={handleExpand}
        onReturn={handleReturn}
        companies={companyStore.companies}
      />
    )

    const renderContent = () => (
      <Box overflow='auto'>
        {layers.length === 0 ? renderContainerGroups() : renderLayerItems()}
      </Box>
    )

    return (
      <Stack sx={{ height: '100%' }}>
        <UnAllocatedContainersHeader
          numberOfUnallocatedContainers={unallocatedTurnovers.length}
          isLoading={isLoading}
        />
        {isLoading
          ? renderLoadingState()
          : unallocatedTurnovers.length === 0
            ? renderEmptyState()
            : renderContent()}
      </Stack>
    )
  },
)

export default UnAllocatedContainers
