import { Alert, Button } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useAdminStore } from '@tom-ui/admin'
import { NavigationStackBackButton } from '@tom-ui/planning'
import { RoutingInfo } from '@tom-ui/storage'
import { Header, StepperInfo } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

type Props = {
  isOnTerminal: boolean
  dropOffs: {
    containerNumber: string
    linkedOrderArrivedOrInOperationVisitName: string | undefined
  }[]
  pickUps: {
    containerNumber: string
    linkedOrderArrivedOrInOperationVisitName: string | undefined
  }[]
  nonNumeric: any[]
  onSubmit: () => Promise<void>
  onClose: () => void
}

export const GateInRouteInfoPage: FC<Props> = observer(
  ({ isOnTerminal, dropOffs, pickUps, nonNumeric, onSubmit, onClose }) => {
    const { t } = useTranslate()
    const { tenantStore } = useAdminStore()

    return (
      <>
        <Header
          title={t('gateIn', 'Gate in')}
          leftRenderOption={<NavigationStackBackButton />}
          rightRenderOption={
            <Button
              disabled={isOnTerminal}
              data-cy='gate-in-route-info-page-gate-in-btn'
              variant='contained'
              sx={{ width: '15%', minWidth: '100px' }}
              onClick={onSubmit}
            >
              {t('gateIn', 'Gate In')}
            </Button>
          }
        />
        {isOnTerminal && (
          <Alert severity='warning' sx={{ margin: '10px' }}>
            {t(
              'aTruckWithSameLicensePlateIsAlreadyOnTerminal',
              'A truck with the same license plate is already on the terminal. You cannot gate in the same truck twice.',
            )}
          </Alert>
        )}
        <StepperInfo
          title={t('provideRoutingInfo', 'Provide routing info')}
          steps={`2 ${t('of', 'of')} 2`}
        />
        <RoutingInfo
          dropOffContainers={dropOffs}
          pickUpContainers={pickUps}
          nonNumeric={nonNumeric}
          allowDirectDelivery={tenantStore.allowDirectDelivery}
        />
      </>
    )
  },
)
