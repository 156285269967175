import { useAdminStore } from '@admin/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BinIcon, ConfirmationDialog, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ContainerDamageTypeFormMapper } from '../components/containerDamageTypeForm/container-damage-type-form.mapper'
import { ContainerDamageTypeFormProfile } from '../components/containerDamageTypeForm/container-damage-type-form.profile'
import { ContainerDamageTypeForm } from '../components/containerDamageTypeForm/ContainerDamageTypeForm'

export const ContainerDamageTypesDialogContainer = observer(() => {
  const { t } = useTranslate()
  const { appStore, containerDamageTypeStore, containerDamageTypeDialogUIStore } = useAdminStore()

  const handleSubmit = async (formValues: ContainerDamageTypeFormProfile) => {
    if (containerDamageTypeDialogUIStore.formType === 'Add') {
      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.longName} ${t('isBeingCreated', 'is being created')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await containerDamageTypeStore.add(
                ContainerDamageTypeFormMapper.mapFormValuesToCreateDto(formValues),
              ),
          ),
        `${formValues.longName} ${t('creationCancelled', 'creation cancelled')}`,
      )
    } else {
      const isDefault = containerDamageTypeDialogUIStore.editingEntity?.isDefault

      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.longName} ${t('isBeingUpdated', 'is being updated')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await containerDamageTypeStore.update(
                ContainerDamageTypeFormMapper.mapFormValuesToUpdateDto(formValues),
                isDefault,
              ),
          ),
        `${formValues.longName} ${t('updateCancelled', 'update cancelled')}`,
      )
    }

    containerDamageTypeDialogUIStore.toggle(containerDamageTypeDialogUIStore.editingEntity?.id)
  }

  const handleClose = () => {
    containerDamageTypeDialogUIStore.toggle(containerDamageTypeDialogUIStore.editingEntity?.id)
  }

  const handleToggleDelete = () => {
    containerDamageTypeDialogUIStore.toggle(containerDamageTypeDialogUIStore.editingEntity?.id)
    containerDamageTypeDialogUIStore.toggleDelete()
  }

  const handleCloseDelete = () => {
    containerDamageTypeDialogUIStore.toggleDelete()
  }

  const handleDelete = async () => {
    if (containerDamageTypeDialogUIStore.editingEntity) {
      const ContainerDamageTypeName = containerDamageTypeDialogUIStore.editingEntity.longName
      await containerDamageTypeStore.delete(containerDamageTypeDialogUIStore.editingEntity.id)
      handleCloseDelete()

      appStore.setShowAlert('success', `${ContainerDamageTypeName} ${t('deleted', 'deleted')}`)
    }
  }

  return (
    <>
      <ConfirmationDialog
        open={containerDamageTypeDialogUIStore.isOpen}
        title={`${
          containerDamageTypeDialogUIStore.formType === 'Add'
            ? t('addNew', 'Add new')
            : t('edit', 'Edit')
        } ${t('type', 'type')}`}
        primaryActionText={t('save', 'Save')}
        closeLabel={t('cancel', 'Cancel')}
        formId='ContainerDamageType-form'
        maxWidth='sm'
        rightRenderOption={
          containerDamageTypeDialogUIStore.formType === 'Edit' &&
          !containerDamageTypeDialogUIStore.editingEntity?.isDefault ? (
            <IconButton variant='contained' color='default' onClick={handleToggleDelete}>
              <BinIcon />
            </IconButton>
          ) : undefined
        }
        onClose={handleClose}
        hasBorderTop
      >
        <ContainerDamageTypeForm
          id='ContainerDamageType-form'
          containerDamageType={containerDamageTypeDialogUIStore.editingEntity}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>

      <ConfirmationDialog
        open={containerDamageTypeDialogUIStore.isDeleteOpen}
        title={t('deleteName', 'Delete {name}', {
          name: t('type', 'type'),
        })}
        message={t(
          'entityWillBeDeleted',
          '{name} will be deleted and you cannot undo this action!',
          {
            name:
              containerDamageTypeDialogUIStore.editingEntity?.longName ?? t('ContainerDamageType'),
          },
        )}
        primaryActionText={t('delete', 'Delete')}
        closeLabel={t('keepEditing', 'Keep editing')}
        maxWidth='xs'
        blockClickOutside
        onConfirm={handleDelete}
        onClose={handleToggleDelete}
      />
    </>
  )
})
