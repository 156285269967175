import { AdminActionsApi, CheckinOrderCommand, CheckoutOrderCommand } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class AdminActionService {
  httpClient = createApiClient(AdminActionsApi)

  checkoutOrder = async (cmd: CheckoutOrderCommand) => {
    await this.httpClient.checkout(cmd)
  }

  checkinOrder = async (cmd: CheckinOrderCommand) => {
    await this.httpClient.checkin(cmd)
  }

  undoOrderCheckIn = async (inboundOrderId: number) =>
    await this.httpClient.undoCheckIn({ inboundOrderId })

  undoOrderCheckOut = async (outboundOrderId: number) => {
    await this.httpClient.undoCheckout({ orderId: outboundOrderId })
  }

  deleteByIds = async (ids: number[], deleteLinkedOrders?: boolean) =>
    await this.httpClient.deleteByIds({
      deleteLinkedOrders: deleteLinkedOrders,
      ids: ids,
    })
}

const adminActionService = new AdminActionService()

export default adminActionService
