import { VesselServiceResponseDto, VesselServicesApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetVesselServicesQuery implements IQueryMessage {
  static type = 'GetVesselServicesQuery'
  type = GetVesselServicesQuery.type

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(VesselServicesApi)

export const getVesselServicesQueryHandler: IQueryHandler<
  GetVesselServicesQuery,
  IEvent<VesselServiceResponseDto[]>
> = async () => {
  const { data } = await httpClient.get()

  return new Event(GetVesselServicesQuery.type, data)
}
