import { createContext, ReactNode, useContext } from 'react'
import { ContainerMovementDialogStore } from './stores/ContainerMovementDialogStore'
import {
  planContainerPositionDialogStore,
  PlanContainerPositionDialogStore,
} from './stores/PlanContainerPositionDialogStore'

type IStore = {
  containerMovementDialogStore: ContainerMovementDialogStore
  planContainerPositionDialogStore: PlanContainerPositionDialogStore
}

const containerMovementDialogStore = new ContainerMovementDialogStore()

export type IAppStore = IStore

const store: IAppStore = {
  containerMovementDialogStore: containerMovementDialogStore,
  planContainerPositionDialogStore: planContainerPositionDialogStore,
}

const UtilsContext = createContext<IAppStore>({ ...store })

export const UtilsStoreProvider = ({ children }: { children: ReactNode }) => {
  return <UtilsContext.Provider value={store}>{children}</UtilsContext.Provider>
}

export const useUtilsStore = () => useContext(UtilsContext)
