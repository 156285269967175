import { createSvgIcon } from '@mui/material'

export const EmptyContainerHandlerIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16 3C16 2.44772 16.4477 2 17 2H19C19.5523 2 20 2.44772 20 3V8H21C21.5523 8 22 8.44771 22 9V11C22 11.5523 21.5523 12 21 12H20V15H21C21.5523 15 22 15.4477 22 16V20C22 20.5523 21.5523 21 21 21H20.8293C20.9398 20.6872 21 20.3506 21 20C21 18.3431 19.6569 17 18 17C16.3431 17 15 18.3431 15 20C15 20.3506 15.0602 20.6872 15.1707 21H9.82929C9.93985 20.6872 10 20.3506 10 20C10 18.3431 8.65685 17 7 17C5.34315 17 4 18.3431 4 20C4 20.3506 4.06015 20.6872 4.17071 21H3C2.44772 21 2 20.5523 2 20V16C2 15.4477 2.44772 15 3 15V8C3 7.44772 3.44772 7 4 7H10.8444C11.1899 7 11.511 7.17835 11.6935 7.4717L16 14.3929V3Z'
      fill='currentColor'
    />
    <path
      d='M20 20C20 21.1046 19.1046 22 18 22C16.8954 22 16 21.1046 16 20C16 18.8954 16.8954 18 18 18C19.1046 18 20 18.8954 20 20Z'
      fill='currentColor'
    />
    <path
      d='M7 22C8.10457 22 9 21.1046 9 20C9 18.8954 8.10457 18 7 18C5.89543 18 5 18.8954 5 20C5 21.1046 5.89543 22 7 22Z'
      fill='currentColor'
    />
  </svg>,
  'EmptyContainerHandler',
)
