import { Typography } from '@mui/material'
import { YardPositionDto } from '@storage/app/api'
import { YardOperationControlProps } from '@storage/features/yard-operation-control/interface/yard-operation-control-interface'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { DropDownSelectContainer } from './Variants/DropDownSelect/DropDownSelectContainer'
import { YardPositionInputContainer } from './Variants/YardPositionInput/YardPositionInputContainer'
import { YardPositionSelectContainer } from './Variants/YardPositionSelect/YardPositionSelectContainer'
import { YardPositionInputMode } from './yard-operation-control.store'

const YardOperationControl = observer(
  ({
    containerNumber,
    hasError,
    handleChange,
    orderId,
    ignoreCheck,
  }: YardOperationControlProps) => {
    const variant = 3

    const { t } = useTranslate()
    const { palette } = useMinimalsTheme()
    const { yardOperationControlStore: store } = useStores()
    const { snackbarStore } = useStores()

    const jobsDataMapKey = store.getJobsDataMapKey(containerNumber, orderId)

    const handleError = useCallback(
      (msg: string) => {
        snackbarStore.showMessage(t(msg, `An error occurred: ${msg}`), 'error')
      },
      [snackbarStore, t],
    )

    const handleOnChange = (value?: YardPositionDto) => {
      if (jobsDataMapKey) {
        store.updateJobLocation(jobsDataMapKey, value)
        store.handleChange?.(value)
      }
    }

    useEffect(() => {
      if (jobsDataMapKey) {
        const existingData = store.jobsDataMap.get(jobsDataMapKey)
        if (!existingData) {
          store.setJobData(jobsDataMapKey, {
            containerNumber,
            orderId,
            mode: YardPositionInputMode.StackIn,
          })
        }
        store.setCallback(handleChange, handleError)
      }
    }, [containerNumber, orderId, handleChange, handleError, store, jobsDataMapKey])

    useEffect(() => {
      const loadData = async () => {
        if (jobsDataMapKey) {
          try {
            await store.loadTurnoverData(jobsDataMapKey)
          } catch (_) {
            handleError(t('databaseIOError', 'database read/write error'))
          }
        }
      }

      loadData()
    }, [store, handleError, t, jobsDataMapKey])

    const turnoverData = store.getTurnoverDataByOrderId(jobsDataMapKey)

    const featureComponent = (variant: number) => {
      switch (variant) {
        case 1:
          return <DropDownSelectContainer turnoverData={turnoverData} handleError={handleError} />
        case 2:
          return (
            <YardPositionSelectContainer
              plannedPosition={turnoverData?.plannedPosition ?? undefined}
              locationSize={turnoverData?.locationSize}
              handleError={handleError}
              onChange={handleOnChange}
            />
          )
        case 3:
          return (
            <YardPositionInputContainer
              plannedPosition={turnoverData?.plannedPosition ?? undefined}
              hasError={hasError}
              handleError={handleError}
              onChange={handleOnChange}
              turnover={turnoverData?.turnover ?? undefined}
            />
          )
        default:
          return <Typography variant='h1'>component not defined</Typography>
      }
    }

    return (
      <>
        {turnoverData &&
          (ignoreCheck || turnoverData?.turnover ? (
            featureComponent(variant)
          ) : (
            <Typography variant='h6' color={palette.error.main}>
              {t(
                'containerNotInYard',
                `The container ${containerNumber} is not located on terminal.`,
              )}
            </Typography>
          ))}
      </>
    )
  },
)

export default YardOperationControl
