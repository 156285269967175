import {
  AllocationDestinationAndStackingStrategyDto,
  AllocationDestinationAndStackingStrategyDtoDestination,
  AllocationRulePropertiesDto,
  AllocationRulesTemplateCreateRequest,
  AllocationRulesTemplateUpdateRequest,
  AllocationRuleTemplateDto,
  CarrierType,
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
  RuleFacets,
  YardPositionDto,
} from '@storage/app/api'
import { v4 as uuidv4 } from 'uuid'

import { AllSelectOption, BooleanSelectOption } from '@storage/app/models'
import { AllValue } from '@storage/components/form-controls/constants'
import { mapCarrierVisitDiscriminatorToCarrierType } from '@storage/pages/yard-planning-dashboard-details/mappers'
import { UnallocatedTurnoversBreakDown } from '@storage/stores/carrier-visit.store'
import { UtilityMappers } from '@storage/utils/mappers'
import { mapBooleanToBooleanSelectOption } from '@storage/utils/mappers/map-boolean-to-boolean-select-option.mapper'
import { mapBooleanToBooleanOption } from '@storage/utils/mappers/map-boolean-to-boolean.mapper'
import {
  AllocationRuleTemplateFormProfile,
  defaultValues,
  FacetsFormProfile,
} from './allocation-rule-templates-form.profile'

export const mapYardPositionDescriptorToYardPositionDto = (descriptor: string): YardPositionDto => {
  const [block, bay, row, tierStr] = descriptor.split('.')

  let tier: number | null = null
  if (tierStr && !isNaN(parseInt(tierStr, 10))) {
    tier = parseInt(tierStr, 10)
  }

  return {
    block: block || null,
    bay: bay || null,
    row: row || null,
    tier: tier,
  }
}

export const mapProfileCarrierTypeToDtoCarrierType = (
  carrierType: CarrierType | AllSelectOption,
): CarrierType => {
  return carrierType === 'All' ? CarrierType.Universal : carrierType
}

export const mapDtoCarrierTypeToProfileCarrierType = (
  carrierType?: CarrierType | null,
): CarrierType | AllSelectOption | null | undefined => {
  return carrierType === CarrierType.Universal ? 'All' : carrierType
}

export const mapFacetsFormProfileToAllocationRuleTemplateDtoFacets = (
  facetsFormProfile: FacetsFormProfile,
  direction?: CarrierVisitDirection,
): RuleFacets => ({
  size: UtilityMappers.mapOptionalFieldValueToNumber(facetsFormProfile.size) ?? 20,
  containerOperator: facetsFormProfile.containerOperator.trim(),
  isEmpty: UtilityMappers.mapOptionalFieldValueToBoolean(facetsFormProfile.isEmpty),
  isReefer: UtilityMappers.mapOptionalFieldValueToBoolean(facetsFormProfile.isReefer),
  isDangerous: UtilityMappers.mapOptionalFieldValueToBoolean(facetsFormProfile.isDangerous),
  isOOG: UtilityMappers.mapOptionalFieldValueToBoolean(facetsFormProfile.isOOG),
  containerHeight: UtilityMappers.mapOptionalFieldValueToString(facetsFormProfile.containerHeight),
  containerType: UtilityMappers.mapOptionalFieldValueToString(facetsFormProfile.containerType),
  weightClasses: facetsFormProfile.weightClasses,
  consignee: facetsFormProfile.consignee.trim(),
  portOfDischarge: facetsFormProfile.portOfDischarge.trim(),
  outboundCarrierType:
    direction === CarrierVisitDirection.Outbound
      ? mapProfileCarrierTypeToDtoCarrierType(facetsFormProfile.carrierType)
      : null,
  inboundCarrierType:
    direction === CarrierVisitDirection.Inbound
      ? mapProfileCarrierTypeToDtoCarrierType(facetsFormProfile.carrierType)
      : null,
  customerId: facetsFormProfile.customer !== '' ? parseInt(facetsFormProfile.customer, 10) : null,
  containerNumber: facetsFormProfile.containerNumber,
})

export const mapAllocationRuleTemplateDtoFacetsToFacetsFormProfile = (
  AllocationRuleTemplateDtoFacets: RuleFacets,
): FacetsFormProfile => ({
  size: AllocationRuleTemplateDtoFacets.size?.toString() ?? defaultValues.facets.size,
  containerOperator: AllocationRuleTemplateDtoFacets.containerOperator ?? '',
  isEmpty: mapBooleanToBooleanSelectOption(AllocationRuleTemplateDtoFacets.isEmpty),
  isReefer: mapBooleanToBooleanOption(AllocationRuleTemplateDtoFacets.isReefer),
  isDangerous: mapBooleanToBooleanOption(AllocationRuleTemplateDtoFacets.isDangerous),
  isOOG: mapBooleanToBooleanOption(AllocationRuleTemplateDtoFacets.isOOG),
  containerHeight:
    AllocationRuleTemplateDtoFacets.containerHeight ?? defaultValues.facets.containerHeight,
  containerType:
    AllocationRuleTemplateDtoFacets.containerType ?? defaultValues.facets.containerType,
  weightClasses: AllocationRuleTemplateDtoFacets.weightClasses,
  consignee: AllocationRuleTemplateDtoFacets.consignee ?? '',
  portOfDischarge: AllocationRuleTemplateDtoFacets.portOfDischarge ?? '',
  carrierType: mapAllocationRuleTemplateDtoFacetCarrierTypeToProfileCarrierType(
    AllocationRuleTemplateDtoFacets.inboundCarrierType,
    AllocationRuleTemplateDtoFacets.outboundCarrierType,
  ),
  customer: AllocationRuleTemplateDtoFacets.customerId
    ? AllocationRuleTemplateDtoFacets.customerId.toString()
    : defaultValues.facets.customer,
  containerNumber:
    AllocationRuleTemplateDtoFacets.containerNumber ?? defaultValues.facets.containerNumber,
})

export const mapAllocationRuleTemplateDtoFacetCarrierTypeToProfileCarrierType = (
  inboundCarrierType?: CarrierType | null,
  outboundCarrierType?: CarrierType | null,
): CarrierType | AllSelectOption => {
  return (
    mapDtoCarrierTypeToProfileCarrierType(inboundCarrierType) ??
    mapDtoCarrierTypeToProfileCarrierType(outboundCarrierType) ??
    defaultValues.facets.carrierType
  )
}

export const mapAllocationRuleTemplateYardPositionToYardPositionDto = (
  block: string,
  bayMin?: string | null,
  bayMax?: string | null,
  rowMin?: string | null,
  rowMax?: string | null,
): YardPositionDto => {
  const bayPart = bayMin && bayMax ? `${bayMin}-${bayMax}` : bayMin
  const rowPart = rowMin && rowMax ? `${rowMin}-${rowMax}` : rowMin
  return {
    block,
    bay: bayPart,
    row: rowPart,
  }
}

export const mapAllocationRuleTemplateYardPositionToYardPositionDescriptor = (
  block: string,
  bayMin?: string | null,
  bayMax?: string | null,
  rowMin?: string | null,
  rowMax?: string | null,
): string => {
  const bayPart = bayMin && bayMax ? `${bayMin}-${bayMax}` : bayMin ?? ''
  const rowPart = rowMin && rowMax ? `${rowMin}-${rowMax}` : rowMin ?? ''

  const parts = [block, bayPart, rowPart].filter(Boolean)

  return parts.join('.')
}

export const mapYardPositionDtoToYardPositionDescriptor = (
  destinationAndStackingStrategies: AllocationDestinationAndStackingStrategyDto[],
): string[] => {
  const result: string[] = []
  if (!destinationAndStackingStrategies || destinationAndStackingStrategies.length === 0)
    return result
  const destinations = destinationAndStackingStrategies.map(d => d.destination)
  destinations.forEach(destination => {
    const { block, bay, row, tier } = destination
    const parts = []

    if (block) parts.push(block)
    if (bay) parts.push(bay)
    if (row) parts.push(row)
    if (tier !== null && tier !== undefined) parts.push(tier.toString())

    result.push(parts.join('.'))
  })

  return result
}

export const mapAllocationRuleTemplateDtoToFormValues = ({
  id,
  name,
  facets,
  destinationAndStackingStrategies,
}:
  | AllocationRuleTemplateDto
  | CarrierVisitAllocationRuleDto): AllocationRuleTemplateFormProfile => ({
  id,
  name,
  facets: mapAllocationRuleTemplateDtoFacetsToFacetsFormProfile(facets),
  destinationAndStackingStrategies,
})

export const mapFormValuesToAllocationRuleTemplateCreateRequest = ({
  name,
  facets,
  destinationAndStackingStrategies,
}: AllocationRuleTemplateFormProfile): AllocationRulesTemplateCreateRequest => ({
  name,
  facets: mapFacetsFormProfileToAllocationRuleTemplateDtoFacets(facets),
  destinationAndStackingStrategies,
})

export const mapFormValuesToAllocationRuleTemplateUpdateRequest = ({
  id,
  name,
  facets,
  destinationAndStackingStrategies,
}: AllocationRuleTemplateFormProfile): AllocationRulesTemplateUpdateRequest => ({
  id: id!,
  name,
  facets: mapFacetsFormProfileToAllocationRuleTemplateDtoFacets(facets),
  destinationAndStackingStrategies: destinationAndStackingStrategies,
})

export const mapSettingsToAllocationRuleTemplateFormProfile = (
  breakdownItem: UnallocatedTurnoversBreakDown,
): AllocationRuleTemplateFormProfile => {
  let mappedIsDangerous: BooleanSelectOption | AllSelectOption = AllValue
  let mappedIsOOG: BooleanSelectOption | AllSelectOption = AllValue
  let mappedIsReefer: BooleanSelectOption | AllSelectOption = AllValue
  let mappedIsEmpty: BooleanSelectOption | AllSelectOption = AllValue

  if (breakdownItem.isDangerous !== undefined) {
    if (breakdownItem.isDangerous) {
      mappedIsDangerous = BooleanSelectOption.True
    } else {
      mappedIsDangerous = BooleanSelectOption.False
    }
  }

  if (breakdownItem.isOOG !== undefined) {
    if (breakdownItem.isOOG) {
      mappedIsOOG = BooleanSelectOption.True
    } else {
      mappedIsOOG = BooleanSelectOption.False
    }
  }

  if (breakdownItem.isReefer !== undefined) {
    if (breakdownItem.isReefer) {
      mappedIsReefer = BooleanSelectOption.True
    } else {
      mappedIsReefer = BooleanSelectOption.False
    }
  }

  if (breakdownItem.isEmpty !== undefined) {
    if (breakdownItem.isEmpty) {
      mappedIsEmpty = BooleanSelectOption.True
    } else {
      mappedIsEmpty = BooleanSelectOption.False
    }
  }

  return {
    name: '',
    facets: {
      ...defaultValues.facets,
      size: breakdownItem.size ? String(breakdownItem.size) : defaultValues.facets.size,
      isDangerous: mappedIsDangerous === AllValue ? BooleanSelectOption.False : mappedIsDangerous,
      isOOG: mappedIsOOG === AllValue ? BooleanSelectOption.False : mappedIsOOG,
      isReefer: mappedIsReefer === AllValue ? BooleanSelectOption.False : mappedIsReefer,
      isEmpty: mappedIsEmpty === AllValue ? 'All' : mappedIsEmpty,
      consignee: breakdownItem.consignee ?? '',
      carrierType: breakdownItem.outboundCarrierType ?? 'All',
      portOfDischarge: breakdownItem.portOfDischarge ?? '',
      weightClasses: breakdownItem.weightClass ? [breakdownItem.weightClass] : [],
      customer: breakdownItem.customer ?? defaultValues.facets.customer,
      containerNumber: breakdownItem.containerNumber ?? defaultValues.facets.containerNumber,
    },
    destinationAndStackingStrategies: [],
  }
}

export const mapCVAllocationRuleToAllocationRulePropertiesDto = (
  { id, facets }: CarrierVisitAllocationRuleDto,
  carrierVisitId: number,
  carrierVisitDirection: CarrierVisitDirection,
): AllocationRulePropertiesDto => ({
  allocationRuleId: id,
  carrierVisitId,
  carrierVisitDirection,
  facets,
})

export const mapAllocationRuleTemplateFormProfileToCarrierVisitAllocationRuleDto = (
  { id, name, facets, destinationAndStackingStrategies }: AllocationRuleTemplateFormProfile,
  direction: CarrierVisitDirection,
): CarrierVisitAllocationRuleDto => ({
  id: id || uuidv4(),
  name,
  facets: mapFacetsFormProfileToAllocationRuleTemplateDtoFacets(facets, direction),
  destinationAndStackingStrategies,
})

export const mapAllocationRuleTemplateToCarrierVisitAllocationRuleDto = (
  allocationRuleTemplateDto: AllocationRuleTemplateDto,
  destinationAndStackingStrategies: AllocationDestinationAndStackingStrategyDto[],
  handlingDirection: CarrierVisitDirection,
  discriminator: string,
): CarrierVisitAllocationRuleDto => ({
  id: uuidv4(),
  name: allocationRuleTemplateDto.name,
  facets: {
    ...allocationRuleTemplateDto.facets,
    inboundCarrierType:
      handlingDirection === CarrierVisitDirection.Inbound
        ? mapCarrierVisitDiscriminatorToCarrierType(discriminator)
        : null,
    outboundCarrierType:
      handlingDirection === CarrierVisitDirection.Outbound
        ? mapCarrierVisitDiscriminatorToCarrierType(discriminator)
        : null,
  },
  destinationAndStackingStrategies: destinationAndStackingStrategies.map(strategy => {
    const { destination } = strategy

    // Remove null values from destination
    if (destination) {
      ;(
        Object.keys(destination) as (keyof AllocationDestinationAndStackingStrategyDtoDestination)[]
      ).forEach(key => {
        if (destination[key] === null) {
          delete destination[key]
        }
      })
    }
    return {
      destination: strategy.destination,
      position: strategy.position,
      stackingStrategy: strategy.stackingStrategy,
    }
  }),
})
