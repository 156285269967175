import { useBillingServiceStore } from '@billing/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BinIcon, ConfirmationDialog, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CompanyFormMapper } from '../components/form/company-form.mapper'
import { CompanyFormProfile } from '../components/form/company-form.profile'
import { CompanyForm } from '../components/form/CompanyForm'

export const CompaniesDialogContainer = observer(() => {
  const { t } = useTranslate()
  const { appStore, companyStore, companyDialogUIStore } = useBillingServiceStore()

  const handleSubmit = async (formValues: CompanyFormProfile) => {
    if (companyDialogUIStore.formType === 'Add') {
      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.name} ${t('isBeingCreated', 'is being created')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await companyStore.add(CompanyFormMapper.mapFormValuesToCreateDto(formValues)),
          ),
        `${formValues.name} ${t('creationCancelled', 'creation cancelled')}`,
      )
    } else {
      appStore.showAlertForCancellableRequest(
        'success',
        `${formValues.name} ${t('isBeingUpdated', 'is being updated')}`,
        () =>
          appStore.triggerRequestWithoutLoader(
            async () =>
              await companyStore.update(CompanyFormMapper.mapFormValuesToUpdateDto(formValues)),
          ),
        `${formValues.name} ${t('updateCancelled', 'update cancelled')}`,
      )
    }

    //pass in companyDialogUIStore.editingEntity?.id is a workaround for title edit change to add before popup closing
    companyDialogUIStore.toggle(companyDialogUIStore.editingEntity?.id)
  }

  const handleClose = () => {
    //pass in companyDialogUIStore.editingEntity?.id is a workaround for title edit change to add before popup closing
    companyDialogUIStore.toggle(companyDialogUIStore.editingEntity?.id)
  }

  const handleTogleDelete = () => {
    companyDialogUIStore.toggle(companyDialogUIStore.editingEntity?.id)
    companyDialogUIStore.toggleDelete()
  }

  const handleCloseDelete = () => {
    companyDialogUIStore.toggleDelete()
  }

  const handleDelete = async () => {
    if (companyDialogUIStore.editingEntity) {
      const companyName = companyDialogUIStore.editingEntity.name
      await companyStore.delete(companyDialogUIStore.editingEntity.id)
      handleCloseDelete()

      appStore.setShowAlert('success', `${companyName} ${t('deleted', 'deleted')}`)
    }
  }

  return (
    <>
      <ConfirmationDialog
        open={companyDialogUIStore.isOpen}
        title={`${
          companyDialogUIStore.formType === 'Add' ? t('addNew', 'Add new') : t('edit', 'Edit')
        } ${t('company', 'Company')}`}
        primaryActionText={t('save', 'Save')}
        closeLabel={t('cancel', 'Cancel')}
        formId='company-form'
        maxWidth='md'
        rightRenderOption={
          companyDialogUIStore.formType === 'Edit' ? (
            <IconButton variant='contained' color='default' onClick={handleTogleDelete}>
              <BinIcon />
            </IconButton>
          ) : undefined
        }
        onClose={handleClose}
        hasBorderTop
      >
        <CompanyForm
          id='company-form'
          company={companyDialogUIStore.editingEntity}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>

      <ConfirmationDialog
        open={companyDialogUIStore.isDeleteOpen}
        title={t('deleteCompany', 'Delete company')}
        message={t(
          'entityWillBeDeleted',
          '{name} will be deleted and you cannot undo this action!',
          {
            name: companyDialogUIStore.editingEntity?.name ?? t('company'),
          },
        )}
        primaryActionText={t('delete', 'Delete')}
        closeLabel={t('keepEditing', 'Keep editing')}
        maxWidth='xs'
        blockClickOutside
        onConfirm={handleDelete}
        onClose={handleTogleDelete}
      />
    </>
  )
})
