import { UnallocatedTurnoversBreakDown } from '@storage/stores/carrier-visit.store'
import { useTranslate } from '@tolgee/react'

interface Props {
  breakdownItem: UnallocatedTurnoversBreakDown
}
export const TurnoverBreakdownLabel = ({ breakdownItem }: Props) => {
  const { t } = useTranslate()

  const labels: string[] = []

  labels.push(breakdownItem.size.toString())

  if (breakdownItem.isEmpty !== undefined) {
    breakdownItem.isEmpty ? labels.push(t('empty', 'Empty')) : labels.push(t('full', 'Full'))
  }

  if (breakdownItem.isReefer) {
    labels.push(t('Reefer', 'Reefer'))
  }

  if (breakdownItem.isDangerous) {
    labels.push(t('dangerous', 'Dangerous'))
  }

  if (breakdownItem.isOOG) {
    labels.push(t('oog', 'OOG'))
  }

  if (breakdownItem.consignee) {
    labels.push(breakdownItem.consignee)
  }

  if (breakdownItem.outboundCarrierType) {
    labels.push(t(breakdownItem.outboundCarrierType))
  }

  if (breakdownItem.portOfDischarge) {
    labels.push(breakdownItem.portOfDischarge)
  }

  if (breakdownItem.weightClass) {
    labels.push(t(breakdownItem.weightClass))
  }

  return <>{labels.join(' ')}</>
}
