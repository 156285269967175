import { Box, Chip, Typography } from '@mui/material'
import { ContainerDto, ContainerResponseDto } from '@planning/app/api'
import { useUnitTypeIcon } from '@planning/components/UnitType'
import { useMinimalsTheme } from '@tom-ui/ui'

interface IProps {
  container: ContainerDto | ContainerResponseDto
}

export const ContainerAutocompleteListItem = ({ container }: IProps) => {
  const unitTypeIcon = useUnitTypeIcon(container.unitType)
  const theme = useMinimalsTheme()

  return (
    <Box
      display='flex'
      alignItems='center'
      data-cy={`container-autocomplete-list-item-${container.number}`}
    >
      <Box gap={theme.customSpacing.xs} display='flex' alignItems='center'>
        {unitTypeIcon}
        <Typography mr='10px' variant='subtitle2'>
          {container.number}
        </Typography>
      </Box>
      {container.operator && (
        <Chip
          sx={{
            m: '0 5px',
          }}
          label={container.operator}
        />
      )}
      {container.isoCode && (
        <Chip
          sx={{
            m: '0 5px',
          }}
          label={container.isoCode}
        />
      )}
    </Box>
  )
}
