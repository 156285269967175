import { Box, Card, CardHeader, Chip, Container, Stack, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { OperationType } from '@operations/app/api'
import { getContainerHeight } from '@operations/features/equipmentOperator/utils'
import { formatContainerNumber } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { Tile, useContainerAttributesParameters, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

const CraneOperatorNoGeometryViewContainer = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { types: containerTypeParameters } = useContainerAttributesParameters()

  const { craneOperatorNoGeometryUIStore } = useOperationsStore()

  const handleClick = (operationType: OperationType) => {
    craneOperatorNoGeometryUIStore.setSelectedOperationType(operationType)
  }

  const getColor = (operationType: OperationType) => {
    return craneOperatorNoGeometryUIStore.selectedOperationType === operationType
      ? 'primary'
      : 'default'
  }

  return (
    <Container sx={{ paddingY: theme.customSpacing.m }}>
      <Stack gap={theme => theme.customSpacing.m}>
        <Stack direction='row' gap={theme.customSpacing.m}>
          <Chip
            label={t('discharge', 'Discharge')}
            onClick={() => handleClick(OperationType.Inbound)}
            color={getColor(OperationType.Inbound)}
          />
          <Chip
            label={t('load', 'Load')}
            onClick={() => handleClick(OperationType.Outbound)}
            color={getColor(OperationType.Outbound)}
          />
        </Stack>

        {craneOperatorNoGeometryUIStore.filteredWorkInstructions?.length ? (
          craneOperatorNoGeometryUIStore.filteredWorkInstructions.map(item => (
            <Card sx={{ width: '100%', height: '100%' }} key={item.id}>
              <CardHeader
                title={
                  <Typography variant='h3'>
                    {item.cargoUnit?.containerNumber
                      ? formatContainerNumber(item.cargoUnit.containerNumber)
                      : t('noContainerNumber', 'No container no.')}
                  </Typography>
                }
                sx={{ paddingBottom: theme => theme.customSpacing.xs }}
              />

              <Box
                sx={{
                  padding: theme.customSpacing.m,
                  gap: theme.customSpacing.xl,
                  display: 'flex',
                  backgroundColor: theme.palette.grey[100],
                  overflowX: 'auto',
                }}
              >
                <Tile
                  nowrap
                  label={t('state', 'State')}
                  value={item.cargoUnit?.isEmpty ? t('empty', 'Empty') : t('full', 'Full')}
                />
                <Tile
                  nowrap
                  label={t('weight', 'Weight')}
                  value={item.cargoUnit?.grossWeight ? `${item.cargoUnit.grossWeight} kg` : ' - '}
                />
                <Tile
                  nowrap
                  label={t('iso', 'Iso')}
                  value={item.cargoUnit?.containerIsoCode ? item.cargoUnit.containerIsoCode : ' - '}
                />
                <Tile
                  nowrap
                  label={t('length', 'Length')}
                  value={item.cargoUnit?.length ? item.cargoUnit.length : ' - '}
                />
                <Tile
                  nowrap
                  label={t('high', 'High')}
                  value={item.cargoUnit?.height ? getContainerHeight(item.cargoUnit.height) : ' - '}
                />
                <Tile
                  nowrap
                  label={t('type', 'Type')}
                  value={
                    item.cargoUnit?.type
                      ? containerTypeParameters[item.cargoUnit.type].label
                      : ' - '
                  }
                />

                {item.cargoUnit?.isDangerous && (
                  <Tile
                    nowrap
                    label={t('handlingInstructions', 'Handling instructions')}
                    value={t('dangerous', 'Dangerous')}
                  />
                )}
                {item.operationType === OperationType.Outbound && (
                  <Tile
                    nowrap
                    label={t('pod', 'POD')}
                    value={item.cargoUnit?.portOfDischarge ?? ' - '}
                  />
                )}
              </Box>
            </Card>
          ))
        ) : (
          <h2>{t('noDataForThisEquipment', 'No Data for this equipment')}</h2>
        )}
      </Stack>
    </Container>
  )
})

export default CraneOperatorNoGeometryViewContainer
