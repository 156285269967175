import { CarrierVisitDirection, HoldStatus, OrderResponseDto } from '@planning/app/api'
import { IContainerJourneyItem } from '@planning/pages/Issues/Stores/ContainerJourneyItem'
import { ContainerJourneyItemStore } from '@planning/pages/Issues/Stores/ContainerJourneyItemStore'
import { IIssueItem } from '@planning/pages/Issues/Stores/IssueItem'
import { IssueItemStore } from '@planning/pages/Issues/Stores/IssueItemStore'
import _ from 'lodash'
import { computed, makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { IContainerItem } from '../container/ContainerItem'
import { IOrderUpdateItem } from '../orderUpdate/OrderUpdateItem'
import { OrderUpdateItemStore } from '../orderUpdate/OrderUpdateItemStore'
import { IRailcarTrackPositionItem } from '../railTrack/RailcarTrackPositionItem'
import { RailcarTrackPositionItemStore } from '../railTrack/RailcarTrackPositionItemStore'
import { IRailVisitItem } from '../railVisit/RailVisitItem'
import { ITruckVisitItem } from '../truckVisit/TruckVisitItem'
import { IEntity } from '../types'
import { IVesselVisitItem } from '../vesselVisit/VesselVisitItem'
import { OrderItemStore } from './OrderItemStore'

export interface IOrderItem extends IEntity<OrderResponseDto> {
  visit: IVesselVisitItem | IRailVisitItem | ITruckVisitItem | undefined
  container: IContainerItem | undefined
  railcarTrackPosition: IRailcarTrackPositionItem | undefined
  notResolvedIssues?: IIssueItem[]
  hasNotResolvedIssues: boolean
  hasMissingPosition?: boolean
  hasActiveHold?: boolean
  unreadOrderUpdates?: IOrderUpdateItem[]
  containerJourney?: IContainerJourneyItem
}

export class OrderItem extends EntityItem<OrderResponseDto> implements IOrderItem {
  constructor(
    private itemStore: OrderItemStore,
    private railcarTrackPositionStore: RailcarTrackPositionItemStore,
    private issueStore: IssueItemStore | undefined,
    private orderUpdateStore: OrderUpdateItemStore | undefined,
    private containerJourneyItemStore: ContainerJourneyItemStore | undefined,
    id: number,
    data: OrderResponseDto,
  ) {
    super(id, data)
    makeObservable(this, {
      visit: computed,
      container: computed,
      railcarTrackPosition: computed,
      notResolvedIssues: computed,
      hasNotResolvedIssues: computed,
      unreadOrderUpdates: computed,
      containerJourney: computed,
      hasMissingPosition: computed,
      hasActiveHold: computed,
    })
  }

  get visit() {
    return this.data.carrierVisitId
      ? this.itemStore.carrierVisitById[this.data.carrierVisitId]
      : undefined
  }

  get container() {
    return this.data.containerId && this.itemStore.containerById
      ? this.itemStore.containerById[this.data.containerId]
      : undefined
  }

  get railcarTrackPosition() {
    return this.railcarTrackPositionStore.elements[this.data.railcarTrackPositionId ?? -1]
  }

  get unreadOrderUpdates() {
    return _(this.orderUpdateStore?.elements)
      .filter(i => i.data.orderId === this.data.id)
      .value()
  }

  get notResolvedIssues() {
    return _(this.issueStore?.elements)
      .filter(e => !!this.data.notResolvedIssueIds?.includes(e.id))
      .value()
  }

  get hasNotResolvedIssues() {
    return (this.notResolvedIssues?.length ?? 0) > 0
  }

  get containerJourney() {
    if (!this.containerJourneyItemStore) return
    if (!this.data.containerId) return

    if (this.data.direction === CarrierVisitDirection.Inbound)
      return _(this.containerJourneyItemStore.journeysByInboundOrderId).get(this.data.id)
    else return _(this.containerJourneyItemStore.journeysByOutboundOrderId).get(this.data.id)
  }

  get hasMissingPosition() {
    return (
      !this.data.yardLocation &&
      !this.containerJourney?.data.plannedYardLocation &&
      this.data.direction === CarrierVisitDirection.Inbound
    )
  }

  get hasActiveHold() {
    return !!this.data.holds?.some(x => x.status === HoldStatus.Active)
  }
}
