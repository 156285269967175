import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
} from '@mui/material'
import { AllocationDestinationAndStackingStrategyDto } from '@storage/app/api'
import { AllocationRuleDestinationsList } from '@storage/features/carrier-visit-allocation-rules/components/AllocationRuleDestinationsList'
import { AllocationRuleTemplateFormProfile } from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'
import { CarrierVisitAllocationRuleForm } from '@storage/features/carrier-visit-allocation-rules/forms/carrier-visit-allocation-rule-form/CarrierVisitAllocationRuleForm'
import { useStores } from '@storage/hooks/use-stores.hook'
import { GlobalAllocationRulesUIStore } from '@storage/pages/global-allocation-rules/stores/global-allocation-rules.ui-store'
import { BaseAllocationRulesUIStore } from '@storage/stores/base-allocation-rules.ui-store'
import { AllocationRuleDto } from '@storage/types'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { HighlightedDestination } from '../../interfaces'

interface AllocationRulePanelFormProps<T extends AllocationRuleDto> {
  uiStore: BaseAllocationRulesUIStore<T>
  formId: string
  allocationRuleForm: any
  destinationAndStackingStrategiesFormValue: AllocationDestinationAndStackingStrategyDto[]
  onSubmit: (formValues: AllocationRuleTemplateFormProfile) => void
  onEditDestination: (id: number) => void
  onAddDestination: () => void
  onDeleteDestination: (id: number) => void
  onReorderDestination: (startIndex: number, endIndex: number) => void
}

export const AllocationRulePanelForm = observer(
  <T extends AllocationRuleDto>({
    uiStore,
    formId,
    allocationRuleForm,
    destinationAndStackingStrategiesFormValue,
    onAddDestination,
    onEditDestination,
    onDeleteDestination,
    onReorderDestination,
    onSubmit,
  }: AllocationRulePanelFormProps<T>) => {
    const { t } = useTranslate()
    const { manualInputValidationStore, weightClassContainerUIStore, companyStore } = useStores()

    const handleCancel = () => {
      uiStore.setOpenDialogForOnlyContainerNumber(false)
      uiStore.setShowAllocationRuleForm(false)
    }

    // Highlight the selected destinations on rule level (fill + border)
    useEffect(() => {
      if (uiStore.showDestinationForm) {
        return
      }
      const formDestinations: HighlightedDestination[] =
        destinationAndStackingStrategiesFormValue.map(strategy => ({
          destination: strategy.destination,
          fill: true,
          border: true,
        }))
      uiStore.setDestinationsToHighlight(formDestinations)
    }, [destinationAndStackingStrategiesFormValue, uiStore])

    const allocationRuleDestinationsErrorMsg =
      allocationRuleForm.formState.errors?.destinationAndStackingStrategies?.message

    return (
      <Card sx={{ overflow: 'auto' }}>
        <CardHeader title={t('createRule', 'Create rule')} />
        <CardContent>
          <CarrierVisitAllocationRuleForm
            allocationRuleForm={allocationRuleForm}
            id={formId}
            manualInputValidationStore={manualInputValidationStore}
            onSubmit={onSubmit}
            weightClassUIStore={weightClassContainerUIStore}
            companyStore={companyStore}
            openWithOnlyContainerNumber={uiStore.openDialogForOnlyContainerNumber}
            showCarrierTypeField={uiStore instanceof GlobalAllocationRulesUIStore}
          />

          <Box>
            <AllocationRuleDestinationsList
              label={t('destinations', 'Destinations')}
              destinationAndStackingStrategies={destinationAndStackingStrategiesFormValue}
              onAdd={onAddDestination}
              onEdit={onEditDestination}
              onDelete={onDeleteDestination}
              onReorder={onReorderDestination}
            />
          </Box>

          {allocationRuleDestinationsErrorMsg && (
            <Alert sx={{ boxShadow: 'none' }} severity='warning'>
              {t(allocationRuleDestinationsErrorMsg, 'At least one destination is required')}
            </Alert>
          )}
        </CardContent>
        <Divider />
        <CardActions>
          <Stack direction='row' gap={2} m={1}>
            <Button
              variant='contained'
              color='inherit'
              type='submit'
              form={formId}
              data-cy='confirm-allocation-rule-button'
            >
              {t('confirm', 'Confirm')}
            </Button>
            <Button onClick={handleCancel} color='inherit'>
              {t('cancel', 'Cancel')}
            </Button>
          </Stack>
        </CardActions>
      </Card>
    )
  },
)
