import { Button, Stack } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { Header, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { VesselViewStore } from '../../stores/VesselViewStore'

interface Props {
  viewStore: VesselViewStore
}
export const VesselHeader = observer(({ viewStore }: Props) => {
  const { t } = useTranslate()
  const { authStore } = usePlanningStore()
  const theme = useMinimalsTheme()

  return (
    <Header
      title={t('vessels', 'Vessels')}
      searchInputLabel={t('searchVesselByNameOrShortName', 'Search vessel by name or short name')}
      onSearchFieldChange={(value: string) => {
        viewStore.setFilter(value)
      }}
      displaySearchIcon
      enableSearchField
      rightRenderOption={
        authStore.hasPermission('write:vessels') && (
          <Stack flexDirection='row' gap={theme.customSpacing.xs}>
            <Button
              color='inherit'
              variant='contained'
              sx={{ flex: 'none' }}
              startIcon={<PlusIcon />}
              onClick={() => viewStore.toggleDialog(true)}
            >
              {t('addNew', 'Add new')}
            </Button>
          </Stack>
        )
      }
    />
  )
})
