import {
  BookingsResponseDto,
  CarrierVisitDirection,
  ContainerJourneyDto,
  OrderResponseDto,
  OrderStatus,
} from '@planning/app/api'
import { SimpleListStore } from '@planning/components/list/SimpleListStore'
import { orderService } from '@planning/services'
import bookingsService from '@planning/services/bookingsService'
import generalCargoService from '@planning/services/generalCargoService'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { OrderContainerSearchType, OrderSearchType } from './GateInViewStore'

export class DropOffOrderSearchStore extends SimpleListStore<
  ContainerJourneyDto | OrderResponseDto | BookingsResponseDto
> {
  containerSearchType: OrderContainerSearchType = 'containerNumber'
  searchType: OrderSearchType = 'container'
  ordersByContainerNumber: OrderResponseDto[] = []

  constructor() {
    super(async (filter: string) => {
      if (filter && filter.trim() !== '') {
        if (this.searchType === 'generalCargo') {
          const generalCargoOrders = (
            await generalCargoService.getByCustomerOrReferenceNumber(
              filter,
              CarrierVisitDirection.Inbound,
            )
          ).filter(o => !o.carrierVisitId && o.status === OrderStatus.Open) as ContainerJourneyDto[]

          return _(generalCargoOrders)
            .sortBy(o => o.referenceNumber)
            .value()
        } else {
          if (this.containerSearchType === 'containerNumber') {
            const dropOffOrders = await orderService.dropOffOrdersContainerJourney(filter)

            const orders = await orderService.getByContainerNumber(filter, OrderStatus.Open)
            runInAction(() => {
              this.ordersByContainerNumber = orders
            })

            const filteredData: Record<string, ContainerJourneyDto> = {}
            const getItemKey = (
              direction: CarrierVisitDirection,
              id?: number,
              linkedId?: number,
            ) => {
              const inboundId = direction === CarrierVisitDirection.Inbound ? id : linkedId
              const outboundId = direction === CarrierVisitDirection.Outbound ? id : linkedId
              return `${inboundId}_${outboundId}`
            }

            dropOffOrders.forEach((item: ContainerJourneyDto) => {
              if (item.containerNumber) {
                const itemKey = getItemKey(item.direction, item.id, item.linkedOrder?.id)
                if (filteredData[itemKey]) {
                  if (item.direction === 'Inbound' && item.linkedOrder !== null) {
                    filteredData[itemKey] = item
                  }
                } else {
                  filteredData[itemKey] = item
                }
              }
            })

            return Object.values(filteredData)
          } else {
            const bookings = await bookingsService.getByPartialNumber(filter)

            return bookings
          }
        }
      }

      return []
    })

    makeObservable(this, {
      containerSearchType: observable,
      searchType: observable,
      ordersByContainerNumber: observable,

      orders: computed,
      bookings: computed,

      setContainerSearchType: action,
      setSearchType: action,
    })
  }

  private isBookingItem = (item: ContainerJourneyDto | OrderResponseDto | BookingsResponseDto) =>
    'id' in item === false

  private isDummyOrder = (item: ContainerJourneyDto | OrderResponseDto | BookingsResponseDto) =>
    'containerNumber' in item && !item.containerNumber

  get orders() {
    return this.items.filter(o => !this.isBookingItem(o)).map(o => o as OrderResponseDto)
  }

  get bookings() {
    return this.items.filter(this.isBookingItem).map(o => o as BookingsResponseDto)
  }

  get ordersForReferenceNumber() {
    return _(this.items.filter(x => !!x.referenceNumber && !this.isDummyOrder(x)))
      .uniqBy(x => x.referenceNumber)
      .orderBy()
      .value()
  }

  setContainerSearchType = (searchType: OrderContainerSearchType) => {
    this.containerSearchType = searchType
  }

  setSearchType = (searchType: OrderSearchType) => {
    this.reset()
    this.searchType = searchType

    if (searchType === 'generalCargo') {
      this.setContainerSearchType('referenceNumber')
    } else {
      this.setContainerSearchType('containerNumber')
    }
  }
}
