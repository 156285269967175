import {
  CreateLocationCodeCommand,
  LocationCodesApi,
  UpdateLocationCodeCommand,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class LocationCodeService {
  httpClient = createApiClient(LocationCodesApi)

  getMatchedLocationCodes = async (
    filter?: string,
    isFilterByCode?: boolean,
    onlyShowActive?: boolean,
    take?: number,
  ) => {
    const { data } = await this.httpClient.getMatchedLocationCodes(
      filter,
      isFilterByCode,
      onlyShowActive,
      take,
    )

    return data
  }
  get = async () => {
    const { data } = await this.httpClient.getAll()

    return data
  }

  post = async (cmd: CreateLocationCodeCommand) => {
    await this.httpClient.create(cmd)
  }

  put = async (cmd: UpdateLocationCodeCommand) => {
    await this.httpClient.update(cmd)
  }
}

const locationCodeService = new LocationCodeService()

export default locationCodeService
