import { AppStore } from '@tom-ui/utils'
import { AxiosResponse } from 'axios'

export const onFulfilled = (appStore: AppStore) => {
  return (response: AxiosResponse<any>) => {
    appStore.decreaseLoadingCounter()

    return response
  }
}

export const onRejected = (appStore: AppStore) => {
  return (error: any) => {
    appStore.decreaseLoadingCounter()

    if (error?.response?.status !== 500) {
      appStore.setShowAlert(
        'error',
        JSON.stringify(error?.response?.data?.errors) ??
          error?.response?.data?.detail ??
          JSON.stringify(error?.message),
        null,
        true,
      )
    } else if (error?.response?.status === 500) {
      appStore.setShowAlert('error', 'Something went wrong', null, true)
    }

    return Promise.reject(error)
  }
}
