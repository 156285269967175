import { Box, Typography } from '@mui/material'
import { NonNumericOrderDto } from '@planning/app/api'
import { NonNumericOrderWithPickUpAmount } from '@planning/stores/truckAppointment/TruckAppointmentDetailsViewStore'
import { useTranslate } from '@tolgee/react'
import {
  ColoredLabel,
  GeneralIcon,
  LengthIcon,
  useContainerAttributesParameters,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { NNROrderHandlingBoxFooter } from './NNROrderHandlingBoxFooter'

interface IProps {
  order: NonNumericOrderDto
  onClickAdd?: (order: NonNumericOrderWithPickUpAmount) => Promise<void> | void
}

export const NNROrderHandlingBox: FC<IProps> = observer(({ order: nnrOrder, onClickAdd }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { types: containerTypeParameters } = useContainerAttributesParameters()

  const remainingOrdersCount = nnrOrder.amount - nnrOrder.orderCount

  const Header = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: theme.customSpacing.m,
      }}
    >
      <Box>
        <Typography variant='subtitle1'>{nnrOrder.referenceNumber}</Typography>
        <Typography variant='body2'>
          {remainingOrdersCount}x {t('nonNumericContainers', 'non-numeric containers')}
        </Typography>
      </Box>

      <ColoredLabel label={t('nonNumeric', 'Non-numeric')} color='busy' />
    </Box>
  )

  const Body = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: '1rem',
        borderTop: '1px solid rgba(145, 158, 171, 0.32)',
        borderBottom: '1px solid rgba(145, 158, 171, 0.32)',
        bgcolor: theme.palette.grey[200],
      }}
    >
      <Box>
        <Typography variant='body2' sx={{ color: theme.palette.grey[600], mb: '.25rem' }}>
          {t('types', 'Types')}
        </Typography>

        {nnrOrder.containerTypes?.map(containerType => {
          const { icon, label } = containerTypeParameters[containerType]

          return (
            <Box key={containerType} sx={{ display: 'flex' }}>
              {icon}
              <Typography
                sx={{ color: theme.palette.common.black, ml: '.5rem' }}
                variant='subtitle1'
              >
                {label}
              </Typography>
            </Box>
          )
        })}
      </Box>

      <Box>
        <Typography variant='body2' sx={{ color: theme.palette.grey[600], mb: '.25rem' }}>
          {t('lengths', 'Lengths')}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <LengthIcon />
          <Typography sx={{ color: theme.palette.common.black, ml: '.5rem' }} variant='subtitle1'>
            {nnrOrder.containerLengths || 'N/A'}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Typography variant='body2' sx={{ color: theme.palette.grey[600], mb: '.25rem' }}>
          {t('operator', 'Operator')}
        </Typography>

        <Box sx={{ display: 'flex' }}>
          <GeneralIcon />
          <Typography sx={{ color: theme.palette.common.black, ml: '.5rem' }} variant='subtitle1'>
            {nnrOrder.shippingLine || 'N/A'}
          </Typography>
        </Box>
      </Box>
    </Box>
  )

  return (
    <Box
      sx={{
        bgcolor: theme.palette.common.white,
        border: '1px solid',
        borderColor: 'rgba(145, 158, 171, 0.32)',
        borderRadius: theme.customRadius.medium,
        mb: '1rem !important',
      }}
    >
      <Header />
      <Body />
      {!!onClickAdd && <NNROrderHandlingBoxFooter order={nnrOrder} onClickAdd={onClickAdd} />}
    </Box>
  )
})
