import { Box } from '@mui/material'
import { ContainerVisitsCard } from '@planning/pages/Order/components/ContainerVisitsCard/ContainerVisitsCard'
import { ContainerVisitsCardHeader } from '@planning/pages/Order/components/ContainerVisitsCard/ContainerVisitsCardHeader'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IIssueItem } from '../../Stores/IssueItem'
import { IssueCardHeader } from '../atoms/IssueCardHeader'

type Props = {
  issueItem: IIssueItem
  onClickResolve: () => void
}

export const IssueCard: FC<Props> = observer(({ issueItem, onClickResolve }) => {
  const theme = useMinimalsTheme()

  if (
    (issueItem.data.objectType === 'Container' && !issueItem.container) ||
    (issueItem.data.objectType === 'Order' && !issueItem.containerJourney)
  )
    return <></>

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.customRadius.medium,
      }}
    >
      <IssueCardHeader issue={issueItem.data} onResolve={onClickResolve} />

      {issueItem.data.objectType === 'Container' && (
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <ContainerVisitsCardHeader container={issueItem.container!} />
        </Box>
      )}
      {issueItem.data.objectType === 'Order' && (
        <ContainerVisitsCard containerVisit={issueItem.containerJourney!} announcedVisits={[]} />
      )}
    </Box>
  )
})
