import { useTranslate } from '@tolgee/react'

export const useFormatMinutesLabel = () => {
  const { t } = useTranslate()
  const formatMinutesLabel = (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    let label = ''

    if (hours > 0) {
      label += `${hours}h `
    }
    if (remainingMinutes > 0) {
      label += `${remainingMinutes}${t('min', 'min')}`
    }

    return label
  }

  return { formatMinutesLabel }
}
