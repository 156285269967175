import { OrderDto, StockDto } from '@billing/app/api'
import { SelectOption } from '@billing/app/models'
import ordersService from '@billing/services/OrdersService'
import { CompanyStore } from '@billing/stores/CompanyStore'
import { Dayjs } from 'dayjs'
import { action, makeObservable, observable, runInAction } from 'mobx'

export class HandlingServicesPreBillingUIStore {
  customerId = ''
  from: Dayjs | null = null
  to: Dayjs | null = null
  orders: OrderDto[] = []
  stockInventories: StockDto[] = []
  ordersToBePreBilled: number[] | null = null

  constructor(private readonly _customerStore: CompanyStore) {
    makeObservable(this, {
      from: observable,
      to: observable,
      customerId: observable,
      orders: observable,
      ordersToBePreBilled: observable,
      setCustomerId: action,
      setFrom: action,
      setTo: action,
      loadData: action,
      setIdsToSubmit: action,
    })
  }

  setCustomerId(customerId: string) {
    this.customerId = customerId
  }

  get customerOptions(): SelectOption[] {
    return this._customerStore.items.map(c => ({ label: c.name, value: c.id.toString() }))
  }

  setFrom(from: Dayjs | null) {
    this.from = from
  }

  setTo(to: Dayjs | null) {
    this.to = to
  }
  async loadData() {
    const result = await ordersService.getFulfilledOrders(
      this.customerId === 'All' ? undefined : parseInt(this.customerId),
      this.from?.toISOString(),
      this.to?.toISOString(),
    )

    runInAction(() => {
      this.orders = result
    })
  }

  resetFilter() {
    this.setFrom(null)
    this.setTo(null)
  }

  setIdsToSubmit(orderIds: number[]) {
    this.ordersToBePreBilled = orderIds
  }
}
