import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ContainerStackOutSequenceItemDto } from '@host/DTOs/YardPositionDto'
import { TruckVisits } from '@tom-ui/planning'
import { OutboundOrderValidationDto } from '@tom-ui/planning/stores/gateControl/ValidateOutboundDto'
import { OutboundRequestValidationControl, stackOutSequenceService } from '@tom-ui/storage'
import { useUtilsStore } from '@tom-ui/utils'
import { useCallback } from 'react'
import { ContainerStackOutSequenceDto } from '../GateControl/ContainerStackOutDto'
import { renderIsoCodeMappingInput } from '../IsoCodeMapping/IsoCodeMappingWrapper'

export const TruckVisitsPage = withAuthenticationRequired(() => {
  const { planContainerPositionDialogStore } = useUtilsStore()

  const handleStackOut = useCallback(async (reference: string) => {
    const containers = await stackOutSequenceService.stackOutSequenceForBayOperation(reference)

    return containers.map(
      (x: ContainerStackOutSequenceItemDto) =>
        ({
          containerNumber: x.containerNumber,
          shifts: x.additionalMovesByYardStatus,
        }) as ContainerStackOutSequenceDto,
    )
  }, [])

  const validateOutboundRequest = useCallback(
    (params: OutboundOrderValidationDto): React.ReactElement => (
      <OutboundRequestValidationControl
        isOutbound={params.isOutbound}
        customerId={params.customerId}
        commodityId={params.commodityId}
        lotNumber={params.lotNumber}
        packageId={params.packageId}
        quantity={params.quantity}
        unitIds={params.unitIds}
        imoClasses={params.imoClasses}
        hideSuccessfulValidation
      />
    ),
    [],
  )

  const openPlanContainerPositionDialog = useCallback(
    (orderId: number) => planContainerPositionDialogStore.openDialogByOrderId(orderId),
    [planContainerPositionDialogStore],
  )

  return (
    <TruckVisits
      containerStackOutFunc={handleStackOut}
      validateOutboundRequest={validateOutboundRequest}
      renderIsoCodeMappingInput={renderIsoCodeMappingInput}
      openPlanContainerPositionDialog={openPlanContainerPositionDialog}
    />
  )
})
