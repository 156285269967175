import { Stack, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { convertMinutesToTruckOverdueForm } from '../../Helpers/convert-minutes-form'

const getDefaultValues = () => ({
  overdueHours: 1,
  overdueMinutes: 0,
})

export interface TruckOverdueFormProfile {
  overdueHours: number
  overdueMinutes: number
}
interface Props {
  formId: string
  overdueTime?: number | null
  onSubmit: (value: TruckOverdueFormProfile) => void
}
export const TruckOverdueForm = ({ formId, overdueTime, onSubmit }: Props) => {
  const { t } = useTranslate()

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
  } = useForm<TruckOverdueFormProfile>({
    defaultValues: getDefaultValues(),
  })

  useEffect(() => {
    if (overdueTime) {
      reset(convertMinutesToTruckOverdueForm(overdueTime))
    } else {
      reset(getDefaultValues())
    }
  }, [overdueTime, reset])

  const watchOverdueHours = watch('overdueHours')
  const getHelperText = (field: keyof TruckOverdueFormProfile) => {
    if (errors[field]?.type === 'min') return t('mustHaveAValidValue', 'Must have a valid value')
    if (errors[field]?.type === 'max')
      return t('maxValueIs', 'Max value is {max}.', {
        max: errors[field]?.types?.max?.toString() ?? 60,
      })

    return errors[field] ? t('fieldIsRequired', 'Field is required.') : ''
  }

  return (
    <Stack gap={4}>
      <Typography variant='body1' color='secondary'>
        {t(
          'overdueTruckVisitTimeDescription',
          'Selected time will be used to calculate the shown trucks on the list which time exceeds the required time on terminal.',
        )}
      </Typography>
      <Stack
        id={formId}
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(async (formValues: TruckOverdueFormProfile) => {
          onSubmit(formValues)
        })}
        gap={1}
        mb={2}
      >
        <Stack flexDirection='row' gap={0.5}>
          <Typography variant='subtitle2'>{t('moreThan', 'More than')}</Typography>
          <Typography variant='subtitle2' color='error'>
            *
          </Typography>
        </Stack>

        <Stack flexDirection='row' gap={2}>
          <Controller
            name='overdueHours'
            control={control}
            rules={{
              required: true,
              min: 0,
              max: 24,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type='number'
                label={t('hours', 'Hours')}
                onChange={event => {
                  field.onChange(event)
                }}
                error={!!errors.overdueHours}
                helperText={getHelperText('overdueHours')}
                InputProps={{
                  endAdornment: (
                    <CustomInputAdornment position='end' color='secondary'>
                      h
                    </CustomInputAdornment>
                  ),
                }}
              />
            )}
          />

          <Controller
            name='overdueMinutes'
            control={control}
            rules={{
              required: true,
              min: watchOverdueHours > 0 ? 0 : 1,
              max: 60,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type='number'
                label={t('minutes', 'Minutes')}
                onChange={event => {
                  field.onChange(event)
                }}
                error={!!errors.overdueMinutes}
                helperText={getHelperText('overdueMinutes')}
                InputProps={{
                  endAdornment: (
                    <CustomInputAdornment position='end' color='secondary'>
                      {t('min', 'min')}
                    </CustomInputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
