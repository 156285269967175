import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { GatePassageResultAlert } from '@planning/pages/GateClerk/Components/GatePassageResultAlert'
import { GateInDialog } from '@planning/pages/GateControl/Components/GateInDialog'
import { GateOutWarningDialogs } from '@planning/pages/GateControl/Components/GateOutWarningDialogs'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { observer } from 'mobx-react-lite'

interface Props {
  gateInViewStore: GateInViewStore
}
export const TruckVisitsDialogs = observer(({ gateInViewStore }: Props) => {
  const isTruckAppointmentImprovementEnabled = useBooleanFlagValue(
    'truck-appointment-improvement',
    false,
  )

  return (
    <>
      {!isTruckAppointmentImprovementEnabled && <GateInDialog store={gateInViewStore} />}
      <GatePassageResultAlert store={gateInViewStore.notificationStore} />
      <GateOutWarningDialogs viewStore={gateInViewStore} />
    </>
  )
})
