import { createSvgIcon } from '@mui/material'

export const TerminalTruckIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.4862 5.29819C19.1511 3.55134 15.5805 3.45346 13.8987 3.51162C13.3864 3.52934 12.999 3.95626 12.999 4.46892V12.5H9.99902V11.5C9.99902 10.9477 9.55131 10.5 8.99902 10.5H4.99902C4.44674 10.5 3.99902 10.9477 3.99902 11.5V12.5H2.99902C2.44674 12.5 1.99902 12.9477 1.99902 13.5V18.5C1.99902 19.0523 2.44674 19.5 2.99902 19.5H4.16973C4.05918 19.1872 3.99902 18.8506 3.99902 18.5C3.99902 16.8431 5.34217 15.5 6.99902 15.5C8.65588 15.5 9.99902 16.8431 9.99902 18.5C9.99902 18.8506 9.93887 19.1872 9.82832 19.5H14.1697C14.0592 19.1872 13.999 18.8506 13.999 18.5C13.999 16.8431 15.3422 15.5 16.999 15.5C18.6559 15.5 19.999 16.8431 19.999 18.5C19.999 18.8506 19.9389 19.1872 19.8283 19.5H20.999C21.5513 19.5 21.999 19.0523 21.999 18.5V13.5C21.999 13.4808 21.9985 13.4618 21.9974 13.4428C22.0143 9.49494 21.9226 7.17761 20.4862 5.29819Z'
      fill='currentColor'
    />
    <path
      d='M4.99902 18.5C4.99902 19.6046 5.89445 20.5 6.99902 20.5C8.10359 20.5 8.99902 19.6046 8.99902 18.5C8.99902 17.3954 8.10359 16.5 6.99902 16.5C5.89445 16.5 4.99902 17.3954 4.99902 18.5Z'
      fill='currentColor'
    />
    <path
      d='M14.999 18.5C14.999 19.6046 15.8945 20.5 16.999 20.5C18.1036 20.5 18.999 19.6046 18.999 18.5C18.999 17.3954 18.1036 16.5 16.999 16.5C15.8945 16.5 14.999 17.3954 14.999 18.5Z'
      fill='currentColor'
    />
  </svg>,
  'TerminalTruck',
)
