import { IssueDto, IssuesApi } from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import { EventTypes } from '@operations/messages/eventsTypes'
import { IEvent, IMessageBus } from '@operations/messages/messageBus'
import { BaseEntityStore } from './base/BaseEntityStore'

export class IssueStore extends BaseEntityStore<IssueDto> {
  constructor(private messageBus: IMessageBus) {
    super()

    this.messageBus.subscribeEvent<IssueDto>(EventTypes.IssueUpserted, this.handleUpserted)
  }

  async load() {
    const { data } = await createApiClient(IssuesApi).getAll()

    this.updateStoreItems(data)
  }

  async getByCarrierVisitId(id: number) {
    const { data } = await createApiClient(IssuesApi).getByCarrierVisitId(id)

    this.updateStoreItems(data)
  }

  handleUpserted = (res: IEvent<IssueDto>) => {
    const upserted = res.payload
    if (!upserted) return

    this.updateStoreItem(upserted, upserted.id)
  }
}
