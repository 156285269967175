import {
  CreateExternalDriverCommand,
  ExternalDriversApi,
  UpdateExternalDriverCommand,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class ExternalDriverService {
  httpClient = createApiClient(ExternalDriversApi)

  get = async () => {
    const { data } = await this.httpClient.get()

    return data
  }

  getByPartialNameOrId = async (filter?: string, isFilteringByName?: boolean) => {
    const { data } = await this.httpClient.getExternalDriversByPartialNameOrId(
      filter,
      isFilteringByName,
    )

    return data
  }

  create = async (cmd: CreateExternalDriverCommand) => {
    const { data } = await this.httpClient.create(cmd)

    return data
  }

  update = async (cmd: UpdateExternalDriverCommand) => {
    await this.httpClient.update(cmd)
  }

  delete = async (id: number) => {
    await this.httpClient._delete(id)
  }
}

const externalDriverService = new ExternalDriverService()

export default externalDriverService
