import {
  CarrierVisitDirection,
  CompanyResponseDto,
  ExternalDriverResponseDto,
  OrderResponseDto,
  RailVisitResponseDto,
  TruckVisitDto,
  VesselVisitDto,
} from '@planning/app/api'
import { IInspectContainerFormData } from '@planning/pages/GateClerk/Components/InspectContainer'
import { IOrderWithVisit } from '@planning/pages/Order/stores/SelectOrderViewStore'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { orderService, truckVisitService } from '@planning/services'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import moment from 'moment'
import { GateOperationViewStore } from '../gateClerk/GateOperationViewStore'
import { GatePassageNotificationStore } from '../gateClerk/GatePassageNotificationStore'
import { NonNumericOrderWithPickUpAmount } from '../truckAppointment/TruckAppointmentDetailsViewStore'
import { ContainerStackOutFunc, ContainerStackOutSequenceDto } from './ContainerStackOutDto'
import { DropOffOrderSearchStore } from './DropOffOrderSearchStore'
import { PickUpOrderSearchStore } from './PickUpOrderSearchStore'
import { ValidateOutboundOrderFunc } from './ValidateOutboundDto'

export type TruckOrderType = 'pickUp' | 'dropOff'
export type EditedType = 'pickUp' | 'dropOff' | null
export type EditedOrder = IInspectContainerFormData | null
export type OrderSearchType = 'container' | 'generalCargo' | null
export type OrderContainerSearchType =
  | 'referenceNumber'
  | 'containerNumber'
  | 'bookingNumber'
  | null

export class GateInViewStore extends GateOperationViewStore {
  licensePlate = ''
  driverName?: string
  truckCompany?: CompanyResponseDto | null
  externalDriver?: ExternalDriverResponseDto | null
  isTruckAppointment = false
  truckAppointmentDate?: string | null = null
  truckAppointmentStartTime?: string | null = null
  truckAppointmentEndTime?: string | null = null
  externalPortGatePassRef?: string

  bookings: IInspectContainerFormData[] = []
  dropOffOrders: IInspectContainerFormData[] = []
  pickUpGeneralCargoOrders: IInspectContainerFormData[] = []
  dropOffGeneralCargoOrders: IInspectContainerFormData[] = []
  pickUpOrders: IInspectContainerFormData[] = []
  nnrOrders: NonNumericOrderWithPickUpAmount[] = []
  ordersByContainerNumber: OrderResponseDto[] = []
  vesselVisits: VesselVisitDto[] = []
  railVisits: RailVisitResponseDto[] = []
  isGateInDialogOpen = false
  isDialogEditMode = false
  editedType: EditedType = null
  editedOrder: EditedOrder = null
  truckVisitId?: number
  selectedVisitWithOrders: IOrderWithVisit[] = []

  containerShifts: ContainerStackOutSequenceDto[] = []
  referenceNumber: string | null = null
  getContainersWithStackOutSequenceByReference?: ContainerStackOutFunc
  validateOutboundRequest?: ValidateOutboundOrderFunc

  // todo: store tests
  public pickUpOrderSearchStore: PickUpOrderSearchStore
  // todo: store tests
  public dropOffOrderSearchStore: DropOffOrderSearchStore
  // todo: store tests
  public notificationStore: GatePassageNotificationStore

  constructor() {
    super()

    makeObservable(this, {
      licensePlate: observable,
      driverName: observable,
      externalDriver: observable,
      truckCompany: observable,
      truckAppointmentDate: observable,
      truckAppointmentStartTime: observable,
      truckAppointmentEndTime: observable,
      dropOffOrders: observable,
      dropOffGeneralCargoOrders: observable,
      pickUpGeneralCargoOrders: observable,
      pickUpOrders: observable,
      bookings: observable,
      nnrOrders: observable,
      ordersByContainerNumber: observable,
      vesselVisits: observable,
      railVisits: observable,
      truckVisitId: observable,
      selectedVisitWithOrders: observable,
      externalPortGatePassRef: observable,

      isGateInDialogOpen: observable,
      isDialogEditMode: observable,
      editedOrder: observable,
      editedType: observable,
      containerShifts: observable,
      referenceNumber: observable,
      isTruckAppointment: observable,
      bookingContainerNumbers: computed,

      setIsTruckAppointment: action,
      setEditedOrder: action,
      setLicensePlate: action,
      setTruckVisitId: action,
      setTruckAppointmentDate: action,
      setTruckAppointmentStartTime: action,
      setTruckAppointmentEndTime: action,
      setReferenceNumberAndGetContainerShifts: action,
      setDriverName: action,
      setExternalPortGatePassRef: action,
      setTruckCompany: action,
      setExternalDriver: action,
      upsertPickUpOrderById: action,
      toggleGateInDialog: action,
      toggleEditDialogVisibility: action,
      upsertDropOffOrder: action,
      upsertPickUpOrder: action,
      upsertNNROrder: action,
      upsertPickUpGeneralCargoOrder: action,
      upsertDropOffGeneralCargoOrder: action,
      upsertBooking: action,
      deleteBooking: action,
      deleteDropOffOrder: action,
      deletePickUpOrder: action,
      deleteNNROrder: action,
      deletePickUpGeneralCargoOrder: action,
      deleteDropOffGeneralCargoOrder: action,
      resetEditMode: action,
      reset: action,

      isSearchingByReferenceNumber: computed,
      isSearchingByBookingNumber: computed,
      truckAppointmentEstimatedTimes: computed,
    })

    this.pickUpOrderSearchStore = new PickUpOrderSearchStore()
    this.dropOffOrderSearchStore = new DropOffOrderSearchStore()
    this.notificationStore = new GatePassageNotificationStore()
    this.notificationStore.setDirection(CarrierVisitDirection.Inbound)
  }

  setLicensePlate = (licensePlate: string) => {
    this.licensePlate = licensePlate
  }

  setDriverName = (driverName: string) => {
    this.driverName = driverName
  }

  setExternalDriver = (driver?: ExternalDriverResponseDto | null) => {
    this.externalDriver = driver
  }

  setTruckCompany = (company?: CompanyResponseDto | null) => {
    this.truckCompany = company
  }

  setTruckVisitId = (truckVisitId: number) => {
    this.truckVisitId = truckVisitId
  }

  setTruckAppointmentDate = (timestamp: string) => {
    this.truckAppointmentDate = timestamp
  }

  setTruckAppointmentStartTime = (timestamp: string) => {
    this.truckAppointmentStartTime = timestamp
  }

  setTruckAppointmentEndTime = (timestamp: string) => {
    this.truckAppointmentEndTime = timestamp
  }

  setReferenceNumberAndGetContainerShifts = async (referenceNumber: string | null) => {
    this.referenceNumber = referenceNumber

    if (this.getContainersWithStackOutSequenceByReference && referenceNumber) {
      const shifts = await this.getContainersWithStackOutSequenceByReference(referenceNumber)

      runInAction(() => {
        this.containerShifts = shifts
      })
    }
  }

  setExternalPortGatePassRef = (externalPortGatePassRef: string) => {
    this.externalPortGatePassRef = externalPortGatePassRef
  }

  upsertDropoffOrderById = async (orderId: number) => {
    const order = await orderService.getById(orderId)

    if (order) {
      this.upsertDropOffOrder(order as IInspectContainerFormData)
    }
  }

  upsertBooking = (order: IInspectContainerFormData) => {
    this.bookings = [...this.bookings.filter(o => o.id !== order.id), order]
  }

  deleteBooking = (orderId: number) => {
    this.bookings = [...this.bookings.filter(o => o.id !== orderId)]
  }

  upsertDropOffOrder = (order: IInspectContainerFormData) => {
    this.dropOffOrders = [...this.dropOffOrders.filter(o => o.id !== order.id), order]
  }

  deleteDropOffOrder = (orderId: number) => {
    this.dropOffOrders = [...this.dropOffOrders.filter(o => o.id !== orderId)]
  }

  upsertPickUpOrderById = async (orderId: number) => {
    const order = await orderService.getById(orderId)

    if (order) {
      this.upsertPickUpOrder(order as IInspectContainerFormData)
    }
  }

  upsertPickUpOrder = (order: IInspectContainerFormData) => {
    this.pickUpOrders = [...this.pickUpOrders.filter(o => o.id !== order.id), order]
  }

  deletePickUpOrder = (orderId: number) => {
    this.pickUpOrders = [...this.pickUpOrders.filter(o => o.id !== orderId)]
  }

  upsertPickUpGeneralCargoOrder = (order: IInspectContainerFormData) => {
    this.pickUpGeneralCargoOrders = [
      ...this.pickUpGeneralCargoOrders.filter(o => o.id !== order.id),
      order,
    ]
  }

  deletePickUpGeneralCargoOrder = (orderId: number) => {
    this.pickUpGeneralCargoOrders = [...this.pickUpGeneralCargoOrders.filter(o => o.id !== orderId)]
  }

  upsertDropOffGeneralCargoOrder = (order: IInspectContainerFormData) => {
    this.dropOffGeneralCargoOrders = [
      ...this.dropOffGeneralCargoOrders.filter(o => o.id !== order.id),
      order,
    ]
  }

  deleteDropOffGeneralCargoOrder = (orderId: number) => {
    this.dropOffGeneralCargoOrders = [
      ...this.dropOffGeneralCargoOrders.filter(o => o.id !== orderId),
    ]
  }

  upsertNNROrder = (order: NonNumericOrderWithPickUpAmount) => {
    this.nnrOrders = [...this.nnrOrders.filter(o => o.id !== order.id), order]
  }

  deleteNNROrder = (orderId: number) => {
    this.nnrOrders = [...this.nnrOrders.filter(o => o.id !== orderId)]
  }

  setIsTruckAppointment = (isTruckAppointment: boolean) => {
    this.isTruckAppointment = isTruckAppointment
  }

  toggleGateInDialog = (isOpen: boolean) => {
    this.isGateInDialogOpen = isOpen
  }

  toggleEditDialogVisibility = (isOpen: boolean) => {
    this.isDialogEditMode = isOpen
  }

  setEditedOrder = (order: EditedOrder) => {
    this.editedOrder = order
  }

  setEditedType = (type: EditedType) => {
    this.editedType = type
  }

  resetEditMode = () => {
    this.toggleEditDialogVisibility(false)
    this.setEditedOrder(null)
    this.setEditedType(null)
  }

  reset = () => {
    this.resetDialogs()
    this.toggleGateInDialog(false)
    this.resetEditMode()
    this.dropOffOrderSearchStore.reset()
    this.pickUpOrderSearchStore.reset()
    this.licensePlate = ''
    this.dropOffOrders = []
    this.pickUpOrders = []
    this.nnrOrders = []
    this.bookings = []
    this.pickUpGeneralCargoOrders = []
    this.dropOffGeneralCargoOrders = []
    this.ordersByContainerNumber = []
    this.referenceNumber = null
    this.truckAppointmentDate = null
    this.truckAppointmentStartTime = null
    this.truckAppointmentEndTime = null
    this.truckVisitId = undefined
    this.driverName = ''
    this.selectedVisitWithOrders = []
    this.externalDriver = null
    this.truckCompany = null
    this.externalPortGatePassRef = ''
  }

  get bookingContainerNumbers() {
    return this.bookings.filter(order => order.containerNumber).map(order => order.containerNumber!)
  }

  get isSearchingByReferenceNumber() {
    return (
      this.pickUpOrderSearchStore.containerSearchType === 'referenceNumber' &&
      this.pickUpOrderSearchStore.searchType === 'container' &&
      !this.referenceNumber
    )
  }

  get isSearchingByBookingNumber() {
    return (
      this.dropOffOrderSearchStore.containerSearchType === 'bookingNumber' &&
      this.dropOffOrderSearchStore.searchType === 'container'
    )
  }

  get truckAppointmentEstimatedTimes() {
    if (!this.truckAppointmentDate) return { eta: null, etd: null }

    const etaTimeBase = moment(this.truckAppointmentDate).startOf('d')
    let etdTimeBase = moment(this.truckAppointmentDate).endOf('d')

    if (this.truckAppointmentStartTime) {
      const start = moment(this.truckAppointmentStartTime)
      etaTimeBase.add(start.hour(), 'h')
      etaTimeBase.add(start.minute(), 'm')
    }

    if (this.truckAppointmentEndTime) {
      const end = moment(this.truckAppointmentEndTime)
      etdTimeBase = moment(this.truckAppointmentDate).startOf('d')
      etdTimeBase.add(end.hour(), 'h')
      etdTimeBase.add(end.minute(), 'm')
    }

    return {
      eta: etaTimeBase.toLocaleString(),
      etd: etdTimeBase.toLocaleString(),
    }
  }

  setSelectedVisitWithOrders = (orders: IOrderWithVisit[]) => {
    this.selectedVisitWithOrders = orders
  }

  clearSelectedOrders = () => {
    this.selectedVisitWithOrders = []
  }

  gateOut = async () => {
    if (!this.selectedVisitWithOrders[0].visit) return

    const orderIds = this.selectedVisitWithOrders
      .filter(o => !!o.order)
      .map(order => order.order!.id)

    await truckVisitService.allowExit({
      id: this.selectedVisitWithOrders[0].visit.id,
      orderIds: orderIds,
    })

    this.resetDialogs()
    this.clearSelectedOrders()
  }

  openEditTruckVisitAppointment = (truckVisit: ITruckVisitItem) => {
    this.setIsTruckAppointment(true)
    this.toggleGateInDialog(true)

    this.setTruckVisitId(truckVisit.id)
    this.setLicensePlate(truckVisit.data.identifier ?? truckVisit?.truck?.data.licensePlate ?? '')
    this.setTruckAppointmentDate(truckVisit?.data.eta ?? '')
    this.setTruckAppointmentStartTime(truckVisit?.data.eta ?? '')
    this.setTruckAppointmentEndTime(truckVisit?.data.etd ?? '')
    this.setDriverName(truckVisit?.data.driverName ?? '')

    const orders = truckVisit.orders

    const dropOffOrderType = orders.length
      ? orders[0].data.commodityId
        ? 'generalCargo'
        : 'container'
      : null

    this.dropOffOrderSearchStore.setSearchType(
      orders.length ? (orders[0].data.commodityId ? 'generalCargo' : 'container') : null,
    )
    this.pickUpOrderSearchStore.setSearchType(
      orders.length ? (orders[0].data.commodityId ? 'generalCargo' : 'container') : null,
    )

    // todo: review gc- and container- orders. we should treat them as the same entity
    // why we have to maintain 2 lists of orders
    orders.forEach(o =>
      o.data.direction === CarrierVisitDirection.Inbound
        ? dropOffOrderType === 'generalCargo'
          ? this.upsertDropOffGeneralCargoOrder(o.data as IInspectContainerFormData)
          : this.upsertDropOffOrder(o.data as IInspectContainerFormData)
        : dropOffOrderType === 'generalCargo'
          ? this.upsertPickUpGeneralCargoOrder(o.data as IInspectContainerFormData)
          : this.upsertPickUpOrder(o.data as IInspectContainerFormData),
    )
  }

  openGateOutConfirmationDialogByTruckVisit = (truckVisit: ITruckVisitItem) => {
    const _dropOffOrders = truckVisit.inboundOrders.map(o => o.data)
    const _pickUpOrders = truckVisit.outboundOrders.map(o => o.data)
    this.validateManualGateOut(truckVisit.data, [..._dropOffOrders, ..._pickUpOrders])
  }

  validateManualGateOut = (truckVisit: TruckVisitDto, selectedOrders: OrderResponseDto[]) => {
    this.setSelectedVisitWithOrders(
      selectedOrders.map(order => ({
        visit: truckVisit,
        order,
      })),
    )

    const ordersWithPendingJobs = this.selectedVisitWithOrders
      .map(order => order.order)
      .filter(order => order?.isJobFinished === false)

    if (ordersWithPendingJobs.length > 0) {
      this.openGateOutWithPendenciesConfirmationDialog()
    } else {
      this.openGateOutConfirmationDialog()
    }
  }
}
