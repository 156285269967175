import { useAdminStore } from '@admin/AppProvider'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { TenantConfigurationProps } from '../TenantConfiguration/components/form/tenant-config.interface'
import { TenantDrawerContainer } from './containers/TenantDrawerContainer'
import { TenantsContainer } from './containers/TenantsContainer'

interface Props extends TenantConfigurationProps {}

const Tenants = observer(({ autocomplete }: Props) => {
  const { t } = useTranslate()
  const { tenantUIStore, timezonesStore } = useAdminStore()

  useEffect(() => {
    const pageData = async () => {
      const requests = [timezonesStore.load(), tenantUIStore.store.load()]
      await Promise.all(requests)
    }

    pageData()
  }, [tenantUIStore.store, timezonesStore])

  const handleDelete = async () => {
    await tenantUIStore.store.delete(tenantUIStore.toBeRemoveTenantId!)

    tenantUIStore.setToBeRemoveTenantId()
  }

  return (
    <>
      <Helmet>
        <title>{t('tenants', 'Tenants')}</title>
      </Helmet>

      <TenantsContainer />

      <TenantDrawerContainer SingleLocationCodeAutoComplete={autocomplete} />

      <ConfirmationDialog
        open={!!tenantUIStore.toBeRemoveTenantId}
        title={t('youAreAboutToRemoveATenant', 'You are about to remove a tenant')}
        message={t('thisActionWillRemoveThisTenant', 'This action will remove this tenant.')}
        primaryActionText={t('remove', 'Remove')}
        closeLabel={t('close', 'Close')}
        onClose={() => tenantUIStore.setToBeRemoveTenantId()}
        onConfirm={handleDelete}
      />
    </>
  )
})

export default Tenants
