import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useExternalDriverMessagesHandler } from '../../Hooks/useExternalDriverMessagesHandler'
import { ExternalDriverFormData } from '../../Stores/ExternalDriverViewStore'
import { ExternalDriverForm } from '../Molecules/ExternalDriverForm'

export const ExternalDriverFormDialog = observer(() => {
  const { t } = useTranslate()
  const { appViewStore, externalDriverViewStore, externalDriverUnmanagedItemStore } =
    usePlanningStore()
  const { getExceptionMessage, getSuccessMessage } = useExternalDriverMessagesHandler()

  const { loading } = useAsyncFetch(async () => {
    await externalDriverUnmanagedItemStore.fetch()
  }, [externalDriverUnmanagedItemStore])

  const onClose = () => externalDriverViewStore.toggleAddDialog(false)

  const onSubmit = async (data: ExternalDriverFormData) => {
    const isEditMode = !!externalDriverViewStore.dataToBeEdited

    try {
      await externalDriverViewStore.onSubmit(data)
      appViewStore.setShowAlert('success', getSuccessMessage(isEditMode, data.name))
      onClose()
    } catch (error: any) {
      appViewStore.setShowAlert('error', getExceptionMessage(error))
    }
  }

  return (
    <InitializationWrapper isInitializing={loading}>
      <ConfirmationDialog
        open={externalDriverViewStore.isAddDialogOpen}
        title={t('addDriver', 'Add Driver')}
        primaryActionText={t('save', 'Save')}
        closeLabel={t('cancel', 'Cancel')}
        formId='external-driver-form'
        onClose={onClose}
        hasBorderTop
      >
        <ExternalDriverForm
          formId='external-driver-form'
          externalDrivers={externalDriverViewStore.allItems}
          onSubmit={onSubmit}
        />
      </ConfirmationDialog>
    </InitializationWrapper>
  )
})
