import { Box, Grid } from '@mui/material'
import { ContainerDamageResponseDto, OrderResponseDto } from '@planning/app/api'
import { ControlledContainerSealsWithTypes } from '@planning/components/ControlledContainerSealsWithTypes'
import { ControlledDamageReports } from '@planning/components/damage-report/ControlledDamageReports'
import { createOogFormDataFromOrderResponse } from '@planning/components/organisms/OogSubForm/oog-helper'
import { OogSubForm } from '@planning/components/organisms/OogSubForm/OogSubForm'
import { UnitLabelSubForm } from '@planning/components/organisms/UnitLabelSubForm/UnitLabelSubForm'
import { DamageReportBox } from '@planning/pages/TallymanV2/Components/DamageReportBox'
import { IContainerItem } from '@planning/rt-stores/container/ContainerItem'
import { DefaultParamType, TFnType, TranslationKey } from '@tolgee/react'
import { IFormStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Control, Controller, FormState, UseFormGetValues, UseFormWatch } from 'react-hook-form'
import { IInspectContainerFormData } from './InspectContainer'

interface IProps {
  control: Control<IInspectContainerFormData, any>
  isDangerous: boolean
  getValues: UseFormGetValues<IInspectContainerFormData>
  setIsDangerous: React.Dispatch<React.SetStateAction<boolean>>
  formState: FormState<IInspectContainerFormData>
  displayDamageReport: boolean | undefined
  containerItem: IContainerItem
  hasNewDamageRecording: boolean
  watch: UseFormWatch<IInspectContainerFormData>
  onEditDamageReport: (
    editedReport: Partial<ContainerDamageResponseDto>,
    initialReport: ContainerDamageResponseDto,
  ) => void
  t: TFnType<DefaultParamType, string, TranslationKey>
  formStore: IFormStore<IInspectContainerFormData>
}

export const InspectContainerBase: FC<IProps> = observer(
  ({
    control,
    isDangerous,
    getValues,
    setIsDangerous,
    formState,
    displayDamageReport,
    containerItem,
    hasNewDamageRecording,
    watch,
    onEditDamageReport,
    t,
    formStore,
  }) => {
    return (
      <>
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <Controller
            control={control}
            name={'unitLabel'}
            render={({ field: { onChange, value } }) => (
              <UnitLabelSubForm
                formStore={formStore}
                name='unitLabel'
                initialValue={value}
                onChange={value => onChange(value)}
              />
            )}
          />
        </Grid>
        {displayDamageReport && containerItem && (
          <Grid item xs={12}>
            <Box sx={{ marginTop: '1rem !important' }}>
              {hasNewDamageRecording ? (
                <ControlledDamageReports
                  control={control}
                  formState={formState}
                  containerId={containerItem.id}
                  damages={containerItem.activeDamages}
                  watch={watch}
                />
              ) : (
                <Controller
                  control={control}
                  name={`damages`}
                  render={({ field: { onChange } }) => (
                    <DamageReportBox
                      containerId={0}
                      damages={getValues('damages')}
                      onCreateDamageReport={(_, data) =>
                        onChange([
                          ...getValues('damages'),
                          {
                            ...(data as ContainerDamageResponseDto),
                            created: new Date().toISOString(),
                          },
                        ])
                      }
                      onEditDamageReport={onEditDamageReport}
                    />
                  )}
                />
              )}
            </Box>
          </Grid>
        )}

        {!getValues('isEmpty') && (
          <Grid item xs={12} sx={{ marginTop: '1rem' }}>
            <Controller
              control={control}
              name={'oog'}
              render={({ field: { onChange, value } }) => (
                <OogSubForm
                  formStore={formStore}
                  name='oog'
                  initialValue={value}
                  onChange={oog => onChange(oog)}
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <ControlledContainerSealsWithTypes
            name={`seals`}
            control={control}
            sealsName={`seals`}
            hasSealsName={`hasSeals`}
          />
        </Grid>
      </>
    )
  },
)

export const defaultInspectContainerFormData = (
  order: OrderResponseDto,
  damages: ContainerDamageResponseDto[] | undefined,
  containerItem: IContainerItem,
) => {
  return {
    defaultValues: {
      ...order,
      id: order?.id,
      referenceNumber: order?.referenceNumber,
      direction: order?.direction,
      carrierType: order.carrierVisitType,
      containerId: order.containerId,
      containerNumber: order?.containerNumber,
      unitType: order.unitType,
      containerIsoCode: order?.containerIsoCode,
      containerTare: order?.containerTare,
      containerMaxGross: order?.containerMaxGross,
      isEmpty: order?.isEmpty,
      imoClasses: order?.imoClasses,
      holds: order?.holds,
      damages: damages ?? containerItem?.data.damages ?? [],
      hasDamage: !!(
        (order as IInspectContainerFormData).damagesReported?.length ??
        containerItem?.data.damages.length
      ),
      damagesReported: (order as IInspectContainerFormData).damagesReported ?? [],
      hasSeals: order?.hasSeals ?? false,
      seals: order?.seals ?? [],
      oog: (order as IInspectContainerFormData)?.oog ?? createOogFormDataFromOrderResponse(order),
      unitLabel: {
        ...order?.unitLabel,
        isUnitLabel: !!order?.unitLabel,
      },
      isEdited: (order as IInspectContainerFormData)?.isEdited,
    },
  }
}
