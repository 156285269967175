import { Alert, Container, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BackButton, Header } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { JobList } from '../JobList/JobList'

interface Props {
  handleReturn: () => void
}
export const JobAlertContent = observer(({ handleReturn }: Props) => {
  const { t } = useTranslate()

  const { equipmentOperatorUIStore, equipmentOperatorSearchUIStore } = useOperationsStore()

  return (
    <>
      <Header
        leftRenderOption={<BackButton tooltip={t('return', 'Return')} onGoBack={handleReturn} />}
      />
      <Container sx={{ paddingY: theme => theme.customSpacing.m }}>
        {equipmentOperatorSearchUIStore.isShowingAlerts &&
          equipmentOperatorUIStore.alertFilteredJobs.flatMap(x => x.jobs).length === 0 && (
            <Alert severity='warning'>
              <Typography variant='subtitle2'>
                {t('noMatchingResults', 'No matching results')}
              </Typography>
            </Alert>
          )}

        <JobList jobGroups={equipmentOperatorUIStore.alertFilteredJobs} />
      </Container>
    </>
  )
})
