import { ArchetypeResponseDto } from '@planning/app/api'
import { nnrOrderService } from '@planning/services'
import { debounce } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

export class ArchetypeAutoCompleteStore {
  open = false
  options: ArchetypeResponseDto[] = []

  constructor() {
    makeObservable(this, {
      open: observable,
      options: observable,

      filterOptions: action,
      setOptions: action,
      setOpened: action,
    })
  }

  setOpened(open: boolean) {
    this.open = open

    if (open) this.filterOptions()
    else this.setOptions([])
  }

  setOptions = (options: ArchetypeResponseDto[]) => (this.options = options)

  filterOptions = debounce(async (filter?: string) => {
    const data = await nnrOrderService.getArchetypesByPartialCode(filter ?? '')
    this.setOptions(data)
  }, 500)
}
