import { TenantsApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class TenantService {
  httpClient = createApiClient(TenantsApi)

  async get() {
    const { data } = await this.httpClient.get()

    return data
  }

  async updateTruckOverdueTime(overdueValue: number) {
    await this.httpClient.update({ truckVisitOverdueTime: overdueValue })
  }
}

const tenantService = new TenantService()

export default tenantService
