import {
  AllowTruckVisitEntryCommand,
  AllowTruckVisitExitCommand,
  CarrierVisitDirection,
  CarrierVisitStatus,
  CreateContainerOrderDto,
  CreateTruckAppointmentCommand,
  NonNumericOrderDto,
  OrderResponseDto,
  TruckAppointmentDto,
  TruckAppointmentOrderDto,
} from '@planning/app/api'
import { truckAppointmentService } from '@planning/services'
import truckVisitService from '@planning/services/truckVisitService'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { SuggestedContainerDto } from '../gateClerk/SuggestedContainerDto'
import { TruckAppointmentViewStore } from './TruckAppointmentViewStore'

export const createTruckAppointmentDetailsViewStore = (parentStore: TruckAppointmentViewStore) =>
  new TruckAppointmentDetailsViewStore(parentStore)

export interface NonNumericOrderWithPickUpAmount extends NonNumericOrderDto {
  pickUpAmount: number
  suggestedContainers?: SuggestedContainerDto[]
}

export class TruckAppointmentDetailsViewStore {
  appointment?: TruckAppointmentDto
  direction: CarrierVisitDirection = CarrierVisitDirection.Inbound
  initialOrders: TruckAppointmentOrderDto[] = []
  nnrOrders: NonNumericOrderWithPickUpAmount[] = []
  bookings: OrderResponseDto[] = []

  constructor(public parentStore: TruckAppointmentViewStore) {
    makeObservable(this, {
      appointment: observable,
      direction: observable,
      initialOrders: observable,
      nnrOrders: observable,
      bookings: observable,

      removedOrders: computed,
      deliveryOrders: computed,
      pickUpOrders: computed,
      outboundOrdersNotOnTerminal: computed,
      passageDirection: computed,
      isExistingAppointment: computed,
      isExit: computed,
      isEntryWithoutOrders: computed,
      openTabDirection: computed,
      ordersWithoutContainerNumber: computed,

      setAppointment: action,
      setEmptyAppointment: action,
      createAppointment: action,
      setDirection: action,
      addOrder: action,
      removeOrder: action,
      addNNROrder: action,
      addBooking: action,
      removeBooking: action,
    })
  }

  setEmptyAppointment() {
    this.setAppointment({
      truckVisitId: 0,
      status: CarrierVisitStatus.Expected,
      licensePlate: '',
      orders: [],
    })

    this.nnrOrders = []

    this.direction = CarrierVisitDirection.Inbound
  }

  private mapToCreateOrderDto = (order: OrderResponseDto) => {
    const mapped: CreateContainerOrderDto = {
      containerNumber: order.containerNumber,
      direction: CarrierVisitDirection.Inbound,
      linkedOrderId: order.id,
      containerIsoCode: order.containerIsoCode,
      referenceNumber: order.referenceNumber,
      imoClasses: order.imoClasses,
      // [BookingsGateIn] TODO: damages
      // [BookingsGateIn] TODO: OOG
      isToBeStoredInYard: true,
      isAccidentalDischarge: false,
    }

    return mapped
  }

  createAppointment = async () => {
    if (!this.appointment) return

    const bookings = this.bookings.map(this.mapToCreateOrderDto)
    const cmd: CreateTruckAppointmentCommand = {
      licensePlate: this.appointment.licensePlate,
      eta: this.appointment.eta,
      etd: this.appointment.etd,
      ordersWithDirection: this.appointment.orders.map(o => ({
        id: o.orderId,
        direction: o.carrierVisitDirection,
      })),
      ordersToBeCreated: bookings,
      nnrOrders: this.nnrOrders.map(o => {
        return {
          nnrOrderId: o.id,
          amountToPickUp: o.pickUpAmount,
        }
      }),
    }

    const response = await truckAppointmentService.post(cmd)

    this.appointment.truckVisitId = response.truckVisitId
  }

  setAppointment(appointment?: TruckAppointmentDto) {
    this.appointment = appointment
    if (appointment) this.initialOrders = [...appointment.orders]
  }

  removeOrder(order: TruckAppointmentOrderDto) {
    if (!this.appointment) return

    if (order.orderId) {
      this.appointment = {
        ...this.appointment,
        orders: this.appointment.orders.filter(o => o.orderId !== order.orderId),
      }
    } else {
      const referenceNumber = order.referenceNumber
      const nnrOrder = this.nnrOrders.find(nnr => nnr.referenceNumber === referenceNumber)

      if (!nnrOrder) return

      nnrOrder.pickUpAmount = nnrOrder.pickUpAmount - 1

      if (nnrOrder?.pickUpAmount === 0)
        this.nnrOrders = [...this.nnrOrders.filter(nnr => nnr.referenceNumber !== referenceNumber)]
      else
        this.nnrOrders = [
          ...this.nnrOrders.filter(nnr => nnr.referenceNumber !== referenceNumber),
          nnrOrder,
        ]
    }
  }

  setDirection = (direction: CarrierVisitDirection) => {
    this.direction = direction
  }

  addOrder = (order: TruckAppointmentOrderDto) => {
    if (!this.appointment) return

    this.appointment.orders.push(order)
  }

  addBooking = (order: OrderResponseDto) => {
    this.bookings.push(order)
  }

  removeBooking = (id: number) => {
    this.bookings = this.bookings.filter(o => o.id !== id)
  }

  addNNROrder = (nnrOrder: NonNumericOrderDto, pickUpAmount: number) => {
    if (!this.appointment) return

    this.nnrOrders.push({ ...nnrOrder, pickUpAmount: pickUpAmount })
  }

  private allowEntry = async () => {
    if (!this.appointment?.truckVisitId) return

    const inboundOrderIds = this.deliveryOrders.map(o => o.orderId)
    const outboundOrderIds = this.pickUpOrders.map(o => o.orderId)

    await truckVisitService.allowEntry({
      id: this.appointment.truckVisitId,
      inboundOrderIds: inboundOrderIds,
      outboundOrderIds: outboundOrderIds,
    } as AllowTruckVisitEntryCommand)
  }

  private allowExit = async () => {
    if (!this.appointment?.truckVisitId) return

    const outboundOrderIds = this.pickUpOrders.map(o => o.orderId)

    await truckVisitService.allowExit({
      id: this.appointment.truckVisitId,
      orderIds: outboundOrderIds,
    } as AllowTruckVisitExitCommand)
  }

  allowPassage = async () => {
    if (!this.appointment) throw new Error('No appointment set.')

    if (!this.isExistingAppointment) await this.createAppointment()

    if (this.passageDirection === CarrierVisitDirection.Inbound) await this.allowEntry()
    else await this.allowExit()

    this.parentStore.selectTruckVisitId()
    await this.parentStore.setFilter()
    await this.parentStore.orderStore.loadCurrentPage()

    runInAction(() => {
      this.nnrOrders = []
    })
  }

  get removedOrders() {
    return this.initialOrders.filter(
      initialOrder => !this.appointment?.orders.map(o => o.orderId).includes(initialOrder.orderId),
    )
  }

  get deliveryOrders() {
    return (
      this.appointment?.orders.filter(
        o => o.carrierVisitDirection === CarrierVisitDirection.Inbound,
      ) ?? []
    )
  }

  get pickUpOrders() {
    return (
      this.appointment?.orders.filter(
        o => o.carrierVisitDirection === CarrierVisitDirection.Outbound,
      ) ?? []
    )
  }

  get isExistingAppointment() {
    return this.appointment && this.appointment.truckVisitId !== 0
  }

  get isExit() {
    return this.appointment && this.appointment.status === CarrierVisitStatus.Arrived
  }

  get isEntryWithoutOrders() {
    return (
      this.appointment && !this.appointment.orders.length && !this.nnrOrders.length && !this.isExit
    )
  }

  get outboundOrdersNotOnTerminal() {
    if (!this.appointment) return []

    return this.appointment.orders.filter(
      o => o.carrierVisitDirection === CarrierVisitDirection.Outbound && !o.isOnTerminal,
    )
  }

  get ordersWithoutContainerNumber() {
    if (!this.appointment) return []

    return this.appointment.orders.filter(
      o => o.carrierVisitDirection === CarrierVisitDirection.Outbound && !o.containerNumber,
    )
  }

  get passageDirection() {
    return this.isExit ? CarrierVisitDirection.Outbound : CarrierVisitDirection.Inbound
  }

  get openTabDirection() {
    if (!this.appointment) return CarrierVisitDirection.Inbound

    if (this.appointment.status !== CarrierVisitStatus.Expected)
      return CarrierVisitDirection.Outbound

    const appointmentContainsDropOffOrders = this.appointment.orders.some(
      o => o.carrierVisitDirection === CarrierVisitDirection.Inbound,
    )

    return appointmentContainsDropOffOrders || !this.appointment.orders.length
      ? this.direction
      : CarrierVisitDirection.Outbound
  }
}
