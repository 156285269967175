import * as yup from 'yup'
import { ContainerDamageTypeFormProfile } from './container-damage-type-form.profile'

export const schema: yup.ObjectSchema<ContainerDamageTypeFormProfile> = yup.object().shape({
  id: yup.number().optional(),
  longName: yup.string().label('Long name').required(),
  shortName: yup.string().label('Short name').nullable().optional(),
  externalCodes: yup.array().label('External codes').required(),
  isDefault: yup.boolean().optional(),
  isActive: yup.boolean().label('Active').required(),
})
