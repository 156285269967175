import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

import { Stack, Typography } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { useAsyncFetch } from '@tom-ui/utils'
import { useMemo } from 'react'
import WeightClassesForm from '../forms/weight-classes-form/WeightClassesForm'
import { WeightClassFormProfile } from '../forms/weight-classes-form/weight-classes-form.profile'
import { WeightClassSetContainerUIStore } from '../stores/weight-class-set-ui-store'
import { WeightClassSet } from './organisms/WeightClassSet'

const WeightClassesContainer = observer(({ formId }: { formId: string }) => {
  const weightClassSetFf = useBooleanFlagValue('weight-class-set', false)

  const { t } = useTranslate()
  const {
    weightClassContainerUIStore,
    weightClassSetStore,
    vesselServiceStore,
    authStore,
    snackbarStore,
  } = useStores()

  const weightClassSetUIStore = useMemo(
    () =>
      new WeightClassSetContainerUIStore(snackbarStore, weightClassSetStore, vesselServiceStore),
    [snackbarStore, vesselServiceStore, weightClassSetStore],
  )

  useAsyncFetch(async () => {
    if (weightClassSetFf) {
      weightClassSetUIStore.refresh()
    } else {
      weightClassContainerUIStore.loadWeightClasses()
    }
  }, [weightClassContainerUIStore, weightClassSetFf, weightClassSetUIStore])

  const handleOnSubmit = (formValues: WeightClassFormProfile) => {
    if (weightClassContainerUIStore.areFormValuesValid(formValues)) {
      weightClassContainerUIStore.update(formValues)
    }
  }

  const handleWeighClassSetOnSubmit = async () => {
    await weightClassSetUIStore.upsertWeightClassSets(weightClassSetUIStore.weightClassSetsControl)
  }

  return (
    <>
      {weightClassSetFf ? (
        <WeightClassSet
          id={formId}
          onSubmit={handleWeighClassSetOnSubmit}
          viewStore={weightClassSetUIStore}
        />
      ) : (
        <>
          <Typography>
            {t(
              'weightClassesPageDescription',
              'Define and manage your custom weight classes for container categorization in Kg.',
            )}
          </Typography>
          <Stack gap={3} maxWidth='710px'>
            <WeightClassesForm
              id={formId}
              onSubmit={handleOnSubmit}
              weightClasses={weightClassContainerUIStore.weightClasses}
              validationAlerts={weightClassContainerUIStore.validationAlerts}
              showAlert={(key, message) => weightClassContainerUIStore.showAlert(key, message)}
              canWrite={authStore.hasPermission('write:weight-classes')}
            />
          </Stack>
        </>
      )}
    </>
  )
})

export default WeightClassesContainer
