import {
  WeightClassesApiUpsertWeightClassSetsRequest,
  WeightClassSetResponseDto,
} from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { WeightClassService } from '@storage/services/weight-class.service'

export class WeightClassSetStore extends EntityStore<WeightClassSetResponseDto> {
  constructor(private readonly _weightClassService: WeightClassService) {
    super()
  }

  public async loadAll(): Promise<void> {
    const weightClassSets = await this._weightClassService.getAllWeightClassSets()
    this.setAll(weightClassSets)
  }

  public async upsert(
    createWeightClassDto: WeightClassesApiUpsertWeightClassSetsRequest,
  ): Promise<void> {
    await this._weightClassService.upsertWeightClassSets(createWeightClassDto)
  }

  resetData() {
    this.removeAll()
  }
}
