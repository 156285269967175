import { useAdminStore } from '@admin/AppProvider'
import { TenantFormMapper } from '@admin/pages/Tenants/components/form/tenant-form.mapper'
import { Box } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { Header, useMinimalsTheme } from '@tom-ui/ui'
import { appStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FeatureFlag } from 'modules/utils/src/components/FeatureFlags'
import React from 'react'
import { TenantConfigurationForm } from '../components/form/TenantConfigurationForm'
import { TenantConfigFormProfile } from '../components/form/tenant-config-form.profile'
import { SingleLocationCodeAutocompleteProps } from '../components/form/tenant-config.interface'

interface Props {
  SingleLocationCodeAutoComplete: React.ComponentType<SingleLocationCodeAutocompleteProps>
}

export const TenantConfigurationContainer = observer(
  ({ SingleLocationCodeAutoComplete }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const { timezonesStore, tenantStore, authStore } = useAdminStore()

    const tenant = tenantStore.items.filter(
      item => item.id.toString() === authStore.claims.user_metadata.tenantId,
    )[0]
    const tenantConfigs = tenantStore.currentTenantConfig

    const handleSubmit = async (formValues: TenantConfigFormProfile) => {
      const data = {
        ...tenantConfigs,
        ...formValues,
      }

      try {
        await tenantStore.update(TenantFormMapper.mapFormValuesToUpdateDto(data))
        appStore.setShowAlert(
          'success',
          t('tenantConfigurationSuccessfullySaved', 'Tenant configuration successfully saved'),
        )
      } catch (error) {
        appStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    if (!tenant || (!timezonesStore.cultures && !timezonesStore.timezones)) {
      return <></>
    }

    return (
      <FeatureFlag name='tenant-configuration-page'>
        <>
          <Header title={t('tenantConfiguration', 'Tenant Configuration')} />
          <Box
            sx={{
              padding: theme.customSpacing.l,
              maxWidth: 665,
            }}
          >
            <TenantConfigurationForm
              id='tenant-configuration-form'
              tenant={tenant}
              timezonesStore={timezonesStore}
              onSubmit={handleSubmit}
              SingleLocationCodeAutoComplete={SingleLocationCodeAutoComplete}
            />
          </Box>
        </>
      </FeatureFlag>
    )
  },
)
