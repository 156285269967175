import {
  ContainerAlerts,
  convertErrorCodeToContainerAlert,
} from '@host/hooks/useStorageContainerMessageHandler'
import { ContainerPlanningService } from '@tom-ui/storage/services/container-planning.service'
import { ContainerTurnoverService } from '@tom-ui/storage/services/container-turnover.service'
import { PlanContainerPositionDialogStore } from '@tom-ui/utils'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { ContainerTurnoverDto, YardPositionDto } from 'modules/storage/src/app/api'

export class PlanContainerPositionDialogViewStore {
  destination?: YardPositionDto
  containerTurnover?: ContainerTurnoverDto
  alerts: ContainerAlerts[] = []

  constructor(
    private readonly planContainerPositionDialogStore: PlanContainerPositionDialogStore,
    private readonly containerTurnoverService: ContainerTurnoverService,
    private readonly containerPlanningService: ContainerPlanningService,
  ) {
    makeObservable(this, {
      destination: observable,
      containerTurnover: observable,
      alerts: observable,

      setContainerTurnover: action,
      submit: action,
      setDestination: action,
      reset: action,

      isMovementInvalid: computed,
    })

    reaction(
      () => this.planContainerPositionDialogStore.orderId,
      async () => {
        if (!this.planContainerPositionDialogStore.orderId) return

        const turnover = await this.containerTurnoverService.getByOrderId({
          orderId: planContainerPositionDialogStore.orderId,
        })

        runInAction(() => {
          this.containerTurnover = turnover
        })
      },
    )
  }

  async submit() {
    if (!this.destination || !this.containerTurnover) {
      return
    }

    this.alerts = []

    const dto = {
      containerTurnoverId: this.containerTurnover.id,
      yardPosition: {
        block: this.destination.block,
        bay: this.destination.bay,
        row: this.destination.row,
        tier: this.destination.tier,
      },
      forceCreation: true,
    }

    try {
      if (
        dto.yardPosition.block &&
        dto.yardPosition.bay &&
        dto.yardPosition.row &&
        dto.yardPosition.tier
      )
        await this.containerPlanningService.createReservation(dto)
      else await this.containerPlanningService.createAllocationForSingleTurnover(dto)
    } catch (ex) {
      runInAction(() => {
        this.alerts.push(convertErrorCodeToContainerAlert(ex))
      })
      throw new Error()
    }
  }

  setDestination(destinationDto: YardPositionDto) {
    this.destination = destinationDto
  }

  setContainerTurnover(containerTurnover?: ContainerTurnoverDto) {
    this.containerTurnover = containerTurnover
  }

  reset() {
    this.destination = undefined
    this.containerTurnover = undefined
    this.alerts = []
  }

  get isMovementInvalid() {
    return !this.destination?.block
  }
}
