import { Alert, Box, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { OrderResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { orderService } from '@planning/services'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { useTranslate } from '@tolgee/react'
import { CloseIcon, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { InspectContainerForBooking } from '../../InspectContainerForBooking'
import { BookingSlotList } from '../../Molecules/BookingSlotList'
import { BookingSlotViewStore } from './BookingSlotViewStore'

interface IProps {
  bookingNumber: string
  operator: string
  carrierVisitId: number
  store: GateInViewStore
}

export const BookingSlotDialog: FC<IProps> = observer(
  ({ bookingNumber, operator, carrierVisitId, store }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const { dialogStore, containerAutocompleteStore } = usePlanningStore()

    const bookingSlotViewStore = useMemo(() => new BookingSlotViewStore(store), [store])

    const { loading } = useAsyncFetch(async () => {
      const data = await orderService.getBookings(bookingNumber, operator, carrierVisitId)
      bookingSlotViewStore.setSlots(data)
    }, [bookingSlotViewStore, bookingNumber, carrierVisitId, operator])

    const handleClickSlot = (slot: OrderResponseDto) => {
      bookingSlotViewStore.setSelectedSlot(slot)
      containerAutocompleteStore.setFilter(slot.containerNumber ?? '')
    }

    return (
      <InitializationWrapper isInitializing={loading}>
        <>
          {bookingSlotViewStore.selectedSlot ? (
            <InspectContainerForBooking
              store={bookingSlotViewStore}
              gateInViewStore={store}
              dialogStore={dialogStore}
              displayDamageReport={true}
            />
          ) : (
            <>
              <DialogTitle sx={{ borderBottom: '1px solid rgba(145, 158, 171, 0.24)', padding: 2 }}>
                <Grid container>
                  <Grid item xs={11} display='flex' alignItems='center'>
                    {bookingNumber}
                  </Grid>
                  <Grid item xs={1} display='flex' justifyContent='flex-end'>
                    <IconButton onClick={dialogStore.close}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <Box
                bgcolor={theme.palette.grey[200]}
                p={2}
                sx={{ borderBottom: '1px solid rgba(145, 158, 171, 0.24)' }}
              >
                <Typography variant='h4'>
                  {t('selectBookngToFulfill', 'Select booking to fulfill')}
                </Typography>
              </Box>
              <DialogContent sx={{ bgcolor: theme.palette.grey[50], padding: 2 }}>
                {bookingSlotViewStore.allSlotsAssigned && (
                  <Alert severity='info' sx={{ marginBottom: 2 }}>
                    {t(
                      'allBookingsSlotsAreAssigned',
                      'All bookings slots are assigned to containers',
                    )}
                  </Alert>
                )}
                <BookingSlotList
                  slots={bookingSlotViewStore.bookingWithoutAssignedContainer}
                  onClickSlot={handleClickSlot}
                />
              </DialogContent>
            </>
          )}
        </>
      </InitializationWrapper>
    )
  },
)
