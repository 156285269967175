import { CompanyDto } from '@storage/app/api'
import { companyService } from '@storage/services'
import { makeObservable, observable, runInAction } from 'mobx'

export class CompanyStore {
  companies: CompanyDto[] = []

  constructor() {
    makeObservable(this, {
      companies: observable,
    })
  }

  async loadCompanies() {
    const companies = await companyService.getAll()

    runInAction(() => {
      this.companies = companies
    })
  }
}
