import { Stack, Typography } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { formatContainerNumber } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { HighlightedText } from '@tom-ui/ui'
import { useMemo } from 'react'

interface Props {
  job: JobDto
  highlightText?: string
  variant?: 'h3' | 'h1'
}
export const JobContainerNumber = ({ job, highlightText, variant }: Props) => {
  const { t } = useTranslate()

  const displayName: string = useMemo(() => {
    const displayName = job.cargoUnit?.displayName ?? job.suggestedEmptyContainer?.number

    return displayName
      ? formatContainerNumber(displayName)
      : t('noContainerNumber', 'No container no.')
  }, [job.cargoUnit?.displayName, job.suggestedEmptyContainer?.number])

  const hasSuggestedContainerNumber: boolean = useMemo(
    () => !job.cargoUnit?.displayName && !!job.suggestedEmptyContainer?.number,
    [job.cargoUnit?.displayName, job.suggestedEmptyContainer?.number],
  )

  return (
    <Stack>
      <Typography
        variant={variant ?? 'h3'}
        data-cy={`job-card-container-number-${job.cargoUnit?.displayName}`}
      >
        <HighlightedText highlight={highlightText}>{displayName}</HighlightedText>
      </Typography>
      {hasSuggestedContainerNumber && (
        <Typography variant='body2'>
          {t('suggestedNonNumericContainer', 'Suggested Non numeric container')}
        </Typography>
      )}
    </Stack>
  )
}
