import {
  CancelStartJobCommand,
  FinishJobCommand,
  JobAndYardBlockEquipmentAssignmentDto,
  JobDto,
  JobsApi,
  StartJobCommand,
  WorkInstructionEquipmentDto,
  YardBlockEquipmentAssignmentDto,
} from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'

import { makeObservable, observable, runInAction } from 'mobx'

export interface EquipmentYardBlock {
  equipment: WorkInstructionEquipmentDto
  yardBlockId: string
}

export class JobStore {
  jobAndYardBlocksDto: JobAndYardBlockEquipmentAssignmentDto = {
    jobs: [],
    yardBlockEquipmentAssignments: [],
  }

  constructor() {
    makeObservable(this, {
      jobAndYardBlocksDto: observable,
    })
  }

  async loadJobsByEquipmentId(equipmentId?: number) {
    const { data } = await createApiClient(JobsApi).getNextByEquipment(equipmentId)

    runInAction(() => {
      this.jobAndYardBlocksDto = data
    })

    return data
  }

  async finishJob(finishJobCommand: FinishJobCommand) {
    await createApiClient(JobsApi).finishJob(finishJobCommand)
    //await this.loadJobsByEquipmentId(equipmentId);  //SignalR event will trigger the reload
  }

  async startJob(startJobCommand: StartJobCommand) {
    await createApiClient(JobsApi).startJob(startJobCommand)
    //await this.loadJobsByEquipmentId(equipmentId);  //SignalR event will trigger the reload
  }

  async cancelStartJob(cancelStartJobCommand: CancelStartJobCommand) {
    await createApiClient(JobsApi).cancelStartJob(cancelStartJobCommand)
  }

  async cancelLastJob(workInstructionId: number) {
    await createApiClient(JobsApi).cancelLastJob({ workInstructionId })
  }

  updateJobByWorkInstructionId(item: JobDto, workInstructionId: number) {
    const existingItems = this.jobAndYardBlocksDto.jobs.find(
      x => x.workInstructionId === workInstructionId,
    )

    runInAction(() => {
      if (existingItems)
        this.jobAndYardBlocksDto.jobs = this.jobAndYardBlocksDto.jobs.map(i =>
          i.workInstructionId === workInstructionId ? item : i,
        )
      else this.jobAndYardBlocksDto.jobs.push(item)
    })
  }

  deleteJobByWorkInstructionId(workInstructionId: number) {
    runInAction(() => {
      this.jobAndYardBlocksDto.jobs = this.jobAndYardBlocksDto.jobs.filter(
        x => x.workInstructionId !== workInstructionId,
      )
    })
  }

  deleteJobByWorkInstructionIds(workInstructionIds: number[]) {
    runInAction(() => {
      this.jobAndYardBlocksDto.jobs = this.jobAndYardBlocksDto.jobs.filter(
        x => !workInstructionIds.includes(x.workInstructionId),
      )
    })
  }

  updateYardBlockAssignments(yardBlockAssignments: YardBlockEquipmentAssignmentDto[]) {
    runInAction(() => {
      this.jobAndYardBlocksDto = {
        ...this.jobAndYardBlocksDto,
        yardBlockEquipmentAssignments: yardBlockAssignments,
      }
    })
  }
}
