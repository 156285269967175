import { ContainerDamageLocationDto } from '@admin/app/api'
import { Card, CardHeader, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel } from '@tom-ui/ui'

interface Props {
  containerDamage: ContainerDamageLocationDto
  canEdit: boolean
  onClick: (containerDamageLocation: ContainerDamageLocationDto) => void
}
export const ContainerDamageLocationCard = ({ containerDamage, canEdit, onClick }: Props) => {
  const { t } = useTranslate()

  return (
    <Card onClick={() => onClick(containerDamage)} sx={{ cursor: canEdit ? 'pointer' : undefined }}>
      <CardHeader
        title={
          <Stack flexDirection='row' justifyContent='space-between'>
            <Typography variant='h4'>{containerDamage.name}</Typography>
            {containerDamage.isDefault ? (
              <ColoredLabel label={t('default', 'Default')} color={'secondary'} />
            ) : (
              <ColoredLabel label={t('added', 'Added')} color='warning' />
            )}
          </Stack>
        }
        subheader={
          containerDamage.code ? `${t('code', 'Code')}: ${containerDamage.code}` : undefined
        }
        sx={{ paddingBottom: theme => theme.customSpacing.m }}
      />
    </Card>
  )
}
