import { action, makeObservable, observable } from 'mobx'

export class PlanContainerPositionDialogStore {
  isOpen = false
  orderId?: number

  constructor() {
    makeObservable(this, {
      isOpen: observable,
      orderId: observable,

      closeDialog: action,
      openDialogByOrderId: action,
      setOrderId: action,
    })
  }

  openDialogByOrderId(orderId: number) {
    this.isOpen = true
    this.setOrderId(orderId)
  }

  closeDialog() {
    this.isOpen = false

    this.reset()
  }

  setOrderId(orderId?: number) {
    if (this.orderId !== orderId) this.orderId = orderId
  }

  private reset() {
    this.setOrderId()
  }
}

export const planContainerPositionDialogStore = new PlanContainerPositionDialogStore()
