import { observer } from 'mobx-react-lite'

import { useAuth0 } from '@auth0/auth0-react'
import { getNavitems } from '@host/app/nav-items'
import { appStore } from '@host/stores/AppStore'
import { Box, Button, Drawer, Stack } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { useTranslate } from '@tolgee/react'
import { useAdminStore } from '@tom-ui/admin'
import { useOperationsStore } from '@tom-ui/operations'
import { CloseButton, Logo, useMinimalsTheme } from '@tom-ui/ui'
import { useEffect } from 'react'
import { Avatar } from './nav-section/vertical/Avatar'
import { NavItem } from './nav-section/vertical/NavItem'

export const Sidebar = observer(() => {
  const { t } = useTranslate()
  const { logout } = useAuth0()
  const { tenantStore } = useAdminStore()
  const { equipmentStore } = useOperationsStore()
  const isNewTruckVisitsPageActive = useBooleanFlagValue('new-truck-visits-page', true)

  const theme = useMinimalsTheme()

  const handleClose = () => {
    if (appStore.isMobile) {
      appStore.toggleMenu(false)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      //TODO: when we decide how to handle the mobile version, then this could be uncommented
      //appStore.setIsMobile(window.innerWidth <= 900);
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const navigationItems = getNavitems({
    showUnifiedOperatorPage: tenantStore.showUnifiedOperatorPage,
    skipVesselTally: tenantStore.skipVesselTally,
    skipRailTally: tenantStore.skipRailTally,
    hasGeneralCargo: tenantStore.hasGeneralCargo,
    hasDamageCatalogue: tenantStore.hasDamageCatalogue,
    skipYardPlanning: tenantStore.skipYardPlanning,
    equipmentTypes: equipmentStore.getAvailableEquipmentTypes(),
    isNewTruckVisitsPageActive,
  }).filter(x => !x.hidden)
  navigationItems.forEach(x => (x.children = x.children?.filter(c => !c.hidden)))

  return (
    <Drawer
      open={appStore.isMenuOpen}
      anchor='left'
      variant={!appStore.isMobile ? 'persistent' : 'temporary'}
      onClose={handleClose}
      hideBackdrop={!appStore.isMobile}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          zIndex: '1 !important',
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: appStore.drawerWidth,
          overflowX: 'hidden',
          paddingY: theme.customSpacing.m,
          paddingX: theme.customSpacing.xxs,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRight: '1px solid  rgba(145, 158, 171, 0.24)',
        }}
      >
        <Box>
          <Stack
            padding={appStore.isMenuMobileOpen ? theme.customSpacing.m : theme.customSpacing.l}
            alignItems='center'
            justifyContent={appStore.isMenuMobileOpen ? 'space-between' : 'center'}
            flexDirection='row'
          >
            <Logo withName={appStore.isMenuMobileOpen} />
            {appStore.isMenuMobileOpen && (
              <CloseButton onClose={handleClose} tooltip={t('close', 'Close')} />
            )}
          </Stack>

          <Stack
            gap={theme.customSpacing.xxs}
            padding={appStore.isMenuMobileOpen ? theme.customSpacing.m : undefined}
          >
            {navigationItems.map(route => (
              <NavItem
                key={route.title + route.path}
                route={route}
                depth={1}
                hasChild={!!route.children}
                isMenuOpen={appStore.isMenuMobileOpen}
              />
            ))}
          </Stack>
        </Box>

        <Stack
          padding={theme.customSpacing.m}
          alignItems='center'
          justifyContent={appStore.isMenuMobileOpen ? 'space-between' : 'center'}
          flexDirection='row'
        >
          <Avatar withName={appStore.isMenuMobileOpen} />
          {appStore.isMenuMobileOpen && (
            <Button
              sx={{ color: theme.palette.common.black }}
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            >
              {t('logout', 'Log out')}
            </Button>
          )}
        </Stack>
      </Box>
    </Drawer>
  )
})
