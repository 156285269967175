import { Box, Button } from '@mui/material'
import { ContainerJourneyDto, OrderResponseDto } from '@planning/app/api'
import { IListStore } from '@planning/components/list/SimpleListStore'
import { useTranslate } from '@tolgee/react'
import { ContainerMobile, Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { NavigationStackBackButton } from '../TallymanV2/Components/HeaderComponents/NavigationStackBackButton'
import { GateClerkGeneralCargoResult } from './Components/GateClerkGeneralCargoResult'
import { GateClerkSearchResult } from './Components/GateClerkSearchResult'
import { IInspectContainerFormData } from './Components/InspectContainer'
import { SearchOrdersState } from './SearchOrdersState'

interface IProps {
  store: IListStore<ContainerJourneyDto>
  selectedOrders: IInspectContainerFormData[]
  onClickOrder: (order: OrderResponseDto) => void
  onClickGCOrder: (order: OrderResponseDto) => void
}

export const SearchDropOffOrders: FC<IProps> = observer(
  ({ store, onClickOrder, onClickGCOrder, selectedOrders }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    useEffect(() => {
      window.scrollTo(0, 0)
      if (store && store.reset) store.reset()
    }, [])

    const searchItems = store.items.filter(
      item => !selectedOrders.find(order => order.id === item.id),
    ) as unknown as OrderResponseDto[]

    const InspectOrAddContainerAction: FC = () => {
      return (
        <Button
          variant='contained'
          size='large'
          sx={{ width: '100%', mt: '1rem' }}
          data-cy='gate-clerk-add-container-btn'
        >
          {t('checkContainer', 'Check container')}
        </Button>
      )
    }

    const AddGeneralCargoAction: FC = () => {
      return (
        <Button variant='contained' size='large' sx={{ width: '100%', mt: '1rem' }}>
          {t('add', 'Add')}
        </Button>
      )
    }

    return (
      <>
        <Header
          leftRenderOption={<NavigationStackBackButton />}
          enableSearchField
          onSearchFieldChange={store.setFilter}
          searchFilter={store.filter}
          searchInputType={'tel'}
          filterTestId='search-drop-off-orders-autocomplete'
        />

        <Box style={{ backgroundColor: theme.palette.grey[200], minHeight: '100vh' }}>
          <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
            <SearchOrdersState store={store} />
            {searchItems?.map(o =>
              o.containerNumber === null && o.commodityId !== null ? (
                <GateClerkGeneralCargoResult
                  key={`gc-search-result-${o.id}-${o.containerNumber}`}
                  highlight={store.filter}
                  order={o}
                  direction={'Inbound'}
                  onClick={() => onClickGCOrder(o)}
                >
                  <AddGeneralCargoAction />
                </GateClerkGeneralCargoResult>
              ) : (
                <GateClerkSearchResult
                  key={`container-search-result-${o.id}-${o.containerNumber}`}
                  containerNumber={o.containerNumber ?? ''}
                  holds={o.holds || []}
                  highlight={store.filter}
                  order={{ ...o, customs: [] }}
                  direction={'Inbound'}
                  onClick={() => onClickOrder(o)}
                >
                  <InspectOrAddContainerAction />
                </GateClerkSearchResult>
              ),
            )}
          </ContainerMobile>
        </Box>
      </>
    )
  },
)
