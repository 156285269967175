import { Button, Chip, ListItemText, MenuItem, Stack, Typography } from '@mui/material'
import { OperationType, WorkInstructionJobDto } from '@operations/app/api'
import { SelectOption } from '@operations/app/models'
import { JobTypes, getWorkInstructionJobAmountByType } from '@operations/app/models/operator-pages'
import { JobNavigationProps } from '@operations/features/equipmentOperator/models/job-navigation.model'
import { isCraneEquipmentType } from '@operations/features/equipmentOperator/utils'
import { useTranslate } from '@tolgee/react'
import { ChipWithOptions, useMinimalsTheme } from '@tom-ui/ui'

interface Props extends JobNavigationProps {
  jobs: WorkInstructionJobDto[]
  hasAnySelectedFilter: boolean
  clearFilters: () => void
}

export const WorkInstructionJobsNavigation = ({
  jobs,
  jobType,
  operationType,
  jobNavigationDto,
  selectedEquipmentType,
  navChange,
  operationTypeChange,
  hasAnySelectedFilter,
  clearFilters,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const options: SelectOption[] = [
    {
      label: t('vessels', 'Vessels'),
      value: JobTypes.vessel,
    },
    {
      label: t('externalTrucks', 'External trucks'),
      value: JobTypes.truck,
    },
    {
      label: t('trains', 'Trains'),
      value: JobTypes.train,
    },
    {
      label: t('service', 'Service'),
      value: JobTypes.service,
    },
  ]

  const optionsElement = options.map(option => (
    <MenuItem
      key={option.value}
      selected={option.value === jobType}
      value={option.value}
      onClick={() => {
        navChange(option.value as JobTypes)
      }}
    >
      <ListItemText primary={option.label} primaryTypographyProps={{ variant: 'body2' }} />
      <Typography variant='body2' color='secondary'>
        {getWorkInstructionJobAmountByType(option.value as JobTypes, jobs)}
      </Typography>
    </MenuItem>
  ))

  return (
    <Stack
      flexDirection='row'
      gap={theme.customSpacing.xs}
      justifyContent='space-between'
      flexWrap='wrap'
    >
      <Stack flexDirection='row' gap={theme.customSpacing.xxs}>
        {!isCraneEquipmentType(selectedEquipmentType) && (
          <ChipWithOptions
            options={optionsElement}
            value={options.find(x => x.value === jobType)?.label}
            width='200px'
          />
        )}

        {jobType !== JobTypes.internal &&
          jobType !== JobTypes.service &&
          jobType !== JobTypes.truck && (
            <>
              <Chip
                label={t('discharge', 'Discharge') + ` (${jobNavigationDto.dischargeAmount})`}
                color={operationType === OperationType.Inbound ? 'primary' : undefined}
                onClick={() => operationTypeChange(OperationType.Inbound)}
                onDelete={
                  operationType === OperationType.Inbound ? () => operationTypeChange() : undefined
                }
              />

              <Chip
                label={t('load', 'Load') + ` (${jobNavigationDto.loadAmount})`}
                color={operationType === OperationType.Outbound ? 'primary' : undefined}
                onClick={() => operationTypeChange(OperationType.Outbound)}
                onDelete={
                  operationType === OperationType.Outbound ? () => operationTypeChange() : undefined
                }
              />
            </>
          )}
      </Stack>

      {hasAnySelectedFilter && (
        <Button color='primary' size='small' onClick={clearFilters}>
          {t('clearAll', 'Clear all')}
        </Button>
      )}
    </Stack>
  )
}
