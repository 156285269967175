import { Box } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, PageHeader, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router'
import { IControlledIsoCodeMappingInputProps } from '../Order/stores/IsoCodeMappingInputStore'
import { NNROrderForm } from './Components/organisms/NNROrderForm'
import { NnrOrderControlViewStore, NnrOrderFormData } from './Stores/NnrOrderControlViewStore'

interface Props {
  renderIsoCodeMappingInput?: (
    props: IControlledIsoCodeMappingInputProps<any>,
  ) => React.ReactElement
}

export const NNROrderControlPage: FC<Props> = observer(({ renderIsoCodeMappingInput }) => {
  const { t } = useTranslate()
  const { appViewStore, nnrOrderItemStore, isoCodeMappingInputStore } = usePlanningStore()
  const navigate = useNavigate()
  const theme = useMinimalsTheme()

  isoCodeMappingInputStore.renderIsoCodeMappingInput = renderIsoCodeMappingInput

  const nnrOrderControlViewStore = useMemo(
    () => new NnrOrderControlViewStore(nnrOrderItemStore),
    [nnrOrderItemStore],
  )

  const editLabel = t('editNnrOrder', 'Edit Non-numeric Order')
  const newLabel = t('newNnrOrder', 'New Non-numeric Order')

  const { nnrOrderId } = useParams()

  const title = nnrOrderId ? editLabel : newLabel
  const breadcrumbEditLabel =
    nnrOrderControlViewStore.selectedNnrOrder?.data.referenceNumber ?? newLabel

  const breadcrumbs = [
    {
      label: t('nnrOrders', 'Non-numeric orders'),
      onClick: () => navigate(`${PATH_PLANNING.nnrOrders}`),
    },
    {
      label: breadcrumbEditLabel,
    },
  ]

  const { loading } = useAsyncFetch(async () => {
    const id = Number(nnrOrderId)

    if (id > 0) {
      nnrOrderControlViewStore.setId(id)
      await nnrOrderItemStore.fetchById(id)
    }
  }, [nnrOrderId, nnrOrderControlViewStore, nnrOrderItemStore])

  const onFormSubmit = async (data: NnrOrderFormData) => {
    if (data.containerIsoCode && (!data.archetypeId || data.archetypeId === -1)) {
      appViewStore.setShowAlert(
        'error',
        t('isoCodeDoesNotMatchAnyArchetype', 'ISO Code does not match any archetype'),
      )
      return
    }

    try {
      await nnrOrderControlViewStore.onSubmit(data)
      const successMessage = nnrOrderControlViewStore.isEditMode
        ? t('orderUpdated', 'NNR Order updated')
        : t('orderCreated', 'NNR Order created')
      appViewStore.setShowAlert('success', successMessage)
      navigate(`${PATH_PLANNING.nnrOrders}`)
    } catch (error) {
      appViewStore.setShowAlert('error', 'Failed to save')
    }
  }

  return (
    <InitializationWrapper isInitializing={loading}>
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Breadcrumbs items={breadcrumbs} />
        <PageHeader
          pageTitle={title}
          formId='nnr-order-form'
          onBack={() => navigate(`${PATH_PLANNING.nnrOrders}`)}
          showDeleteButton={!!nnrOrderId}
          onDelete={async () => {
            const confirm = await appViewStore.setOpenConfirmDialog(
              true,
              t(
                'confirmNnrOrderDelete',
                'Do you really want to delete this non-numeric order? The action is irreversible.',
              ),
              'Delete',
            )

            if (confirm) {
              try {
                await nnrOrderControlViewStore.onDelete(Number(nnrOrderId))
                appViewStore.setShowAlert('success', t('orderDeleted', 'NNR Order deleted'))
                navigate(`${PATH_PLANNING.nnrOrders}`)
              } catch (error) {
                appViewStore.setShowAlert('error', t('failedToDelete', 'Failed to delete'))
              }
            }
          }}
        />
        <Box
          paddingX={theme.customSpacing.l}
          paddingY={theme.customSpacing.m}
          gap={theme.customSpacing.m}
          display={'flex'}
          flexDirection={'column'}
        >
          <NNROrderForm viewStore={nnrOrderControlViewStore} onSubmit={onFormSubmit} />
        </Box>
      </>
    </InitializationWrapper>
  )
})
