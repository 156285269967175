import {
  CompaniesApi,
  CompanyDto,
  CreateCompanyCommand,
  UpdateCompanyCommand,
} from '@billing/app/api'
import { createApiClient } from '@billing/app/http-client'
import { BaseEntityStore } from './base/BaseEntityStore'

export class CompanyStore extends BaseEntityStore<CompanyDto> {
  async load() {
    const { data } = await createApiClient(CompaniesApi).get()
    this.updateStoreItems(data)
  }

  async add(company: CreateCompanyCommand) {
    const { data } = await createApiClient(CompaniesApi).create(company)

    this.updateStoreItem({ ...company, id: data }, data)
  }

  async update(company: UpdateCompanyCommand) {
    const { data } = await createApiClient(CompaniesApi).update(company)

    this.updateStoreItem({ ...company, id: data }, data)
  }

  async delete(id: number) {
    await createApiClient(CompaniesApi)._delete(id)

    this.deleteStoreItem(id)
  }
}
