import { ExternalDriverResponseDto } from '@planning/app/api'
import externalDriverService from '@planning/services/externalDriverService'
import { debounce } from 'lodash'
import { action, makeObservable, observable, runInAction } from 'mobx'

export class DriverAutoCompleteStore {
  options: ExternalDriverResponseDto[] = []
  isFiltertingByName = true

  constructor() {
    makeObservable(this, {
      options: observable,
      isFiltertingByName: observable,

      filterOptions: action,
      setOptions: action,
      setFilteringByName: action,
    })
  }

  setOptions = (options: ExternalDriverResponseDto[]) => {
    runInAction(() => {
      this.options = options
    })
  }

  setFilteringByName = (isFiltertingByName: boolean) => {
    this.isFiltertingByName = isFiltertingByName
  }

  filterOptions = debounce(async (filter?: string) => {
    if (!filter) return
    const data = await externalDriverService.getByPartialNameOrId(filter, this.isFiltertingByName)
    this.setOptions(data)
  }, 500)
}
