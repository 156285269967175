import { Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { SummaryCard } from './Organisms/SummaryCard'

interface CargoDetailsSummaryProps {
  isEmpty: boolean
  dangerousGoods?: boolean
  seals?: boolean
}

export function CargoDetailsSummary({ isEmpty, dangerousGoods, seals }: CargoDetailsSummaryProps) {
  const { t } = useTranslate()

  const attributes = [
    {
      key: 'status',
      label: t('status', 'Status'),
      value: isEmpty ? t('Empty', 'Empty') : t('full', 'Full'),
    },
    {
      key: 'dangerousGoods',
      label: t('dangerousGoods', 'Dangerous Goods'),
      value: dangerousGoods ? t('yes', 'Yes') : t('no', 'No'),
    },
    {
      key: 'seals',
      label: t('seals', 'Seals'),
      value: seals ? t('yes', 'Yes') : t('no', 'No'),
    },
  ]

  return (
    <SummaryCard
      title={<Typography variant='h4'>{t('cargoDetails', 'Cargo Details')}</Typography>}
      attributes={attributes}
    />
  )
}
