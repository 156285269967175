import { OrderDto, StockDto } from '@billing/app/api'
import { SelectOption } from '@billing/app/models'
import { GCStorageReportMode } from '@billing/app/models/gc-storage-report-mode.enum'
import ordersService from '@billing/services/OrdersService'
import { CompanyStore } from '@billing/stores/CompanyStore'
import { Dayjs } from 'dayjs'
import { action, makeObservable, observable, runInAction } from 'mobx'

export class GCStoragePreBillingUIStore {
  customerId = 'All'
  from: Dayjs | null = null
  to: Dayjs | null = null
  inboundOrders: OrderDto[] = []
  stockInventories: StockDto[] = []
  ordersToBePreBilled: number[] | null = null
  reportMode: GCStorageReportMode = GCStorageReportMode.Stock

  constructor(private readonly _customerStore: CompanyStore) {
    makeObservable(this, {
      from: observable,
      to: observable,
      customerId: observable,
      inboundOrders: observable,
      stockInventories: observable,
      ordersToBePreBilled: observable,
      reportMode: observable,
      setCustomerId: action,
      setFrom: action,
      setTo: action,
      loadData: action,
      setIdsToSubmit: action,
      setReportMode: action,
    })
  }

  setCustomerId(customerId: string) {
    this.customerId = customerId
  }

  get customerOptions(): SelectOption[] {
    const options: SelectOption[] = []
    options.push(...this._customerStore.items.map(c => ({ label: c.name, value: c.id.toString() })))
    return options
  }

  setFrom(from: Dayjs | null) {
    this.from = from
  }

  setTo(to: Dayjs | null) {
    this.to = to
  }
  async loadData() {
    if (this.reportMode === GCStorageReportMode.InboundOrder) {
      const result = await ordersService.getFulfilledInboundOrders(
        this.customerId === 'All' ? undefined : parseInt(this.customerId),
        this.from?.toISOString(),
        this.to?.toISOString(),
      )

      runInAction(() => {
        this.inboundOrders = result
      })
    } else {
      const result = await ordersService.getStockInventory(
        this.customerId === 'All' ? undefined : parseInt(this.customerId),
        this.from?.toISOString(),
        this.to?.toISOString(),
      )
      runInAction(() => {
        this.stockInventories = result
      })
    }
  }

  resetFilter() {
    this.setFrom(null)
    this.setTo(null)
  }

  setIdsToSubmit(orderIds: number[]) {
    this.ordersToBePreBilled = orderIds
  }

  setReportMode(reportMode: GCStorageReportMode) {
    this.reportMode = reportMode
  }
}
