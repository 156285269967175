import { ArchetypesApi, IsoCodeMappingsApi, RolesApi, TenantsApi, UsersApi } from '@admin/app/api'
import { createApiClient, setupInterceptors } from '@admin/app/http-client'
import { TenantDrawerUIStore } from '@admin/pages/Tenants/stores/tenant-drawer.ui-store'
import { TenantUIStore } from '@admin/pages/Tenants/stores/tenant.ui-store'
import { UserDialogUIStore } from '@admin/pages/Users/stores/user-dialog.ui-store'
import { UserDrawerUIStore } from '@admin/pages/Users/stores/user-drawer.ui-store'
import { UserUIStore } from '@admin/pages/Users/stores/user.ui-store'
import { RoleStore } from '@admin/stores/role.store'

import { TenantStore } from '@admin/stores/tenant.store'
import { TimezonesStore } from '@admin/stores/timezones.store'
import { UserStore } from '@admin/stores/user.store'
import { AppStore, appStore as appStoreObject, authStore, AuthStore } from '@tom-ui/utils'

import { createContext, ReactNode, useContext } from 'react'
import { ContainerDamageLocationDialogUIStore } from './pages/DamageCatalogue/stores/container-damage-location-dialog.ui-store'
import { ContainerDamageLocationListUIStore } from './pages/DamageCatalogue/stores/container-damage-location-list.ui-store'
import { ContainerDamageTypeDialogUIStore } from './pages/DamageCatalogue/stores/container-damage-type-dialog.ui-store'
import { ContainerDamageTypeListUIStore } from './pages/DamageCatalogue/stores/container-damage-type-list.ui-store'
import { IsoCodeMappingUIStore } from './pages/IsoCodeMapping/stores/iso-code-mapping.ui-store'
import { ArchetypeStore } from './stores/Archetype.store'
import { ContainerDamageLocationStore } from './stores/ContainerDamageLocationStore'
import { ContainerDamageTypeStore } from './stores/ContainerDamageTypeStore'
import { IsoCodeMappingStore } from './stores/IsoCodeMapping.store'
export interface IStore {
  appStore: AppStore
  tenantStore: TenantStore
  userStore: UserStore
  authStore: AuthStore
  roleStore: RoleStore
  timezonesStore: TimezonesStore
  containerDamageLocationStore: ContainerDamageLocationStore
  containerDamageTypeStore: ContainerDamageTypeStore
  isoCodeMappingStore: IsoCodeMappingStore
  archetypeStore: ArchetypeStore
}

type IViewStore = {
  tenantUIStore: TenantUIStore
  tenantDrawerUIStore: TenantDrawerUIStore
  userUIStore: UserUIStore
  userDialogUIStore: UserDialogUIStore
  userDrawerUIStore: UserDrawerUIStore
  containerDamageLocationListUIStore: ContainerDamageLocationListUIStore
  containerDamageLocationDialogUIStore: ContainerDamageLocationDialogUIStore
  containerDamageTypeListUIStore: ContainerDamageTypeListUIStore
  containerDamageTypeDialogUIStore: ContainerDamageTypeDialogUIStore
  isoCodeMappingUIStore: IsoCodeMappingUIStore
}

const appStore = appStoreObject
const tenantStore = new TenantStore(createApiClient(TenantsApi))
const userStore = new UserStore(createApiClient(UsersApi))
const roleStore = new RoleStore(createApiClient(RolesApi))
const timezonesStore = new TimezonesStore(createApiClient(TenantsApi))
const isoCodeMappingStore = new IsoCodeMappingStore(createApiClient(IsoCodeMappingsApi))
const archetypeStore = new ArchetypeStore(createApiClient(ArchetypesApi))
const containerDamageLocationStore = new ContainerDamageLocationStore()
const containerDamageTypeStore = new ContainerDamageTypeStore()

const tenantUIStore = new TenantUIStore(tenantStore)
const tenantDrawerUIStore = new TenantDrawerUIStore(tenantStore)
const userUIStore = new UserUIStore(userStore)
const userDialogUIStore = new UserDialogUIStore(userStore)
const userDrawerUIStore = new UserDrawerUIStore(userStore, tenantStore)
const isoCodeMappingUIStore = new IsoCodeMappingUIStore(isoCodeMappingStore)
const containerDamageLocationListUIStore = new ContainerDamageLocationListUIStore(
  containerDamageLocationStore,
)
const containerDamageLocationDialogUIStore = new ContainerDamageLocationDialogUIStore(
  containerDamageLocationStore,
)
const containerDamageTypeListUIStore = new ContainerDamageTypeListUIStore(containerDamageTypeStore)
const containerDamageTypeDialogUIStore = new ContainerDamageTypeDialogUIStore(
  containerDamageTypeStore,
)

const store: IAppStore = {
  appStore,
  tenantStore,
  userStore,
  authStore,
  roleStore,
  timezonesStore,
  tenantUIStore,
  containerDamageLocationStore,
  containerDamageTypeStore,
  tenantDrawerUIStore,
  userUIStore,
  userDialogUIStore,
  userDrawerUIStore,
  containerDamageLocationListUIStore,
  containerDamageLocationDialogUIStore,
  containerDamageTypeListUIStore,
  containerDamageTypeDialogUIStore,
  isoCodeMappingStore,
  isoCodeMappingUIStore,
  archetypeStore,
}

export interface IAppStore extends IStore, IViewStore {}

setupInterceptors(appStore)

const TomAdminContext = createContext<IAppStore>({ ...store })
export const AdminStoreProvider = ({ children }: { children: ReactNode }) => {
  return <TomAdminContext.Provider value={store}>{children}</TomAdminContext.Provider>
}

export const useAdminStore = () => useContext(TomAdminContext)
