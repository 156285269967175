import { ErrorCode } from '@planning/utils/ErrorCode/error-code-enum'
import {
  getDomainModelExceptionPayload,
  isDomainModelException,
} from '@planning/utils/ErrorCode/error-code-utils'
import { useTranslate } from '@tolgee/react'

export const useExternalDriverMessagesHandler = () => {
  const { t } = useTranslate()
  const externalDriverText = t('externalDriver', 'External driver')

  const getExceptionMessage = (error: any) => {
    if (isDomainModelException(error, ErrorCode.EntityNotFound)) {
      return t('entityNotFound', '{entity} with not found', {
        entity: externalDriverText,
      })
    }

    if (isDomainModelException(error, ErrorCode.NameNotUnique)) {
      const payload = getDomainModelExceptionPayload(error, ErrorCode.NameNotUnique)

      return t('driverNameAlreadyExists', 'Name: Driver name {driverName} already exists', {
        driverName: payload,
      })
    }

    if (isDomainModelException(error, ErrorCode.DriverIdNotUnique)) {
      const payload = getDomainModelExceptionPayload(error, ErrorCode.DriverIdNotUnique)

      return t('driverIDAlreadyExists', 'ID: Driver ID {driverID} already exists', {
        driverID: payload,
      })
    }

    return (
      error?.response?.data?.detail ??
      t('failedToSaveExternalDriver', 'Failed to save external driver')
    )
  }

  const getSuccessMessage = (isEditMode: boolean, name: string) => {
    if (isEditMode) {
      return t(
        'externalDriverWasUpdatedSuccessfully',
        'External driver {name} was updated successfully',
        { name: name },
      )
    }

    return t(
      'externalDriverWasCreatedSuccessfully',
      'External driver {name} was created successfully',
      { name: name },
    )
  }

  return { getExceptionMessage, getSuccessMessage }
}
