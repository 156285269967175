import { Container } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { NoListResult, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { ArchetypeUIStore } from '../../stores/archetype.ui-store'
import { ArchetypeCard } from '../molecules/ArchetypeCard'

interface IProps {
  viewStore: ArchetypeUIStore
}

export const ArchetypeList: FC<IProps> = observer(({ viewStore }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigate = useNavigate()

  const hasFilteredItems =
    viewStore.filter && (!viewStore.archetypes || viewStore.archetypes.length === 0)

  return (
    <Container
      sx={{
        paddingY: theme.customSpacing.m,
      }}
    >
      {hasFilteredItems && (
        <NoListResult
          result={t('noArchetypeFound', 'No archetypes found')}
          filtering={hasFilteredItems}
        />
      )}
      {viewStore.archetypes.map(item => (
        <ArchetypeCard
          key={item.id}
          filter={viewStore.filter}
          archetype={item}
          onClickEdit={() => {
            navigate(`/registers/archetypes/${item.id}`)
          }}
        />
      ))}
    </Container>
  )
})
