import { Autocomplete, TextField } from '@mui/material'
import { LocationCodeAutocompleteViewStore } from '@planning/stores/locationCode/LocationCodeAutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'

interface Props {
  store: LocationCodeAutocompleteViewStore
  values?: string[]
  error?: boolean
  helperText?: React.ReactNode
  label?: string
  required?: boolean
  disabled?: boolean
  multiple?: boolean
  onChange: (locationCodes: string[]) => void
}

export const LocationCodeAutoComplete: FC<Props> = observer(
  ({ store, values, error, helperText, label, required, disabled, onChange }) => {
    const { t } = useTranslate()

    const [fieldValue, setFieldValue] = useState<string[]>(values ?? [])
    const [inputValue, setInputValue] = useState<string>('')

    useEffect(() => {
      setFieldValue(values ?? [])
      setInputValue('')
    }, [values])

    return (
      <Autocomplete
        fullWidth
        disabled={disabled}
        multiple={true}
        open={store.open}
        isOptionEqualToValue={(option: string, value: string) => option === value}
        onOpen={() => store.setOpened(true)}
        onClose={() => store.setOpened(false)}
        options={store.options}
        value={fieldValue ?? []}
        onChange={(_, selectedLocationCode: string[] | null) => {
          setFieldValue(selectedLocationCode ?? [])
          onChange(selectedLocationCode ?? [])
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
          store.filterOptions(newInputValue)
        }}
        renderInput={params => (
          <TextField
            {...params}
            required={required}
            placeholder={
              values?.length
                ? ''
                : t('startTypingTOGetlocationCode', 'Start typing to get location code')
            }
            data-cy='location-code-autocomplete'
            label={label}
            helperText={helperText}
            error={error}
          />
        )}
      />
    )
  },
)
