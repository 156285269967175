import { TenantDto } from '@admin/app/api'
import { TenantConfigFormProfile } from './tenant-config-form.profile'

const mapDtoToFormValues = ({
  id,
  name,
  timezoneId,
  cultureId,
  address,
  truckArrivalWindowDuration,
  unloCodes,
  configs,
  reeferContainerMaxUnpluggedTime,
}: TenantDto): TenantConfigFormProfile => ({
  id,
  name: name ?? '',
  timezone: timezoneId ?? '',
  culture: cultureId ?? '',
  address: address ?? '',
  truckArrivalWindowDuration: truckArrivalWindowDuration ?? undefined,
  unloCodes: unloCodes ?? [],
  hasDamageCatalogue: configs?.hasDamageCatalogue,
  reeferContainerMaxUnpluggedTime:
    reeferContainerMaxUnpluggedTime || reeferContainerMaxUnpluggedTime === 0
      ? reeferContainerMaxUnpluggedTime
      : undefined,
})

export const TenantConfigFormMapper = {
  mapDtoToFormValues,
}
