import { Paper } from '@mui/material'
import { CarrierVisitDirection } from '@planning/app/api'
import { RailcarSequencingStore } from '@planning/stores/railcarSequencing/RailcarSequencingStore'
import { FC } from 'react'
import { ContainerYardOperationViewStore } from '../VesselVisit/Components'
import { ObserverGrouping } from './Components/Grouping/ObserverGrouping'

interface IProps {
  store: RailcarSequencingStore
  containerYardOperationViewStore: ContainerYardOperationViewStore
  direction: CarrierVisitDirection
}

export const RailOrderGrouping: FC<IProps> = ({
  store,
  containerYardOperationViewStore,
  direction,
}) => {
  return (
    <Paper variant='elevation' elevation={1}>
      <ObserverGrouping
        store={store}
        containerYardOperationViewStore={containerYardOperationViewStore}
        direction={direction}
      />
    </Paper>
  )
}
