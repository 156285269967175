import { Autocomplete, TextField, Typography } from '@mui/material'
import { VesselServiceDto } from '@storage/app/api'
import { DialogFormContainer } from '@storage/components/DialogFormContainer'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { WeightClassSetContainerUIStore } from '../../stores/weight-class-set-ui-store'

export interface WeightClassSetVesselServiceFormData {
  vesselService: VesselServiceDto
}

export interface WeightClassSetWeightClassFormData {
  id?: string
  name?: string
  min?: number
  max?: number | null
  maxFieldIsInfinite: boolean
}

interface Props {
  viewStore: WeightClassSetContainerUIStore
  selectedVesselService?: VesselServiceDto
}

export const WeightClassSetVesselServiceDialog: FC<Props> = observer(
  ({ viewStore, selectedVesselService }) => {
    const { t } = useTranslate()
    const {
      control,
      formState: { errors },
      reset,
      getValues,
      handleSubmit,
    } = useForm<WeightClassSetVesselServiceFormData>({
      defaultValues: {
        vesselService: selectedVesselService,
      },
    })

    useEffect(() => {
      reset()
    }, [reset, viewStore.isVesselServiceDialogOpen])

    return (
      <DialogFormContainer
        formType={viewStore.editingVesselServiceId ? 'Edit' : 'Add'}
        open={viewStore.isVesselServiceDialogOpen}
        form={
          <form
            id='weightClassSetVesselServiceForm'
            onSubmit={handleSubmit(viewStore.handleVesselServiceSubmit)}
          >
            <Typography>
              {t(
                'selectVesselService',
                'Select vessel service that the  weight classes should affect',
              )}
            </Typography>
            <Controller
              control={control}
              name={`vesselService`}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <>
                  <Autocomplete
                    clearIcon={false}
                    sx={{ marginTop: '1rem' }}
                    options={viewStore.notInUseVesselServices}
                    getOptionLabel={vs => vs.name}
                    value={getValues('vesselService')}
                    onChange={(_, values) => {
                      onChange(values)
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={
                          <>
                            {t('vesselService', 'Vessel Service')}{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        error={!!errors.vesselService}
                        helperText={errors.vesselService ? t('required', 'Required') : ''}
                      />
                    )}
                  />
                </>
              )}
            />
          </form>
        }
        entityTitle={t('weightClassSet', 'Weight class set')}
        onClose={() => {
          viewStore.setEditingVesselServiceId(undefined)
          viewStore.toggleVesselServiceDialog()
        }}
      />
    )
  },
)
