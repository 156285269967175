import {
  ContainerDamageTypeDto,
  CreateContainerDamageTypeCommand,
  UpdateContainerDamageTypeCommand,
} from '@admin/app/api'
import { ContainerDamageTypeFormProfile } from './container-damage-type-form.profile'

const mapFormValuesToCreateDto = ({
  longName,
  shortName,
  externalCodes,
  isActive,
}: ContainerDamageTypeFormProfile): CreateContainerDamageTypeCommand => ({
  longName,
  shortName,
  externalCodes,
  isActive,
})

const mapFormValuesToUpdateDto = ({
  id,
  longName,
  shortName,
  externalCodes,
  isActive,
}: ContainerDamageTypeFormProfile): UpdateContainerDamageTypeCommand => ({
  id: id!,
  longName,
  shortName,
  externalCodes,
  isActive,
})

const mapDtoToFormValues = ({
  id,
  longName,
  shortName,
  externalCodes,
  isActive,
}: ContainerDamageTypeDto): ContainerDamageTypeFormProfile => ({
  id,
  longName,
  shortName: shortName ?? '',
  externalCodes,
  isActive,
})

export const ContainerDamageTypeFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
