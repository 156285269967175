import { Autocomplete, TextField } from '@mui/material'
import { ArchetypeResponseDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo, useState } from 'react'
import { ArchetypeAutoCompleteStore } from './ArchetypeAutoCompleteStore'

interface Props {
  defaultValues?: ArchetypeResponseDto
  error?: boolean
  helperText?: React.ReactNode
  label?: string
  required?: boolean
  disabled?: boolean
  multiple?: boolean
  onChange: (archetype: ArchetypeResponseDto | undefined | null) => void
}

export const ArchetypeAutoComplete: FC<Props> = observer(
  ({ defaultValues, error, helperText, label, required, disabled, onChange }) => {
    const { t } = useTranslate()

    const store = useMemo(() => new ArchetypeAutoCompleteStore(), [])

    const [fieldValue, setFieldValue] = useState<ArchetypeResponseDto | undefined | null>(
      defaultValues,
    )
    const [inputValue, setInputValue] = useState<string>('')

    useEffect(() => {
      setFieldValue(defaultValues)
      setInputValue('')
    }, [defaultValues])

    return (
      <Autocomplete
        disablePortal
        disabled={disabled}
        getOptionLabel={(archetype: ArchetypeResponseDto) =>
          archetype.name ?? t('unknown', 'Unknown')
        }
        open={store.open}
        onOpen={() => store.setOpened(true)}
        onClose={() => store.setOpened(false)}
        options={store.options}
        value={fieldValue}
        isOptionEqualToValue={(option: ArchetypeResponseDto, value: ArchetypeResponseDto) =>
          option.id === value.id
        }
        onChange={(_, selectedArchetype: ArchetypeResponseDto | null) => {
          setFieldValue(selectedArchetype)
          onChange(selectedArchetype)
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
          store.filterOptions(newInputValue)
        }}
        renderInput={params => (
          <TextField
            {...params}
            required={required}
            label={<>{label ?? t('archetype', 'Archetype')} </>}
            data-cy='archetype-autocomplete'
            helperText={helperText}
            error={error}
          />
        )}
      />
    )
  },
)
