import { PATHS } from '@host/app/paths'
import { Box, Grid } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import {
  adminActionService,
  HandleOrderPageLocationState,
  InitializationWrapper,
  OrderAlerts,
  PageHeader,
  usePlanningStore,
} from '@tom-ui/planning'
import { CheckinOrderCommand } from '@tom-ui/planning/app/api'
import { useOrderBreadcrumbs } from '@tom-ui/planning/hooks/useOrderBreadcrumbs'
import {
  CheckinContainerForm,
  ICheckinContainerFormData,
} from '@tom-ui/planning/pages/Container/components/Organism/CheckinContainerForm'
import { ContainerSummary } from '@tom-ui/planning/pages/Order/components/ContainerSummary'
import { CargoDetailsSummary } from '@tom-ui/planning/pages/Order/components/Organisms/CargoDetailsSummary'
import { canOrderProceed } from '@tom-ui/planning/utils/order-utils'
import { useStorageStore, YardOperationControl } from '@tom-ui/storage'
import { Breadcrumbs, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { CheckinContainerViewStore } from './Stores/CheckinContainerViewStore'

const DEFAULT_RETURN_PATH = PATHS.visits

export const CheckinContainerPage: FC = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigate = useNavigate()
  const [isValidState, setIsValidState] = useState(false)

  const { appViewStore, containerItemStore, orderItemStore, tenantStore } = usePlanningStore()
  const { yardOperationControlStore } = useStorageStore()

  const {
    getVesselVisitCheckinContainerBreadcrumbs,
    getRailVisitCheckinContainerBreadcrumbs,
    getContainerVisitCheckinContainerBreadcrumbs,
  } = useOrderBreadcrumbs()

  const store = useMemo(
    () => new CheckinContainerViewStore(containerItemStore, orderItemStore),
    [containerItemStore, orderItemStore],
  )

  const { state, pathname } = useLocation() as {
    state: HandleOrderPageLocationState
    pathname: string
  }

  const { orderId: orderIdParam } = useParams()
  const orderId = Number(orderIdParam)

  const { loading: loadingOrder } = useAsyncFetch(async () => {
    store.setOrderId(orderId)
    await orderItemStore.fetchById(orderId)
  }, [orderId, orderItemStore, store])

  const { loading: loadingContainer } = useAsyncFetch(async () => {
    if (store.order?.containerId) {
      await containerItemStore.fetchById(store.order.containerId)
    }
  }, [store.order?.containerId, containerItemStore])

  useEffect(() => {
    const isValid = canOrderProceed({
      allowGateInWhenUnitNotOnTerminal: tenantStore.allowGateInWhenUnitNotOnTerminal,
      direction: store.order?.direction,
      hasActiveHolds: store.order?.holds?.some(x => x.status === 'Active'),
      isOnTerminal: store.order?.isOnTerminal,
      skipReleaseOrder: tenantStore.skipReleaseOrder,
    })
    setIsValidState(isValid)
  }, [store.order, tenantStore.allowGateInWhenUnitNotOnTerminal, tenantStore.skipReleaseOrder])

  const onSubmit = async (formData: ICheckinContainerFormData) => {
    try {
      store.setLoading(true)

      if (!store.hasSelectedDestination) {
        appViewStore.setShowAlert(
          'error',
          t('selectValidYardLocation', 'Please select a valid yard location'),
        )
        return
      }

      if (store.container?.number && store.orderId) {
        const cmd: CheckinOrderCommand = {
          orderId: orderId ?? 0,
          carrierVisitId: formData.transport.carrierVisitId,
          carrierType: formData.transport.carrierType ?? 'Universal',
          arrivalTime: formData.arrivalTime.toISOString(),
          customs: formData.customs ?? null,
        }

        await adminActionService.checkinOrder(cmd)

        await yardOperationControlStore.onConfirm(store.container?.number, store.orderId, true)
        appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved successfully'))
        onBack()
      } else {
        throw new Error('Container number or Order id is missing')
      }
    } catch (e) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    } finally {
      store.setLoading(false)
    }
  }

  const onBack = () => {
    navigate(state?.returnPath || DEFAULT_RETURN_PATH)
  }

  const getRouteBreadcrumbs = useCallback(
    (pathname: string) => {
      if (!store.order) return []

      const path = pathname.split('/').filter(Boolean)

      const isContainerVisit = path.includes('container-visits')
      const isVesselVisit = path.includes('vessel-visits')
      const isRailVisit = path.includes('rail-visits')

      if (isContainerVisit) {
        return getContainerVisitCheckinContainerBreadcrumbs(store.order)
      } else if (isVesselVisit) {
        return getVesselVisitCheckinContainerBreadcrumbs(store.order)
      } else if (isRailVisit) {
        return getRailVisitCheckinContainerBreadcrumbs(store.order)
      }

      return []
    },
    [
      getVesselVisitCheckinContainerBreadcrumbs,
      getRailVisitCheckinContainerBreadcrumbs,
      getContainerVisitCheckinContainerBreadcrumbs,
      store.order,
    ],
  )

  return (
    <InitializationWrapper
      isInitializing={
        store.isLoading || loadingOrder || loadingContainer || !store.order || !store.container
      }
    >
      <>
        <Breadcrumbs items={getRouteBreadcrumbs(pathname)} />
        <PageHeader
          formId='check-container-form'
          onBack={onBack}
          saveTitle={t('checkinContainer', 'Check in')}
          pageTitle={t('checkinContainer', 'Check in container')}
          saveDisabled={!isValidState}
        />

        <Box
          paddingX={theme.customSpacing.l}
          paddingY={theme.customSpacing.m}
          gap={theme.customSpacing.m}
          display={'flex'}
          flexDirection={'column'}
        >
          {store.container && store.order && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ContainerSummary container={store.container} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CargoDetailsSummary
                    isEmpty={store.order.isEmpty}
                    dangerousGoods={!!store.order.isDangerous}
                    seals={!!store.order.hasSeals}
                  />
                </Grid>

                <Grid item xs={12}>
                  <OrderAlerts
                    direction={store.order.direction}
                    hasActiveHolds={store.order.holds?.some(x => x.status === 'Active')}
                    isOnTerminal={store.order.isOnTerminal}
                    releaseEnd={store.order.releaseEnd}
                  />
                </Grid>

                {store.container.number && isValidState && (
                  <Grid item xs={12}>
                    <CheckinContainerForm
                      order={store.order}
                      onSubmitFn={onSubmit}
                      yardLocationComponent={
                        <YardOperationControl
                          containerNumber={store.container.number}
                          handleChange={(value: any[]) => {
                            store.setHasSelectedDestination(!!value)
                          }}
                          orderId={store.orderId}
                        />
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
      </>
    </InitializationWrapper>
  )
})
