import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useMinimalsTheme } from '../../../hooks'
import { PageHeaderActions } from '../pageHeaderActions/PageHeaderActions'

interface IProps {
  children?: React.ReactNode
  pageTitle?: string
  saveTitle?: string
  cancelTitle?: string
  formId: string
  saveDisabled?: boolean
  showSaveButton?: boolean
  showDeleteButton?: boolean
  showCancelButton?: boolean
  onDelete?: () => void
  onBack?: () => void
}

export const PageHeader: FC<IProps> = observer(
  ({
    pageTitle,
    saveTitle,
    cancelTitle,
    children,
    formId,
    saveDisabled,
    showSaveButton,
    showDeleteButton,
    showCancelButton,
    onDelete,
    onBack,
  }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    return (
      <Stack
        paddingX={theme.customSpacing.l}
        paddingY={theme.customSpacing.s}
        alignItems={'center'}
        direction={'row'}
        sx={{ placeContent: 'space-between' }}
        borderBottom={`1px solid ${theme.palette.divider}`}
      >
        <Stack alignItems={'flex-start'}>
          <Typography variant='h3' align='center'>
            {pageTitle ?? t('orderDetails', 'Order details')}
          </Typography>
          <Box>{children}</Box>
        </Stack>
        <PageHeaderActions
          saveTitle={saveTitle}
          cancelTitle={cancelTitle}
          formId={formId}
          saveDisabled={saveDisabled}
          showSaveButton={showSaveButton}
          showDeleteButton={showDeleteButton}
          showCancelButton={showCancelButton}
          onDelete={onDelete}
          onBack={onBack}
        />
      </Stack>
    )
  },
)
