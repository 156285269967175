import { Alert, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DragAndDropZoneWithFilePreview } from '@planning/components/DragAndDropZoneWithFilePreview'
import { LoaderButton } from '@planning/components/LoaderButton'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { LocationCodesParsingStore } from './LocationCodesParsingStore'

export const MasterLocationCodesPage = observer(() => {
  const { t } = useTranslate()

  const locationCodesParsingStore = useMemo(() => new LocationCodesParsingStore(), [])

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/csv': ['.csv'],
    },
    onDrop: (acceptedFiles: File[]) => {
      locationCodesParsingStore.setUploadFailedReason()

      if (acceptedFiles.length > 0) {
        locationCodesParsingStore.setFile(acceptedFiles[0])
      }
    },
  })

  return (
    <Stack gap={2} sx={{ margin: '1rem' }}>
      <Typography variant='h1'>Location Codes</Typography>
      <DragAndDropZoneWithFilePreview
        file={locationCodesParsingStore.file}
        inputProps={getInputProps()}
        rootProps={getRootProps()}
        setFile={locationCodesParsingStore.setFile}
      />

      {locationCodesParsingStore.uploadFailedReason && (
        <Alert severity='error'>
          <Typography variant='body2' color='error'>
            {locationCodesParsingStore.uploadFailedReason}
          </Typography>
        </Alert>
      )}

      {locationCodesParsingStore.resultCount && (
        <Alert severity='success'>
          {locationCodesParsingStore.resultCount} location codes updated from file &quot;
          {locationCodesParsingStore.resultFileName}&quot;
        </Alert>
      )}

      <Box
        sx={{
          width: '100%',
          p: '1rem 2rem!important',
        }}
      >
        <LoaderButton
          onClick={async () => await locationCodesParsingStore.submit()}
          variant='contained'
          color='primary'
          disabled={!locationCodesParsingStore.file}
        >
          {t('confirmUpdates', 'Confirm updates')}
        </LoaderButton>
      </Box>
    </Stack>
  )
})
