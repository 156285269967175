import { AlertColor } from '@mui/material'
import { action, computed, makeObservable, observable } from 'mobx'
import { ReactNode } from 'react'
import { v4 as uuid } from 'uuid'

export interface AlertMessage {
  key: string
  severity: AlertColor
  message: string
  cancellable?: boolean
  cancelledMessage?: string
  action?: ReactNode
  translate?: boolean
  request?: () => Promise<void>
  reset?: () => void
}

export const isActiveRoute = (startsWith: string, endWith?: string) => {
  const currentHost = window.location.pathname
  return currentHost.startsWith(startsWith) && (!endWith || currentHost.endsWith(endWith))
}

export class AppStore {
  loadingCounter = 0
  forbiddenLoader = false
  forcedLoader = false

  alertQueue: AlertMessage[] = []
  delay = 5000

  isOnline = navigator.onLine

  constructor() {
    makeObservable(this, {
      loadingCounter: observable,
      forbiddenLoader: observable,
      forcedLoader: observable,
      alertQueue: observable,
      isOnline: observable,

      isLoading: computed,

      increaseLoadingCounter: action,
      decreaseLoadingCounter: action,
      forbidLoader: action,
      unforbidLoader: action,
      forceLoader: action,
      unforceLoader: action,
      setShowAlert: action,
      showAlertForCancellableRequest: action,
      cancelAlert: action,
      removeAlert: action,
      setIsOnline: action,
    })
    this.setupConnectionListener()
  }

  public get isLoading() {
    return (this.forcedLoader || !this.forbiddenLoader) && this.loadingCounter > 0
  }

  increaseLoadingCounter = () => {
    this.loadingCounter++
  }

  decreaseLoadingCounter = () => {
    this.loadingCounter--
  }

  forbidLoader = () => {
    //We should only forbidLoader when previous loading is done
    if (this.loadingCounter === 0 && this.forbiddenLoader === false) this.forbiddenLoader = true
  }

  unforbidLoader = () => {
    if (this.forbiddenLoader === true) this.forbiddenLoader = false
  }

  forceLoader = () => {
    this.forcedLoader = true
  }

  unforceLoader = () => {
    this.forcedLoader = false
  }

  requestWithLoader = async (request: Promise<any>) => {
    this.increaseLoadingCounter()
    await request
    this.decreaseLoadingCounter()
  }

  triggerReloadBySignalR = async (
    reloadFunction: () => Promise<void>,
    activeRouteStartsWith: string,
    activeRouteEndWith?: string,
  ) => {
    if (!isActiveRoute(activeRouteStartsWith, activeRouteEndWith)) return // we don't want to call load when page is not active

    await this.triggerRequestWithoutLoader(reloadFunction)
  }

  triggerRequestWithoutLoader = async (request: () => Promise<void>) => {
    this.forbidLoader()
    await request()
    this.unforbidLoader()
  }

  setShowAlert(severity: AlertColor, message: string, action?: ReactNode, translate = false) {
    this.alertQueue = [
      ...this.alertQueue,
      { severity, message, translate, key: uuid(), action: action },
    ]
  }

  showAlertForCancellableRequest(
    severity: AlertColor,
    message: string,
    requestFunc: () => Promise<void>,
    cancelledMessage?: string,
    reset?: () => void,
  ) {
    this.alertQueue = [
      ...this.alertQueue,
      {
        severity,
        message,
        cancelledMessage,
        cancellable: true,
        request: requestFunc,
        reset: reset,
        key: uuid(),
      },
    ]
  }

  cancelAlert(alert: AlertMessage) {
    if (alert.reset) {
      alert.reset()
    }

    alert.request = undefined

    this.removeAlert(alert)

    if (alert.cancelledMessage) {
      this.setShowAlert('success', alert.cancelledMessage)
    }
  }

  async removeAllAlerts() {
    while (this.alertQueue.length > 0) {
      await this.removeAlert(this.alertQueue[0])
    }
  }

  async removeAlert(alert: AlertMessage) {
    const index = this.alertQueue.findIndex(x => x === alert)
    if (index >= 0) {
      this.alertQueue = this.alertQueue.filter((_, i) => i !== index)

      if (alert.request) {
        await alert.request!()
      }
    }
  }

  setIsOnline(isOnline: boolean) {
    this.isOnline = isOnline
  }

  setupConnectionListener() {
    window.addEventListener('online', () => this.setIsOnline(true))
    window.addEventListener('offline', () => this.setIsOnline(false))
  }
}

export const appStore = new AppStore()
