import { Box, Grid, Typography } from '@mui/material'
import { CarrierVisitDirection } from '@planning/app/api'
import { ContainerYardOperationViewStore } from '@planning/pages/VesselVisit/Components'
import { OrderGroupListItem } from '@planning/pages/VesselVisit/Components/Grouping/OrderGroupListItem'
import {
  RailcarSequencingStore,
  RailcarTrackPosition,
} from '@planning/stores/railcarSequencing/RailcarSequencingStore'
import { formatRailcarName } from '@planning/utils/railcar-utils'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, Group, HighlightedText, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Id } from 'react-beautiful-dnd'

interface Props {
  store: RailcarSequencingStore
  item: { id: Id; content: RailcarTrackPosition }
  group: Group
  direction: CarrierVisitDirection
  containerYardOperationViewStore: ContainerYardOperationViewStore
}

export const RailcarGroupingItem: FC<Props> = observer(
  ({ store, containerYardOperationViewStore, item, group }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    return (
      <Grid container display={'flex'} flexDirection={'column'}>
        <Grid
          key='grp-header'
          item
          paddingX={1.5}
          height={'60px'}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          borderBottom={`1px solid ${theme.palette.grey[300]}`}
          sx={{ backgroundColor: theme.palette.grey[200], placeContent: 'space-between' }}
          textAlign={'start'}
          paddingLeft={'58px'}
        >
          <Typography component={'div'} variant='subtitle1' data-cy={'railcar-grouping-item'}>
            <HighlightedText highlight={store.filter} color={theme.palette.info.main}>
              {`${t('railcarNo', 'Railcar no')}.: ${formatRailcarName(item.content.railCar)}`}
            </HighlightedText>
          </Typography>
          <Box display={'flex'} gap={0.5} flexDirection={'row'} sx={{ marginRight: '35px' }}>
            {item.content.isMissingRailcar && (
              <ColoredLabel label={t('missingRailcar', 'Missing railcar')} color='secondary' />
            )}
            {item.content.hasIssue && (
              <ColoredLabel label={t('unresolved', 'Unresolved')} color='warning' />
            )}
            {item.content.checkedIn && (
              <ColoredLabel label={t('checkedIn', 'Checked-in')} color='success' />
            )}
            {item.content.checkedOut && (
              <ColoredLabel label={t('checkedOut', 'Checked-out')} color='success' />
            )}
          </Box>
        </Grid>
        <Grid key='grp-orders' item>
          {item.content.orders?.map(order => (
            <OrderGroupListItem
              key={`grp-gil-${order.id}`}
              hasThreeDotMenu={true}
              group={group}
              item={{ id: `${order.id}`, content: order }}
              filter={store.filter}
              containerYardOperationViewStore={containerYardOperationViewStore}
            />
          ))}
        </Grid>
      </Grid>
    )
  },
)
