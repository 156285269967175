import { IContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourney'
import { isContainerReefer } from '@tom-ui/utils'
import { computed } from 'mobx'

export const canAssignCoolingOrder = (journey: IContainerJourney) => {
  const { stuffingOrderId, coolingOrderId, inboundOrder, container } = journey

  const isReefer = computed(() => isContainerReefer(container.type)).get()

  if (coolingOrderId || !inboundOrder?.order || !isReefer) return false

  if (inboundOrder.order.isEmpty && !stuffingOrderId) return false

  return true
}

export const canAssignStrippingOrder = (journey: IContainerJourney) => {
  const { strippingOrderId, inboundOrder } = journey
  return !strippingOrderId && inboundOrder?.order && !inboundOrder.order.isEmpty
}

export const canAssignStuffingOrder = (journey: IContainerJourney) => {
  const { stuffingOrderId, strippingOrderId, inboundOrder } = journey
  return !stuffingOrderId && inboundOrder?.order && (inboundOrder.order.isEmpty || strippingOrderId)
}
