import { ErrorCode } from '@planning/utils/ErrorCode/error-code-enum'
import {
  getDomainModelExceptionPayload,
  isDomainModelException,
} from '@planning/utils/ErrorCode/error-code-utils'
import { useTranslate } from '@tolgee/react'

export const useVesselMessagesHandler = () => {
  const { t } = useTranslate()

  const getExceptionMessage = (error: any) => {
    if (isDomainModelException(error, ErrorCode.EntityNotFound)) {
      const payload = getDomainModelExceptionPayload(error, ErrorCode.EntityNotFound)

      return t('entityNotFound', '{entity} with Id {id} not found', {
        entity: payload?.name,
        id: payload?.id,
      })
    }

    if (isDomainModelException(error, ErrorCode.NameNotUnique)) {
      const payload = getDomainModelExceptionPayload(error, ErrorCode.NameNotUnique)

      return t('fieldNotUnique', 'The {field} "{name}" already exists', {
        field: t('name', 'name'),
        name: payload,
      })
    }

    if (isDomainModelException(error, ErrorCode.ShortNameNotUnique)) {
      const payload = getDomainModelExceptionPayload(error, ErrorCode.ShortNameNotUnique)

      return t('fieldNotUnique', 'The {field} "{name}" already exists', {
        field: t('shortName', 'Short name'),
        name: payload,
      })
    }

    if (isDomainModelException(error, ErrorCode.VesselOperatorNotFound)) {
      const payload = getDomainModelExceptionPayload(error, ErrorCode.VesselOperatorNotFound)

      return t('fieldNotFound', 'The {field} "{name}" not found', {
        field: t('vesselOperator', 'Vessel operator'),
        name: payload,
      })
    }

    if (isDomainModelException(error, ErrorCode.ShippingAgentNotFound)) {
      const payload = getDomainModelExceptionPayload(error, ErrorCode.ShippingAgentNotFound)

      return t('entityNotFound', '{entity} with Id {id} not found', {
        entity: t('shippingAgent', 'Shipping agent'),
        id: payload,
      })
    }

    if (isDomainModelException(error, ErrorCode.VesselServiceNotFound)) {
      const payload = getDomainModelExceptionPayload(error, ErrorCode.VesselServiceNotFound)

      return t('entityNotFound', '{entity} with Id {id} not found', {
        entity: t('vesselService', 'Vessel service'),
        id: payload,
      })
    }

    return (
      error?.response?.data?.detail ??
      t('failedToSaveVesselService', 'Failed to save vessel service')
    )
  }

  const getSuccessMessage = (isEditMode: boolean, name: string) => {
    if (isEditMode) {
      return t('vesselUpdatedSuccessfully', 'Vessel {name} updated successfully', { name: name })
    }

    return t('vesselCreatedSuccessfully', 'Vessel {name} created successfully', { name: name })
  }

  return { getExceptionMessage, getSuccessMessage }
}
