import { Stack, Typography } from '@mui/material'
import { CarrierType, ContainerTurnoverDto } from '@storage/app/api'
import {
  DEFAULT_OCCUPANCY_BACKGROUND,
  EXPORT_OCCUPANCY_BACKGROUND,
  IMPORT_OCCUPANCY_BACKGROUND,
} from '@storage/pages/yard-management/constants'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useMemo } from 'react'
import { Group, Rect } from 'react-konva'
import { Html } from 'react-konva-utils'
import { ContainerTurnoverIcons } from './ContainerTurnoverIcons'
import { YardBlockBayFilterBy, YardBlockBayOverviewItem } from './models'
import { MultipleStackingIcon } from './MultipleStackingIcon'

export interface YardBlockSlotProps {
  item: YardBlockBayOverviewItem
  left: number
  top: number
  slotWidth: number
  filterViewBy: YardBlockBayFilterBy
  onMenuAction?: (action: string, turnover: ContainerTurnoverDto) => void
  tenantConfigStore: TenantConfigStore
}

export const YardBlockBayOverviewSlotItem = ({
  item,
  left,
  top,
  slotWidth,
  onMenuAction,
  filterViewBy: viewExportBy,
  tenantConfigStore,
}: YardBlockSlotProps) => {
  const { palette } = useMinimalsTheme()

  const { width, height, padding } = {
    width: slotWidth,
    height: slotWidth,
    padding: slotWidth / 30,
  }

  const turnover = item.renderedTurnover
  const isReservation = item.reservations?.find(e => e.id === turnover?.id)
  const isOccupancy = item.occupancies?.find(e => e.id === turnover?.id)
  const isExport = turnover?.outboundCarrierType === CarrierType.Vessel
  const isImport = !isExport && turnover?.inboundCarrierType === CarrierType.Vessel
  const otherCarrierTypeThanVessel = !isExport && !isImport && isOccupancy

  const fillColor = useMemo(() => {
    if (turnover) {
      if (isOccupancy) {
        return isImport
          ? IMPORT_OCCUPANCY_BACKGROUND
          : isExport
            ? EXPORT_OCCUPANCY_BACKGROUND
            : DEFAULT_OCCUPANCY_BACKGROUND
      }
    }
    return DEFAULT_OCCUPANCY_BACKGROUND
  }, [turnover, isOccupancy, isImport, isExport])

  const strokeColor = useMemo(() => {
    if (isReservation) {
      return palette.info.main
    }

    if (otherCarrierTypeThanVessel) {
      return palette.grey[500]
    }

    if (!tenantConfigStore.skipYardPlanning && item.slot.isAllocated) {
      return palette.primary.main
    }

    return palette.grey[300]
  }, [
    isReservation,
    otherCarrierTypeThanVessel,
    tenantConfigStore.skipYardPlanning,
    item.slot.isAllocated,
    palette,
  ])

  const strokeWidth = useMemo(() => {
    if (otherCarrierTypeThanVessel) {
      return 2
    }

    if (!tenantConfigStore.skipYardPlanning && item.slot.isAllocated) {
      return 3
    }

    return 1
  }, [otherCarrierTypeThanVessel, tenantConfigStore.skipYardPlanning, item.slot.isAllocated])

  const opacity = useMemo(() => (isReservation ? 0.5 : 1), [isReservation])

  const textColor = useMemo(
    () => (isReservation ? palette.info.main : palette.common.black),
    [isReservation, palette.common.black, palette.info.main],
  )

  const subtitle = useMemo(() => {
    if (turnover) {
      if (isImport) {
        if (viewExportBy === 'TripId') {
          return turnover.inboundTripIds?.length ? turnover.inboundTripIds[0] : ''
        } else {
          return turnover.inboundCarriers.map(x => x.name).join() ?? ''
        }
      } else {
        if (viewExportBy === 'TripId') {
          return turnover.outboundTripIds?.length ? turnover.outboundTripIds[0] : ''
        } else {
          return turnover.outboundCarriers.map(x => x.name).join() ?? ''
        }
      }
    }

    return ''
  }, [isImport, turnover, viewExportBy])

  return (
    <>
      <Group width={width} height={height} x={left} y={top} overflow='hidden'>
        <Rect
          width={width}
          height={height}
          fill={fillColor}
          strokeWidth={strokeWidth}
          stroke={strokeColor}
          hitStrokeWidth={0}
        />
        <Html>
          <Stack
            sx={{
              width: width,
              height: height,
              overflow: 'hidden',
              padding: 0.5,
              justifyContent: 'space-between',
              opacity: opacity,
            }}
          >
            {turnover && (
              <>
                <Stack flexDirection='row' justifyContent='space-between'>
                  <Typography variant='caption' sx={{ color: `${textColor} !important` }}>
                    {(isImport ? turnover.portOfLoading : turnover.portOfDischarge) ?? ''}
                  </Typography>

                  <MultipleStackingIcon occupancies={item.occupancies} />

                  <Typography variant='caption' sx={{ color: `${textColor} !important` }}>
                    {turnover.containerOperator ?? ''}
                  </Typography>
                </Stack>

                <Typography
                  variant='subtitle1'
                  sx={{
                    maxWidth: `${width - 2 * padding}px`,
                    textWrap: 'none',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: `${textColor} !important`,
                  }}
                >
                  {turnover.containerNumber}
                </Typography>

                <Stack flexDirection='row' justifyContent='space-between'>
                  <Typography variant='caption' sx={{ color: `${textColor} !important` }}>
                    {turnover.containerIsoCode ?? ''}
                  </Typography>
                  <Typography variant='caption' sx={{ color: `${textColor} !important` }}>
                    {parseFloat(((turnover.grossWeight ?? 0) / 1000).toFixed(1)) + 't'}
                  </Typography>
                </Stack>

                <Typography
                  variant='body2'
                  sx={{
                    maxWidth: `${width - 2 * padding}px`,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: `${textColor} !important`,
                  }}
                >
                  {subtitle}
                </Typography>

                <ContainerTurnoverIcons
                  turnover={turnover}
                  occupancies={item.occupancies}
                  onUpdatePosition={onMenuAction}
                />
              </>
            )}
          </Stack>
        </Html>
      </Group>
    </>
  )
}
