import { BooleanSelectOption, SelectOption } from '@storage/app/models'
import { useMemo } from 'react'

const KEY_PREFIX = 'OOG'

export const useOOGOptions = (): SelectOption[] =>
  useMemo(
    () => [
      { label: KEY_PREFIX, value: BooleanSelectOption.True },
      { label: `non${KEY_PREFIX}`, value: BooleanSelectOption.False },
    ],
    [],
  )
