import { ContainerJourneyResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetContainerJourneysQuery } from '@planning/messages/queries/getContainerJourneysQueryHandler'
import { ItemStore } from '@planning/rt-stores/base/ItemStore'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { IEntityStore } from '@planning/rt-stores/types'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { ContainerJourneyItem, IContainerJourneyItem } from './ContainerJourneyItem'

export class ContainerJourneyItemStore
  extends ItemStore<ContainerJourneyResponseDto, IContainerJourneyItem>
  implements IEntityStore<IContainerJourneyItem>
{
  private loadedContainerIds: number[] = []
  private orderItemStore?: OrderItemStore

  constructor(private readonly messageBus: IMessageBus) {
    super((key, data) => new ContainerJourneyItem(this, key, data, this.orderItemStore))
    makeObservable(this, {
      journeysByInboundOrderId: computed,
      journeysByOutboundOrderId: computed,
      receiveContainerJourneysMessage: action,
      receiveContainerJourneysUpsertedEventMessage: action,
    })

    messageBus.subscribeEvent(GetContainerJourneysQuery.type, this.receiveContainerJourneysMessage)
    messageBus.subscribeEvent(
      EventTypes.ContainerJourneysUpsertedEvent,
      this.receiveContainerJourneysUpsertedEventMessage,
    )
  }

  connect = (orderItemStore: OrderItemStore) => {
    this.orderItemStore = orderItemStore
  }

  get journeysByInboundOrderId() {
    return _(this.elements)
      .mapKeys(value => value.data.inboundOrderId)
      .value()
  }

  get journeysByOutboundOrderId() {
    return _(this.elements)
      .mapKeys(value => value.data.outboundOrderId)
      .value()
  }

  fetch = async (containerIds: number[], includeCompleted?: boolean) => {
    const containerIdsToLoad = containerIds.filter(id => !this.loadedContainerIds.includes(id))
    if (!containerIdsToLoad.length) return

    this.loadedContainerIds = [...this.loadedContainerIds, ...containerIdsToLoad]

    await this.messageBus.dispatchQuery(
      new GetContainerJourneysQuery(containerIdsToLoad, includeCompleted ?? false),
    )
  }

  receiveContainerJourneysMessage = (event: IEvent<ContainerJourneyResponseDto[]>): void => {
    console.log('received container journeys', event.payload)
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveContainerJourneysUpsertedEventMessage = (event: IEvent<ContainerJourneyResponseDto[]>) => {
    console.log('received ContainerJourneysUpsertedEvent', event.payload)
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }
}
