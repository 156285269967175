import { useAdminStore } from '@admin/AppProvider'
import { useTranslate } from '@tolgee/react'

import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { TenantConfigurationProps } from './components/form/tenant-config.interface'
import { TenantConfigurationContainer } from './containers/TenantConfigurationContainer'

const TenantConfiguration = observer(({ autocomplete }: TenantConfigurationProps) => {
  const { t } = useTranslate()
  const { tenantUIStore, timezonesStore, authStore } = useAdminStore()

  useEffect(() => {
    const pageData = async () => {
      const requests = [
        timezonesStore.load(),
        tenantUIStore.store.loadOne(Number(authStore.claims.user_metadata.tenantId)),
        tenantUIStore.store.loadConfigs(),
      ]
      await Promise.all(requests)
    }

    pageData()
  }, [authStore.claims, tenantUIStore.store, timezonesStore])

  return (
    <>
      <Helmet>
        <title>{t('tenantConfiguration', 'Tenant Configuration')}</title>
      </Helmet>

      <TenantConfigurationContainer SingleLocationCodeAutoComplete={autocomplete} />
    </>
  )
})

export default TenantConfiguration
