import {
  ContainerSlotDto,
  ContainerSlotPositionType,
  ContainerVesselDirectionType,
} from '@operations/app/api'
import {
  LoadingColors,
  isJobAssignedByEquipment,
  isLoadOperation,
  isRestow,
} from '@operations/features/craneOperator/utils'

import { Stack, Theme, Typography, styled } from '@mui/material'
import { Box } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import { SlotContainer } from './SlotContainer'

interface Props {
  isHull?: boolean
  currentEquipmentId?: number
  zoom: number
  slotContainer?: ContainerSlotDto | null
  slotBaroti: string
  operationType: ContainerVesselDirectionType
}

const UnavailableSlot = styled(Box)<{ zoom: number }>(({ zoom }) => ({
  flex: 'none',
  height: `${7 * zoom}rem`,
  width: `${7 * zoom}rem`,
  borderRadius: `${4 * zoom}px`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: `${24 * zoom}px`,
  backgroundColor: '#F4F6F8',
}))

const EmptySlot = styled(Box)<{ zoom: number }>(({ zoom }) => ({
  flex: 'none',
  height: `${7 * zoom}rem`,
  width: `${7 * zoom}rem`,
  borderRadius: `${4 * zoom}px`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: `${24 * zoom}px`,
  border: 'solid 1px #C4CDD5',
  backgroundColor: '#FFFFFF',
}))

const Container = styled(Stack)<{ zoom: number }>(({ zoom }) => ({
  height: `${7 * zoom}rem`,
  width: `${7 * zoom}rem`,
  padding: `${5 * zoom}px`,
  borderRadius: `${4 * zoom}px`,
  border: 'solid 1px #C4CDD5',
}))

const containerBgColor = (
  theme: Theme,
  operationType: ContainerVesselDirectionType,
  container?: ContainerSlotDto | null,
  currentEquipmentId?: number,
): string => {
  if (!container) return '#F4F6F8' //TOLATER: this on Figma is not using theme (maybe ask Ludivine why?)

  if (operationType === ContainerVesselDirectionType.RemainOnBoard) return theme.palette.grey[300]

  if (
    currentEquipmentId &&
    !isJobAssignedByEquipment(container, currentEquipmentId) &&
    !isRestow(container)
  )
    return theme.palette.grey[700]

  if (operationType === ContainerVesselDirectionType.Inbound) return theme.palette.info.light
  if (isLoadOperation(operationType)) return LoadingColors[container.portOfDischargeIndex]

  return 'black'
}

export const SlotPanel = ({
  isHull,
  currentEquipmentId,
  zoom,
  slotContainer,
  slotBaroti,
  operationType,
}: Props) => {
  const theme = useMinimalsTheme()

  if (isHull) return <UnavailableSlot zoom={zoom} />

  if (!slotContainer) return <EmptySlot zoom={zoom} />

  if (
    slotContainer.workInstruction?.confirmed &&
    operationType !== ContainerVesselDirectionType.Outbound
  )
    return <EmptySlot zoom={zoom} />

  return (
    <Container
      zoom={zoom}
      sx={{
        backgroundColor: containerBgColor(theme, operationType, slotContainer, currentEquipmentId),
        opacity: `${
          isLoadOperation(operationType) && !slotContainer.workInstruction?.confirmed ? '55%' : ''
        }`,
      }}
      direction='column'
      justifyContent='center'
      alignItems='stretch'
      spacing={1 * zoom}
    >
      {slotContainer.containerPositionType === ContainerSlotPositionType.FortyBack ? (
        <Typography
          marginTop={`${3 * zoom}px`}
          color='gray'
          fontSize={`${150 * zoom}px`}
          fontWeight='lighter'
          alignSelf='center'
        >
          X
        </Typography>
      ) : (
        <SlotContainer
          operationType={operationType}
          slotBaroti={slotBaroti}
          zoom={zoom}
          slotContainer={slotContainer}
          currentEquipmentId={currentEquipmentId}
        />
      )}
    </Container>
  )
}
