import { Grid } from '@mui/material'
import { OrderResponseDto } from '@planning/app/api'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { BookingSlotItem } from './BookingSlotItem'

interface IProps {
  slots: OrderResponseDto[]
  onClickSlot?: (slot: OrderResponseDto) => void
}

export const BookingSlotList: FC<IProps> = observer(({ slots, onClickSlot }) => {
  const theme = useMinimalsTheme()

  return (
    <Grid
      bgcolor={theme.palette.common.white}
      border={`1px solid ${theme.palette.divider}`}
      borderRadius={theme.customRadius.medium}
      display='flex'
      alignItems='center'
      container
      direction={'column'}
      spacing={0}
    >
      {slots.map(slot => (
        <Grid item key={slot.id} width='100%'>
          <BookingSlotItem order={slot} onClickSlot={onClickSlot} />
        </Grid>
      ))}
    </Grid>
  )
})
