import {
  CarrierType,
  CarrierVisitAllocationRuleDto,
  ContainerTurnoverDto,
  WeightClassDto,
} from '@storage/app/api'
import { UnknownNumberValue, UnknownStringValue } from '@storage/app/models'
import _ from 'lodash'
import { filterContainerTurnoversByWeightClass } from './yard-planning-dashboard-stack.util'

export const getFilteredTurnovers = (
  allocationRule: CarrierVisitAllocationRuleDto,
  containerTurnovers: ContainerTurnoverDto[],
  weightClasses: WeightClassDto[],
): ContainerTurnoverDto[] => {
  let filteredTurnovers: ContainerTurnoverDto[] = []
  if (allocationRule.facets.containerNumber && allocationRule.facets.containerNumber !== '') {
    filteredTurnovers = containerTurnovers.filter(
      x => x.containerNumber === allocationRule.facets.containerNumber,
    )
  } else {
    filteredTurnovers = containerTurnovers.filter(
      turnover =>
        turnover.size === allocationRule.facets.size &&
        turnover.isReefer === allocationRule.facets.isReefer &&
        turnover.isDangerous === allocationRule.facets.isDangerous &&
        turnover.isOOG === allocationRule.facets.isOOG,
    )

    if (allocationRule.facets.isEmpty !== undefined && allocationRule.facets.isEmpty !== null) {
      filteredTurnovers = filteredTurnovers.filter(
        turnover => turnover.isEmpty === allocationRule.facets.isEmpty,
      )
    }

    if (allocationRule.facets.containerHeight) {
      filteredTurnovers = filteredTurnovers.filter(
        turnover => turnover.containerHeight === allocationRule.facets.containerHeight,
      )
    }
    if (allocationRule.facets.containerType) {
      filteredTurnovers = filteredTurnovers.filter(
        turnover => turnover.containerType === allocationRule.facets.containerType,
      )
    }

    if (allocationRule.facets.containerOperator !== '') {
      filteredTurnovers = filteredTurnovers.filter(
        turnover => turnover.containerOperator === allocationRule.facets.containerOperator,
      )
    }

    if (allocationRule.facets.consignee !== '') {
      filteredTurnovers = filteredTurnovers.filter(
        turnover =>
          turnover.consignee === allocationRule.facets.consignee ||
          (allocationRule.facets.consignee === UnknownStringValue && !turnover.consignee),
      )
    }

    if (allocationRule.facets.customerId) {
      filteredTurnovers = filteredTurnovers.filter(
        turnover =>
          turnover.customerId === allocationRule.facets.customerId ||
          (allocationRule.facets.customerId === UnknownNumberValue && !turnover.customerId),
      )
    }

    // Exclude unknown when having carrier type as universal
    if (allocationRule.facets.outboundCarrierType) {
      filteredTurnovers = filteredTurnovers.filter(
        turnover =>
          turnover.outboundCarrierType === allocationRule.facets.outboundCarrierType ||
          (allocationRule.facets.outboundCarrierType === CarrierType.Unknown &&
            !turnover.outboundCarrierType) ||
          (allocationRule.facets.outboundCarrierType === CarrierType.Universal &&
            turnover.outboundCarrierType),
      )
    }

    if (allocationRule.facets.inboundCarrierType) {
      filteredTurnovers = filteredTurnovers.filter(
        turnover =>
          turnover.inboundCarrierType === allocationRule.facets.inboundCarrierType ||
          (allocationRule.facets.inboundCarrierType === CarrierType.Unknown &&
            !turnover.inboundCarrierType) ||
          (allocationRule.facets.inboundCarrierType === CarrierType.Universal &&
            turnover.inboundCarrierType),
      )
    }

    if (allocationRule.facets.portOfDischarge) {
      filteredTurnovers = filteredTurnovers.filter(
        turnover =>
          turnover.portOfDischarge === allocationRule.facets.portOfDischarge ||
          (allocationRule.facets.portOfDischarge === UnknownStringValue &&
            !turnover.portOfDischarge),
      )
    }

    if (allocationRule.facets.weightClasses.length > 0) {
      if (allocationRule.facets.weightClasses.includes(UnknownStringValue)) {
        const orderedWeightClasses = _(weightClasses)
          .orderBy(x => x.minWeight)
          .value()
        const minWeight = orderedWeightClasses[0]?.minWeight
        const maxWeight = orderedWeightClasses[orderedWeightClasses.length - 1]?.maxWeight

        filteredTurnovers = filteredTurnovers.filter(
          x =>
            !x.grossWeight ||
            x.grossWeight < minWeight ||
            (!!maxWeight && x.grossWeight > maxWeight),
        )
      } else {
        const filteredWeightClasses = weightClasses.filter(wc =>
          allocationRule.facets.weightClasses
            .map(s => s.toUpperCase())
            .includes(wc.name.toUpperCase()),
        )

        filteredTurnovers = filterContainerTurnoversByWeightClass(
          filteredTurnovers,
          filteredWeightClasses,
        )
      }
    }
  }

  return filteredTurnovers
}
