import { VesselServiceApi, VesselServiceDto } from '@storage/app/api'

export class VesselServiceService {
  constructor(private api: VesselServiceApi) {}

  async getAll(): Promise<Array<VesselServiceDto>> {
    const { data: vesselServices } = await this.api.getAll()
    return vesselServices
  }
}
