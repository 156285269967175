import {
  CarrierType,
  CarrierVisitContainerDataDto,
  CarrierVisitDirection,
  CarrierVisitDto,
  CarrierVisitStatus,
  CarrierVisitSummaryDto,
  CarrierVisitsApi,
  CarrierVisitsApiGetAllRequest,
  ContainerTurnoverDto,
} from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { EntityStore } from '@storage/app/store/entity.store'
import {
  CarrierVisitListItem,
  CarrierVisitListItemFactory,
} from '@storage/components/vessel-visits-list/models'
import { YardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { AlertStore, AppAlert } from './alert.store'
import { IssueStore } from './issue.store'

export interface UnallocatedSummaryDto {
  unplannedLoad: ContainerTurnoverDto[]
  unplannedDischarge: ContainerTurnoverDto[]
}

export interface UnallocatedTurnoversBreakDown {
  size: number
  isEmpty?: boolean
  isDangerous?: boolean
  isOOG?: boolean
  isReefer?: boolean
  consignee?: string
  portOfDischarge?: string
  weightClass?: string
  outboundCarrierType?: CarrierType
  customer?: string
  containerNumber?: string
  value: ContainerTurnoverDto[]
}

export interface CarrierVisitInfo extends CarrierVisitDto {
  summary?: CarrierVisitSummaryDto
}

export class CarrierVisitStore extends EntityStore<CarrierVisitDto> {
  carrierVisitSummaries: Map<number, CarrierVisitSummaryDto> = new Map()
  carrierVisitContainerData: CarrierVisitContainerDataDto[] = []
  carrierVisitUnallocatedContainersSummary: Map<number, UnallocatedSummaryDto> = new Map()
  constructor(
    private carrierVisitApi: CarrierVisitsApi,
    private _issueStore: IssueStore,
    yardManagementHubConnection: YardManagementHubConnection,
  ) {
    super()

    makeObservable(this, {
      carrierVisitSummaries: observable,
      carrierVisitUnallocatedContainersSummary: observable,
      loadCarrierVisitSummary: action,
      loadCarrierVisitSummaryV2: action,
      resetData: action,
      carrierVisitContainerData: observable,
      setCarrierVisitControllData: action,
    })

    yardManagementHubConnection.onCarrierVisitUpserted(carrierVisit => {
      this.addOrUpdate(carrierVisit)
    })
    yardManagementHubConnection.onCarrierVisitsDeleted(carrierVisitIds => {
      carrierVisitIds.forEach(id => this.remove(id))
    })
  }

  public setCarrierVisitControllData(data: CarrierVisitContainerDataDto[]) {
    this.carrierVisitContainerData = data
  }

  public async loadCarrierVisitControllData() {
    const { data } = await this.carrierVisitApi.getCarrierVisitContainerData({})

    runInAction(() => this.setCarrierVisitControllData(data))
  }

  resetData() {
    this.removeAll()
    this.carrierVisitSummaries = new Map()
    this.carrierVisitUnallocatedContainersSummary = new Map()
  }

  static isLoadingDataNotEmpty(carrierVisitSummary: CarrierVisitSummaryDto | undefined) {
    return (
      carrierVisitSummary &&
      (carrierVisitSummary.plannedLoad > 0 ||
        carrierVisitSummary.unplannedLoad > 0 ||
        carrierVisitSummary.inYardLoad > 0)
    )
  }

  static isDischargeDataNotEmpty(carrierVisitSummary: CarrierVisitSummaryDto | undefined) {
    return (
      carrierVisitSummary &&
      (carrierVisitSummary.plannedDischarge > 0 ||
        carrierVisitSummary.unplannedDischarge > 0 ||
        carrierVisitSummary.inYardDischarge > 0)
    )
  }

  static isDischargeAndLoadingEmpty(carrierVisitSummary: CarrierVisitSummaryDto | undefined) {
    return (
      !CarrierVisitStore.isLoadingDataNotEmpty(carrierVisitSummary) &&
      !CarrierVisitStore.isDischargeDataNotEmpty(carrierVisitSummary)
    )
  }

  getCarrierVisitData(carrierType: CarrierType): Record<CarrierVisitStatus, number> {
    const initialValue = {
      Expected: 0,
      Arrived: 0,
      InOperation: 0,
      Departed: 0,
      Completed: 0,
    }

    const carrierTypeMap: Record<CarrierType, string> = {
      Vessel: 'VesselVisit',
      Train: 'RailVisit',
      Universal: '',
      Truck: '',
      Unknown: '',
    }

    const filteredCarrierVisits = this.entries.filter(
      cv => cv.discriminator === carrierTypeMap[carrierType],
    )

    return filteredCarrierVisits.reduce((acc, cv) => {
      acc[cv.status]++
      return acc
    }, initialValue)
  }

  public sortActiveCarrierVisits(status: CarrierVisitStatus): CarrierVisitInfo[] {
    return (
      this.entries
        .filter(v => v.status === status)
        .map(vv => {
          return {
            ...vv,
            summary: this.carrierVisitSummaries.get(vv.id),
          }
        })
        // Sort by unplanned carrier visits
        .sort((a, b) => {
          const checkCondition = (item: any) =>
            item.summary?.unplannedLoad > 0 ||
            item.summary?.unplannedDischarge > 0 ||
            CarrierVisitStore.isDischargeAndLoadingEmpty(item.summary)

          const aPriority = checkCondition(a) ? 0 : 1
          const bPriority = checkCondition(b) ? 0 : 1

          return aPriority - bPriority
        })
    )
  }

  public mapToListItems(carrierVisits: CarrierVisitDto[]): CarrierVisitListItem[] {
    return carrierVisits.map(CarrierVisitListItemFactory)
  }

  public mapToOptions(entries: CarrierVisitDto[]): SelectOption[] {
    return entries.map(({ id, name }) => ({ label: name, value: id.toString() }))
  }

  public async load(id: number): Promise<CarrierVisitDto> {
    const { data: carrierVisit } = await this.carrierVisitApi.get({ id })

    this.addOrUpdate(carrierVisit)

    return carrierVisit
  }

  public async loadList(status?: CarrierVisitsApiGetAllRequest): Promise<void> {
    const { data: carrierVisits } = await this.carrierVisitApi.getAll(status)

    this.setAll(carrierVisits)
  }

  public getById(id: number): CarrierVisitDto | undefined {
    return this.data.get(id)
  }

  public getActiveCarrierVisits(): CarrierVisitDto[] {
    return this.entries.filter(
      v =>
        v.status === CarrierVisitStatus.Expected ||
        v.status === CarrierVisitStatus.InOperation ||
        v.status === CarrierVisitStatus.Arrived,
    )
  }

  public getArrivalTimeToString(arrival: any) {
    return new Date(arrival).toLocaleDateString('de', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      hour12: false,
      minute: '2-digit',
    })
  }

  async loadCarrierVisitSummary(id: number, visitDirection?: CarrierVisitDirection) {
    const { data: carrierVisitSummary } = await this.carrierVisitApi.getCarrierVisitSummary({
      id,
      visitDirection,
    })

    runInAction(() => {
      this.carrierVisitSummaries.set(id, carrierVisitSummary)
    })
  }

  async loadCarrierVisitSummaryV2(id: number, visitDirection?: CarrierVisitDirection) {
    const { data: carrierVisitSummary } = await this.carrierVisitApi.getCarrierVisitSummaryV2({
      id,
      visitDirection,
    })

    runInAction(() => {
      this.carrierVisitSummaries.set(id, carrierVisitSummary)
    })
  }

  loadCarrierVisitsIssues() {
    this._issueStore.loadByType('planning')
  }

  getCarrierVisitIssues(carrierVisitId: number) {
    return this._issueStore.issues.filter(i => i.context?.carrierVisitId === carrierVisitId)
  }

  /**
   * Transform issues into alerts
   * @param carrierVisitId
   * @returns Alert[]
   */
  getCarrierVisitAlerts(carrierVisitId: number): AppAlert[] {
    return AlertStore.getAlertsFromIssues(this.getCarrierVisitIssues(carrierVisitId))
  }

  getStandardUnallocatedBreakdownFromTurnovers(turnovers: ContainerTurnoverDto[]) {
    const standardTurnoversBreakdown: UnallocatedTurnoversBreakDown[] = []

    turnovers
      .filter(t => !t.isReefer && !t.isDangerous && !t.isOOG)
      .forEach(turnover => {
        const breakdown = standardTurnoversBreakdown.find(
          x => x.size === turnover.size && x.isEmpty === !!turnover.isEmpty,
        )
        if (breakdown) {
          breakdown.value.push(turnover)
        } else {
          standardTurnoversBreakdown.push({
            size: turnover.size,
            isEmpty: !!turnover.isEmpty,
            isDangerous: false,
            isOOG: false,
            isReefer: false,
            value: [turnover],
          })
        }
      })

    return this.orderUnallocatedTurnoversBreakdown(standardTurnoversBreakdown)
  }

  getSpecialUnallocatedBreakdownFromTurnovers(
    turnovers: ContainerTurnoverDto[],
  ): UnallocatedTurnoversBreakDown[] {
    const specialTurnoversBreakdown: UnallocatedTurnoversBreakDown[] = []

    turnovers
      .filter(t => t.isReefer || t.isDangerous || t.isOOG)
      .forEach(turnover => {
        const breakdown = specialTurnoversBreakdown.find(
          x =>
            x.size === turnover.size &&
            x.isEmpty === !!turnover.isEmpty &&
            x.isDangerous === !!turnover.isDangerous &&
            x.isOOG === !!turnover.isOOG &&
            x.isReefer === !!turnover.isReefer,
        )
        if (breakdown) {
          breakdown.value.push(turnover)
        } else {
          specialTurnoversBreakdown.push({
            size: turnover.size,
            isEmpty: !!turnover.isEmpty,
            isDangerous: !!turnover.isDangerous,
            isOOG: !!turnover.isOOG,
            isReefer: !!turnover.isReefer,
            value: [turnover],
          })
        }
      })

    return this.orderUnallocatedTurnoversBreakdown(specialTurnoversBreakdown)
  }

  private orderUnallocatedTurnoversBreakdown(turnoversBreakdown: UnallocatedTurnoversBreakDown[]) {
    return turnoversBreakdown
      .filter(t => t.value.length > 0)
      .sort((a, b) => b.value.length - a.value.length)
  }
}
