export interface CompanyFormProfile {
  id?: number
  name: string
  shortName: string
  companyTypes: string[]
  alternativeNames: string[]
  taxId?: string | null
  address?: string | null
  phoneNumber?: string | null
  email?: string | null
  remarks?: string | null
}

export const defaultValues: CompanyFormProfile = {
  name: '',
  shortName: '',
  companyTypes: [],
  alternativeNames: [''],
  taxId: '',
  address: '',
  phoneNumber: '',
  email: '',
  remarks: '',
}
