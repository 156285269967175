import { Drawer } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { observer } from 'mobx-react-lite'
import { JobAlertContent } from '../components/Alert/JobAlertContent'
import { WorkInstructionAlertContent } from '../components/Alert/WorkInstructionAlertContent'

export const EquipmentOperatorAlertContainer = observer(() => {
  const { equipmentOperatorUIStore, equipmentOperatorSearchUIStore } = useOperationsStore()

  const handleReturn = () => {
    equipmentOperatorSearchUIStore.setSelectedJobWorkInstructionIds()
    equipmentOperatorSearchUIStore.toggleSearch()
  }

  return (
    <Drawer
      open={equipmentOperatorSearchUIStore.isAlertOpen}
      anchor='right'
      PaperProps={{
        sx: {
          width: '100vw',
        },
      }}
    >
      {equipmentOperatorUIStore.isContainerOperation ? (
        <JobAlertContent handleReturn={handleReturn} />
      ) : (
        <WorkInstructionAlertContent handleReturn={handleReturn} />
      )}
    </Drawer>
  )
})
