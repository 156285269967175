import { Grid, Paper, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CustomsResponseDto, OrderResponseDto } from '@planning/app/api'
import { FeatureFlag } from '@planning/components/FeatureFlags'
import {
  ChangeTransportSubFormType,
  RegisteredChangeTransportSubForm,
} from '@planning/components/organisms/ChangeTransportSubForm'
import { CustomsSubForm } from '@planning/components/organisms/CustomsSubForm'
import { useTranslate } from '@tolgee/react'
import { FormField, useFormStore } from '@tom-ui/utils'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'

interface IProps {
  order?: OrderResponseDto
  yardLocationComponent: JSX.Element
  onSubmitFn: (formData: ICheckinContainerFormData) => void
}

export interface ICheckinContainerFormData {
  customs: CustomsResponseDto[]
  transport: ChangeTransportSubFormType
  arrivalTime: Dayjs
}

export const CheckinContainerForm = observer(
  ({ order, yardLocationComponent, onSubmitFn }: IProps) => {
    const { t } = useTranslate()

    const formStore = useFormStore<ICheckinContainerFormData>()

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      if (!formStore.validate()) {
        return
      }

      const formData = formStore.data
      onSubmitFn(formData)
    }

    return (
      <form onSubmit={onSubmit} id='check-container-form'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
              <Typography variant='subtitle1' sx={{ mb: '.5rem' }}>
                {t('containerArrivalDetails', 'Container arrival details')}
              </Typography>

              <Grid item sm={3}>
                <FormField formStore={formStore} name={'arrivalTime'} initialValue={dayjs()}>
                  {store => {
                    return (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label={t('arrivalTime', 'Arrival time')}
                          value={store.value}
                          onChange={newValue => (newValue != null ? store.setValue(newValue) : '')}
                          slotProps={{
                            textField: {
                              required: true,
                              variant: 'outlined',
                              helperText: store.error
                                ? t('fieldIsRequired', 'Field is required.')
                                : '',
                              error: !!store.error,
                              sx: { width: '100%', marginTop: '10px' },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )
                  }}
                </FormField>
              </Grid>

              <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
                <Typography variant='subtitle2' sx={{ mb: '1rem' }}>
                  {t('transportType', 'Transport Type')}
                </Typography>

                <RegisteredChangeTransportSubForm
                  name={'transport'}
                  formStore={formStore}
                  hideContainerReleasePrompt
                  initialValues={{
                    carrierType: order?.carrierVisitType,
                    carrierVisitId: order?.carrierVisitId ?? undefined,
                    railcarId: order?.railcarId ?? undefined,
                    railTrackId: order?.railTrackId ?? undefined,
                  }}
                />
              </Paper>

              <Grid item xs={12} mt={'1rem'}>
                <Typography mb={'1rem'} variant='subtitle1'>
                  {t('yardLocation', 'Yard location')}
                </Typography>
                {yardLocationComponent}
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <FeatureFlag name='customs-v-1'>
              <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
                <CustomsSubForm
                  name={'customs'}
                  formStore={formStore}
                  initialValue={order?.customs}
                  allowDelete={true}
                  showHeader={true}
                />
              </Paper>
            </FeatureFlag>
          </Grid>
        </Grid>
      </form>
    )
  },
)
