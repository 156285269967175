import {
  CarrierVisitStatus,
  ContainerJourneyResponseDto,
  ContainerResponseDto,
  OrderResponseDto,
  TruckVisitWithOrdersDto,
} from '@planning/app/api'
import { ContainerJourneyItemStore } from '@planning/pages/Issues/Stores/ContainerJourneyItemStore'
import { ContainerItemStore } from '@planning/rt-stores/container/ContainerItemStore'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { TenantStore } from '@planning/rt-stores/tenant/TenantStore'
import { ITruckVisitItem } from '@planning/rt-stores/truckVisit/TruckVisitItem'
import { TruckVisitItemStore } from '@planning/rt-stores/truckVisit/TruckVisitItemStore'
import { truckAppointmentService, truckVisitService } from '@planning/services'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export enum TruckVisitIssues {
  missingPosition,
  hold,
  overdue,
}
export enum TruckVisitStatus {
  expected,
  arrived,
  departed,
  cancelled,
}

export class TruckVisitsViewStore {
  isOverdueDialogOpen = false
  isTimeRangeDialogOpen = false
  isCalendarPopperOpen = false
  isAdvancedFilterOpen = false

  filter = ''
  selectedStatus: TruckVisitStatus = TruckVisitStatus.expected
  selectedIssues: TruckVisitIssues[] = []
  timeRangeStart?: Date
  timeRangeEnd?: Date
  selectedDate?: Date

  openPlanContainerPositionDialog?: (orderId: number) => void

  constructor(
    private readonly tenantStore: TenantStore,
    private readonly truckVisitItemStore: TruckVisitItemStore,
    private readonly orderItemStore: OrderItemStore,
    private readonly containerItemStore: ContainerItemStore,
    private readonly containerJourneyItemStore: ContainerJourneyItemStore,
  ) {
    makeObservable(this, {
      isTimeRangeDialogOpen: observable,
      isCalendarPopperOpen: observable,
      isOverdueDialogOpen: observable,
      isAdvancedFilterOpen: observable,
      timeRangeStart: observable,
      timeRangeEnd: observable,
      selectedDate: observable,
      selectedIssues: observable,
      filter: observable,
      selectedStatus: observable,

      isFiltering: computed,
      truckOverdue: computed,
      isTimeRangeSet: computed,
      truckVisitFilteredByStatus: computed,
      truckVisitItems: computed,
      needsReservation: computed,
      fromFilter: computed,
      toFilter: computed,
      totalForSelectedStatus: computed,
      totalPerStatus: computed,

      toggleOverdueDialog: action,
      toggleCalendarPopper: action,
      toggleTimeRangeDialog: action,
      toggleAdvancedFilter: action,
      setTimeRange: action,
      setIssues: action,
      setSelectedDate: action,
      clearTimeRange: action,
      setTruckVisitStatus: action,
    })
  }

  fetchVisits = async () => {
    const truckVisitsWithOrders = await truckVisitService.getWithOrders(
      this.fromFilter,
      this.toFilter,
    )

    this.upsertVisitsToItemStores(truckVisitsWithOrders)
  }

  deleteTruckAppointment = async (truckVisitId: number) => {
    await truckAppointmentService.delete(truckVisitId)
  }

  toggleOverdueDialog = (isOpen: boolean) => {
    this.isOverdueDialogOpen = isOpen
  }

  toggleTimeRangeDialog = (isOpen: boolean) => {
    this.isTimeRangeDialogOpen = isOpen
  }

  toggleCalendarPopper = (isOpen: boolean) => {
    this.isCalendarPopperOpen = isOpen
  }

  toggleAdvancedFilter = (isOpen: boolean) => {
    this.isAdvancedFilterOpen = isOpen
  }

  clearTimeRange = () => {
    this.timeRangeStart = undefined
    this.timeRangeEnd = undefined
  }

  setTimeRange = (start: Date, end: Date) => {
    this.timeRangeStart = start
    this.timeRangeEnd = end

    this.fetchVisits()
  }

  setFilter = (filter: string) => {
    if (this.filter !== filter) {
      this.filter = filter
    }
  }

  setIssues = (issues: TruckVisitIssues[]) => {
    this.selectedIssues = issues
  }

  setSelectedDate = (date?: Date) => {
    this.selectedDate = date

    this.fetchVisits()
  }

  setTruckVisitStatus(status: TruckVisitStatus) {
    if (this.selectedStatus !== status) {
      this.selectedStatus = status

      if (this.totalForSelectedStatus !== this.truckVisitFilteredByStatus.length) {
        this.fetchVisits()
      }
    }
  }

  resetFilters() {
    this.setIssues([])
    this.setSelectedDate()
    this.clearTimeRange()
    this.setFilter('')
  }

  async saveOverdueTime(overdueValue: number) {
    await this.tenantStore.update(overdueValue)
  }

  get totalPerStatus() {
    return {
      expected: this.getTotalForStatus(TruckVisitStatus.expected),
      arrived: this.getTotalForStatus(TruckVisitStatus.arrived),
      departed: this.getTotalForStatus(TruckVisitStatus.departed),
      cancelled: this.getTotalForStatus(TruckVisitStatus.cancelled),
    }
  }

  get totalForSelectedStatus() {
    switch (this.selectedStatus) {
      case TruckVisitStatus.expected:
        return this.totalPerStatus.expected
      case TruckVisitStatus.arrived:
        return this.totalPerStatus.arrived
      case TruckVisitStatus.departed:
        return this.totalPerStatus.departed
      case TruckVisitStatus.cancelled:
        return this.totalPerStatus.cancelled
      default:
        return 0
    }
  }

  get truckVisitItems() {
    return _.values(this.truckVisitItemStore.elements).filter(
      item => this.filterByDates(item) && this.filterByName(item) && this.filterByIssues(item),
    )
  }

  get truckVisitFilteredByStatus() {
    const isCancelled = this.selectedStatus === TruckVisitStatus.cancelled
    return this.truckVisitItems.filter(item =>
      this.filterByStatus(item, this.carrierVisitStatus, isCancelled),
    )
  }

  get needsReservation() {
    return !this.tenantStore.skipYardPlanning
  }

  get isFiltering() {
    return (
      !!this.filter || !!this.selectedIssues.length || !!this.selectedDate || this.isTimeRangeSet
    )
  }

  get truckOverdue() {
    return this.tenantStore.tenant?.truckVisitOverdueTime
  }

  get isTimeRangeSet() {
    return this.timeRangeStart && this.timeRangeEnd
  }

  get fromFilter() {
    const from = this.selectedDate ? new Date(this.selectedDate) : new Date()
    const hours = this.timeRangeStart?.getHours() ?? 0
    const minutes = this.timeRangeStart?.getMinutes() ?? 0

    from.setHours(hours, minutes, 0)

    return from
  }

  get toFilter() {
    const to = this.selectedDate ? new Date(this.selectedDate) : new Date()
    const hours = this.timeRangeEnd?.getHours() ?? 0
    const minutes = this.timeRangeEnd?.getMinutes() ?? 0

    if (hours === 0 && minutes === 0) {
      to.setDate(to.getDate() + 1)
    }
    to.setHours(hours, minutes, 0)

    return to
  }

  get carrierVisitStatus(): CarrierVisitStatus[] {
    return this.convertTruckVisitStatusToCarrierVisitStatus(this.selectedStatus)
  }

  private filterByStatus = (
    truckVisit: ITruckVisitItem,
    status: CarrierVisitStatus[],
    onlyCancelledVisits?: boolean,
  ) => {
    if (onlyCancelledVisits) {
      return truckVisit.data.isCancelled
    }

    return !truckVisit.data.isCancelled && status.includes(truckVisit.data.status)
  }

  private filterByName = (truckVisit: ITruckVisitItem) => {
    return (
      !this.filter ||
      truckVisit.data.identifier?.toLowerCase()?.includes(this.filter.toLowerCase()) ||
      truckVisit.data.driverName?.toLowerCase()?.includes(this.filter.toLowerCase())
    )
  }

  private filterByIssues = (truckVisit: ITruckVisitItem) => {
    if (!this.selectedIssues.length) return true

    const hasMissingPosition =
      !this.selectedIssues.includes(TruckVisitIssues.missingPosition) ||
      (truckVisit.orders.some(o => o.hasMissingPosition) &&
        truckVisit.data.status === CarrierVisitStatus.Expected)

    const hasActiveHold =
      !this.selectedIssues.includes(TruckVisitIssues.hold) ||
      (truckVisit.orders.some(o => o.hasActiveHold) &&
        truckVisit.data.status === CarrierVisitStatus.Expected)

    const hasOverdue =
      !this.selectedIssues.includes(TruckVisitIssues.overdue) ||
      (!this.isOverdueTime(truckVisit.data.eta) &&
        (truckVisit.data.status === CarrierVisitStatus.Arrived ||
          truckVisit.data.status === CarrierVisitStatus.InOperation))

    return hasMissingPosition && hasActiveHold && hasOverdue
  }

  private filterByDates = (truckVisit: ITruckVisitItem) => {
    const expectedDates =
      truckVisit.data.status === CarrierVisitStatus.Expected &&
      (this.isDateWithinSelectedRange(truckVisit.data.eta) ||
        this.isDateWithinSelectedRange(truckVisit.data.etd) ||
        this.isDateWithinRange(
          truckVisit.data.eta,
          truckVisit.data.etd,
          this.fromFilter.toUTCString(),
        ))

    const arrivedDates =
      (truckVisit.data.status === CarrierVisitStatus.Arrived ||
        truckVisit.data.status === CarrierVisitStatus.InOperation) &&
      this.isDateWithinSelectedRange(truckVisit.data.ata)

    const departedDates =
      (truckVisit.data.status === CarrierVisitStatus.Departed ||
        truckVisit.data.status === CarrierVisitStatus.Completed) &&
      this.isDateWithinSelectedRange(truckVisit.data.atd)

    return expectedDates || arrivedDates || departedDates
  }

  private upsertVisitsToItemStores = (truckVisitsWithOrders: TruckVisitWithOrdersDto[]) => {
    const orders = truckVisitsWithOrders
      .flatMap(x => x.inboundOrders)
      .concat(truckVisitsWithOrders.flatMap(x => x.outboundOrders))
      .filter(x => !!x) as OrderResponseDto[]
    this.orderItemStore.upsertBulk(orders)

    const containers = truckVisitsWithOrders
      .flatMap(x => x.containers)
      .filter(x => !!x) as ContainerResponseDto[]
    this.containerItemStore.upsertBulk(containers)

    const containerJourneys = truckVisitsWithOrders
      .flatMap(x => x.containerJourneys)
      .filter(x => !!x) as ContainerJourneyResponseDto[]
    this.containerJourneyItemStore.upsertBulk(containerJourneys)

    const truckVisits = truckVisitsWithOrders.map(x => ({
      id: x.id!,
      status: x.status ?? CarrierVisitStatus.Expected,
      carrierIds: [],
      cargoType: x.cargoType ?? 'Unknown',
      driverName: x.driver?.name,
      isCancelled: x.isCancelled,
      eta: x.eta ?? undefined,
      ata: x.ata ?? undefined,
      etd: x.etd ?? undefined,
      atd: x.atd ?? undefined,
      identifier: x.identifier,
    }))
    this.truckVisitItemStore.upsertBulk(truckVisits)
  }

  private isDateWithinSelectedRange = (date?: string | null) => {
    return this.isDateWithinRange(this.fromFilter.toUTCString(), this.toFilter.toUTCString(), date)
  }

  private isDateWithinRange = (min?: string | null, max?: string | null, date?: string | null) => {
    if (!date || !min || !max) return false

    const dateObj = new Date(date)
    return dateObj >= new Date(min) && dateObj <= new Date(max)
  }

  private isOverdueTime = (eta?: string | null) => {
    if (!this.truckOverdue || !eta) return false

    const etaDate = new Date(eta)
    const now = new Date()
    const diff = Math.abs(now.getTime() - etaDate.getTime())
    const minutes = Math.floor(diff / 1000 / 60)

    return minutes > this.truckOverdue
  }

  private getTotalForStatus(status: TruckVisitStatus) {
    const visitStatus = this.convertTruckVisitStatusToCarrierVisitStatus(status)
    const isCancelled = status === TruckVisitStatus.cancelled

    return this.truckVisitItems.reduce((acc, item) => {
      if (this.filterByStatus(item, visitStatus, isCancelled)) {
        return acc + 1
      }

      return acc
    }, 0)
  }

  private convertTruckVisitStatusToCarrierVisitStatus(
    status: TruckVisitStatus,
  ): CarrierVisitStatus[] {
    switch (status) {
      case TruckVisitStatus.arrived:
        return [CarrierVisitStatus.Arrived, CarrierVisitStatus.InOperation]
      case TruckVisitStatus.departed:
        return [CarrierVisitStatus.Departed, CarrierVisitStatus.Completed]
      case TruckVisitStatus.cancelled:
      case TruckVisitStatus.expected:
      default:
        return [CarrierVisitStatus.Expected]
    }
  }
}
