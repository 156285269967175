import { Dialog } from '@mui/material'
import { FormType } from '@storage/app/models'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { GlobalAllocationRulesUIStore } from '@storage/pages/global-allocation-rules/stores/global-allocation-rules.ui-store'
import { AllocationRulePanelFormDestination } from '@storage/pages/yard-planning-dashboard-details/components/allocation-rule-panel/AllocationRulePanelFormDestination'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import {
  mapAllocationRuleTemplateDtoToFormValues,
  mapFormValuesToAllocationRuleTemplateCreateRequest,
  mapFormValuesToAllocationRuleTemplateUpdateRequest,
} from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import {
  AllocationRuleTemplateFormProfile,
  defaultDestinationAndStackingStrategy,
  defaultValues,
} from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'
import { schema } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.scheme'
import { AllocationRuleTemplatesForm } from '../forms/allocation-rule-templates-form/AllocationRuleTemplatesForm'
import { CarrierVisitAllocationRulesV2UIStore } from '../stores/carrier-visit-allocation-rules-v2.ui-store'

interface AllocationRuleTemplatesTableDialogProps {
  destinationUIStore: CarrierVisitAllocationRulesV2UIStore | GlobalAllocationRulesUIStore
}
const AllocationRuleTemplatesTableDialog = observer(
  ({ destinationUIStore }: AllocationRuleTemplatesTableDialogProps) => {
    const { t } = useTranslate()
    const [destinationFieldIndex, setDestinationFieldIndex] = useState(0)
    const {
      allocationRuleTemplatesV2UIStore,
      allocationRuleTemplatesV2Store,
      snackbarStore,
      weightClassContainerUIStore,
      yardBlockStore,
      yardBlockBayStore,
      yardBlockRowStore,
      companyStore,
      carrierVisitAllocationRulesV2UIStore,
      manualInputValidationStore,
    } = useStores()

    const dialogUtilStore = allocationRuleTemplatesV2UIStore.dialogUtilStore

    const formId = 'allocation-rules-form'
    const saveLabel = t('save', 'Save')
    const cancelLabel = t('cancel', 'Cancel')
    const deletionConfirmationMessage = t(
      'allocationRuleTemplateWillBeDeleted',
      'This allocation rule template will be deleted and you cannot undo this action!',
    )

    const existingAllocationRuleTemplate = dialogUtilStore.dialogEntityId
      ? allocationRuleTemplatesV2Store.getById(dialogUtilStore.dialogEntityId)
      : undefined

    const allocationRuleTemplateFormType = dialogUtilStore.dialogAction
    const allocationRuleTemplateForm = useFormWithSchema<AllocationRuleTemplateFormProfile>(
      schema(true),
      defaultValues,
    )

    useEffect(() => {
      if (allocationRuleTemplateFormType === 'Edit' && existingAllocationRuleTemplate) {
        allocationRuleTemplateForm.reset(
          mapAllocationRuleTemplateDtoToFormValues(existingAllocationRuleTemplate),
        )
      }
      if (allocationRuleTemplateFormType === 'Add') {
        allocationRuleTemplateForm.reset(defaultValues)
      }
    }, [allocationRuleTemplateForm, allocationRuleTemplateFormType, existingAllocationRuleTemplate])

    const { fields, append, remove, swap } = useFieldArray({
      control: allocationRuleTemplateForm.control,
      name: 'destinationAndStackingStrategies',
    })

    const destinationAndStackingStrategiesFormValue = useWatch({
      control: allocationRuleTemplateForm.control,
      name: `destinationAndStackingStrategies`,
    })

    useEffect(() => {
      const loadBlocks = async () => {
        await yardBlockStore.loadList()
      }

      const loadBays = async () => {
        await yardBlockBayStore.loadList()
      }
      const loadRows = async () => {
        await yardBlockRowStore.loadAll()
      }

      loadBlocks()
      loadBays()
      loadRows()
    }, [yardBlockBayStore, yardBlockRowStore, yardBlockStore])

    const getDialogLabels = (action: FormType) => {
      switch (action) {
        case 'Add':
          return {
            title: t('addNewAllocationRuleTemplate', 'Add New Allocation Rule Template'),
            buttons: { primary: saveLabel, secondary: cancelLabel },
          }
        case 'Edit':
          return {
            title: t('updateAllocationRuleTemplate', 'Edit Allocation Rule Template'),
            buttons: { primary: saveLabel, secondary: cancelLabel },
          }
        case 'Delete':
          return {
            title: t('deleteAllocationRuleTemplate', 'Delete Allocation Rule Template?'),
            buttons: { primary: t('delete', 'Delete'), secondary: cancelLabel },
          }
      }
    }

    const dialogLabels = getDialogLabels(dialogUtilStore.dialogAction)

    const handleOnClose = () => dialogUtilStore.toggleDialog()

    const handleOnAddDestination = () => {
      carrierVisitAllocationRulesV2UIStore.setShowDestinationFormInTemplate(true)
      append(defaultDestinationAndStackingStrategy)
      setDestinationFieldIndex(fields.length)
    }

    const handleEditDestination = (id: number) => {
      setDestinationFieldIndex(id)
      carrierVisitAllocationRulesV2UIStore.setShowDestinationFormInTemplate(true)
    }

    const handleDeleteDestination = (id: number) => {
      remove(id)
    }

    const handleReorderDestination = (startIndex: number, endIndex: number) => {
      swap(startIndex, endIndex)
    }

    const handleOnSubmit = (formValues: any) => {
      destinationAndStackingStrategiesFormValue.forEach((destination, index) => {
        destination.position = index + 1
      })
      formValues.destinationAndStackingStrategies = destinationAndStackingStrategiesFormValue

      if (dialogUtilStore.dialogAction === 'Add') {
        allocationRuleTemplatesV2Store
          .add(mapFormValuesToAllocationRuleTemplateCreateRequest(formValues))
          .then(() =>
            snackbarStore.showMessage(
              t(
                'allocationRuleTemplateCreationSuccess',
                'The allocation rule template is successfully created',
              ),
              'success',
            ),
          )
          .catch(() =>
            snackbarStore.showMessage(
              t(
                'allocationRuleTemplateCreationFailure',
                'An unexpected error occurred while creating the allocation rule template',
              ),
              'error',
            ),
          )
          .finally(() => handleOnClose())
      } else if (dialogUtilStore.dialogAction === 'Edit') {
        allocationRuleTemplatesV2Store
          .update(mapFormValuesToAllocationRuleTemplateUpdateRequest(formValues))
          .then(() =>
            snackbarStore.showMessage(
              t(
                'allocationRuleTemplateUpdateSuccess',
                'The allocation rule template is successfully updated',
              ),
              'success',
            ),
          )
          .catch(() =>
            snackbarStore.showMessage(
              t(
                'allocationRuleTemplateUpdateFailure',
                'An unexpected error occurred while updating the allocation rule template',
              ),
              'error',
            ),
          )
          .finally(() => handleOnClose())
      }
    }

    const handleOnDelete = () => {
      allocationRuleTemplatesV2Store
        .delete(dialogUtilStore.dialogEntityId!)
        .then(() =>
          snackbarStore.showMessage(
            t(
              'allocationRuleTemplateDeletionSuccess',
              'The allocation rule template is successfully deleted',
            ),
            'success',
          ),
        )
        .catch(() =>
          snackbarStore.showMessage(
            t(
              'allocationRuleTemplateDeletionFailure',
              'An unexpected error occurred while deleting the allocation rule template',
            ),
            'error',
          ),
        )
        .finally(() => handleOnClose())
    }

    return (
      <>
        <ConfirmationDialog
          open={dialogUtilStore.isDialogOpen}
          title={dialogLabels.title}
          primaryActionText={dialogLabels.buttons.primary}
          closeLabel={dialogLabels.buttons.secondary}
          formId={formId}
          maxWidth='sm'
          onClose={handleOnClose}
          hasBorderTop={dialogUtilStore.dialogAction !== 'Delete'}
          blockClickOutside={dialogUtilStore.dialogAction === 'Delete'}
          message={
            dialogUtilStore.dialogAction === 'Delete' ? deletionConfirmationMessage : undefined
          }
          onConfirm={dialogUtilStore.dialogAction === 'Delete' ? handleOnDelete : undefined}
        >
          {dialogUtilStore.dialogAction !== 'Delete' && (
            <AllocationRuleTemplatesForm
              id={formId}
              allocationRuleTemplateForm={allocationRuleTemplateForm}
              onSubmit={handleOnSubmit}
              isATemplate
              weightClassUIStore={weightClassContainerUIStore}
              manualInputValidationStore={manualInputValidationStore}
              companyStore={companyStore}
              onAddDestination={handleOnAddDestination}
              onDeleteDestination={handleDeleteDestination}
              onEditDestination={handleEditDestination}
              onReOrderDestination={handleReorderDestination}
              destinationAndStackingStrategy={destinationAndStackingStrategiesFormValue || []}
            />
          )}
        </ConfirmationDialog>
        <Dialog
          open={carrierVisitAllocationRulesV2UIStore.showDestinationFormInTemplate}
          title={''}
          onClose={() =>
            carrierVisitAllocationRulesV2UIStore.setShowDestinationFormInTemplate(false)
          }
          fullWidth
        >
          <AllocationRulePanelFormDestination
            uiStore={destinationUIStore}
            fieldIndex={destinationFieldIndex}
            allocationRuleForm={allocationRuleTemplateForm}
            goBack={() =>
              carrierVisitAllocationRulesV2UIStore.setShowDestinationFormInTemplate(false)
            }
            destinationAndStackingStrategiesFormValue={
              destinationAndStackingStrategiesFormValue || []
            }
          />
        </Dialog>
      </>
    )
  },
)

export default AllocationRuleTemplatesTableDialog
