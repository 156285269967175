import { CarrierVisitDirection, OrderResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { BreadcrumpItem } from 'modules/ui/src/theme/components/breadcrumbs/Breadcrumb'
import { useNavigate } from 'react-router'

export const useOrderBreadcrumbs = () => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const { vesselVisitItemStore, railVisitItemStore } = usePlanningStore()

  const bookingsLabel = t('bookings', 'Bookings')
  const containerVisitsLabel = t('containerVisits', 'Container visits')
  const orderDetailsLabel = t('orderDetails', 'Order details')
  const visitsLabel = t('visits', 'Visits')
  const checkoutContainerLabel = t('checkoutContainer', 'Checkout container')
  const checkinContainerLabel = t('checkinContainer', 'Checkin container')

  const visitsUrl = '/visits'
  const bookingsUrl = '/bookings'
  const containerVisitsUrl = '/container-visits'

  const getContainerNumberLabel = (order: OrderResponseDto) =>
    order.containerNumber ? `<${order.containerNumber}>` : ''

  const getDirectionLabel = (direction: string) =>
    direction === CarrierVisitDirection.Inbound
      ? t('dischargeList', 'Discharge list')
      : t('loadList', 'Load list')

  const getDirectionPageName = (direction: string) =>
    direction === CarrierVisitDirection.Inbound ? 'discharge' : 'load'

  const getCarrierVisitUrl = (basePath: string, carrierVisitId: string, page: string) =>
    `/${basePath}/${carrierVisitId}/${page}`

  const getVisitBreadcrumbs = (
    order: OrderResponseDto,
    carrierVisitLabel: string,
    basePath: string,
    lastLabel: string,
  ): BreadcrumpItem[] => {
    if (!order.carrierVisitId) return []

    const containerNumberLabel = getContainerNumberLabel(order)
    const directionLabel = getDirectionLabel(order.direction)

    const carrierVisitDashboardUrl = getCarrierVisitUrl(
      basePath,
      `${order.carrierVisitId}`,
      'dashboard',
    )

    const carrierVisitOrderListUrl = getCarrierVisitUrl(
      basePath,
      `${order.carrierVisitId}`,
      getDirectionPageName(order.direction),
    )

    const breadcrumbs: BreadcrumpItem[] = [
      {
        label: visitsLabel,
        onClick: () => navigate(visitsUrl),
      },
      {
        label: carrierVisitLabel,
        onClick: () => navigate(carrierVisitDashboardUrl),
      },
      {
        label: directionLabel,
        onClick: () => navigate(carrierVisitOrderListUrl),
      },
      {
        label: `${lastLabel} ${containerNumberLabel}`,
      },
    ]

    return breadcrumbs
  }

  const getBookingBreadcrumbs = (order: OrderResponseDto): BreadcrumpItem[] => {
    const containerNumberLabel = getContainerNumberLabel(order)
    const bookingNumberLabel = `${order.referenceNumber}`

    const bookingNumberUrl = `${bookingsUrl}/${order.referenceNumber}/visit/${order.carrierVisitId}/orders`

    const breadcrumbs: BreadcrumpItem[] = [
      {
        label: bookingsLabel,
        onClick: () => navigate(bookingsUrl),
      },
      {
        label: bookingNumberLabel,
        onClick: () => navigate(bookingNumberUrl),
      },
      {
        label: `${orderDetailsLabel} ${containerNumberLabel}`,
      },
    ]

    return breadcrumbs
  }

  const getContainerVisitBreadcrumbs = (label: string): BreadcrumpItem[] => {
    const breadcrumbs: BreadcrumpItem[] = [
      {
        label: containerVisitsLabel,
        onClick: () => navigate(containerVisitsUrl),
      },
      {
        label,
      },
    ]

    return breadcrumbs
  }

  const getVesselVisitBreadcrumbs = (order: OrderResponseDto, label: string): BreadcrumpItem[] => {
    if (!order.carrierVisitId) return []

    const vesselVisitItem = vesselVisitItemStore.elements[order.carrierVisitId]
    const vessel = vesselVisitItem?.vessels?.[0]?.data

    if (!vessel) return []

    const carrierVisitLabel = `${vessel.name} <${vessel.shippingLine}>`

    return getVisitBreadcrumbs(order, carrierVisitLabel, 'vessel-visits', label)
  }

  const getRailVisitBreadcrumbs = (order: OrderResponseDto, label: string): BreadcrumpItem[] => {
    if (!order.carrierVisitId) return []

    const raillVisitItem = railVisitItemStore.elements[order.carrierVisitId]

    const carrierVisitLabel = raillVisitItem?.data?.name || t('unknownTrain', 'Unknown train')

    return getVisitBreadcrumbs(order, carrierVisitLabel, 'rail-visits', label)
  }

  const getContainerVisitCheckContainerBreadcrumbs = (
    order: OrderResponseDto,
    checkType: 'checkin' | 'checkout',
  ): BreadcrumpItem[] => {
    const containerNumberLabel = getContainerNumberLabel(order)
    const checkTypeLabel = checkType === 'checkin' ? checkinContainerLabel : checkoutContainerLabel
    const label = `${checkTypeLabel} ${containerNumberLabel}`
    return getContainerVisitBreadcrumbs(label)
  }

  const getVesselVisitOrderDetailsBreadcrumbs = (order: OrderResponseDto): BreadcrumpItem[] =>
    getVesselVisitBreadcrumbs(order, orderDetailsLabel)

  const getRailVisitOrderDetailsBreadcrumbs = (order: OrderResponseDto): BreadcrumpItem[] =>
    getRailVisitBreadcrumbs(order, orderDetailsLabel)

  const getVesselVisitCheckinContainerBreadcrumbs = (order: OrderResponseDto): BreadcrumpItem[] =>
    getVesselVisitBreadcrumbs(order, checkinContainerLabel)

  const getRailVisitCheckinContainerBreadcrumbs = (order: OrderResponseDto): BreadcrumpItem[] =>
    getRailVisitBreadcrumbs(order, checkinContainerLabel)

  const getVesselVisitCheckoutContainerBreadcrumbs = (order: OrderResponseDto): BreadcrumpItem[] =>
    getVesselVisitBreadcrumbs(order, checkoutContainerLabel)

  const getRailVisitCheckoutContainerBreadcrumbs = (order: OrderResponseDto): BreadcrumpItem[] =>
    getRailVisitBreadcrumbs(order, checkoutContainerLabel)

  const getContainerVisitCheckinContainerBreadcrumbs = (
    order: OrderResponseDto,
  ): BreadcrumpItem[] => getContainerVisitCheckContainerBreadcrumbs(order, 'checkin')

  const getContainerVisitCheckoutContainerBreadcrumbs = (
    order: OrderResponseDto,
  ): BreadcrumpItem[] => getContainerVisitCheckContainerBreadcrumbs(order, 'checkout')

  return {
    getBookingBreadcrumbs,

    getVesselVisitOrderDetailsBreadcrumbs,
    getVesselVisitCheckinContainerBreadcrumbs,
    getVesselVisitCheckoutContainerBreadcrumbs,

    getRailVisitOrderDetailsBreadcrumbs,
    getRailVisitCheckinContainerBreadcrumbs,
    getRailVisitCheckoutContainerBreadcrumbs,

    getContainerVisitCheckoutContainerBreadcrumbs,
    getContainerVisitCheckinContainerBreadcrumbs,
  }
}
