import { CreateVesselCommand, UpdateVesselCommand, VesselDto } from '@planning/app/api'
import { VesselItemStore } from '@planning/rt-stores/vessel/VesselItemStore'
import vesselService from '@planning/services/vesselService'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { VesselFormData } from '../components/Molecules/VesselForm'

export class VesselViewStore {
  isDialogOpen = false
  dataToBeEdited?: VesselDto = undefined
  filter = ''

  constructor(private vesselItemStore: VesselItemStore) {
    makeObservable(this, {
      isDialogOpen: observable,
      dataToBeEdited: observable,
      filter: observable,

      isFiltering: computed,
      items: computed,
      vesselItems: computed,

      toggleDialog: action,
      setFilter: action,
      onDelete: action,
      onSubmit: action,
    })
  }

  toggleDialog = (shouldOpen: boolean, data?: VesselDto) => {
    this.isDialogOpen = shouldOpen
    this.dataToBeEdited = data
  }

  setFilter(filter: string) {
    this.filter = filter
  }

  onDelete = async (id: number) => {
    await vesselService.delete(id)
    this.toggleDialog(false)
  }

  onSubmit = async (data: VesselFormData) => {
    const isEditMode = !!data.id

    if (isEditMode) {
      const cmd: UpdateVesselCommand = {
        ...data,
        id: data.id!,
      }
      await vesselService.put(cmd)
    } else {
      const cmd: CreateVesselCommand = {
        ...data,
      }
      await vesselService.post(cmd)
    }
  }

  get isFiltering() {
    return this.filter.length > 0
  }

  get items() {
    return this.vesselItems.filter(
      i =>
        i.data.name.toLowerCase().includes(this.filter.toLowerCase()) ||
        i.data.shortName?.toLowerCase().includes(this.filter.toLowerCase()),
    )
  }

  get vesselItems() {
    return _.values(this.vesselItemStore.elements)
  }
}
