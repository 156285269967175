import {
  CreateEquipmentCommand,
  EquipmentDto,
  EquipmentType,
  UpdateEquipmentCommand,
  YardBlockAssignmentDto,
} from '@operations/app/api'
import { EquipmentFormProfile } from './equipment-form.profile'

const mapFormValuesToCreateDto = ({
  name,
  description,
  equipmentType,
  isOnMaintenance,
  maxStackingHeight,
  maxLiftWeight,
  yardBlockAssignments,
}: EquipmentFormProfile): CreateEquipmentCommand => ({
  name,
  description,
  equipmentType: equipmentType as EquipmentType,
  isOnMaintenance,
  yardBlockAssignments: yardBlockAssignments ?? [],
  maxStackingHeight,
  maxLiftWeight,
})

const mapFormValuesToUpdateDto = ({
  id,
  name,
  description,
  isOnMaintenance,
  maxStackingHeight,
  maxLiftWeight,
  yardBlockAssignments,
}: EquipmentFormProfile): UpdateEquipmentCommand => ({
  id: id!,
  name,
  description,
  isOnMaintenance,
  yardBlockAssignments: yardBlockAssignments ?? [],
  maxStackingHeight,
  maxLiftWeight,
})

const mapDtoToFormValues = ({
  id,
  name,
  description,
  equipmentType,
  isOnMaintenance,
  maxStackingHeight,
  maxLiftWeight,
  workAreaEquipments,
}: EquipmentDto): EquipmentFormProfile => ({
  id,
  name,
  description: description ?? '',
  equipmentType,
  isOnMaintenance,
  maxStackingHeight,
  maxLiftWeight,
  yardBlockAssignments:
    workAreaEquipments
      ?.filter(x => x.yardBlockId)
      ?.map(
        wa =>
          ({
            yardBlockId: wa.yardBlockId,
            bays: wa.assignedBays,
          }) as YardBlockAssignmentDto,
      ) ?? [],
})

export const EquipmentFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
