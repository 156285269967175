import {
  CreateVesselServiceCommand,
  UpdateVesselServiceCommand,
  VesselServicesApi,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class VesselServiceService {
  httpClient = createApiClient(VesselServicesApi)

  get = async () => {
    const { data } = await this.httpClient.get()

    return data
  }

  create = async (cmd: CreateVesselServiceCommand) => {
    await this.httpClient.create(cmd)
  }

  update = async (cmd: UpdateVesselServiceCommand) => {
    await this.httpClient.update(cmd)
  }

  delete = async (id: number) => {
    await this.httpClient._delete(id)
  }
}

const vesselServiceService = new VesselServiceService()

export default vesselServiceService
