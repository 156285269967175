import { Tooltip, Typography } from '@mui/material'
import { ContainerHeight, ContainerResponseDto, ContainerType } from '@planning/app/api'
import { useUnitTypeIcon, useUnitTypeLabel } from '@planning/components/UnitType'
import { useTranslate } from '@tolgee/react'
import { useContainerAttributesParameters } from '@tom-ui/ui'
import { SummaryCard } from './Organisms/SummaryCard'

interface IProps {
  noContainerNumberText?: string
  container?: ContainersCriteria
  showArchetypeAttributes?: boolean
}

export interface ContainersCriteria extends ContainerResponseDto {
  archetype?: string | null
  archetypeTypes?: ContainerType[] | null
  archetypeLengths?: number[] | null
  archetypeHeights?: ContainerHeight[] | null
}

export const ContainerSummary = ({
  container,
  noContainerNumberText,
  showArchetypeAttributes = false,
}: IProps) => {
  const { t } = useTranslate()
  const unitTypeIcon = useUnitTypeIcon(container?.unitType)
  const unitTypeLabel = useUnitTypeLabel(container?.unitType)
  const { types: containerTypeParameters, heights: containerHeightParameters } =
    useContainerAttributesParameters()

  if (!container) return <></>

  const attributes = [
    {
      key: 'length',
      label: t('length', 'Length'),
      value: showArchetypeAttributes
        ? container.archetypeLengths?.map(l => `${l}ft`).join(', ')
        : `${container.length}ft`,
    },
    {
      key: 'type',
      label: t('type', 'Type'),
      value: showArchetypeAttributes
        ? container.archetypeTypes?.map(t => containerTypeParameters[t].label).join(', ')
        : containerTypeParameters[container.type].label,
    },
    {
      key: 'height',
      label: t('height', 'Height'),
      value: showArchetypeAttributes
        ? container.archetypeHeights?.map(h => containerHeightParameters[h].label).join(', ')
        : containerHeightParameters[container.height]?.label,
    },
  ]

  if (showArchetypeAttributes) {
    attributes.unshift({
      key: 'archetype',
      label: t('archetype', 'Archetype'),
      value: container.archetype ?? '',
    })
  } else {
    attributes.unshift({
      key: 'isocode',
      label: t('isoCode', 'ISO Code'),
      value: container.isoCode ?? '',
    })
  }

  return (
    <SummaryCard
      title={
        <>
          <Tooltip title={unitTypeLabel} placement='bottom'>
            {unitTypeIcon}
          </Tooltip>
          <Typography variant='h4'>
            {container.number ?? noContainerNumberText ?? t('toBeAssigned', 'To be assigned')}
          </Typography>
        </>
      }
      attributes={attributes}
    />
  )
}
