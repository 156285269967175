import { Box } from '@mui/material'
import { ContainerTurnoverDto } from '@storage/app/api'
import {
  Multi2Icon,
  Multi3Icon,
  Multi4Icon,
  Multi5Icon,
  Multi6Icon,
  Multi7Icon,
  Multi8Icon,
  Multi9Icon,
  Multi9PlusIcon,
} from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

interface Props {
  occupancies?: ContainerTurnoverDto[]
}
export const MultipleStackingIcon = observer(({ occupancies }: Props) => {
  const occupancyCount = useMemo(() => {
    if (occupancies?.every(occ => occ.isFlatRack && occ.isEmpty)) return 1

    return occupancies?.length ?? 0
  }, [occupancies])

  if (occupancyCount <= 1) return <Box></Box>

  if (occupancyCount === 2) return <Multi2Icon />
  if (occupancyCount === 3) return <Multi3Icon />
  if (occupancyCount === 4) return <Multi4Icon />
  if (occupancyCount === 5) return <Multi5Icon />
  if (occupancyCount === 6) return <Multi6Icon />
  if (occupancyCount === 7) return <Multi7Icon />
  if (occupancyCount === 8) return <Multi8Icon />
  if (occupancyCount === 9) return <Multi9Icon />

  return <Multi9PlusIcon />
})
