import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { Header, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { ArchetypeUIStore } from '../../stores/archetype.ui-store'

interface IProps {
  viewStore: ArchetypeUIStore
}

export const ArchetypeHeader: FC<IProps> = observer(({ viewStore }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigate = useNavigate()

  return (
    <Header
      title={t('archetypes', 'Archetypes')}
      searchInputLabel={t('searchForArchetype', 'Search for an archetype')}
      onSearchFieldChange={filter => viewStore.setFilter(filter)}
      displaySearchIcon
      enableSearchField
      rightRenderOption={
        <Stack flexDirection='row' gap={theme.customSpacing.xs}>
          <Button
            color='inherit'
            variant='contained'
            sx={{ flex: 'none' }}
            startIcon={<PlusIcon />}
            onClick={() => navigate('new')}
          >
            {t('addNew', 'Add new')}
          </Button>
        </Stack>
      }
    />
  )
})
