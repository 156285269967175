import { Autocomplete, TextField } from '@mui/material'
import { VesselServiceResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

interface IProps {
  label?: string
  value?: number | null
  hasError?: boolean
  onChange: (vesselId?: number) => void
}

const filterOptions = (
  options: VesselServiceResponseDto[],
  { inputValue }: { inputValue: string },
) => {
  return options.filter(option => {
    const nameCondition = option.name.toLowerCase().includes(inputValue.toLowerCase())
    return nameCondition
  })
}

export const VesselServiceAutocomplete = observer(
  ({ hasError, label, value, onChange }: IProps) => {
    const { t } = useTranslate()

    const { vesselServiceItemStore } = usePlanningStore()

    useEffect(() => {
      vesselServiceItemStore.fetch()
    }, [vesselServiceItemStore])

    const options = computed(() =>
      _.values(vesselServiceItemStore.elements).map(vesselService => vesselService.data),
    ).get()

    return (
      <>
        <Autocomplete
          disablePortal
          getOptionLabel={vesselService => vesselService?.name}
          options={options}
          filterOptions={filterOptions}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          value={options.find(x => x?.id === value) ?? null}
          onChange={(_, vesselService) => {
            onChange(vesselService?.id)
          }}
          renderInput={params => (
            <TextField
              {...params}
              data-cy='vessel-service-autocomplete'
              label={label ?? t('service', 'Service')}
              error={hasError}
              helperText={hasError ? t('fieldIsRequired', 'Field is required') : undefined}
            />
          )}
        />
      </>
    )
  },
)
