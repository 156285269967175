import { ChevronLeft } from '@mui/icons-material'
import { Divider, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { CarrierType, CompanyDto, WeightClassDto } from '@storage/app/api'
import { AllStringValue, UnknownNumberValue, UnknownStringValue } from '@storage/app/models'
import { UnallocatedTurnoversBreakDown } from '@storage/stores/carrier-visit.store'
import { useTranslate } from '@tolgee/react'
import { IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import {
  ContainerTurnoverGroup,
  ContainerTurnoverGroupWithBreakdown,
  UnAllocatedFilter,
  UnAllocatedLayer,
} from '../interfaces'
import { getContainerTurnoverGroups } from '../utils/yard-planning-unallocated.util'
import { TurnoverBreakdownLabel } from './UnAllocatedBreakdownLabel'
import { UnAllocatedItem } from './UnAllocatedItem'

interface Props {
  layer: UnAllocatedLayer
  weightClasses: WeightClassDto[]
  companies: CompanyDto[]
  filter: UnAllocatedFilter
  changeFilter: (newFilter: UnAllocatedFilter) => void
  onExpand?: (
    breakdownItem: UnallocatedTurnoversBreakDown,
    previousFilter?: UnAllocatedFilter,
  ) => void
  onCreateNewRule: (breakdownItem: UnallocatedTurnoversBreakDown) => void
  onReturn: () => void
}

export const UnAllocatedItems = observer(
  ({
    layer,
    weightClasses,
    companies,
    filter,
    changeFilter,
    onCreateNewRule,
    onExpand,
    onReturn,
  }: Props) => {
    const { t } = useTranslate()

    const getKeyValue = (key: string, filter: UnAllocatedFilter) => {
      if (key === AllStringValue) return undefined
      if (key === UnknownStringValue) {
        switch (filter) {
          case 'customer':
            return UnknownNumberValue
          case 'outboundCarrierType':
            return CarrierType.Unknown
          default:
            return UnknownStringValue
        }
      }

      return key
    }

    const getNameFromContainerGroup = (group: ContainerTurnoverGroup) => {
      if (group.label) return group.label
      if (group.name === UnknownStringValue) return t('unknown', 'Unknown')
      if (group.name === AllStringValue) return t('all', 'All')

      return group.name
    }

    const unallocatedGroups = useMemo(() => {
      const currentFilter = layer.filters.length ? filter : layer.defaultGroupBy

      const containerTurnoverGroups = getContainerTurnoverGroups(
        layer.containerTurnovers,
        currentFilter,
        weightClasses,
        companies,
      )

      return containerTurnoverGroups.map(
        group =>
          ({
            ...group,
            currentFilter: currentFilter,
            breakdown: {
              ...layer.turnoversBreakdown,
              value: group.containerTurnovers,
              [currentFilter]: getKeyValue(group.name, filter),
            },
          }) as ContainerTurnoverGroupWithBreakdown,
      )
    }, [filter, layer.isFinalLevel, layer.turnoversBreakdown, layer.containerTurnovers])

    return (
      <Stack>
        <Stack flexDirection='row' gap={1} alignItems='center'>
          <Tooltip title={t('return', 'Return')}>
            <IconButton onClick={onReturn}>
              <ChevronLeft />
            </IconButton>
          </Tooltip>
          <Typography variant='h6' my={1}>
            {`${layer.isStandard ? t('standard', 'Standard') : t('special', 'Special')} `}
            <TurnoverBreakdownLabel breakdownItem={layer.turnoversBreakdown} />
          </Typography>
        </Stack>
        <Divider />

        {layer.filters.length > 0 && (
          <>
            <Stack flexDirection='row' gap={2} p={2} alignItems='center'>
              <Typography variant='body2' flex='none'>
                {t('groupBy', 'Group by')}
              </Typography>
              <Select
                value={filter}
                fullWidth
                size='small'
                onChange={value => changeFilter(value.target.value as UnAllocatedFilter)}
              >
                {layer.filters.map(filter => (
                  <MenuItem key={`layer_filter_${filter.value}`} value={filter.value}>
                    {filter.label}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Divider />
          </>
        )}

        {unallocatedGroups.map(group => (
          <UnAllocatedItem
            key={`unallocated_item-${group.name}`}
            breakdownItem={group.breakdown}
            label={getNameFromContainerGroup(group)}
            total={group.containerTurnovers.length}
            onCreateNewRule={() => onCreateNewRule(group.breakdown)}
            onExpand={
              !layer.isFinalLevel && onExpand
                ? () => onExpand(group.breakdown, group.currentFilter)
                : undefined
            }
          />
        ))}
      </Stack>
    )
  },
)
