import { Paper, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'

interface Props {
  label: string
  icon: ReactElement
}
export const DirectionCard = observer(({ label, icon }: Props) => {
  return (
    <Paper
      variant='outlined'
      sx={{ minWidth: '12rem', borderRadius: 0, backgroundColor: 'transparent' }}
    >
      <Stack padding={2} flexDirection='row' gap={2} alignItems='center'>
        {icon}
        <Typography variant='subtitle2'>{label}</Typography>
      </Stack>
    </Paper>
  )
})
