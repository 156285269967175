import { Stack } from '@mui/material'
import { IContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourney'
import { ServiceOrdersType } from '@planning/pages/ServiceOrders/Stores/ServiceOrdersViewStore'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { AddServiceOrderButton } from './AddServiceOrderButton'
import { CoolingServiceTooltipChip } from './CoolingServiceTooltipChip'
import { PackingServiceTooltipChip } from './PackingServiceTooltipChip'

type Props = {
  data: IContainerJourney
  onClickAddService: (serviceOrdersType: ServiceOrdersType) => void
  onClickService: (serviceOrdersType: ServiceOrdersType) => void
}

export const ContainerVisitsCardServicesInfo: FC<Props> = observer(
  ({ data, onClickAddService, onClickService }) => {
    const { strippingOrderId, stuffingOrderId, coolingOrderId, container } = data

    if (!container) return <></>

    const isCriticalContainerDamage = useMemo(
      () => container.damages.some(d => d.types?.some(t => t.isCriticalDamage)),
      [container],
    )

    return (
      <Stack spacing={{ xs: 1 }} direction='row' useFlexGap flexWrap='wrap' alignItems={'center'}>
        {strippingOrderId && (
          <PackingServiceTooltipChip isStuffing={false} onClick={onClickService} />
        )}
        {stuffingOrderId && (
          <PackingServiceTooltipChip isStuffing={true} onClick={onClickService} />
        )}
        {coolingOrderId && <CoolingServiceTooltipChip onClick={onClickService} />}

        <AddServiceOrderButton
          journey={data}
          onClickAddService={onClickAddService}
          isCriticalContainerDamage={isCriticalContainerDamage}
        />
      </Stack>
    )
  },
)
