import { Paper } from '@mui/material'
import { OrderGroupingStore } from '@planning/stores/grouping/OrderGroupingStore'
import { FC } from 'react'
import { ObserverGrouping } from './Grouping/ObserverGrouping'
export interface OpsIssue {
  carrierVisitIds?: number[]
  orderId: number | null | undefined
  objectId: number
}
export interface IProps {
  store: OrderGroupingStore
  containerYardOperationViewStore: ContainerYardOperationViewStore
  disableSequencing?: boolean
  opsIssues?: OpsIssue[]
}

export interface ContainerYardOperationViewStore {
  containerNumber?: string
  orderId?: number | null
  isOpen: boolean
  open: (
    containerNumber: string,
    orderId: number | null,
    title?: string,
    message?: string,
    beforeYardPositionChangeConfirmation?: () => Promise<void>,
  ) => void
}

export const OrderGrouping: FC<IProps> = ({
  store,
  containerYardOperationViewStore,
  disableSequencing,
  opsIssues,
}) => {
  return (
    <Paper variant='elevation' elevation={1}>
      <ObserverGrouping
        store={store}
        containerYardOperationViewStore={containerYardOperationViewStore}
        disableSequencing={disableSequencing}
        opsIssues={opsIssues}
      />
    </Paper>
  )
}
