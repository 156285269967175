import { ExternalDriverResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetExternalDriversQuery } from '@planning/messages/queries'
import _ from 'lodash'
import { action, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { IEntityStore } from '../types'
import { ExternalDriverItem, IExternalDriverItem } from './ExternalDriverItem'

export class ExternalDriverItemStore
  extends ItemStore<ExternalDriverResponseDto, IExternalDriverItem>
  implements IEntityStore<IExternalDriverItem>
{
  private hasBeenLoaded = false

  constructor(private readonly messageBus: IMessageBus) {
    super((key, data) => new ExternalDriverItem(key, data), {
      messageBus,
      bulkFetchFunc: (_: number[]) => new GetExternalDriversQuery(),
    })

    makeObservable(this, {
      receiveEntityMessage: action,
      receiveUpsertedEventMessage: action,
      receiveDeletedEventMessage: action,
    })

    messageBus.subscribeEvent(GetExternalDriversQuery.type, this.receiveEntityMessage)
    messageBus.subscribeEvent(
      EventTypes.ExternalDriverUpsertedEvent,
      this.receiveUpsertedEventMessage,
    )
    messageBus.subscribeEvent(
      EventTypes.ExternalDriverDeletedEvent,
      this.receiveDeletedEventMessage,
    )
  }

  receiveEntityMessage = (event: IEvent<ExternalDriverResponseDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveUpsertedEventMessage = (event: IEvent<ExternalDriverResponseDto>): void => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  receiveDeletedEventMessage = (event: IEvent<number>): void => {
    if (event.payload && _.has(this.elements, event.payload)) {
      this.delete(event.payload)
    }
  }

  fetch = async (query?: GetExternalDriversQuery) => {
    if (this.hasBeenLoaded) return

    this.hasBeenLoaded = true

    await this.messageBus.dispatchQuery(query ?? new GetExternalDriversQuery())
  }
}
