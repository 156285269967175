import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '../../../hooks'

interface IProps {
  formId: string
  saveTitle?: string
  deleteTitle?: string
  cancelTitle?: string
  saveDisabled?: boolean
  showSaveButton?: boolean
  showDeleteButton?: boolean
  showCancelButton?: boolean
  onDelete?: () => void
  onBack?: () => void
}

export function PageHeaderActions({
  formId,
  saveTitle,
  deleteTitle,
  cancelTitle,
  saveDisabled,
  showSaveButton = true,
  showDeleteButton,
  showCancelButton = true,
  onDelete,
  onBack,
}: IProps) {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const defaultSaveTitle = saveTitle ?? t('save', 'Save')
  const defaultDeleteTitle = deleteTitle ?? t('delete', 'Delete')
  const defaultCancelTitle = cancelTitle ?? t('cancel', 'Cancel')

  return (
    <Stack gap={theme.customSpacing.m} direction='row' alignItems='center'>
      {showCancelButton && (
        <Button variant='text' onClick={onBack} color='inherit' data-cy='page-header-cancel-btn'>
          {defaultCancelTitle}
        </Button>
      )}

      {showDeleteButton && (
        <Button
          variant='contained'
          color='inherit'
          data-cy='page-header-delete-btn'
          type='button'
          onClick={onDelete}
        >
          {defaultDeleteTitle}
        </Button>
      )}

      {showSaveButton && (
        <Button
          disabled={saveDisabled}
          variant='contained'
          color='primary'
          form={formId}
          data-cy='page-header-submit-btn'
          type='submit'
        >
          {defaultSaveTitle}
        </Button>
      )}
    </Stack>
  )
}
